import { useCallback, useEffect, useState } from 'react';
import { RolebotClient } from "../../../../api/client";
import { useDebouncedValue } from '@mantine/hooks';
import RolebotMultiselect from 'components/Inputs/RolebotMultiSelect';
import { Text, Group, Badge, CloseButton, createStyles } from "@mantine/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandSparkles } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles(() => ({
  xStyles: {
    color: '#B3B3B3',
    ':hover': {
      color: '#4F4F4F',
    },
    marginLeft: '-5px'
  }
}))

interface SearchCriteriaSkillsProps {
  onSkillsChange: (payload: { name: string }[]) => void;
  skills: any;
  isNiceToHave: boolean;
  suggestedSkills: any;
  addSkillToBlacklist: (payload: object) => void;
  removeSkillFromSuggestions: (payload: object) => void;
}


// transforms the data to a Mantine MultiSelect readable format
const formatDropdownItems = (items: { id: any; name: any; }[]) => {

  if (items?.length) {
    return items?.map((x: { id: any; value?: any; name: any; label?: any; pivot?: any }) => {
      return {
        value: x.id.toString(),
        label: x.name,
        id: x.id,
        name: x.name,
        is_from_feedback: x.pivot?.is_from_feedback
      };
    });
  } else {
    return []
  }
}

const getUniqueListById = (arr: any) => {
  return [...new Map(arr.map((item: any) => [item['id'], item])).values()]
}

const SearchCriteriaSkills = ({ onSkillsChange, skills, isNiceToHave, suggestedSkills = [], addSkillToBlacklist, removeSkillFromSuggestions }: SearchCriteriaSkillsProps) => {
  const [skillSearchTerm, setSkillSearchTerm] = useState("");
  const [availableSkills, setAvailableSkills] = useState(skills?.length ? formatDropdownItems(skills) : []);
  const [selectedSkills, setSelectedSkills] = useState(skills ? skills.map((skill: any) => skill.id.toString()) : []);
  const [debouncedSearch] = useDebouncedValue(skillSearchTerm.trim(), 500);
  const { classes, cx } = useStyles();

  useEffect(() => {
    onSkillsChange(formatPayloadItems(selectedSkills));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkills]);

  useEffect(() => {
    if (skillSearchTerm) searchForSkillsTag(skillSearchTerm)
  }, [debouncedSearch])

  // transforms the data to a back to the expected from API format  e.g: id: 1, name: "Laravel"
  // applicable for both skills and job titles
  const formatPayloadItems = (items: string[]) => {

    if (items?.length) {
      return items.map((id: string) => {
        let object = availableSkills.find((s: any) => s.id.toString() === id)
        return {
          id: object?.id ?? id,
          name: object?.name ?? id,
          nice_to_have: isNiceToHave,
          is_from_feedback: object?.is_from_feedback
        };
      });
    } else {
      return []
    }
  }


  const searchForSkillsTag = useCallback((searchTerm) => {
    (async () => {
      try {
        const { data } = await RolebotClient.get('/skills', { params: { 'search-term': searchTerm } });

        if (data.data && data.data.length) {
          // @ts-ignore
          setAvailableSkills((prev) => getUniqueListById([...prev, ...formatDropdownItems(data.data)]));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [debouncedSearch]);

  const copyToAvailable = (values: string[]) => {

    let selected = availableSkills.filter((x: any) => values.includes(x.value))

    setAvailableSkills(selected)
    setSelectedSkills(values)
  }

  const handleAddSuggestion = (suggestion: any) => {
    const payload = {
      value: suggestion.skill_id.toString(),
      label: suggestion.skill_name,
      id: suggestion.skill_id,
      name: suggestion.skill_name,
      is_from_feedback: false
    }

    setAvailableSkills(prevSkills => {
      const newSkills = [...prevSkills];
      const existingIndex = newSkills.findIndex(skill => skill.id === payload.id);
      if (existingIndex === -1) {
        newSkills.push(payload);
      }
      return newSkills;
    });

    setSelectedSkills((prevSelectedSkills: string[]) => {
      const newSelected = [...prevSelectedSkills];
      if (!newSelected.includes(payload.id.toString())) {
        newSelected.push(payload.id.toString());
      }
      return newSelected;
    });

    removeSkillFromSuggestions(suggestion);
  }

  return (
    <>

      <RolebotMultiselect
        data={availableSkills}
        searchable
        creatable
        value={selectedSkills}
        getCreateLabel={(query) => `+ Create ${query}`}
        onChange={copyToAvailable}
        nothingFound={'Type to search'}
        onSearchChange={setSkillSearchTerm}
        selectOnBlur={true}
        clearSearchOnBlur={true}
        clearSearchOnChange={true}
        style={{ width: '100%' }}
        maxSelectedValues={isNiceToHave ? 100 : 7}
        withinPortal
        onBlur={() => {
          setAvailableSkills(availableSkills.filter((x: any) => selectedSkills.includes(x.value)));
        }}
      />

      {selectedSkills.length < 7 && suggestedSkills.length > 0 && (
        <Group my={10} spacing={10}>
          <Group
            spacing={10}
            style={{
              backgroundColor: '#40A5D01A',
              fontStyle: 'italic',
              color: '#147CA1',
              padding: '2px',
              borderRadius: '2px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            <Text>Suggested by AI</Text>
            <FontAwesomeIcon icon={faWandSparkles} />
          </Group>
          {suggestedSkills.map((suggestion: any) => (
            <Badge
              styles={{
                root: {
                  backgroundColor: 'transparent'
                },
                inner: {
                  backgroundColor: 'transparent',
                  color: '#838485',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}
              style={{
                marginRight: '-10px'
              }}
              key={suggestion.id}
              rightSection={<CloseButton
                aria-hidden
                onMouseDown={() => addSkillToBlacklist(suggestion)}
                radius={2}
                variant="transparent"
                tabIndex={-1}
                className={classes.xStyles}
                onClick={(event) => event.stopPropagation()}
              />}
              onClick={() => handleAddSuggestion(suggestion)}
            >
              {suggestion.skill_name}
            </Badge>
          ))}
        </Group>
      )}

    </>
  );
};

export default SearchCriteriaSkills;
