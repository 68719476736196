import React from 'react';
import {Group, Text} from '@mantine/core';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type WaitingForSetupStepMenuItemProps = {
  step: number;
  menuItem: { text: string; step: number; };
  isDesktop: boolean;
};

const WaitingForSetupStepMenuItem: React.FC<WaitingForSetupStepMenuItemProps> = ({ step, menuItem, isDesktop }) => {

  const color = menuItem.step == step ? "#24154F" : step < menuItem.step ? '#838485' : '#4f4f4f'
  return (
      <Group noWrap style={{
        width: isDesktop ? '81%' : '100%',
        padding: 6,
        backgroundColor: step === menuItem.step ? '#8241FD2E' : 'inherit',
        borderRadius: 5,
        paddingLeft: 10,
      }}>
        <Text color={color}
              style={{ flex: '1 1 auto', whiteSpace: 'nowrap' }}>{menuItem.step + ". " + menuItem.text}</Text>
        { step > menuItem.step && <FontAwesomeIcon icon={faCheck} color={'#92ce71'} /> }
      </Group>
  );
};

export default WaitingForSetupStepMenuItem;
