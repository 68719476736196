import { Container, Text, Table, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    container: {
        backgroundColor: '#F7F5F5',
        padding: '20px',
        width: '100%'
    },
    title: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '28px',
        fontWeight: 500,
        paddingTop: '20px',
        paddingLeft: '50px'
    },
    table: {
        '& thead tr th': {
            width: '33.33%',
            textAlign: 'center',
            paddingBottom: '10px',
            fontSize: '22px',
            fontWeight: 500,
            color: '#242424',
            letterSpacing: '0px',
            fontFamily: 'Roboto, sans-serif'
        },
        '& td': {
            width: '33.33%',
            textAlign: 'left',
            border: '1px solid #ddd',
            padding: '20px',
        },
        '& td:first-of-type': {
            borderLeft: 'none',
        },
        '& td:last-child': {
            borderRight: 'none',
        },
    },
    tableRow: {
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
}));

const PlanComparisonTable = () => {
    const { classes } = useStyles();

    return (
        <Container
            fluid
            className={classes.container}
        >
            <Text className={classes.title}>Compare plan features</Text>
            <Table className={classes.table}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Lite</th>
                        <th>Pro</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>10 daily candidates per role</strong>
                                <br />
                                Enjoy 10 new candidates per active role every business
                                day! Ensure a constant flow by reviewing pending candidates regularly
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>Unlimited Interview Scheduling</strong>
                                <br />
                                Rolebot handles candidate engagement and interview
                                scheduling for all your roles, saving you time and effort
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>Access to candidate’s contact info</strong>
                                <br />
                                Gain access to candidate contact information when
                                available, facilitating direct communication with potential hires
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>Unlimited Users</strong>
                                <br />
                                Invite an unlimited number of coworkers to join Rolebot, fostering
                                collaboration across your entire team
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>Access to Integrations</strong>
                                <br />
                                Unlock seamless workflows with access to all integrations,
                                including Greenhouse, Lever, iCIMS, and more
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>Email Support</strong>
                                <br />
                                Email support for any inquiries or assistance you may need
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>As many roles as needed</strong>
                                <br />
                                Chat with our sales team to tailor a solution that perfectly
                                fits your team's needs. Enjoy the flexibility of covering as many roles as your team requires!
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.1236 25.6798C24.5505 26.1067 25.2529 26.1067 25.6798 25.6798C26.1067 25.2529 26.1067 24.5505 25.6798 24.1236L16.5562 15L25.6798 5.87637C26.1067 5.44945 26.1067 4.7471 25.6798 4.32019C25.2529 3.89327 24.5505 3.89327 24.1236 4.32019L15 13.4438L5.87637 4.32019C5.44945 3.89327 4.7471 3.89327 4.32019 4.32019C3.89327 4.7471 3.89327 5.44945 4.32019 5.87637L13.4438 15L4.32019 24.1236C3.89327 24.5505 3.89327 25.2529 4.32019 25.6798C4.7471 26.1067 5.44945 26.1067 5.87637 25.6798L15 16.5562L24.1236 25.6798Z" fill="#D20B13" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>Dedicated customer success manager</strong>
                                <br />
                                Receive dedicated support with a dedicated manager on the Pro plan, ensuring personalized assistance for your unique needs
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.1236 25.6798C24.5505 26.1067 25.2529 26.1067 25.6798 25.6798C26.1067 25.2529 26.1067 24.5505 25.6798 24.1236L16.5562 15L25.6798 5.87637C26.1067 5.44945 26.1067 4.7471 25.6798 4.32019C25.2529 3.89327 24.5505 3.89327 24.1236 4.32019L15 13.4438L5.87637 4.32019C5.44945 3.89327 4.7471 3.89327 4.32019 4.32019C3.89327 4.7471 3.89327 5.44945 4.32019 5.87637L13.4438 15L4.32019 24.1236C3.89327 24.5505 3.89327 25.2529 4.32019 25.6798C4.7471 26.1067 5.44945 26.1067 5.87637 25.6798L15 16.5562L24.1236 25.6798Z" fill="#D20B13" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>Interchangeable Roles</strong>
                                <br />
                                Effortlessly manage your hiring needs by closing one role and launching a different one mid-month. Maintain flexibility while keeping within your active role limit based on your subscription plan
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.1236 25.6798C24.5505 26.1067 25.2529 26.1067 25.6798 25.6798C26.1067 25.2529 26.1067 24.5505 25.6798 24.1236L16.5562 15L25.6798 5.87637C26.1067 5.44945 26.1067 4.7471 25.6798 4.32019C25.2529 3.89327 24.5505 3.89327 24.1236 4.32019L15 13.4438L5.87637 4.32019C5.44945 3.89327 4.7471 3.89327 4.32019 4.32019C3.89327 4.7471 3.89327 5.44945 4.32019 5.87637L13.4438 15L4.32019 24.1236C3.89327 24.5505 3.89327 25.2529 4.32019 25.6798C4.7471 26.1067 5.44945 26.1067 5.87637 25.6798L15 16.5562L24.1236 25.6798Z" fill="#D20B13" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>Sourcing-Only Roles</strong>
                                <br />
                                Quickly launch sourcing-only roles to receive qualified candidates without engagement from Rolebot. This option allows you to manage candidate interactions directly while still benefiting from our candidate sourcing capabilities
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.1236 25.6798C24.5505 26.1067 25.2529 26.1067 25.6798 25.6798C26.1067 25.2529 26.1067 24.5505 25.6798 24.1236L16.5562 15L25.6798 5.87637C26.1067 5.44945 26.1067 4.7471 25.6798 4.32019C25.2529 3.89327 24.5505 3.89327 24.1236 4.32019L15 13.4438L5.87637 4.32019C5.44945 3.89327 4.7471 3.89327 4.32019 4.32019C3.89327 4.7471 3.89327 5.44945 4.32019 5.87637L13.4438 15L4.32019 24.1236C3.89327 24.5505 3.89327 25.2529 4.32019 25.6798C4.7471 26.1067 5.44945 26.1067 5.87637 25.6798L15 16.5562L24.1236 25.6798Z" fill="#D20B13" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                    <tr className={classes.tableRow}>
                        <td style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto, sans-serif', color: '#242424' }}>
                            <p>
                                <strong>Live & Email Support</strong>
                                <br />
                                Receive prompt assistance with our dedicated live and email support. Our team is here to help you navigate your hiring process and ensure a smooth experience
                            </p>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.1236 25.6798C24.5505 26.1067 25.2529 26.1067 25.6798 25.6798C26.1067 25.2529 26.1067 24.5505 25.6798 24.1236L16.5562 15L25.6798 5.87637C26.1067 5.44945 26.1067 4.7471 25.6798 4.32019C25.2529 3.89327 24.5505 3.89327 24.1236 4.32019L15 13.4438L5.87637 4.32019C5.44945 3.89327 4.7471 3.89327 4.32019 4.32019C3.89327 4.7471 3.89327 5.44945 4.32019 5.87637L13.4438 15L4.32019 24.1236C3.89327 24.5505 3.89327 25.2529 4.32019 25.6798C4.7471 26.1067 5.44945 26.1067 5.87637 25.6798L15 16.5562L24.1236 25.6798Z" fill="#D20B13" />
                            </svg>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5909 4.49696C28.1364 5.15956 28.1364 6.23102 27.5909 6.88657L12.2729 25.503C11.7275 26.1657 10.8456 26.1657 10.306 25.503L2.40906 15.9164C1.86365 15.2538 1.86365 14.1823 2.40906 13.5268C2.95447 12.8712 3.83642 12.8641 4.37603 13.5268L11.2807 21.9151L25.6182 4.49696C26.1636 3.83435 27.0455 3.83435 27.5851 4.49696H27.5909Z" fill="#6FBE44" />
                            </svg>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    )
}

export default PlanComparisonTable;