const AVAILABLE_HOURS = [
  { value: '00:00', label: '00:00am' },
  { value: '00:30', label: '00:30am' },
  { value: '01:00', label: '1:00am' },
  { value: '01:30', label: '1:30am' },
  { value: '02:00', label: '2:00am' },
  { value: '02:30', label: '2:30am' },
  { value: '03:00', label: '3:00am' },
  { value: '03:30', label: '3:30am' },
  { value: '04:00', label: '4:00am' },
  { value: '04:30', label: '4:30am' },
  { value: '05:00', label: '5:00am' },
  { value: '05:30', label: '5:30am' },
  { value: '06:00', label: '6:00am' },
  { value: '06:30', label: '6:30am' },
  { value: '07:00', label: '7:00am' },
  { value: '07:30', label: '7:30am' },
  { value: '08:00', label: '8:00am' },
  { value: '08:30', label: '8:30am' },
  { value: '09:00', label: '9:00am' },
  { value: '09:30', label: '9:30am' },
  { value: '10:00', label: '10:00am' },
  { value: '10:30', label: '10:30am' },
  { value: '11:00', label: '11:00am' },
  { value: '11:30', label: '11:30am' },
  { value: '12:00', label: '12:00pm' },
  { value: '12:30', label: '12:30pm' },
  { value: '13:00', label: '1:00pm' },
  { value: '13:30', label: '1:30pm' },
  { value: '14:00', label: '2:00pm' },
  { value: '14:30', label: '2:30pm' },
  { value: '15:00', label: '3:00pm' },
  { value: '15:30', label: '3:30pm' },
  { value: '16:00', label: '4:00pm' },
  { value: '16:30', label: '4:30pm' },
  { value: '17:00', label: '5:00pm' },
  { value: '17:30', label: '5:30pm' },
  { value: '18:00', label: '6:00pm' },
  { value: '18:30', label: '6:30pm' },
  { value: '19:00', label: '7:00pm' },
  { value: '19:30', label: '7:30pm' },
  { value: '20:00', label: '8:00pm' },
  { value: '20:30', label: '8:30pm' },
  { value: '21:00', label: '9:00pm' },
  { value: '21:30', label: '9:30pm' },
  { value: '22:00', label: '10:00pm' },
  { value: '22:30', label: '10:30pm' },
  { value: '23:00', label: '11:00pm' },
  { value: '23:30', label: '11:30pm' },
  { value: '24:00', label: '12:00am' },
];

const buildNumberArray = (lowEnd: number, highEnd: number): number[] => {
  let list = [];
  for (let i = lowEnd; i <= highEnd; i++) {
    list.push(i);
  }
  return list;
}

export const getStartRange = (endTime: string|null): typeof AVAILABLE_HOURS => {
  return endTime === null ? AVAILABLE_HOURS :
    AVAILABLE_HOURS.slice(0, AVAILABLE_HOURS.findIndex(x => x.value === endTime));
}

export const getEndRange = (startTime: string|null): typeof AVAILABLE_HOURS => {
  return startTime === null ? AVAILABLE_HOURS :
    AVAILABLE_HOURS.slice( (AVAILABLE_HOURS.findIndex(x => x.value === startTime) + 2) + -1 );
}

export const getIndexRange = (startTime: string, endTime: string): number[]=> {
  const startRange = AVAILABLE_HOURS.findIndex(x => x.value === startTime);
  const endRange = AVAILABLE_HOURS.findIndex(x => x.value === endTime);
  return buildNumberArray(startRange, endRange);
}

export const isIndexRangeInHayStack = (needles: number[], haystack: number[]):boolean => {
  let isFound = false;

  needles.forEach(n => {
    if(haystack.includes(n)){
      isFound = true;
    }
  })

  return isFound;
}