import React, { useMemo } from 'react';
import { Avatar, createStyles, Divider, Group, Menu, Stack, Text } from '@mantine/core';
import { getNameInitials } from 'utils';
import { useGetCompanyQuery, useGetUserQuery, useSignOutMutation } from 'app/services/rolebot';
import { useNavigate } from 'react-router-dom';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faFileLines,
  faGear,
  faCircleQuestion,
  faArrowRightFromBracket,
} from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  avatar: {
    borderRadius: 100,
    width: 47,
    height: 47,
  },
  placeholder: {
    fontSize: theme.fontSizes.xl,
  },
  name: {
    fontFamily: 'Roboto',
    fontSize: theme.fontSizes.md,
    lineHeight: 1.4,
  },
  company: {
    fontSize: theme.fontSizes.sm,
    color: '#838485',
  },

  innerPlaceholder: {
    fontSize: theme.fontSizes.lg,
  },
  icon: {
    paddingBottom: '2px'
  },
  contactIcon: {
    paddingBottom: '4px'
  }
}));

const NavbarUserMenu = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const user = useGetUserQuery();
  const { data } = useGetCompanyQuery();
  const userName = user?.data?.user?.name || '';
  const [signOut] = useSignOutMutation();
  const { contactSupport } = useOpenModal();

  const isAdmin = useMemo(() => {
    return Boolean(data?.customer_user?.is_admin);
    // eslint-disable-next-line
  }, [data]);

  const handleSignOut = async () => {
    await signOut();
  };

  const openContactSupportModal = () => {
    contactSupport();
  };

  return (
    <Group direction="column" align="center" spacing={0} mb={20} sx={{ cursor: 'pointer' }}>
      <Menu
        control={
          <Avatar radius={65} className={classes.avatar} classNames={{ placeholder: classes.placeholder }}>
            {getNameInitials(userName)}
          </Avatar>
        }
        position={'right'}
        placement={'start'}
        styles={{
          body: {
            width: 250,
          },
        }}
      >
        <Menu.Item sx={{ pointerEvents: 'none' }}>
          <Group spacing={'xs'} noWrap position={'apart'}>
            <Avatar radius={65} className={classes.avatar} classNames={{ placeholder: classes.innerPlaceholder }}>
              {getNameInitials(userName)}
            </Avatar>
            <Stack spacing={0}>
              <Text className={classes.name} lineClamp={1}>
                {userName}
              </Text>
              <Text className={classes.company} lineClamp={1}>
                {data?.name}
              </Text>
            </Stack>
          </Group>
        </Menu.Item>
        <Divider />
        <Item onClick={() => navigate('/profile')}>
          <FontAwesomeIcon icon={faUser} width={20} height={20} className={classes.icon}/>

          <Text>My Profile</Text>
        </Item>
        {isAdmin && (
          <Item onClick={() => navigate('/role-stats')}>
            <FontAwesomeIcon icon={faFileLines} width={20} height={20} className={classes.icon}/>
            <Text>Role Stats</Text>
          </Item>
        )}
        <Item onClick={() => navigate( isAdmin ? '/settings/users' : '/settings/customizations')}>
          <FontAwesomeIcon icon={faGear} width={20} height={20} className={classes.icon}/>
          <Text>Settings</Text>
        </Item>
        <Divider />
        <Item onClick={openContactSupportModal}>
          <FontAwesomeIcon icon={faCircleQuestion} width={20} height={20} className={classes.contactIcon}/>
          <Text>Contact Support</Text>
        </Item>
        <Divider />
        <Item onClick={handleSignOut}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} width={20} height={20} className={classes.icon}/>
          <Text>Log Out</Text>
        </Item>
      </Menu>
    </Group>
  );
};

const Item = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => {
  return (
    <Menu.Item onClick={onClick}>
      <Group spacing={'xs'}>{children}</Group>
    </Menu.Item>
  );
};

export default NavbarUserMenu;
