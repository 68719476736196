import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import { Outlet, useNavigate } from 'react-router-dom';
import useAppSelector from 'hooks/useAppSelector';
import { LoadingOverlay } from '@mantine/core';
import { useLazyGetUserQuery } from 'app/services/rolebot';
import { useEffect } from 'react';
import LeverAuthExpiredHook from 'components/Webhooks/LeverAuthExpired/LeverAuthExpired';

/*
Since the user is going directly to a protected page, we need to check if the user is logged in.
If they are not logged in, we need to redirect them to the login page.
 */
const AuthLayout = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const [getUser, { error }] = useLazyGetUserQuery();

  useEffect(() => {
    if (!isLoggedIn) {
      getUser();
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    if (error) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, [error]);

  return isLoggedIn ? (
    <LeverAuthExpiredHook>
      <SiteWrapper>
        <Outlet />
      </SiteWrapper>
    </LeverAuthExpiredHook>
  ) : (
    <LoadingOverlay visible={true} />
  );
};
export default AuthLayout;
