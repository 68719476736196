import React, { useEffect, useRef, useState } from 'react';
import { ContextModalProps } from '@mantine/modals';
import { Alert, Box, Divider, ScrollArea, Stack, Text, TextInput } from '@mantine/core';
import { useGetRoleByIdQuery, usePostRoleUserMutation } from 'app/services/rolebot';
import AddUsersToRoleDropDown from '../LaunchRole/Components/AddUsersToRoleDropDown';
import AdminRow from 'components/Modals/RoleAdmins/AdminRow';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useOutsideAreaClick from '../../../pages/RoleStats/hooks/useOutsideAreaClick';
import { useClickOutside } from '@mantine/hooks';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const RoleAdminSchema = z.object({
  email: z.string().email('Invalid email address'),
});

type RoleAdminPayload = z.infer<typeof RoleAdminSchema>;

interface RoleAdminProps extends Record<string, unknown> {
  roleId: string | number;
  origin: 'settings' | string;
}

const RoleAdmins = ({ context, id, innerProps }: ContextModalProps<RoleAdminProps>) => {
  const { roleId, origin } = innerProps;
  const { data } = useGetRoleByIdQuery(roleId);
  const [addAdmin, { isLoading, isSuccess, isError }] = usePostRoleUserMutation();
  const [showUsers, setShowUsers] = useState(false);
  const [error, setError] = useState<any>({});

  const {
    register,
    resetField,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(RoleAdminSchema),
  });

  const [emailWatcher] = watch(['email']);
  const onSubmit = async (payload: RoleAdminPayload) => {
    setError({});
    try {
      await addAdmin({
        roleId,
        payload: {
          email: payload.email,
        },
      }).unwrap();
    } catch (e: any) {
      setError({ ...e, email: payload.email });
    }
    resetField('email');
  };

  useEffect(() => {
    setValue('email', emailWatcher);
    // eslint-disable-next-line
  }, [emailWatcher]);

  const handleCloseDropdown = () => {
    // if(!showUsers) {
    //     setShowUsers(false);
    //     setValue('email', '');
    // }
    //console.log('handleclosedropdown');
  };

  const handleUsersDropdownFocus = () => {
    setShowUsers(true);
  };

  const handleUserSelected = async (user: any) => {
    setError({});
    setValue('email', user.email);
    setShowUsers(false);
    try {
      await addAdmin({
        roleId,
        payload: {
          email: user.email,
        },
      }).unwrap();
    } catch (e: any) {
      setError({ ...e, email: user.email });
    }
    resetField('email');
  };

  useEffect(() => {
    if (!isSuccess) return;
    reset();
    // eslint-disable-next-line
  }, [isSuccess]);

  const handleSkip = () => {
    context.closeModal(id);
  };

  let removable = true;

  if (data?.users.length === 1) {
    removable = false;
  }

  let users = data?.users.map((user) => (
    <AdminRow key={user.id} id={user.id} removable={removable} name={user?.name} email={user.email} roleId={roleId} />
  ));

  const wrapperRef = useRef(null);
  const clickedOutside = useOutsideAreaClick(wrapperRef);

  useEffect(() => {
    if (clickedOutside) {
      if (showUsers) {
        setShowUsers(false);
        setValue('email', '');
      }
    }
    // eslint-disable-next-line
  }, [clickedOutside]);

  return (
    <Box>
      <Text size={'md'} color={'#838485'} mb={15}>
        Role Members
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div ref={wrapperRef}>
          <TextInput
            disabled={isLoading}
            label="Would you like to add additional users to this role?"
            placeholder="ex. Bob Ross, or bob@acme.com"
            error={errors?.email?.message}
            {...register('email')}
            onFocus={() => handleUsersDropdownFocus()}
            onClick={() => {
              if (!showUsers) {
                setShowUsers(true);
              }
            }}
          />
          <AddUsersToRoleDropDown
            closeDropdown={handleCloseDropdown}
            userSelected={handleUserSelected}
            show={showUsers}
            needle={getValues('email')}
          />
        </div>
      </form>
      <ScrollArea sx={{ height: 300 }} offsetScrollbars scrollbarSize={8} type={'always'} mt={16}>
        <Stack>{users}</Stack>
      </ScrollArea>
      {isError &&
        (error?.status === 400 ? (
          <Alert title={'Oops!'} color={'orange'}>
            {error?.email + ' cannot be added because they are part of a different company'}
          </Alert>
        ) : (
          <Alert title={'Oops, something went wrong!'} color={'orange'}>
            We were not able to add this user to the role.
          </Alert>
        ))}
      {/* <Divider mb={20} />

      <RolebotButton sx={{ display: 'block', marginLeft: 'auto' }} type={'neutral'} onClick={handleSkip}>
        {origin === 'settings' ? 'Close' : ' Skip for now'}
      </RolebotButton> */}
    </Box>
  );
};

export default RoleAdmins;
