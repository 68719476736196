import React, { forwardRef } from "react"
import { Textarea, TextareaProps } from "@mantine/core"

const RolebotTextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(({size = 'md', ...props}, ref) => {
    return (
        <Textarea
            ref={ref}
            size={size}
            {...props}/>
    )
})

export default RolebotTextArea