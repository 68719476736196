import React from 'react';
import { toast, ToastContent, ToastPosition } from 'react-toastify';

interface IRules {
  position: ToastPosition;
  autoClose: number | false;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
}

const rules: IRules = {
  position: 'top-right',
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
};

const persistentRules: IRules = {
  position: 'top-right',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
};

export const toastSuccess = (content: ToastContent<unknown>) => {
  toast.success(content, rules);
};

export const toastInfo = (content: ToastContent<unknown>, id?: string) => {
  if (id) {
    toast.info(content, { ...persistentRules, toastId: id });
  } else {
    toast.info(content, persistentRules);
  }
};

export const toastWarning = (content: ToastContent<unknown>) => {
  toast.warning(content, rules);
};

export const toastFeedbackWarning = (content: ToastContent<unknown>, id?: string) => {
  toast.warning(content, { ...persistentRules, toastId: id || 'feedback-warning' });
};

export const toastError = (content: ToastContent<unknown>) => {
  toast.error(content, persistentRules);
};

// Utility function to get the toast content for reactivating plans
export const getReactivatePlanToastContent = () => {
  return React.createElement(
    "div",
    { className: "toastContainer" },
    React.createElement("div", null, "Our team will contact you soon!"),
    React.createElement("div", null, "Our team will reach out to you to help reactivate your plan!")
  );
};
