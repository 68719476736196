import { createStyles, Text, Box, Avatar, Title, Badge, Group } from '@mantine/core';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { getNameInitials } from 'utils';
import { getTimeinHourOrDayByDate } from 'utils/dates';
import useRandomColor from 'hooks/useRandomColor';
import SearchCriteriaTag from './SearchCriteriaTag';

const useStyles = createStyles((theme, { color }: any) => ({
    avatar: {
        width: '50%',
        height: '50%',
        borderRadius: 35,
        [theme.fn.largerThan('md')]: {
          width: '2vw',
          height: '4.2vh',
          borderRadius: 60,
          padding: '2px',
        },
      },
    placeholder: {
        width: '50%',
        height: '50%',
        borderRadius: 35,
        [theme.fn.largerThan('md')]: {
            width: '4vh',
            height: '4vh',
            borderRadius: 40,
        },
        h2: {
            color,
            fontSize: 14,
            [theme.fn.largerThan('md')]: {
            fontSize: 15,
            margin: '2px',
            },
        },
    },
    inactiveAvatar: {
        filter: 'brightness(210%)',
    },
}));

interface RoleActivityRowProps {
    key: number;
    date: string;
    tags: Array<any>;
}

const RoleActivityRow = (
    { key, date, tags }: RoleActivityRowProps,
) => {

    const color = useRandomColor(key);
    const { classes } = useStyles({ color });
    const colors = ['pink', 'gray', 'grape', 'violet', 'indigo', 'blue', 'cyan', 'teal', 'green', 'lime'];

    return (
      <Box
          sx={{
              border: '1px solid #DFE1E1',
              borderRadius: '5px',
              marginBottom: '10px',
              padding: '10px 15px',
              position: 'relative',
          }}
      >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar color={colors[Math.floor(Math.random()*colors.length)]} >
                    <div className={tags[0]?.user?.deleted_at ? classes.inactiveAvatar : ''}>
                      {getNameInitials(tags[0]?.user?.name ?? '').toUpperCase()}
                    </div>
                  </Avatar>
                  <Text
                      color={tags[0]?.user?.deleted_at ? '#B3B3B3' : '#242424'}
                      sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      fontFamily: 'Roboto',
                      marginLeft: '4px',
                      paddingLeft: '5px',
                      paddingTop: '5px',
                      }}
                  >
                      {tags[0]?.user?.name ?? ''} {tags[0]?.user?.deleted_at && (<> (Inactive)</>)}
                  </Text>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingRight: '15px' }}>
                  <AiOutlineClockCircle width={'20px'} color={'#C4C4C4'} />
                  <Text color={'#B3B3B3'} sx={{ fontSize: '16px', marginLeft: '6px', paddingTop: '2px' }}>
                      {getTimeinHourOrDayByDate(date)}
                  </Text>
              </div>
          </div>
          <Group spacing="xs" style={{ paddingTop: '10px' }}>
              {tags.map((item: any) => {
                  return <SearchCriteriaTag key={item['id']} item={item} />
              })}
          </Group>
      </Box>
    );

};

export default RoleActivityRow;