import {
  Box,
  Checkbox,
  createStyles, Input,
  Loader,
  LoadingOverlay,
  MultiSelect, NumberInput,
  ScrollArea,
  Select,
  Stack,
  Text
} from '@mantine/core';
import * as z from 'zod';
import React, {useCallback, useEffect, useState} from 'react';
import useDebounce from "../../../../pages/RoleStats/hooks/useDebounce";
import {RolebotClient} from "../../../../api/client";
import {sendGoogleEvent} from "../../../../utils/analytics";
import RolebotSelect from 'components/Inputs/RolebotSelect';
import RolebotNumberInput from 'components/Inputs/RolebotNumberInput';

const useStyles = createStyles(() => ({

  CandidateExperienceWrapper: {
    flexGrow: 1
  },

  GenderDiversityDiv: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 1
  },

  CandidateExperienceDaterangeInputField: {
    width: 60,
    marginRight: 10,
    boxShadow: 'none',
    ':hover': {
      margin: 0,
      boxShadow: `0 0 0 2px #7039ED33`,
      marginRight: 10,
      borderRadius: 5
    },
    ':focus-within': {
      border: '1 px solid #C882FF',
      boxShadow: `0 0 0 2px #7039ED33`,
      marginRight: 10,
      borderRadius: 5
    }
  },

  CandidateExperienceTypeDropdown: {
    width: 150,
    marginRight: 10
  },

  CandidateExperienceTypeDiv: {
    flexDirection: "column"
  },

  RoleSearchCriteriaVariableDiv: {
    // display: "flex",
    marginBottom: 15,
    // flexWrap: 'wrap'
  },

  SuggestedAiRoles: {
    marginBottom: 20
  },

  MultiSelectDiv: {
    // flexGrow: 1
  }

}));

interface SearchCriteriaCandidateExperienceProps {
  onCandidateExperienceChange: (payload: object) => void;
  preferred_experience_min: number;
  preferred_experience_max: number;
  suggestedYearsOfExperience: any;
}

const SearchCriteriaCandidateExperience = ({ onCandidateExperienceChange, preferred_experience_min, preferred_experience_max, suggestedYearsOfExperience }: SearchCriteriaCandidateExperienceProps) => {
  const { classes } = useStyles();
  const [firstRun, setFirstRun] = useState(true);
  const [experienceTypes, setExperienceTypes] = useState([
      "Between",
      "More than"
  ]);

  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  const [availableSuggestedYearsOfExperience, setAvailableSuggestedYearsOfExperience] = useState(suggestedYearsOfExperience ? suggestedYearsOfExperience : null);
  const [selectedExperienceType, setSelectedExperienceType] = useState(availableSuggestedYearsOfExperience ? 'More than' : "Between");


  //In some instances the role info data could be loading and we would need to reset the form to get new values
  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }

  }, []);


  useEffect(() => {

    if ((preferred_experience_min && preferred_experience_max) || (!preferred_experience_min && preferred_experience_max)) {
      setSelectedExperienceType("Between");
      setRangeStart(preferred_experience_min || 0);
      setRangeEnd(preferred_experience_max || 1);
    } else if (preferred_experience_min && !preferred_experience_max) {
      setSelectedExperienceType("More than");
      setRangeStart(preferred_experience_min || 1);
    }

    onCandidateExperienceChange({
      preferred_experience_min,
      preferred_experience_max
    });
    setAvailableSuggestedYearsOfExperience(null);

  }, [preferred_experience_min, preferred_experience_max]);

  useEffect(() => {

    // logic that sends updated data back to role info form
    if (selectedExperienceType === "Between") {
      onCandidateExperienceChange({
        preferred_experience_min: (rangeStart > rangeEnd) ? rangeEnd : rangeStart,
        preferred_experience_max: (rangeStart > rangeEnd) ? rangeStart: rangeEnd
      });
    } else if (selectedExperienceType === "More than") {
      onCandidateExperienceChange({
        preferred_experience_min: rangeStart,
        preferred_experience_max: null
      });
    }

  }, [rangeStart, rangeEnd]);

  const handleExperienceTypeChange = (value: string) => {

    if (value === "Between") {

      setRangeStart(preferred_experience_min || 0);
      setRangeEnd(preferred_experience_max || 1);
    } else if (value === "More than") {
      setRangeStart(preferred_experience_min || 1);
      // @ts-ignore
      setRangeEnd(null);
    }

    setSelectedExperienceType(value);
  };

  return (
      <div className={classes.CandidateExperienceWrapper}>
        <div className={classes.GenderDiversityDiv}>
          <div className={classes.CandidateExperienceTypeDiv}>
            <RolebotSelect
                className={classes.CandidateExperienceTypeDropdown}
                label=""
                placeholder="Pick one"
                //@ts-ignore
                value={selectedExperienceType}
                //@ts-ignore
                data={experienceTypes}
                //@ts-ignore
                onChange={handleExperienceTypeChange}
            />
          </div>
          {(selectedExperienceType === "Between") && ( // If "Between" option is selected
              <>
                <RolebotNumberInput
                    className={classes.CandidateExperienceDaterangeInputField}
                    defaultValue={preferred_experience_min ?? 0}
                    //@ts-ignore
                    onChange={value => setRangeStart(value)}
                    //@ts-ignore
                    placeholder={"0"}
                    min={0}
                    max={99}
                    hideControls
                    styles={{
                      input: {
                        fontSize: '16px'
                      }
                    }}
                />
                <Text size={"md"} color={"#838485"} style={{marginRight: 10, alignSelf: "center"}}>and</Text>
                <RolebotNumberInput
                    className={classes.CandidateExperienceDaterangeInputField}
                    defaultValue={preferred_experience_max}
                    //@ts-ignore
                    onChange={value => setRangeEnd(value)}
                    //@ts-ignore
                    placeholder={"5"}
                    min={1}
                    max={99}
                    hideControls
                    styles={{
                      input: {
                        fontSize: '16px'
                      }
                    }}
                />
                <Text size={"md"} color={"#838485"} style={{marginLeft: 5, alignSelf: "center"}}>years of experience</Text>
              </>
          )}

          {(selectedExperienceType === "More than") && ( // If "More than" option is selected
              <>
                <RolebotNumberInput
                    className={classes.CandidateExperienceDaterangeInputField}
                    defaultValue={preferred_experience_min}
                    //@ts-ignore
                    onChange={value => setRangeStart(value)}
                    //@ts-ignore
                    placeholder={"1"}
                    min={1}
                    max={99}
                    hideControls
                    styles={{
                      input: {
                        fontSize: '16px'
                      }
                    }}
                />
                <Text size={"md"} color={"#242424"} style={{marginLeft: 5, alignSelf: "center"}}>years of experience</Text>
              </>
          )}
        </div>
      </div>
  );
};

export default SearchCriteriaCandidateExperience;
