
const OktaRedirect = () => {
    const baseURL = process.env.REACT_APP_API_URL;

    const queryString = window.location.search;
    window.location.href = baseURL + '/auth/okta/redirect' + queryString + '&temp_oauth=true';

    return null
};
export default OktaRedirect;
