import React from "react";
import classes from './CustomIcons.css';
import {renderToStaticMarkup} from 'react-dom/server';

const buildIcon = (svg, props) => {
    const svgString = encodeURIComponent(renderToStaticMarkup(svg));

    const propsSubmitted = {
        ...props,
        style: {
            ...props.style,
            background: `url("data:image/svg+xml,${svgString}")`
        },
        className: !!props.className ? props.className : classes.Icon
    }

    return <div {...propsSubmitted}/>;
};

const MetricsSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.54 18.54">
            <g>
                <circle cx="9.27" cy="9.27" r="8.77"
                        style={{fill: "none", stroke: `${color}`, strokeMiterlimit: "10"}}/>
                <rect x="11.41" y="3.83" width="1.99" height="10.01" rx="0.08" style={{fill: `${color}`}}/>
                <rect x="8.13" y="6.57" width="1.99" height="7.28" rx="0.08" style={{fill: `${color}`}}/>
                <rect x="4.86" y="8.64" width="1.99" height="5.2" rx="0.08" style={{fill: `${color}`}}/>
            </g>
        </svg>
    );
}

const CompletedSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.54 18.54">
            <g>
                <circle cx="9.27" cy="9.27" r="8.77"
                        style={{fill: "none", stroke: `${color}`, strokeMiterlimit: "10"}}/>
                <polyline points="5.3 10.52 8.5 13.49 13.43 4.66"
                          style={{fill: "none", stroke: `${color}`, strokeMiterlimit: "10"}}/>
            </g>
        </svg>
    );
};

const ArrowCircleSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.54 18.54">
            <g>
                <circle cx="9.27" cy="9.27" r="8.77"
                        style={{fill: "none", stroke: `${color}`, strokeMiterlimit: "10"}}/>
                <path
                    d="M10.37,5.54c-.35.38-.72.76-1.08,1.14a.61.61,0,0,1-.53.21.57.57,0,0,1-.47-.37.56.56,0,0,1,.12-.6L9,5.28a3.39,3.39,0,0,0-1.17.26A4.06,4.06,0,0,0,5.2,8.83a4.12,4.12,0,0,0,1,3.35l.21.22L5.31,13A5.34,5.34,0,0,1,8.23,4.21l.68-.11L8.85,4l-.49-.48a.58.58,0,1,1,.83-.82l1.57,1.5C11.33,4.76,10.37,5.54,10.37,5.54Z"
                    style={{fill: `${color}`}}/>
                <path
                    d="M8,13.43l1.09-1.14a.59.59,0,0,1,.53-.21.58.58,0,0,1,.35,1c-.19.21-.38.4-.61.64a3.4,3.4,0,0,0,1.18-.26,4.17,4.17,0,0,0,1.65-6.64L12,6.57,13.11,6a5.36,5.36,0,0,1,1.26,4.3,5.3,5.3,0,0,1-4.18,4.43c-.23.05-.45.08-.69.12l.06.07c.17.16.33.31.49.48a.58.58,0,1,1-.82.82c-.52-.5-1-1-1.57-1.5S8,13.43,8,13.43Z"
                    style={{fill: `${color}`}}/>
            </g>
        </svg>
    );
};

const AvatarSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.61 20.61">
            <g>
                <g>
                    <circle cx="10.3" cy="10.3" r="9.8" style={{fill: 'none', stroke: `${color}`, strokeMiterlimit: 10}}/>
                    <path
                        d="M10.15,15.91H4.37a.83.83,0,0,1-.82-.65.86.86,0,0,1,0-.16c0-.77,0-1.54,0-2.31a2.85,2.85,0,0,1,.65-1.74A3,3,0,0,1,6.09,9.91a3.29,3.29,0,0,1,.54-.05h7A3.11,3.11,0,0,1,16.77,13c0,.69,0,1.38,0,2.07a.84.84,0,0,1-.84.84Z"
                        style={{fill: `${color}`}}/>
                    <path
                        d="M10,9.46A3.2,3.2,0,0,1,7.74,8.37a3.47,3.47,0,0,1-.88-1.76,3.67,3.67,0,0,1,.72-3.06A3.16,3.16,0,0,1,9.77,2.3a3.06,3.06,0,0,1,2,.39,3.52,3.52,0,0,1,1.72,2.42,3.58,3.58,0,0,1,.06,1.13,3.75,3.75,0,0,1-.7,1.84A3.28,3.28,0,0,1,10,9.46Z"
                        style={{fill: `${color}`}}/>
                    <path d="M15.13,13a2.17,2.17,0,1,1-2.39-2.08A2.17,2.17,0,0,1,15.13,13Z" style={{fill: '#ffffff'}}/>
                    <path
                        d="M12.86,13.56l.49-.75c.13-.19.26-.39.41-.58a.32.32,0,0,1,.42,0,.28.28,0,0,1,.07.39l-.39.59-.64,1a.41.41,0,0,1-.3.21.28.28,0,0,1-.25-.09l-.92-1a.31.31,0,0,1,0-.49.28.28,0,0,1,.4,0c.13.14.26.29.4.43Z"
                        style={{fill: `${color}`}}/>
                </g>
            </g>
        </svg>
    );
};

const HandShakeSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.61 20.61">
            <g>
                <path
                    d="M7.05,7.13c0,1,0,1.78,0,2.56,0,1.39.7,2.22,1.84,2.23s1.86-.81,1.87-2.19v-1c1.29,0,2.52,0,3.74,0a.62.62,0,0,1,.46.42c0,.57.11,1.41-.2,1.64a1.29,1.29,0,0,0-.5,1.28c0,.7,0,1.31-1,1.36-.1,0-.22.23-.27.37A1.69,1.69,0,0,1,11.18,15c-.17,0-.35,0-.53,0a6.47,6.47,0,0,0-4.73,1.22c-.89.65-1.15.51-1.72-.47L2.61,13A.87.87,0,0,1,3,11.64a5.45,5.45,0,0,1,.62-.35A1.66,1.66,0,0,0,4.79,9.43C4.73,8,6.05,7.86,7.05,7.13Z"
                    style={{fill: `${color}`}}/>
                <path
                    d="M9.84,8c0,.68,0,1.32,0,2s-.27,1-.94,1A.85.85,0,0,1,8,10.06c0-1,0-2,0-3C8,6.48,9,5.66,9.82,5.55H10a6.49,6.49,0,0,0,4.94-1.39c.54-.4,1-.2,1.31.36.58,1,1.17,2,1.76,3.07a.84.84,0,0,1-.35,1.33c-.54.29-1.06.6-1.52.86-.26-.56-.36-1.24-.75-1.52a3.51,3.51,0,0,0-1.74-.38c-1.15,0-2.3,0-3.45,0A1.57,1.57,0,0,0,9.84,8Z"
                    style={{fill: `${color}`}}/>
                <circle cx="10.3" cy="10.3" r="9.8" style={{fill:'none', stroke: `${color}`, strokeMiterlimit : '10'}}/>
            </g>
        </svg>
    );
};

const BoltSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.61 20.61">
            <g>
                <circle cx="10.3" cy="10.3" r="9.8" style={{fill:'none', stroke: `${color}`, strokeMiterlimit: '10'}}/>
                <path
                    d="M9.59,18.07c-.36-.17-.32-.33-.29-.65s.09-.81.14-1.22.12-1,.17-1.51.09-.81.14-1.21.09-.83.14-1.24c0-.1,0-.2,0-.31h-3a.47.47,0,0,1-.45-.73c.82-1.65,1.65-3.3,2.47-5l1.72-3.45A.68.68,0,0,1,11,2.43h.18a.46.46,0,0,1,.33.57c0,.38-.08.77-.13,1.15s-.09.81-.14,1.22c-.07.58-.14,1.16-.2,1.74,0,.4-.09.79-.14,1.19,0,.18,0,.35-.06.55h3.11c.42,0,.58.26.39.64L11.51,15c-.46.89-.92,1.79-1.37,2.69a.79.79,0,0,1-.33.37Z"
                    style={{fill: `${color}`}}/>
            </g>
        </svg>
    );
};

const BulbSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.61 20.61">
            <g>
                <circle cx="10.3" cy="10.3" r="9.8" style={{fill:'none', stroke: `${color}`, strokeMiterlimit: 10}}/>
                <path d="M9.51,15.67h1.91A1.13,1.13,0,0,1,9.51,15.67Z" style={{fill: `${color}`}}/>
                <path
                    d="M11.31,13.63a3,3,0,0,1,.56-1.69,15.08,15.08,0,0,0,.85-1.44,1.94,1.94,0,0,0-1.2-2.7,2.63,2.63,0,0,0-3.27,1,2.12,2.12,0,0,0,.18,2.05c.34.59.72,1.16,1,1.77a4.58,4.58,0,0,1,.26,1H8.93a3.44,3.44,0,0,0-.78-1.79A4.33,4.33,0,0,1,7.33,10c-.17-1.81,1.29-3.13,3.32-3,1.85.1,3.07,1.23,3,2.79A3.71,3.71,0,0,1,13,11.6a4.32,4.32,0,0,0-1,2Z"
                    style={{fill: `${color}`}}/>
                <path
                    d="M10.47,14h1c.2,0,.37.05.34.24a.41.41,0,0,1-.32.24c-.69,0-1.39,0-2.09,0-.11,0-.21-.16-.32-.24.11-.08.21-.22.33-.23C9.78,14,10.12,14,10.47,14Z"
                    style={{fill: `${color}`}}/>
                <path
                    d="M10.46,15.33c-.32,0-.64,0-1,0a.45.45,0,0,1-.33-.22c0-.19.14-.27.33-.27.66,0,1.31,0,2,0a.46.46,0,0,1,.33.23c.05.19-.13.26-.33.26Z"
                    style={{fill: `${color}`}}/>
                <path d="M6.7,7.51l-.48.68L4.89,7.24l.48-.68Z" style={{fill: `${color}`}}/>
                <path d="M5.07,13.26l-.35-.76,1.49-.68.36.76Z" style={{fill: `${color}`}}/>
                <path d="M13.92,7.52l1.33-1,.49.68-1.34,1Z" style={{fill: `${color}`}}/>
                <path d="M15.89,12.49l-.32.77-1.49-.6.3-.77Z" style={{fill: `${color}`}}/>
                <path d="M10.72,5.87H10V4.41h.77Z" style={{fill: `${color}`}}/>
            </g>
        </svg>
    );
};

const HelpSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.48 13.48">
            <g>
                <g>
                    <circle cx="6.74" cy="6.74" r="6.74" style={{fill: `${color}`}}/>
                    <text transform="translate(4.31 9.52)"
                          style={{
                              fontSize: '9.210530281066895px',
                              fill: '#fff',
                              fontFamily: 'CircularStd-Bold, CircularStd Bold',
                              fontWeight: '700'}}>?
                    </text>
                </g>
            </g>
        </svg>
    );
};

const CheckSvg = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.64 16.64">
            <g>
                <g>
                    <circle cx="8.32" cy="8.32" r="7.82" style={{fill: 'none', stroke:`${color}`, strokeMiterlimit: '10'}}/>
                    <polyline points="4.78 9.43 7.63 12.08 12.02 4.21"
                              style={{fill:'none', stroke:`${color}`, strokeMiterlimit: '10'}}/>
                </g>
            </g>
        </svg>
    );
};



export const MetricCircle = (props) => {
    return buildIcon(<MetricsSvg color={props.color}/>, props);
};

export const CompletedIcons = (props) => {
    return buildIcon(<CompletedSvg color={props.color}/>, props);
};

export const ArrowCircles = (props) => {
    return buildIcon(<ArrowCircleSvg color={props.color}/>, props);
};

export const Avatar = (props) => {
    return buildIcon(<AvatarSvg color={props.color}/>, props);
};

export const HandShake = (props) => {
    return buildIcon(<HandShakeSvg color={props.color}/>, props);
};

export const Bolt = (props) => {
    return buildIcon(<BoltSvg color={props.color}/>, props);
};

export const Bulb = (props) => {
    return buildIcon(<BulbSvg color={props.color}/>, props);
};

export const Help = (props) => {
    return buildIcon(<HelpSvg color={props.color}/>, props);
};

export const CheckCircle = (props) => {
    return buildIcon(<CheckSvg color={props.color}/>, props);
};

MetricCircle.defaultProps = {
    color: '#000000',
    style: {}
};

CompletedIcons.defaultProps = {
    color: '#000000',
    style: {}
};

ArrowCircles.defaultProps = {
    color: '#000000',
    style: {}
};

Avatar.defaultProps = {
    color: '#000000',
    style: {}
};

HandShake.defaultProps = {
    color: '#000000',
    style: {}
};

Bolt.defaultProps = {
    color: '#000000',
    style: {}
};

Bulb.defaultProps = {
    color: '#000000',
    style: {}
};

Help.defaultProps = {
    color: '#000000',
    style: {}
};
