import React from 'react';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  wrapper: {
    // backgroundColor: 'yellow',
    marginTop: '20px',
    flexGrow: 1,
    height: '100%',
    overflowY: 'unset',
    [theme.fn.largerThan('md')]: {
      maxHeight: '200px',
      overflowY: 'auto',
    }
  }
}));

interface BodyWrapperProps {
  children: React.ReactNode;
}

const BodyScrollWrapper = ({children}: BodyWrapperProps) => {

  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      {children}
    </div>
  );
};

export default BodyScrollWrapper;