import React from 'react';
import { createStyles } from '@mantine/core';

import { ILaravelError, ILaravelFormError } from '../../types';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
    marginTop: '20px',
    color: '#E43F3F',
    border: 'double 1px lightcoral',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  ul: {
    marginTop: '2px',
    marginBottom: '2px',
  },
}));

interface FormSubmitServerErrorProps {
  formError: ILaravelFormError;
}

const buildErrorsArray = (errors: ILaravelError[]): string[] => {
  const errorArray: string[] = [];

  const errorKeys = Object.keys(errors);
  for (const errorKey of errorKeys) {
    //this ignore is here because typescript does not like me using a string as a key in an array
    //the alternative is to add something to the main config file to tell typescript to ignore this = booooo
    // @ts-ignore
    errors[errorKey].forEach((error: string) => {
      errorArray.push(error);
    });
  }
  return errorArray;
};

const FormSubmitServerErrors = ({ formError }: FormSubmitServerErrorProps) => {
  const { classes } = useStyles();
  const errors = buildErrorsArray(formError.errors);

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <HiOutlineExclamationCircle />
        {formError.message}
      </div>
      {errors.length > 0 && (
        <ul className={classes.ul}>
          {errors.map((x, i) => (
            <li key={i.toString()}>{x}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormSubmitServerErrors;
