import React from 'react';
import { createStyles, Divider, Group, MediaQuery, Menu, Title, UnstyledButton } from '@mantine/core';
import { Dots } from 'tabler-icons-react';
import moment from 'moment';
import { IRole } from 'types';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { toggleRoleMenu } from 'features/app/appSlice';
import useOpenModal from 'hooks/useOpenModal';
import { useClickOutside } from '@mantine/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faUserPlus,
  faArrowDownToLine,
  faCirclePlay,
  faXmark,
  faCirclePause,
} from '@fortawesome/pro-light-svg-icons';
import { sendGoogleEvent } from 'utils/analytics';
import { RolebotClient } from 'api/client';
import { useGetCompanyQuery } from 'app/services/rolebot';

const useStyles = createStyles((theme) => ({
  body: {
    width: '95vw',
    [theme.fn.largerThan('sm')]: {
      width: 220,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  itemBody: {
    justifyContent: 'center',
    [theme.fn.largerThan('sm')]: {
      justifyContent: 'left',
    },
  },

  title: {
    fontSize: theme.fontSizes.md,
    justifyContent: 'center',
    [theme.fn.largerThan('sm')]: {
      justifyContent: 'left',
    },
  },

  hidden: {
    display: 'none',
    [theme.fn.largerThan('sm')]: {
      display: 'flex',
    },
    height: '16px',
    width: '16px',
    paddingBottom: '2px'
  },
  icon: {
    height: '16px',
    width: '16px',
    paddingBottom: '2px'
  },
  reactivateRole: {
    height: '16px',
    width: '16px',
    paddingBottom: '2px'
  }
}));

const RoleMenu = ({
  role,
  roleId,
  roleName,
}: {
  role?: IRole;
  roleId: string;
  roleName: string;
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const roleMenuOpen = useAppSelector((state) => state.app.roleMenuOpen);
  const { pauseRole, resumeRole, roleSetup, closeRole, openRole, roleLimitReachedModal } = useOpenModal();
  const elem = document.getElementById('sidebar-right-content');
  const ref = useClickOutside(() => dispatch(toggleRoleMenu(false)), [], [elem!]);
  const { data } = useGetCompanyQuery();

  const showAdditionalEmails = role?.sourcing_only && (data?.enable_contact_out || data?.enable_rocket_reach);

  const handleClickRoleSetup = () => {
    roleSetup({
      roleId,
      roleName,
      showInfo: false,
    });
    dispatch(toggleRoleMenu(false));
  };

  const handleResumeRole = () => {
    resumeRole({
      roleName: role!.name,
      roleId: role!.id,
    });
    dispatch(toggleRoleMenu(false));
  };

  const handleOpenRoleAdmins = () => {
    roleSetup({
      roleId,
      roleName,
      initialStep: role?.sourcing_only ? 4: 6,
      showInfo: false,
    });
    dispatch(toggleRoleMenu(false));
  };

  const renderPauseRestartButton = () => {
    if (role?.completed) return null;
    if (role?.on_hold) {
      // @ts-ignore
      return (
        <Menu.Item onClick={handleResumeRole} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
          <Group spacing={12} align={'center'}>
            <FontAwesomeIcon icon={faCirclePlay} className={classes.hidden} />
            Resume Role
          </Group>
        </Menu.Item>
      );
    }
    return (
      <Menu.Item onClick={handlePauseRole} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
        <Group spacing={12} align={'center'}>
          <FontAwesomeIcon icon={faCirclePause} className={classes.hidden} />
          Pause Role
        </Group>
      </Menu.Item>
    );
  };

  const handlePauseRole = () => {
    sendGoogleEvent('User', 'Role settings pause close role clicked');
    pauseRole({
      roleName: role?.name || roleName,
      roleId: role?.id || roleId,
    });
    dispatch(toggleRoleMenu(false));
  };

  const handleCloseRole = () => {
    sendGoogleEvent('User', 'Role settings request close role clicked');
    closeRole({
      roleName: role?.name || roleName,
      roleId: role?.id || roleId,
      withCloseButton: true,
      withTitle: true,
      reload: false,
    });
    dispatch(toggleRoleMenu(false));
  };

  const handleOpenRole = () => {
    if (data?.active_role_limit && data?.active_role_count >= data?.active_role_limit) {
      roleLimitReachedModal({ company: data });
    } else {
      sendGoogleEvent('User', 'Role settings open role clicked');
      openRole({
        roleName: role?.name || roleName,
        roleId: role?.id || roleId,
        withCloseButton: true,
        withTitle: true,
        reload: false,
      });
    }

    dispatch(toggleRoleMenu(false));
  };

  const getTalentList = async () => {
    try {

      let areMoreTalent = true;
      let page = 1;
      let totalCount = 0;
      let talentList: any[] = [];

      while (areMoreTalent) {

        const request = {
          'talent-filter-type': 'all',
          'page': page,
          'per_page': 30,
        };

        const { data } = await RolebotClient.get('/roles/' + roleId + '/talent', {	params: request	})

        const talent = data.data;
        const total = data.total;

        if (page === 1) {
          totalCount = total;
        }

        talentList = talentList.concat(talent);

        if (talentList.length >= totalCount) {
          areMoreTalent = false;
        }

        page++;
      }

      return talentList;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleExportTalentClicked = async () => {
    sendGoogleEvent('User', 'Role settings export clicked');

    const talent = await getTalentList();

    const rows = [['name', 'location', 'current job title', 'link', 'diverse', 'accepted', 'skipped reason', 'outcome', 'interview date', 'talent emails', 'talent phones']];

    // @ts-ignore
    const payload = rows.concat(
      talent.map((x: any) => {
        let acceptedStatus;
        switch (true) {
          case x.pivot.accepted === true:
            acceptedStatus = 'accepted';
            break;
          case x.pivot.accepted === false:
            acceptedStatus = 'declined';
            break;
          default:
            acceptedStatus = x.pivot.skipped_reason !== null ? 'skipped' : 'waiting';
        }

        let skippedReason;
        switch (true) {
          case x.pivot.skipped_reason !== null:
            skippedReason = x.pivot.skipped_reason;
            break;
          default:
            skippedReason = null;
        }

        let outcome;
        switch (true) {
          case x.pivot.outcome_id === 1:
            outcome = 'Happy in role';
            break;
          case x.pivot.outcome_id === 2:
            outcome = 'Commute Distance';
            break;
          case x.pivot.outcome_id === 3:
            outcome = 'Comp Differential';
            break;
          case x.pivot.outcome_id === 4:
            outcome = 'No Response';
            break;
          case x.pivot.outcome_id === 5:
            outcome = 'Not Interested';
            break;
          case x.pivot.outcome_id === 6:
            outcome = 'Interview';
            break;
          case x.pivot.outcome_id === 7:
            outcome = 'Requires visa Sponsorship';
            break;
          case x.pivot.outcome_id === 8:
            outcome = 'Seeking remote opportunities';
            break;
          case x.pivot.outcome_id === 9:
            outcome = 'Seeking hybrid opportunities';
            break;
          case x.pivot.outcome_id === 10:
            outcome = 'Not interested in relocating';
            break;
          default:
            outcome = null;
        }

        let interviewDate;
        switch (true) {
          case x.interviews.length > 0:
            interviewDate = moment.utc(x.interviews[x.interviews.length - 1].interview_at).format();
            break;
          default:
            interviewDate = null;
        }

        let isDiverse;
        switch (true) {
          case x.is_diverse === true:
            isDiverse = 'yes';
            break;
          case x.is_diverse === false:
            isDiverse = 'no';
            break;
          default:
            isDiverse = 'unknown';
        }

        let talentCurrentJobTitle = x?.job_titles[0] ? x.job_titles[0].name : "";
        let talentLocation = x.talent_info?.location ? `"${x.talent_info.location}"` : "";

        if ((outcome !== 'Interview') || (interviewDate !== null)) {
          const contactOut = x.contact_out_email;
          const rocketReach = x.rocket_reach_email;
          const rolebotEmail = x.email;
          const mobileNumber = x.mobile_number;
          const altNumber = x.alt_number;
          
          let talentEmails: string[] = [];
          let talentNumbers = [];
          
          if (data?.enable_contact_out && contactOut?.length && showAdditionalEmails) talentEmails.push(...contactOut);
          if (data?.enable_rocket_reach && rocketReach?.length && showAdditionalEmails) talentEmails.push(...rocketReach);
          if (rolebotEmail) talentEmails.push(rolebotEmail);
          const uniqueEmails = [...new Set(talentEmails)];

          if (mobileNumber) talentNumbers.push(mobileNumber);
          if (altNumber) talentNumbers.push(altNumber);
          const uniqueNumbers = [...new Set(talentNumbers)];
          
          return [
            x.name,
            talentLocation,
            talentCurrentJobTitle,
            x.link,
            isDiverse,
            acceptedStatus,
            skippedReason,
            outcome,
            interviewDate,
            (uniqueEmails.length > 0 && acceptedStatus !== 'waiting') ? [ '\"' + uniqueEmails.join(',') + '\"'] : [],
            (uniqueNumbers.length > 0 && acceptedStatus !== 'waiting') ? [ '\"' + uniqueNumbers.join(',') + '\"'] : [],
          ];
        }

        return [
          x.name,
          x.link,
          isDiverse,
          acceptedStatus,
          skippedReason,
          outcome,
          interviewDate,
          "",
          ""
        ];
      })
    );

    let csvContent = payload.map((e) => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      // @ts-ignore
      link.setAttribute('download', `${role.name.split(' ').join('_')}_talent_export.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Menu
      ref={ref}
      opened={roleMenuOpen}
      onOpen={() => dispatch(toggleRoleMenu(true))}
      onClose={() => dispatch(toggleRoleMenu(false))}
      withinPortal={false}
      control={
        <UnstyledButton
          style={{
            borderRadius: 40,
            padding: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Dots color={roleMenuOpen ? '#351B77' : '#B3B3B3'} size={20} />
        </UnstyledButton>
      }
      classNames={{
        body: classes.body,
        itemBody: classes.itemBody,
      }}
      position={'bottom'}
      placement={'end'}
      withArrow
    >
      <MediaQuery styles={{ display: 'none' }} largerThan={'sm'}>
        <Menu.Item sx={{ pointerEvents: 'none' }}>
          <Title className={classes.title}>Role Settings</Title>
        </Menu.Item>
      </MediaQuery>
      <MediaQuery largerThan={'sm'} styles={{ display: 'none' }}>
        <Divider />
      </MediaQuery>
      <Menu.Item onClick={handleClickRoleSetup} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
        <Group spacing={12}>
          <FontAwesomeIcon icon={faInfoCircle} className={classes.hidden} />
          Role Info
        </Group>
      </Menu.Item>
      <Menu.Item onClick={handleOpenRoleAdmins} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
        <Group spacing={12}>
          <FontAwesomeIcon icon={faUserPlus} className={classes.hidden} />
          Role Members
        </Group>
      </Menu.Item>
      <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
        <Divider />
      </MediaQuery>
      <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
        <Menu.Item onClick={handleExportTalentClicked} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
          <Group spacing={12}>
            <FontAwesomeIcon icon={faArrowDownToLine} className={classes.hidden} />
            Export
          </Group>
        </Menu.Item>
      </MediaQuery>
      {role?.completed ? null : <Divider />}
      {renderPauseRestartButton()}
      {role?.completed && !role?.role_requests?.find((x) => x.type === 'open') ? (
        <Menu.Item onClick={handleOpenRole} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
          <Group>
            <FontAwesomeIcon icon={faCirclePlay} className={classes.reactivateRole}/>
            Reactivate Role
          </Group>
        </Menu.Item>
      ) : null}
      {role?.completed ? null : (
        <Menu.Item onClick={handleCloseRole} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
          <Group spacing={8}>
            <FontAwesomeIcon icon={faXmark} className={classes.hidden} />
            Close Role
          </Group>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default RoleMenu;