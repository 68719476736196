import { ButtonProps, Button as MantineButton } from '@mantine/core';

export default function RolebotButton(props: ButtonProps<any>) {
  return (
    <MantineButton
      styles={(theme) => ({
        root: {
          borderRadius: 6,
          paddingLeft: 14,
          paddingRight: 14,
          transition: '0.2s',
        },
        filled: {
          backgroundColor: '#7039ED',
          '&:hover': {
            backgroundColor: theme.fn.rgba('#522BB5', 1),
          },
          '&:disables' : {
            backgroundColor: theme.fn.rgba('#7039ED', 0.5)
          },
          '&:active' : {
            backgroundColor: theme.fn.rgba('#351B77', 1)
          }
        },
      })}
      {...props}
    >
      {props.children}
    </MantineButton>
  );
}
