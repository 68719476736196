import React, { useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import WidgetWrapper from '../WidgetWrapper/WidgetWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { createStyles, Popover, Text } from '@mantine/core';

const useStyles = createStyles(() => ({
  DangerIcon: {
    position: 'absolute',
    top: '10px',
    left: '15px',
  },

  Help: {
    display: 'inline-block',
    position: 'relative',
    marginLeft: '3px',
    top: '2px',
  },

  GraphWrapper: {
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '130px',
  },

  Score: {
    textAlign: 'center',
    fontSize: '35px',
    position: 'absolute',
  },

  Title: {
    marginBottom: '15px',
  },

  BottomWrapper: {
    fontSize: '13px',
  },
}));

const PercentSmallWidget = (props) => {
  const data = [
    {
      value: props.percent,
      color: props.color,
    },
    {
      value: 100 - props.percent,
      color: 'lightgrey',
    },
  ];

  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);

  const popover = (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      position="top"
      placement="start"
      withArrow
      trapFocus={false}
      closeOnEscape={false}
      transition="pop-top-left"
      width={260}
      styles={{ body: { pointerEvents: 'none' } }}
      target={
        <FontAwesomeIcon
          onMouseEnter={() => setOpened(true)}
          onMouseLeave={() => setOpened(false)}
          icon={faQuestionCircle}
          style={{
            cursor: 'pointer',
            marginRight: '4px',
            display: 'inlineBlock',
            color: 'cornflowerblue',
          }}
        />
      }
    >
      <div>
        <Text size="md" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          {props.section1}
        </Text>
        <Text size="sm">{props.helpMessage}</Text>
      </div>
    </Popover>
  );

  return (
    <WidgetWrapper>
      <div>
        <div className={classes.Icon}>{props.icon}</div>
        <div className={classes.GraphWrapper}>
          <div className={classes.Score}>{props.percent ? props.percent : 0}%</div>
          <ResponsiveContainer width={150} height={150}>
            <PieChart>
              <Pie
                dataKey="value"
                data={data}
                innerRadius={60}
                outerRadius={70}
                startAngle={90}
                endAngle={-270}
                paddingAngle={1}
              >
                {data.map((x, index) => (
                  <Cell key={index} fill={x.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className={classes.Title}>
          {props.section1}
          <div className={classes.Help}>{popover}</div>
        </div>
        <div className={classes.BottomWrapper}>
          <div>{props.section2}</div>
          <div>{props.section3}</div>
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default PercentSmallWidget;
