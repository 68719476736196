import { Alert, Button, createStyles, Group, List, Stack, Text } from '@mantine/core';
import { useCloseRoleMutation } from 'app/services/rolebot';
import { useEffect } from 'react';
import { ContextModalProps, useModals } from '@mantine/modals';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { CloseRoleProps } from 'types/modals';

const useStyles = createStyles((theme) => ({
  container: {
    justifyContent: 'center',
    [theme.fn.largerThan('md')]: {
      justifyContent: 'center',
    },
  },

  bottomButtons: {
    marginTop: 'auto',
    button: {
      ':first-child': {
        order: 2,
      },
      ':last-child': {
        order: 1,
      },
    },
    [theme.fn.largerThan('md')]: {
      marginTop: 30,
      flexGrow: 0,
      flexDirection: 'row',
      button: {
        ':first-child': {
          order: 1,
        },
        ':last-child': {
          order: 2,
        },
      },
    },
  },
}));

const CloseRole = ({ id, innerProps }: ContextModalProps<CloseRoleProps>) => {
  const { roleId, roleName, reload } = innerProps;
  const { classes } = useStyles();
  const [closeRole, { isLoading, isSuccess, isError }] = useCloseRoleMutation();
  const modals = useModals();

  const handleClose = () => {
    modals.closeModal(id);
  };

  const handleCloseRole = async () => {
    await closeRole({
      roleId: roleId,
      payload: {
        completed: true,
      },
      reload: reload
    }).unwrap()
    .then((response) => {
      const role = response.role;
      if (role && innerProps.onPatchCompleted) {
        innerProps.onPatchCompleted('closeRole', role.id);
      }
    });
  };

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
// eslint-disable-next-line
  }, [isSuccess]);

  return (
    <>
      <TitleWithClose id={id} title={'Close Role'} mb={20} />
      <>
        <Stack>
          <Text color={'#242424'}>
            Do you want to close the <strong style={{ fontFamily: 'Roboto', fontWeight: 500 }}>{roleName}</strong> role?
          </Text>
          <Text color={'#242424'}>This action will:</Text>
          <List withPadding styles={{ item: { color: '#242424' } }}>
            <List.Item>Mark this role as completed in Rolebot</List.Item>
            <List.Item>Stop Rolebot from surfacing new rounds of talent</List.Item>
            <List.Item>Stop Rolebot’s engagement with all active candidates</List.Item>
          </List>
        </Stack>
        {isError && (
          <Alert title={'Oops, something went wrong!'} color={'orange'}>
            We were not able to close this Role. Please try again.
          </Alert>
        )}
        <Group position={'right'} className={classes.bottomButtons}>
          <Button onClick={handleClose} disabled={isLoading} variant={'outline'}>
            Cancel
          </Button>
          <Button px={40} onClick={handleCloseRole} loading={isLoading} color={'red'}>
            Close Role
          </Button>
        </Group>
      </>
    </>
  );
};

export default CloseRole;
