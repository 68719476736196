import { faSuitcase, faChartSimple } from '@fortawesome/pro-light-svg-icons';
/*
 * Object that renders the navbar icons and links.
 * Not related to the core router implementation
 * */

export const navbarRoutes = [
  {
    icon: faSuitcase,
    label: 'Roles',
    to: '/roles',
    links: [
      { label: 'Active Roles', link: '/roles?status=active' },
      { label: 'Completed Roles', link: '/roles?status=completed' },
    ],
  },
  { icon: faChartSimple, label: 'Metrics', to: '/metrics' },
];
