import React from 'react';
import { Group, Image, Popover, Text } from '@mantine/core';
import GreenhouseIcon from 'assets/img/greenhouse_icon.svg';
import LeverIcon from 'assets/img/lever_icon.svg';
import SapIcon from 'assets/img/logos/sap_logo.svg';
import IcimsIcon from 'assets/img/icims_wordmark.png';

interface IntegrationIconProps {
  reqId?: number | string;
  type: 'greenhouse' | 'lever' | 'sap' | 'icims';
}

const assets = {
  greenhouse: GreenhouseIcon,
  lever: LeverIcon,
  sap: SapIcon,
  icims: IcimsIcon
};

const IntegrationIcon = ({ reqId, type }: IntegrationIconProps) => {
  const [opened, setOpened] = React.useState(false);

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      position="top"
      placement="start"
      trapFocus={false}
      closeOnEscape={false}
      transition="pop-top-left"
      target={
        <Image
          src={assets[type]}
          width={type === 'sap' ? 16 : type === 'lever' ? 15 : type === 'icims' ? 35 : 10}
          onMouseEnter={() => setOpened(true)}
          onMouseLeave={() => setOpened(false)}
        />
      }
      styles={{
        inner: {
          display: 'flex',
          padding: '8px 16px',
        },
        body: {
          width: 'auto',
          backgroundColor: '#000',
          color: '#FFF',
        },
      }}
    >
      <Group>
        <Text size="sm">
          {type === 'greenhouse' && `Requisition ID: ${reqId}`}
          {type === 'icims' && `Requisition ID: ${reqId}`}
          {type === 'lever' && (reqId && reqId !== '' ? `Requisition ID: ${reqId}` : 'Connected to Lever')}
          {type === 'sap' && (reqId && reqId !== '' ? `Requisition ID: ${reqId}` : 'Connected to SAP SuccessFactors')}
        </Text>
      </Group>
    </Popover>
  );
};

export default React.memo(IntegrationIcon);
