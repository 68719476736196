import RolebotBadge from '../RolebotBadge';
import { Group } from '@mantine/core';
import React from 'react';
import { IRole } from 'types';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { nanoid } from 'nanoid';

interface RoleBadgesProps extends IRole {
  id: number;
  minimal?: boolean;
  className?: string;
  talent_to_review?: number;
}

const RoleBadges = ({
  on_hold,
  show_info,
  needs_info_update,
  completed,
  role_requests,
  minimal = false,
  className,
  id,
  talent_to_review = 0,
}: RoleBadgesProps) => {
  const { data } = useGetCompanyQuery();

  const isWaiting = (type: 'open' | 'close') => {
    return role_requests.length > 0 && role_requests.find((x) => x.type === type);
  };

  let badges = [];

  // if we don't have stripe connected already and is not any kind of the above
  if (data?.force_collect_card && data?.stripe_id === null) {
    badges.push(
      <RolebotBadge key={`${nanoid(6)}-card-required`} variant={'filled'} color={'#46209E'} backgroundColor={'#EEEEEE'}>
        Card Required
      </RolebotBadge>
    );
  }

  if (minimal && talent_to_review > 0)
    badges.push(
      <RolebotBadge
        key={`${nanoid(6)}-talent-to-review`}
        variant={'filled'}
        color={'#46209E'}
        backgroundColor={'#EEEEEE'}
      >
        {talent_to_review} new
      </RolebotBadge>
    );

  if (on_hold)
    badges.push(
      <RolebotBadge key={`${nanoid(6)}-on-hold`} variant={'filled'} color={'#46209E'} backgroundColor={'#EEEEEE'}>
        On Hold
      </RolebotBadge>
    );

  if (show_info || needs_info_update)
    badges.push(
      <RolebotBadge
        key={`${nanoid(6)}-waiting-for-setup`}
        variant={'filled'}
        color={'#46209E'}
        backgroundColor={'#EEEEEE'}
      >
        {needs_info_update ? 'Update Required' : 'Waiting for Setup'}
      </RolebotBadge>
    );

  if (!completed && isWaiting('close'))
    badges.push(
      <RolebotBadge
        key={`${nanoid(6)}-closure-pending`}
        variant={'filled'}
        color={'#46209E'}
        backgroundColor={'#EEEEEE'}
      >
        Closure Pending
      </RolebotBadge>
    );

  if (role_requests?.find((x) => x.type === 'open'))
    badges.push(
      <RolebotBadge
        key={`${nanoid(6)}-closure-pending`}
        variant={'filled'}
        color={'#46209E'}
        backgroundColor={'#EEEEEE'}
      >
        Reactivation Pending
      </RolebotBadge>
    );

  if (minimal && badges.length > 1) {
    badges = [
      <div className={className} key={`${nanoid(6)}-more-badges`}>
        <RolebotBadge color={'#FFF'} backgroundColor={'#D7378C'} variant={'filled'}>
          {badges.length} new
        </RolebotBadge>
      </div>,
    ];
  }

  return (
    <Group spacing={'xs'} noWrap className={className}>
      {badges}
    </Group>
  );
};
export default RoleBadges;
