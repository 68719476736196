import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

const calculateMaxPages = (total: number, size: number) => {
  return Math.ceil(total / size);
};

export const isValidNotEmptyArray = (array: any[]): boolean => {
  return !!(array && array?.length && array.length > 0);
};

export interface IListQueryResponse {
  data: any[];
  total: number;
  current_page: number;
  per_page: number;
}

const useInfiniteScroll = (useGetDataListQuery: UseQuery<any>, { perPage = 500, ...queryParameters }) => {
  const [localPage, setLocalPage] = useState(1);
  const [combinedData, setCombinedData] = useState<any>([]);

  const queryResponse = useGetDataListQuery({
    page: localPage,
    per_page: perPage,
    ...queryParameters,
  });

  const {
    data: fetchData = [],
    current_page: remotePage = 1,
    total: remoteTotal = 0,
    per_page: remoteSize = 500,
    ...rest
  } = (queryResponse?.data as IListQueryResponse) || {};

  const { refetch } = queryResponse;

  useEffect(() => {
    if (isValidNotEmptyArray(fetchData)) {
      if (localPage === 1) {
        setCombinedData(fetchData);
      } else if (localPage === remotePage) {
        let mergedData = [...combinedData, ...fetchData]
        let newCombinedData = [
            ...new Map(mergedData.map((talent: { id: any }) => [talent.id, talent])).values(),
        ];
        setCombinedData(newCombinedData);
      }
    }
    // eslint-disable-next-line
    // eslint-disable-next-line
  }, [fetchData]);

  const maxPages = useMemo<number>(() => {
    return calculateMaxPages(remoteTotal, remoteSize);
    // eslint-disable-next-line
  }, [remoteTotal, remoteSize]);

  const refresh = useCallback(() => {
    setLocalPage(1);
    // eslint-disable-next-line
  }, []);

  const readMore = () => {
    if (localPage < maxPages && localPage === remotePage) {
      setLocalPage((page) => page + 1);
    }
  };

  return {
    combinedData,
    localPage,
    readMore,
    refresh,
    isLoading: queryResponse?.isLoading,
    isFetching: queryResponse?.isFetching,
    extraData: { total: remoteTotal, page: localPage, ...rest },
    refetch,
  };
};

export default useInfiniteScroll;
