import React from 'react';
import { createStyles } from '@mantine/core';
import ConnectUserCalendarButton from './ConnectUserCalendarButton';

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
    // backgroundColor: 'lightseagreen',
  },
  message: {
    color: '#838485',
  },
}));

const OauthCalendar = ( ) => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <p className={classes.message}>
        If you are in charge of interviewing candidates for any of your company's roles, connecting your calendar will
        help streamline the interview coordination process and will allow candidates to schedule a meeting directly to
        your calendar.
      </p>
      <p className={classes.message}>
        Feel free to connect any of the following available calendars: iCloud, Google, Outlook, Office365 or Exchange.
      </p>
      <ConnectUserCalendarButton />
    </div>
  );
};

export default OauthCalendar;
