import React, { useEffect, useState } from 'react';
import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps, useModals } from '@mantine/modals';
import { Button, Group, Text, Stack, createStyles, TextInput, LoadingOverlay } from '@mantine/core';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { useLazyGetReactivationSetupIntentQuery, usePostCompletePlanReactivationMutation } from 'app/services/rolebot';
import { ICustomer } from 'types';
import { loadStripe } from '@stripe/stripe-js';
import { toastError, toastSuccess } from 'utils/toastify-messages';
import { ModalsContextProps } from '@mantine/modals/lib/context';

const useStyles = createStyles((theme) => ({
  customStack: {
    marginBottom: '10px',
    padding: '12px',
    marginTop: '10px',
    borderRadius: '5px',
    backgroundColor: '#F7F5F5',
  },
  primary: {
    [theme.fn.largerThan('sm')]: {
      marginLeft: 'auto',
    },
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const ReactivationFormModal = ({ id, innerProps, context }: ContextModalProps<{ data: ICustomer }>) => {
  const modals = useModals();

  const { data } = innerProps;
  const [getReactivationSetupIntent, { data: setupIntent, isLoading: isLoadingSetupIntent }] =
    useLazyGetReactivationSetupIntentQuery();

  useEffect(() => {
    if (data) {
      getReactivationSetupIntent({ customerId: data.id });
    }
  }, [data]);

  const onClose = () => {
    modals.closeAll();
  };

  return (
    <Stack spacing={0}>
      {setupIntent && (
        <TitleWithClose id={id} title={'Reactivation Initiated - Action Required'} onClose={onClose} />
      )}
      {setupIntent && (
        <Elements stripe={stripePromise} options={{ clientSecret: setupIntent?.client_secret }}>
          <ReactivationForm setupIntent={setupIntent} modals={modals} />
        </Elements>
      )}
      {isLoadingSetupIntent && <div style={{ height: '100px' }}><LoadingOverlay visible={isLoadingSetupIntent} /></div>}
    </Stack>
  );
};

const ReactivationForm = ({ setupIntent, modals }: { setupIntent: any, modals: ModalsContextProps }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const { classes } = useStyles();
  const [postCompletePlanReactivation, { isLoading: isLoadingPostCompletePlanReactivation }] = usePostCompletePlanReactivationMutation();

  const toastErrorComponent = (error: any) => {
    //check if error variable has decline_code key
    var isDeclined = true;
    return (
        <div className="toastContainer">
            <div>{isDeclined ? 'Payment declined' : 'Payment failed'}</div>
            <div>{
                isDeclined ? 'Your payment method was declined. Please review and update your payment details.' :
                    'Your payment couldn’t be processed. If the problem persists, contact your card issuer.'
                
            }</div>
        </div>
    )
}

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
        setIsLoading(false);
        return;
    }

    const response = await stripe.confirmSetup({
        elements,
        confirmParams: {
            return_url: `${window.location.origin}/payment/success`,
        },
        redirect: 'if_required'
    });

    if (response.error) {
        setIsLoading(false);
        toastError(toastErrorComponent(response.error));
    } else if (response.setupIntent) {

        try {
            let reactivation = await postCompletePlanReactivation({ customerId: setupIntent.customer_id, payload: {
                payment_method_id: response.setupIntent.payment_method,
                name: name
            } })
            toastSuccess('Payment successful');
            setIsLoading(false);
            modals.closeAll();

        } catch (error) {
            console.log('error', error);
            setIsLoading(false);
            toastError(toastErrorComponent(error));
        }


    }
}

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{ position: 'relative' }}>
      <Text color={'#838485'} style={{ fontSize: '16px', marginBottom: '20px', fontFamily: 'Helvetica', fontWeight: 400 }}>
        Your membership will restart as soon as you update your payment method.
      </Text>
      <TextInput
        label={`Name on card`}
        placeholder={'Jane Doe'}
        size={'md'}
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ paddingBottom: '10px' }}
      />
      <PaymentElement
        id="payment-element"
        options={{
          wallets: {
            applePay: 'never',
            googlePay: 'never',
          },
        }}
      />
      <Stack spacing={2} className={classes.customStack}>
        <Text style={{ fontSize: '16px', color: '#4F4F4F', fontFamily: 'Helvetica' }}>
          USD ${Math.round(setupIntent.amount)} / month
        </Text>
        <Text style={{ fontSize: '15px', color: '#838485', fontFamily: 'Helvetica' }}>
          {setupIntent.subscription_type === 'Month to Month' ? 'Month-to-Month' : 'Annual Agreement'} -{' '}
          {setupIntent.number_of_roles} roles
        </Text>
      </Stack>

      {setupIntent.subscription_type === 'Month to Month' && (
        <Text
          style={{
            color: '#838485',
            fontSize: '12px',
            padding: '2px',
            lineHeight: 'normal',
            marginBottom: '8px',
          }}
        >
          By clicking "Reactivate Membership" you agree that Rolebot will automatically continue your membership plan on a
          month-to-month basis until you cancel, and confirm your understanding and acceptance of Rolebot's
          <>
            {' '}
            <a
              style={{ color: '#40A5D0', textDecoration: 'none' }}
              href={'https://www.rolebot.io/terms-of-service'}
              target="_blank"
            >
              termination policy
            </a>
          </>
          . Your membership will start as soon as you set up payment.
        </Text>
      )}

      {setupIntent.subscription_type === 'Annual' && (
        <Text
          style={{
            color: '#838485',
            fontSize: '12px',
            padding: '2px',
            lineHeight: 'normal',
            marginBottom: '8px',
          }}
        >
          By clicking "Start Membership" you agree that Rolebot will automatically continue your membership plan for one
          (1) year and confirm your understanding and acceptance of Rolebot's
          <>
            {' '}
            <a
              style={{ color: '#40A5D0', textDecoration: 'none' }}
              href={'https://www.rolebot.io/terms-of-service'}
              target="_blank"
            >
              termination policy
            </a>
          </>
          . If you choose to cancel your membership during this period, your subscription will remain active and will be
          billed until its conclusion. Your membership will start as soon as you set up payment.
        </Text>
      )}

      <Group position={'center'} mb={10} mt={40}>
        <RolebotButton
          type={'primary'}
          loading={isLoading}
          loaderPosition={'right'}
          form="create-account-form"
          disabled={isLoading || !stripe || !elements || !name}
          onClick={handleSubmit}
          className={classes.primary}
        >
          Reactivate Membership
        </RolebotButton>
      </Group>
    </form>
  );
};

export default ReactivationFormModal;
