import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import useAppSelector from './useAppSelector';

const useAnalyticsRouteChange = () => {
  const userId = useAppSelector((state) => state.auth.userId);
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({
      page: location.pathname,
      ...(userId ? { userId } : {}),
    });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    // eslint-disable-next-line
  }, [location.pathname]);
};

export default useAnalyticsRouteChange;
