import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Center,
  createStyles,
  Group,
  Menu,
  ScrollArea,
  Stack,
  Table,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { ChevronDown, ChevronUp, Dots, Selector } from 'tabler-icons-react';
import { getNameInitials } from 'utils';
import { IManagedUser } from 'types';
import { usePatchCompanyUserMutation, useReactivateUserMutation } from 'app/services/rolebot';
import useRandomColor from 'hooks/useRandomColor';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import {  toastSuccess } from 'utils/toastify-messages';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },

  tr: {
    height: 90,
    padding: 10,
  },

  icon: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },

  body: {
    td: { padding: '0 !important' },
    border: '1px solid #DFE1E1',
    '& > div': {
      borderBottom: '1px solid #DFE1E1',
    },
  },

  inactiveAvatar: {
    filter: 'brightness(210%)',
  }
}));

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;

  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
  return (
    <th className={classes.th} colSpan={children === 'Name' ? 3 : 1}>
      <Group align={'center'} position={children === 'Name' ? 'left' : 'center'}>
        <UnstyledButton onClick={onSort}>
          <Group position="center" spacing={4}>
            <Text weight={500} size="sm" color={'#838485'}>
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon size={14} color={'#838485'} />
            </Center>
          </Group>
        </UnstyledButton>
      </Group>
    </th>
  );
}

function filterData(data: IManagedUser[], search: string) {
  let keys = Object.keys(data[0]);
  const validKeys = ['name', 'email', 'type', 'status'];
  keys = keys.filter((key) => validKeys.includes(key));
  const query = search.toLowerCase().trim();

  //@ts-ignore
  return data.filter((item) => keys.some((key) => item[key].toLowerCase().includes(query)));
}

function sortData(
  data: IManagedUser[],
  payload: { sortBy: 'name' | 'email' | 'type' | 'status'; reversed: boolean; search: string }
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[payload.sortBy].localeCompare(a[payload.sortBy]);
      }
      return a[payload.sortBy].localeCompare(b[payload.sortBy]);
    }),
    payload.search
  );
}

const UserTable = ({ search = '', data, sortBy, setSortBy }: { search?: string; data: IManagedUser[], sortBy: string, setSortBy(value:any): void }) => {
  const { classes } = useStyles();
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const sortField = Object.keys(sortBy)[0] ?? 'name'

  const setSorting = (field: 'name' | 'email' | 'type' | 'status' | 'signin') => {
    const reversed = field === sortField ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy({[field] : reversed ? 'desc':'asc'});
  };

  const rows = data.map((row) => <UserRow key={row.id} {...row} />);

  return (
    <ScrollArea mt={20}>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        sx={{ tableLayout: 'fixed', minWidth: 700 }}
        styles={{
          td: {
            padding: 0,
          },
        }}
      >
        <thead>
          <tr>
            <Th sorted={sortField === 'name'} reversed={reverseSortDirection} onSort={() => setSorting('name')}>
              Name
            </Th>
            <Th sorted={sortField === 'status'} reversed={reverseSortDirection} onSort={() => setSorting('status')}>
              Status
            </Th>
            <Th sorted={sortField === 'signin'} reversed={reverseSortDirection} onSort={() => setSorting('signin')}>
              Last Login
            </Th>
            <Th sorted={sortField === 'type'} reversed={reverseSortDirection} onSort={() => setSorting('type')}>
              User Type
            </Th>
          </tr>
        </thead>
        <tbody className={classes.body}>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td colSpan={Object.keys(data[0]).length}>
                <Text weight={500} align="center">
                  Nothing found
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
};

export default UserTable;

const UserRow = ({ id, name, email, status, type, latest_signin, roles, customer_user, is_interviewer }: IManagedUser) => {
  const { classes } = useStyles();
  const color = useRandomColor(id);
  const [changeUserType, { isLoading }] = usePatchCompanyUserMutation();
  const [reactivateUser, { isLoading: isReactivateLoading }] = useReactivateUserMutation();
  const [menuOpen, setMenuOpen] = useState(false);
  const { deactivateUser, addUserToRoles } = useOpenModal();

  const handleChangeUserType = async () => {
    await changeUserType({
      userId: id,
      payload: {
        is_admin: !(type === 'Admin'),
      },
    });
  };

  const openAddUserToRolesModal = () => {
    addUserToRoles({
      userId: id,
      userName: name,
      userEmail: email,
      roles: roles,
      customer_id: customer_user.customer_id
    });
  };

  const openDeleteModal = () => {
    deactivateUser({
      userId: id,
      userName: name,
      userEmail: email,
      is_interviewer: is_interviewer
    });
  };

  const onReactivateUser = async () => {
    await reactivateUser(id);
    toastSuccess(
      <div className="toastContainer">
        <div>Success!</div>
        <div>The user has been reactivated.</div>
      </div>
    );
  }

  return (
    <tr className={classes.tr}>
      <td colSpan={3}>
        <Group sx={{ height: '100%', padding: 15 }} noWrap>
          {name ? (
            <Avatar
              size={60}
              radius={30}
              color={'violet'}
            >
              <div className={status === 'Inactive' ? classes.inactiveAvatar : ''}>
                {getNameInitials(name)}
              </div>
            </Avatar>
          ) : (
            <Avatar
              size={60}
              radius={30}
              styles={{
                placeholder: {
                  svg: {
                    fill: color,
                    width: 24,
                    height: 24,
                  },
                },
              }}
            >
              <div className={status === 'Inactive' ? classes.inactiveAvatar : ''}>
                <FontAwesomeIcon icon={faUser} />
              </div>
            </Avatar>
          )}
          <Stack spacing={0}>
            <Text weight={500} sx={{ fontFamily: 'Roboto' }} color={status === 'Inactive' ? '#B3B3B3' : '#242424'}>
              {name}
            </Text>
            <Text style={{ fontSize: '15px' }} color={status === 'Inactive' ? '#B3B3B3' : '#838485'}>
              {email}
            </Text>
          </Stack>
        </Group>
      </td>
      <td>
        <Group position={'center'}>
          <Text color={status === 'Inactive' ? '#B3B3B3' : '#242424'}>{status}</Text>
        </Group>
      </td>
      <td>
        <Group position={'center'}>
          <Text color={status === 'Inactive' ? '#B3B3B3' : '#242424'}>{latest_signin?.signed_in_at ? moment.utc(latest_signin?.signed_in_at).local().format('MMM Do, YYYY'): ''}</Text>
        </Group>
      </td>
      <td>
        <Menu
          placement={'end'}
          styles={{
            body: {
              width: 300
            },
          }}
          control={
            <Button
              variant={'default'}
              px={0}
              sx={{
                width: 125,
                ...(isLoading || status === 'Inactive'
                  ? {
                      backgroundColor: 'transparent',
                      color: '#B3B3B3',
                      cursor: 'default !important',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#B3B3B3',
                      },
                      '&:disabled': {
                        backgroundColor: 'transparent !important',
                        color: '#B3B3B3',
                        borderColor: '#ced4da !important',
                      },
                    }
                  : {}),
              }}
              disabled={isLoading || status === 'Inactive'}
            >
              <Group align={'stretch'} position={'apart'} spacing={4} style={{ paddingLeft: '10px', paddingRight: '10px', minWidth: '100px'}}>
                {type}
                <ChevronDown color={'#B3B3B3'} size={16}/>
              </Group>
            </Button>
          }
        >
          <Menu.Item
            sx={{
              backgroundColor: type === 'Admin' ? '#8241FD2E' : 'transparent',
              '&:hover': { backgroundColor: type === 'Admin' ? '#8241FD2E' : '#EEEEEE' },
            }}
            onClick={handleChangeUserType}
          >
            <Stack spacing={0}>
              <Text color={type === 'Admin' ? '#24154F' : '#242424'}>Admin</Text>
              <Text color={type === 'Admin' ? '#4f4f4f' : '#838485'} sx={{ fontSize: 15 }}>
                Can create and manage integrations, oversee role stats and manage billing
              </Text>
            </Stack>
          </Menu.Item>
          <Menu.Item
            sx={{
              backgroundColor: type === 'Member' ? '#8241FD2E' : 'transparent',
              '&:hover': { backgroundColor: type === 'Member' ? '#8241FD2E' : '#EEEEEE' },
            }}
            onClick={handleChangeUserType}
          >
            <Stack spacing={0}>
              <Text color={type === 'Member' ? '#24154F' : '#242424'}>Member</Text>
              <Text color={type === 'Member' ? '#4f4f4f' : '#838485'} sx={{ fontSize: 15 }}>
                Can create roles and manage roles they are a part of
              </Text>
            </Stack>
          </Menu.Item>
        </Menu>
      </td>
      <td>
        <Group position={'right'} mr={15}>
          <Menu
            opened={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
            control={
              <UnstyledButton
                style={{
                  borderRadius: 40,
                  padding: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Dots color={menuOpen ? '#351B77' : '#B3B3B3'} size={20} />
              </UnstyledButton>
            }
          >
            {status !== 'Inactive' && <Menu.Item onClick={openAddUserToRolesModal}>Add user to roles</Menu.Item>}
            {status !== 'Inactive' && <Menu.Item onClick={openDeleteModal}>Deactivate User</Menu.Item>}
            {status === 'Inactive' && <Menu.Item onClick={onReactivateUser}>Reactivate User</Menu.Item>}
          </Menu>
        </Group>
      </td>
    </tr>
  );
};
