import React from 'react';
import { Check } from 'tabler-icons-react';
import { createStyles } from '@mantine/core';


const useStyles = createStyles((theme, _params, getRef) => ({
  icon: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    backgroundColor: '#ffffff',
    height: '18px',
    width: '18px',
    borderRadius: '50%',
    zIndex: 10,
    border: 'solid 1px #C4C4C4',
  },
  completed: {
    backgroundColor: '#38AA2C',
    borderColor: '#38AA2C',
  }
}));


interface CircleIconProps {
  completed?: boolean;
}

const CircleIcon = ({completed = false}: CircleIconProps) => {
  const { classes } = useStyles();
  return (
    <div className={completed ? [classes.icon, classes.completed].join(' ') : classes.icon}>
      <Check
        size={12}
        strokeWidth={3}
        color={'white'}
      />
  </div>
  )};

export default CircleIcon;