import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps } from '@mantine/modals';
import { ProvideFeedbackProps } from '../../../types/modals';
import { Button, Group, Text, createStyles, MediaQuery } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    okayButton: {
        backgroundColor: '#FFF',
        color: '#838485',
        borderRadius: '6px',
        border: '1px solid #838485',
        paddingRight: '20px',
        paddingLeft: '20px',
        fontSize: '16px',
        '&:hover': {
            backgroundColor: '#EEEEEE',
            color: '#4F4F4F',
            border: '1px solid #4F4F4F',
        },
    },
    submitButton: {
        color: '#7039ED',
        background: 'none',
        fontSize: '16px',
        '&:hover': {
            textDecorationLine: 'underline',
            background: 'none'
        },
    },

    button: {
        flexGrow: 1,
        [theme.fn.largerThan('md')]: {
            flexGrow: 0,
        },
    },
}));

const ProvideFeedback = ({ id, context, innerProps }: ContextModalProps<ProvideFeedbackProps>) => {
    const { classes } = useStyles();

    const handleCloseModal = () => {
        context.closeModal(id);
    }

    const handleUpdateSearchCriteria = async () => {
        try {
            console.log('handleUpdateSearchCriteria modal');
            context.closeModal(id);
            innerProps.handleOpenSearchCriteria();

        } catch (e: any) {
            console.log('handleUpdateSearchCriteria error', e);
        }
    }

    return (
        <>
            <TitleWithClose id={id} title={'Provide Feedback'} />
            <>
                <Text mb={20} color={'#242424'}>
                    It looks like these candidates weren't a perfect fit.
                </Text>
                <Text mb={20} color={'#242424'}>
                    We recommend updating your search criteria to better match your expectations or providing feedback on what was missing.
                </Text>
            </>
            <Group position={'right'} mt={20} align={'end'}>
                <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                    <Button onClick={handleCloseModal} variant={'outline'}>
                        Skip
                    </Button>
                </MediaQuery>
                <Button
                    onClick={handleUpdateSearchCriteria}
                    className={classes.button}
                >
                    Update Search Criteria
                </Button>
            </Group>
        </>
    );
};

export default ProvideFeedback;