import React from 'react';
import { Avatar, Group, Text } from '@mantine/core';
import { getNameInitials } from 'utils';
import useRandomColor from 'hooks/useRandomColor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faXmark } from '@fortawesome/pro-light-svg-icons';

interface CompanyUserListItemProps {
  user: { id: number; name: string; email: string };
  userChecked: (id: number) => void;
  checked: boolean;
}

const CompanyUserListItem = ({ user, userChecked, checked }: CompanyUserListItemProps) => {
  const color = useRandomColor(user.id);
  const handleUserChecked = () => {
    userChecked(user.id);
  };

  return (
    <Group
      noWrap
      spacing={0}
      position={'apart'}
      onClick={handleUserChecked}
      sx={{ cursor: 'pointer' }}
      align={'center'}
    >
      <Group noWrap>
        {user.name ? (
          <Avatar
            size={40}
            styles={{
              placeholder: {
                color,
              },
            }}
          >
            {getNameInitials(user.name)}
          </Avatar>
        ) : (
          <Avatar size={40} styles={{ placeholder: { svg: { fill: color } } }}>
            <FontAwesomeIcon icon={faUser} width={16} height={16} style={{ paddingBottom: '1px' }}/>
          </Avatar>
        )}
        <Text color={'#242424'}>{user.name || user.email}</Text>
      </Group>
      {checked && <FontAwesomeIcon icon={faXmark} width={16} height={16} style={{ paddingBottom: '1px' }}/>}
    </Group>
  );
};

export default CompanyUserListItem;
