import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Avatar, Box, createStyles, Group, Image, Popover, Stack, Text, Title, Tooltip, MediaQuery } from '@mantine/core';
import { useHover, useMediaQuery } from '@mantine/hooks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { openTalentMenu } from 'features/app/appSlice';
import TalentName from 'components/TalentRow/TalentName';
import TalentRole from 'components/TalentRow/TalentRole';
import { useGetCompanyQuery, useGetOutcomesQuery, useGetRolesQuery } from 'app/services/rolebot';
import ThumbsUp from 'components/ActionButtons/ThumbsUp';
import ThumbsDown from 'components/ActionButtons/ThumbsDown';
import Skip from 'components/ActionButtons/Skip';
import { IInterview, ITalent, RoleType } from 'types';
import dayjs from 'dayjs';
import ContactOut from 'components/ContactOut/ContactOut';
import useRandomColor from 'hooks/useRandomColor';
import GreenhouseLogo from 'assets/img/greenhouse_icon.svg';
import LeverLogo from 'assets/img/lever_icon.svg';
import SapLogo from 'assets/img/logos/sap_logo.svg';
import IcimsLogo from 'assets/img/logos/ICIMS_logo.png';
import { getNameInitials } from 'utils';
import TalentLocation from 'components/TalentRow/TalentLocation';
import { sendGoogleEvent } from 'utils/analytics';
import LinkedinLogoCircular from 'assets/img/logos/linkedin_logo_circular.png';
import IndeedLogoCircular from 'assets/img/logos/indeed_logo_circular.png';
import { RoleStackData } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';
import RolebotBadge from "../RolebotBadge";
import moment from "moment";
import TalentBadges from './TalentBadges';

const useStyles = createStyles((theme, { color }: any) => ({
  container: {
    cursor: 'pointer',
    padding: '10px',
    // width: '90vw',
    ':hover': { backgroundColor: '#F7F5F5' },
    paddingInline: '8px !important',
  },
  avatar: {
    width: 65,
    height: 65,
    borderRadius: 35,
    [theme.fn.largerThan('md')]: {
      width: 80,
      height: 80,
      borderRadius: 40,
    },
  },

  placeholder: {
    width: 65,
    height: 65,
    borderRadius: 35,
    [theme.fn.largerThan('md')]: {
      width: 80,
      height: 80,
      borderRadius: 40,
    },
    h2: {
      color,
      fontSize: 28,
      [theme.fn.largerThan('md')]: {
        fontSize: 34,
      },
    },
  },

  root: {
    width: '65px !important',
    height: 65,
    borderRadius: 35,
    [theme.fn.largerThan('md')]: {
      width: 80,
      height: 80,
      borderRadius: 40,
    },
  },

  contentWrapper: {
    marginInline: 0,
    [theme.fn.largerThan('md')]: {
      marginLeft: 10,
    },
  },
}));

interface TalentRowProps {
  talent: ITalent;
  roleId: string;
  hasContactOut: boolean;
  hasRocketReach: boolean;
  type: RoleType;
  handleSetTalent?: Dispatch<SetStateAction<RoleStackData[]>>;
  handlePatchCompleted?: (action: string, talentId: number | string, success: boolean | null) => void;
  isButtonsActive: boolean;
  isSourceOnly?: boolean;
  extraRoleData: any;
}

const TalentRow = ({
  talent,
  roleId,
  hasContactOut,
  hasRocketReach,
  type,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive,
  isSourceOnly = false,
  extraRoleData
}: TalentRowProps) => {
  const { ref, hovered } = useHover<HTMLTableDataCellElement>();
  const { data: company } = useGetCompanyQuery();
  const dispatch = useAppDispatch();
  const isMediumBreakpoint = useMediaQuery('(min-width: 993px)');
  const color = useRandomColor(talent?.id || 0);
  const { classes } = useStyles({ color });
  const validInterviews = (talent && talent.interviews.filter((i) => i.voided_at === null))?.reverse() || [];
  const interview = validInterviews[0] || null;
  const isInterview = validInterviews.length > 0;

  const canSearchLever = Boolean(extraRoleData?.lever_job && type === "review");
  const canSearchGreenhouse = Boolean(extraRoleData?.greenhouse_job && type === "review");

  const { data: outcome } = useGetOutcomesQuery();
  const showContactInfo = Boolean(type === 'unavailable')

  const talentCurrentJob = useMemo(() => {
    if (!talent) return null;
    return talent.job_titles[0];
    // eslint-disable-next-line
  }, [talent]);

  const rightContent = useMemo(() => {
    switch (type) {
      case 'accepted':
        return (
          <AcceptedContent
            talent={talent}
            roleId={roleId}
            hovered={hovered}
            handleSetTalent={handleSetTalent}
            handlePatchCompleted={handlePatchCompleted}
            isButtonsActive={isButtonsActive}
            onHold={extraRoleData?.on_hold}
            completed={extraRoleData?.completed}
            sourcingOnly={extraRoleData?.sourcing_only}
            extraRoleData={extraRoleData}
          />
        );
      case 'interview':
        return <InterviewContent talent={talent} interview={interview} hovered={hovered} />;
      case 'unavailable':
        return <UnavailableContent talent={talent} reasonId={talent?.pivot.outcome_id!} hovered={hovered} />;
      case 'skipped':
        return (
          <SkippedContent
            talent={talent}
            roleId={roleId}
            hovered={hovered}
            handleSetTalent={handleSetTalent}
            handlePatchCompleted={handlePatchCompleted}
            isButtonsActive={isButtonsActive}
            extraRoleData={extraRoleData}
          />
        );
      case 'declined':
        return (
          <DeclinedContent
            talent={talent}
            roleId={roleId}
            hovered={hovered}
            handleSetTalent={handleSetTalent}
            handlePatchCompleted={handlePatchCompleted}
            isButtonsActive={isButtonsActive}
            extraRoleData={extraRoleData}
          />
        );
      default:
        return (
          <DefaultRowContent
            talent={talent}
            roleId={roleId}
            hovered={hovered}
            handleSetTalent={handleSetTalent}
            handlePatchCompleted={handlePatchCompleted}
            isButtonsActive={isButtonsActive}
            extraRoleData={extraRoleData}
          />
        );
    }
  }, [isInterview, hovered, isButtonsActive, extraRoleData]);

  return (
    <div
      className={classes.container}
      ref={ref}
      onClick={() => {
        sendGoogleEvent('User', 'User opened talent profile');
        if (isButtonsActive) {
          dispatch(openTalentMenu(talent));
        }
      }}
    >
      <Group position={'apart'} noWrap className={classes.contentWrapper}>
        <Box>
          <Group noWrap>
            {talent?.profile_image ? (
              <Image
                classNames={{ figure: classes.avatar, image: classes.avatar }}
                className={classes.avatar}
                src={process.env.REACT_APP_S3_BUCKET + encodeURIComponent(talent.profile_image)}
                styles={{
                  image: {
                    border: '1px solid #DFE1E1',
                  },
                }}
              />
            ) : (
              <Avatar classNames={{ root: classes.avatar, placeholder: classes.placeholder }}>
                <Title order={2}>{getNameInitials(talent?.name).toUpperCase()}</Title>
              </Avatar>
            )}
            <Stack spacing={0}>
              <Group spacing={7} align={'center'} noWrap>
                <TalentName name={talent?.name || ''} />
                {isMediumBreakpoint ? (
                  <Group noWrap spacing={10} align={'center'}>
                    {/* {(hasContactOut || hasRocketReach || talent?.email || talent?.mobile_number || talent?.alt_number) &&
                      (talent?.contact_out_email?.length ||
                        talent?.email ||
                        talent?.mobile_number ||
                        talent?.alt_number ||
                        talent?.rocket_reach_email?.length ||
                        talent?.email ||
                        talent?.mobile_number ||
                        talent?.alt_number) &&
                      ((talent?.type === 'accepted' && isSourceOnly) ||
                        talent?.type === 'interview' ||
                        (talent?.type === 'unavailable' && outcome?.outcomes?.[(talent?.pivot.outcome_id!) - 1].name === 'No Response')) && <ContactOut />} */}

                    {
                      (
                        (!isSourceOnly && (showContactInfo || type === 'interview') && (talent?.email || talent?.mobile_number || talent?.alt_number)) ||// rules for turnkey roles (only show card if talent is no response or interview and has any internal contact info)
                        (isSourceOnly && //rules for source only roles (show info if talent is accepted and has any info enabled)
                          type === 'accepted' &&
                          ((hasContactOut && talent?.contact_out_email?.length) ||
                            (hasRocketReach && talent?.rocket_reach_email?.length) ||
                            (talent?.email || talent?.mobile_number || talent?.alt_number)))
                      ) &&
                      <ContactOut />
                    }
                    {company?.greenhouse && canSearchGreenhouse ? (
                      <GreenhouseSearchIcon name={talent?.name || ''} />
                    ) : null}
                    {company?.lever && canSearchLever ? <LeverSearchIcon name={talent?.name || ''} /> : null}
                    {talent?.pivot.greenhouse_user_id ? <GreenhouseIcon /> : null}
                    {talent?.pivot.lever_user_id ? <LeverIcon /> : null}
                    {talent?.pivot.sap_user_id ? <SapIcon /> : null}
                    {talent?.pivot.icims_user_id ? <IcimsIcon /> : null}
                  </Group>
                ) : null}
                <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
                  <TalentBadges {...talent} id={talent.id} />
                </MediaQuery>
              </Group>
              {talentCurrentJob && <TalentRole withIcon role={talentCurrentJob.name} />}
              {talent?.talent_info?.location && <TalentLocation location={talent.talent_info.location} />}
            </Stack>
          </Group>
        </Box>
        {isMediumBreakpoint && rightContent}
      </Group>
    </div>
  );
};

const WrapperDiv = (props: any) => {
  return <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>{props.children}</div>;
};

const DefaultRowContent = ({
  talent,
  roleId,
  hovered,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive = true,
  extraRoleData
}: {
  talent?: ITalent;
  roleId: string;
  hovered: boolean;
  handleSetTalent?: Dispatch<SetStateAction<RoleStackData[]>>;
  handlePatchCompleted?: (action: string, talentId: number | string, success: boolean | null) => void;
  isButtonsActive: boolean;
  extraRoleData: any;
}) => {
  if (!talent) return null;
  return (
    <Group noWrap spacing={40} sx={{ justifyContent: 'space-evenly' }}>
      {hovered && <SocialLink url={talent.link} />}
      {hovered && <Text color={'#838485'}>View Profile</Text>}
      {talent?.pivot.skipped_reason ? <Text>Skipped reason: {talent.pivot.skipped_reason}</Text> : null}
      <Group noWrap>
        <ThumbsUp
          talentId={talent.id}
          roleId={Number(roleId)}
          active={
            talent?.pivot.skipped_at === null &&
            talent?.pivot.skipped_reason === null &&
            Boolean(talent?.pivot.accepted)
          }
          handleSetTalent={handleSetTalent}
          handlePatchCompleted={handlePatchCompleted}
          isButtonsActive={isButtonsActive}
          isFeatureLocked={!extraRoleData?.customer?.is_active}
        />
        <ThumbsDown
          talentId={talent.id}
          roleId={Number(roleId)}
          active={talent?.pivot.accepted === null ? false : !talent?.pivot.accepted}
          acceptedOn={talent?.pivot.accepted_on}
          handleSetTalent={handleSetTalent}
          handlePatchCompleted={handlePatchCompleted}
          isButtonsActive={isButtonsActive}
          isFeatureLocked={!extraRoleData?.customer?.is_active}
        />
      </Group>
      <Skip
        roleId={Number(roleId)}
        talentId={talent.id}
        active={talent?.pivot.skipped_at !== null || talent?.pivot.skipped_reason !== null}
        handleSetTalent={handleSetTalent}
        handlePatchCompleted={handlePatchCompleted}
        isFeatureLocked={!extraRoleData?.customer?.is_active}
      />
    </Group>
  );
};

const SkippedContent = ({
  talent,
  roleId,
  hovered,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive,
  extraRoleData
}: {
  talent?: ITalent;
  roleId: string;
  hovered: boolean;
  handleSetTalent?: Dispatch<SetStateAction<RoleStackData[]>>;
  handlePatchCompleted?: (action: string, talentId: number | string, success: boolean | null) => void;
  isButtonsActive: boolean;
  extraRoleData: any;
}) => {
  if (!talent) return null;
  return (
    <Box>
      <Group noWrap spacing={60} sx={{ justifyContent: 'space-evenly' }}>
        {hovered && <SocialLink url={talent.link} />}
        {hovered && <Text color={'#838485'}>View Profile</Text>}
        <Text sx={{ textTransform: 'capitalize' }} color={'#838485'}>
          {talent.pivot.skipped_reason}
        </Text>
        <Group noWrap>
          <ThumbsUp
            talentId={talent.id}
            roleId={Number(roleId)}
            active={false}
            handleSetTalent={handleSetTalent}
            handlePatchCompleted={handlePatchCompleted}
            isButtonsActive={isButtonsActive}
            isFeatureLocked={!extraRoleData?.customer?.is_active}
          />
          <ThumbsDown
            talentId={talent.id}
            roleId={Number(roleId)}
            active={false}
            acceptedOn={talent?.pivot.accepted_on}
            handleSetTalent={handleSetTalent}
            handlePatchCompleted={handlePatchCompleted}
            isButtonsActive={isButtonsActive}
            isFeatureLocked={!extraRoleData?.customer?.is_active}
          />
        </Group>
      </Group>
    </Box>
  );
};

const AcceptedContent = ({
  talent,
  roleId,
  hovered,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive,
  onHold,
  completed,
  sourcingOnly,
  extraRoleData
}: {
  talent?: ITalent;
  roleId: string;
  hovered: boolean;
  handleSetTalent?: Dispatch<SetStateAction<RoleStackData[]>>;
  handlePatchCompleted?: (action: string, talentId: number | string, success: boolean | null) => void;
  isButtonsActive: boolean;
  onHold?: boolean;
  completed?: boolean;
  sourcingOnly?: boolean;
  extraRoleData: any;
}) => {
  if (!talent) return null;
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
      {hovered && <SocialLink url={talent.link} />}
      {hovered && (
        <Text color={'#838485'} mr={40}>
          View Profile
        </Text>
      )}
      {!sourcingOnly ?
        <div style={{ margin: "0 30px 0 0" }}>
          <RolebotBadge variant={'filled'} color={'#46209E'} backgroundColor={'#EEEEEE'}>
            {(onHold || completed) ? "Outreach paused" : "Outreach in progress"}
          </RolebotBadge>
        </div>
        : null}
      <ThumbsUp talentId={talent.id} roleId={Number(roleId)} active={true} isButtonsActive={isButtonsActive} isFeatureLocked={!extraRoleData?.customer?.is_active} />
      <ThumbsDown
        talentId={talent.id}
        roleId={Number(roleId)}
        active={false}
        acceptedOn={talent?.pivot.accepted_on}
        handleSetTalent={handleSetTalent}
        handlePatchCompleted={handlePatchCompleted}
        isButtonsActive={isButtonsActive}
        isFeatureLocked={!extraRoleData?.customer?.is_active}
      />
    </div>
  );
};

const DeclinedContent = ({
  talent,
  roleId,
  hovered,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive,
  extraRoleData
}: {
  talent?: ITalent;
  roleId: string;
  hovered: boolean;
  handleSetTalent?: Dispatch<SetStateAction<RoleStackData[]>>;
  handlePatchCompleted?: (action: string, talentId: number | string, success: boolean | null) => void;
  isButtonsActive: boolean;
  extraRoleData: any;
}) => {
  if (!talent) return null;
  return (
    <WrapperDiv>
      {/* <Group position={'apart'} noWrap> */}
      {hovered && <SocialLink url={talent.link} />}
      {hovered && (
        <Text color={'#838485'} mr={40}>
          View Profile
        </Text>
      )}
      <ThumbsUp
        talentId={talent.id}
        roleId={Number(roleId)}
        active={false}
        handleSetTalent={handleSetTalent}
        handlePatchCompleted={handlePatchCompleted}
        isButtonsActive={isButtonsActive}
        isFeatureLocked={!extraRoleData?.customer?.is_active}
      />
      <ThumbsDown
        talentId={talent.id}
        roleId={Number(roleId)}
        active={true}
        acceptedOn={talent?.pivot.accepted_on}
        handleSetTalent={handleSetTalent}
        handlePatchCompleted={handlePatchCompleted}
        isButtonsActive={isButtonsActive}
        isFeatureLocked={!extraRoleData?.customer?.is_active}
      />
      {/* </Group> */}
    </WrapperDiv>
  );
};

const InterviewContent = ({
  talent,
  interview,
  hovered,
}: {
  talent?: ITalent;
  interview: IInterview | null;
  hovered: boolean;
}) => {
  const [opened, setOpened] = React.useState(false);
  if (!interview) return null;
  if (!talent) return null;
  return (
    <Group noWrap>
      {hovered && <SocialLink url={talent.link} />}
      {hovered && (
        <Text color={'#838485'} mr={40}>
          View Profile
        </Text>
      )}
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        position="top"
        placement="start"
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        styles={{ body: { backgroundColor: 'black', color: '#fff', pointerEvents: 'none' }, inner: { padding: 4 } }}
        target={
          <Group onMouseEnter={() => setOpened(true)} onMouseLeave={() => setOpened(false)} mr={40}>
            <Text color={'#838485'}>
              {interview && !interview.cancelled_at
                ? dayjs(interview.interview_at).format('MMM D, YYYY')
                : 'Interview Cancelled - ' + dayjs(interview.interview_at).format('MMM D, YYYY')}
            </Text>
          </Group>
        }
      >
        <Text component={'span'} size="sm">
          Interview Date
        </Text>
      </Popover>
    </Group>
  );
};

const UnavailableContent = ({
  talent,
  reasonId,
  hovered,
}: {
  talent?: ITalent;
  reasonId: number;
  hovered: boolean;
}) => {
  const { data } = useGetOutcomesQuery();
  if (!talent) return null;
  return (
    <Group noWrap sx={{ minWidth: '30%', display: 'flex', justifyContent: 'flex-end' }} mr={20}>
      {hovered && <SocialLink url={talent.link} />}
      <Box>
        {hovered && (
          <Text color={'#838485'} mr={40} sx={{}}>
            View Profile
          </Text>
        )}
      </Box>
      <Text color={'#838485'} align={'center'}>
        {data?.outcomes?.[reasonId - 1].name ?? 'Unavailable'}
      </Text>
    </Group>
  );
};

export default React.memo(TalentRow);

const GreenhouseSearchIcon = ({ name }: { name: string }) => {
  return (
    <Tooltip label={'Search on Greenhouse'}>
      <a
        href={`${process.env.REACT_APP_GREENHOUSE_BASE_URL}?${getUrlParams(name)}`}
        target={'_blank'}
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <Image src={GreenhouseLogo} width={9} />
      </a>
    </Tooltip>
  );
};

const LeverSearchIcon = ({ name }: { name: string }) => {
  return (
    <Tooltip label={'Search on Lever'}>
      <a
        href={`${process.env.REACT_APP_LEVER_BASE_URL}?q=${name}`}
        target={'_blank'}
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <Image src={LeverLogo} width={14} />
      </a>
    </Tooltip>
  );
};

const GreenhouseIcon = () => {
  return (
    <Tooltip label={'Candidate exported'}>
      <Image src={GreenhouseLogo} width={8} />
    </Tooltip>
  );
};

const LeverIcon = () => {
  return (
    <Tooltip label={'Candidate exported'}>
      <Image src={LeverLogo} width={16} />
    </Tooltip>
  );
};

const SapIcon = () => {
  return (
    <Tooltip label={'Candidate exported'}>
      <Image src={SapLogo} width={16} />
    </Tooltip>
  );
};

const IcimsIcon = () => {
  return (
    <Tooltip label={'Candidate exported'}>
      <Image src={IcimsLogo} width={32} />
    </Tooltip>
  );
};

const SocialLink = ({ url }: { url: string }) => {
  return (
    <>
      {url.includes('linkedin') ? (
        <a
          style={{ textDecoration: 'none' }}
          href={url}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.stopPropagation();
            sendGoogleEvent('User', 'User clicked Linkedin profile button in the talent row');
          }}
        >
          <Text mr={30}>
            <Image src={LinkedinLogoCircular} width={22} />
          </Text>
        </a>
      ) : url.includes('indeed') ? (
        <a
          style={{ textDecoration: 'none' }}
          href={url}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          <Text mr={30}>
            <Image src={IndeedLogoCircular} width={22} />
          </Text>
        </a>
      ) : (
        <a
          style={{ textDecoration: 'none' }}
          href={url}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          <Text mr={30}>
            <FontAwesomeIcon icon={faExternalLinkAlt} width={22} />
          </Text>
        </a>
      )}
    </>
  );
};

export const getUrlParams = (name = '') => {
  const params = {
    sort_by: 'last_activity',
    sort_order: 'desc',
    job_status: 'all',
    type: 'all',
    search_terms: name,
  };
  let str = '';
  for (const [k, v] of Object.entries(params)) {
    str += `${k}=${v}&`;
  }
  return encodeURI(str.slice(0, -1));
};
