import { useEffect, useState } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAreaClick(ref) {
  const [clickedOutside, setClickedOutside] = useState(false);

  useEffect(() => {
    if (clickedOutside) setClickedOutside(false);
    // eslint-disable-next-line
  }, [clickedOutside]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickedOutside(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [ref]);

  return clickedOutside;
}

export default useOutsideAreaClick;

/**
 EXAMPLE

 const Component = (props) => {
    const wrapperRef = useRef(null);
    const clickedOutside = useOutsideAreaClick(wrapperRef);

    const [isOpen,setIsOpen] = useState(false);

    useEffect(() => {
        if(clickedOutside && isOpen){
            setIsOpen(false);
        }
    // eslint-disable-next-line
}, [clickedOutside, isOpen])

    return (
        <div ref={wrapperRef}></div>
    )
}
 */
