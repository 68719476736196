import React from 'react';
import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { Button, RadioGroup, Radio, Group, createStyles } from '@mantine/core';
import { useUpdateCronofyUserCalendarMutation } from 'app/services/rolebot';
import { ICronofyCalendar } from '../../../../types';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorServerResponse } from '../../../../types/responses';
import FormSubmitServerErrors from '../../../../components/FormSubmitServerErrors/FormSubmitServerErrors';

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
    marginTop: '20px',
  },
  label: {
    color: '#4f4f4f',
    fontFamily: 'Helvetica',
    fontSize: '16px',
  },
  // radio: {
  //   borderColor: 'green',
  //   backgroundColor: 'green',
  // },
  // inner: {
  //   borderColor: 'yellow',
  // },
  // icon: {
  //   color: '#7039ed',
  // },
}));

const calendarSchema = z.object({
  calendarId: z.string().min(1, { message: 'You must select an email account.' }),
});

type CalendarSelectorFormPayload = z.infer<typeof calendarSchema>;

interface CalendarSelectorProps {
  selectedCalendar: string;
  calendars: ICronofyCalendar[] | undefined;
  disconnectOnCancel: boolean;
  onDisconnectCalendar: () => void;
}

const CalendarSelector = ({
  selectedCalendar,
  calendars = [],
  disconnectOnCancel = false,
  onDisconnectCalendar,
}: CalendarSelectorProps) => {
  const { classes } = useStyles();
  const [updateCronofyUserCalendar, { error, isError }] = useUpdateCronofyUserCalendarMutation();

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      calendarId: selectedCalendar ? selectedCalendar : '',
    },
    resolver: zodResolver(calendarSchema),
  });

  const handleSubmitCalendar = ({ calendarId }: CalendarSelectorFormPayload) => {
    const calendarSelected = calendars.find((x) => x.calendar_id === calendarId);
    if (calendarSelected?.calendar_id && calendarSelected?.profile_id) {
      updateCronofyUserCalendar({
        calendar_id: calendarSelected?.calendar_id,
        profile_id: calendarSelected?.profile_id,
        tzid: Intl.DateTimeFormat().resolvedOptions().timeZone
      }).catch((e) => console.log(e));
    }
  };

  const handleCancelClicked = () => {
    if (disconnectOnCancel) {
      onDisconnectCalendar();
    } else {
      alert('Close This Section');
    }
  };

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(handleSubmitCalendar)}>
        {/*The mantine radio group needs to be wrapped with a controller in order to work.*/}
        {/*https://react-hook-form.com/api/usecontroller/controller*/}
        <Controller
          control={control}
          name="calendarId"
          render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
            <RadioGroup
              classNames={{}}
              orientation="vertical"
              size="sm"
              label="Please select your calendar"
              value={value}
              onChange={onChange}
              error={error?.message}
            >
              {calendars.map((x) => (
                <Radio my={5} key={x.calendar_id} value={x.calendar_id} label={x.calendar_name} />
              ))}
            </RadioGroup>
          )}
        />
        {isError && <FormSubmitServerErrors formError={(error as ErrorServerResponse)?.data} />}
        <Group position="center" mt={30} align={'end'}>
          <Button px={40} variant="outline" onClick={handleCancelClicked}>
            Cancel
          </Button>
          <Button type="submit" px={40}>
            Save
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default CalendarSelector;
