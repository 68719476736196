import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import {
  Alert,
  Button,
  createStyles,
  Grid,
  Group,
  Loader,
  Stack,
  Text,
  TextInput,
  Title,
  Switch
} from '@mantine/core';
import AdminRow from 'components/Modals/RoleAdmins/AdminRow';
import SuccessLaunch from 'components/Modals/LaunchRole/SuccessLaunch';
import { useModals } from '@mantine/modals';
import { useForm } from 'react-hook-form';
import { useListState, useMediaQuery } from '@mantine/hooks';
import {
  useCreateRoleFromGreenhouseMutation,
  useGetCompanyQuery,
  useGetGreenhouseRolesQuery,
  useGetRolesQuery,
  useGetUserQuery,
  useLinkGreenhouseJobMutation,
  useUnlinkGreenhouseJobMutation,
} from 'app/services/rolebot';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import * as z from 'zod';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { IRole } from 'types';
import _ from 'lodash';
import GreenhouseRolesDropdown from "./Components/GreenhouseRolesDropdown";
import LinkableGreenhouseRolesDropdown from "./Components/LinkableGreenhouseRolesDropdown";
import useOutsideAreaClick from "../../../pages/RoleStats/hooks/useOutsideAreaClick";
import AddUsersToRoleDropDown from "./Components/AddUsersToRoleDropDown";
import BodyScrollWrapper from '../components/BodyScrollWrapper';
import {  toastSuccess } from 'utils/toastify-messages';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faLinkSlash  } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  button: {
    marginTop: 'auto',
    marginBottom: 10,
    width: '100%',
    [theme.fn.largerThan('md')]: {
      marginBottom: 0,
      width: 'fit-content',
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '19px',
    fontWeight: 400,
    margin: '20px 0',
    color: '#242424'
  },
  chevron: {
    marginRight: '4px'
  },
  unlinkIcon: {
    cursor: 'pointer',
  },
  roleNameText: {
    color: '#242424'
  }
}));

const LaunchFormSchema = z.object({
  // roleName: z.string().nonempty('Role name is required'),
  admin: z.union([z.string().email('Invalid email address'), z.literal('')])
});

type LaunchFormPayload = z.infer<typeof LaunchFormSchema>;

//TODO: fix typescript in this file

const getAvailableJobs = (roles = [], connectedRoles = []) => {
  // @ts-ignore
  const unavailableIds = connectedRoles.map((r) => r.job_id);
  return roles.map((i: IRole) => {
    // @ts-ignore
    if (unavailableIds.includes(i.id)) {
      return {
        //@ts-ignore
        ...i,
        isAvailable: false,
        label: i.name,
        value: i.id,
      };
    }
    return {
      //@ts-ignore
      ...i,
      label: i.name,
      value: i.id,
      isAvailable: true,
    };
  });
};

type linkError = {
  data: {
    message: string;
  };
};

const GreenhouseTab = ({
  integrations = null
}) => {
  const [currentPane, setCurrentPane] = useState(1);
  // This one needs a better name. I only use it to determine which should be the right dropdown that's showing when trying to link RB role to ATS role.
  const [localRolebotRoleId, setLocalRolebotRoleId] = useState(0);
  const [showGreenhouseRoles, setShowGreenhouseRoles] = useState(false);
  const greenhouseRolesDropdownRef = useRef(null);
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const [linkableGreenhouseRolesInputFields, setLinkableGreenhouseRolesInputFields] = useState({});
  const clickedOutsideOfGreenhouseRolesDropdown = useOutsideAreaClick(greenhouseRolesDropdownRef);
  const [selectedGreenhouseRole, setSelectedGreenhouseRole] = useState<any>({});
  const [selectedLinkableGreenhouseRoles, setSelectedLinkableGreenhouseRoles] = useState<any>([]);
  const { classes } = useStyles();
  const modals = useModals();
  const { data: user } = useGetUserQuery();
  const { data: greenhouseRoles } = useGetGreenhouseRolesQuery({type: "name", value: ""});
  const { combinedData: activeRoles, extraData } = useInfiniteScroll(useGetRolesQuery, {
    type: 'active',
  });
  const [links, setLinks] = useState({});
  const [, setRepeatedFields] = useState([]);
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [someFailed, setSomeFailed] = useState(false);
  const [rejectsError, setRejectsError] = useState<any>([]);
  const [linkRole, { isLoading: isLinking,  }] = useLinkGreenhouseJobMutation();
  const [showUsers, setShowUsers] = useState(false);
  const [emailErrors, setEmailErrors] = useState<any>([]);
  const { data: company } = useGetCompanyQuery();
  const roles = useMemo(
    //@ts-ignore
    () => getAvailableJobs(greenhouseRoles?.greenhouse_jobs, integrations?.greenhouse?.greenhouse_jobs),
    [greenhouseRoles, integrations]
  );

  //@ts-ignore
  // const canUnlink = useMemo(() => extraData && extraData?.greenhouse_roles?.length > 0, [extraData]);

  const canUnlink = useMemo(() => integrations && integrations?.greenhouse?.greenhouse_jobs?.length > 0, [integrations]);

  const _roles = useMemo(
    () =>
      //@ts-ignore
      activeRoles.map((role) => {
        const index = roles.findIndex((r) => r.id === role.greenhouse_job?.job_id);
        if (index === -1) {
          return role;
        }
        return {
          ...role,
          greenhouse_job: {
            ...role.greenhouse_job,
            name: roles[index].name,
          },
        };
      }),
    [activeRoles, roles]
  );

  const [value, setValue] = useState(null);

  const {
    handleSubmit,
    getValues,
    setValue: setGreenhouseRoleValue,
    register,
    resetField,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      roleName: '',
      admin: '',
      isSourcingOnly: false,
    },
    resolver: zodResolver(LaunchFormSchema),
  });

  const [launchRole, { isSuccess, isLoading, error, reset: resetCreateRole }] = useCreateRoleFromGreenhouseMutation();
  const [admins, handlers] = useListState<string>([]);
  const [roleNameWatcher] = watch([
    "roleName"
  ]);

  const [adminWatcher] = watch([
    "admin"
  ]);
  const [isSourcingOnlyWatcher] = watch(['isSourcingOnly']);

  useEffect(() => {
    if (roleNameWatcher === "") {
      setValue(null);
    }
    setGreenhouseRoleValue('roleName', roleNameWatcher);
    // eslint-disable-next-line
  }, [roleNameWatcher]);

  useEffect(() => {
    setGreenhouseRoleValue('admin', adminWatcher);
    // eslint-disable-next-line
  }, [adminWatcher])

  useEffect(() => {
    if (user?.user) {
      admins.includes(user.user.email) || handlers.append(user.user.email);
    }
    // eslint-disable-next-line
  }, [admins, user, handlers]);

  useEffect(() => {
    if (clickedOutsideOfGreenhouseRolesDropdown) {
      handleClickedOutsideOfGreenhouseRolesDropdown();
    }
    // eslint-disable-next-line
  }, [clickedOutsideOfGreenhouseRolesDropdown]);

  useEffect(() => {
    if (emailErrors.length === 0) {
      resetCreateRole();
    }
    // eslint-disable-next-line
  }, [emailErrors]);

  const handleClickedOutsideOfGreenhouseRolesDropdown = () => {
    setShowGreenhouseRoles(false);
    if (selectedGreenhouseRole?.greenhouse_job_name !== getValues('roleName')) {
      setGreenhouseRoleValue('roleName', '');
      setValue(null);
      setSelectedGreenhouseRole({});
    }
  };

  const handleLinkableGreenhouseRolesInputFieldsChanged = (e: any, roleId: number) => {
    setLinkableGreenhouseRolesInputFields((previousValue) => {
      return {...previousValue, [roleId]: e};
    });
    if (e === '') {
      const newLinks = { ...links };
      // @ts-ignore
      delete newLinks[roleId];
      setLinks(newLinks);
    }
  }

  const handleCloseUsersDropdown = () => {
    //setShowUsers(false);
    //setGreenhouseRoleValue('admin', "");
  };

  const handleUsersDropdownFocus = () => {
    setShowUsers(true);
  };

  const handleUserSelected = (user: any) => {
    setGreenhouseRoleValue("admin", user.email);
    setShowUsers(false);
    if (!admins.includes(user.email)) {
      handlers.append(user.email);
    }
    resetField('admin');
  };

  const addAdmin = (data: LaunchFormPayload) => {
    if (!data.admin) return;
    if (admins.includes(data.admin!)) return;
    handlers.append(data.admin!);
    resetField('admin');
  };

  const onReset = () => {
    reset();
    resetCreateRole();
    setValue(null);
    const userEmail = user?.user.email.trim() || null;
    if (userEmail) {
      handlers.setState([userEmail]);
    }
  };

  const handleCreateRole = async () => {
    setEmailErrors([]);
    resetCreateRole();
    if (!selectedGreenhouseRole || !value) {
      return;
    }
    try {
        /* @SM changed interviews from 1 to 100 as required on ticket T3-1483 */
      await launchRole({
        greenhouse_job_id: parseInt(selectedGreenhouseRole?.greenhouse_job_id),
        name: selectedGreenhouseRole?.greenhouse_job_name,
        interviews: 100,
        price: 0,
        //@ts-ignore
        admins,
        ...(company?.role_type?.name === 'Hybrid (Sourcing & Turnkey)' &&
          (isSourcingOnlyWatcher === true ? { sourcing_only: 1 } : { sourcing_only: 0 })),
      }).unwrap();
      toastSuccess(
        <div className="toastContainer">
          <div>Success!</div>
          <div>Your new role has been created</div>
        </div>
      );
      modals.closeAll()
    } catch (e: any) {
      if (e.data.type === 'email-error') {
        setEmailErrors(e.data.message);
      }
    }
  };

  const handleGreenhouseRoleSelected = (role: any) => {
    setGreenhouseRoleValue('roleName', role.greenhouse_job_name);
    setValue(role.greenhouse_job_id);
    setSelectedGreenhouseRole(role);

    setShowGreenhouseRoles(false);
  };

  const handleLinkableGreenhouseRoleSelected = (role: any) => {
    setSelectedLinkableGreenhouseRoles((previousValue: any) =>{
      return {...previousValue, [localRolebotRoleId]: role}
    });
    setLinkableGreenhouseRolesInputFields((previousValue) => {
      return {...previousValue, [localRolebotRoleId]: role.greenhouse_job_name};
    });
    onAdd(localRolebotRoleId, role.greenhouse_job_id);

    setShowGreenhouseRoles(false);
  };

  const handleGreenhouseRolesFocus = (roleId = 0) => {
    setShowGreenhouseRoles(true);
    setLocalRolebotRoleId(roleId);
  };

  const handleCloseAllDropdowns = () => {
    setLinkableGreenhouseRolesInputFields((previousValue) => {
      // @ts-ignore
      if (previousValue[localRolebotRoleId] !== selectedLinkableGreenhouseRoles[localRolebotRoleId]?.greenhouse_job_name) {
        const newLinks = { ...links };
        // @ts-ignore
        delete newLinks[localRolebotRoleId];
        setLinks(newLinks);
      }
      // @ts-ignore
      return {...previousValue, [localRolebotRoleId]: previousValue[localRolebotRoleId] === selectedLinkableGreenhouseRoles[localRolebotRoleId]?.greenhouse_job_name ? previousValue[localRolebotRoleId] : ""};
    });
    setShowGreenhouseRoles(false);
  };

  const handleDelete = (admin: string) => {
    //@ts-ignore
    let newEmailErrors = emailErrors.filter(x => x !== admin);
    setEmailErrors(newEmailErrors);
    const newState = admins.filter((a) => a !== admin);
    handlers.setState(newState);
  };

  const onAdd = (roleId: number, atsId: number) => {
    const newLinks = { ...links };
    //@ts-ignore
    const item = newLinks[roleId];
    if (item) {
      const wasSameItemClicked = item.roleId === roleId;
      if (wasSameItemClicked) {
        //@ts-ignore
        delete newLinks[roleId];
      }
    } else {
      //@ts-ignore
      newLinks[roleId] = {
        roleId,
        atsId,
      };
    }
    setLinks(newLinks);
  };

  const handleMakeConnection = async () => {
    setSomeFailed(false);
    setRepeatedFields([]);
    setHasDuplicates(false);
    setRejectsError([]);
    // look if there is no repeated data first.
    const atsRolesIds: any[] = [];

    _.mapValues(links, (v) => {
      //@ts-ignore
      atsRolesIds.push(v.atsId);
    });
    // do nothing if we have no data.
    if (atsRolesIds.length === 0) return;

    // return early if there are duplicates.
    const uniqueIds = [...new Set(atsRolesIds)];
    let duplicates = [...atsRolesIds];
    uniqueIds.forEach((item) => {
      const i = duplicates.indexOf(item);
      duplicates = duplicates.slice(0, i).concat(duplicates.slice(i + 1, duplicates.length));
    });
    if (duplicates.length > 0) {
      setHasDuplicates(true);
      return;
    }

    // get params for each request we are going to make.
    const connectionParams: { roleId: number; atsId: number }[] = []; // has objects of type: {roleId, atsId}
    _.mapValues(links, (v) => {
      //@ts-ignore
      connectionParams.push({ roleId: v.roleId, atsId: v.atsId });
    });
    try {
      const results = await Promise.allSettled(
        connectionParams.map(({ roleId, atsId }) =>
          linkRole({
            greenhouse_jobId: atsId,
            roleId,
          }).unwrap()
        )
      );
      const fulfilled = results.filter((p) => p.status === 'fulfilled');
      const rejects = results.filter((p) => p.status === 'rejected');

      if (rejects.length > 0) {
        setRejectsError(rejects);
      }
      //@ts-ignore
      const rejectedIds = rejects.map((r) => r.reason.meta.roleId);
      //@ts-ignore
      setRepeatedFields(rejectedIds);
      if (fulfilled.length > 0 && rejects.length > 0) {
        setSomeFailed(true);
        return;
      }
      if (fulfilled.length === 0 && rejects.length > 0) {
        throw new Error();
      }
      toastSuccess(
        <div className="toastContainer">
          <div>Your role was successfully linked</div>
          <div></div>
        </div>
      );
    } catch (e) {
      setLinks({});
      console.log(e);
    }
  };

    const wrapperRef = useRef(null);
    const clickedOutside = useOutsideAreaClick(wrapperRef);

    useEffect(() => {
        if (clickedOutside) {
            if(showUsers) {
                setShowUsers(false);
                setGreenhouseRoleValue('admin', "");
            }
        }
        // eslint-disable-next-line
    }, [clickedOutside]);

  return (
    <>
      {currentPane === 1 && (
        <>
              <div style={{minHeight: '380px', display: 'flex', flexDirection:'column', justifyContent: 'space-between', paddingTop: '10px'}}>
                  <Stack>
                    <div ref={greenhouseRolesDropdownRef}>
                      <TextInput
                        label={"Role's Name"}
                        placeholder={"Type to search for a Greenhouse role..."}
                        size={'md'}
                        {...register('roleName')}
                        onFocus={() => handleGreenhouseRolesFocus()}
                      />
                      <GreenhouseRolesDropdown
                        greenhouseRoleSelected={handleGreenhouseRoleSelected}
                        show={showGreenhouseRoles}
                        needle={getValues('roleName')}
                      />
                    </div>

                    {company?.role_type?.name === 'Hybrid (Sourcing & Turnkey)' && (
                    <div>
                      <Title style={{ fontSize: '16px', fontWeight: 400 ,  color: '#242424',fontFamily: 'helvetica', marginBottom: "10px", padding: 0}}>Set to 'Sourcing only'</Title>
                      <Grid style={ isDesktop ? {} : {display: 'grid', gridTemplateColumns: "75% 22vw" }}>
                        <Grid.Col xs={9}>
                          <Stack>
                            <Text
                              sx={
                                isSourcingOnlyWatcher
                                  ? { color: '#242424', paddingTop: 0 }
                                  : { color: '#838485', paddingTop: 0 }
                              }
                            >
                              Rolebot does not engage with candidates when a role is set to 'Sourcing only'.
                              <span style={{fontFamily: 'Roboto', fontWeight: 500}}> This can only be set during role creation.</span>
                            </Text>
                          </Stack>
                        </Grid.Col>
                        <Grid.Col xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Group grow>
                            <Switch onLabel="" {...register('isSourcingOnly')} offLabel="" size="lg" />
                          </Group>
                        </Grid.Col>
                      </Grid>
                    </div>
                  )}

                    <form onSubmit={handleSubmit(addAdmin)}>
                        <div ref={wrapperRef}>
                          <TextInput
                            label={'Role Members'}
                            placeholder={'ex. bob@acme.com'}
                            size={'md'}
                            {...register('admin')}
                            // onSubmit={handleSubmit(onSubmit)}
                            error={errors?.admin?.message}
                            onFocus={() => handleUsersDropdownFocus()}
                            onClick={() => {
                              if (!showUsers) {
                                setShowUsers(true);
                              }
                            }}
                          />
                          <AddUsersToRoleDropDown
                            closeDropdown={handleCloseUsersDropdown}
                            userSelected={handleUserSelected}
                            show={showUsers}
                            needle={getValues('admin')}
                          />
                        </div>
                    </form>
                  </Stack>
                  <BodyScrollWrapper>
                    <Stack spacing={5}>
                      {admins.map((admin) => (
                        <AdminRow
                          key={admin}
                          onDelete={() => handleDelete(admin)}
                          email={admin}
                          removable={admin !== user?.user.email}
                          isError={emailErrors?.includes(admin)}
                        />
                      ))}
                    </Stack>
                  </BodyScrollWrapper>
                  {error &&
                  (emailErrors?.length > 0 ? (
                      <Alert title={'Oops!'} color={'orange'} style={{marginTop: 15}}>
                        {`The following user${
                          emailErrors?.length === 1 ? '' : 's'
                        } cannot be added because they are part of another company`}
                      </Alert>
                    ) : null
                  )}
                  {(error as linkError)?.data && emailErrors.length === 0 && <Alert color={'red'}>{(error as linkError)?.data.message}</Alert>}
                <Group position={'right'} mt={20}>
                  {/* <RolebotButton type={'secondary'} onClick={() => setCurrentPane(2)}>Link jobs to Rolebot roles</RolebotButton> */}

                  <RolebotButton type={'primary'} loaderPosition={'right'}
                    disabled={value === null}
                    onClick={handleSubmit(handleCreateRole)}
                    loading={isLoading}>Create Role</RolebotButton>
                </Group>
              </div>
        </>
      )}
      {currentPane === 2 && (
        <div>
          <Group sx={{ color: '#4BA7C3' }} align={'center'} position={'left'} spacing={5} px={0}>

          <RolebotButton
                      type={'link'}
                      onClick={() => setCurrentPane(1)}
                      
                    >
                      <FontAwesomeIcon icon={faChevronLeft} style={{paddingRight : '6px'}} /> Launch a new role
          </RolebotButton>

          </Group>
          <div style={{ maxHeight: 600, overflowY: 'auto', overflowX: 'hidden', padding: '0 15px 0 0'}}>
            {/*@ts-ignore*/}
            {extraData && extraData?.greenhouse_roles?.length !== _roles.length &&
            <>
              <Title className={classes.title} order={3}>
                Link Greenhouse jobs to Rolebot roles
              </Title>
              <Group>
                <Stack sx={{width: '100%'}}>
                  <Grid>
                    {_roles.map((role: IRole) => {
                      if (Boolean(role.greenhouse_job)) return null;
                      return (
                        <>
                          <Grid.Col xs={6}>
                            <Stack spacing={0}>
                              <Text className={classes.roleNameText}>{role.name}</Text>
                              <Text color={'#838485'}>{role?.info?.office_locations}</Text>
                            </Stack>
                          </Grid.Col>
                          <Grid.Col xs={6}>
                            <Group grow>
                              <div>
                                <TextInput
                                  placeholder={"Type to search for a Greenhouse role..."}
                                  size={'md'}
                                  //@ts-ignore
                                  value={linkableGreenhouseRolesInputFields[role.id] || ""}
                                  onChange={(event) => handleLinkableGreenhouseRolesInputFieldsChanged(event.currentTarget.value, role.id)}
                                  onFocus={() => handleGreenhouseRolesFocus(role.id)}
                                />
                                <LinkableGreenhouseRolesDropdown
                                  closeAllDropdowns={handleCloseAllDropdowns}
                                  linkableGreenhouseRoleSelected={handleLinkableGreenhouseRoleSelected}
                                  show={showGreenhouseRoles && role.id === localRolebotRoleId}
                                  //@ts-ignore
                                  needle={linkableGreenhouseRolesInputFields[role.id] || ""}
                                />
                              </div>
                            </Group>
                          </Grid.Col>
                        </>
                      );
                    })}
                  </Grid>
                </Stack>
              </Group>
              {hasDuplicates && <Alert color={'red'}>You can only connect one Rolebot role to a Greenhouse Job</Alert>}
              {someFailed && <Alert color={'yellow'}>We were not able to make some connections</Alert>}
              {rejectsError.length > 0 && <Alert color={'yellow'}>{rejectsError[0]?.reason?.data?.message}</Alert>}
              <Button ml={'auto'} mt={20} sx={{ display: 'block' }} onClick={handleMakeConnection} loading={isLinking}>
                Save Changes
              </Button>
            </>
            }
            {/*@ts-ignore*/}
            {canUnlink && (
              <>
                <Title className={classes.title} order={3}>
                  Linked Greenhouse jobs
                </Title>
                <Grid>
                  {_roles.map((role: IRole) => (
                    <LinkedRole
                      role={role}
                      reqId={
                        //@ts-ignore
                        role?.greenhouse_job?.requisition_id
                      }
                    />
                  ))}
                </Grid>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  requisition_id: number;
  name: string;
  _id: number;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ requisition_id, name, _id, className, ...others }: ItemProps, ref) => (
    <Group
      ref={ref}
      key={_id + name}
      {...others}
      sx={() => ({
        gap: 6,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#F5F5F5',
        },
      })}
      px={10}
      py={12}
    >
      <Text color={'#4F4F4F'} component={'span'} sx={{ display: 'inline-block', lineHeight: 1.3 }} mr={5}>
        {name}{' '}
      </Text>
      <Text size={'sm'} color={'#959595'} component={'span'} sx={{ display: 'inline-block', lineHeight: 0.75 }}>
        Req ID {requisition_id}
      </Text>
    </Group>
  )
);

const LinkedRole = ({ role, reqId }: { role: IRole; reqId: string }) => {
  const [unlink, { isLoading }] = useUnlinkGreenhouseJobMutation();
  const { classes } = useStyles();

  const handleUnlink = async () => {
    try {
      await unlink(role.id).unwrap();
      toastSuccess(
        <div className="toastContainer">
          <div>Your role was successfully unlinked</div>
          <div></div>
        </div>
      );
    } catch (e) {
      console.log('Error unlinking');
    }
  };

  if (!Boolean(role.greenhouse_job)) return null;
  return (
    <>
      <Grid.Col xs={6}>
        <Stack spacing={0}>
          <Text className={classes.roleNameText}>{role.name}</Text>
          <Text color={'#838485'}>{role?.info?.office_locations}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col xs={6}>
        <Group>
          {/*@ts-ignore*/}
          <TextInput
            placeholder="Select a role..."
            value={`${role.greenhouse_job.name} ${reqId}`}
            sx={{ flex: 1 }}
            disabled
          />
          {isLoading ? <Loader size={'sm'} color={'violet'} /> : <FontAwesomeIcon icon={faLinkSlash} onClick={handleUnlink} />}
        </Group>
      </Grid.Col>
    </>
  );
};

interface UnlinkedRoleProps extends IRole {
  requisition_id: string;
  isAvailable: boolean;
  handleAdd: (roleId: number, greenhouseId: number) => void;
  mainRole: IRole;
}

//@ts-ignore
const UnlinkedRole = ({ requisition_id, name, id, isAvailable, handleAdd, mainRole, ...others }: UnlinkedRoleProps) => {
  return (
    <Group
      {...others}
      key={id + name}
      onMouseDown={(e) => {
        if (!isAvailable) return;
        //@ts-ignore
        others?.onMouseDown(e);
        handleAdd(mainRole.id, id);
      }}
      style={{ cursor: isAvailable ? 'pointer' : 'not-allowed' }}
      noWrap
      sx={() => ({
        gap: 5,
        padding: 0,
        height: 'max-content',
        '&:hover': {
          backgroundColor: '#F5F5F5',
        },
      })}
    >
      <Text component={'span'} color={'#4F4F4F'} p={0} sx={{ lineHeight: 1, display: 'inline-block' }}>
        {name}
      </Text>
      <Text
        component={'span'}
        size={'sm'}
        color={'#959595'}
        lineClamp={1}
        p={0}
        sx={{ lineHeight: 1, display: 'inline-block' }}
      >
        Req ID {requisition_id}
      </Text>
    </Group>
  );
};

export default GreenhouseTab;
