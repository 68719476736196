import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

interface OfficeRowProps {
  id: number;
  name: string;
  className? : string;
  onDelete(id:number): void;
  isAdmin: boolean;
}

const useStyles = createStyles((theme) => ({
    row:{
        maxWidth: 500,
        width: '100%',
        borderBottom: "1px solid #DFE1E1"
    },
    content:{
        width: '100%',
        paddingTop: 12,
        paddingBottom: 12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        width: 40,
        height: 40,
        borderRadius: 20,
        '&:hover': {
          backgroundColor: '#EEEEEE',
        },
      },
  }));

const OfficeRow = ({ 
  id,
  name,
  className,
  onDelete,
  isAdmin = false
}: OfficeRowProps) => {
    const { classes } = useStyles();

  return (
    <div className={classes.row}>
        <div className={classes.content}>
            <Text>{name}</Text>
            {/* {isAdmin && (
              <Group>
                <ActionIcon variant={'transparent'} ml={'auto'} size={20} onClick={() => onDelete(id)}>
                    <FontAwesomeIcon icon={faXmark} color={'#838485'} />
                </ActionIcon>
            </Group>
            )} */}
        </div>

    </div>
  );
};
export default OfficeRow;
