import React from 'react';
import { Text } from '@mantine/core';

interface TableInterviewerCellProps {
  children: React.ReactNode;
}

const InterviewerCell = ({ children }: TableInterviewerCellProps) => {
  return <Text color={'#242424'}>{children}</Text>;
};

export default InterviewerCell;
