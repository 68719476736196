import React from 'react';
import { Text } from '@mantine/core';
import dayjs from 'dayjs';

interface RoleDateProps {
  date: any;
}

const RoleDate = ({ date }: RoleDateProps) => {
  return (
    <Text color="#838485" size="md">
      {dayjs(date).format('MMM DD, YYYY')}
    </Text>
  );
};
export default RoleDate;
