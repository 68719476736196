import { useEffect, useReducer } from 'react';
import { Text, Image, Tooltip } from '@mantine/core';
import GreenHouseIcon from 'assets/img/greenhouse_icon.svg';
import LeverIcon from 'assets/img/lever_icon.svg';
import SapIcon from 'assets/img/logos/sap_logo.svg';
import moment from 'moment';
import { getNumberOfDaysByDate } from 'utils/dates';
import { BsFillCircleFill } from 'react-icons/bs';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'setRowsToShow':
      return {
        ...state,
        data: action.payload,
      };
    default:
      throw new Error();
  }
};

interface IMetricsDetail {
  roleId?: number;
  data: [];
}

const MetricsDetail = ({ roleId, data }: IMetricsDetail) => {
  const [state, dispatch] = useReducer(reducer, {
    data: {},
  });

  useEffect(() => {
    if (data.length > 0) {
      const dataRow = data.filter((e: any) => e.id === roleId);
      dispatch({ type: 'setRowsToShow', payload: dataRow });
    }
    // eslint-disable-next-line
  }, [data, roleId]);

  const roleStatus = () => {
    if (state.data[0]?.completed) {
      return 'Completed';
    } else if (
      !state.data[0]?.on_hold &&
      !state.data[0]?.completed &&
      !state.data[0]?.show_info &&
      !state.data[0]?.vault
    ) {
      return 'Active';
    } else if (state.data[0]?.show_info) return 'Waiting';
    else if (state.data[0]?.on_hold) return 'On hold';
  };

  return (
    <div style={{ display: 'flex', width: '100%', marginTop: '10px', paddingBottom: '10px' }}>
      <div style={{ width: '50%' }}>
        <div style={{ alignContent: 'center', display: 'flex' }}>
          <Tooltip
            label={
              roleStatus() === 'Completed'
                ? 'Completed'
                : roleStatus() === 'Active'
                ? 'Active'
                : roleStatus() === 'Waiting'
                ? 'Waiting'
                : roleStatus() === 'On hold'
                ? 'On hold'
                : ''
            }
            gutter={-4}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <BsFillCircleFill
              color={
                roleStatus() === 'Completed'
                  ? '#E82429'
                  : roleStatus() === 'Active'
                  ? '#6FBE44'
                  : roleStatus() === 'On hold'
                  ? '#7039ED'
                  : roleStatus() === 'Waiting'
                  ? '#B3B3B3'
                  : ''
              }
              style={{ width: '10px', marginRight: '10px' }}
            />
          </Tooltip>
          <Text color={'#242424'} style={{ fontSize: '16px', display: 'inline' }}>
            {state.data[0]?.name}
          </Text>
        </div>
        <Text color={'#838485'} style={{ fontSize: '15px', marginLeft: '20px' }}>
          {state.data[0]?.info?.office_locations}
        </Text>
      </div>
      <div style={{ textAlign: 'right', flex: 1 }}>
        <div style={{ width: '100%' }}>
          <Text
            color={'#838485'}
            style={{ fontSize: '15px', paddingBottom: '1px' }}
            // sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            Created {getNumberOfDaysByDate(state.data[0]?.created_at.slice(0, 10))} {' days ago, on '}
            {moment(state.data[0]?.created_at.slice(0, 10)).format('MMM DD, YYYY')}
          </Text>
        </div>
        <Text color={'#838485'} style={{ fontSize: '15px' }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {state.data[0]?.greenhouse_job?.requisition_id && (
            <>
              <Image
                src={GreenHouseIcon}
                fit={'contain'}
                width={8}
                sx={{ margin: '0 5px 0 0', paddingRight: '5px', paddingTop: '3px' }}
              />
              {'Req Id '}
              {state.data[0]?.greenhouse_job && state.data[0]?.greenhouse_job?.requisition_id.concat(' ')}
            </>
          )}
          {state.data[0]?.lever_job && (
            <>
              <Image src={LeverIcon} fit={'contain'} width={14} sx={{ margin: '3px 5px 0 0', paddingRight: '5px' }} />
              {state.data[0]?.lever_job?.requisition_ids.replace(/[[\]"\\]/g, '').length > 0
                ? 'Req Id ' + state.data[0]?.lever_job?.requisition_ids.replace(/[[\]"\\]/g, '').concat(' ')
                : 'No Req Id'}
            </>
          )}
          {state.data[0]?.sap_job?.requisition_id && (
            <>
              <Image src={SapIcon} fit={'contain'} width={15} sx={{ margin: '3px 5px 0 0', paddingRight: '5px' }} />
              {'Req Id '}
              {state.data[0]?.sap_job && state.data[0]?.sap_job?.requisition_id.concat(' ')}
            </>
          )}
        </Text>
      </div>
    </div>
  );

};

export default MetricsDetail;
