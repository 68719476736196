import React, { forwardRef } from "react"
import { Select, SelectProps } from "@mantine/core"

const RolebotSelect = forwardRef<HTMLInputElement, SelectProps>(({size = 'md', data, ...props}, ref) => {
    return (
        <Select
            styles={{ rightSection: { pointerEvents: 'none' } }}
            ref={ref}
            data={data}
            size={size}
            {...props} />
    )
})

export default RolebotSelect