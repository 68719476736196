import React, { FormEvent, useMemo } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Group,
  Image,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import OktaLogo from 'assets/img/logos/okta_logo.webp';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Link } from 'react-router-dom';
import ExtLink from 'pages/Integration/ExtLink';
import { useConnectOktaMutation, useDisconnectOktaMutation, useGetCompanyQuery } from 'app/services/rolebot';
import { ConnectOktaPayload } from 'types/requests';
import ContactSupportLink from 'pages/Integration/ContactSupportLink';
import InfoPopover from 'components/InfoPopover/InfoPopover';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const OktaFormSchema = z.object({
  appId: z.string().nonempty('App ID is required'),
  appSecret: z.string().nonempty('App Secret is required'),
  oktaDomain: z.string().nonempty('Okta Domain is required'),
  forceAdmins: z.boolean(),
});

type OktaFormPayload = z.infer<typeof OktaFormSchema>;

const Okta = () => {
  const { disconnectIntegration } = useOpenModal();
  const { data } = useGetCompanyQuery();
  const isConnected = useMemo(() => {
    if (!data) return false;
    return Boolean(data?.okta);
    // eslint-disable-next-line
  }, [data]);
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    reset: resetForm,
  } = useForm({
    defaultValues: {
      appId: isConnected ? '*'.repeat(10) : '',
      appSecret: isConnected ? '*'.repeat(10) : '',
      oktaDomain: isConnected ? data?.okta?.base_url || '' : '',
      forceAdmins: isConnected ? !(data?.okta?.force_signin === 0) : false,
    },
    resolver: zodResolver(OktaFormSchema),
    mode: 'onBlur',
  });
  const [connectOkta, { isSuccess: successConnected, error, reset: resetConnect, isLoading: connectLoading }] =
    useConnectOktaMutation();
  const [disconnectOkta, { isSuccess: successDisconnected, reset: resetDisconnect, isLoading: disconnectLoading }] =
    useDisconnectOktaMutation();

  const onConnect = async (data: OktaFormPayload) => {
    resetDisconnect();
    const payload: ConnectOktaPayload = {
      base_url: data.oktaDomain,
      force_signin: data.forceAdmins,
      client_id: data.appId,
      client_secret: data.appSecret,
    };
    await connectOkta(payload).unwrap();
  };

  const handleDisconnect = async () => {
    resetConnect();
    await disconnectOkta().unwrap();
    resetForm();
  };

  const onDisconnect = (e: FormEvent) => {
    e.preventDefault();
    disconnectIntegration({
      integration: 'Okta',
      content: (
        <>
          <Text>
            This action will disable your ability to sign into Rolebot via Okta, and will affect all of your company’s
            users.
          </Text>
          <Text>Are you sure you want to disconnect this app?</Text>
        </>
      ),
      onAction: async () => {
        await handleDisconnect();
      },
    });
  };

  return (
    <Container size={'xl'} px={0}>
      <Group noWrap spacing={8} mb={16}>
        <RolebotButton type={'link'}>
          <FontAwesomeIcon icon={faChevronLeft} style={{paddingRight : '6px'}} />
          <Link to={'/settings/integrations'} style={{ color: '#249FC8', textDecoration: 'none' }}>
            Browse Apps
          </Link>
        </RolebotButton>
      </Group>
      <Group spacing={40} noWrap>
        <Box sx={{ maxWidth: 200, alignSelf: 'flex-start' }}>
          <Image src={OktaLogo} width={200} height={200} fit={'contain'} radius={15} />
          <Divider mt={40} mb={20} />
          <Stack spacing={4} mb={40}>
            <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }}>
              Requirements
            </Text>
            <Text color={'#B3B3B3'}>Paid Okta Plan</Text>
          </Stack>
          <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }} mb={4}>
            Support
          </Text>
          <Stack spacing={4}>
            <ExtLink to={'https://www.rolebot.io/okta-user-guide'} title={'Setup Guide'} />
            <ContactSupportLink />
            <ExtLink to={'https://www.okta.com/'} title={'Visit Okta'} />
          </Stack>
        </Box>
        <Box mb={'auto'}>
          <Stack spacing={20} mb={10}>
            <Title order={2} sx={{ color: '#242424' }}>
              Okta
            </Title>
            <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'}>
              Description
            </Text>
          </Stack>
          <Stack sx={{ color: '#838485' }} spacing={10} mb={50}>
            <Text>
              Okta is an identity and access management company. The Okta Identity Cloud enables organizations to
              securely connect the right people to the right technologies at the right time.
            </Text>
            <Text>
              Rolebot's Okta integration enables customers to log into Rolebot's platform using Okta as a single sign-on
              provider.
            </Text>
          </Stack>
          <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'} mb={10}>
            App Setup
          </Text>
          <Text color={'#838485'} mb={20}>
            Enter your Okta details to complete the integration. The Client ID and the Client Secret can be found on the
            "Sign On" tab of the Rolebot app in Okta. Click{' '}
            <Text component={'a'} href={'https://www.rolebot.io/okta-user-guide'} target={'_blank'} color={'#53AFCD'}>
              here
            </Text>{' '}
            for more details.
          </Text>
          <form onSubmit={isConnected ? onDisconnect : handleSubmit(onConnect)}>
            <Stack>
              <TextInput
                label={'App Client ID'}
                {...register('appId')}
                error={errors?.appId?.message}
                disabled={isConnected}
              />
              <TextInput
                label={'App Client Secret'}
                {...register('appSecret')}
                error={errors?.appSecret?.message}
                disabled={isConnected}
              />
              <TextInput
                label={'Okta Domain'}
                {...register('oktaDomain')}
                error={errors?.oktaDomain?.message}
                disabled={isConnected}
              />
              <Checkbox
                disabled={isConnected}
                label={
                  <Group noWrap spacing={4}>
                    Force Okta Sign-in for non-admin users
                    <InfoPopover
                      message={
                        'The ‘forced Okta sign in’ will force all non-admin users to log in to Rolebot via Okta. Sign in via Rolebot will be blocked.'
                      }
                    />
                  </Group>
                }
                {...register('forceAdmins')}
                color={'violet'}
              />
              <Button
                ml={'auto'}
                px={30}
                type={'submit'}
                disabled={isConnected ? false : !isValid}
                loading={isConnected ? disconnectLoading : connectLoading}
              >
                {isConnected ? 'Disconnect' : 'Add to Rolebot'}
              </Button>
            </Stack>
          </form>
          {error && (
            <Alert color={'red'} mt={20}>
              {/*@ts-ignore*/}
              {error?.data?.message}
            </Alert>
          )}
          {successConnected && (
            <Alert color={'green'} mt={20}>
              Successfully connected to Okta
            </Alert>
          )}
          {successDisconnected && (
            <Alert color={'green'} mt={20}>
              Successfully disconnected from Okta
            </Alert>
          )}
        </Box>
      </Group>
    </Container>
  );
};
export default Okta;
