import React, { useMemo, useReducer } from 'react';
import { createStyles, Group, Text, Transition } from '@mantine/core';
import { useGetCompanyQuery, useGetTopMessagesQuery } from 'app/services/rolebot';
import { useMediaQuery } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
    container: {
      justifyContent: 'space-between',
    },
    text: {
      fontSize: 15,
    },
}));

const isURL = (url) => {
    try{
        new URL(url)
        return true
    } catch (err) {
        return false
    }
}

const TopMessage = () => {
    const { classes } = useStyles();
    const isMediumBreakpoint = useMediaQuery('(min-width: 993px)');
    const modals = useModals();
    const { data: topMessage } = useGetTopMessagesQuery()
    const navigate = useNavigate();


    const handleLinkClick = () => {

        if (topMessage.modal != null) {

            modals.openContextModal( 'announcements' , {
                closeOnClickOutside: false,
                size: 'auto',
                styles: {modal: {padding: '0px !important'}},
                innerProps: {
                    ...topMessage.modal,
                },
            })

        } else {

            if(isURL(topMessage.cta_url)) {
                window.open(topMessage.cta_url, "_blank")
            } else {
                navigate(topMessage.cta_url)
            }
        }
    }
    return (
        <Transition transition={'slide-down'} mounted={topMessage != null}>
            {(styles) => (
            <div
                style={{
                ...styles,
                paddingTop: '5px',
                paddingBottom: '5px',
                backgroundColor: 'rgba(64, 165, 208, 0.12)',
                textAlign: 'center'
                }}
            >
                <Group sx={{ justifyContent: 'center', height: '100%' }} align={'center'} px={10} spacing={0} noWrap>
                    <Text style={{wordWrap: 'break-word', color: '#242424'}} fw={400} fz={10}>
                        {topMessage.content}
                        <span onClick={handleLinkClick} style={{color: '#40A5D0', cursor:'pointer'}}> {topMessage.cta_text}</span>
                    </Text>
                </Group>
            </div>
            )}
        </Transition>
    )
}

export default TopMessage