import React from 'react';
import { Stack, Image, Title, Text, Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import DizzyRolebot from 'assets/img/rolebot-dizzy.svg'; // Ensure the path is correct
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    subHeading: {
        fontSize: 16,
        fontWeight: 400,
        color: '#838485',
        textAlign: 'center',
    },
  }));

const NotFound = () => {
    const { classes } = useStyles();
    const navigate = useNavigate();

    return (
        <Stack align="center" justify="center" style={{ height: '100vh' }}>
            <Image src={DizzyRolebot} width={150} />
            <Title order={2}>Oops! 404 Error</Title>
            <Text className={classes.subHeading}>
                This page is playing hide and seek! We couldn’t find it...<br />
                It might not exist, or you might not have access to it.
            </Text>
            <Text component="span" style={{ color: '#4BA7C3', cursor: 'pointer' }} onClick={() => navigate('/roles')}>
                Go back to All Roles
            </Text>
        </Stack>
    );
};

export default NotFound;