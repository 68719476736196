import React, { useEffect } from 'react';
import { useGetCompanyQuery } from 'app/services/rolebot';
import Echo from 'utils/echo';

const LeverAuthExpired = (props: any) => {
  const { data, refetch } = useGetCompanyQuery();

  useEffect(() => {
    if (process.env.REACT_APP_WEB_SOCKETS_DISABLED === 'false') {
      if (data) {
        Echo.channel(`company${data?.id}`).listen('LeverTokenRefreshed', (e: any) => {
          refetch();
        });
        return () => Echo.leaveChannel(`company${data?.id}`);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return <>{props.children}</>;
};

export default LeverAuthExpired;
