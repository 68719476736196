import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Group, Popover, Stack, Title, createStyles, Text } from "@mantine/core";
import useAppSelector from "hooks/useAppSelector";
import { useState } from "react";
import { boolean } from "zod";

const useStyles = createStyles((theme, { hasActiveFilters, showFilters }: any) => ({
    title: {
      color: '#242424',
    },
    button: {
      backgroundColor: hasActiveFilters || showFilters ? '#EEEEEE' : 'transparent',
      color: hasActiveFilters ? '#351B77' : '#838485',
      padding: 10,
      borderRadius: 6,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#EEEEEE',
      }
    },
    filterIcon: {
      height: '16px',
      paddingBottom: '2px'
    }
  }));

type FiltersProps = {
  activeFilters: {active: boolean, completed: boolean},
  onFiltersChange: (arg: {active:boolean, completed: boolean}) => void
}

type IFilter = {
  active: boolean,
  completed: boolean
}

const Filters = ({activeFilters, onFiltersChange}:FiltersProps) => {
    const hasActiveFilters = activeFilters.active || activeFilters.completed
    const activeFiltersCount = (activeFilters.active ? 1 : 0) + (activeFilters.completed ? 1 : 0)
    const [showFilters, setShowFilters] = useState(false);
    const { classes } = useStyles({ hasActiveFilters, showFilters });

    const setChecked = (checked: boolean, filter: string) => {
      onFiltersChange({
        ...activeFilters,
        [filter] : checked
      } as Pick<IFilter, keyof IFilter>)
    }

    const onClear = () => {
      onFiltersChange({
        active: false,
        completed: false
      })
    }
    
    return (
        <Popover
          opened={showFilters}
          onClose={() => setShowFilters(false)}
          target={
            <Group spacing={5} onClick={() => setShowFilters(true)} className={classes.button}>
              <FontAwesomeIcon icon={faFilter} fill={hasActiveFilters ? '#351B77' : '#838485'} className={classes.filterIcon}/>
              {hasActiveFilters ? `Filter / ${activeFiltersCount}` : 'Filter'}
            </Group>
          }
          width={320}
          position="bottom"
          placement="start"
          styles={{
            body: {
              borderRadius: 6,
              border: '1px solid #DFE1E1',
              boxShadow: '0px 5px 15px rgba(131, 132, 133, 0.45)',
            },
            inner: {
              padding: '20px 30px'
            },
          }}
        >
          <Stack spacing={0}>
            <Group position={'apart'} mb={20}>
              <Title order={5} color={'#242424'} className={classes.title}>
                Filters
              </Title>
              <Text size={'md'} color={'#B3B3B3'} sx={{ cursor: 'pointer' }} onClick={onClear}>
                Clear All
              </Text>
            </Group>
            <Text component={'h6'} my={0} mb={6} color={'#4F4F4F'} sx={{ fontFamily: 'Roboto', fontWeight: 500 }}>
              Status
            </Text>
            <Stack spacing={8}>
              <Checkbox size={'sm'} checked={activeFilters.active} onChange={(event) => setChecked(event.currentTarget.checked, 'active')} label={'Active'} />
              <Checkbox size={'sm'} checked={activeFilters.completed} onChange={(event) => setChecked(event.currentTarget.checked, 'completed')} label={'Completed'} />
            </Stack>
          </Stack>
        </Popover>
      );
}

export default Filters