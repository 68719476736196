import { Alert, Box, Button, createStyles, Group, List, Stack, Text } from '@mantine/core';
import { usePauseRoleMutation } from 'app/services/rolebot';
import { useEffect } from 'react';
import { ContextModalProps, useModals } from '@mantine/modals';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { PauseRoleProps } from 'types/modals';

const useStyles = createStyles((theme) => ({
  container: {
    justifyContent: 'center',
    [theme.fn.largerThan('md')]: {
      justifyContent: 'center',
    },
  },

  bottomButtons: {
    marginTop: 'auto',
    button: {
      ':first-child': {
        order: 2,
      },
      ':last-child': {
        order: 1,
      },
    },
    [theme.fn.largerThan('md')]: {
      marginTop: 30,
      flexGrow: 0,
      flexDirection: 'row',
      button: {
        ':first-child': {
          order: 1,
        },
        ':last-child': {
          order: 2,
        },
      },
    },
  },
}));

const PauseRole = ({ id, innerProps }: ContextModalProps<PauseRoleProps>) => {
  const { roleId, roleName } = innerProps;
  const [pauseRole, { isLoading, isSuccess, isError }] = usePauseRoleMutation();
  const modals = useModals();
  const { classes } = useStyles();

  const handleClose = () => {
    modals.closeAll();
  };

  const handlePauseRole = async () => {
    await pauseRole({
      roleId: roleId,
      payload: {
        on_hold: 1,
      },
    }).unwrap()
    .then((response) => {
      const role = response.role;
      if (role && innerProps.onPatchCompleted) {
        innerProps.onPatchCompleted('pauseRole', role.id);
      }
    });
  };

  useEffect(() => {
    if (!isSuccess) return;
    modals.closeAll();
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <>
      <TitleWithClose id={id} title={'Pause Role'} mb={20} />
      <>
        <Group direction={'column'} noWrap className={classes.container}>
          <Stack>
            <Text color={'#242424'}>
              You’re putting the <strong style={{ fontFamily: 'Roboto', fontWeight: 500 }}>{roleName}</strong> on hold.
            </Text>
            <Text color={'#242424'}>This action will:</Text>
          </Stack>
          <Box sx={{ height: 'auto', overflow: 'hidden', overflowY: 'auto' }}>
            <List withPadding styles={{ item: { color: '#242424' } }}>
              <List.Item>Pause Rolebot from surfacing new rounds of talent</List.Item>
              <List.Item>Pause Rolebot’s engagement with all active candidates</List.Item>
            </List>
          </Box>
          <Text color={'#838485'}>You can choose to reactivate this role when you’re ready.</Text>
        </Group>
        {isError && (
          <Alert title={'Oops, something went wrong!'} color={'orange'}>
            We were not able to pause this Role.
          </Alert>
        )}
        <Group position={'right'} className={classes.bottomButtons}>
          <Button onClick={handleClose} disabled={isLoading} variant={'outline'}>
            Cancel
          </Button>
          <Button px={40} onClick={handlePauseRole} loading={isLoading} loaderPosition={'right'}>
            Pause Role
          </Button>
        </Group>
      </>
    </>
  );
};
export default PauseRole;
