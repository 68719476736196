import React, {useState, useEffect} from 'react';
import {
    Button,
    Checkbox,
    createStyles,
    Group,
    Image,
    Input,
    InputWrapper,
    Stack,
    Text,
    TextInput,
    Title
} from '@mantine/core';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import * as z from 'zod';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import {usePostCustomerPricinglanMutation} from 'app/services/rolebot';
import {ChevronDown} from 'tabler-icons-react';
import RolebotSelect from 'components/Inputs/RolebotSelect';

const useStyles = createStyles((theme) => ({
    button: {
        marginBottom: '20px',
        [theme.fn.largerThan('sm')]: {
            marginLeft: 'auto',
        },
    },
    member: {
        marginBottom: 24,
        [theme.fn.largerThan('md')]: {
            marginInline: 44,
        },
    },
    backButton: {
        display: 'none',
        [theme.fn.largerThan('md')]: {
          display: 'flex',
        },
    },
}));

const CreateAccountSchema = z.object({
    contact_name: z.string()
                    .min(2, 'Must be at least 2 characters long')
                    .max(50, 'Must be less than 50 characters long')
                    .nonempty('Name is required'),
    contact_email: z.string()
                    .email('Please enter a valid email address')
                    .nonempty('Email is required'),
    company_name: z.string()
                    .min(1, 'Must be at least 1 character long')
                    .max(50, 'Must be less than 50 characters long')
                    .nonempty('Company is required'),
    password: z.string()
                .min(8, 'Password must be at least 8 characters long')
                .max(20, 'Password must be less than 20 characters long')
                .refine((v) => v.match(/[A-Z]/), 'Password must contain at least one uppercase letter')
                .refine((v) => v.match(/[a-z]/), 'Password must contain at least one lowercase letter'),
    how_hear_about_us: z.string()
                        .nonempty('How did you hear about us is required'),
    agreeTerms: z.boolean()
                .refine((v) => v, 'You must agree to the terms and conditions'),
});

interface CreateAccountProps {
    selectedPlan: any;
    handleBack: () => void;
}

const CreateAccount = ({ selectedPlan, handleBack} : CreateAccountProps) => {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const [sourceOptions, setSourceOptions] = useState([
        { value: "Social Media (LinkedIn, Twitter, etc)", label: "Social Media (LinkedIn, Twitter, etc)" },
        { value: "Search Engines (Google, Yahoo, etc)", label: "Search Engines (Google, Yahoo, etc)" },
        { value: "Lever", label: "Lever" },
        { value: "Greenhouse", label: "Greenhouse" },
        { value: "ICIMs", label: "ICIMs" },
        { value: "SAP", label: "SAP" },
        { value: "Word of Mouth", label: "Word of Mouth" },
        { value: "Website", label: "Website" },
        { value: "Other", label: "Other" },
    ]);
    const [selectedHowHearAboutUs, setSelectedHowHearAboutUs] = useState(() => {
        const savedFormData = sessionStorage.getItem('createAccountFormData');
        const formData = savedFormData ? JSON.parse(savedFormData) : {};
        return formData.how_hear_about_us || '';
    });

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        setValue,
        getValues,
        reset,
        watch
    } = useForm({
        defaultValues: (() => {
            const savedFormData = sessionStorage.getItem('createAccountFormData');
            return savedFormData ? JSON.parse(savedFormData) : {
                contact_name: '',
                contact_email: '',
                company_name: '',
                password: '',
                how_hear_about_us: '',
                agreeTerms: false,
            };
        })(),
        resolver: zodResolver(CreateAccountSchema),
        mode: 'all'
    });

    useEffect(() => {
        const subscription = watch((data, { name, type }) => {
            // Save the form data to sessionStorage, excluding the password
            sessionStorage.setItem('createAccountFormData', JSON.stringify({...data, password: ''}));
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        // Check if selectedPlan exists in sessionStorage upon component mount
        const savedPlan = sessionStorage.getItem('selectedPlan');

        if (!savedPlan) {
            // If not, navigate back to the plan selection step
            navigate(`/pricing?step=choose_plan`);
        }
    }, [navigate]);

    const [postCustomerPricingPlan, { isLoading }] = usePostCustomerPricinglanMutation();

    const handleCreateAccount = async (payload: any) => {
        payload = {...payload, ...selectedPlan};
        await postCustomerPricingPlan({ payload })
            .unwrap()
            .then((response) => {
                navigate('/payment?token=' + response.uuid);
                sessionStorage.removeItem('selectedPlan');
                sessionStorage.removeItem('createAccountFormData');
            })
            .catch((err) => {
                console.log(err);
                navigate('/company-already-exists');
            });
    }

    const handleSelectedHowHearAboutUs = (value: string) => {
        setValue('how_hear_about_us', value, { shouldValidate: true });
        setSelectedHowHearAboutUs(value);
    }

    const termsLabel = (
        <Text color='#838485'>
            I agree to be bound by Rolebot's {' '}
            <Text<'a'>
                component={'a'}
                color={'#7039ED'}
                href={'https://www.rolebot.io/terms-of-service'}
                target={'_blank'}
            >
                 Terms of Service
            </Text> and its {' '}
            <Text<'a'>
                component={'a'}
                color={'#7039ED'}
                href={'https://www.rolebot.io/privacy-policy'}
                target={'_blank'}
            >
                Privacy Policy
            </Text> and represent that I have the necessary authority to enter into this agreement.
        </Text>
    );

    return (
        <>
            <NavLink to={'/'}>
                <Image src={LogoLetters} width={180} fit={'contain'} mb={30} />
            </NavLink>
            <Text
                color='#838485'
                mb={10}
                style={{
                    fontFamily: 'Roboto'
                }}
            >
                Step 2
            </Text>
            <Title order={3} mb={20}>
                Create an Account
            </Title>
            <Stack spacing={'xs'}>
                <form onSubmit={handleSubmit(handleCreateAccount)} id="create-account-form">
                    <TextInput
                        size={'md'}
                        label="Full name*"
                        placeholder="Jane Doe"
                        autoComplete="contact_name"
                        {...register('contact_name')}
                        error={errors?.contact_name?.message}
                        tabIndex={1}
                        autoCapitalize={'false'}
                        mb={16}
                    />
                    <TextInput
                        size={'md'}
                        label="Work Email*"
                        placeholder="jane@acme.com"
                        autoComplete="contact_email"
                        {...register('contact_email')}
                        error={errors?.contact_email?.message}
                        tabIndex={1}
                        autoCapitalize={'false'}
                        mb={16}
                    />
                    <TextInput
                        size={'md'}
                        label="Company name*"
                        placeholder="ACME"
                        autoComplete="company_name"
                        {...register('company_name')}
                        error={errors?.company_name?.message}
                        tabIndex={1}
                        autoCapitalize={'false'}
                        mb={16}
                    />
                    <InputWrapper error={errors?.password?.message} size={'md'} mb={20}>
                        <Group position={'apart'} mb={4}>
                            <Text component={'label'} size={'md'} weight={500} color="#212529" htmlFor={'password'}>
                                Password*
                            </Text>
                        </Group>
                        <Input
                            id={'password'}
                            sx={{ width: '100%' }}
                            placeholder={'8+ characters'}
                            size={'md'}
                            {...register('password')}
                            tabIndex={2}
                            type={'password'}
                        />
                    </InputWrapper>
                    <InputWrapper error={errors?.how_hear_about_us?.message} size={'md'} mb={20}>
                        <Group position={'apart'} mb={4}>
                            <Text component={'label'} size={'md'} weight={500} color="#212529" htmlFor={'how_hear_about_us'}>
                                How did you hear about us?*
                            </Text>
                        </Group>
                        <RolebotSelect
                            rightSection={<ChevronDown size={20} />}
                            data={sourceOptions}
                            value={selectedHowHearAboutUs}
                            onChange={handleSelectedHowHearAboutUs}
                            size='md'
                            placeholder='Select...'
                            onBlur={() => {
                                setValue('how_hear_about_us', getValues('how_hear_about_us'), { shouldValidate: true });
                            }}
                            tabIndex={2}
                        />
                    </InputWrapper>
                    <Checkbox
                        px={10}
                        size={'sm'}
                        {...register('agreeTerms')}
                        label={termsLabel}
                    />
                </form>
                <Button
                    type="submit"
                    className={classes.button}
                    mt="xl"
                    form="create-account-form"
                    loading={isLoading}
                    loaderPosition={'right'}
                    px={20}
                    tabIndex={3}
                    disabled={!isValid}
                >
                    Create account
                </Button>
                <Group align={'center'} position={'center'} spacing={4} className={classes.member}>
                    <Text>Have an account?</Text>
                    <Text component={Link} to={'/'} color={'#7039ED'}>
                        Sign in
                    </Text>
                </Group>
            </Stack>
        </>
    );
};

export default CreateAccount;
