import React, { FormEvent, useMemo, useEffect } from 'react';
import {
  Alert,
  Box,
  Container,
  Divider,
  Group,
  Image,
  List,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import GreenhouseLogo from 'assets/img/logos/greenhouse_logo.png';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Link } from 'react-router-dom';
import ExtLink from 'pages/Integration/ExtLink';
import { useConnectGreenhouseMutation, useDeleteGreenhouseMutation, useGetCompanyQuery } from 'app/services/rolebot';
import ContactSupportLink from 'pages/Integration/ContactSupportLink';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const GreenhouseFormSchema = z.object({
  token: z.string().nonempty('Token is required').min(10, 'Token is too short'),
});

type GreenhouseFormPayload = z.infer<typeof GreenhouseFormSchema>;

const Greenhouse = () => {
  const { disconnectIntegration } = useOpenModal();
  const { data: company } = useGetCompanyQuery();

  const isConnected = useMemo(() => {
    if (!company) return false;
    return Boolean(company.greenhouse);
    // eslint-disable-next-line
  }, [company]);

  useEffect(() => {
    resetForm({ token: company?.greenhouse ? '*'.repeat(10) : '' })
  }, [company]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    reset: resetForm,
    setValue,
  } = useForm({
    defaultValues: useMemo(() => {
      if (!company) return { token: '' };
      return { token: company.greenhouse ? '*'.repeat(10) : '' };
    }, [company]),
    resolver: zodResolver(GreenhouseFormSchema),
    mode: 'onChange',
  });

  const [disconnect, { isSuccess: disconnectedSuccess, isLoading: loadingDisconnect, reset: resetDisconnect }] =
    useDeleteGreenhouseMutation();
  const [connect, { isError, error, isLoading, isSuccess, reset }] = useConnectGreenhouseMutation();

  const onConnect = async (payload: GreenhouseFormPayload) => {
    resetDisconnect();
    try {
      await connect(payload).unwrap();
      setValue('token', '*'.repeat(10));
    } catch (e) {
      console.log('error connecting to greenhouse');
    }
  };

  const handleDisconnect = async () => {
    reset();
    try {
      await disconnect().unwrap();
      setValue('token', '');
    } catch (e) {
      console.error(e);
    }
  };

  const onDisconnect = (e: FormEvent) => {
    e.preventDefault();
    disconnectIntegration({
      integration: 'Greenhouse',
      content: (
        <>
          <Text>
            This action will delete all the connections between Rolebot and Greenhouse and will affect all of your
            company’s users.
          </Text>
          <Text>
            You will continue to have access to any roles that were previously created in Rolebot via Greenhouse, but
            will not have access to any new information.
          </Text>
          <Text>Are you sure you want to disconnect this app?</Text>
        </>
      ),
      onAction: async () => {
        await handleDisconnect();
      },
    });
  };

  return (
    <Container size={'xl'} px={0}>
      <Group noWrap direction={'column'}>
        <Group noWrap spacing={8}>
          <RolebotButton type={'link'}>
            <FontAwesomeIcon icon={faChevronLeft} style={{paddingRight : '6px'}} />
            <Link to={'/settings/integrations'} style={{ color: '#249FC8', textDecoration: 'none' }}>
              Browse Apps
            </Link>
          </RolebotButton>
        </Group>
        <Group noWrap spacing={40}>
          <Box sx={{ maxWidth: 200, alignSelf: 'flex-start' }}>
            <Image src={GreenhouseLogo} width={200} height={200} fit={'contain'} radius={15} />
            <Divider mt={40} mb={20} />
            <Stack spacing={4} mb={40}>
              <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }}>
                Requirements
              </Text>
              <Text color={'#B3B3B3'} sx={{ fontSize: 16 }}>
                Paid Greenhouse Plan
              </Text>
            </Stack>
            <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }} mb={4}>
              Support
            </Text>
            <Stack spacing={4}>
              <ExtLink
                to={'https://support.greenhouse.io/hc/en-us/articles/4757642449563-Rolebot-integration'}
                title={'Setup Guide'}
              />
              <ContactSupportLink />
              <ExtLink to={'https://www.greenhouse.io/'} title={'Visit Greenhouse'} />
            </Stack>
          </Box>
          <Box mb={'auto'}>
            <Stack spacing={20} mb={10}>
              <Title order={2} sx={{ color: '#242424' }}>
                Greenhouse
              </Title>
              <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'}>
                Description
              </Text>
            </Stack>
            <Stack sx={{ color: '#838485' }} spacing={10} mb={50}>
              <Text>
                Greenhouse software designs tools that help companies source, interview, hire and onboard the right
                talent.
              </Text>
              <Text>Greenhouse Recruiting's integration with Rolebot allows you to:</Text>
              <List sx={{ color: '#838485' }} ml={20}>
                <List.Item>Launch Greenhouse Jobs in Rolebot</List.Item>
                <List.Item>Link Greenhouse jobs to Rolebot roles</List.Item>
                <List.Item>Search for Rolebot candidates in your company's Candidates + Prospects database</List.Item>
                <List.Item>Export Rolebot candidates to your company’s Greenhouse database</List.Item>
              </List>
            </Stack>
            <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'} mb={10}>
              App Setup
            </Text>
            <form onSubmit={isConnected ? onDisconnect : handleSubmit(onConnect)}>
              <Group align={'center'} sx={{ alignItems: 'center' }}>
                <TextInput
                  disabled={isConnected}
                  label={'API Key'}
                  placeholder={'Paste your API Key here'}
                  {...register('token')}
                  error={errors?.token?.message}
                  sx={{ flex: 1 }}
                  styles={{ label: { color: '#4f4f4f' } }}
                />

                <RolebotButton
                  type={'neutral'}
                  sx={{ alignSelf: errors?.token ? 'center' : 'flex-end' }}
                  disabled={isConnected ? !isValid : !isValid}
                  loading={isConnected ? loadingDisconnect : isLoading}
                >
                  {isConnected || isSuccess ? 'Disconnect' : 'Add to Rolebot'}
                </RolebotButton>
              </Group>
              <Text color={'#B3B3B3'} size={'sm'} mt={10}>
                Create it in Greenhouse’s API Configuration Page and paste it here.{' '}
                <strong style={{ fontFamily: 'Roboto' }}>Type</strong> should be{' '}
                <strong style={{ fontFamily: 'Roboto' }}>Harvest</strong>.
              </Text>
            </form>

            {isError && (
              <Alert color={'red'} mt={20}>
                {/*@ts-ignore*/}
                {error?.data?.message}
              </Alert>
            )}
            {isSuccess && (
              <Alert color={'green'} mt={20}>
                Successfully connected to Greenhouse
              </Alert>
            )}
            {disconnectedSuccess && (
              <Alert color={'green'} mt={20}>
                Successfully disconnected from Greenhouse
              </Alert>
            )}
          </Box>
        </Group>
      </Group>
    </Container>
  );
};

export default Greenhouse;
