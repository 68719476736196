import React from 'react';
import { Group, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcase } from '@fortawesome/pro-light-svg-icons';

interface TalentRoleProps {
  role?: string;
  withIcon: boolean;
}

const TalentRole = ({ role = '', withIcon = false }: TalentRoleProps) => {
  return (
    <Group noWrap align={'center'} spacing={5}>
      {withIcon && (
        <Group align={'center'} sx={{ width: 20 }} spacing={0}>
          <FontAwesomeIcon icon={faSuitcase} style={{ height: '15px', color: '#4F4F4F', marginBottom: '2px' }} />
        </Group>
      )}
      <Text
        lineClamp={1}
        sx={{
          color: '#4F4F4F',
          wordBreak: 'break-all',
        }}
      >
        {role}
      </Text>
    </Group>
  );
};

export default TalentRole;
