import React, { useState, useEffect } from 'react';
import {
    Button,
    createStyles,
    Group,
    Image,
    Text,
    Title,
    Col,
    Box,
    Stack,
    Popover
} from '@mantine/core';
import { ChevronDown } from "tabler-icons-react";
import RolebotSelect from 'components/Inputs/RolebotSelect';
import ProPlan from 'assets/img/pro-plan.png';
import { IPricingPlan } from 'types/pricing';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { useSearchParams } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    member: {
        marginTop: 24,
        [theme.fn.largerThan('md')]: {
            marginInline: 44,
        },
    }
}));

const FeaturePopover = ({ message }: { message: string }) => {
    const [opened, setOpened] = React.useState(false);

    return (
        <Popover
            opened={opened}
            onClose={() => setOpened(false)}
            position="top"
            placement="center"
            trapFocus={false}
            closeOnEscape={false}
            transition="pop-top-left"
            target={
                <Group align={'center'}>
                    <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{
                            height: '15px',
                            marginBottom: '2px',
                            color: '#838485'
                        }}
                        onMouseEnter={() => setOpened(true)}
                        onMouseLeave={() => setOpened(false)}
                    />
                </Group>
            }
            styles={{
                inner: {
                  display: 'flex',
                  padding: '8px 16px',
                },
                body: {
                  maxWidth: '300px',
                  color: '#000',
                  backgroundColor: '#FFF',
                },
              }}
        >
            <Group>
                <Text size="sm">{message}</Text>
            </Group>
        </Popover>
    );
};

const PricingPlan = ({ pricingPlan, isYearly, handlePlanSelected, handleContactSales }: {
    pricingPlan: IPricingPlan;
    isYearly: boolean;
    handlePlanSelected: (data: any, shouldNavigate?: boolean) => void;
    handleContactSales: () => void;
}) => {
    const { classes } = useStyles();

    // Attempt to read the selected plan from sessionStorage
    const savedPlan = JSON.parse(sessionStorage.getItem('selectedPlan') || '{}');
    const initialRoleNumber = savedPlan && savedPlan.pricing_plan_id === pricingPlan.id ? savedPlan.number_of_roles : 1;
    const [selectedRoleNumber, setSelectedRoleNumber] = useState(initialRoleNumber);

    const [roleNumber, setRoleNumber] = useState([
        { value: "1", label: "1 role" },
        { value: "2", label: "2 roles" },
        { value: "3", label: "3 roles" },
        { value: "4", label: "4 roles" },
        { value: "5", label: "5 roles" }
    ]);

    const [searchParams] = useSearchParams()

    const [planPrice, setPlanPrice] = useState(pricingPlan.monthly_price);

    useEffect(() => {
        if (pricingPlan.monthly_price) {
            setPlanPrice(isYearly ? Math.round(pricingPlan.monthly_price * 0.9) : pricingPlan.monthly_price);
        }
    }, [isYearly, pricingPlan.monthly_price]);

    useEffect(() => {
        // Check if a selected plan already exists in sessionStorage
        const existingSelectedPlan = sessionStorage.getItem('selectedPlan');

        if (!existingSelectedPlan) {
            // If not, call handlePlanSelected with default values
            handlePlanSelected({
                pricing_plan_id: pricingPlan.id,
                number_of_roles: selectedRoleNumber,
                amount: planPrice,
                subscription_type: isYearly ? 'Annual' : 'Month to Month',
                utm_source: searchParams.get('utm_source'),
                utm_medium: searchParams.get('utm_medium'),
                utm_campaign: searchParams.get('utm_campaign'),
            }, false);
        }
    }, []); // Empty dependency array to run only on initial component mount

    const handleSelectedRoleNumber = (value: string) => {
        const newSelectedRoleNumber = parseInt(value);
        setSelectedRoleNumber(newSelectedRoleNumber);

        // Retrieve the current selectedPlan from sessionStorage
        const currentSelectedPlan = JSON.parse(sessionStorage.getItem('selectedPlan') || '{}');

        // Update the selectedPlan with the new role number
        const updatedSelectedPlan = {
            ...currentSelectedPlan,
            number_of_roles: newSelectedRoleNumber,
            pricing_plan_id: pricingPlan.id // Ensure the plan ID is also stored/updated
        };

        // Save the updated selectedPlan back to sessionStorage
        sessionStorage.setItem('selectedPlan', JSON.stringify(updatedSelectedPlan));
    };

    const handleSelectedPlanChanged = () => {
        handlePlanSelected({
            pricing_plan_id: pricingPlan.id,
            number_of_roles: selectedRoleNumber,
            amount: planPrice,
            subscription_type: isYearly ? 'Annual' : 'Month to Month',
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium'),
            utm_campaign: searchParams.get('utm_campaign'),
        });
    }

    return (
        <>
            <Col key={pricingPlan.name} span={5}>
                <Box
                    sx={{
                        width: '100%',
                        backgroundColor: pricingPlan.name === 'Lite' ? 'rgba(199, 79, 255, 0.04)' : 'rgba(58, 131, 246, 0.04)',
                        border: pricingPlan.name === 'Lite' ? '1px solid #7039ED' : '1px solid #3A83F6',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '40px',
                        paddingBottom: '40px',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        height: '100%',
                    }}
                >
                    <Stack align={'center'}>
                        <Title order={2}
                            style={{
                                fontSize: '22px',
                                fontWeight: 500,
                                color: '#242424'
                            }}
                        >
                            {pricingPlan.name}
                        </Title>
                        {pricingPlan.show_role_quantity_dropdown ?
                            (
                                <RolebotSelect
                                    rightSection={<ChevronDown size={20} />}
                                    data={roleNumber}
                                    value={selectedRoleNumber.toString()}
                                    onChange={handleSelectedRoleNumber}
                                    size='sm'
                                />
                            ) : (
                                <Text
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        fontSize: '16px',
                                        color: '#242424',
                                        fontWeight: 400
                                    }}
                                >
                                    {pricingPlan.alternate_dropdown_text}
                                </Text>
                            )
                        }
                        {planPrice ?
                            (
                                <Text
                                    style={{
                                        fontSize: '35px',
                                        color: '#242424'
                                    }}
                                >
                                    ${planPrice * selectedRoleNumber}<span style={{ fontSize: '18px' }}> / month</span>
                                </Text>
                            ) : (
                                <Image src={ProPlan} alt={'ProPlan'} fit={'contain'} height={40} pt={10} />
                            )
                        }
                        <Text
                            style={{
                                fontSize: '12px',
                                fontWeight: 600,
                                color: '#242424',
                                fontFamily: 'Roboto'
                            }}
                        >
                            {pricingPlan.plan_includes_text}
                        </Text>
                        <div style={{ height: '130px' }}>
                            {pricingPlan.features.map((feature) => (
                                <Group
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        paddingTop: '5px',
                                    }}
                                    key={feature.id}
                                >
                                    <Text
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                            color: '#242424'
                                        }}
                                    >
                                        {feature.name}
                                    </Text>
                                    <FeaturePopover message={feature.description} />
                                </Group>
                            ))}
                        </div>
                        {pricingPlan.show_contact_sales_button ?
                            (
                                <RolebotButton
                                    onClick={handleContactSales}
                                    type={'secondary'}
                                    mt="xl"
                                >
                                    Contact Sales
                                </RolebotButton>
                            ) : (
                                <Button
                                    mt="xl"
                                    px={20}
                                    tabIndex={3}
                                    onClick={handleSelectedPlanChanged}
                                >
                                    Select and continue
                                </Button>
                            )
                        }
                    </Stack>
                </Box>
            </Col>
        </>
    );
}

export default PricingPlan;