import { Alert, Box, Group, List, Text } from '@mantine/core';
import { useOpenRoleMutation } from 'app/services/rolebot';
import { useEffect } from 'react';
import { ContextModalProps, useModals } from '@mantine/modals';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { OpenRoleProps } from 'types/modals';

const OpenRole = ({ id, innerProps }: ContextModalProps<OpenRoleProps>) => {
  const { roleId, roleName, reload } = innerProps;
  const [openRole, { isLoading, isSuccess, isError }] = useOpenRoleMutation();
  const modals = useModals();

  const handleClose = () => {
    modals.closeModal(id)
  };

  const handleOpenRole = async () => {
    await openRole({
      roleId: roleId,
      payload: {
        completed: false,
      },
      reload: reload
    }).unwrap()
    .then((response) => {
      console.log('response', response);
      const role = response.role;
      if (role && innerProps.onPatchCompleted) {
        innerProps.onPatchCompleted('openRole', role.id);
      }
    });
  };

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <>
      <TitleWithClose id={id} title={'Reopen Role'} mb={20} />
      <>
        <Group align={'flex-start'} noWrap direction={'column'}>
          <Text color={'#242424'}>
            Do you want to reopen the <strong style={{ fontFamily: 'Roboto', fontWeight: 500 }}>{roleName}</strong>{' '}
            role?
          </Text>

          <Box sx={{ height: 'auto', overflow: 'hidden', overflowY: 'auto' }}>
            <Text>This action will:</Text>
            <List>
              <List.Item>Mark this role as open in Rolebot</List.Item>
              <List.Item>Start Rolebot surfacing new rounds of talent</List.Item>
              <List.Item>Start Rolebot’s engagement with all active candidates</List.Item>
            </List>
          </Box>
        </Group>
        {isError && (
          <Alert title={'Oops, something went wrong!'} color={'orange'}>
            We were not able to reopen this Role. Please try again.
          </Alert>
        )}
        <Group position={'right'} mt={40}>
          <RolebotButton type={'neutral'} onClick={handleClose}>
            Cancel
          </RolebotButton>

          <RolebotButton type={'primary'} onClick={handleOpenRole} loading={isLoading}>
            Confirm
          </RolebotButton>
        </Group>
      </>
    </>
  );
};

export default OpenRole;
