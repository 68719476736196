import React from 'react';
import { Box, Divider, Space, Stack, Title } from "@mantine/core";

interface TalentDrawerSectionProps {
  title: string;
  withDivider?: boolean;
  children: React.ReactNode;
}

const TalentDrawerSection = ({ title, withDivider = true, children }: TalentDrawerSectionProps) => {
  return (
    <Stack mb={20}>
      <Title order={6} m={0} p={0} style={{color: '#242424'}}>
        {title}
      </Title>
      <Box>
        {children}
        <Space h={8} />
      </Box>
      {withDivider && <Divider />}
    </Stack>
  );
};

export default TalentDrawerSection;
