import React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  SapRoleItem: {
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '8px',
    color: '#242424',
    padding: '2px 10px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      borderRadius: '5px',
    },
    '&:last-child': {
      marginBottom: '0',
    },
  },
  UnavailableSapRoleItem: {
    cursor: 'not-allowed',
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '8px',
    color: '#b3b3b3',
    padding: '4px 10px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
}));

const SapRoleItem = ({ sapRoleSelected, role }) => {
  const { classes } = useStyles();

  return (
    <>
      {role.is_available && (
        <div className={classes.SapRoleItem} onClick={() => sapRoleSelected(role)}>
          {role.sap_job_name}{' '}
          <span style={{ color: '#959595', marginLeft: 10, fontSize: '12px' }}>{'Req ID ' + role.requisition_id}</span>
        </div>
      )}

      {!role.is_available && (
        <div className={classes.UnavailableSapRoleItem}>
          {role.sap_job_name}{' '}
          <span style={{ color: '#b3b3b3', marginLeft: 10, fontSize: '12px' }}>{'Req ID ' + role.requisition_id}</span>{' '}
          <span style={{ color: '#b3b3b3', float: 'right', fontSize: '16px' }}>{'On Rolebot'}</span>
        </div>
      )}
    </>
  );
};

export default SapRoleItem;

SapRoleItem.propTypes = {
  role: PropTypes.object.isRequired,
  sapRoleSelected: PropTypes.func.isRequired,
};

SapRoleItem.defaultProps = {};
