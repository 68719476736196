import React from 'react';
import { createStyles, Group, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { X } from 'tabler-icons-react';
import { TitleWithCloseProps } from 'types';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  wrapper: {
    flexWrap: 'nowrap',
    paddingBottom: 10,
    justifyContent: 'center',
    [theme.fn.largerThan('md')]: {
      paddingBottom: 0,
    },
  },

  title: {
    marginLeft: 'auto',
    lineHeight: 1.3,
    textAlign: 'center',
    fontSize: theme.fontSizes.lg,
    color: '#242424',
    fontFamily: 'Roboto',
    fontWeight: 500,
    [theme.fn.largerThan('sm')]: {
      textAlign: 'left',
      marginLeft: '0',
      fontSize: 22,
    },
  },

  spacer: {
    width: 24,
    [theme.fn.largerThan('md')]: {
      display: 'none',
      width: 0,
    },
  },

  leftAction: {
    width: 24,
    [theme.fn.largerThan('md')]: {
      display: 'none',
      width: 0,
    },
  },

  iconContainer: {
    marginTop: 2,
    marginLeft: 'auto',
    display: 'flex',
    height: '100%',
    width: 24,
    marginBottom: 'auto',
    [theme.fn.largerThan('md')]: {
      marginLeft: 'auto',
    },
  },

  close: {
    marginLeft: 'auto',
  },
}));

const TitleWithClose = ({ id, title, leftChild: Component, onClose, ...rest }: TitleWithCloseProps) => {
  const modals = useModals();
  const { classes } = useStyles();
  const matches = useMediaQuery('(min-width: 993px)');

  const closeModal = () => {
    modals.closeModal(id);
    if(onClose) {
      setTimeout(() => onClose(), 200)
    }
  };

  return (
    <Group mb={20} spacing={10} {...rest} className={classes.wrapper}>
      {Component ? (
        <Group className={classes.leftAction} noWrap align={'center'}>
          {Component}
        </Group>
      ) : (
        <div className={classes.spacer} />
      )}
      <Text className={classes.title} lineClamp={matches ? 1 : 2}>
        {title}
      </Text>
      <div className={classes.iconContainer}>
        <X onClick={closeModal} cursor={'pointer'} color={'#838485'} className={classes.close} size={20} />
      </div>
    </Group>
  );
};

export default TitleWithClose;
