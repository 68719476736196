import React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  ICIMSRoleItem: {
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '8px',
    color: '#242424',
    padding: '2px 10px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      borderRadius: '5px',
    },
    '&:last-child': {
      marginBottom: '0',
    },
  },
  UnavailableICIMSRoleItem: {
    cursor: 'not-allowed',
    backgroundColor: '#EEEEEE',
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '8px',
    color: '#838485',
    padding: '4px 10px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
}));

const LinkableICIMSRoleItem = ({ linkableICIMSRoleSelected, role }) => {
  const { classes } = useStyles();

  return (
    <>
      {role.is_available && (
        <div className={classes.ICIMSRoleItem} onClick={() => linkableICIMSRoleSelected(role)}>
          {role.icims_job_name}{' '}
          {role.job_id ? (
            <span style={{ color: '#959595', marginLeft: 10, fontSize: '12px' }}>{'Req ID ' + role.job_id}</span>
          ) : null}
          {role.icims_portal ? (
            <span style={{ color: '#959595', marginLeft: 10, fontSize: '12px' }}>{'Req ID ' + role.job_id}</span>
          ) : null}
        </div>
      )}
      {!role.is_available && (
        <div className={classes.UnavailableICIMSRoleItem}>
          {role.icims_job_name}{' '}
          {role.job_id ? (
            <span style={{ color: '#838485', marginLeft: 10, fontSize: '12px' }}>{'Req ID ' + role.job_id}</span>
          ) : null}
          {role.icims_portal.name ? (
            <span style={{ color: '#838485', marginLeft: 10, fontSize: '12px' }}>{'Req ID ' + role.job_id}</span>
          ) : null}
        </div>
      )}
    </>
  );
};

export default LinkableICIMSRoleItem;

LinkableICIMSRoleItem.propTypes = {
  role: PropTypes.object.isRequired,
  linkableICIMSRoleSelected: PropTypes.func.isRequired,
};

LinkableICIMSRoleItem.defaultProps = {};
