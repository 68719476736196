import React from 'react';
import { createStyles, Divider, Stack, Text } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  title: {
    textTransform: 'capitalize',
  },
  mobileIndicator: {
    marginTop: 20,
    [theme.fn.largerThan('md')]: {
      display: 'none',
    }
  },
  divider: {
    borderTopColor: '#EEEEEE'
  }
}));

interface props {
  title: string;
}

const MobileRoleTopPageHeader = ({title} : props) => {
  const { classes } = useStyles();

  return (
    <Stack spacing={0} className={classes.mobileIndicator}>
      <Text color={'#838485'} pl={4} mb={10} className={classes.title}>
        {title}
      </Text>
      <Divider className={classes.divider} />
    </Stack>
  );
};

export default MobileRoleTopPageHeader;