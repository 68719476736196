import React, { useState, useEffect } from 'react';
import { Alert, Button, Container, Group, Image, Stack, Text, TextInput, Title, LoadingOverlay } from '@mantine/core';
import RolebotIdea from 'assets/img/password_reset/change-password.png';
import RolebotChanged from 'assets/img/password_reset/password-updated.png';
import RolebotSad from 'assets/img/password_reset/trouble-loggin-in.png';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import { Link, NavLink, useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useChangePasswordMutation, useValidatePasswordResetTokenMutation } from 'app/services/rolebot';
import { mapErrors } from 'utils';
import { RolebotClient } from 'api/client';

const ChangePasswordFormSchema = z
  .object({
    password: z.string().min(8, 'Password must be at least 8 characters long')
        .refine((v) => v.match(/[A-Z]/), 'Password must contain at least one uppercase letter')
        .refine((v) => v.match(/[a-z]/), 'Password must contain at least one lowercase letter'),
    confirm: z.string(),
  })
  .refine((data) => data.password === data.confirm, {
    message: 'Passwords must match',
    path: ['confirm'],
  });

type ChangePasswordPayload = z.infer<typeof ChangePasswordFormSchema>;

const ChangePassword = () => {
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(true);
  const [invalidLink, setInvalidLink] = useState(false);
  const params = useParams<{ token: string }>();
  const token = params.token || null;
  const _email = searchParams.get('email') || '';
  const email = _email.replace(/ /g, '+');
  const [validateToken, {isLoading : validatingToken, isSuccess: tokenValidated, isUninitialized}] = useValidatePasswordResetTokenMutation()

  useEffect(() => {
    if (token && email) {
      validateToken({token, email});
    }
  }, [token, email]);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirm: '',
    },
    resolver: zodResolver(ChangePasswordFormSchema),
  });

  const [changePassword, { isSuccess, isError, isLoading, reset, error }] = useChangePasswordMutation();

  const onSubmit = async (data: ChangePasswordPayload) => {
    reset();
    if (!email || !token) {
      setInvalidLink(true);
      return;
    }
    await changePassword({
      password: data.password,
      password_confirmation: data.confirm,
      email,
      token,
    });
  };

  if (isSuccess)
    return (
      <div>
        {/*TODO: This is horrible! We should only use the top image once. AO*/}
        <NavLink to={'/'}>
          <Image src={LogoLetters} width={180} fit={'contain'} mb={60} ml={50} mt={40} />
        </NavLink>
        <Container size={600} mt={40}>
          <Stack spacing={0} align={'center'}>
            <Image src={RolebotChanged} width={95} fit={'contain'} mb={35} />
            <Title order={3} mb={20}>
              Password Changed!
            </Title>
            <Text color={'#838485'} align={'center'}>
              Your password has been changed successfully.
            </Text>
            <Button mt={40} component={Link} to={'/'} px={40}>
              Back to Login
            </Button>
          </Stack>
        </Container>
      </div>
    );

  return (
    <>
      {(!validatingToken && !isUninitialized) ? (<div>
        {/*TODO: This is horrible! We should only use the top image once. AO*/}
        <NavLink to={'/'}>
          <Image src={LogoLetters} width={120} fit={'contain'} mb={60} ml={50} mt={40} />
        </NavLink>
        <Container size={600} mt={40}>
          <Stack align={'center'} spacing={0}>
            <Image src={tokenValidated ? RolebotIdea : RolebotSad} width={95} fit={'contain'} mb={35} />
            {tokenValidated && (
              <Title order={3} mb={20}>
                Change Password
              </Title>
            )}
            {!tokenValidated && (
              <Title order={3} mb={20}>
                Invite token expired
              </Title>
            )}
          </Stack>
          {tokenValidated && (
            <Stack>
              <TextInput size={'md'} label="Email" autoComplete="email" value={email || ''} disabled />
              <form onSubmit={handleSubmit(onSubmit)} id="sign-in-form">
                <Stack>
                  <TextInput
                    label={'Password'}
                    placeholder={'8+ Characters'}
                    {...register('password')}
                    size={'md'}
                    error={errors?.password?.message}
                    rightSectionProps={{ style: { marginRight: 15 } }}
                    type={show ? 'password' : 'text'}
                    rightSection={
                      <Text
                        size={'sm'}
                        color={'#838485'}
                        onClick={() => setShow(!show)}
                        sx={{ cursor: 'pointer', userSelect: 'none' }}
                      >
                        {show ? 'SHOW' : 'HIDE'}
                      </Text>
                    }
                  />
                  <TextInput
                    label={'Confirm New Password'}
                    placeholder={'Confirm New Password'}
                    {...register('confirm')}
                    size={'md'}
                    error={errors?.confirm?.message}
                    type={'password'}
                  />
                  <Button
                    mt={24}
                    type="submit"
                    fullWidth
                    size={'md'}
                    loading={isLoading}
                    loaderPosition={'right'}
                    disabled={!isValid}
                  >
                    Change Password
                  </Button>
                </Stack>
              </form>
              {isSuccess && <Alert color={'green'}>We have e-mailed your password reset link!</Alert>}
              {invalidLink && (
                <Alert>The reset link is invalid or has expired. Please generate a new link and try again.</Alert>
              )}
              {isError && (
                <Alert color={'red'}>
                  {mapErrors(error)[0] || 'Oops, Something went wrong. Please try again or contact support.'}
                </Alert>
              )}
              <Group spacing={4} position={'center'} mt={60}>
                <Text>Remembered your password?</Text>
                <Text component={Link} to={'/'} color={'#7039ED'}>
                  {' '}
                  Back to Login
                </Text>
              </Group>
            </Stack>
          )}
          {!tokenValidated && (
            <Stack>
              <Text color={'#838485'} align={'justify'}>
                A valid invite token is required to sign up for Rolebot. Ask your company's Admin for a new invite or
                Contact Support for help.
              </Text>
              <Group spacing={4} position={'center'} mt={20}>
                <Text>Have an account?</Text>
                <Text component={Link} to={'/'} color={'#7039ED'}>
                  {' '}
                  Sign in
                </Text>
              </Group>
            </Stack>
          )}
        </Container>
      </div>) : <LoadingOverlay visible={true}/>}
    </>
  );
};
export default ChangePassword;
