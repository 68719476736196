import { SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, Group, Popover, Radio, RadioGroup, Stack, Text, TextInput } from '@mantine/core';
import FormNavigation from 'components/Modals/RoleInfo/FormNavigation';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import useOutsideAreaClick from '../../../pages/RoleStats/hooks/useOutsideAreaClick';
import ContactsDropDown from './ContactsDropDown/ContactsDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { TrimValue } from 'utils';
import RolebotTextInput from 'components/Inputs/RolebotTextInput';
import RolebotSelect from 'components/Inputs/RolebotSelect';
import { useGetCompanyContactsQuery } from 'app/services/rolebot';
import React from 'react';

interface StepTwoProps {
  prev: (payload: object) => void;
  next: (payload: object) => void;
  save: (payload: object) => void;
  showFinishLater: boolean;
  values: {
    interviewer_id: number | null,
    interviewer: string;
    interviewer_title: string;
    report_to_id: number | null,
    report_to: string;
    report_to_title: string;
    calendly_link: string;
  };
  originalData: any;
  isSubmitting: boolean;
  companyId: number | undefined;
  onUnmount: (payload: object) => void;
  isCandidateLocationEmpty: boolean;
}

const NameSchema = z.preprocess(
  TrimValue,
  z
    .string()
    .regex(/\b([a-zA-ZÀ-ÿ][-,A-Za-z. ']+ *)+/gm, 'Please provide a valid name')
    .max(255, 'Must be under 255 chars.')
    .or(z.literal(''))
);

const TitleSchema = z.preprocess(
  TrimValue,
  z
    .string()
    .regex(/\b([a-zA-ZÀ-ÿ][-,A-Za-z. ']+ *)+/gm, 'Please provide a valid title')
    .max(255, 'Must be under 255 chars.')
);

const StepTwoSchema = z
  .object({
    interviewer_id: z.any(),
    interviewer: NameSchema,
    interviewer_title: z.any(),
    calendly_link: z.string().max(255, 'Must be under 255 chars.'),
    report_to: NameSchema,
    report_to_title: z.any(),
    report_to_id: z.any()
  })

  //check to make sure interview and title are both filled.
  .refine(
    ({ interviewer, interviewer_title }) => {
      try {
        const interviewerIsFilled = interviewer !== '';
        const isValidInterviewer = NameSchema.parse(interviewer);
        // this branch means we have a valid interviewer name, so we must parse the interviewer title
        if (interviewerIsFilled && isValidInterviewer) {
          // if the next line does not throw an error, this field is valid.
          TitleSchema.parse(interviewer_title);
          return true;
        }

        if (!interviewerIsFilled && isValidInterviewer) {
          return false;
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    {
      message: 'Must supply an interview title with interview name',
      path: ['interviewer_title'],
    }
  )

  //check to make sure report to and report to title are both filled.
  .refine(
    ({ report_to, report_to_title }) => {
      try {
        const isReportToNameFilled = report_to !== '';
        const isReportToNameValid = NameSchema.parse(report_to);
        // this branch means we have a valid interviewer name, so we must parse the interviewer title
        if (isReportToNameFilled && isReportToNameValid) {
          // if the next line does not throw an error, this field is valid.
          TitleSchema.parse(report_to_title);
          return true;
        }

        if (!isReportToNameFilled && isReportToNameValid) {
          return false;
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    {
      message: 'Must supply an report to title with report to name',
      path: ['report_to_title'],
    }
  );

const HiringTeamStep = ({
  values,
  originalData,
  next,
  save,
  prev,
  isSubmitting,
  showFinishLater,
  companyId,
  onUnmount,
  isCandidateLocationEmpty
}: StepTwoProps) => {
  const [opened, setOpened] = useState(false);
  const {
    interviewer_id,
    interviewer,
    interviewer_title,
    report_to_id,
    report_to,
    report_to_title,
    calendly_link
  } = values;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      interviewer_id,
      interviewer,
      interviewer_title,
      report_to_id,
      report_to,
      report_to_title,
      calendly_link,
    },
    resolver: zodResolver(StepTwoSchema),
  });

  const [interviewWatcher,
    interviewTitleWatcher,
    reportToWatcher,
    interviewerIdWatcher,
    reportToIdWatcher,
    reportToTitleWatcher,
    calendlyLinkWatcher] = watch([
      'interviewer',
      'interviewer_title',
      'report_to',
      'interviewer_id',
      'report_to_id',
      'report_to_title',
      'calendly_link'
    ]);

  const formatDropdownItems = (contacts: { id: number, name: string, title: string, calendly_link: string }[]) => {

    return contacts.map((contact) => {
      return {
        ...contact,
        value: contact.id ? contact.id.toString() : contact.name,
        label: contact.name
      }
    })
  }

  const [formValuesHaveChanged, setFormValuesHaveChanged] = useState(false);
  const [interviewerIsReporterStateToggleChanged, setInterviewerIsReporterStateToggleChanged] = useState(false);
  const { data: companyContacts } = useGetCompanyContactsQuery({ customer_id: companyId }, { refetchOnMountOrArgChange: true })
  const [dropdownData, setDropdownData] = useState(companyContacts && companyContacts.contacts.length ? formatDropdownItems(companyContacts.contacts) : [])
  const updatedValuesRef = useRef({ ...values });



  useEffect(() => {
    if (companyContacts && companyContacts.contacts.length) setDropdownData(formatDropdownItems(companyContacts.contacts))

    // This code is just a bandaid to a bigger issue, as long as the main form is
    // badly implemented, fixing things the right way COULD and WILL break stuff
    if (interviewWatcher === interviewerIdWatcher?.toString()) {
      let newest = (companyContacts && companyContacts.contacts.length) ? companyContacts.contacts.find((val: any) => val.name === interviewWatcher) : null
      if (newest) {
        setValue('interviewer_id', newest.id);
        trigger('interviewer_id');
      }
    }

    if (reportToWatcher === reportToIdWatcher?.toString()) {
      let newest = (companyContacts && companyContacts.contacts.length) ? companyContacts.contacts.find((val: any) => val.name === reportToWatcher) : null

      if (newest) {
        setValue('report_to_id', newest.id);
        trigger('report_to_id');
      }
    }
    //end of bandaid code
  }, [companyContacts])

  useEffect(() => {

    updatedValuesRef.current = {
      interviewer: interviewWatcher,
      interviewer_title: interviewTitleWatcher,
      report_to: reportToWatcher,
      interviewer_id: interviewerIdWatcher,
      report_to_id: reportToIdWatcher,
      report_to_title: reportToTitleWatcher,
      calendly_link: calendlyLinkWatcher,
    };
  });

  useEffect(() => {
    // This code is just a bandaid to a bigger issue, as long as the main form is
    // badly implemented, fixing things the right way COULD and WILL break stuff
    if (interviewWatcher === interviewerIdWatcher?.toString()) {
      let newest = (companyContacts && companyContacts.contacts.length) ? companyContacts.contacts.find((val: any) => val.name === interviewWatcher) : null
      if (newest) {
        setValue('interviewer_id', newest.id);
        trigger('interviewer_id');
      } else {
        setDropdownData((prev) => [...prev, { name: interviewWatcher, id: interviewerIdWatcher ?? interviewWatcher, value: interviewWatcher, label: interviewWatcher, calendly_link: calendlyLinkWatcher, title: interviewTitleWatcher }])
      }
    }

    if (reportToWatcher === reportToIdWatcher?.toString()) {
      let newest = (companyContacts && companyContacts.contacts.length) ? companyContacts.contacts.find((val: any) => val.name === reportToWatcher) : null

      if (newest) {
        setValue('report_to_id', newest.id);
        trigger('report_to_id');
      } else {
        if (interviewerIsReporterValue === '0') {
          setDropdownData((prev) => [...prev, { name: reportToWatcher, id: reportToIdWatcher ?? reportToWatcher, value: reportToWatcher, label: reportToWatcher, calendly_link: '', title: reportToTitleWatcher }])
        }
      }
    }
    //end of bandaid code
  }, [])

  // useEffect hook to handle component unmount-like behavior
  useEffect(() => {
    if (!showFinishLater) {
      return () => {
        onUnmount(updatedValuesRef.current);
      };
    }
  }, []);

  useEffect(() => {

    if (!showFinishLater) {
      setFormValuesHaveChanged(
        ((originalData?.contacts.find((x: { role_contact_info: { type: string; }; }) => x.role_contact_info.type === 'interviewer')?.name || '') !== interviewWatcher) ||
        ((originalData?.contacts.find((x: { role_contact_info: { type: string; }; }) => x.role_contact_info.type === 'interviewer')?.title || '') !== interviewTitleWatcher) ||
        ((originalData?.contacts.find((x: { role_contact_info: { type: string; }; }) => x.role_contact_info.type === 'report_to')?.name || '') !== reportToWatcher) ||
        ((originalData?.contacts.find((x: { role_contact_info: { type: string; }; }) => x.role_contact_info.type === 'report_to')?.title || '') !== reportToTitleWatcher) ||
        interviewerIsReporterStateToggleChanged ||
        ((originalData?.calendly_link || '') !== calendlyLinkWatcher));
    }
  }, [
    showFinishLater,
    originalData?.interviewer,
    interviewWatcher,
    originalData?.interviewer_title,
    interviewTitleWatcher,
    originalData?.report_to,
    reportToWatcher,
    originalData?.report_to_title,
    reportToTitleWatcher,
    originalData?.calendly_link,
    calendlyLinkWatcher,
    interviewerIsReporterStateToggleChanged
  ]);

  const [interviewerIsReporterValue, setInterviewerIsReporterValue] = useState(
    interviewer_id === report_to_id ? '1' : '0'
  );

  const handleSetInterviewerIsReporterValue = (value: SetStateAction<string> | undefined) => {

    if (value !== undefined) {
      setInterviewerIsReporterValue(value);
    }

    setInterviewerIsReporterStateToggleChanged(!interviewerIsReporterStateToggleChanged);
  }

  useEffect(() => {
    // This watched for interviewer same as report to. It will copy the values over when '1'
    if (interviewerIsReporterValue === '1') {
      setValue('report_to_id', interviewerIdWatcher);
      setValue('report_to', interviewWatcher);
      setValue('report_to_title', interviewTitleWatcher);
    }

    trigger('interviewer_id')
    trigger('interviewer_title');
    trigger('report_to_title');
    // eslint-disable-next-line
  }, [interviewerIsReporterValue, interviewerIdWatcher]);

  const handleInterviewContactSelected = (contact_value: any) => {
    let contact = companyContacts.contacts.find((c: any) => c.id.toString() === contact_value)

    setValue('interviewer', contact ? contact.name : contact_value);
    setValue('interviewer_title', contact ? contact.title : '');
    setValue('calendly_link', contact && contact.calendly_link ? contact.calendly_link : '');
    setValue('interviewer_id', contact ? contact.id : contact_value);

    trigger('interviewer')
    trigger('interviewer_title')
    trigger('calendly_link')
    trigger('interviewer_id')
  };

  const handleReportToContactSelected = (contact_value: any) => {
    let contact = companyContacts.contacts.find((c: any) => c.id.toString() === contact_value)
    setValue('report_to', contact ? contact.name : contact_value);
    setValue('report_to_title', contact ? contact.title : '');
    setValue('report_to_id', contact ? contact.id : contact_value);

    trigger('report_to')
    trigger('report_to_title')
    trigger('report_to_id')
  };

  useEffect(() => {
    if (interviewerIsReporterValue === '1') {
      setValue('report_to_title', interviewTitleWatcher);
      trigger('report_to_title')
    }
  }, [interviewTitleWatcher])

  const calendlyLinkLabelWithPopover = (
    <Group noWrap spacing={'xs'} align={'start'}>
      <Text>If the interviewer has an online calendar, please add the link below</Text>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        position="top"
        placement="end"
        withArrow
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        width={360}
        styles={{ body: { pointerEvents: 'none' } }}
        target={
          <FontAwesomeIcon
            onMouseEnter={() => setOpened(true)}
            onMouseLeave={() => setOpened(false)}
            icon={faQuestionCircle}
            style={{
              cursor: 'pointer',
              marginRight: '4px',
              display: 'inlineBlock',
              color: 'cornflowerblue',
            }}
          />
        }
      >
        <Text size="md" color={'#242424'}>
          If you’d like for us to schedule meetings directly on a calendar, please share the online calendar link, e.g.
          calendly or Google calendar.
        </Text>
      </Popover>
      <Text style={{ marginLeft: 'auto', color: '#B3B3B3', width: '12%' }} size="md">
        Optional
      </Text>
    </Group>
  );

  return (
    <div style={{ paddingLeft: '2px' }}>
      <Box mb={20}>
        <Text weight={500} color="#242424" style={{ fontFamily: 'Roboto' }} mb={20}>
          Hiring Team
        </Text>
        <form>
          <Stack>
            <RolebotSelect
              data={dropdownData}
              searchable
              creatable
              placeholder={'Bob Ross'}
              label="Who will conduct the first interview?"
              error={errors?.interviewer?.message}
              value={interviewerIdWatcher ? interviewerIdWatcher.toString() : interviewWatcher}
              onChange={handleInterviewContactSelected}
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(query) => setDropdownData((current: any) => [...current, { id: query, name: query, title: '', calendly_link: '', value: query, label: query }])}
            />
            <RolebotTextInput
              {...register('interviewer_title')}
              label="What is the interviewer's title?"
              placeholder={'Art Director'}
              error={errors?.interviewer_title?.message}
              maxLength={255}
            />
            <RolebotTextInput
              {...register('calendly_link')}
              label={calendlyLinkLabelWithPopover}
              placeholder={'https://calendly.com/bobross'}
              error={errors?.calendly_link?.message}
              maxLength={255}
            />
            <RadioGroup
              value={interviewerIsReporterValue}
              onChange={handleSetInterviewerIsReporterValue}
              label="Is the Interviewer the same person this role reports to?"
              spacing="xl"
              size={'md'}
              color="violet"
            >
              <Radio value="1" label="Yes" />
              <Radio value="0" label="No" />
            </RadioGroup>
            {interviewerIsReporterValue === '0' && (
              <>
                <RolebotSelect
                  data={dropdownData}
                  searchable
                  creatable
                  placeholder={'John Doe'}
                  label="Who does this role report to?"
                  error={errors?.report_to?.message}
                  value={reportToIdWatcher ? reportToIdWatcher.toString() : reportToWatcher}
                  onChange={handleReportToContactSelected}
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={(query) => setDropdownData((current: any) => [...current, { id: query, name: query, title: '', value: query, label: query }])}
                />
                <RolebotTextInput
                  {...register('report_to_title')}
                  label={'Title of the person this role reports to?'}
                  placeholder={'CTO'}
                  error={errors?.report_to_title?.message}
                  maxLength={255}
                />
              </>
            )}
          </Stack>
        </form>
      </Box>
      <div style={{ marginBottom: '2px' }}>
        <FormNavigation
          step={4}
          next={handleSubmit(next)}
          prev={handleSubmit(prev)}
          save={handleSubmit(save)}
          isSubmitting={isSubmitting}
          isSaveEnabled={!isValid}
          showFinishLater={showFinishLater}
          isNextDisabled={!isValid || interviewWatcher === '' || reportToWatcher === '' || isCandidateLocationEmpty}
          isSaveChangesDisabled={!formValuesHaveChanged}
        />
      </div>
    </div>
  );
};

export default HiringTeamStep;
