
const OAuth = () => {
    const baseURL = process.env.REACT_APP_API_URL;

    const queryString = window.location.search;
    window.location.href = baseURL + '/oauth' + queryString;

    return null
};
export default OAuth;
