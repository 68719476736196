import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filters, RoleStatsFilters, sortOption } from 'types';

interface AppState {
  waitingForSetupRolesCount: number;
  mobileNavOpen: boolean;
  currentRoleId: number | null;
  talentMenuOpen: boolean;
  currentTalent: any;
  requiresLeverOauth: boolean;
  hasActiveFilters: boolean;
  activeFiltersCount: number;
  filters: filters;
  sortType: sortOption;
  hasActiveSort: boolean;
  roleMenuOpen: boolean;
  talentOptionsOpen: boolean;
  roleStats: {
    hasActiveFilters: boolean;
    activeFiltersCount: number;
    filters: RoleStatsFilters;
    users: {
      ids: number[];
      hasActiveUsers: boolean;
      activeUsersCount: number;
    };
  };
  requiresCronofyAuth: boolean;
  talentQueue: number;
  showConfettiAnimation: boolean;
  ATSRoleSelected: number | null;
  ATSRoleNameSelected: string | null;
  customerPlanIsActive: boolean;
}

const initialState: AppState = {
  waitingForSetupRolesCount: 0,
  mobileNavOpen: false,
  currentRoleId: null,
  talentMenuOpen: false,
  currentTalent: null,
  requiresLeverOauth: false,
  hasActiveFilters: false,
  activeFiltersCount: 0,
  filters: {
    talentToReview: false,
    waitingForSetup: false,
    onHold: false,
    turnkey: false,
    sourcing_only: false
  },
  roleStats: {
    hasActiveFilters: false,
    activeFiltersCount: 0,
    filters: {
      active: false,
      onHold: false,
      waitingForSetup: false,
      completed: false,
    },
    users: {
      ids: [],
      hasActiveUsers: false,
      activeUsersCount: 0,
    },
  },
  sortType: 'default',
  hasActiveSort: false,
  roleMenuOpen: false,
  talentOptionsOpen: false,
  requiresCronofyAuth: true,
  talentQueue: 0,
  showConfettiAnimation: false,
  ATSRoleSelected: null,
  ATSRoleNameSelected: null,
  customerPlanIsActive: true,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    logOut: (state) => {
      Object.assign(state, initialState);
    },
    setCurrentRoleId: (state, action) => {
      state.currentRoleId = action.payload;
    },
    setWaitingForSetupRolesCount: (state, action) => {
      state.waitingForSetupRolesCount = action.payload;
    },
    toggleNavigation: (state) => {
      state.mobileNavOpen = !state.mobileNavOpen;
    },
    openTalentMenu: (state, action) => {
      state.talentMenuOpen = true;
      state.currentTalent = action.payload;
    },
    closeTalentMenu: (state) => {
      state.talentMenuOpen = false;
      state.currentTalent = null;
    },
    setCurrentTalentId: (state, action) => {
      state.currentTalent = action.payload;
    },
    isLeverOauthRequired: (state, action) => {
      state.requiresLeverOauth = action.payload;
    },
    filtersChanged: (state, action) => {
      const { hasActiveFilters, activeFiltersCount, ...filters } = action.payload;
      state.filters = filters;
      state.activeFiltersCount = activeFiltersCount;
      state.hasActiveFilters = hasActiveFilters;
    },
    sortChanged: (state, action) => {
      const { sortType, hasActiveSort } = action.payload;
      state.sortType = sortType;
      state.hasActiveSort = hasActiveSort;
    },
    toggleRoleMenu: (state, action) => {
      state.roleMenuOpen = action.payload;
    },
    toggleTalentOptions: (state, action) => {
      state.talentOptionsOpen = action.payload;
    },
    roleStatsFilterChanged: (state, action) => {
      const { hasActiveFilters, activeFiltersCount, ...filters } = action.payload;
      state.roleStats.hasActiveFilters = hasActiveFilters;
      state.roleStats.activeFiltersCount = activeFiltersCount;
      state.roleStats.filters = filters;
    },
    roleStatsUsersChanged: (
      state,
      action: PayloadAction<{ ids: number[]; hasActiveUsers: boolean; activeUsersCount: number }>
    ) => {
      const { ids, hasActiveUsers, activeUsersCount } = action.payload;
      state.roleStats.users.ids = ids;
      state.roleStats.users.hasActiveUsers = hasActiveUsers;
      state.roleStats.users.activeUsersCount = activeUsersCount;
    },
    changeCronofyAuthStatus: (state, action: PayloadAction<{ status: boolean }>) => {
      state.requiresCronofyAuth = action.payload.status;
    },
    enqueueTalent: (state, action) => {
      state.talentQueue = state.talentQueue + action.payload.count;
    },
    showConfettiAnimation: (state, action) => {
      state.showConfettiAnimation = action.payload;
    },
    selectATSRole: (state, action) => {
      state.ATSRoleSelected = action.payload;
    },
    selectATSRoleName: (state, action) => {
      state.ATSRoleNameSelected = action.payload;
    },
    // New reducer to set the isActive status
    setCustomerPlanIsActive: (state, action: PayloadAction<boolean>) => {
      state.customerPlanIsActive = action.payload;
    },
  },
});

const { reducer, actions } = appSlice;

export const {
  logOut,
  setCurrentRoleId,
  toggleNavigation,
  openTalentMenu,
  closeTalentMenu,
  setCurrentTalentId,
  isLeverOauthRequired,
  filtersChanged,
  sortChanged,
  toggleRoleMenu,
  toggleTalentOptions,
  roleStatsFilterChanged,
  roleStatsUsersChanged,
  changeCronofyAuthStatus,
  enqueueTalent,
  setWaitingForSetupRolesCount,
  showConfettiAnimation,
  selectATSRole,
  selectATSRoleName,
  setCustomerPlanIsActive,
} = actions;
export default reducer;
