import {Box, Checkbox, createStyles, Loader, LoadingOverlay, MultiSelect, ScrollArea, Stack, Text} from '@mantine/core';
import * as z from 'zod';
import React, {useCallback, useEffect, useState} from 'react';
import useDebounce from "../../../../pages/RoleStats/hooks/useDebounce";
import {RolebotClient} from "../../../../api/client";
import {sendGoogleEvent} from "../../../../utils/analytics";

const useStyles = createStyles(() => ({

  GenderDiversityDiv: {
    flexDirection: "column",
    flexGrow: 1
  },

  RoleSearchCriteriaVariableDiv: {
    // display: "flex",
    marginBottom: 15,
    // flexWrap: 'wrap'
  },

  SuggestedAiRoles: {
    marginBottom: 20
  },

  MultiSelectDiv: {
    // flexGrow: 1
  }
}));

interface SearchCriteriaDiversityProps {
  onDiversityChange: (payload: boolean) => void;
  prioritize_gender_diversity: boolean;
}

const SearchCriteriaGenderDiversity = ({ onDiversityChange, prioritize_gender_diversity }: SearchCriteriaDiversityProps) => {
  const { classes } = useStyles();
  const [firstRun, setFirstRun] = useState(true);
  const [prioritizeGenderDiversity, setPrioritizeGenderDiversity] = useState(false);


  //In some instances the role info data could be loading and we would need to reset the form to get new values
  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }

  }, []);

  useEffect(() => {

    setPrioritizeGenderDiversity(prioritize_gender_diversity);
    onDiversityChange(prioritize_gender_diversity);
  }, [prioritize_gender_diversity]);

  const handlePrioritizeGenderDiversityChange = (event: { currentTarget: { checked: boolean; }; }) => {

    if (event.currentTarget.checked) {
      setPrioritizeGenderDiversity(true);
    } else {
      setPrioritizeGenderDiversity(false);
    }

    onDiversityChange(event.currentTarget.checked);
  };

  return (
      <div className={classes.GenderDiversityDiv}>
        <Checkbox
            className={classes.MultiSelectDiv}
            onChange={handlePrioritizeGenderDiversityChange}
            label={'Prioritize gender diversity'}
            checked={prioritizeGenderDiversity}
            styles={{
              input: {cursor: 'pointer'}
            }}
        />
      </div>
  );
};

export default SearchCriteriaGenderDiversity;
