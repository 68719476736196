import React, { useMemo, useEffect, useState } from 'react';
import { Box, createStyles, Group, Popover, Stack, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons';
import CompanyUserListItem from 'pages/RoleStats/RoleManagerSection/CompanyUserListItem';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { roleStatsUsersChanged } from 'features/app/appSlice';
import useAppSelector from 'hooks/useAppSelector';
import { sendGoogleEvent } from 'utils/analytics';
import SearchInput from '../../components/SearchInput/SearchInput';

const useStyles = createStyles((theme, { hasActiveUsers, open }: any) => ({
  button: {
    backgroundColor: hasActiveUsers || open ? '#EEEEEE' : 'transparent',
    color: hasActiveUsers ? '#351B77' : '#838485',
    padding: 10,
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  sortIcon: {
    height: '18px',
    paddingBottom: '2px'
  }
}));

interface RoleStatsSortProps {
  users: [];
  selectedUsers: [];
  isLastCallDone: boolean;
}

const RoleStatsSort = ({ users, selectedUsers, isLastCallDone }: RoleStatsSortProps) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const activeUsersCount = useAppSelector((state) => state.app.roleStats.users.activeUsersCount);
  const hasActiveUsers = useAppSelector((state) => state.app.roleStats.users.hasActiveUsers);
  const { classes } = useStyles({ hasActiveUsers, open });

  const addId = (userId: number) => {
    if (selectedIds.includes(userId)) return;
    const newPayload = [...selectedIds, userId];
    setSelectedIds(newPayload);
    dispatch(
      roleStatsUsersChanged({
        ids: newPayload,
        hasActiveUsers: true,
        activeUsersCount: newPayload.length,
      })
    );
  };

  const removeId = (userId: number) => {
    const newPayload = [...selectedIds].filter((id) => id !== userId);
    setSelectedIds(newPayload);
    dispatch(
      roleStatsUsersChanged({
        ids: newPayload,
        hasActiveUsers: newPayload.length > 0,
        activeUsersCount: newPayload.length,
      })
    );
  };

  const onClear = () => {
    if (selectedIds.length) {
      setSelectedIds([]);
      dispatch(
        roleStatsUsersChanged({
          ids: [],
          hasActiveUsers: false,
          activeUsersCount: 0,
        })
      );
    }
  };

  useEffect(() => {
    if (open) {
      sendGoogleEvent('User', 'Role manager users filter opened');
    }
    // eslint-disable-next-line
  }, [open]);

  const handleSearchValueChanged = (value: any) => {
    setSearchValue(value);
  };

  // @ts-ignore
  const filteredUsers = useMemo(
    () =>
      users.filter(
        (x: any) =>
          x.name.toUpperCase().includes(searchValue.toUpperCase()) ||
          x.email.toUpperCase().includes(searchValue.toUpperCase())
      ),
    // eslint-disable-next-line
    [searchValue]
  );
  const dataSource = searchValue === '' ? users : filteredUsers;

  return (
    <Popover
      opened={open}
      onClose={() => setOpen(false)}
      target={
        <Group spacing={5} onClick={() => setOpen((o) => !o)} className={classes.button}>
          <FontAwesomeIcon icon={faUserCircle} fill={hasActiveUsers ? '#351B77' : '#838485'} className={classes.sortIcon} />
          {activeUsersCount > 0 ? `Users / ${activeUsersCount}` : 'Users'}
        </Group>
      }
      width={350}
      position="bottom"
      withArrow
    >
      <Group position={'apart'} noWrap mb={25}>
        <Text color={'#242424'} sx={{ fontFamily: 'Roboto' }}>
          Filter by User
        </Text>
        <Text onClick={onClear} color={'#B3B3B3'} sx={{ cursor: 'pointer' }}>
          Clear All
        </Text>
      </Group>

      <SearchInput
        value={searchValue}
        onChange={handleSearchValueChanged}
        sx={{ minWidth: 200, maxWidth: 500, flex: 1 }}
        customPlaceholder={'ex. Bob Ross, or bob@acme.com'}
      />

      {/*Users that are currently selected*/}
      {selectedUsers.length > 0 && (
        <Stack spacing={10} mb={10} mt={15} style={!isLastCallDone ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
          <Text color={'#242424'}>Selected</Text>
          <Stack spacing={4}>
            {selectedUsers.map((x: any) => (
              <CompanyUserListItem key={x.id} user={x} checked={true} userChecked={removeId} />
            ))}
          </Stack>
        </Stack>
      )}
      <Stack spacing={10} mt={15} style={!isLastCallDone ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
        <Text color={'#242424'}>Users</Text>
        <Box sx={{ maxHeight: 300, overflowY: 'scroll', outline: '2px solid transparent' }}>
          <Stack spacing={4}>
            {dataSource.map(
              (x: any) =>
                // @ts-ignore
                !selectedIds.includes(x.id) && (
                  <CompanyUserListItem key={x.id} user={x} checked={false} userChecked={addId} />
                )
            )}
          </Stack>
        </Box>
      </Stack>
    </Popover>
  );
};

export default RoleStatsSort;
