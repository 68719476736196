import {useEffect, useMemo, useRef, useState} from 'react';
import {ActionIcon, Alert, Button, Checkbox, createStyles, Group, InputWrapper, Overlay, Popover, Stack, Text, Title} from '@mantine/core';
import FormNavigation from 'components/Modals/RoleInfo/FormNavigation';
import {useForm} from 'react-hook-form';
import * as z from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {TrimValue} from 'utils';
import RolebotTextInput from 'components/Inputs/RolebotTextInput';
import RolebotTextArea from 'components/Inputs/RolebotTextArea';
import { Editor, RichTextEditor } from '@mantine/rte';
import { faLightbulb, faTrash } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import moment from 'moment';
import React from 'react';

const DEFAULT_TEMPLATE = "<p>Hi {{first_name}},</p><p><strong><span class='mention' data-index='1' data-denotation-char='#' data-id='interviewer_name' data-value='Interviewer name'><span contenteditable='false'><span class='ql-mention-denotation-char'>#</span>Interviewer name</span></span> </strong>at <strong><span class='mention' data-index='2' data-denotation-char='#' data-id='company_name' data-value='Company name'><span contenteditable='false'><span class='ql-mention-denotation-char'>#</span>Company name</span></span> </strong>is intrigued by your background and would love to schedule a call to share more insight on the <strong><span class='mention' data-index='1' data-denotation-char='#' data-id='role_name' data-value='Role name'><span contenteditable='false'><span class='ql-mention-denotation-char'>#</span>Role name</span></span></strong>, and find out if there are mutual synergies.</p><ul><li>The role will report to <span class='mention' data-index='4' data-denotation-char='#' data-id='hiring_manager_name' data-value='Hiring manager name'><span contenteditable='false'><span class='ql-mention-denotation-char'>#</span>Hiring manager name</span></span></li><li>We recommend providing 1-2 concise and compelling bullet points that highlight the role's key selling points. Rolebot will use the information provided earlier during its engagement process with the candidates.</li></ul><p>Would you be interested in having a conversation?</p><p>If so, happy to make it happen!</p>"

interface StepThreeProps {
  prev: (payload: object) => void;
  next: (payload: object) => void;
  save: (payload: object) => void;
  showFinishLater: boolean;
  values: {
    target_salary: string;
    bonus_offered: boolean;
    equity_offered: boolean;
    jd_link: string;
    sell_opportunity: string;
    custom_template: string | null;
    custom_template_edited_at: Date | null;
    custom_template_edited_by: string | null;
  };
  originalData: any;
  isSubmitting: boolean;
  sourcing_only: boolean;
  showInfo: boolean;
  isAdmin: boolean;
  oldLocation: string;
  onUnmount: (payload: object) => void;
  setEmailModified: (bool:boolean) => void;
  isCandidateLocationEmpty: boolean;
}

const useStyles = createStyles((theme) => ({
  defaultVariant: {
    color: '#242424',
    fontWeight: 400,
    outline: 2,
    border: '1px solid #DFE1E1',
    height: 42,
    paddingLeft: 14,
    paddingRight: 14,
    transition:
      'background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease',
    borderRadius: 4,
    ':hover': {
      margin: 0,
      boxShadow: `0 0 0 2px #7039ED33`,
    },
    ':focus': {
      border: '1px solid rgba(97, 81, 215, 0.5)',
      boxShadow: ' 0px 0px 15px rgba(97, 81, 215, 0.1), inset 0px 0px 4px rgba(0, 0, 0, 0.25)',
    },
    ':focus[type="text"], :focus[type="password"]': {
      borderWidth: 1,
      borderColor: '#C882FF',
      boxShadow: `0 0 0 2px #7039ED33`,
    },
    '::placeholder':{
      color: 'rgb(179, 179, 179)'
    }
  },
}))

const StepThreeSchema = z.object({
  target_salary: z.preprocess(TrimValue, z.string().max(255, 'Must be under 255 chars')),
  equity_offered: z.boolean().nullable(),
  bonus_offered: z.boolean().nullable(),
  jd_link: z
    .string()
    .regex(/^(https?):\/\/[^\s$.?#].[^\s,*!@^{}\[\];'"]*$/gm, 'Must be a valid URL')
    .max(255, 'Must be under 255 chars')
    .nullable()
    .or(z.literal('')),
  sell_opportunity: z.preprocess(TrimValue, z.string().max(6000, 'The sell opportunity must be under 6000 chars')),
  custom_template: z.string().max(6000, 'The template must be under 6000 chars').nullable()
});

const JobInfoStep = ({
  next,
  prev,
  save,
  isSubmitting,
  values,
  originalData,
  showFinishLater,
  sourcing_only,
  showInfo,
  onUnmount,
  setEmailModified,
  isCandidateLocationEmpty
}: StepThreeProps) => {
  const [sellOpportunityPopoverIsOpened, setSellOpportunityPopoverIsOpened] = useState(false);
  const [formValuesHaveChanged, setFormValuesHaveChanged] = useState(false);
  const [customTemplatePopoverIsOpened, setCustomTemplatePopoverIsOpened] = useState(false);
  const [showEditor, setShowEditor] = useState(values.custom_template !== null);
  const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);
  const updatedValuesRef = useRef({ ...values });
  const editorRef = useRef<Editor>(null);

  const {
    target_salary,
    equity_offered,
    bonus_offered,
    jd_link,
    sell_opportunity,
    custom_template
  } = values;

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { isValid, errors, isDirty, dirtyFields },
  } = useForm({
    mode: 'all',
    defaultValues: {
      target_salary,
      equity_offered,
      bonus_offered,
      jd_link,
      sell_opportunity,
      custom_template: custom_template ? custom_template : null
    },
    resolver: zodResolver(StepThreeSchema),
  });

  useEffect(() => {
    if (isDirty && 'custom_template' in dirtyFields) {
      setEmailModified(true);
    } else {
      setEmailModified(false);
    }
  }, [isDirty]);

  const [targetSalaryWatcher,
    equityOfferedWatcher,
    bonusOfferedWatcher,
    jdLinkWatcher,
    sellOpportunityWatcher,
    customTemplateWatcher] = watch([
    'target_salary',
    'equity_offered',
    'bonus_offered',
    'jd_link',
    'sell_opportunity',
    'custom_template'
  ]);

  useEffect(() => {
    updatedValuesRef.current = {
      target_salary: targetSalaryWatcher,
      equity_offered: equityOfferedWatcher,
      bonus_offered: bonusOfferedWatcher,
      jd_link: jdLinkWatcher,
      sell_opportunity: sellOpportunityWatcher,
      custom_template: customTemplateWatcher,
      custom_template_edited_at: values.custom_template_edited_at,
      custom_template_edited_by: values.custom_template_edited_by
    };
  });

  // useEffect hook to handle component unmount-like behavior
  useEffect(() => {
    if (!showFinishLater) {
      return () => {
        onUnmount(updatedValuesRef.current);
      };
    }
  }, []);

  useEffect(() => {
    if (!showFinishLater) {
      setFormValuesHaveChanged(
          (originalData?.target_salary !== targetSalaryWatcher) ||
          ((originalData?.equity_offered || false) !== (equityOfferedWatcher || false)) ||
          ((originalData?.bonus_offered || false) !== (bonusOfferedWatcher || false)) ||
          ((originalData?.jd_link || '') !== jdLinkWatcher) ||
          (originalData?.sell_opportunity !== sellOpportunityWatcher) ||
          (originalData?.custom_template !== customTemplateWatcher)
      );
    }
  }, [
    showInfo,
    originalData?.target_salary,
    targetSalaryWatcher,
    originalData?.equity_offered,
    equityOfferedWatcher,
    originalData?.bonus_offered,
    bonusOfferedWatcher,
    originalData?.jd_link,
    jdLinkWatcher,
    originalData?.sell_opportunity,
    sellOpportunityWatcher,
    originalData?.custom_template,
    customTemplateWatcher
  ]);

  const targetSalaryLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>What is the target salary for this role?</Text>
    </Group>
  );

  const companyPerksLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>For this role, does your company offer:</Text>
      <Text style={{ marginLeft: 'auto', color: '#B3B3B3' }} size="md">
        Optional
      </Text>
    </Group>
  );

  const jdLinkLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>Link to Job Description</Text>
      <Text align={'right'} style={{ color: '#B3B3B3' }} size="md">
        Optional
      </Text>
    </Group>
  );

  const saleOpportunityLabelWithPopover = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>What are the key selling points of this opportunity?</Text>
      <Popover
        opened={sellOpportunityPopoverIsOpened}
        onClose={() => setSellOpportunityPopoverIsOpened(false)}
        position="top"
        placement="center"
        withArrow
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        width={420}
        styles={{ body: { pointerEvents: 'none' } }}
        target={
          <FontAwesomeIcon
            onMouseEnter={() => setSellOpportunityPopoverIsOpened(true)}
            onMouseLeave={() => setSellOpportunityPopoverIsOpened(false)}
            icon={faQuestionCircle}
            style={{
              cursor: 'pointer',
              marginRight: '4px',
              display: 'inlineBlock',
              color: 'cornflowerblue',
            }}
          />
        }
      >
        <Stack>
          <Text size="md" color={'#242424'}>
            Rolebot will use the information provided to get candidates excited about the job.
          </Text>
          <Text size="md" color={'#242424'}>
            To enhance candidate engagement, we recommend sharing :
          </Text>
          <ul>
            <li>Job responsibilities and expectations</li>
            <li>Upcoming projects or initiatives</li>
            <li>Recent achievements or milestones</li>
            <li>Dynamics of the team the candidate would join</li>
            <li>Company mission and values</li>
          </ul>
          <Text size="md" color={'#242424'}>
            Sharing relevant information can significantly improve candidate engagement and the overall recruitment
            process.
          </Text>
        </Stack>
      </Popover>
    </Group>
  );

  const customTemplateLabelWithPopover = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>Refine candidate outreach with a customized template</Text>
      <Popover
        opened={customTemplatePopoverIsOpened}
        onClose={() => setCustomTemplatePopoverIsOpened(false)}
        position="top"
        placement="center"
        withArrow
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        width={360}
        styles={{ body: { pointerEvents: 'none' } }}
        target={
          <FontAwesomeIcon
            onMouseEnter={() => setCustomTemplatePopoverIsOpened(true)}
            onMouseLeave={() => setCustomTemplatePopoverIsOpened(false)}
            icon={faQuestionCircle}
            style={{
              cursor: 'pointer',
              marginRight: '4px',
              display: 'inlineBlock',
              color: 'cornflowerblue',
            }}
          />
        }
      >
        <Text size="md" color={'#242424'}>
        Enhance candidate engagement with a personalized message! Craft a tailored template to optimize communication with candidates. Include key details about the role and your company to make a lasting impression and drive results.
        </Text>
      </Popover>
      <Text ml={'auto'} style={{ color: '#B3B3B3' }} size="md">
        Optional
      </Text>
    </Group>
  );
  
  const handleClearEditor = () => {
    setValue('custom_template', null, {shouldDirty: true});
    setShowEditor(false);
    setShowDeleteOverlay(false);
  }

  const handleCreateTemplate = () => {
    setValue('custom_template', DEFAULT_TEMPLATE, {shouldDirty: true});
    setShowEditor(true);
  }

  const ClearEditorBtn = (
    <ActionIcon onClick={() => setShowDeleteOverlay(true)} className='ql-clear-button'>
      <FontAwesomeIcon icon={faTrash} />
    </ActionIcon>
  );

  const clearEditorOverlay = (
    <Overlay opacity={0.95} style={{border:'1px solid #DEE2E6', borderRadius:5, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
      <Text align='center' style={{maxWidth:350}}>Are you sure you want to delete this role's customized email template? You can always create a new one later.</Text>
      <Group mt={40}>
          <RolebotButton type={'neutral'} onClick={() => setShowDeleteOverlay(false)}>
            Cancel
          </RolebotButton>
          <RolebotButton type={'destructive'} onClick={handleClearEditor}>
            Delete
          </RolebotButton>
      </Group>
    </Overlay>
  );

  
  const tags = [
    { id: 'candidate_name', value: 'Candidate name' },
    { id: 'interviewer_name', value: 'Interviewer name' },
    { id: 'company_name', value: 'Company name' },
    { id: 'role_name', value: 'Role name' },
    { id: 'hiring_manager_name', value: 'Hiring manager name' },
  ];

  const mentions = useMemo(
    () => ({
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['#'],
      source: (searchTerm: string, renderList: any, mentionChar: string) => {
        const list = tags;
        const includesSearchTerm = list.filter((item: any) =>
          item.value.toLowerCase().includes(searchTerm.toLowerCase())
        );
        renderList(includesSearchTerm);
      },
    }),
    []
  );

  const getEditorLength = () => {
    try {
      return editorRef.current?.getEditor().getLength() ?? 0;
    } catch (error) {
      return 0;
    }
  }

  return (
    <div style={{padding: '2px'}}>
      <>
        <Text weight={500} color="#242424" style={{ fontFamily: 'Roboto' }} mb={20}>
          Job Info
        </Text>
        <Stack mb={40}>
          <RolebotTextInput
            {...register('target_salary')}
            label={targetSalaryLabel}
            placeholder={'$100K USD'}
            error={errors?.target_salary?.message}
          />
          {!sourcing_only && (
            <InputWrapper label={companyPerksLabel} size={'md'}>
              <Stack spacing={'xs'}>
                <>
                  <Checkbox size={'md'} label="Bonus" color="grape" {...register('bonus_offered')} />
                  <Checkbox size={'md'} label="Equity" color="grape" {...register('equity_offered')} />
                </>
              </Stack>
            </InputWrapper>
          )}
          {!sourcing_only && (
            <>
              <RolebotTextInput
                {...register('jd_link')}
                label={jdLinkLabel}
                placeholder={'https://...'}
                error={errors?.jd_link?.message}
              />
              <Text weight={500} color="#242424" style={{ fontFamily: 'Roboto' }} mt={14} pb={4}>
                Candidate Engagement
              </Text>
              <RolebotTextArea
                variant={'default'}
                label={saleOpportunityLabelWithPopover}
                placeholder={
                  'What should we share with the candidates about this opportunity? What makes this role exciting?'
                }
                {...register('sell_opportunity')}
                minRows={3}
                error={errors?.sell_opportunity?.message}
              />
              <Stack spacing={10}>
                {customTemplateLabelWithPopover}
                <Text style={{ color: '#B3B3B3' }} size="md">
                Personalize an example template to tailor Rolebot's message to candidates. 
                Avoid adding candidate-specific information. Write “#” to add a dynamic variable.
                </Text>
                {!showEditor ? (
                  <Text style={{ cursor: 'pointer' }} color={'#40A5D0'} onClick={handleCreateTemplate}>
                    + Create template
                  </Text>
                ) : (
                  <div style={{position: 'relative'}}>
                    {showDeleteOverlay && clearEditorOverlay}
                    <div style={{position: 'absolute', top: '4px', right: '4px', zIndex: 10}}>{ClearEditorBtn}</div>
                    <RichTextEditor
                      ref={editorRef}
                      value={customTemplateWatcher ?? ''}
                      onChange={(text) => setValue('custom_template', text, {shouldDirty: true})}
                      controls={[
                        ['bold', 'italic', 'underline', 'link', 'unorderedList', 'orderedList'],
                      ]}
                      mentions={mentions}
                      styles={{
                        toolbar: {
                          padding: '4px 4px',
                          background: '#F7F5F5'
                        },
                        toolbarInner:{
                          width: '100%'
                        }
                      }}
                      classNames={{
                        toolbarGroup: 'trash-target'
                      }}
                    />
                    <Text size='sm' color='#B3B3B3' align='right'>
                      {getEditorLength()}
                    </Text>
                  </div>
                )}
                { values.custom_template_edited_at && values.custom_template_edited_by && <Text color='#838485' weight={400} style={{fontSize: 16, fontStyle: 'italic'}}>
                  {(values.custom_template && values.custom_template.length > 0) ? 'Last Modified ' : 'Removed'} by {values.custom_template_edited_by} on {moment(values.custom_template_edited_at).format('MMM Do, YYYY')}
                </Text> }
                {getEditorLength() >= 650 && <Group align='center' style={{backgroundColor: 'rgba(255, 196, 0, 0.1)', padding: '4px', borderRadius: '4px'}}>
                  <FontAwesomeIcon icon={faLightbulb} color='#EEA20F' />
                  <Text weight={400} style={{fontSize: 15}} color='#4F4F4F'>Pro tip: Aim for emails under 700 characters for optimal results</Text>
                </Group>}
              </Stack>
            </>
          )}
        </Stack>
        <div style={{ marginBottom: '2px' }}>
          <FormNavigation
            step={2}
            next={handleSubmit(next)}
            prev={handleSubmit(prev)}
            save={handleSubmit(save)}
            isSubmitting={isSubmitting}
            isSaveEnabled={!isValid}
            showFinishLater={showFinishLater}
            isNextDisabled={
              !isValid || targetSalaryWatcher === '' || (!sourcing_only ? sellOpportunityWatcher === '' : false) || isCandidateLocationEmpty
            }
            isSaveChangesDisabled={!formValuesHaveChanged}
          />
        </div>
      </>
    </div>
  );
};

export default JobInfoStep;
