import React from 'react';
import TalentDrawerSection from 'components/TalentDetail/TalentDrawerSection';
import { createStyles, Group, Stack, Text } from '@mantine/core';
import { Point } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { getElapsedTime } from 'utils';
import { ITalentExperience } from 'types';
import { useMediaQuery } from '@mantine/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcase } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  hiddenOnMobile: {
    display: 'none',
    [theme.fn.largerThan('md')]: {
      display: 'inline',
    },
  },
  company: {
    display: 'block',
    [theme.fn.largerThan('md')]: {
      marginTop: 0,
      display: 'inline-block',
    }
  },
}));

interface TalentExperienceProps {
  experience?: ITalentExperience[];
}



const TalentExperience = ({ experience = [] }: TalentExperienceProps) => {
  const { classes } = useStyles();
  const isMediumBreakpoint = useMediaQuery('(min-width: 993px)');
  const format = isMediumBreakpoint ? 'MMMM YYYY' : 'MMM YYYY';
  if (experience.length === 0) return null;

  let previousCompany = '';

  const companyDates: { [key: string]: number[] } = {};
  let totalMonths = 0;
  experience.forEach((job) => {
    if (!companyDates[job?.company_name]) {
      companyDates[job?.company_name] = [];
    }
    let from = job?.pivot?.end_date ? dayjs(job?.pivot?.end_date) : dayjs();
    let to = dayjs(job?.pivot?.start_date);
    totalMonths = from.diff(to, 'months');
    companyDates[job?.company_name].push(totalMonths);
  });


  //sum the months for each company and get years and months
  const companyDuration= Object.keys(companyDates).map((company) => {
    const totalMonths = companyDates[company].reduce((a, b) => a + b, 0);
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    if (years > 0) {
      return { company: company, duration: `${years}y ${months}mo` };
  } else {
    return { company: company, duration: `${months}mo` };
  }
  });

  // find companies that are repeated in the experience array output an array with company_name
  const repeatedCompanies = experience.filter((job, index, self) => {
    return index !== self.findIndex((t) => t?.company_name === job?.company_name);
  }
  ).map((job) => job?.company_name);

  return (
    <TalentDrawerSection title={'Previous Positions'}>
      <Stack>
        {experience?.map((job) => {
          const companyName = job?.company_name;
          const shouldDisplayCompany = companyName !== previousCompany;
          previousCompany = companyName;
          return (
            <Stack>
              {repeatedCompanies.includes(job?.company_name) && shouldDisplayCompany ? (
                <Stack spacing={2} key={job.id}>
                  <Group align={'flex-start'} spacing={12}>
                      <FontAwesomeIcon icon={faSuitcase} style={{ height: '16px', marginTop: '4px' }} color={'#4F4F4F'} />
                      <Text sx={{ fontSize: 16 }} className={classes.company} >{companyName} </Text>
                    </Group>
                  <Group align={'center'} ml={30} >
                  <Text color={'#838485'} sx={{ fontSize: 15, lineHeight: 1.75 }} >{(companyDuration.find((company) => company.company === job?.company_name) || {}).duration}</Text>
                  </Group>
                  <Stack spacing={2} key={job.id}>
                    <Group align={'center'} ml={5} mt={5}>
                      <Point color={'#838485'} fill={'#838485'} size={8} />
                      <Text>
                        {job.name} 
                      </Text>                      
                    </Group>
                    {job?.pivot?.start_date && (
                      <Group align={'center'} ml={30}>
                        <Text color={'#838485'} sx={{ fontSize: 15, lineHeight: 1.75 }}>
                          {dayjs(job?.pivot?.start_date).format(format)} -{' '}
                          {job?.pivot.end_date ? dayjs(job?.pivot?.end_date).format(format) : 'Today'}
                        </Text>
                        <Point color={'#838485'} fill={'#838485'} size={4} />
                        <Text color={'#838485'} sx={{ fontSize: 15, lineHeight: 1.75 }}>
                          {getElapsedTime(job?.pivot?.end_date, job?.pivot?.start_date)}
                        </Text>
                      </Group>
                    )}
                  </Stack>
                </Stack>
              ) : (repeatedCompanies.includes(job.company_name) && !shouldDisplayCompany ? (
                <Stack spacing={2} key={job.id}>
                  <Group align={'center'} ml={5}>
                    <Point color={'#838485'} fill={'#838485'} size={8} />
                    <Text>
                      {job.name}
                    </Text>
                  </Group>
                  {job?.pivot?.start_date && (
                    <Group align={'center'} ml={30}>
                      <Text color={'#838485'} sx={{ fontSize: 15, lineHeight: 1.75 }}>
                        {dayjs(job?.pivot?.start_date).format(format)} -{' '}
                        {job?.pivot.end_date ? dayjs(job?.pivot?.end_date).format(format) : 'Today'}
                      </Text>
                      <Point color={'#838485'} fill={'#838485'} size={4} />
                      <Text color={'#838485'} sx={{ fontSize: 15, lineHeight: 1.75 }}>
                        {getElapsedTime(job?.pivot?.end_date, job?.pivot?.start_date)}
                      </Text>
                    </Group>
                  )}
                </Stack>
              ) : (
                <Stack spacing={2} key={job.id} mb={2}>
                  <Group noWrap align={'flex-start'} spacing={12}>
                    <Group align={'center'} sx={{ width: 17 }}>
                      <FontAwesomeIcon icon={faSuitcase} style={{ height: '16px', marginTop: '4px' }} color={'#4F4F4F'} />
                    </Group>
                    <Text color={'#242424'}>
                      {job.name}
                      <Text className={classes.hiddenOnMobile}> - </Text>
                      <Text sx={{ fontSize: 15 }} className={classes.company}>{` ${job?.company_name}`}</Text>
                    </Text>
                  </Group>
                  {job?.pivot?.start_date && (
                    <Group align={'center'} ml={30}>
                      <Text color={'#838485'} sx={{ fontSize: 15, lineHeight: 1.75 }}>
                        {dayjs(job?.pivot?.start_date).format(format)} -{' '}
                        {job?.pivot.end_date ? dayjs(job?.pivot?.end_date).format(format) : 'Today'}
                      </Text>
                      <Point color={'#838485'} fill={'#838485'} size={4} />
                      <Text color={'#838485'} sx={{ fontSize: 15, lineHeight: 1.75 }}>
                        {getElapsedTime(job?.pivot?.end_date, job?.pivot?.start_date)}
                      </Text>
                    </Group>
                  )}
                </Stack>
              ))}
            </Stack>
          )
        })}
      </Stack>
    </TalentDrawerSection>
  )
};

export default TalentExperience;
