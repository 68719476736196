import axios from 'axios';
import store from 'app/store';
import { rolebotApi, useLazyCsrfQuery } from 'app/services/rolebot';
import { logOut } from 'features/auth/authSlice';

export const RolebotClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
    'Content-Security-Policy': "default-src * 'unsafe-inline'; img-src * data: blob: 'unsafe-inline';",
    Accept: 'application/json',
  },
});



const onError = async (e: any) => {

  if (e.response.status === 419) {
    await RolebotClient({ baseURL: process.env.REACT_APP_API_URL, url:'/sanctum/csrf-cookie', method:'get'});
    return RolebotClient.request(e.config);
  }

  if (e.response.status === 401) {
    store.dispatch(logOut()); 
    // only reset api client if user was logged in before.
    if(store.getState().auth.isLoggedIn){
      store.dispatch(rolebotApi.util.resetApiState());
    }
    return Promise.reject(e);
  }
  return Promise.reject(e);
};

RolebotClient.interceptors.response.use((s) => s, onError);
