import React, { forwardRef } from "react"
import { NumberInput, NumberInputProps, createStyles } from "@mantine/core"

const RolebotNumberInput = forwardRef<HTMLInputElement, NumberInputProps>(({size = 'md', ...props}, ref) => {

    return (
        <NumberInput
            ref={ref}
            size={size}
            {...props} />
    )
})

export default RolebotNumberInput