import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Group, Stack, Title, Pagination, createStyles, Text } from '@mantine/core';
import SearchInput from 'components/SearchInput/SearchInput';
import UserTable from './UserTable';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { useGetUsersQuery } from 'app/services/rolebot';

const useStyles = createStyles( () => ({
	pagination: {
		color: '#838485',
		cursor: 'pointer',
		width: 30,
		height: 30,
		borderRadius: 2,
		':hover': {
		  backgroundColor: '#EEEEEE',
		  color: '#522BB5',
		},
	  },
	
	  activePagination: {
		color: '#fff',
		backgroundColor: '#7039ED',
		':hover': {
		  color: '#fff',
		  backgroundColor: '#7039ED',
		},
	  },
}));


const UserManagement = () => {
	const { inviteUser } = useOpenModal();
	const [makeApiCall, setMakeApiCall] = useState(true);
	const [searchText, setSearchText] = useState('')
	const [activePage, setActivePage] = useState(1)
	const [debouncedText, setDebouncedText] = useState('')
	const [sortBy, setSortBy] = useState<any>({name: 'asc'});


	const { refetch, data } = useGetUsersQuery({
		page: activePage,
		params: {
			search: debouncedText,
			...sortBy
		}
	})

	const onInviteUser = () => {
		inviteUser();
	};

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			setActivePage(1)
		  	setDebouncedText(searchText)
		}, 1000)
	
		return () => clearTimeout(delayDebounceFn)
	}, [searchText])

  	

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stack spacing={30} mb={30}>
        <Title order={2} color={'#242424'}>
          User Management
        </Title>
        <Group>
          <SearchInput value={searchText} onChange={setSearchText} sx={{ minWidth: '50%' }} />
          <Button ml={'auto'} onClick={onInviteUser}>
            Invite User
          </Button>
        </Group>
      </Stack>
      <Divider />
	  {(data?.users?.data && data?.users?.data.length > 0) ? (
		<div style={{ }}>
			<UserTable data={data?.users?.data} sortBy={sortBy} setSortBy={(value) => setSortBy(value)}/>
			<Pagination
              style={!makeApiCall ? { pointerEvents: 'none', opacity: '0.4' } : {}}
              position={'center'}
              total={data?.users?.last_page ?? 1}
              onChange={setActivePage}
              my={30}
			  page={activePage}
              itemComponent={PaginationComponent}
            />
		</div>
	  ) : null}
    </Box>
  );
};

interface IPaginationComponentProps {
	page: number | string;
	active?: boolean | undefined;
	className?: string | undefined;
}

const PaginationComponent = ({ page, active, className, ...rest } : IPaginationComponentProps) => {
	const { classes, cx } = useStyles();
  
	return (
	  <Group
		{...rest}
		className={cx(classes.pagination, { [classes.activePagination]: active })}
		align={'center'}
		position={'center'}
	  >
		<Text>
		  {page === 'prev' && <FontAwesomeIcon icon={faChevronLeft} />}
		  {page === 'next' && <FontAwesomeIcon icon={faChevronRight} />}
		  {page === 'dots' &&
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2 8c0-.733.6-1.333 1.333-1.333.734 0 1.334.6 1.334 1.333s-.6 1.333-1.334 1.333C2.6 9.333 2 8.733 2
		    8zm9.333 0c0-.733.6-1.333 1.334-1.333C13.4 6.667 14 7.267 14 8s-.6 1.333-1.333 1.333c-.734 0-1.334-.6-1.334
		    -1.333zM6.667 8c0-.733.6-1.333 1.333-1.333s1.333.6 1.333 1.333S8.733 9.333 8 9.333 6.667 8.733 6.667 8z"
		    fill="currentColor"/></svg>}
		  {page !== 'prev' && page !== 'next' && page !== 'dots' && page}
		</Text>
	  </Group>
	);
  };

export default UserManagement;
