import { Text, Badge, Group, Tooltip } from '@mantine/core';

interface SearchCriteriaTag {
    item: any;
}

const SearchCriteriaTag = (
    { item }: SearchCriteriaTag,
) => {

    return item['new_value'] !== null ? (
        item['type'] === 'MustHaveSkillAI' || item['type'] === 'JobTitleAI' ? (
            <Tooltip label="Suggested by AI">
                <Badge
                    sx={(theme) => ({
                        backgroundColor: "#ebf6fa",
                        padding: "13px",
                        borderColor: "#147CA1",
                    })}
                    styles={{
                        root: {
                            borderRadius: "5px"
                        },
                        inner: {
                            backgroundColor: "#ebf6fa",
                        },
                    }}
                >
                    <Group spacing={4} sx={{ color: "#242424", fontFamily: 'Helvetica', fontSize: '15px' }}>
                        <Text>+ {item['new_value']}</Text>
                        {(item['type'] === 'MustHaveSkill' || item['type'] === 'MustHaveSkillAI') && (
                            <Text sx={{ color: "#838485", fontStyle: 'italic' }}>(must have)</Text>
                        )}
                    </Group>
                </Badge>
            </Tooltip>
        ) : (
            <Tooltip label="Added by user">
                <Badge
                    sx={(theme) => ({
                        backgroundColor: "#F3F9F2",
                        padding: "13px",
                        borderColor: "#84BF7E",
                    })}
                    styles={{
                        root: {
                            borderRadius: "5px"
                        },
                        inner: {
                            backgroundColor: "#F3F9F2",
                        },
                    }}
                >
                    <Group spacing={4} sx={{ color: "#242424", fontFamily: 'Helvetica', fontSize: '15px' }}>
                        <Text>+ {item['new_value']}</Text>
                        {item['type'] === 'NiceToHaveSkill' && (
                            <Text sx={{ color: "#838485", fontStyle: 'italic' }}>(nice to have)</Text>
                        )}
                        {(item['type'] === 'MustHaveSkill' || item['type'] === 'MustHaveSkillAI') && (
                            <Text sx={{ color: "#838485", fontStyle: 'italic' }}>(must have)</Text>
                        )}
                        {(item['type'] === 'ExcludedJobTitle') && (
                            <Text sx={{ color: "#838485", fontStyle: 'italic' }}>(excluded title)</Text>
                        )}
                        {(item['type'] === 'ExcludedSkill') && (
                            <Text sx={{ color: "#838485", fontStyle: 'italic' }}>(excluded skill)</Text>
                        )}
                    </Group>
                </Badge>
            </Tooltip>
        )
    ) : (
        <Tooltip label="Removed by user">
            <Badge
                sx={(theme) => ({
                    backgroundColor: "#F7F5F5",
                    padding: "13px",
                    borderColor: "#838485",
                })}
                styles={{
                    root: {
                        borderRadius: "5px",
                    },
                    inner: {
                        backgroundColor: "#F7F5F5",
                    },
                }}
            >
                <Group spacing={4} sx={{ color: "#242424", fontFamily: 'Helvetica', fontSize: '15px' }}>
                    <Text>- {item['old_value']}</Text>
                    {item['type'] === 'NiceToHaveSkill' && (
                        <Text sx={{ color: "#838485", fontStyle: 'italic' }}>(nice to have)</Text>
                    )}
                    {item['type'] === 'MustHaveSkill' && (
                        <Text sx={{ color: "#838485", fontStyle: 'italic' }}>(must have)</Text>
                    )}
                    {(item['type'] === 'ExcludedJobTitle') && (
                        <Text sx={{ color: "#838485", fontStyle: 'italic' }}>(excluded title)</Text>
                    )}
                    {(item['type'] === 'ExcludedSkill') && (
                        <Text sx={{ color: "#838485", fontStyle: 'italic' }}>(excluded skill)</Text>
                    )}
                </Group>
            </Badge>
        </Tooltip>
    );

};

export default SearchCriteriaTag;