import { Alert, Button, createStyles, Group, Stack, Text, TextInput } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAddStripeCardMutation, useGetStripeIntentQuery } from 'app/services/rolebot';
import { FormEvent, useEffect, useState } from 'react';
import InfoPopover from 'components/InfoPopover/InfoPopover';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { useNotifications } from '@mantine/notifications';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const useStyles = createStyles((theme) => ({
  empty: {
    fontSize: '22px',
  },
  base: {
    borderRadius: 4,
    width: '100%',
    color: '#242424',
    border: '1px solid #ced4da',
    backgroundColor: '#fff',
    transition: 'border-color 100ms ease',
    height: '42px',
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  complete: {
    border: '1px solid green',
  },
  invalid: {
    border: '1px solid red',
  },

  focus: {
    borderColor: '#339af0',
  },
  expireBase: {
    borderRadius: 4,
    minWidth: 90,
    border: '1px solid #ced4da',
    backgroundColor: '#fff',
    transition: 'border-color 100ms ease',
    height: '42px',
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:span': {
      display: 'inline-block',
      marginInline: 'auto',
    },
  },
  cvcBase: {
    borderRadius: 4,
    width: 62.5,
    border: '1px solid #ced4da',
    backgroundColor: '#fff',
    transition: 'border-color 100ms ease',
    height: '42px',
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:span': {
      display: 'inline-block',
      marginInline: 'auto',
    },
  },
}));

const PaymentForm = ({ id }: { id: string }) => {
  const modals = useModals();
  const notifications = useNotifications();
  const { data } = useGetStripeIntentQuery();
  const { classes } = useStyles();
  const elements = useElements();
  const stripe = useStripe();
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [addStripeCard, { isLoading, isSuccess, isError, reset }] = useAddStripeCardMutation();

  const handleAddCard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    reset();
    if (!stripe) return;
    setLoading(true);
    try {
      const { setupIntent: intent, error } = await stripe.confirmCardSetup(data.intent.client_secret, {
        payment_method: {
          // @ts-ignore
          card: elements!.getElement(CardNumberElement),
          billing_details: {
            name,
          },
        },
      });
      if (error) {
        setError(error?.message || 'Something went wrong');
        setLoading(false);
        return;
      }
      await addStripeCard({ intent }).unwrap();
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isSuccess) return;
    notifications.showNotification({
      message: 'Card added successfully',
      color: 'green',
    });
    modals.closeModal(id);
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    setLoading(false);
    // eslint-disable-next-line
  }, [isError]);

  return (
    <>
      <TitleWithClose id={id} title={'Payment Method'} mb={20} />
      <Text color={'#838485'} mb={30}>
        Add your debit / credit card
      </Text>
      <form onSubmit={handleAddCard}>
        <Stack>
          <TextInput
            label={`Cardholder's Name`}
            placeholder={'Jane Doe'}
            size={'md'}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Group spacing={0}>
            <Text mb={4} weight={500} color={'#212529'}>
              Card Number
            </Text>
            <CardNumberElement
              options={{
                showIcon: true,
                placeholder: '1234 5678 9012 3456',
                style: {
                  base: {
                    fontSize: '18px',
                    fontWeight: 400,
                    '::placeholder': {
                      color: '#838485',
                    },
                  },
                },
                classes: {
                  focus: classes.focus,
                  base: classes.base,
                  empty: classes.empty,
                  complete: classes.complete,
                  invalid: classes.invalid,
                },
              }}
            />
          </Group>
          <Group spacing={0} noWrap direction={'column'}>
            <Text mb={4} weight={500} color={'#212529'}>
              Expiration Date
            </Text>
            <CardExpiryElement
              options={{
                style: {
                  base: {
                    fontSize: '18px',
                  },
                },
                classes: {
                  focus: classes.focus,
                  base: classes.expireBase,
                  empty: classes.empty,
                  complete: classes.complete,
                  invalid: classes.invalid,
                },
              }}
            />
          </Group>
          <Group spacing={0} noWrap direction={'column'}>
            <Group noWrap spacing={10} mb={4} align={'center'}>
              <Text weight={500} color={'#212529'}>
                CVC
              </Text>
              <InfoPopover
                message={
                  'The Card Verification Code, or CVC*, is the three-digit number located on the back of the credit card, immediately after the last four digits of the credit card number.'
                }
              />
            </Group>
            <CardCvcElement
              options={{
                style: {
                  base: {
                    fontSize: '18px',
                  },
                },
                classes: {
                  focus: classes.focus,
                  base: classes.cvcBase,
                  empty: classes.empty,
                  complete: classes.complete,
                  invalid: classes.invalid,
                },
                placeholder: '123',
              }}
            />
          </Group>
        </Stack>
        {(error || isError) && (
          <Alert color={'red'} mt={20}>
            {error ||
              'Something went wrong adding your card. Please try again or contact support if you keep having issues.'}
          </Alert>
        )}
        <Group position={'right'} mt={50}>
          <RolebotButton type={'neutral'} onClick={() => modals.closeAll()}>
            Cancel
          </RolebotButton>

          <Button px={40} loaderPosition={'right'} type={'submit'} disabled={!stripe} loading={loading || isLoading}>
            Add Card
          </Button>
        </Group>
      </form>
    </>
  );
};

const PaymentMethod = ({ id }: ContextModalProps) => {
  const { data } = useGetStripeIntentQuery();

  const options = {
    clientSecret: data?.intent.client_secret,
  };

  if (!data) return null;

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm id={id} />
    </Elements>
  );
};

export default PaymentMethod;
