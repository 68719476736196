import { ButtonStylesParams, CSSObject, MantineTheme } from "@mantine/core";
/*
 * Overrides the default theme styles with custom styles.
 * */

const ThemeStyles: Record<
  string,
  Record<string, CSSObject> | ((theme: MantineTheme, params: any) => Record<string, CSSObject>)
> = {
  Text: (theme) => ({
    root: {
      fontFamily: 'Helvetica',
      fontWeight: 400,
    },
  }),
  Input: (theme) => ({
    label: {
      fontWeight: 400,
      fontSize: theme.fontSizes.md,
      marginBottom: 4,
    },
    input: {
      fontSize: theme.fontSizes.md,
      ':focus[type="text"]': {
        borderWidth: 1,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 2px #7039ED33`,
      },
    },
    defaultVariant: {
      outline: 2,
      border: '1px solid #DFE1E1',
      transition:
        'background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease',
      borderRadius: 40,
      '&:hover': {
        margin: 0,
        boxShadow: `0 0 0 2px #7039ED33`,
      },
      ':focus': {
        borderWidth: 1,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 2px #7039ED33`,
      },
    },
  }),
  TextInput: (theme) => ({
    label: {
      color: '#242424',
      fontWeight: 400,
      marginBottom: 4,
      fontSize: theme.fontSizes.md,
    },
    input: {
      fontSize: theme.fontSizes.md,
      color: '#242424',
      ':disabled': {
        backgroundColor: '#EEEEEE',
        boxShadow: 'none',
      },
    },
    defaultVariant: {
      color: '#242424',
      fontWeight: 400,
      outline: 2,
      border: '1px solid #DFE1E1',
      transition:
        'background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease',
      borderRadius: 40,
      ':hover': {
        margin: 0,
        boxShadow: `0 0 0 2px #7039ED33`,
      },
      ':focus': {
        border: '1px solid rgba(97, 81, 215, 0.5)',
        boxShadow: ' 0px 0px 15px rgba(97, 81, 215, 0.1), inset 0px 0px 4px rgba(0, 0, 0, 0.25)',
      },
      ':focus[type="text"], :focus[type="password"]': {
        borderWidth: 1,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 2px #7039ED33`,
      },
    },
  }),
  PasswordInput: (theme) => ({
    label: {
      marginBottom: 4,
    },
    input: {
      '&:hover': {
        margin: 0,
        boxShadow: `0 0 0 3px #7039ED33`,
      },
      '&:focus': {
        border: '1px solid red',
        // border: '1px solid rgba(97, 81, 215, 0.5)',
        boxShadow: ' 0px 0px 15px rgba(97, 81, 215, 0.1), inset 0px 0px 4px rgba(0, 0, 0, 0.25)',
      },
      ':focus[type="password"]': {
        borderWidth: 1,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 3px #7039ED33`,
      },
    },
  }),
  Button: (theme, params: ButtonStylesParams) => ({
    label: {
      fontFamily: 'Helvetica',
      fontSize: theme.fontSizes.md,
      fontWeight: 400,
    },
    root: {
      translate: 'none',
      borderRadius: 6,
      paddingLeft: 14,
      paddingRight: 14,
      transition: '0.2s',
      ':active': {
        transform: 'none !important',
      },
      width: '100%',
      [theme.fn.largerThan('md')]: {
        width: 'auto',
      },
    },
    filled: {
      backgroundColor: params.color ? '#E50B14' : theme.colors.violet[7],
      '&:hover': {
        backgroundColor: params.color ? '#CF242B' : '#522BB5',
      },
      '&:active': {
        backgroundColor: params.color ? '#B90008' : '#351B77',
      },
      ':disabled': {
        color: '#FFFFFF !important',
        backgroundColor: params.color
          ? `${theme.colors[params.color][3]} !important`
          : `${theme.fn.rgba(theme.colors.violet[7], 0.4)} !important`,
      },
    },
    subtle: {
      color: params.color ? params.color : '#7039ED',
      backgroundColor: 'transparent',
      '&:hover': {
        color: params.color ? params.color : '#522BB5',
        backgroundColor: params.color ? theme.fn.rgba('#CF242B', 0.1) : theme.fn.rgba('#522BB5', 0.1),
        border: params.color ? `1px solid ${params.color}` : '1px solid #522BB5',
      },
      '&:active': {
        backgroundColor: params.color ? '#B900081A' : theme.fn.rgba('#351B771A', 0.1),
        color: params.color ? '#B90008' : '#351B77',
        border: params.color ? '1px solid #B90008' : '1px solid #351B77',
      },
      ':disabled': {
        backgroundColor: 'transparent !important',
        color: params.color
          ? `${theme.fn.rgba('#E50B14', 0.4)} !important`
          : `${theme.fn.rgba(theme.colors.violet[7], 0.4)} !important`,
      },
    },
    outline: {
      backgroundColor: 'transparent',
      color: '#838485',
      border: '1px solid #838485',
      ':hover': {
        backgroundColor: '#EEEEEE',
        color: '#4F4F4F',
      },
      ':active': {
        backgroundColor: '#DFE1E1',
        color: '#4F4F4F',
        border: '1px solid #4F4F4F',
      },
    },
    white: {
      backgroundColor: 'transparent',
      color: '#249FC8',
      ':hover': {
        textDecoration: 'underline',
        textDecorationColor: '#249FC8',
      },
      ':active': {
        color: '#147CA1',
        textDecorationColor: '#147CA1',
      },
    },
  }),
  Tabs: (theme) => ({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    tabControl: {
      color: '#522BB566',
      fontSize: 22,
      fontWeight: 700,
      borderBottom: '3px solid transparent',
    },
    tabActive: {
      color: '#522BB5',
      borderBottomColor: '#522BB5',
      span: {
        color: '#838485 !important',
      },
      ':hover': {
        color: '#522BB5',
      },
    },
    tabsListWrapper: {
      borderBottom: '1px solid #DFE1E1',
      boxShadow: '0px 2px 3px -3px rgba(88,89,91,0.76)',
    },
    tabsList: {
      flexWrap: 'nowrap',
      [theme.fn.largerThan('sm')]: {
        display: 'flex',
      },
      marginBottom: -1,
    },
    tabLabel: {
      wordBreak: 'keep-all',
      fontSize: theme.fontSizes.lg,
      fontFamily: 'Roboto',
      fontWeight: 500,
      span: {
        color: '#B3B3B3',
      },
    },
  }),
  Textarea: (theme) => ({
    input: {
      color: '#242424',
      fontSize: theme.fontSizes.md,
      fontWeight: 400,
      ':focus': {
        borderWidth: 1,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 2px #7039ED33`,
      },
      ':disabled': {
        backgroundColor: '#EEEEEE',
        boxShadow: 'none',
      },
    },
    defaultVariant: {
      outline: 1,
      border: '1px solid #DFE1E1',
      transition:
        'background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease',
      borderRadius: 40,
      ':hover': {
        margin: 0,
        boxShadow: `0 0 0 2px #7039ED33`,
      },
      ':focus': {
        border: '1px solid rgba(97, 81, 215, 0.5)',
        borderWidth: 1,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 2px #7039ED33`,
      },
    },
  }),
  Switch: (theme) => ({
    root: {
      color: 'red',
      cursor: 'pointer',
    },
    input: {
      color: 'blue'
    },
    label: {
      color: 'pink'
    }
  }),
  Select: (theme) => ({
    label: {
      fontWeight: 400,
    },
    input: {
      color: '#242424',
      fontSize: theme.fontSizes.md,
      ':focus': {
        borderWidth: 1,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 2px #7039ED33`,
      },
      ':disabled': {
        backgroundColor: '#EEEEEE',
        boxShadow: 'none',
      },
    },
    defaultVariant: {
      outline: 2,
      border: '1px solid #DFE1E1',
      transition:
        'background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease',
      borderRadius: 40,
      ':hover': {
        margin: 0,
        boxShadow: `0 0 0 2px #7039ED33`,
      },
      ':focus': {
        border: '1px solid rgba(97, 81, 215, 0.5)',
        borderWidth: 1,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 2px #7039ED33`,
      },
    },
    dropdown: {
      padding: '5px 10px',
    },
    hovered: {
      height: '80%',
      backgroundColor: '#EEEEEE',
    },
    selected: {
      backgroundColor: '#E9DDFF',
    },
    item: {
      fontSize: theme.fontSizes.md,
    },
  }),
  NativeSelect: (theme) => ({
    input: {
      fontSize: theme.fontSizes.md,
      ':focus': {
        borderWidth: 2,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 2px #7039ED33`,
      },
      ':disabled': {
        backgroundColor: '#EEEEEE',
        boxShadow: 'none',
      },
    },
    defaultVariant: {
      outline: 1,
      border: '1px solid #DFE1E1',
      transition:
        'background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease',
      borderRadius: 40,
      ':hover': {
        margin: 0,
        boxShadow: `0 0 0 2px #7039ED33`,
      },
      ':focus': {
        border: '1px solid rgba(97, 81, 215, 0.5)',
        borderWidth: 1,
        borderColor: '#C882FF',
        boxShadow: `0 0 0 2px #7039ED33`,
      },
    },
  }),
  Checkbox: (theme) => ({
    input: {
      ':focus': {
        outline: '1px solid #7039ED',
      },
      ':checked': {
        backgroundColor: '#7039ED',
        borderColor: '#7039ED',
      },
      ':hover': {
        borderColor: '#7039ED33',
        border: '3px solid #7039ED33',
      },
      ':disabled': {
        backgroundColor: '#EEE',
        borderColor: '#DFE1E1',
      }
    },
    label: {
      paddingLeft: 10,
      fontSize: theme.fontSizes.md,
      color: '#242424',
    },
  }),
  RadioGroup: (theme) => ({
    radio: {
      ':focus': {
        outline: '1px solid #7039ED',
      },
      ':checked': {
        backgroundColor: '#7039ED',
        borderColor: '#7039ED',
      },
      ':hover': {
        borderColor: '#7039ED33',
        border: '3px solid #7039ED33',
        transition: 'border 50ms linear',
      },
    },
    label: {
      fontWeight: 400,
      fontSize: theme.fontSizes.md,
      color: '#242424',
      ':first-child': { marginLeft: 0 },
      marginLeft: 10,
    },
  }),
  Table: (theme) => ({
    tr: {
      ':hover': {
        backgroundColor: '#F7F5F5',
      },
    },
  }),
  Menu: (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      button: {
        ':hover': {
          transition: 'background-color 0.2s ease-in-out',
          backgroundColor: '#EEEEEE',
        },
        ':focus': {
          backgroundColor: '#EEEEEE',
        },
      },
    },
    item: {
      fontSize: theme.fontSizes.md,
      color: '#242424',
    },
    itemHovered: {
      ':hover': {
        backgroundColor: '#EEEEEE',
      },
    },
  }),
  Avatar: (theme) => ({
    placeholder: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      backgroundColor: '#EEEEEE',
      border: '1px solid #fff',
      borderRadius: '50%',
    },
  }),
  ScrollArea: (theme) => ({
    thumb: {
      backgroundColor: '#c4c4c4',
    },
  }),
  Popover: (theme) => ({
    body: {
      boxShadow: '0px 2px 10px rgba(132, 133, 134, 0.3)',
    },
  }),
  Badge: (theme) => ({
    root: {
      paddingInline: 2,
      overflow: 'unset',
      textOverflow: 'unset',
      backgroundColor: '#f8eaff',
      color: '#24154F',
      fontWeight: 400,
      borderRadius: 2,
    },
    inner: {
      backgroundColor: '#f8eaff',
      fontFamily: 'Helvetica',
      color: '#24154F',
      fontSize: theme.fontSizes.md,
      textTransform: 'none',
    },
  }),
  InputWrapper: (theme) => ({
    label: {
      fontWeight: 400,
    },
  }),
};

export default ThemeStyles;
