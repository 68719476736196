import React from 'react';
import { X } from 'tabler-icons-react';
import { Sx, TextInput, createStyles } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

interface SearchInputProps {
  value: string;
  onChange: (arg: string) => void;
  width?: number;
  sx?: Sx;
  customPlaceholder?: string;
}

const useStyles = createStyles((theme) => ({
  searchIcon: {
    height: '16px',
    paddingBottom: '2px'
  }
}));

const SearchInput = ({ value, onChange, sx, customPlaceholder }: SearchInputProps) => {
  const { classes } = useStyles();

  return (
    <TextInput
      styles={{
        defaultVariant: {
          outline: 2,
          border: '1px solid #DFE1E1',
          transition:
            'background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease',
          borderRadius: 40,
          '&:hover': {
            margin: 0,
            border: '1px solid rgba(97, 81, 215, 0.1)',
            boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)',
          },
          '&:focus': {
            border: '1px solid rgba(97, 81, 215, 0.5)',
            boxShadow: ' 0px 0px 15px rgba(97, 81, 215, 0.1), inset 0px 0px 4px rgba(0, 0, 0, 0.25)',
          },
        },
      }}
      placeholder={customPlaceholder ? customPlaceholder : 'Search'}
      variant={'default'}
      radius="xl"
      size="md"
      icon={<FontAwesomeIcon icon={faMagnifyingGlass} className={classes.searchIcon}/>}
      rightSection={
        value ? (
          <X size={20} onClick={() => onChange('')} color={'#4F4F4F'} style={{ cursor: 'pointer', marginRight: 10 }} />
        ) : null
      }
      sx={sx}
      value={value}
      onChange={({ target }) => onChange(target.value)}
    />
  );
};

export default SearchInput;
