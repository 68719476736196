import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  createStyles,
  Group,
  Image,
  Input,
  InputWrapper,
  Stack,
  Text,
  TextInput,
  Title,
  LoadingOverlay,
  NumberInput,
  UnstyledButton,
} from '@mantine/core';
import { Link, Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';
import useAppSelector from 'hooks/useAppSelector';
import { ILocation } from 'types';
import { useLazyGetUserQuery, usePostResendOTPMutation, usePostVerifyOTPMutation, useSignInMutation } from 'app/services/rolebot';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { mapErrors } from 'utils';
import RolebotButton from 'components/RolebotButton/RolebotButton';
import RolebotOTP from 'assets/img/rolebot-otp.png'
import { useNotifications } from '@mantine/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { LoginPayload } from 'types/requests';

const useStyles = createStyles((theme) => ({
  button: {
    marginBottom: '20px',
    [theme.fn.largerThan('sm')]: {
      marginLeft: 'auto',
    },
  },
}));

const LoginFormSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
});

const OTPFormSchema = z.object({
  code: z.string().min(6).max(6)
})

type LoginFormPayload = z.infer<typeof LoginFormSchema>;
type OTPFormPayload = z.infer<typeof OTPFormSchema>;

const Login = () => {
  const { classes } = useStyles();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const OTPToken = useAppSelector((state) => state.auth.OTPToken);
  const userId = useAppSelector((state) => state.auth.userId)
  const navigate = useNavigate();
  const notifications = useNotifications();
  const location = useLocation() as ILocation;
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(LoginFormSchema),
  });

  const {
    handleSubmit: handleOTPSubmit,
    register: registerOTP,
    formState: { errors: otpErrors, isValid: otpIsValid },
  } = useForm({
    defaultValues: {
      code: '',
    },
    resolver: zodResolver(OTPFormSchema),
  });

  const [login, { isLoading, isError, reset, error }] = useSignInMutation({ fixedCacheKey: 'login' });
  const [getUser, {  isFetching }] = useLazyGetUserQuery();
  const [verifyOTP, {isLoading: verifyOTPLoading, isError: verifyOTPIsError, error: verifyOTPError}] = usePostVerifyOTPMutation()
  const [resendOTP, {isLoading: resendOTPLoading, isError: resendOTPIsError, error: resendOTPError}] = usePostResendOTPMutation()
  // This checks for pathname then sends you to home (/roles in our case)
  const from = location.state?.from?.pathname || '/roles';

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [getUser]);

  const handleSignIn = async (payload: LoginFormPayload) => {
    reset();
    const { email, password } = payload;

    const otpSessions = document.cookie.match('(^|;)\\s*' + 'ots' + '\\s*=\\s*([^;]+)')?.pop() || null
    const loginPayload:LoginPayload = {email, password}
    if(otpSessions) loginPayload.otp_sessions = otpSessions
    const {require_otp} = await login(loginPayload).unwrap();
    navigate(from, { replace: true });
  };

  const [otpCurrentError, setOtpCurrentError] = useState<React.ReactNode>(null)

  const CodeRequestsExceededError = <>
    <Group noWrap align={'start'}>
      <FontAwesomeIcon icon={faTriangleExclamation} color='#E50B14' width={20} height={20}/>
      <Text color={'#E50B14'} weight={400} style={{fontSize: 16}}>
        You have exceeded the maximum number of code requests. Enter the last code you received or try again later.
      </Text>
    </Group>
  </>

  const CodeExpiredError = <>
    <Text color={'#E50B14'} weight={400} size={'sm'}>
      This code has now expired. Request a new code and try again.
    </Text>
  </>

  const CodeInvalidError = <>
    <Text color={'#E50B14'} weight={400} size={'sm'}>
      Invalid code. Please check the code you entered and try again.
    </Text>
  </>

  const RateLimitError = <>
    <Text color={'#E50B14'} weight={400} size={'sm'}>
      You have exceeded the maximum number of attempts. Try again later.
    </Text>
  </>

  const handleVerifyOTP = async (payload: OTPFormPayload) => {
    try {
      const {success, error} = await verifyOTP({code: payload.code, token: OTPToken!}).unwrap()
    } catch (error:any) {
      console.log(error)
      if(error.status === 429){
        setOtpCurrentError(RateLimitError)
      } else {
        switch (error.data.error) {
          case 'INVALID_CODE':
            setOtpCurrentError(CodeInvalidError)
            break
          case 'EXPIRED_CODE':
            setOtpCurrentError(CodeExpiredError)
            break
        }
      }
      
    }
  }

  const handleResendOTP = async () => {
    try {
      const {success, error} = await resendOTP({token: OTPToken!}).unwrap()
      if (success) {
        notifications.showNotification({
          title: 'Verification code sent!',
          color: 'green',
          message: 'A 6-digit code has been sent to your business email.',
          autoClose: 3000,
        });
      }
    } catch (error:any) {
      switch (error.data.error) {
        case 'EXCEEDED_RESENDS':
          setOtpCurrentError(CodeRequestsExceededError)
          break
      }
    }
    
  }

  if (isLoggedIn) return <Navigate to={from} replace />;

  return (
    <Container
      size={OTPToken ? undefined : 600}
      styles={{
        root: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '40px 18px',
        },
      }}
    >
      <LoadingOverlay visible={isFetching} transitionDuration={0} />
      {!OTPToken && (<div>
        <NavLink to={'/'}>
          <Image src={LogoLetters} width={180} fit={'contain'} mb={50} />
        </NavLink>
        <Title order={3} mb={30} color='#242424'>
          Welcome Back!
        </Title>
        {isError && (
          <Text color={'#FF5555'} mb={20}>
            {mapErrors(error)[0] || 'Something went wrong. Please contact support if this keeps happening.'}
          </Text>
        )}
        <Stack>
          <form onSubmit={handleSubmit(handleSignIn)} id="sign-in-form">
            <Stack>
              <TextInput
                size={'md'}
                label="Email"
                placeholder="example@email.com"
                autoComplete="email"
                {...register('email')}
                error={errors?.email?.message}
                tabIndex={1}
                autoCapitalize={'false'}
              />
              <InputWrapper error={errors?.password?.message} size={'md'}>
                <Group position={'apart'} mb={4}>
                  <Text component={'label'} size={'md'} weight={500} color="#242424" htmlFor={'password'}>
                    Password
                  </Text>
                  <Text component={Link} to={'/forgot-password'} size={'md'} weight={400} color="#7039ED" tabIndex={4}>
                    Forgot Password?
                  </Text>
                </Group>
                <Input
                  id={'password'}
                  sx={{ width: '100%' }}
                  placeholder={'8+ characters'}
                  size={'md'}
                  {...register('password')}
                  tabIndex={2}
                  type={'password'}
                />
              </InputWrapper>
            </Stack>
          </form>
          <Button
            className={classes.button}
            mt="xl"
            type="submit"
            form="sign-in-form"
            loading={isLoading}
            loaderPosition={'right'}
            px={50}
            tabIndex={3}
          >
            Sign in
          </Button>
        </Stack>
      </div>)}
      {OTPToken && <div>
        <NavLink to={'/'}>
          <Image src={LogoLetters} width={180} fit={'contain'} mb={50} />
        </NavLink>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <Stack align={'center'} style={{maxWidth: 600, width: '100%'}}>
            <Image src={RolebotOTP} width={95} fit={'contain'} mb={36} />
            <Text mb={4} style={{fontSize: 28, fontFamily: 'Roboto', fontWeight: 500}} color='#242424'>Two-factor authentication</Text>
            <Text mb={4} style={{fontSize: 16, color: '#838485', textAlign: 'center', maxWidth: 450}}>
              Your company requires a two-factor authentication to log in.
                  Enter the 6-digit code sent to your email to verify your identity.
            </Text>
            <TextInput {...registerOTP('code')} style={{width: '100%'}} type='number' placeholder="Code" error={otpCurrentError}
              onWheel={(e) => e.currentTarget.blur()}
              onKeyDown={(e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                  e.preventDefault();
                }
              }}/>
            <RolebotButton disabled={!otpIsValid} mt={24} style={{width: '100%'}} onClick={handleOTPSubmit(handleVerifyOTP)} loading={verifyOTPLoading}>Verify</RolebotButton>
            <Group mt={44} spacing={'xs'}>
              <Text color='#242424'>Didn't receive a code?</Text>
              <UnstyledButton onClick={handleResendOTP} disabled={resendOTPLoading} style={{color: '#7039ED'}}>Send it again</UnstyledButton>
            </Group>
          </Stack>
        </div>
      </div>}
    </Container>
  );
};

export default Login;
