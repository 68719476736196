import React from 'react';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import { Group } from '@mantine/core';

interface ColumnSortIconProps {
  sortType: string;
  sortOrder: 'asc' | 'desc' | null;
  onClick: (sortType: string) => void;
}

const ColumnSortIcons = ({ sortType, sortOrder, onClick }: ColumnSortIconProps) => {
  const Icon = sortOrder === 'asc' ? ChevronUp : ChevronDown;
  return (
    <Group noWrap onClick={() => onClick(sortType)}>
      <Icon size={14} />
    </Group>
  );
};

export default ColumnSortIcons;
