import React from 'react';
import { Stack, Text, Title, Image, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    image: {
        height: 250,
        width: 'auto%',
    },

    noItemsTitle: {
        color: '#242424',
        fontSize: 28,
        [theme.fn.largerThan('md')]: {
            fontSize: 34,
        },
    },

    placeholderContainer: {
        paddingInline: 25,
        gap: 10,
        [theme.fn.largerThan('md')]: {
            paddingInline: 0,
            gap: 0,
        },
    },
}))

const RoleAllSetScreen = () => {
    const { classes } = useStyles();

    return (
        <Stack sx={{ height: '100%' }} align={'center'} mb={'20%'} justify={'center'} spacing={20}>
            <Image src={require('../../../assets/img/rolebot-allset.png')} fit={'contain'} width={140} />
            <Title className={classes.noItemsTitle} sx={{ color: '#242424' }}>
                You're all set!
            </Title>
            <Stack align={'center'} justify={'center'} className={classes.placeholderContainer}>
                <Text color={'#242424'} align={'center'}>
                    We now have the information we need to start matching you with potential candidates
                </Text>
                <Text color={'#242424'} align={'center'}>
                    Hang tight! Your first set of candidates is on its way!
                </Text>
            </Stack>
        </Stack>
    );

}

export default RoleAllSetScreen;