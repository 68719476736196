import React, { useRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import useOutsideAreaClick from '../../../../pages/RoleStats/hooks/useOutsideAreaClick';
import { createStyles } from '@mantine/core';
import MetricRow from './MetricRow';

const useStyles = createStyles((theme) => ({
  DropDownWrapper: {
    position: 'relative',
    marginTop: '2px',
  },

  AddedRowsDropDown: {
    border: 'solid 2px #DFE1E1',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: '1000',
    top: '0',
    left: '0',
    right: '0',
    padding: '5px 7px',
    maxHeight: '150px',
    overflowY: 'auto',
    borderRadius: '10px',
  },

  NotFound: {
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '8px',
    color: '#242424',
    padding: '2px 10px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      borderRadius: '5px',
    },
    '&:last-child': {
      marginBottom: '0',
    },
  },
  ActiveRole: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    color: '#838485',
    textTransform: 'uppercase',
    margin: '12px 0 10px 12px',
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case 'setRowsToShow':
      return {
        ...state,
        data: action.payload,
      };
    default:
      throw new Error();
  }
};

const MetricDropDown = ({ show, rowSelected, needle = '', closeDropdown, dataToshow, filter, filter2 }) => {
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    searchValue: '',
  });
  const { classes } = useStyles();
  const wrapperRef = useRef(null);
  const clickedOutside = useOutsideAreaClick(wrapperRef);

  useEffect(() => {
    if (clickedOutside && show) {
      closeDropdown();
    }
    // eslint-disable-next-line
  }, [clickedOutside, show]);

  useEffect(() => {
    let filteredData =
      filter === 'active-roles'
        ? dataToshow.filter((x) => !x.completed)
        : filter === 'completed-roles'
        ? dataToshow.filter((x) => x.completed)
        : dataToshow;

    filteredData =
      filter2 === 'turnkey-roles'
        ? filteredData.filter((x) => !x.sourcing_only)
        : filter2 === 'sourcing-only-roles'
        ? filteredData.filter((x) => x.sourcing_only)
        : filteredData;

    if (dataToshow.length > 0) {
      dispatch({ type: 'setRowsToShow', payload: filteredData });
    }
    // eslint-disable-next-line
  }, [dataToshow, filter, filter2]);

  const rowsToShow =
    state.data.length > 0 &&
    state.data.filter(
      (x) =>
        x.name.includes(needle) ||
        x.name.toLowerCase().includes(needle.toLowerCase()) ||
        x.name.toUpperCase().includes(needle.toUpperCase()) ||
        (x.greenhouse_job
          ? x.greenhouse_job.requisition_id?.includes(needle)
          : x.lever_job
          ? x.lever_job?.requisition_ids.replace(/[[\]"\\]/g, '').includes(needle)
          : x.sap_job && x.sap_job?.requisition_id.includes(needle))
    );

  return show && state.data.length ? (
    <div className={classes.DropDownWrapper} ref={wrapperRef}>
      <div className={classes.AddedRowsDropDown}>
        {(filter !== 'all' || filter2 !== 'all') && (
          <div className={classes.ActiveRole}>
            displaying
            {filter === 'active-roles' ? ' active roles' : filter === 'completed-roles' && ' completed roles'}
            {filter2 === 'turnkey-roles' ? `${filter !== 'all' ? ' and' : ''} turnkey roles` : filter2 === 'sourcing-only-roles' && `${filter !== 'all' ? ' and' : ''} sourcing only roles`}
          </div>
        )}
        {rowsToShow.map((x) => (
          <MetricRow row={x} key={x.id} rowSelected={rowSelected} />
        ))}
        {rowsToShow.length === 0 && <div className={classes.NotFound}>No matches found.</div>}
      </div>
    </div>
  ) : null;
};

export default MetricDropDown;

MetricDropDown.propTypes = {
  show: PropTypes.bool.isRequired,
  rowSelected: PropTypes.func.isRequired,
};
