import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps } from '@mantine/modals';
import { RoleLimitReachedProps } from '../../../types/modals';
import { Button, Group, Text, createStyles, MediaQuery } from '@mantine/core';
import { toastSuccess } from 'utils/toastify-messages';
import { usePostRequestUpgradePlanMutation } from 'app/services/rolebot';

const useStyles = createStyles((theme) => ({
    okayButton: {
        backgroundColor: '#FFF',
        color: '#838485',
        borderRadius: '6px',
        border: '1px solid #838485',
        paddingRight: '20px',
        paddingLeft: '20px',
        fontSize: '16px',
        '&:hover': {
            backgroundColor: '#EEEEEE',
            color: '#4F4F4F',
            border: '1px solid #4F4F4F',
        },
    },
    submitButton: {
        color: '#7039ED',
        background: 'none',
        fontSize: '16px',
        '&:hover': {
            textDecorationLine: 'underline',
            background: 'none'
        },
    },

    button: {
        flexGrow: 1,
        [theme.fn.largerThan('md')]: {
            flexGrow: 0,
        },
    },
}));

const ContactSales = ({ id, context, innerProps }: ContextModalProps<RoleLimitReachedProps>) => {
    const [postRequestUpgradePlan, { isLoading, isSuccess, isError, reset: resetMutation }] = usePostRequestUpgradePlanMutation();
    const { fromProPlan } = innerProps;
    const { classes } = useStyles();

    const handleCloseModal = () => {
        context.closeModal(id);
    }

    const handleRequestInfo = async () => {
        try {
            await postRequestUpgradePlan({}).unwrap();

            context.closeModal(id);

            toastSuccess(
                <div className="toastContainer">
                    <div>Success!</div>
                    <div>A member of our team will contact you for further steps.</div>
                </div>
            );

        } catch (e: any) {
            console.log('updgradePlan error', e);
        }
    }

    return (
        <>
            <TitleWithClose id={id} title={fromProPlan ? 'Ready to Explore a Pro Plan?' : 'Upgrade or change your plan'} />
            <>
                {fromProPlan ?
                ( 
                    <Text mb={20} color={'#242424'}>
                        It looks like you're interested in our Pro plan for more than 5 roles. Our sales team is here to help create a tailored solution that meets your needs. Would you like to proceed to contact sales?
                    </Text>
                ) : (
                    <Text mb={20} color={'#242424'}>
                        Looking to make changes to your plan or explore new options? We're here to assist you! Click on the "Contact Sales" button below, and a dedicated team member will reach out shortly to discuss how we can tailor your plan to better suit your needs.
                    </Text>
                )}
            </>
            <Group position={'right'} mt={20} align={'end'}>
                <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                    <Button disabled={isLoading} onClick={handleCloseModal} variant={'outline'}>
                        Cancel
                    </Button>
                </MediaQuery>
                <Button
                    onClick={handleRequestInfo}
                    loading={isLoading}
                    loaderPosition={'right'}
                    className={classes.button}
                >
                    {fromProPlan ? 'Yes, Contact Sales' : 'Contact Sales'}
                </Button>
            </Group>
        </>
    );
};

export default ContactSales;