import React from 'react';
import { UnstyledButton } from '@mantine/core';

interface DayButtonProps {
  children: React.ReactNode;
  dayId: number;
  onClick: (id: number) => void;
  isSelected: boolean
}

const DayButton = ({ children, dayId, onClick, isSelected }: DayButtonProps) => {

  return (
    <UnstyledButton
      onClick={() => onClick(dayId)}
      sx={{
        backgroundColor: isSelected ? '#7039ED' : '#eee',
        width: 40,
        height: 40,
        borderRadius: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: isSelected ? '#fff' : '#4F4F4F',
        textTransform: 'capitalize'
      }}
    >
      {children}
    </UnstyledButton>
  );
};

export default DayButton;
