import React, { useEffect } from 'react';
import { useLazyCsrfQuery } from 'app/services/rolebot';

interface CsrfTokenProps {
  children: React.ReactNode;
}

const CsrfToken = (props: CsrfTokenProps) => {
  const [getCsrf] = useLazyCsrfQuery();

  useEffect(() => {
    getCsrf();
    // eslint-disable-next-line
  }, []);

  return <>{props.children}</>;
};

export default CsrfToken;
