import moment from 'moment';

export const getNumberOfDaysByDate = (date: any): number => {
  const iniDate = moment(date).format('YYYY-MM-DD');
  const currentDate: any = moment().format('YYYY-MM-DD');
  return Math.abs(moment(iniDate).diff(currentDate, 'day'));
};

export const getTimeinHourOrDayByDate = (date: any) => {
  const iniDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  const currentDate: any = moment.utc().format('YYYY-MM-DD HH:mm:ss');

  const minutes = Math.abs(moment(iniDate).diff(currentDate, 'minute'));
  const hours = Math.abs(moment(iniDate).diff(currentDate, 'hour'));
  const days = Math.abs(moment(iniDate).diff(currentDate, 'day'));
  const months = Math.abs(moment(iniDate).diff(currentDate, 'month'));
  const years = Math.abs(moment(iniDate).diff(currentDate, 'year'));

  if (minutes > 60 && hours > 0 && hours < 24) {
    return `${hours} h`;
  }

  if (hours > 23 && days > 0 && days < 63) {
    return `${days} d`;
  }

  if (days > 62 && months > 0) {
    return `${months} mo`;
  }

  if (months > 12 && years > 0) {
    return `${months} yr`;
  }

  return `${minutes} mi`;
};
