import React, {useState} from "react";
// import {OverlayTrigger, Popover} from 'react-bootstrap';
import WidgetWrapper from '../WidgetWrapper/WidgetWrapper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import PercentBarDisplay from "../PercentBarDisplay/PercentBarDisplay";
import PropTypes from 'prop-types';
import {createStyles, Popover, Text} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    DangerIcon: {
        position: 'absolute',
        top: '10px',
        left: '15px'
    },

    Help: {
        display: 'inline-block',
        position: 'relative',
        marginLeft: '3px',
        top: '2px'
    },

    GraphWrapper: {
        /*margin-bottom: 15px;*/
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '145px',
        justifyContent: 'space-evenly'
    },

    Score: {
        textAlign: 'center',
        fontSize: '35px',
        position: 'absolute'
    },

    Title: {
        marginBottom: '15px'
    },

    BottomWrapper: {
        fontSize: '13px'
    }
}));

const  PercentBarSmallWidget = props => {
    const { classes } = useStyles();
    const [opened, setOpened] = useState(false);

    const popover = (
        <Popover
            opened={opened}
            onClose={() => setOpened(false)}
            position="top"
            placement="start"
            withArrow
            trapFocus={false}
            closeOnEscape={false}
            transition="pop-top-left"
            width={260}
            styles={{ body: { pointerEvents: 'none' } }}
            target={
                <FontAwesomeIcon onMouseEnter={() => setOpened(true)} onMouseLeave={() => setOpened(false)} icon={faQuestionCircle} style={{cursor: 'pointer', marginRight: "4px", display: "inlineBlock", color: 'cornflowerblue'}}/>
            }
        >
            <div>
                <Text size="md" style={{marginBottom: 10, fontWeight: "bold"}}>{ props.section1 }</Text>
                <Text size="sm">{ props.helpMessage }</Text>
            </div>
        </Popover>
    );

    return (
        <WidgetWrapper>
            <div className={classes.Icon}>{props.icon}</div>
            <div className={classes.GraphWrapper}>
                <PercentBarDisplay title={props.barOneText} value={props.barOnePercent} fillColor={props.color}/>
                <PercentBarDisplay title={props.barTwoText} value={props.barTwoPercent} fillColor={props.color}/>
            </div>
            <div className={classes.Title}>
                {props.section1}
                <div className={classes.Help}>
                    { popover }
                </div>
            </div>
            <div className={classes.BottomWrapper}>
                <div>{props.section2}</div>
                <div>{props.section3}</div>
            </div>
        </WidgetWrapper>
    );
};

export default PercentBarSmallWidget;

PercentBarSmallWidget.propTypes = {
    barOneText: PropTypes.string.isRequired,
    barOnePercent: PropTypes.number.isRequired,
    barTwoText: PropTypes.string.isRequired,
    barTwoPercent: PropTypes.number.isRequired,
};

PercentBarSmallWidget.defaultProps = {
};
