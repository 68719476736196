export function number_format(val: any, decimals: any = null) {
  //Parse the value as a float value
  val = parseFloat(val);
  //Format the value w/ the specified number
  //of decimal places and return it.
  return parseInt(val.toFixed(decimals));
}

export function calculatePercent(small: any, big: any) {
  return (small * 100) / big;
}

export const buildMetricsData = (rolesData: any) => {
  let totalTalentSourced = 0;

  let totalTalentReviewed = 0;

  // green flag
  let interestedTalent = 0;

  // accepted Talent
  let acceptedTalent = 0;

  // talent accepted in a active role
  let inProgressTalent = 0;

  // total interviews
  let interviews = 0;

  // total diverse talent
  let diverseTalent = 0;

  // Total diverse talent accepted
  let diverseTalentAccepted = 0;

  //responses
  let talentHappyInRole = 0;
  let talentCompDifferential = 0;
  let talentCommuteDistance = 0;
  let talentNotInterested = 0;
  let talentRequireVisaSponsorship = 0;
  let talentSeekingRemoteWork = 0;
  let talentSeekingHybridWork = 0;
  let talentNotOpenToRelocation = 0;

  let totalResponsesPercentage = 0;
  let sourcingAccuracyPercentage = 0;

  let sourcedDiversityPercentage = 0;
  let acceptedDiversityPercentage = 0;

  // response rate pie data
  let pieData = [];

  // @ts-ignore
  rolesData.forEach((x) => {
    const {
      talent_reviewed,
      talent_interested_count,
      talent_interview_count,
      talent_happy_in_role_count,
      talent_comp_differential_count,
      talent_commute_distance_count,
      talent_requires_visa_sponsorship_count,
      talent_seeking_remote_work_count,
      talent_seeking_hybrid_work_count,
      talent_not_open_to_relocation_count,
      talent_not_interested_count,
      talent_in_progress_count,
      talent_diverse_count,
      talent_diverse_accepted_count,
      talent_accepted_count,
    } = x.metrics;

    const { talent_count } = x;

    totalTalentSourced += talent_count;
    totalTalentReviewed += talent_reviewed;
    interestedTalent += x.sourcing_only ? 0 : talent_interested_count;
    interviews += x.sourcing_only ? 0 : talent_interview_count;
    acceptedTalent += talent_accepted_count;
    talentHappyInRole += x.sourcing_only ? 0 : talent_happy_in_role_count;
    talentCompDifferential += x.sourcing_only ? 0 : talent_comp_differential_count;
    talentCommuteDistance += x.sourcing_only ? 0 : talent_commute_distance_count;
    talentRequireVisaSponsorship += x.sourcing_only ? 0 : talent_requires_visa_sponsorship_count;
    talentSeekingRemoteWork += x.sourcing_only ? 0 : talent_seeking_remote_work_count;
    talentSeekingHybridWork += x.sourcing_only ? 0 : talent_seeking_hybrid_work_count;
    talentNotOpenToRelocation += x.sourcing_only ? 0 : talent_not_open_to_relocation_count;
    talentNotInterested += x.sourcing_only? 0 : talent_not_interested_count;
    diverseTalent += talent_diverse_count;
    diverseTalentAccepted += talent_diverse_accepted_count;

    // only add to inProgressTalent if the role is active
    if (!x.completed) {
      inProgressTalent += talent_in_progress_count;
    }
  });

  if (interviews > 0) {
    pieData.push({ name: 'Confirmed Interviews', value: interviews, color: '#4EB448' });
  }

  if (interestedTalent > 0) {
    pieData.push({ name: 'Interested Candidates', value: interestedTalent, color: '#FFD250' });
  }

  if (talentHappyInRole > 0) {
    pieData.push({ name: 'Happy in Role', value: talentHappyInRole, color: '#18A9B7' });
  }

  if (talentCompDifferential > 0) {
    pieData.push({ name: 'Comp. Differential', value: talentCompDifferential, color: '#E16726' });
  }

  if (talentCommuteDistance > 0) {
    pieData.push({ name: 'Commute Distance', value: talentCommuteDistance, color: '#4A2E91' });
  }

  if (talentNotInterested > 0) {
    pieData.push({ name: 'Not Interested', value: talentNotInterested, color: '#C7489A' });
  }

  if (talentSeekingRemoteWork > 0) {
    pieData.push({ name: 'Seeking remote opportunities', value: talentSeekingRemoteWork, color: '#004EC3' });
  }

  if (talentSeekingHybridWork > 0) {
    pieData.push({ name: 'Seeking hybrid opportunities', value: talentSeekingHybridWork, color: '#109153' });
  }

  if (talentRequireVisaSponsorship > 0) {
    pieData.push({ name: 'Requires visa Sponsorship', value: talentRequireVisaSponsorship, color: '#B3B3B3' });
  }

  if (talentNotOpenToRelocation > 0) {
    pieData.push({ name: 'Not interested in relocating', value: talentNotOpenToRelocation, color: '#838485' });
  }

  const totalResponses =
    talentHappyInRole +
    talentCompDifferential +
    talentCommuteDistance +
    talentSeekingRemoteWork +
    talentSeekingHybridWork +
    talentRequireVisaSponsorship +
    talentNotOpenToRelocation +
    talentNotInterested +
    interviews +
    interestedTalent;
  if (totalResponses > 0) {
    // @ts-ignore
    totalResponsesPercentage = number_format(calculatePercent(totalResponses, acceptedTalent));
  }

  if (totalTalentSourced > 0) {
    // @ts-ignore
    sourcingAccuracyPercentage = number_format(calculatePercent(acceptedTalent, totalTalentReviewed));
  }

  if (totalTalentSourced > 0) {
    // @ts-ignore
    sourcedDiversityPercentage = number_format(calculatePercent(diverseTalent, totalTalentSourced));
  }

  if (totalTalentSourced > 0) {
    // @ts-ignore
    acceptedDiversityPercentage = number_format(calculatePercent(diverseTalentAccepted, diverseTalent));
    //there is a chance that the line above return undefined. In that case we set this value to 0;
    acceptedDiversityPercentage = acceptedDiversityPercentage ? acceptedDiversityPercentage : 0;
  }

  return {
    interestedTalent,
    inProgressTalent,
    interviews,
    acceptedTalent,
    totalResponses,
    totalResponsesPercentage,
    diverseTalent,
    diverseTalentAccepted,
    sourcedDiversityPercentage,
    acceptedDiversityPercentage,
    totalTalentSourced,
    sourcingAccuracyPercentage,
    pieData,
    totalTalentReviewed,
  };
};

