import React from 'react';
import TalentDrawerSection from 'components/TalentDetail/TalentDrawerSection';
import { Group, Text } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';

const TalentAbout = ({ about = '' }: { about: string }) => {
  const [isOpen, toggle] = useToggle(false, [false, true]);
  const shouldToggle = about?.length > 120;
  if (!about) return null;
  return (
    <TalentDrawerSection title={'About'}>
      <Text color={'#4f4f4f'} lineClamp={shouldToggle && !isOpen ? 2 : 0}>
        {about}
      </Text>
      {about.length > 120 ? (
        <Group onClick={() => toggle()} align={'center'} spacing={4} sx={{ cursor: 'pointer' }}>
          <Text color={'#1075BDCC'} sx={{ display: 'inline' }}>
            {isOpen ? 'Show less' : 'Show more'}
          </Text>
          <Group sx={{ width: 20 }} align={'center'} position={'center'}>
            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} width={16} height={16} color={'#1075BDCC'} />
          </Group>
        </Group>
      ) : null}
    </TalentDrawerSection>
  );
};

export default TalentAbout;
