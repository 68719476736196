import { FormEvent, forwardRef, useEffect, useRef, useState } from 'react';
import { ActionIcon, Autocomplete, Checkbox, createStyles, Group, InputWrapper, MediaQuery, Popover, Stack, Text } from '@mantine/core';
import FormNavigation from 'components/Modals/RoleInfo/FormNavigation';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ChevronDown } from 'tabler-icons-react';
import { TrimValue } from 'utils';
import { useGetCompanyLocationsQuery, useGetSearchLocationsQuery } from 'app/services/rolebot';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { faLocationDot, faPlus, faTriangleExclamation, faXmark } from '@fortawesome/pro-light-svg-icons';
import { nanoid } from 'nanoid';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useNavigate } from 'react-router-dom';
import RolebotSelect from 'components/Inputs/RolebotSelect';
import RolebotTextInput from 'components/Inputs/RolebotTextInput';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { useModals } from '@mantine/modals';

interface LocationStepProps {
  prev: (payload: object) => void;
  next: (payload: object) => void;
  save: (payload: object) => void;
  showFinishLater: boolean;
  values: {
    target_salary: string;
    offer_relocation: boolean;
    visa_sponsorship: boolean;
    environment: string;
    office_locations: any;
    specific_location: boolean;
    remote_locations: any
    remote_buffer: any,
    office_buffer: any
  };
  originalData: any;
  isSubmitting: boolean;
  sourcing_only: boolean;
  showInfo: boolean,
  isAdmin: boolean,
  oldLocation: string;
  onUnmount: (payload: object) => void;
  handleCandidateLocationChanges: (payload: object) => void;
}

const useStyles = createStyles((theme) => ({
  defaultVariant: {
    color: '#242424',
    fontWeight: 400,
    outline: 2,
    border: '1px solid #DFE1E1',
    height: 42,
    paddingLeft: 14,
    paddingRight: 14,
    transition:
      'background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease',
    borderRadius: 4,
    ':hover': {
      margin: 0,
      border: '1 px solid #D9A8FF',
      boxShadow: `0 0 0 2px #E2D7FB`,
    },
    ':focus': {
      border: '1px solid rgba(97, 81, 215, 0.5)',
      boxShadow: `0 0 0 2px #E2D7FB`,
    },
    ':focus[type="text"], :focus[type="password"]': {
      borderWidth: 1,
      borderColor: '#C882FF',
      boxShadow: `0 0 0 2px #7039ED33`,
    },
    '::placeholder':{
      color: 'rgb(179, 179, 179)'
    }
  },

  rolebotStyles: {
    ':hover': {
        boxShadow: `0 0 0 2px #E2D7FB`,
        borderRadius: 5
      },
    ':focus-within': {
        border: '1 px solid #D9A8FF',
        boxShadow: `0 0 0 2px #E2D7FB`,
        borderRadius: 5
      }    
  },

  AddVariableButton: {
    '&:hover': {
      cursor: 'pointer',
    }
  }
}))

const LocationStepSchema = z.object({
  environment: z.string(),
  office_locations: z.any().array(),
  specific_location: z.boolean().nullable(),
  remote_locations: z.any().array().optional(),
  remote_buffer: z.any().optional(),
  office_buffer: z.any().optional(),
  offer_relocation: z.boolean().nullable(),
  visa_sponsorship: z.boolean().nullable(),
  target_salary: z.preprocess(TrimValue, z.string().max(255, 'Must be under 255 chars')),
});

const LocationStep = ({
  next,
  prev,
  save,
  isSubmitting,
  values,
  originalData,
  showFinishLater,
  sourcing_only,
  showInfo,
  isAdmin,
  oldLocation,
  onUnmount,
  handleCandidateLocationChanges
}: LocationStepProps) => {
  const [officeLocationPopoverIsOpened, setOfficeLocationPopoverIsOpened] = useState(false);
  const [officeEnvironmentPopoverIsOpened, setOfficeEnvironmentPopoverIsOpened] = useState(false);
  const [formValuesHaveChanged, setFormValuesHaveChanged] = useState(false);
  const { classes } = useStyles();
  //const { data: offices } = useGetCompanyLocationsQuery({ per_page: 200}, { refetchOnMountOrArgChange: true })
  const updatedValuesRef = useRef({ ...values });
  const [showOfficeLocationInput, setShowOfficeLocationInput] = useState(false);
  const [remoteLocationChanged, setRemoteLocationChanged] = useState<boolean>(false);
  const [officeLocationChanged, setOfficeLocationChanged] = useState<boolean>(false);

  const [officeSearch, setOfficeSearch] = useState('')
  const [searchdebounced] = useDebouncedValue(officeSearch, 500)
  const { data: offices, isLoading } = useGetSearchLocationsQuery({ search: searchdebounced})

  const {
    offer_relocation,
    visa_sponsorship,
    office_locations,
    environment,
    specific_location,
    remote_locations,
    remote_buffer,
    office_buffer,
    target_salary
  } = values;

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      offer_relocation,
      visa_sponsorship,
      office_locations,
      environment,
      specific_location,
      remote_locations,
      remote_buffer,
      office_buffer,
      target_salary
    },
    resolver: zodResolver(LocationStepSchema),
  });

  const [
    environmentWatcher,
    officeLocationsWatcher,
    specificLocationWatcher,
    remoteLocationsWatcher,
    remoteBufferWatcher,
    officeBufferWatcher,
    targetSalaryWatcher,
    offerRelocationWatcher,
    visaSponsorshipWatcher] = watch([
    'environment',
    'office_locations',
    'specific_location',
    'remote_locations',
    'remote_buffer',
    'office_buffer',
    'target_salary',
    'offer_relocation',
    'visa_sponsorship'
  ]);

  useEffect(() => {

    updatedValuesRef.current = {
      environment: environmentWatcher,
      office_locations: officeLocationsWatcher,
      specific_location: specificLocationWatcher,
      remote_locations: remoteLocationsWatcher,
      remote_buffer: remoteBufferWatcher,
      office_buffer: officeBufferWatcher,
      target_salary: targetSalaryWatcher,
      offer_relocation: offerRelocationWatcher,
      visa_sponsorship: visaSponsorshipWatcher,
    };
  });

  // useEffect hook to handle component unmount-like behavior
  useEffect(() => {
    if (!showFinishLater) {
      return () => {
        onUnmount(updatedValuesRef.current);
      };
    }
  }, []);

  const [specificLocation, setSpecificLocation] = useState(
    specificLocationWatcher ? '1' : '0'
  )

  useEffect(() => {
    setValue('specific_location', specificLocation === "1")
    trigger('specific_location');
  }, [specificLocation])

  interface ItemProps {
    id: number;
    location_name: string;
  }

  interface RemoteItemProps {
    place_id: string;
    description: string;
  }

  useEffect(() => {
    if (!showFinishLater || sourcing_only) {
      setFormValuesHaveChanged(
          ((originalData?.environment || '') !== environmentWatcher) ||
          (
              remoteLocationChanged ||
              officeLocationChanged ||
              ((originalData?.offer_relocation || false) !== (offerRelocationWatcher || false)) ||
              ((originalData?.visa_sponsorship || false) !== (visaSponsorshipWatcher || false))
          ) ||
          ((originalData?.target_salary || '') !== targetSalaryWatcher)
      );
    }
  }, [
    showFinishLater,
    originalData?.target_salary,
    targetSalaryWatcher,
    originalData?.environment,
    environmentWatcher,
    originalData?.offer_relocation,
    offerRelocationWatcher,
    originalData?.visa_sponsorship,
    visaSponsorshipWatcher,
    originalData?.equity_offered,
    originalData?.bonus_offered,
    originalData?.jd_link,
    originalData?.sell_opportunity,
    remoteLocationChanged,
    officeLocationChanged,
    sourcing_only
  ]);

  const locationItemComponent = forwardRef<HTMLDivElement, ItemProps>(
    ({ id, location_name, ...others}:ItemProps, ref) => (
      <div ref={ref} {...others} key={id}>
        <div style={{display: 'flex', alignItems:'center', height:25}}>
          <FontAwesomeIcon icon={faLocationDot} color={'#838485'} />
          <p style={{marginLeft:10, marginTop:'auto', marginBottom:'auto'}}>{location_name}</p>
        </div>
      </div>
    )
  )

  const remoteLocationItemComponent = forwardRef<HTMLDivElement, RemoteItemProps>(
    ({ place_id, description, ...others}:RemoteItemProps, ref) => (
      <div ref={ref} {...others} key={place_id}>
        <div style={{display: 'flex', alignItems:'center', height:25}}>
          <FontAwesomeIcon icon={faLocationDot} color={'#838485'} />
          <p style={{marginLeft:10, marginTop:'auto', marginBottom:'auto'}}>{description}</p>
        </div>
      </div>
    )
  )

  const companyPerksLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>For this role, does your company offer:</Text>
      <Text style={{ marginLeft: 'auto', color: '#B3B3B3' }} size="md">
        Optional
      </Text>
    </Group>
  );

  const officeEnvironmentLabelWithPopover = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>What is the type of work environment for this role?</Text>
      <Popover
        opened={officeEnvironmentPopoverIsOpened}
        onClose={() => setOfficeEnvironmentPopoverIsOpened(false)}
        position="top"
        placement="center"
        withArrow
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        width={515}
        styles={{ body: { pointerEvents: 'none' } }}
        target={
          <FontAwesomeIcon
            onMouseEnter={() => setOfficeEnvironmentPopoverIsOpened(true)}
            onMouseLeave={() => setOfficeEnvironmentPopoverIsOpened(false)}
            icon={faQuestionCircle}
            style={{
              cursor: 'pointer',
              marginRight: '4px',
              display: 'inlineBlock',
              color: 'cornflowerblue',
            }}
          />
        }
      >
        <div>
          <Text size="md" mb={15} color={'#242424'}>
            What type of work environment is required for this position?
          </Text>
          <Text size="md" mb={15} color={'#242424'}>
            <strong>Onsite</strong> jobs require that the candidate be in a specific location and work from an office.
          </Text>
          <Text size="md" mb={15} color={'#242424'}>
            <strong>Hybrid</strong> jobs require that the candidate be in a specific location, but give the employees flexibility to work from home part of the week.
          </Text>
          <Text size="md" mb={15} color={'#242424'}>
            <strong>Remote</strong> jobs do not require candidates to be in a specific location; employees work remotely full time.
          </Text>
          <Text size="md" color={'#242424'}>
            <strong>Same-city</strong> remote jobs require that the candidate be in a specific location, but give employees flexibility to work remotely full time.
          </Text>
        </div>
      </Popover>
    </Group>
  );

  const officeLocationLabelWithPopover = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>{environmentWatcher === 'full-time-remote' ? "What office location is this job tied to?" : "What is this job's office location?"}</Text>
      <Popover
        opened={officeLocationPopoverIsOpened}
        onClose={() => setOfficeLocationPopoverIsOpened(false)}
        position="top"
        placement="start"
        withArrow
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        width={360}
        styles={{ body: { pointerEvents: 'none' } }}
        target={
          <FontAwesomeIcon
            onMouseEnter={() => setOfficeLocationPopoverIsOpened(true)}
            onMouseLeave={() => setOfficeLocationPopoverIsOpened(false)}
            icon={faQuestionCircle}
            style={{
              cursor: 'pointer',
              marginRight: '4px',
              display: 'inlineBlock',
              color: 'cornflowerblue',
            }}
          />
        }
      >
        <div>
          <Text size="md" style={{ marginBottom: 0 }}>
            If your company has multiple locations, this will help your team identify and differentiate roles.
          </Text>
        </div>
      </Popover>
    </Group>
  );

  /**
   *  IMPORTANT!!
   *  Locations code below
   *  The way locations are handled requires the use of a main OfficeLocations array and an OfficeBuffer
   *  the former helps keep track of the list of locations above the main location autocomplete, and the OfficeBuffer
   *  keeps track of the location currently in the main location autocomplete. it was done this way in order to be
   *  able to combine the functionality of mantine's autocomplete and to comply with the proposed figma design
   */
  const [remoteBuffer, setRemoteBuffer] = useState<any>(remoteBufferWatcher ? remoteBufferWatcher : null)
  const [remoteValue, setRemoteValue] = useState<string>(remoteBufferWatcher ? remoteBufferWatcher.location_name ? remoteBufferWatcher.location_name : remoteBufferWatcher.formatted_address : '')
  const [officeBuffer, setOfficeBuffer] = useState<any>(officeBufferWatcher ? officeBufferWatcher : null)
  const [officeValue, setOfficeValue] = useState<string>(officeBufferWatcher ? officeBufferWatcher.location_name : '')

  const viewport = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleCandidateLocationChanges(remoteBuffer);
  }, [remoteBuffer])

  /**
   * When the user selects 'YES' in the specific location input, we use this function to scroll
   * down to the RemoteLocations input now visible
   * @returns void
   */
  const scrollToBottom = () =>
    viewport.current!.scrollTo({ top: viewport.current!.scrollHeight, behavior: 'smooth' });

  const handleAddOffice = (e:FormEvent) => {
    e.preventDefault()
    if(officeBuffer){
      let newofloc = officeLocationsWatcher
      newofloc.push(officeBuffer)

      setValue('office_locations', newofloc)
      trigger('office_locations')

      setOfficeBuffer(null)
      setValue('office_buffer', null)
      trigger('office_buffer')
      setOfficeValue('')
    } 
  }

  /**
   * When deleting an input derectly from the autocomplete input
   * we undo the modifications made to the office buffer and office locations array
   */
  const reverseAddOffice = () => {

    if(!officeBufferWatcher && officeValue) {
      setOfficeValue('')
      return
    }

    let locToRemove = officeBufferWatcher
    let newofloc = officeLocationsWatcher
    let aux = newofloc.pop() ?? null
    setOfficeBuffer(aux ?? null)
    setValue('office_buffer', aux ?? null)
    trigger('office_buffer')
    setOfficeValue(aux?.location_name ?? '')
    setValue('office_locations', newofloc)
    trigger('office_locations')

    //check if the location already exists in remote locations and delete it from there too
    if(remoteLocationsWatcher.findIndex((rloc:any) => rloc.location_name === locToRemove.location_name && 'type' in rloc) >= 0){
      handleRemoveRemote(locToRemove)
    }
    setOfficeLocationChanged(true)
  }

  const handleAddRemote = (e:FormEvent) => {
    e.preventDefault()
    if(remoteBuffer){
      let newreloc = remoteLocationsWatcher
      newreloc.push(remoteBuffer)

      setValue('remote_locations', newreloc)
      trigger('remote_locations')

      setRemoteBuffer(null)
      setValue('remote_buffer', null)
      trigger('remote_buffer')
      setRemoteValue('')
    } 
  }

  const reverseAddRemote = () => {

    if(!remoteBufferWatcher && remoteValue) {
      setRemoteValue('')
      getPlacePredictions({ input: '' });
      return
    }

    let newreloc = remoteLocationsWatcher
    let aux = newreloc.pop() ?? null
    console.log(aux)
    setRemoteBuffer(aux ?? null)
    setValue('remote_buffer', aux ?? null)
    trigger('remote_buffer')
    setRemoteValue(aux?.formatted_address ?? '')
    setValue('remote_locations', newreloc)
    trigger('remote_locations')
    setRemoteLocationChanged(true)
  }

  /**
   * Logic to perform after a user clicks an option from the offices dropdown
   * 
   * @param item 
   */
  const handleOfficeAutoCompleteSubmit = (item : any) => {

    if ( !ExistsInOfficeLocations(item) ) {// validates the location has not been already added

      setOfficeBuffer(item)
      setValue('office_buffer', item)
      trigger('office_buffer')
      
    } else { //if it exists, clean the autocomplete input text
      setOfficeValue('')
    }
    
  }

  const handleRemoveOffice = (loc : any) => {
    let locs = officeLocationsWatcher.filter((l : any) => {
      return l.id !== loc.id
    })

    setValue('office_locations', locs)
    trigger('office_locations')

    //check if the location already exists in remote locations and delete it from there too
    if(remoteLocationsWatcher.findIndex((rloc:any) => rloc.location_name === loc.location_name && 'type' in rloc) >= 0){
      handleRemoveRemote(loc)
    }

    setOfficeLocationChanged(true)
  }

  const handleRemoveRemote = (loc : any) => {
    let locs = remoteLocationsWatcher.filter((l : any) => {
      return (l.id ?? l.place_id) !== (loc.id ?? loc.place_id)
    })

    setValue('remote_locations', locs)
    trigger('remote_locations')

    setRemoteLocationChanged(true)
  }

  useEffect(() => {

    //when loading the form with previous data, we process the data so we can
    //show it properly on the remote and office locations autocompletes
    if(officeLocationsWatcher.length > 0 && !officeBufferWatcher) {
      let newarr = officeLocationsWatcher
      let buffer = newarr.pop()
      setOfficeBuffer(buffer)
      setValue('office_buffer', buffer)
      trigger('office_buffer')
      setOfficeValue(buffer.location_name)

      setValue('office_locations', newarr)
      trigger('office_locations')
    }

    if(remoteLocationsWatcher.length > 0 && !remoteBufferWatcher) {
      let newarr = remoteLocationsWatcher
      let buffer = newarr.pop()
      setRemoteBuffer(buffer)
      setValue('remote_buffer', buffer)
      trigger('remote_buffer')
      setRemoteValue(buffer.location_name)

      setValue('remote_locations', newarr)
      trigger('remote_locations')
    }
  }, [])

  //when clicking outside an input, refill it with the current office buffer location name
  const refillOfficeValue = () => {
    if(officeBuffer){
      setOfficeValue(officeBuffer.location_name)
    } else {
      setOfficeValue("")
    }
  }

  const refillRemoteValue = () => {
    if(remoteBuffer){
      setRemoteValue(remoteBuffer.formatted_address)
    } else {
      setRemoteValue('')
      getPlacePredictions({ input: '' });
    }
  }

  //Google's geocode hook
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    debounce: 1000,
    options: {
      fields: ["formatted_address", "address_components", "place_id"],//only ask for these fields in the api response to reduce consumption
      strictBounds: false,
      types: ["locality", "administrative_area_level_1", "country", "continent"],//only show results of these types
    }
  });

  /**
   * when user clicks on add office from the role info form
   * we should save their current progress and the redirect
   * them to the offices page
   */
  const navigate = useNavigate();
  const modals = useModals();
  const handleSaveAndRedirect = () => {
    handleSubmit(save)().then(() => {
      modals.closeAll()
      navigate('/settings/customizations')
    })
  }

  const NothingFoundComponent = () => {
    return (
      <>
        {isLoading ? (<div>
          <span>Searching...</span>
        </div>) : (
          <>
          {searchdebounced ? (
            <div>
            <span>
              No office locations found.&nbsp;
              <MediaQuery smallerThan={'md'} styles={{display:'none'}}>
                <span onClick={handleSaveAndRedirect} style={{textDecoration: 'none', color: '#1075BD', cursor: 'pointer'}} >Add an office</span>
              </MediaQuery>
            </span>
          </div>
          ) : (
            <div>
              <span>Type to search</span>
            </div>
          )}
        </>
        )}
      </>
    )
  }

  const AutocompleteXButton = ({type} : {type:'office' | 'remote'}) => {
    return (
      <div style={{paddingRight: '14px'}}>
        <ActionIcon variant={'transparent'} size={20} onClick={type === 'office' ? reverseAddOffice : reverseAddRemote}>
          <FontAwesomeIcon icon={faXmark} color={'#838485'} />
        </ActionIcon>
      </div>
)
  }

  /**
   * Checks if a certain office location exists in the remote locations
   * 
   * @param location 
   * @returns boolean
   */
  const ExistsInRemoteLocations = (location : any) => {
    return remoteLocationsWatcher.findIndex((rloc:any) => rloc.location_name === location.location_name) >= 0
  }

  /**
   * Checks if a location already has been added to the roles locations
   * 
   * @param location 
   * @returns boolean
   */
  const ExistsInOfficeLocations = (location : any) => {
    return officeLocationsWatcher.findIndex((oloc:any) => oloc.location_name === location.location_name) >= 0
  }

  const targetSalaryLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>What is the target salary for this role?</Text>
    </Group>
  );

  const remoteNotFoundMessage = isPlacePredictionsLoading ? 'Searching locations...' : (placePredictions.length === 0 && remoteValue !== '') ? `Type to search for a location` : 'Type to search for a location'

  return (
    <div style={{paddingLeft: '2px'}}>
      <>
        <Text weight={500} color="#242424" style={{ fontFamily: 'Roboto' }} mb={20}>
          Location
        </Text>
        <form>
          <Stack mb={40}>
            { sourcing_only && <RolebotTextInput
              {...register('target_salary')}
              label={targetSalaryLabel}
              placeholder={'$100K USD'}
              error={errors?.target_salary?.message}
            />}
            <Controller
              name={'environment'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <RolebotSelect
                  onChange={onChange}
                  value={value}
                  data={[
                    { value: 'onsite', label: 'On-site' },
                    { value: 'blended', label: 'Hybrid' },
                    { value: 'full-time-remote', label: 'Full-time Remote' },
                    { value: 'same-city-remote', label: 'Same City Remote' },
                  ]}
                  label={officeEnvironmentLabelWithPopover}
                  placeholder={'Select...'}
                  size={'md'}
                  error={errors?.environment?.message}
                  rightSection={<ChevronDown color={'#B3B3B3'} />}
                />
              )}
            />
            <InputWrapper label='Where should candidates be located?' size={'md'}>
              {remoteLocationsWatcher && remoteLocationsWatcher.map((loc:any) => (
                <div key={`${nanoid(6)}-remote`} style={{border: "1px solid #CED4DA", height: 42, width:'100%', paddingLeft: 14, paddingRight:14, marginBottom:5, borderRadius: 4, maxWidth:670}}>
                  <div style={{display: 'flex', alignItems: 'center', height:'100%'}}>
                    <div>{loc.location_name ?? loc.formatted_address}</div>
                    <ActionIcon variant={'transparent'} ml={'auto'} size={20} onClick={() => handleRemoveRemote(loc)}>
                      <FontAwesomeIcon icon={faXmark} color={'#838485'} />
                    </ActionIcon>
                  </div>
                </div>
              ))}
              <div style={{display:'flex', width:'100%', alignItems:'end'}}>
                
                <Autocomplete
                  className={classes.rolebotStyles}
                  value={remoteValue}
                  onChange={(value) => {
                    setRemoteValue(value)
                    getPlacePredictions({ input: value });
                    setRemoteLocationChanged(true);
                  }}
                  style={{flexGrow:1, marginRight:5}}
                  onItemSubmit={(item) => {
                    placesService.getDetails({
                      placeId: item.place_id,
                      fields: ["formatted_address", "address_components", "place_id"]
                    },
                    (placeDetails : any) => {
                      setRemoteBuffer(placeDetails)
                      setValue('remote_buffer', placeDetails)
                      trigger('remote_buffer')
                      setRemoteValue(placeDetails.formatted_address)
                    })
                  }}
                  placeholder={'Please specify a location'}
                  size={'md'}
                  data={placePredictions ?? []}
                  filter={(value, item) => item.description.toLowerCase().includes(value.toLowerCase().trim())}
                  itemComponent={remoteLocationItemComponent}
                  onBlur={() => refillRemoteValue()}
                  nothingFound={remoteNotFoundMessage}
                  rightSection={(remoteLocationsWatcher.length !== 0) && (<AutocompleteXButton type='remote' />)}
                  onKeyDown={(e) => {
                    if(e.key === 'Enter'){
                      e.preventDefault()
                      e.stopPropagation()
                    }
                  }}
                  >
                </Autocomplete>

                <RolebotButton disabled={!!!remoteBufferWatcher} style={{height: '42px', width:'60px', border:'1px solid'}} type={'secondary'} onClick={handleAddRemote}>
                  <FontAwesomeIcon icon={faPlus}/>
                </RolebotButton>
              </div>
            </InputWrapper>
            {(environmentWatcher !== '') && (!showOfficeLocationInput && !officeBufferWatcher && officeLocationsWatcher.length === 0 && oldLocation === '' && environmentWatcher !== 'onsite' && environmentWatcher !== 'blended') && (
              <Text
                  className={classes.AddVariableButton}
                  color={'#40A5D0'}
                  onClick={() => setShowOfficeLocationInput(!showOfficeLocationInput)}
              >
                + Tie role to an office location
              </Text>
            )}
            {(environmentWatcher !== '') && (showOfficeLocationInput || officeBufferWatcher || officeLocationsWatcher.length !== 0 || oldLocation !== '' || environmentWatcher === 'onsite' || environmentWatcher === 'blended') && (
              <InputWrapper label={officeLocationLabelWithPopover} size={'md'}>
                {officeLocationsWatcher && officeLocationsWatcher.map((loc:any) => (
                  <div key={loc.id} style={{border: "1px solid #CED4DA", height: '42px', width: '100%', paddingLeft: '14px', paddingRight: '14px', marginBottom: '5px', borderRadius: '4px', maxWidth:'670px'}}>
                    <div style={{display: 'flex', alignItems: 'center', height:'100%'}}>
                      <div>{loc.location_name}</div>
                      <ActionIcon variant={'transparent'} ml={'auto'} size={20} onClick={() => handleRemoveOffice(loc)}>
                        <FontAwesomeIcon icon={faXmark} color={'#838485'} />
                      </ActionIcon>
                    </div>
                  </div>
                ))}
                <div style={{display:'flex', width:'100%', alignItems:'end'}}>
                  <Autocomplete
                    className={classes.rolebotStyles}
                    value={officeValue}
                    onChange={(value) => {
                      setOfficeSearch(value)
                      setOfficeValue(value)
                      setOfficeLocationChanged(true);
                    }}
                    style={{flexGrow:1, marginRight:5}}
                    onItemSubmit={(item) => handleOfficeAutoCompleteSubmit(item)}
                    placeholder={oldLocation ? oldLocation : environmentWatcher === 'full-time-remote' ? 'What office location is this job tied to?' : "What is this job's office location?"}
                    size={'md'}
                    nothingFound={(<NothingFoundComponent/>)}
                    data={offices ? offices.data : []}
                    itemComponent={locationItemComponent}
                    onBlur={() => refillOfficeValue()}
                    rightSection={(officeLocationsWatcher.length !== 0) && (<AutocompleteXButton type='office' />)}
                    >
                  </Autocomplete>
                  <RolebotButton disabled={!!!officeBufferWatcher} style={{height: '42px', width: '60px', border:'1px solid'}} type={'secondary'} onClick={handleAddOffice}>
                    <FontAwesomeIcon icon={faPlus}/>
                  </RolebotButton>
                </div>
                { (oldLocation !== '' && !officeBufferWatcher && !officeLocationsWatcher.length) && (
                  <div style={{color:"#F96F0B"}}>
                  This job is tied to an old location. Please update the Office Location
                  <span style={{marginLeft: 4}}><FontAwesomeIcon icon={faTriangleExclamation}/></span>
                  </div>
                )}
              </InputWrapper>
            )}
            { (environmentWatcher !== '' && environmentWatcher !== 'full-time-remote') && (
              <InputWrapper label={companyPerksLabel} size={'md'}>
                <Stack spacing={'xs'}>
                    <>
                      <Checkbox size={'md'} label="Relocation" color="grape" {...register('offer_relocation')} />
                      <Checkbox size={'md'} label="Visa Sponsorship" color="grape" {...register('visa_sponsorship')} />
                    </>
                </Stack>
              </InputWrapper>
            )}
          </Stack>
        </form>
      </>
      <div style={{marginBottom: '2px'}}>
      <FormNavigation
        step={sourcing_only ? 2 : 3}
        next={handleSubmit(next)}
        prev={handleSubmit(prev)}
        save={handleSubmit(save)}
        isSubmitting={isSubmitting}
        isSaveEnabled={!isValid}
        showFinishLater={showFinishLater}
        isNextDisabled={
          !isValid ||
          environmentWatcher === '' ||
          remoteBufferWatcher === null ||
          ((environmentWatcher === 'onsite' || environmentWatcher === 'blended') && officeBufferWatcher === null) ||
          (sourcing_only && !targetSalaryWatcher)
        }
        isSaveChangesDisabled={!formValuesHaveChanged}
      />
      </div>
    </div>
  );
};

export default LocationStep;
