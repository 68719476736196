import React from 'react';
import { Avatar, AvatarsGroup, createStyles, Group, Stack, Tooltip } from '@mantine/core';
import { getNameInitials } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
interface styleProps {
  id: number;
}

const useStyles = createStyles((theme, { id }: styleProps) => ({
  avatarRoot: {
    boxShadow:  '0px 1px 5px rgba(0, 0, 0, 0.45)',
  },
  avatarPlaceholder: {
    border: '2px solid transparent',
    color: theme.colors.avatars[id % theme.colors.avatars.length],
    svg: {
      fill: theme.colors.avatars[id % theme.colors.avatars.length],
    },
  },
  avatarPlaceHolderIcon: {
    color: theme.colors.avatars[id % theme.colors.avatars.length],
  },
  numberAvatarRoot: {
    width: '40',
    height: '40px',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.45)',
  },
  numberAvatarPlaceholder: {
    border: '2px solid transparent',
    backgroundColor: '#4F4F4F',
    color: '#fff',
  },
}));


const LastReviewedDate = ({ users, id,reviewed_by,reviewed_at}: { users: { name: string; id: number; email: string; deleted_at: string }[]; id: number ,reviewed_by :number,reviewed_at:string}) => {
  const { classes } = useStyles({ id: users[0]?.id });

  const usersList = (
    <Stack spacing={4}>
      {users.filter(user => user.id === reviewed_by)[0]?.email ? users.filter(user => user.id === reviewed_by)[0]?.email : null} {users.filter(user => user.id === reviewed_by)[0]?.deleted_at ? '(inactive)' : null}
    </Stack>
  );

  const avatarStyles = {
    root: classes.avatarRoot,
    placeholder: classes.avatarPlaceholder,
    placeholderIcon: classes.avatarPlaceHolderIcon,
  };


  const buildFirstCircleContent = () => {
    return users.filter(user => user.id === reviewed_by)[0]?.name ? (
      <Avatar classNames={avatarStyles}>  
        {users.filter(user => user.id === reviewed_by)[0]?.name ? getNameInitials(users.filter(user => user.id === reviewed_by)[0]?.name) : <FontAwesomeIcon icon={faUser} width={16} height={16} style={{ paddingBottom: '2px' }}/>}
      </Avatar>
    ) : null;
  };


    return (
        <Group noWrap position={'center'}>
            {
                buildFirstCircleContent() ? (
                    <Group noWrap position={'center'}>
                        <Tooltip label={usersList} key={'users-list-tooltip' + id}>
                            <AvatarsGroup limit={1} styles={{ child: { border: 'none' } }}>
                                {buildFirstCircleContent()}
                            </AvatarsGroup>
                        </Tooltip>
                        {reviewed_at ? (
                            moment.utc(reviewed_at).local().format('ll')
                        ) : null
                        }
                    </Group>
                ) : null
            }
        </Group>
);
};

export default LastReviewedDate;
