import { Button, Group, Text } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';
import TitleWithClose from 'components/Modals/components/TitleWithClose';

const GracePeriodExpired = ({ id }: ContextModalProps) => {
  const modals = useModals();

  const handleClose = () => {
    modals.closeModal(id);
  };

  return (
    <>
      <TitleWithClose id={id} title={'We are in progress with this candidate!'} mb={15} />
      <>
        <Group align={'center'}>
          <Text color={'#242424'}>
            We allow a 24hr grace period to change candidates from ‘accepted’ to ‘declined’. Please contact
            hello@rolebot.io for help.
          </Text>
        </Group>
        <Group position={'right'} mt={30}>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: 'transparent',
              color: '#838485',
              border: '1px solid #838485',
              paddingInline: 40,
            }}
            variant={'subtle'}
          >
            Okay
          </Button>
        </Group>
      </>
    </>
  );
};
export default GracePeriodExpired;
