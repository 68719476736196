import React from 'react';
import { Outlet } from 'react-router-dom';
import RolebotFooter from "../RolebotFooter/RolebotFooter";

const Wrapper = ({children}: any) => {
    return (
        <div style={{height: '100dvh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{width: '100%'}}>
                {children}
            </div>
            <RolebotFooter />
        </div>
    )
}

const GuestLayout = () => {

    return (
        <Wrapper>
            <Outlet />
        </Wrapper>
    )
};

export default GuestLayout;
