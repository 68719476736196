import React, { useEffect, useState } from 'react';
import {
    Container,
    LoadingOverlay
} from '@mantine/core';
import Plans from './components/Plans';
import CreateAccount from './components/CreateAccount';
import { useGetPricingPlansQuery } from 'app/services/rolebot';    
import ContactSales from './components/ContactSales';
import { useNavigate, useLocation } from 'react-router-dom';

const Pricing = () => {
    const [isFetching, setIsFetching] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState<any>(() => {
        const savedPlan = sessionStorage.getItem('selectedPlan');
        return savedPlan ? JSON.parse(savedPlan) : null;
    });
    const { data } = useGetPricingPlansQuery();
    const navigate = useNavigate();
    const location = useLocation();

    // Function to parse the step from URL query parameters
    const getStepFromLocation = () => {
        const queryParams = new URLSearchParams(location.search);
        const step = queryParams.get('step');
        switch(step) {
            case 'choose_plan':
                return 'choose_plan';
            case 'create_account':
                return 'create_account';
            case 'contact_support':
                return 'contact_support';
            default:
                return 'choose_plan'; // Default to 'choose_plan' if not specified
        }
    };

    const [currentStep, setCurrentStep] = useState(getStepFromLocation());

    useEffect(() => {
        if (data) {
            setIsFetching(false);
        }
    }, [data]);

    useEffect(() => {
        // Update the URL without navigating
        navigate(`/pricing?step=${currentStep}`, {replace: true});
    }, [currentStep, navigate]);

    useEffect(() => {
        // React to changes in location.search to update the current step
        setCurrentStep(getStepFromLocation());
    }, [location.search]);

    const handlePlanSelected = (payload: any, shouldNavigate: boolean = true) => {
        setSelectedPlan(payload);
        sessionStorage.setItem('selectedPlan', JSON.stringify(payload));

        if (shouldNavigate) {
            navigate(`/pricing?step=create_account`);
        }
    };

    const handleContactSalesSelected = () => {
        navigate(`/pricing?step=contact_support`);
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Container
            size={850}
            styles={{
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '40px 18px',
                },
            }}
        >
            <LoadingOverlay visible={isFetching} />
            <div>
                {!isFetching && currentStep === 'choose_plan' && 
                    <Plans
                        pricingPlans={data}
                        handlePlanSelected={handlePlanSelected}
                        handleContactSalesSelected={handleContactSalesSelected}
                    />}
                {!isFetching && currentStep === 'create_account' && 
                    <CreateAccount
                        handleBack={handleBack}
                        selectedPlan={selectedPlan}
                    />
                }
                {!isFetching && currentStep === 'contact_support' && 
                    <ContactSales
                        handleBack={handleBack}
                    />
                }
            </div>
        </Container>
    );
};

export default Pricing;