import React, { useEffect, useState } from 'react';
import { Button, Collapse, createStyles, Group, Text, Badge } from '@mantine/core';
// import { Icon as TablerIcon } from 'tabler-icons-react';
import { Link, NavLink, matchPath, useLocation, useMatch, useResolvedPath, useSearchParams } from 'react-router-dom';
import { useGetCompanyQuery } from 'app/services/rolebot';
import useAppSelector from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { toggleNavigation } from 'features/app/appSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = createStyles((theme) => ({
  control: {
    marginBottom: 10,
    display: 'flex',
    color: '#838485',
    alignItems: 'center',
    width: '100%',
    height: 60,
    '&:hover': {
      backgroundColor: '#DFE1E1',
    },
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
  },

  active: {
    backgroundColor: '#EEEEEE',
    '&:before': {
      content: `""`,
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      bottom: 0,
      width: 8,
      backgroundColor: '#7039ED',
      borderBottomLeftRadius: 8,
      borderTopLeftRadius: 8,
    },
  },

  activeText: {
    color: '#24154f',
  },

  link: {
    fontWeight: 400,
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 30,
    marginLeft: 30,
    color: '#B3B3B3',
  },
}));

interface LinksGroupProps {
  icon: any;
  label: string;
  initiallyOpened?: boolean;
  to: string;
  links?: { label: string; link: string }[];
}

export function LinksGroup({ icon, label, initiallyOpened, links, to }: LinksGroupProps) {
  const { classes, cx } = useStyles();
  const search = useAppSelector((state) => state.router.location?.search);
  const waitingForSetupRolesCount = useAppSelector((state) => state.app.waitingForSetupRolesCount);
  const dispatch = useAppDispatch();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: company } = useGetCompanyQuery();
  
  const handleButtonClick = () => {
    setOpened((o) => !o);
    !hasLinks && closeNavigation();
  };

 
  const closeNavigation = () => dispatch(toggleNavigation());

  const rolesClass = (link:any) => {

    if (link.label === 'Active Roles' && (searchParams.get('status') === 'active' || searchParams.get('status') === null)) {
      return true
    } else if (link.label === 'Completed Roles' && searchParams.get('status') === 'completed') {
      return true
    } else {
      return false
    }
  }

  const items = (hasLinks ? links : []).map((link) => (
    <>
      <Text
        component={NavLink}
        className={cx(classes.link, { [classes.activeText]: rolesClass(link)  })}
        key={link.label}
        to={link.link}
        onClick={closeNavigation}
        size={'lg'}
      >
        {link.label}
        {link.label === 'Active Roles' && company?.active_role_count && (
          <Badge color="pink" variant="filled" ml={10} px={10}>
            {company?.active_role_count}
          </Badge>
        )}
      </Text>
    </>
  ));

  return (
    <>
    {!hasLinks ? (
      <>
      <Button
        onClick={handleButtonClick}
        variant="white"
        className={cx(classes.control, { [classes.active]: match })}
        component={Link}
        to={to}
        sx={{ fontWeight: 500 }}
      >
        <Group spacing={15}>
          <Group>
            <FontAwesomeIcon icon={icon} color={match ? '#24154f' : '#838485'} width={35} height={35} />
          </Group>
          <Text color={match ? '#24154f' : '#838485'} sx={{ fontFamily: 'Roboto', fontSize: 19 }}>
            {label}
          </Text>
        </Group>
      </Button>
    </>
    ) : (
      <>
      <Button
        onClick={handleButtonClick}
        variant="white"
        className={cx(classes.control, { [classes.active]: match })}
        component={'div'}
        sx={{ fontWeight: 500 }}
      >
        <Group spacing={15}>
          <Group>
            <FontAwesomeIcon icon={icon} color={match ? '#24154f' : '#838485'} width={35} height={35} />
          </Group>
          <Text color={match ? '#24154f' : '#838485'} sx={{ fontFamily: 'Roboto', fontSize: 19 }}>
            {label}
          </Text>
        </Group>
      </Button>
      <Collapse in={opened}>{items}</Collapse>
    </>
    )}
    </>
  );
}
