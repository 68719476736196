import React, { useEffect } from 'react';
import { ContextModalProps } from '@mantine/modals';
import { Button, Group, List, Text } from '@mantine/core';
import { useDeactivateUserMutation } from 'app/services/rolebot';
import { useNotifications } from '@mantine/notifications';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { DeactivateUserProps } from 'types/modals';

const DeactivateUser = ({ id, context, innerProps }: ContextModalProps<DeactivateUserProps>) => {
  const notifications = useNotifications();
  const [deactivateUser, { isLoading, isSuccess, isError, reset }] = useDeactivateUserMutation();

  const handleDeactivateUser = async () => {
    reset();
    await deactivateUser(innerProps.userId);
  };

  useEffect(() => {
    if (!isSuccess) return;
    notifications.showNotification({
      title: 'Success',
      message: 'User deactivated successfully',
      color: 'green',
    });
    context.closeModal(id);
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    notifications.showNotification({
      title: 'Error',
      message: 'Oops, something went wrong. Please try again.',
      color: 'red',
    });
    // eslint-disable-next-line
  }, [isError]);

  let name = () => {
    if (innerProps.userName.length > 0) {
      return innerProps.userName;
    }
    return innerProps.userEmail;
  };

  return (
    <>
      <TitleWithClose id={id} title={'Deactivate User'} />
      <Text mb={20} color={'#242424'}>
        Do you want to deactivate <span style={{ fontFamily: 'Roboto' }}>{name()}</span>?
      </Text>
      {innerProps.is_interviewer && (
        <>
          <Text mb={20} color={'#242424'} style={{ fontWeight: 'bold' }}>
            This user is assigned as an interviewer for active roles in your company. Removing this user may impact ongoing or scheduled interviews.
          </Text>
        </>
      )}
      <Text mb={20} color={'#242424'}>
          Deactivating them will remove the user's access to Rolebot.
        </Text>
      <Text mb={20} color={'#242424'}>
        Are you sure you want to proceed?
      </Text>
      <Group position={'right'} mt={40}>
        <Button variant={'default'} onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button onClick={handleDeactivateUser} loading={isLoading} color={'red'}>
          Yes, deactivate user
        </Button>
      </Group>
    </>
  );
};

export default DeactivateUser;
