
const OktaCallback = () => {
    const baseURL = process.env.REACT_APP_API_URL;

    const queryString = window.location.search;
    window.location.href = baseURL + '/auth/okta/callback' + queryString + '&temp_oauth=true';

    return null
};
export default OktaCallback;
