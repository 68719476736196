import { createStyles, Tooltip } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    toggleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '50px',
    },

    toggleSlider: {
        position: 'absolute',
        width: '50%',
        height: '100%',
        backgroundColor: '#ae00ff',
        borderRadius: '25px',
        transition: 'transform 0.3s ease',
    },

    toggle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        width: '490px',
        maxWidth: '100%',
        margin: '0 auto',
        border: 'solid 2px #fff',
        borderRadius: '25px',
        overflow: 'hidden',
        fontFamily: 'Helvetica, sans-serif',
        fontSize: '19px',
        fontWeight: 400,
        '& input[type="radio"]': {
            display: 'none',
        },
        '& label': {
            flex: 1,
            textAlign: 'center',
            padding: '10px 5px',
            cursor: 'pointer',
            borderRadius: '25px',
            transition: 'background-color 0.3s ease, color 0.3s ease',
            border: 'none',
            fontSize: '19px',
            fontFamily: 'Helvetica, sans-serif',
            fontWeight: 400,
            color: '#fff',
            position: 'relative',
            zIndex: 2,
        },
        '& label:hover:not(:has(input:checked))': {
            backgroundColor: '#351b77',
            color: 'white',
        },
        '& input[type="radio"]:checked + label': {
            color: '#fff',
            backgroundColor: 'transparent',
        },
        '& input[type="radio"]:disabled + label': {
            pointerEvents: 'none',
            opacity: 0.5,
        },
        '& input[type="radio"]#monthly:checked ~ .toggleSlider': {
            transform: 'translateX(0)',
        },
        '& input[type="radio"]#yearly:checked ~ .toggleSlider': {
            transform: 'translateX(50%)',
        },
        '& > div': {
            flex: 1,
            display: 'flex',
        },
    },

    tooltipTarget: {
        flex: 1,
        display: 'flex',
    }
}));

interface BillingCycleToggleProps {
    billingCycle: string;
    handleBillingChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    subscriptionType: string;
}

const BillingCycleToggle = ({ billingCycle, handleBillingChange, subscriptionType }: BillingCycleToggleProps) => {
    const { classes } = useStyles();

    return (
        <div className={classes.toggleContainer}>
            <div className={classes.toggle}>
                {subscriptionType === 'Annual' ?
                    <Tooltip
                        label="You are currently subscribed to an annual plan, which doesn't allow switching to a month-to-month option. Contact our support team for assistance."
                        wrapLines
                        withArrow
                        width={300}
                        position="bottom"
                    >
                        <div className={classes.tooltipTarget}>
                            <input
                                type="radio"
                                id="monthly"
                                name="billing"
                                value="monthly"
                                checked={billingCycle === 'monthly'}
                                disabled={subscriptionType === 'Annual'}
                                onChange={handleBillingChange}
                        />
                        <label htmlFor="monthly">Pay monthly</label>
                    </div>
                </Tooltip>
                :
                <>
                    <input
                        type="radio"
                        id="monthly"
                        name="billing"
                        value="monthly"
                        checked={billingCycle === 'monthly'}
                        disabled={subscriptionType === 'Annual'}
                        onChange={handleBillingChange}
                    />
                    <label htmlFor="monthly">Pay monthly</label>
                </>
                }
                <input
                    type="radio"
                    id="yearly"
                    name="billing"
                    value="yearly"
                    checked={billingCycle === 'yearly'}
                    onChange={handleBillingChange}
                />
                <label htmlFor="yearly">Pay yearly (SAVE 10%)</label>
                <div
                    className={classes.toggleSlider}
                    style={{ transform: billingCycle === 'yearly' ? 'translateX(100%)' : 'translateX(0)' }}
                ></div>
            </div>
        </div>
    );
};

export default BillingCycleToggle;
