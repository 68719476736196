import React from 'react';

import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  buttonsWrapper: {
    flex: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    gap: 12,
    button: {
      ':first-of-type': {
        order: 2,
      },
      ':last-of-type': {
        order: 1,
      },
    },
    [theme.fn.largerThan('md')]: {

      flexGrow: 0,
      flexDirection: 'row',
      button: {
        ':first-of-type': {
          order: 1,
        },
        ':last-of-type': {
          order: 2,
        },
      },
    },
  },
}));

const ButtonsWrapper = ({ children }: { children: React.ReactNode }) => {
  const { classes } = useStyles();
  return <div className={classes.buttonsWrapper}>{children}</div>;
};

export default ButtonsWrapper;
