import React from 'react';
import { ITalent, IRole } from 'types';
import { createStyles, Group, Menu, UnstyledButton } from '@mantine/core';
import { Dots } from 'tabler-icons-react';
import useAppSelector from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { toggleTalentOptions } from 'features/app/appSlice';
import { useClickOutside } from '@mantine/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-light-svg-icons';
import {toastError, toastSuccess, toastWarning} from 'utils/toastify-messages';
import { RolebotClient } from 'api/client';

const useStyles = createStyles((theme) => ({
    body: {
      width: '95vw',
      [theme.fn.largerThan('sm')]: {
        width: 220,
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },
    itemBody: {
      justifyContent: 'center',
      [theme.fn.largerThan('sm')]: {
        justifyContent: 'left',
      },
    },
  
    hidden: {
      display: 'none',
      [theme.fn.largerThan('sm')]: {
        display: 'flex',
      },
      height: '16px',
      width: '16px',
      paddingBottom: '2px'
    }
}));

const TalentMenu = ({
    role,
    talent,
    talentId,
    integration
}: {
    role: IRole;
    talent: ITalent;
    talentId: string;
    integration: any;
}) => {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const talentOptionsOpen = useAppSelector((state) => state.app.talentOptionsOpen);
    const elem = document.getElementById('sidebar-right-content');
    const ref = useClickOutside(() => dispatch(toggleTalentOptions(false)), [], [elem!]);

    const getRequestUrl = () => {
        let endpoint = "";
        switch (integration) {
            case 'Greenhouse':
                endpoint = `/greenhouse/export-talent`;
                break;
            case 'Lever':
                endpoint = `/lever/export-talent`;
                break;
            case 'SAP':
                endpoint = `sap/export-talent`;
                break;
            case 'ICIMS':
                endpoint = `/icims/export-talent`;
        }
        return endpoint;
    }

    const getAtsJobId = () => {
        switch (integration) {
            case 'Greenhouse':
                return role?.greenhouse_job.job_id;
            case 'Lever':
                return role?.lever_job.job_id;
            case 'SAP':
                return role?.sap_job.job_id;
            case 'ICIMS':
                return role?.icims_job.job_id;
        }
    }

    const handleExportTalent = async () => {
        const requestUrl = getRequestUrl();
        RolebotClient.post(requestUrl, {
            talentId: talentId,
            roleId: role?.id,
            atsJobId: getAtsJobId(),
        })
        .then((res) => {
            console.log(res);
            toastSuccess(
                <div className="toastContainer">
                    <div>Talent exported</div>
                    <div>Successfully exported {talent?.name} to {integration}</div>
                </div>
            );
        })
        .catch((e) => {
            console.log(e);
            let error = e.response?.data
            let success = error?.success ?? false;
            let message = error?.message ?? 'Something went wrong.';
            if (success) {
                toastWarning(
                    <div className="toastContainer">
                        <div>Candidate already exported</div>
                        <div>{message}</div>
                    </div>    
                );
            } else {
                toastError(
                    <div className="toastContainer">
                        <div>Oops!</div>
                        <div>{message}</div>
                    </div>
                );
            }
        });
    };

    return (
        <Menu
            ref={ref}
            opened={talentOptionsOpen}
            onOpen={() => dispatch(toggleTalentOptions(true))}
            onClose={() => dispatch(toggleTalentOptions(false))}
            withinPortal={false}
            control={
                <UnstyledButton
                    style={{
                        borderRadius: 40,
                        padding: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Dots color={talentOptionsOpen ? '#351B77' : '#B3B3B3'} size={20} />
                </UnstyledButton>
            }
            classNames={{
                body: classes.body,
                itemBody: classes.itemBody,
            }}
            position={'bottom'}
            placement={'end'}
            withArrow
            sx={{
              zIndex: 1000,
              paddingLeft: '40px'
            }}
        >
            <Menu.Item onClick={handleExportTalent} style={{ alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                <Group spacing={12}>
                <FontAwesomeIcon icon={faArrowDownToLine} className={classes.hidden} />
                    Export to {integration}
                </Group>
            </Menu.Item>
        </Menu>
    );

};

export default TalentMenu;