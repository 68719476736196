import React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({

    AddUsersToRoleItem: {
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'unset',
        marginBottom: '8px',
        color: '#242424',
        padding: '2px 10px',
        '&:hover': {
            backgroundColor: '#EEEEEE',
            borderRadius: '5px'
        },
        '&:last-child': {
            marginBottom: '0'
        }
    },

    AddUserEmail: {
        color: '#b1afaf',
        marginLeft: '5px'
    }
}));

const AddUsersToRoleItem = ({ userSelected, user }) => {
    const { classes } = useStyles();

    return (
        <div className={classes.AddUsersToRoleItem} onClick={() => userSelected(user)}>{user.name || user.email}  <span className={classes.AddUserEmail}>{user.email}</span></div>
    )
};

export default AddUsersToRoleItem;

AddUsersToRoleItem.propTypes = {
    user: PropTypes.object.isRequired,
    userSelected: PropTypes.func.isRequired
};
