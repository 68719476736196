import { Alert, Button, createStyles, Group, Stack, Text, TextInput } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';

import { FormEvent, useEffect, useState } from 'react';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { useNotifications } from '@mantine/notifications';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useGetCompanyLocationsQuery, usePostCompanyLocationMutation } from 'app/services/rolebot';

const EnableMFA = ({ id }: ContextModalProps) => {
  const modals = useModals();
  
  const handleCancelButtonClicked = () => {
    modals.closeAll()
  }

  return (
    <>
      <TitleWithClose id={id} title={'Two-factor authentication is now active'} mb={20} />
      <Stack>
        <Text color='#242424'>
          Starting now, users will need to enter a one-time code to verify their identity when signing in from a new
          device or if it's been 24 hours since their last sign-in.
        </Text>
        <Text color='#242424'>This token will be sent to the user's business email.</Text>
        <Group position={'right'} mt={24}>
          <RolebotButton type={'neutral'} onClick={handleCancelButtonClicked}>
            Got it!
          </RolebotButton>
        </Group>
      </Stack>
    </>
  );
};

export default EnableMFA;
