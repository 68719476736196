import React from 'react';
import { Card, createStyles, Image, Stack, Text, Title, UnstyledButton } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useHover } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  card: {
    width: 280,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #DFE1E1',
    borderRadius: 10,
    padding: '25px 20px',
    ':hover': {
      boxShadow: '0px 0px 10px rgba(79, 79, 79, 0.35)',
      transition: 'box-shadow 0.2s linear',
    },
  },
  cardImage: {
    width: 170,
    height: 80,
    marginInline: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  base: {
    color: '#B3B3B3',
  },
  activeIntegration: {
    color: '#7A3DFD',
  },
  requiresOauthIntegration: {
    color: '#EB444A'
  },

  underline: {
    textDecoration: 'underline',
  },
}));

type IntegrationCardProps = {
  asset: string;
  title: string;
  description: string;
  active: boolean;
  requiresOauth?: boolean;
  to: string;
};

const IntegrationCard = ({ asset, title, description, to, active, requiresOauth }: IntegrationCardProps) => {
  const { classes, cx } = useStyles();
  const { ref, hovered } = useHover<HTMLAnchorElement>();

  return (
    <Card className={classes.card} component={Link} to={to} ref={ref} pt={25} px={20} pb={20}>
      <Card.Section className={classes.cardImage}>
        <Image src={asset} alt={title} fit={'contain'} height={40} />
      </Card.Section>
      <Stack align={'start'} justify={'start'} spacing={0}>
        <Title order={4} mx={0} mt={10} mb={4} color={'#242424'}>
          {title}
        </Title>
        <Text color={'#838485'} lineClamp={4}>
          {description}
        </Text>
      </Stack>
      <UnstyledButton
        mt={'auto'}
        mr={'auto'}
        color={'#B3B3B3'}
        className={cx(
          classes.base,
          { [classes.activeIntegration]: (active && !requiresOauth) },
          { [classes.requiresOauthIntegration]: (active && requiresOauth) },
          { [classes.underline]: hovered && !active }
        )}
      >
        {(active && requiresOauth) ? 'Re-authentication required' : ''}
        {(active && !requiresOauth) ? 'Active' : ''}
        {!active ? 'Add' : ''}
      </UnstyledButton>
    </Card>
  );
};

export default IntegrationCard;
