import { Group, GroupProps } from '@mantine/core';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { Point } from 'tabler-icons-react';

const Stepper = ({
  step,
  handleChangeView,
  containerProps,
}: {
  step: number;
  handleChangeView: any;
  containerProps?: GroupProps;
}) => {
  const { data: company } = useGetCompanyQuery();

  return (
    <Group {...containerProps} style={{ display: 'flex', flexWrap: 'nowrap', margin: '0 12px' }}>
      <Point
        onClick={() => handleChangeView(1)}
        fill={step === 1 ? '#6FBE44' : '#DFE1E1'}
        stroke={step === 1 ? '#6FBE44' : '#DFE1E1'}
      />
      <Point
        onClick={() => handleChangeView(2)}
        fill={step === 2 ? '#6FBE44' : '#DFE1E1'}
        stroke={step === 2 ? '#6FBE44' : '#DFE1E1'}
      />
      {company?.role_type?.name !== 'Sourcing Only' && (
        <Point
          onClick={() => handleChangeView(3)}
          fill={step === 3 ? '#6FBE44' : '#DFE1E1'}
          stroke={step === 3 ? '#6FBE44' : '#DFE1E1'}
        />
      )}
    </Group>
  );
};

export default Stepper;
