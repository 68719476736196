import React, { useEffect, useState } from 'react';
import {
    createStyles,
    Image,
    Text,
    Title,
    Container,
    LoadingOverlay,
    DEFAULT_THEME
} from '@mantine/core';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import { RolebotClient } from 'api/client';
import CheckoutForm from './components/CheckoutForm';
import { toastSuccess } from 'utils/toastify-messages';
import { useSignOutMutation } from 'app/services/rolebot';

const useStyles = createStyles((theme) => ({
    overlayContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000, // Increased zIndex to ensure it's above all other content
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '25vh',
        width: '100vw', // Ensure it spans the full viewport width
        height: '100vh', // Ensure it spans the full viewport height
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
    },
    overlayText: {
        position: 'relative',
        color: '#000000',
        textAlign: 'center',
        fontWeight: 700,
        fontSize: '20px',
        padding: '0 20px',
        marginBottom: '20px',
    },
    overlaySubText: {
        position: 'relative',
        color: '#000000',
        textAlign: 'center',
        fontSize: '17px',
        fontWeight: 700,
        padding: '0 20px',
    },
    customLoader: {
        position: 'relative',
        marginBottom: '25px',
        zIndex: 2,
    }
}));

const customLoader = (
    <svg
      width="80"
      height="80"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke={DEFAULT_THEME.colors.violet[6]}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const Payment = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [paymentIntent, setPaymentIntent] = useState(null);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const { classes } = useStyles();

    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const [signOut] = useSignOutMutation();

    const handleUpdateLoadingStatus = (status: boolean) => {
        setIsLoading(status);
    }

    const handleUpdatePaymentLoadingStatus = (status: boolean) => {
        setIsPaymentLoading(status);
    }

    const handleGoToSuccessPage = async () => {
        toastSuccess(
            <div className="toastContainer">
                <div>Welcome to Rolebot!</div>
                <div>Your payment was processed successfully!</div>
            </div>
        );
        await signOut();
        navigate('/');
    }

    useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }

        setIsLoading(true);
        RolebotClient.get(`/customer-pricing-plan/${token}/payment-intent`)
            .then(({ data }) => {
                console.log('data', data)
                setPaymentIntent(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token]);

    return (
        <Container
            size={600}
            styles={{
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: '40px 18px',
                },
            }}
        >
            <LoadingOverlay visible={isLoading} />
            {isPaymentLoading && (
                <div className={classes.overlayContainer}>
                    <LoadingOverlay className={classes.customLoader} loader={customLoader} visible={true} overlayOpacity={0.5} overlayColor="#fff" />
                    <Text className={classes.overlayText} >
                        We are processing your payment
                    </Text>
                    <Text className={classes.overlaySubText}>
                        Do not close or refresh this window
                    </Text>
                </div>
            )}
            <NavLink to={'/'}>
                <Image src={LogoLetters} width={180} fit={'contain'} mb={30} />
            </NavLink>
            <Text
                color='#838485'
                mb={10}
                style={{
                    fontFamily: 'Roboto'
                }}
            >
                Step 3
            </Text>
            <Text style={{ fontSize: '16px', color: '#242424', marginBottom: '30px', fontFamily: 'Helvetica' }}>
                Welcome aboard! Your account has been successfully created. Your membership will start as soon as you set up payment.
            </Text>
            <Title
                order={3}
                mb={20}
                style={{
                    color: '#242424'
                }}
            >
                Set up your payment
            </Title>
            {paymentIntent && (
                // @ts-ignore
                <Elements stripe={stripePromise} options={{clientSecret: paymentIntent.stripe_payment_intent}}>
                    <CheckoutForm
                        updateLoadingStatus={handleUpdateLoadingStatus}
                        paymentIntent={paymentIntent}
                        updatePaymentLoadingStatus={handleUpdatePaymentLoadingStatus}
                        goToSuccessPage={handleGoToSuccessPage}
                    />
                </Elements>
            )}
        </Container>
    );
};

export default Payment;
