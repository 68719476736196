import dayjs from 'dayjs';
import _ from 'lodash';
import { filters, ITalent, RoleType, sortOption } from 'types';

export const getNameInitials = (name = '') => {
  if (name.length === 0) return '';
  const names = name.split(' ');
  return names.length > 1 ? names[0][0] + names[1][0] : names[0][0];
};

export const getElapsedTime = (from: string, to: string) => {
  let _from = from ? dayjs(from) : dayjs();
  let _to = dayjs(to);
  let totalMonths = _from.diff(_to, 'months');
  let years = Math.floor(totalMonths / 12);
  let months = totalMonths % 12;

  if (years < 1) return `${months}mo`;

  if (months < 1) return `${years}y`;

  return `${years}y ${months}mo`;
};

export const getRandomColor = (id?: number) => {
  const colors = ['pink', 'violet', 'yellow', 'green', 'orange', 'pink', 'teal', 'indigo', 'lime'];
  if (!id) return colors[1];
  return colors[Math.ceil((id! % colors.length) - 1)];
};

export const getCleanPayload = (originalPayload: any) => {
  const cleanPayload: any = {};
  const contacts: any = [];
  const stringKeys = [
    'environment',
    'office_locations',
    'sell_opportunity',
    'first_profile_link',
    'second_profile_link',
    'calendly_link',
    'team_size',
    'target_salary',
  ];
  const emptyKeys = ['jd_link'];
  const booleanKeys = ['equity_offered', 'bonus_offered', 'offer_relocation', 'visa_sponsorship'];
  for (const [k, v] of Object.entries(originalPayload)) {
    if (stringKeys.includes(k) && v !== '') {
      cleanPayload[k] = v;
    }

    if (booleanKeys.includes(k)) {
      cleanPayload[k] = v ? 1 : 0;
    }

    //this is where handle items that can be empty strings
    if (emptyKeys.includes(k)) {
      cleanPayload[k] = v;
    }
  }

  if (originalPayload.interviewer && originalPayload.interviewer_title && originalPayload.calendly_link) {
    contacts.push({
      name: originalPayload.interviewer,
      title: originalPayload.interviewer_title,
      calendly_link: originalPayload.calendly_link,
      type: 'interviewer',
    });
  }

  if (originalPayload.report_to && originalPayload.report_to_title) {
    contacts.push({
      name: originalPayload.report_to,
      title: originalPayload.report_to_title,
      type: 'report_to',
    });
  }

  if (contacts.length > 0) cleanPayload.contacts = contacts;
  return cleanPayload;
};

export const buildFilterPayload = (filters: filters) => {
  let newPayload: any = {};
  _.mapValues(filters, (value, key) => {
    newPayload[_.kebabCase(key)] = value ? 1 : 0;
  });
  return newPayload;
};

export const buildSortPayload = (type: sortOption) => {
  let payload: any = {};
  switch (type) {
    case 'default': {
      payload = {};
      break;
    }
    case 'newest-role': {
      payload = {
        'sort-order': 'desc',
      };
      break;
    }
    case 'oldest-role': {
      payload = {
        'sort-order': 'asc',
      };
      break;
    }
    case 'last-reviewed': {
      payload = {
        'sort-type': 'date-time-reviewed',
        'sort-order': 'desc',
      };
      break;
    }
    case 'A-Z': {
      payload = {
        'sort-type': 'role-name',
        'sort-order': 'asc',
      };
      break;
    }
    case 'Z-A': {
      payload = {
        'sort-type': 'role-name',
        'sort-order': 'desc',
      };
      break;
    }
  }
  return payload;
};

export const getTalentStatus = (talent: ITalent): RoleType => {
  const isDeclined = talent?.pivot.accepted === null ? false : !talent?.pivot.accepted;
  const isInterview = talent && talent.interviews.filter((i) => i.voided_at === null).length > 0;
  const isAccepted =
    talent?.pivot.skipped_at === null && talent?.pivot.skipped_reason === null && Boolean(talent?.pivot.accepted);
  const isSkipped = talent?.pivot.skipped_at !== null || talent?.pivot.skipped_reason !== null;
  const isUnavailable = talent?.pivot.outcome_id && !isNaN(talent.pivot.outcome_id);
  if (isInterview) return 'interview';
  if (isUnavailable) return 'unavailable';
  if (isSkipped) return 'skipped';
  if (isDeclined) return 'declined';
  if (isAccepted) return 'accepted';
  return 'review';
};

// function used to put all backend errors inside an array.
// as long as the same structure is used in the backend, this function will work
export const mapErrors = (error: any) => {
  let err = error as { data: { message: ''; errors: { key: string[] } } };
  let allErrors: string[] = [];
  _.mapValues(err?.data?.errors, (value, key) => {
    if (value.length > 0) {
      allErrors.push(...value);
    }
  });
  return allErrors;
};

export const TrimValue = (value: any) => {
  if (typeof value !== 'string') {
    return value;
  }

  return value.trim().replace(/\s\s+/g, ' ');
};
