import React, { useEffect, useState } from 'react';
import { Checkbox, createStyles, Group, Popover, Stack, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { useForm } from 'react-hook-form';
import useAppSelector from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { roleStatsFilterChanged } from 'features/app/appSlice';
import { sendGoogleEvent } from 'utils/analytics';

const useStyles = createStyles((theme, { hasActiveFilters, open }: any) => ({
  button: {
    backgroundColor: hasActiveFilters || open ? '#EEEEEE' : 'transparent',
    color: hasActiveFilters ? '#351B77' : '#838485',
    padding: 10,
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  filterIcon: {
    height: '16px',
    paddingBottom: '2px'
  }
}));

const RoleStatsFilter = () => {
  const [open, setIsOpen] = useState(false);
  const hasActiveFilters = useAppSelector((state) => state.app.roleStats.hasActiveFilters);
  const activeFiltersCount = useAppSelector((state) => state.app.roleStats.activeFiltersCount);
  const filtersState = useAppSelector((state) => state.app.roleStats.filters);
  const { classes } = useStyles({ hasActiveFilters, open });
  const dispatch = useAppDispatch();
  const { register, reset, watch } = useForm({
    defaultValues: filtersState,
    mode: 'onChange',
  });

  const active = watch('active');
  const waitingForSetup = watch('waitingForSetup');
  const onHold = watch('onHold');
  const completed = watch('completed');

  useEffect(() => {
    dispatch(
      roleStatsFilterChanged({
        activeFiltersCount: [active, waitingForSetup, onHold, completed].filter(Boolean).length,
        hasActiveFilters: active || waitingForSetup || onHold || completed,
        active,
        waitingForSetup,
        onHold,
        completed,
      })
    );
    // eslint-disable-next-line
  }, [active, waitingForSetup, onHold, completed, dispatch]);

  const onClear = () => {
    dispatch(
      roleStatsFilterChanged({
        activeFiltersCount: 0,
        hasActiveFilters: false,
        active: false,
        waitingForSetup: false,
        onHold: false,
        completed: false,
      })
    );
    reset({
      active: false,
      waitingForSetup: false,
      onHold: false,
      completed: false,
    });
  };

  useEffect(() => {
    if (open) sendGoogleEvent('User', 'Role manager filters opened');
    // eslint-disable-next-line
  }, [open]);

  return (
    <Popover
      opened={open}
      onClose={() => setIsOpen(false)}
      target={
        <Group spacing={5} onClick={() => setIsOpen((o) => !o)} className={classes.button}>
          <FontAwesomeIcon icon={faFilter} fill={hasActiveFilters ? '#351B77' : '#838485'} className={classes.filterIcon} />
          {hasActiveFilters ? `Filters / ${activeFiltersCount}` : 'Filters'}
        </Group>
      }
      width={320}
      position="bottom"
      withArrow
    >
      <Stack spacing={30} mb={20}>
        <Group position={'apart'} noWrap>
          <Text color={'#242424'} sx={{ fontFamily: 'Roboto' }}>
            Filters
          </Text>
          <Text onClick={onClear} color={'#B3B3B3'} sx={{ cursor: 'pointer' }}>
            Clear All
          </Text>
        </Group>
        <Text color={'#4F4F4F'}>Status</Text>
      </Stack>
      <Stack>
        <form>
          <Checkbox id="filterActive" label="Active" {...register('active')} />
        </form>
        <form>
          <Checkbox id="filterWaitingForSetup" label="Waiting for Setup" {...register('waitingForSetup')} />
        </form>
        <form>
          <Checkbox id="filterOnHold" label="On Hold" {...register('onHold')} />
        </form>
        <form>
          <Checkbox id="filterCompleted" label="Completed" {...register('completed')} />
        </form>
      </Stack>
    </Popover>
  );
};

export default RoleStatsFilter;
