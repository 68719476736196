import React from 'react';
import { createStyles, Text } from '@mantine/core';

const useStyles = createStyles((theme, _params) => ({
  wrapper: {},
  text: {
    fontFamily: 'Helvetica',
    fontWeight: 400,
    color: '#242424',
    marginTop: '10px',
    fontSize: '19px',
  },
}));

interface TitleSectionProps {
  message: string;
}

const TitleSection = ({ message }: TitleSectionProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Text className={classes.text}>{message}</Text>
    </div>
  );
};

export default TitleSection;
