import React, { useRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import AddUsersToRoleItem from './AddUsersToRoleItem';
import useOutsideAreaClick from '../../../../pages/RoleStats/hooks/useOutsideAreaClick';
import { createStyles } from '@mantine/core';
import { RolebotClient } from '../../../../api/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  AddUsersToRoleDropDownWrapper: {
    position: 'relative',
  },

  AddedUsersDropDown: {
    border: 'solid 2px #DFE1E1',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 5px 5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: '1000',
    top: '0',
    left: '0',
    right: '0',
    padding: '5px 7px',
    maxHeight: '150px',
    overflowY: 'auto',
  },

  SearchFeedbackItem: {
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '8px',
    color: '#242424',
    padding: '2px 10px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      borderRadius: '5px',
    },
    '&:last-child': {
      marginBottom: '0',
    },
  },

  svg: {
    width: 30,
    height: 30,
    marginRight: 10,
    verticalAlign: 'middle',
    [theme.fn.largerThan('md')]: {
      width: 25,
      height: 25,
    },
    fill: 'purple',
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case 'searchValueChanged':
      return {
        ...state,
        searchValue: action.payload,
      };
    case 'getCompanyUsersReturned':
      return {
        ...state,
        users: action.payload,
      };
    default:
      throw new Error();
  }
};

const AddUsersToRoleDropDown = ({ show, userSelected, needle, closeDropdown }) => {
  const [state, dispatch] = useReducer(reducer, {
    users: [],
    searchValue: '',
  });

  const { classes } = useStyles();
  const wrapperRef = useRef(null);
  const clickedOutside = useOutsideAreaClick(wrapperRef);

  useEffect(() => {
    if (clickedOutside && show) {
      closeDropdown();
    }
    // eslint-disable-next-line
  }, [clickedOutside, show]);

  useEffect(() => {
    RolebotClient.get('/customers/users')
      .then(({ data }) => {
        dispatch({ type: 'getCompanyUsersReturned', payload: data.users });
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line
  }, []);

  const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const usersToShow = state.users.filter(
    (x) => !x.deleted_at && (x.name.toLowerCase().includes(needle.toLowerCase()) || x.email.toLowerCase().includes(needle.toLowerCase()))
  );

  return show && state.users.length && needle.length > 0 ? (
    <div className={classes.AddUsersToRoleDropDownWrapper} ref={wrapperRef}>
      <div className={classes.AddedUsersDropDown}>
        {usersToShow.filter((x) => x.deleted_at === null).map((x) => (
          <AddUsersToRoleItem user={x} key={x.id} userSelected={userSelected} />
        ))}
        {usersToShow.length === 0 && !isValidEmail(needle) && (
          <div className={classes.SearchFeedbackItem}>No matches found, try using their email instead.</div>
        )}
        {usersToShow.length === 0 && isValidEmail(needle) && (
          <div
            onClick={() => {
              userSelected({ email: needle });
            }}
            className={classes.SearchFeedbackItem}
          >
            <span style={{ fontWeight: 'bold' }}>
              <FontAwesomeIcon icon={faEnvelope} className={classes.svg} width={25} height={25} />
              Invite
            </span>{' '}
            {needle}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default AddUsersToRoleDropDown;

AddUsersToRoleDropDown.propTypes = {
  show: PropTypes.bool.isRequired,
  userSelected: PropTypes.func.isRequired,
};
