import { Alert, Button, createStyles, Group, Stack, Text, TextInput } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';

import { FormEvent, useEffect, useState } from 'react';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { useNotifications } from '@mantine/notifications';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useGetCompanyLocationsQuery, usePostCompanyLocationMutation } from 'app/services/rolebot';

const NewOffice = ({ id }: ContextModalProps) => {
  const modals = useModals();
  const [location, setLocation] = useState(null);
  const [postCompanyLocation, { isLoading, isSuccess, isError, error }] = usePostCompanyLocationMutation();
  let notifications = useNotifications()

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => setLocation(place),
    options: {
      fields: ["formatted_address", "address_components"],
      strictBounds: false,
      types: ["locality", "administrative_area_level_1", "country", "continent"],
    }
  })

  useEffect(() => {
    if (isSuccess) {
      notifications.showNotification({
        title: 'Done',
        color: 'green',
        message: 'Office has been added successfully',
        autoClose: 3000,
      });

      modals.closeAll()
    }

    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      notifications.showNotification({
        title: 'Oops!',
        color: 'yellow',
        // @ts-ignore
        message: error.status === 409 ? 'Office already exists' : 'Something went wrong',
        autoClose: 3000,
      });
    }
  }
  , [isError, error]);

  const handleSubmitLocation = (e: FormEvent) => {
    e.preventDefault();
    postCompanyLocation(location)
  }

  const handleCancelButtonClicked = (e: FormEvent) => {
    e.preventDefault();
    modals.closeAll()
  }

  return (
    <>
      <TitleWithClose id={id} title={'Create new offices'} mb={20} />
      <form onSubmit={handleSubmitLocation}>
        <Stack>
          <TextInput
              label={'Office Location'}
              size={'md'}
              placeholder={'Type a location'}
              ref={ref}
            />
            <Group position={'right'} mt={50}>
            <RolebotButton type={'neutral'} onClick={handleCancelButtonClicked}>
              Cancel
            </RolebotButton>

            <Button px={30} loaderPosition={'right'} type={'submit'}  loading={isLoading} disabled={location === null}>
              Create Office
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};

export default NewOffice;
