import RolebotBadge from '../RolebotBadge';
import { Group, Tooltip, Badge } from '@mantine/core';
import React from 'react';
import { ITalent } from 'types';
import { nanoid } from 'nanoid';

interface TalentBadgesProps extends ITalent {
    id: number;
    minimal?: boolean;
    className?: string;
}

const TalentBadges = ({
    id,
    pivot,
    minimal = false,
    className,
}: TalentBadgesProps) => {

    let badges = [];

    if (pivot?.accepted === null && pivot?.skipped_at === null && pivot?.skipped_reason === null && pivot?.is_calibration_talent)
        badges.push(
            <Tooltip
                label={'This talent is automatically added to test your search criteria. Review and modify your criteria to optimize candidate matches.'}
                placement={'center'}
                wrapLines
                width={400}
            >
                <Badge
                    key={`${nanoid(6)}-calibration-talent`}
                    variant={'filled'}
                    color={'red'}
                    styles={{
                        root: {
                            backgroundColor: '#FEF9EB',
                        },
                        inner: {
                            color: '#242424',
                            backgroundColor: '#FEF9EB',
                        },
                    }}
                >
                    Search Samples
                </Badge>
            </Tooltip>
        );

    return (
        <Group spacing={'xs'} noWrap className={className}>
            {badges}
        </Group>
    );
};

export default TalentBadges;
