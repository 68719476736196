import React, { useEffect, useReducer, useState } from 'react';
import { useTable } from 'react-table';
import { useSelector } from 'react-redux';
import moment from 'moment';
import RoleNameCell from './RoleManagerSection/TableCells/RoleNameCell';
import InterviewerCell from './RoleManagerSection/TableCells/InterviewerCell';
import UsersCell from './RoleManagerSection/TableCells/UsersCell';
import CreatedDateCell from './RoleManagerSection/TableCells/CreatedDateCell';
import LastReviewedCell from './RoleManagerSection/TableCells/LastReviewedCell';
import useDebounce from './hooks/useDebounce';
import { createStyles, Divider, Group, Pagination, Stack, Text, Title } from '@mantine/core';
import {
  TypeOfRoleHeader,
  AwaitingReviewHeader,
  ConfirmedInterviewsHeader,
  InterviewerHeader,
  LastReviewedHeader,
  RoleCreatedOnHeader,
  RoleNameHeader,
  StatusHeader,
  TalentSurfacedHeader,
  UsersInRoleHeader,
} from './RoleStatsHeaders';
import SearchInput from '../../components/SearchInput/SearchInput';
import {
  AwaitingReviewCell,
  TalentSurfacedCell,
  ConfirmedInterviews,
} from './RoleManagerSection/TableCells/RoleStatCells';
import RoleManagerDownLoadButton from './RoleManagerSection/RoleManagerDownloadButton';
import { RolebotClient } from '../../api/client';
import useAppSelector from '../../hooks/useAppSelector';
import RoleStatsFilter from './RoleStatsFilter';
import RoleStatsSort from './RoleStatsSort';
import RolesNotFound from 'components/AlertMessages/RolesNotFound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  container: {
    paddingInline: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    marginInline: 20,

    [theme.fn.largerThan('md')]: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      paddingInline: 30,
      marginInline: 'unset',
    },
  },

  HeaderTitle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '22px',
  },

  Table: {
    borderSpacing: 0,
    width: '100%',
    overflow: 'auto',
    display: 'block',
    //height: '58vh',
    th: {
      paddingBottom: 10,
      ':first-of-type': {
        left: 0,
        position: 'sticky',
        zIndex: 10,
        //display: 'flex',
        backgroundColor: '#fff',
        '@media (max-width: 800px)': {
          width: '50vw',
          paddingBottom: 30,
        },
      },
      ':nth-of-type(n+2)': {
        '@media (max-width: 800px)': {
          left: '50vw',
        },
      },
    },
    td: {
      padding: 2,
      ':first-of-type': {
        left: 0,
        position: 'sticky',
        zIndex: 10,
        textAlign: 'left',
        padding: '1vh 0 1vh 1vw',
        backgroundColor: '#fff',

        '@media (max-width: 800px)': {
          width: '50vw',
          display: 'flex',
        },
      },
      ':nth-of-type(n+2)': {
        '@media (max-width: 800px)': {},
      },

      height: 66,
      border: '1px solid #DFE1E199',
      textAlign: 'center',
    },
    '@media (max-width: 800px)': {
      //mobile
      marginTop: '6vh',
    },
    '@media (min-width: 1500px) and (max-width: 2500px)': {
      marginTop: '0vh',
    },
  },

  'th, td': {
    textAlign: 'center',
  },

  'td:nth-child(1)': {
    textAlign: 'left',
    paddingLeft: '10px',
  },

  'table, th, td': {
    border: '1px solid #DFE1E1',
  },

  '.PaginationSection .Pagination .PaginationItem a': {
    color: '#18234d',
  },

  '.PaginationSection .Pagination .PaginationItemActive span': {
    backgroundColor: '#44c9c7',
    borderColor: 'unset',
  },

  '@media (min-width: 992px)': {
    table: {
      backgroundColor: '#f7f5f5',
      td: {
        padding: 5,
        ':first-of-type': {
          width: '20vw',
          backgroundColor: 'red',
        },
        backgroundColor: 'red',
      },
    },

    th: {
      textAlign: 'center',
      justifyContent: 'center',
      backgroundColor: '#FFFFFF',
    },

    td: {
      textAlign: 'center',
      backgroundColor: 'red',
    },

    Popup: {
      background: 'rgba(255, 255, 255, 1)',
      borderRadius: '5px',
      boxShadow: '0px 0px 15px 4px rgba(0, 0, 0, 0.25)',
      padding: '20px',
    },

    Checkbox: {
      marginTop: '0',
      userSelect: 'none',
      color: '#4F4F4F',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontSize: '17px',
    },

    UserLists: {
      maxHeight: '200px',
      overflowY: 'auto',
    },
  },
  topGrid: {
    marginBottom: '10px',
  },

  button: {
    backgroundColor: 'transparent',
    color: '#838485',
    padding: 10,
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },

  pagination: {
    color: '#838485',
    cursor: 'pointer',
    width: 30,
    height: 30,
    borderRadius: 2,
    ':hover': {
      backgroundColor: '#EEEEEE',
      color: '#522BB5',
    },
  },

  activePagination: {
    color: '#fff',
    backgroundColor: '#7039ED',
    ':hover': {
      color: '#fff',
      backgroundColor: '#7039ED',
    },
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case 'getMetricsReturned':
      return {
        ...state,
        loading: false,
        roles: action.payload.data,
        page: action.payload.data.length > 0 ? action.payload.current_page : 1,
        total: action.payload.total,
        lastPage: action.payload.last_page,
        perPage: parseInt(action.payload.per_page, 10),
      };
    case 'pageChanged':
      return {
        ...state,
        page: action.payload,
      };
    case 'searchValueChanged':
      return {
        ...state,
        searchValue: action.payload,
      };

    case 'sortOrderChanged':
      return {
        ...state,
        ...(state.sortType === action.payload
          ? {
              // if sortType is the same at last we set sortOrder to asc
              sortType: action.payload,
              sortOrder: state.sortOrder === 'asc' ? 'desc' : 'asc',
            }
          : {
              //If sortType is different we set that sortType and set sortOrder to desc
              sortType: action.payload,
              sortOrder: 'desc',
            }),
      };
    case 'getCompanyUsersReturned':
      return {
        ...state,
        users: action.payload.filter((x) => !/@rolebot\.io/.test(x.email)),
      };
    default:
      throw new Error();
  }
};

const RoleStats = () => {
  const companyId = useSelector((state) => state.app.defaultCompanyId);
  const filters = useAppSelector((state) => state.app.roleStats.filters);
  const userIds = useAppSelector((state) => state.app.roleStats.users.ids);

  const [isLastCallDone, setisLastCallDone] = useState(true);
  const [state, dispatch] = useReducer(reducer, {
    //users related to company
    users: [],
    loading: false,
    searchValue: '',
    roles: [],
    page: 1,
    perPage: 20,
    total: 0, // total results not paginated
    lastPage: 0, // last page one can call
    sortType: null,
    sortOrder: null,
  });

  const searchDebounced = useDebounce(state.searchValue, 1000);

  const requestRoleMetrics = (request) => {
    RolebotClient.get('/role-metrics', { params: request })
      .then(({ data }) => {
        if (data?.roles?.data.length === 0) {
          const payload = { ...request, page: 1 };
          RolebotClient.get('/role-metrics', { params: payload })
            .then(({ data }) => {
              dispatch({ type: 'getMetricsReturned', payload: data.roles });
              setisLastCallDone(true);
            })
            .catch((e) => {
              console.log(e);
              setisLastCallDone(true);
            });
        }
        dispatch({ type: 'getMetricsReturned', payload: data.roles });
        setisLastCallDone(true);
      })
      .catch((e) => {
        console.log(e);
        setisLastCallDone(true);
      });
  };

  useEffect(() => {
    const payload = {
      'company-id': companyId,
      users: 1,
      info: 1,
      contacts: 1,
      page: state.page,
      'per-page': state.perPage,
      'include-ats': 1,
    };

    if (state.sortType) {
      payload['sort-type'] = state.sortType;
      payload['sort-order'] = state.sortOrder;
    }

    if (searchDebounced !== '') {
      payload.search = searchDebounced;
    }

    if (filters.active) {
      payload['filter-active'] = 1;
    }

    if (filters.onHold) {
      payload['filter-on-hold'] = 1;
    }

    if (filters.waitingForSetup) {
      payload['filter-waiting-for-setup'] = 1;
    }

    if (filters.completed) {
      payload['filter-completed'] = 1;
    }

    if (userIds.length > 0) {
      payload['filter-user-ids'] = userIds;
    }

    if (isLastCallDone) {
      setisLastCallDone(false);
      requestRoleMetrics(payload);
    }
    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.page,
    state.perPage,
    companyId,
    searchDebounced,
    state.sortType,
    state.sortOrder,
    filters.active,
    filters.onHold,
    filters.waitingForSetup,
    filters.completed,
    userIds,
  ]);

  useEffect(() => {
    RolebotClient.get('/customers/users')
      .then(({ data }) => {
        dispatch({ type: 'getCompanyUsersReturned', payload: data.users });
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line
  }, []);

  const handleSearchValueChanged = (value) => {
    dispatch({ type: 'searchValueChanged', payload: value });
  };

  const handleSortClicked = (sortType) => {
    dispatch({ type: 'sortOrderChanged', payload: sortType });
  };

  const findInterviewerNameFromContacts = (contacts) => {
    if (contacts?.users.length === 0 || contacts?.info?.contacts.length === 0) {
      return '';
    }

    const interviewer =
      contacts?.users.find((x) => x.pivot.is_interviewer === 1) ||
      contacts?.info?.contacts.find((e) => e.role_contact_info.type === 'interviewer');
    return interviewer ? interviewer.name : '';
  };

  const { classes } = useStyles();

  const buildStatus = (role) => {
    switch (true) {
      case role.completed === true:
        return 'Completed';
      case role.on_hold:
        return 'On Hold';
      case !role.info || role.force_card || role.show_info:
        return 'Waiting For Setup';
      default:
        return 'Active';
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <RoleNameHeader
            currentSortType={state.sortType}
            currentSortOrder={state.sortOrder}
            onCLick={handleSortClicked}
          />
        ),
        accessor: 'col1',
        width: '300px',
      },
      {
        Header: <StatusHeader />,
        accessor: 'col2',
        width: '125px',
      },
      {
        Header: <TypeOfRoleHeader />,
        accessor: 'col3',
      },
      {
        Header: (
          <TalentSurfacedHeader
            currentSortType={state.sortType}
            currentSortOrder={state.sortOrder}
            onCLick={handleSortClicked}
          />
        ),
        accessor: 'col4',
        width: '100px',
      },
      {
        Header: (
          <AwaitingReviewHeader
            currentSortType={state.sortType}
            currentSortOrder={state.sortOrder}
            onCLick={handleSortClicked}
          />
        ),
        accessor: 'col5',
        width: '100px',
      },
      {
        Header: <InterviewerHeader />,
        accessor: 'col6',
      },
      {
        Header: <ConfirmedInterviewsHeader />,
        accessor: 'col7',
      },
      {
        Header: <UsersInRoleHeader />,
        accessor: 'col8',
      },
      {
        Header: (
          <RoleCreatedOnHeader
            currentSortType={state.sortType}
            currentSortOrder={state.sortOrder}
            onCLick={handleSortClicked}
          />
        ),
        accessor: 'col9',
        width: '200px',
      },
      {
        Header: (
          <LastReviewedHeader
            currentSortType={state.sortType}
            currentSortOrder={state.sortOrder}
            onCLick={handleSortClicked}
          />
        ),
        accessor: 'col10',
        width: '200px',
      },
    ],
    [state.sortType, state.sortOrder]
  );

  const data = React.useMemo(
    () =>
      state.roles.map((x) => {
        const locationTag = ( (x.info?.offices) && (x?.info?.offices.length > 0)) ? x?.info?.offices[0].location_name : ''
        const locationCount = ( (x?.info?.offices) && (x?.info?.offices.length > 1)) ? ('+'+(x?.info?.offices.length-1)) : ''
        return {
          col1: (
            <RoleNameCell
              name={x.name}
              location={locationTag ? locationTag + ' ' + locationCount : x?.info?.office_locations}
              greenHouseJob={x.greenhouse_job}
              leverJob={x.lever_job}
              sapJob={x.sap_job}
            />
          ),
          col2: <Text color={'#242424'}>{buildStatus(x)}</Text>,
          col3: <Text>{x.sourcing_only ? 'Sourcing only' : 'Turnkey'}</Text>,
          col4: <TalentSurfacedCell amount={x.metrics.talent_surfaced} />,
          col5: <AwaitingReviewCell amount={x.metrics.talent_waiting_count} />,
          col6: <InterviewerCell>{x ? findInterviewerNameFromContacts(x) : ''}</InterviewerCell>,
          col7: <ConfirmedInterviews amount={x.metrics.talent_interview_count} />,
          col8: <UsersCell users={x.users.filter((x) => !/@rolebot\.io/.test(x.email) && x.deleted_at === null)} key={x.id} />,          
          col9: <CreatedDateCell users={x.users.filter((x) => !/@rolebot\.io/.test(x.email))} key={x.id} created_by={x.created_by} created_at ={x.created_at}/>,
          col10: <LastReviewedCell users={x.users.filter((x) => !/@rolebot\.io/.test(x.email))} key={x.id} reviewed_by={x.last_reviewed_by} reviewed_at ={x.talent_reviewed_date}/>,
        };
      }),
    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.roles, state.sortType, state.sortOrder]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
  const selectedUsers = state.users.filter((x) => userIds.includes(x.id));
  const userPool = state.users.filter((x) => !userIds.includes(x.id));

  return (
    <div className={classes.container}>
      <Stack mt={40} mb={30} spacing={30}>
        <Title order={2} sx={{ color: '#242424' }}>
          Role Stats
        </Title>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Group sx={{ flex: 1 }} noWrap>
            <SearchInput
              value={state.searchValue}
              onChange={handleSearchValueChanged}
              sx={{ minWidth: 200, maxWidth: 500, flex: 1 }}
            />

            <RoleStatsFilter />

            <RoleStatsSort
              users={userPool}
              selectedUsers={selectedUsers}
              style={!isLastCallDone ? { pointerEvents: 'none', opacity: '0.4' } : {}}
              isLastCallDone={isLastCallDone}
            />
          </Group>

          <RoleManagerDownLoadButton companyId={companyId} />
        </div>
        <Divider />
      </Stack>
      <div style={{display: 'grid', flex: 1, overflow: 'auto'}}>
        {state.roles.length > 0 ? (<>
        
            <table {...getTableProps()} className={classes.Table}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps([
                      {
                        style: headerGroup.style,
                      },
                    ])}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            <div style={{ width: cell.column.width }}>{cell.render('Cell')}</div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
        
          </>) : (
          <RolesNotFound />
        )}
      </div>
      <div style={{display: 'flex', height: 100, alignItems: 'end', justifyContent: 'center'}}>
      {state.roles.length > 0 ? (
      <Pagination
              style={!isLastCallDone ? { pointerEvents: 'none', opacity: '0.4' } : {}}
              position={'center'}
              total={state.lastPage}
              onChange={(page) => dispatch({ type: 'pageChanged', payload: page })}
              my={30}
              itemComponent={PaginationComponent}
            />) : (<></>)}
      </div>
    </div>
  );
};

const PaginationComponent = ({ page, active, className, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <Group
      {...rest}
      className={cx(classes.pagination, { [classes.activePagination]: active })}
      align={'center'}
      position={'center'}
    >
      <Text>
        {page === 'prev' && <FontAwesomeIcon icon={faChevronLeft} />}
        {page === 'next' && <FontAwesomeIcon icon={faChevronRight} />}
        {page === 'dots' &&
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 8c0-.733.6-1.333 1.333-1.333.734 0 1.334.6 1.334 1.333s-.6 1.333-1.334 1.333C2.6 9.333 2 8.733 2
           8zm9.333 0c0-.733.6-1.333 1.334-1.333C13.4 6.667 14 7.267 14 8s-.6 1.333-1.333 1.333c-.734 0-1.334-.6-1.334
           -1.333zM6.667 8c0-.733.6-1.333 1.333-1.333s1.333.6 1.333 1.333S8.733 9.333 8 9.333 6.667 8.733 6.667 8z"
    fill="currentColor"/></svg>}
        {page !== 'prev' && page !== 'next' && page !== 'dots' && page}
      </Text>
    </Group>
  );
};

export default RoleStats;
