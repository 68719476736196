import { createSlice } from '@reduxjs/toolkit';

interface AppState {
  roleInfoForm: {
    first_profile_link: string;
    second_profile_link: string;
    interviewer: string;
    interviewer_title: string;
    calendly_link: string;
    team_size: string;
    target_salary: string;
    sell_opportunity: string;
    jd_link: string;
    office_locations: any;
    report_to: string;
    report_to_title: string;
    environment: string;
    bonus_offered: boolean;
    equity_offered: boolean;
    offer_relocation: boolean;
    visa_sponsorship: boolean;
    specific_location: boolean;
    remote_locations: any;
    remote_buffer: any;
    office_buffer: any;
  };
}

const initialState: AppState = {
  roleInfoForm: {
    first_profile_link: '',
    second_profile_link: '',
    interviewer: '',
    interviewer_title: '',
    calendly_link: '',
    team_size: '',
    target_salary: '',
    sell_opportunity: '',
    jd_link: '',
    office_locations: [],
    report_to: '',
    report_to_title: '',
    environment: '',
    bonus_offered: false,
    equity_offered: false,
    offer_relocation: false,
    visa_sponsorship: false,
    specific_location: true,
    remote_locations: [],
    remote_buffer: null,
    office_buffer: null
  },
};

export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    updateRoleInfoForm: (state, action) => {
      state.roleInfoForm = {
        ...state.roleInfoForm,
        ...action.payload,
      }
    },
    resetRoleInfoForm: (state) => {
      state.roleInfoForm = initialState.roleInfoForm;
    }
  },
});

const { reducer, actions } = formsSlice;

export const { updateRoleInfoForm, resetRoleInfoForm } = actions;
export default reducer;
