import { ActionIcon, createStyles, Group, Popover, Stack, Text, Tooltip, UnstyledButton } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { usePatchTalentMutation } from 'app/services/rolebot';
import { useClickOutside, useHover, useMediaQuery } from '@mantine/hooks';
import { closeTalentMenu, enqueueTalent } from 'features/app/appSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

interface SkipProps {
  roleId: number;
  talentId: number;
  active: boolean;
  size?: number;
  handleSetTalent?: any;
  handlePatchCompleted?: any;
  isFeatureLocked?: boolean;
}

const useStyles = createStyles((theme, { hovered, active }: { hovered: boolean; active: boolean }) => ({
  body: {
    padding: '10px 10px',
  },
  title: {
    fontFamily: 'Roboto',
    color: '#242424',
    marginLeft: 10,
  },
  option: {
    width: '100%',
    fontFamily: 'Helvetica',
    fontSize: 16,
    color: '#242424',
    padding: '10px 10px',
    borderRadius: 6,
    ':hover': {
      backgroundColor: '#EEEEEE',
      transition: 'background-color 0.2s ease-in-out',
    },
  },

  svg: {
    fill: active ? '#4F4F4FE6' : '#838485',
    ':hover': {
      fill: hovered ? '#4F4F4FE6' : '#838485',
    },
  },
}));

const Skip = ({ roleId, talentId, size = 50, active, handleSetTalent = () => {}, handlePatchCompleted = (action: string, talentId: number | string) => {}, isFeatureLocked }: SkipProps) => {
  const [opened, setOpened] = useState(false);
  const dispatch = useAppDispatch();
  const [patchTalent, { isSuccess, isLoading }] = usePatchTalentMutation();
  const [, setButton] = useState();
  const ref = useClickOutside(() => {});
  const { ref: refHover, hovered } = useHover();
  const { classes } = useStyles({ hovered, active });
  const isMediumBreakpoint = useMediaQuery('(min-width: 993px)');
  const talentMenuOpen = useAppSelector((state) => state.app.talentMenuOpen);

  useEffect(() => {
    if (!isSuccess) return;
    let id = setInterval(() => {
      if (talentMenuOpen) {
        dispatch(closeTalentMenu());
        dispatch(enqueueTalent({ count: 1 }));
      }
    }, 250);
    return () => clearInterval(id);
    // eslint-disable-next-line
  }, [isSuccess]);

  const handlePatchTalent = async (reason: string) => {
    if (active) return;
    setOpened(false);
    try {
      handleSetTalent(talentId);
      await patchTalent({
        roleId,
        talentId,
        payload: {
          skipped_reason: reason,
        },
      }).unwrap()
      .then((response: any) => {
        console.log('patch response', response);
        handlePatchCompleted('skipped', talentId, response.should_show_feedback_modal);
      })
      .catch((error: any) => {
        console.log('patch error', error);
      });
    } catch (e) {
      throw e;
    }
  };

  return (
    <Popover
      opened={opened}
      position={isMediumBreakpoint ? 'bottom' : 'top'}
      placement="end"
      onClose={() => setOpened(false)}
      transition="pop-top-right"
      classNames={{
        inner: classes.body,
      }}
      target={
        <Tooltip label={isFeatureLocked ? 'Reactivate your plan to unlock this feature' : 'Skip'} placement={'center'}>
          <ActionIcon
            loading={isLoading}
            //@ts-ignore
            ref={(ref) => {
              refHover.current = ref;
              setButton(ref);
            }}
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation();
              (!active && !isFeatureLocked) && setOpened((o) => !o);
            }}
            size={size}
            variant={isLoading ? 'transparent' : 'outline'}
            radius={size / 2}
            sx={{
              backgroundColor: '#fff',
              boxShadow: '0px 0px 4px #4f4f4f40',
              color: active ? '#4F4F4FE6' : '#4F4F4F',
              border:
                active && !isLoading ? `2px solid #4F4F4FE6` : !active && !isLoading ? '1px solid #DFE1E1' : 'none',
              '&:hover': {
                backgroundColor: '#fff',
                color: `#4F4F4F99`,
                border: !isLoading ? `2px solid #4F4F4F99` : 'none',
              },
            }}
          >
            <Text size={'sm'} color={'#838485'}>
              Skip
            </Text>
          </ActionIcon>
        </Tooltip>
      }
    >
      <Group direction={'column'} spacing={6} ref={ref} onClick={(e) => e.stopPropagation()}>
        <Text className={classes.title}>Reason:</Text>
        <Stack spacing={0}>
          <UnstyledButton onClick={() => handlePatchTalent('previously contacted')} className={classes.option}>
            Previously Contacted
          </UnstyledButton>
          <UnstyledButton onClick={() => handlePatchTalent('profile error')} className={classes.option}>
            Profile Error
          </UnstyledButton>
        </Stack>
      </Group>
    </Popover>
  );
};

export default Skip;
