import React, { useEffect, useState } from 'react';
import { Anchor, Button, createStyles, Group, Image } from '@mantine/core';
import { IConferencingProfiles } from '../../../../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-light-svg-icons';
import GoogleLogo from 'assets/img/logos/google_meet_logo.png';
import EightxEightLogo from 'assets/img/logos/8x8_logo.png';
import ZoomLogo from 'assets/img/logos/zoom_logo.png';
import MicrosoftTeamsLogo from 'assets/img/logos/teams_logo.png';
import GotoLogo from 'assets/img/logos/goto_logo.png';
// import AuthExpiredByProviderModal from 'components/Modals/AuthExpiredByProviderModal/AuthExpiredByProviderModal';

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
    padding: '10px',
    borderBottom: 'solid 1px #c4c4c4',
  },
  name: {
    color: '#242424',
    fontFamily: 'Helvetica',
    textTransform: 'capitalize',
  },
  button: {
    width: '100%',
    minWidth: '200px',
  },
  serviceLogo: {
    width: '24px',
    height: '24px',
  }
}));

interface CalendarItemProps {
  item: IConferencingProfiles;
  onSelectItem: (profile_id: string) => void;
  loading: boolean;
  cronofyProviders: any;
}

const CalendarItem = ({ item, loading, onSelectItem, cronofyProviders }: CalendarItemProps) => {
  const { classes } = useStyles();
  const [serviceLogo, setServiceLogo] = useState('');
  const baseURL = process.env.REACT_APP_API_URL;
  const providerAllowed = ['zoom', 'ms_teams', 'go_to'];
  const handleClick = () => {
    onSelectItem(item.profile_id);
  };

  const cronofyProfile =
    cronofyProviders && cronofyProviders['cronofy.data']?.conferencing_profiles.length > 0 &&
    cronofyProviders['cronofy.data']?.conferencing_profiles.filter(
      (e: any) => e.provider_name === item.provider_name && providerAllowed.includes(item.provider_name)
    );

  const buttonText = () => {
    if (loading) return '';
    return item.is_selected ? 'Default' : 'Set as Default';
  };

  const authExpired = () => {
    let cronofyUrl =
      cronofyProfile && !cronofyProfile[0]?.profile_connected
        ? cronofyProfile?.profile_relink_url
        : `${baseURL}/cronofy/conferencing-setup`;
    return (
      // <AuthExpiredByProviderModal
      //   title={'Microsoft Teams Authentication Expired'}
      //   subtitle={
      //     'Oh oh! it looks like your Microsoft teams authentication expired.\n This can happen for all sorts of reasons. Often, for example, providers will expire links to services when you change your password.'
      //   }
      //   url={cronofyUrl}
      // />
      <Anchor style={{ color: '#fff' }} href={cronofyUrl} target="_self">
        <Button className={classes.button}>Reauthenticate</Button>
      </Anchor>
    );
  };

  const getServiceLogo = (serviceName: string | null | undefined) => {
    if (!serviceName) return '';

    var brands = {
      'google': GoogleLogo,
      '8x8': EightxEightLogo,
      'phone': 'Phone',
      'zoom': ZoomLogo,
      'teams': MicrosoftTeamsLogo,
      'go_to': GotoLogo,
      'default': ''
    };

    //as keyof typeof cards => 'visa' | 'mastercard' | 'amex' | 'unionpay' | 'discover' | 'default'
    //we use this so TS knows that cardBrand is a valid key of brands, otherwise it will throw an error
    return brands[serviceName as keyof typeof brands] || brands['default'];
  };

  useEffect(() => {
    if(item.provider_name) {
      console.log('item.provider_name', item.provider_name);
      const cardLogo = getServiceLogo(item.provider_name);
      setServiceLogo(cardLogo);
    }
  }, [item.provider_name]);

  return (
    <Group position="apart" className={classes.wrapper}>
      <Group>
        {serviceLogo === 'Phone' && (
          <FontAwesomeIcon icon={faPhone} className={classes.serviceLogo} style={{ marginRight: '10px' }} />
        )}
        {serviceLogo !== 'Phone' && serviceLogo !== '' && (
          <Image src={serviceLogo} className={classes.serviceLogo} />
        )}
        <div className={classes.name}>{item.provider_name}</div>
      </Group>
      {item?.is_selected &&
      providerAllowed.includes(item.provider_name) &&
      (!cronofyProfile[0]?.profile_connected ||
        cronofyProviders['cronofy.data']?.conferencing_profiles.length === 0) ? (
        authExpired()
      ) : (
        <Button
          variant="outline"
          className={classes.button}
          px={40}
          onClick={handleClick}
          loading={loading}
          disabled={item.is_selected}
        >
          {buttonText()}
        </Button>
      )}
    </Group>
  );
};

export default CalendarItem;
