import { Text } from '@mantine/core';
import {useCallback, useEffect, useState} from 'react';
import {RolebotClient} from "../../../../api/client";
import { useDebouncedValue } from '@mantine/hooks';
import RolebotMultiselect from 'components/Inputs/RolebotMultiSelect';
interface SearchCriteriaCompaniesProps {
  onCompaniesChange: (payload: { name: string }[]) => void;
  companies: any;
  isExcluded: boolean;
}
// transforms the data to a Mantine MultiSelect readable format
const formatDropdownItems = (items: { id: any; name: any; }[]) => {
  if (items?.length) {
    return items?.map((x: { id: any; value?: any; name: any; label?: any; }) => {
      return {
        value: x.id.toString(),
        label: x.name,
        id: x.id,
        name: x.name
      };
    });
  } else {
    return []
  }
}
const getUniqueListById = (arr: any) => {
  return [...new Map(arr.map((item:any) => [item['id'], item])).values()]
}
const SearchCriteriaCompanies = ({ onCompaniesChange, companies, isExcluded }: SearchCriteriaCompaniesProps) => {
  const [companySearchTerm, setCompanySearchTerm] = useState("");
  const [availableCompanies, setAvailableCompanies] = useState(companies?.length ? formatDropdownItems(companies) : []);
  const [selectedCompanies, setSelectedCompanies] = useState(companies ? companies.map( (company:any) => company.id.toString()) : []);
  const [debouncedSearch] = useDebouncedValue(companySearchTerm.trim(), 500);
  useEffect(() => {
    onCompaniesChange(formatPayloadItems(selectedCompanies));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanies]);
  useEffect(() => {
    if ( companySearchTerm ) searchForCompaniesTag(companySearchTerm)
  }, [debouncedSearch])
  // transforms the data to a back to the expected from API format  e.g: id: 1, name: "Laravel"
  const formatPayloadItems = (items: string[]) => {
    if (items?.length) {
      return items.map((id: string) => {
        let object = availableCompanies.find((s:any) => s.id.toString() === id)
        return {
          id: object?.id ?? id,
          name: object?.name ?? id,
          is_excluded: isExcluded
        };
      });
    } else {
      return []
    }
  }
  const searchForCompaniesTag = useCallback((searchTerm) => {
    ( async () => {
      try {
        const { data } = await RolebotClient.get('/search-companies', { params: { 'search-term': searchTerm } });
        if (data.data && data.data.length) {
          // @ts-ignore
          setAvailableCompanies((prev) => getUniqueListById([...prev, ...formatDropdownItems(data.data)]));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [debouncedSearch]);
  const copyToAvailable = (values: string[]) => {
    let selected = availableCompanies.filter( (x:any) => values.includes(x.value))
    setAvailableCompanies(selected)
    setSelectedCompanies(values)
  }
  return (
    <>
      
      <RolebotMultiselect
        data={availableCompanies}
        searchable
        creatable
        value={selectedCompanies}
        getCreateLabel={(query) => `+ Create ${query}`}
        onChange={copyToAvailable}
        nothingFound={'Type to search'}
        onSearchChange={setCompanySearchTerm}
        selectOnBlur={true}
        clearSearchOnBlur={true}
        clearSearchOnChange={true}
        style={{width: '100%'}}
        maxSelectedValues={100}
        withinPortal
        onBlur={() => {
          setAvailableCompanies(availableCompanies.filter( (x:any) => selectedCompanies.includes(x.value)));
        }}
      />
      
    </>
  );
};
export default SearchCriteriaCompanies;