import React from 'react';
import { useModals } from '@mantine/modals';
import { Button, Group, Image, Stack, Text, Title } from '@mantine/core';
import RolebotThumbsUp from 'assets/img/rolebot-thumbs-up.svg';

const SuccessLaunch = ({ onClose }: any) => {
  useModals();

  return (
    <div style={{ overflow: 'hidden', marginTop: "3rem" }} >
      <Stack align={'center'}>
        <Image src={RolebotThumbsUp} fit={'contain'} width={150} />
        <Title order={2} mt={20}>
          Thank You!
        </Title>
        <Stack align={'center'} spacing={5}>
          <Text>We have received your message.</Text>
          <Text>We will be in touch soon!</Text>
        </Stack>
      </Stack>
      <Group position={'right'} mt={30}>
        <Button
          sx={{
            backgroundColor: 'transparent',
            color: '#838485',
            border: '1px solid #838485',
            paddingInline: 40,
          }}
          variant={'subtle'}
          onClick={onClose}
        >
          Close
        </Button>
      </Group>
    </div>
  );
};

export default SuccessLaunch;
