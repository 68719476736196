import React from 'react';
import { Group, Popover, Text } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';

interface InfoPopoverProps {
  message: string;
}

const InfoPopover = ({ message }: InfoPopoverProps) => {
  const [opened, setOpened] = React.useState(false);

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      position="right"
      placement="start"
      trapFocus={false}
      closeOnEscape={false}
      transition="pop-top-left"
      target={
        <Group align={'center'}>
          <InfoCircle
            color={'#fff'}
            fill={'#5190B4'}
            onMouseEnter={() => setOpened(true)}
            onMouseLeave={() => setOpened(false)}
          />
        </Group>
      }
      styles={{
        inner: {
          display: 'flex',
          padding: '8px 16px',
        },
        body: {
          maxWidth: '300px',
          color: '#000',
          backgroundColor: '#FFF',
        },
      }}
    >
      <Group>
        <Text size="sm">{message}</Text>
      </Group>
    </Popover>
  );
};

export default InfoPopover;
