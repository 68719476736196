import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  primaryColor: 'violet',
  primaryShade: 7,
  fontFamily: 'Helvetica',
  fontSizes: {
    xs: 10,
    sm: 12,
    md: 16,
    lg: 19,
    xl: 20,
  },
  colors: {
    violet: ['#efe6ff', '#ccb8fd', '#ab8af6', '#895bf1', '#2a0a77', '#D9A8FF', '#3c0ea5', '#7039ED', '#190549'],
    blue: ['#249FC8', '#249FC8', '#249FC8', '#249FC8', '#249FC8', '#249FC8', '#249FC8', '#249FC8', '#249FC8'],
    //these are colors used exclusively for avatar letter placeholders. do not use anywhere else.
    avatars: ['#46209E', '#7039ED', '#65A712', '#37870C', '#50B2FA', '#5068E9', '#3A37CA'],
  },
  headings: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    sizes: {
      h1: {
        fontSize: 40,
      },
      h2: {
        fontSize: 34,
      },
      h3: {
        fontSize: 28,
      },
      h4: {
        fontSize: 22,
      },
      h5: {
        fontSize: 19,
      },
      h6: {
        fontSize: 19,
      },
    },
  },
};

export default theme;
