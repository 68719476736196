import { faSort } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, Text, Popover, Group, Title, Stack } from "@mantine/core";
import useAppSelector from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { sortOption } from "types";

const useStyles = createStyles((theme, { isSelected, hasActiveSort, showFilters }: any) => ({
  wrapper: {
    margin: '20px 15px',
    minWidth: 320,
  },

  title: {
    color: '#242424',
  },

  button: {
    backgroundColor: hasActiveSort || showFilters ? '#EEEEEE' : 'transparent',
    color: hasActiveSort ? '#351B77' : '#838485',
    padding: 10,
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  item: {
    cursor: 'pointer',
    color: '#4F4F4F',
    backgroundColor: isSelected ? '#8241FD33' : 'transparent',
    padding: 10,
    paddingInline: 15,
    borderRadius: 10,
    '&:hover': {
      backgroundColor: isSelected ? '#8241FD2E' : '#EEEEEE',
      transition: 'background-color 0.2s ease-in-out',
    },
  },
  sortIcon: {
    height: '18px',
    paddingBottom: '2px'
  }
}));
interface SortsProps {
    activeSort: sortOption,
    onSortChange: (arg: sortOption) => void 
}

const Sorts = ({activeSort, onSortChange}: SortsProps) => {
  const [showFilters, setShowFilters] = useState(false);
  const hasActiveSort = activeSort !== 'default'
  const { classes } = useStyles({ isSelected: false, hasActiveSort, showFilters });

  const onClear = () => {
    onSortChange('default')
  }

  return (
    <Popover
      opened={showFilters}
      onClose={() => setShowFilters(false)}
      target={
        <Group spacing={5} onClick={() => setShowFilters(true)} className={classes.button}>
          <FontAwesomeIcon icon={faSort} fill={hasActiveSort ? '#351B77' : '#838485'} className={classes.sortIcon} />
          {hasActiveSort ? 'Sort / 1' : 'Sort'}
        </Group>
      }
      position="bottom"
      placement="start"
      styles={{
        body: {
          borderRadius: 6,
          border: '1px solid #DFE1E1',
          boxShadow: '0px 5px 15px rgba(131, 132, 133, 0.45)',
        },
        inner: { padding: 0 },
      }}
    >
      <div className={classes.wrapper}>
        <Group position={'apart'} mb={20}>
          <Title order={5} className={classes.title} ml={15}>
            Sort
          </Title>
          <Text
            mr={15}
            color={'#B3B3B3'}
            size={'md'}
            sx={{ cursor: 'pointer', paddingRight: 0 }}
            onClick={onClear}
          >
            Clear
          </Text>
        </Group>
        <Stack spacing={0}>
          <ListItem isSelected={activeSort === 'newest-role'} onClick={() => onSortChange('newest-role')}>
            Newest Role
          </ListItem>
          <ListItem isSelected={activeSort === 'oldest-role'} onClick={() => onSortChange('oldest-role')}>
            Oldest Role
          </ListItem>
          <ListItem isSelected={activeSort === 'A-Z'} onClick={() => onSortChange('A-Z')}>
            A-Z
          </ListItem>
          <ListItem isSelected={activeSort === 'Z-A'} onClick={() => onSortChange('Z-A')}>
            Z-A
          </ListItem>
        </Stack>
      </div>
    </Popover>
  );
}

interface ListItemProps {
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
}

const ListItem = ({ children, isSelected, onClick }: ListItemProps) => {
  const { classes } = useStyles({ isSelected });
  return (
    <Text className={classes.item} onClick={onClick}>
      {children}
    </Text>
  );
};

export default Sorts