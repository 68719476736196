import { Box, Avatar, Text, createStyles, Tooltip } from '@mantine/core';
import { AiOutlineClockCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { getTimeinHourOrDayByDate } from 'utils/dates';
import { getNameInitials } from 'utils';
import useRandomColor from 'hooks/useRandomColor';
import React from 'react';

const useStyles = createStyles((theme, { color }: any) => ({
  avatar: {
    width: '1vw',
    height: '4vh',
    borderRadius: 35,
    [theme.fn.largerThan('md')]: {
      width: '2vw',
      height: '4.2vh',
      borderRadius: 60,
      padding: '2px',
    },
  },
  placeholder: {
    width: '5vw',
    height: '4vh',
    borderRadius: 35,
    [theme.fn.largerThan('md')]: {
      width: '4vh',
      height: '4vh',
      borderRadius: 40,
    },
    h2: {
      color,
      fontSize: 14,
      [theme.fn.largerThan('md')]: {
        fontSize: 15,
        margin: '2px',
      },
    },
  },
  inactiveAvatar: {
    filter: 'brightness(210%)',
  },
  inactiveUser: {
    fontWeight: 'normal',
  },
}));
interface IImproveResultRow {
  roleData: any;
}

const ImproveResultRow = ({ roleData }: IImproveResultRow) => {
  const color = useRandomColor(roleData?.user?.id);
  const { classes } = useStyles({ color });

  const colors = ['pink', 'gray', 'grape', 'violet', 'indigo', 'blue', 'cyan', 'teal', 'green', 'lime'];
  return (
    <Box
      sx={{
        border: '1px solid #DFE1E1',
        borderRadius: '5px',
        marginBottom: '10px',
        padding: '10px 15px',
        position: 'relative',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar color={colors[Math.floor(Math.random() * colors.length)]} radius={'xl'}>
            <div className={roleData?.user?.deleted_at ? classes.inactiveAvatar : ''}>
              {getNameInitials(roleData?.user?.name).toUpperCase()}
            </div>
          </Avatar>
          <Text
            color={roleData?.user?.deleted_at ? '#B3B3B3' : '#242424'}
            sx={{
              fontSize: '16px',
              fontFamily: 'Roboto',
              marginLeft: '4px',
              paddingLeft: '5px',
              paddingTop: '5px',
            }}
            className={roleData?.user?.deleted_at ? classes.inactiveUser : ''}
          >
            {roleData?.user?.name} {roleData?.user?.deleted_at && (<> (Inactive)</>)}
          </Text>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', paddingRight: '15px' }}>
          {roleData?.processed_by_ai ?
            <Tooltip
              label="Analyzed by AI"
              sx={{ paddingTop: '5px', paddingRight: '5px' }}
            >
              <AiOutlineCheckCircle
                width={'20px'}
                color={'green'}
              />
            </Tooltip>
            : null}
          <AiOutlineClockCircle width={'20px'} color={'#C4C4C4'} />
          <Text color={'#B3B3B3'} sx={{ fontSize: '16px', marginLeft: '6px', paddingTop: '2px' }}>
            {getTimeinHourOrDayByDate(roleData?.created_at)}
          </Text>
        </div>
      </div>
      <Text
        color={'#4F4F4F'}
        sx={{
          fontSize: '16px',
          marginTop: '10px',
          marginLeft: '.4vw',
          fontWeight: 400,
          fontFamily: 'Helvetica',
          position: 'relative',
          zIndex: 0,
        }}
      >
        {roleData?.feedback}
      </Text>
    </Box>
  );
};

export default React.memo(ImproveResultRow);
