import { Avatar, CloseButton, createStyles, Group, Image, Navbar, Stack, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { LinksGroup } from './NavbarLinksGroup';
import { useGetCompanyQuery, useGetUserQuery, useSignOutMutation } from 'app/services/rolebot';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { toggleNavigation } from 'features/app/appSlice';
import { navbarRoutes } from 'router/routes';
import { getNameInitials } from 'utils';
import useOpenModal from 'hooks/useOpenModal';
import RolebotLogo from 'assets/img/rolebot-wordmark.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleQuestion, faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles(() => ({
  inner: {
    marginLeft: 20,
    marginRight: 20,
  },
}));

const MobileNavigation = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { data } = useGetUserQuery();
  const { data: company } = useGetCompanyQuery();
  const dispatch = useAppDispatch();
  const links = navbarRoutes.map((link) => <LinksGroup {...link} key={link.to} />);
  const [signOut] = useSignOutMutation();
  const { contactSupport } = useOpenModal();

  const onContactSupport = () => {
    closeNav();
    contactSupport();
  };

  const onOpenProfile = () => {
    closeNav();
    navigate('/profile');
  };

  const closeNav = () => {
    dispatch(toggleNavigation());
  };

  return (
    <Navbar styles={{ root: { top: 0, height: '100%' } }}>
      <Navbar.Section>
        <Group position="right" mb={5}>
          <CloseButton size={25} onClick={() => dispatch(toggleNavigation())} mt={15} mr={20} variant="transparent" />
        </Group>
      </Navbar.Section>
      <Navbar.Section>
        <Group mx={20}>
          <Avatar sx={{ width: 75, height: 75, borderRadius: 35 }}>
            <Title order={2}>{getNameInitials(data?.user.name)}</Title>
          </Avatar>
          <Stack spacing={0}>
            <Text sx={{ fontSize: 22, fontFamily: 'Roboto' }} color={'#242424'} lineClamp={1}>
              {data?.user.name}
            </Text>
            <Text color={'#838485'} size="lg" lineClamp={1}>
              {company?.name}
            </Text>
          </Stack>
        </Group>
      </Navbar.Section>
      <Navbar.Section grow mt={50}>
        <div className={classes.inner}>{links}</div>
      </Navbar.Section>
      <Navbar.Section mb={20}>
        <Stack>
          <Group mx={20}>
            <Group noWrap align={'center'} role={'button'} onClick={onOpenProfile} sx={{ fontSize: 19 }} spacing={14}>
              <FontAwesomeIcon icon={faUser} color={'#838485'} width={24} height={24} />
              <Text size="lg" color="#838485">
                My Profile
              </Text>
            </Group>
          </Group>
          <Group mx={20}>
            <Group
              noWrap
              align={'center'}
              role={'button'}
              onClick={onContactSupport}
              sx={{ fontSize: 19 }}
              spacing={14}
            >
              <FontAwesomeIcon icon={faCircleQuestion} color={'#838485'} width={24} height={24} />
              <Text size="lg" color="#838485">
                Contact Support
              </Text>
            </Group>
          </Group>
          <Group mx={20}>
            <Group noWrap align={'center'} role={'button'} onClick={() => signOut()} sx={{ fontSize: 19 }} spacing={14}>
              <FontAwesomeIcon icon={faArrowRightFromBracket} color={'#838485'} width={24} height={24} />
              <Text size="lg" color="#838485">
                Log out
              </Text>
            </Group>
          </Group>
        </Stack>
      </Navbar.Section>
      <Navbar.Section sx={{ width: 90, marginInline: 'auto', marginBottom: '20px' }}>
        <Image src={RolebotLogo} width={85} />
      </Navbar.Section>
    </Navbar>
  );
};

export default MobileNavigation;
