import { useCallback, useEffect, useState } from 'react';
import { RolebotClient } from "../../../../api/client";
import RolebotMultiselect from "components/Inputs/RolebotMultiSelect";
import { useDebouncedValue } from "@mantine/hooks";

interface SearchCriteriaJobTitlesProps {
  onJobTitlesChange: (payload: { name: string }[]) => void;
  jobTitles: any;
}

// transforms the data to a Mantine MultiSelect readable format
const formatDropdownItems = (items: { id: any; name: any; }[]) => {
  if (items?.length) {
    return items?.map((x: { id: any; value?: any; name: any; label?: any; pivot?: any }) => {
      return {
        value: x.id.toString(),
        label: x.name,
        id: x.id,
        name: x.name,
        is_from_feedback: x.pivot?.is_from_feedback,
        is_excluded: x.pivot?.is_excluded
      };
    });
  } else {
    return []
  }
}

const getUniqueListById = (arr: any) => {
  return [...new Map(arr.map((item: any) => [item['id'], item])).values()]
}

const SearchCriteriaJobTitlesExcluded = ({ onJobTitlesChange, jobTitles }: SearchCriteriaJobTitlesProps) => {
  const [jobTitleSearchTerm, setJobTitleSearchTerm] = useState("");
  const [availableJobTitles, setAvailableJobTitles] = useState(jobTitles?.length ? formatDropdownItems(jobTitles) : []);
  const [selectedTitles, setSelectedTitles] = useState(jobTitles ? jobTitles.map((title: any) => title.id.toString()) : []);
  const [debouncedSearch] = useDebouncedValue(jobTitleSearchTerm.trim(), 500);

  useEffect(() => {
    onJobTitlesChange(formatPayloadItems(selectedTitles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTitles]);

  useEffect(() => {
    if (jobTitleSearchTerm) searchForJobTitlesTag(jobTitleSearchTerm)
  }, [debouncedSearch])

  // transforms the data to a back to the expected from API format  e.g: id: 1, name: "Laravel"
  // applicable for both skills and job titles
  const formatPayloadItems = (items: string[]) => {
    if (items?.length) {
      return items?.map((id: string) => {
        let object = availableJobTitles.find((t: any) => t.id.toString() === id)
        return {
          id: object?.id ?? id,
          name: object?.name ?? id,
          is_from_feedback: object?.is_from_feedback,
          is_excluded: object?.is_excluded
        };
      });
    } else {
      return []
    }
  }

  const searchForJobTitlesTag = useCallback((searchTerm) => {
    (async () => {
      try {
        const { data } = await RolebotClient.get('/job-titles', { params: { 'search-term': searchTerm } });

        if (data.data && data.data.length) {
          // @ts-ignore
          setAvailableJobTitles((prev) => getUniqueListById([...prev, ...formatDropdownItems(data.data)]));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [debouncedSearch]);

  const copyToAvailable = (values: string[]) => {
    let selected = availableJobTitles.filter((x: any) => values.includes(x.value))

    setAvailableJobTitles(selected)
    setSelectedTitles(values)
  }

  return (
    <>
      <RolebotMultiselect
        data={availableJobTitles}
        searchable
        creatable
        value={selectedTitles}
        getCreateLabel={(query) => `+ Create ${query}`}
        onChange={copyToAvailable}
        nothingFound={'Type to search'}
        onSearchChange={setJobTitleSearchTerm}
        selectOnBlur={true}
        clearSearchOnBlur={true}
        clearSearchOnChange={true}
        style={{ width: '100%' }}
        maxSelectedValues={100}
        onBlur={() => {
          setAvailableJobTitles(availableJobTitles.filter((x: any) => selectedTitles.includes(x.value)));
        }}
      />
    </>
  );
};

export default SearchCriteriaJobTitlesExcluded;
