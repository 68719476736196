import React, { useMemo } from 'react';
import { Button, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { ContextModalProps } from '@mantine/modals';
import TitleWithClose from 'components/Modals/components/TitleWithClose';

const UpdatePayments = ({ id, context }: ContextModalProps) => {
  const navigate = useNavigate();
  const { data } = useGetCompanyQuery();

  const isAdmin = useMemo(() => {
    if (!data) return false;
    return data.customer_user.is_admin;
    // eslint-disable-next-line
  }, [data]);

  const onUpdateDetails = () => {
    if (isAdmin) {
      navigate('/settings/billing');
    }
    context.closeModal(id);
  };

  return (
    <Stack spacing={30}>
      <TitleWithClose id={id} title={'Update Payment Details'} mb={0} />
      {isAdmin ? (
        <Stack spacing={5}>
          <Text color={'#242424'}>
            We noticed we don’t have a credit card associated with your account. Please update your account’s payment
            details to continue
          </Text>
        </Stack>
      ) : (
        <Stack spacing={5}>
          <Text color={'#242424'}>
            We noticed we don’t have a credit card associated with your account. Please ask your administrator to update
            your account’s payment details.
          </Text>
        </Stack>
      )}
      <Button
        ml={'auto'}
        onClick={onUpdateDetails}
        variant={isAdmin ? 'filled' : 'default'}
        sx={{ color: isAdmin ? '#fff' : '#838485' }}
      >
        {isAdmin ? 'Update Payment Details' : 'Okay'}
      </Button>
    </Stack>
  );
};

export default UpdatePayments;
