import React from 'react';
import TalentDrawerSection from 'components/TalentDetail/TalentDrawerSection';
import { Group, Stack, Text, Image } from '@mantine/core';
import { ITalentSocialLink } from 'types';
import { sendGoogleEvent } from 'utils/analytics';
import LinkedinLogoSquare from 'assets/img/logos/linkedin_logo_square.svg';
import IndeedLogoSquare from 'assets/img/logos/indeed_logo_square.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

interface TalentSocialLinkProps {
  linkedIn?: string;
  links?: ITalentSocialLink[];
}

const TalentSocialLinks = ({ links = [], linkedIn }: TalentSocialLinkProps) => {
  let linkedinLink: ITalentSocialLink = {
    social_link: linkedIn || '',
    talent_id: 0,
    id: -1,
    created_at: new Date(),
    updated_at: new Date(),
  };

  const _links = [linkedinLink, ...links];

  const handleLinkClick = (link: string) => {
    if (link.includes('linkedin')) sendGoogleEvent('User', 'User opened linkedin profile from inside talent profile');
  };

  return (
    <TalentDrawerSection title={'Social Links'}>
      <Stack spacing={5}>
        {_links?.length &&
          _links.map((link) => (
            <Group align={'flex-start'} spacing={8} noWrap key={link.id}>
              <Group sx={{ width: 17, justifyContent: 'left' }} mb={2} mr={10}>
                <>
                  {link.social_link.includes('linkedin') ? (
                    <Image src={LinkedinLogoSquare} width={22} height={22} />
                  ) : link.social_link.includes('indeed') ? (
                    <Image src={IndeedLogoSquare} width={22} height={22} />
                  ) : (
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ height: '16px', color: '#4F4F4F', marginTop: '3px', marginLeft: '3px' }} />
                  )}
                </>
              </Group>
              <Group noWrap align={'center'}>
                <Text<'a'>
                  component={'a'}
                  color={'gray'}
                  lineClamp={1}
                  href={link.social_link}
                  target={'_blank'}
                  onClick={() => handleLinkClick(link.social_link)}
                >
                  {link.social_link}
                </Text>
              </Group>
            </Group>
          ))}
      </Stack>
    </TalentDrawerSection>
  );
};

export default TalentSocialLinks;
