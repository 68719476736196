import React, { useState } from 'react';
import moment from 'moment';
import { RolebotClient } from 'api/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-light-svg-icons';
import { Group, Text, createStyles } from '@mantine/core';
import { calculatePercent, number_format } from '../../../pages/Metrics/metricsHelpers';

const useStyles = createStyles((theme) => ({
  downloadIcon: {
    color: '#838485',
    height: '16px',
    paddingBottom: '2px',
    paddingRight: '4px'
  }
}));

const RoleManagerDownLoadButton = ({ companyId }: { companyId: number }) => {
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();

  const exportRoleInfo = () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const payload = {
      'company-id': companyId,
      users: 1,
      info: 1,
      contacts: 1,
      page: 1,
      'per-page': 10000,
      'include-ats': 1,
    };
    RolebotClient.get('/role-stats-data', { params: payload })
      .then(({ data }) => {
        setLoading(false);
        exportData(data.roles.data);
      })
      .catch((e) => console.log(e));
  };

  const exportData = (data: any) => {
    const rows = [
      [
        'role id',
        'role name',
        'role location',
        'status',
        'type of role',
        'talent surfaced',
        'awaiting review',
        'interviewer',
        'confirmed interviews',
        'users in role',
        'greenhouse Req Id',
        'sourced percentage',
        'accepted percentage',
        'created by',
        'created on',
        'last reviewed',
      ],
    ];

    const payload = rows.concat(
      data.map((x: any) => {
        let status;
        switch (true) {
          case parseInt(x.completed) === 1:
            status = 'Completed';
            break;
          case parseInt(x.on_hold) === 1:
            status = 'On Hold';
            break;
          case parseInt(x.show_info) === 1 || x.force_card:
            status = 'Waiting For Info';
            break;
          default:
            status = 'Active';
        }

        const interviewer = x?.contacts
          ? JSON.parse(x?.contacts).find((x: any) => x.type === 'interviewer')?.name.replaceAll(',', ' - ')
          : '';

        const users = JSON.parse(x.users)
          ?.filter((x: any) => !/@rolebot\.io/.test(x.email))
          .map((x: any) => x.email)
          .join(':');

        const locationNames = ( (x?.offices) && (JSON.parse(x?.offices).length > 0)) ? [ '\"' + JSON.parse(x?.offices).map((x: any) => x.formatted_location).join(',') + '\"'] : []

        const roleLocation = locationNames.length > 0 ? locationNames : `"${x?.office_locations}"`;

        const sourcedPercentage = number_format(
          calculatePercent(x.talent_diverse_count, x.talent_surfaced)
        );

        const acceptedPercentage = number_format(
          calculatePercent(x.talent_diverse_accepted_count, x.talent_diverse_count)
        );

        const createdBy = JSON.parse(x.users)?.find((user: any) => user.id === parseInt(x.created_by))?.name.replaceAll(',', ' - ');

        return [
          x.id,
          x.name.replaceAll(',', ' - '),
          roleLocation,
          status,
          x.sourcing_only ? 'Sourcing only' : 'Turnkey',
          x.talent_surfaced,
          x.talent_waiting_count,
          interviewer,
          x.talent_interview_count,
          users,
          x.greenhouse_requisition_id ? x.greenhouse_requisition_id : 'n/a',

          sourcedPercentage || 'n/a',

          acceptedPercentage || 'n/a',

          createdBy,
          moment.utc(x.created_at).format(),
          x.talent_reviewed_date ? moment.utc(x.talent_reviewed_date).format() : '',
        ];
      })
    );

    let csvContent = payload.map((e) => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    //@ts-ignore
    if (navigator.msSaveBlob) {
      // IE 10+
      //@ts-ignore
      navigator.msSaveBlob(blob, `role_export.csv`);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `role_export.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <Group
      noWrap
      spacing={4}
      sx={{
        padding: 10,
        borderRadius: 6,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#EEEEEE',
        },
      }}
      onClick={exportRoleInfo}
    >
      {/* <FontAwesomeIcon icon={faArrowDownToLine} width={20} height={20} fill={'#838485'} /> */}
      <FontAwesomeIcon icon={faArrowDownToLine} className={classes.downloadIcon} />
      <Text color={'#838485'}>Export</Text>
    </Group>
  );
};

export default RoleManagerDownLoadButton;
