import React from 'react';
import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps } from '@mantine/modals';
import { LeverAuthExpiredProps } from '../../../types/modals';
import { useNavigate } from 'react-router-dom';
import { Button, Group, Text } from '@mantine/core';

const LeverAuthExpired = ({ id, context, innerProps }: ContextModalProps<LeverAuthExpiredProps>) => {
  const navigate = useNavigate();
  const { isLeverAdmin, onClose } = innerProps;

  const handleButtonClicked = () => {
    if(isLeverAdmin){
      navigate('/settings/integrations');
      context.closeModal(id);
    } else {
      context.closeModal(id);
    }
    if(onClose) {
      setTimeout(() => onClose(), isLeverAdmin ? 60000 : 200)
    }
  }

  return (
    <>
      <TitleWithClose id={id} title={'LeverAuthExpired'} onClose={onClose}/>
      {innerProps.isLeverAdmin ? (
        <Text mb={20} color={'#242424'}>
          Your company’s Lever authentication token has expired. Please re-authenticate to continue using the integration.
        </Text>
      ) : (
        <>
          <Text mb={20} color={'#242424'}>
            Your company’s Lever authentication token has expired. All Lever features are currently unavailable.
          </Text>
          <Text mb={20} color={'#242424'}>
            Please ask one of your company’s Lever Super Admins to re-authenticate the app, to continue using the integration.
          </Text>
        </>
      )}
      <Group position={'right'} mt={40}>
        <Button onClick={handleButtonClicked}>
          {isLeverAdmin ? 'Authenticate' : 'Close'}
        </Button>
      </Group>
    </>
  );
};

export default LeverAuthExpired;