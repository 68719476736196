import React from 'react';
import { useModals } from '@mantine/modals';
import { Button, Group, Image, Stack, Text, Title } from '@mantine/core';
import RolebotThumbsUp from 'assets/img/rolebot-thumbs-up.svg';

type SuccessRoleUsersProps = {
  onClose: () => void;
  onClick: () => void;
};

const SuccessRoleUsers = ({ onClose, onClick }: SuccessRoleUsersProps) => {
  useModals();

  return (
    <div style={{ overflow: 'hidden' }}>
      <Stack align={'center'}>
        <Image src={RolebotThumbsUp} fit={'contain'} width={150} />
        <Title order={2} mt={20}>
          Users have been invited to the role
        </Title>
        <Stack align={'center'} spacing={5}>
          <Text>The users you selected have been added to the role</Text>
        </Stack>
      </Stack>
      <Group position={'center'} mt={30}>
        <Button
          sx={{
            backgroundColor: 'transparent',
            color: '#838485',
            border: '1px solid #838485',
            paddingInline: 40,
          }}
          variant={'subtle'}
          onClick={onClose}
        >
          Close
        </Button>
      </Group>
    </div>
  );
};

export default SuccessRoleUsers;
