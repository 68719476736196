import { AppShell, Center, createStyles, Group, Header, Image, Navbar, Text, Transition } from '@mantine/core';
import { ChevronLeft, Menu2, Plus } from 'tabler-icons-react';
import Logo from 'assets/img/logos/rolebot-logo.png';
import { matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom';
import MobileNavigation from './MobileNavigation';
import { useMediaQuery } from '@mantine/hooks';
import useAppSelector from 'hooks/useAppSelector';
import { closeTalentMenu, toggleNavigation, toggleRoleMenu } from 'features/app/appSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { navbarRoutes } from 'router/routes';
import NavbarUserMenu from 'components/NavbarUserMenu/NavbarUserMenu';
import useOpenModal from 'hooks/useOpenModal';
import { useGetRoleByIdQuery, useGetCompanyQuery } from 'app/services/rolebot';
import RoleMenu from 'components/RoleMenu/RoleMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useShowWarningModals from '../../hooks/useShowWarningModals';
import useShowModals from 'hooks/useShowModals';

const useStyles = createStyles((theme) => ({
  link: {
    backgroundColor: '#261755',
    width: 47,
    height: 47,
    borderRadius: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    marginBottom: 20,
    transition: '0.15s',
    ':hover': {
      backgroundColor: '#7039ED66',
    },
    svg: {
      color: '#DFE1E1',
      ':hover': {
        color: '#FFF',
      },
    },
  },

  active: {
    backgroundColor: '#7039ED',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.45)',
    ':hover': {
      backgroundColor: '#7039ED',
    },
    svg: {
      color: '#FFF',
    },
  },

  inner: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  desktopNavbar: {
    borderRight: 'none',
    width: 68,
    backgroundColor: '#24154F',
  },

  navbar: {
    zIndex: 100,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
  },

  mobileNav: {
    backgroundColor: '#1E1650',
    color: 'white',
  },

  title: {
    fontSize: 22,
    lineHeight: 1.3,
    wordBreak: 'keep-all',
    paddingInline: 10,
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    textAlign: 'center',
    [theme.fn.largerThan('md')]: {
      paddingInline: 20,
      fontSize: 22,
    },
  },

  iconWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  noPadding: {
    paddingLeft: 16,
    paddingRight: 16,
    [theme.fn.smallerThan('md')]: {
      paddingInline: 0,
    },
  },

  root: {
    top: 0,
  },

  navIcon: {
    height: '24px',

  }
}));

interface NavbarLinkProps {
  icon: any;
  label: string;
  active?: boolean;
  to: string;
  onClick?(): void;
}

function NavbarLink({ icon, onClick, to }: NavbarLinkProps) {
  const { classes, cx } = useStyles();

  return (
    <NavLink onClick={onClick} className={({ isActive }) => cx(classes.link, { [classes.active]: isActive })} to={to}>
      <Group sx={{ justifyContent: 'center', display: 'flex' }}>
        <FontAwesomeIcon icon={icon} className={classes.navIcon} />
      </Group>
    </NavLink>
  );
}

const SiteWrapper = ({ children }: any) => {
  const showHeader = useMediaQuery('(max-width: 992px)');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const roleMenuOpen = useAppSelector((state) => state.app.roleMenuOpen);
  const currentPath = useAppSelector((state) => state.router.location?.pathname);
  const mobileNavOpen = useAppSelector((state) => state.app.mobileNavOpen);
  const talentMenuOpen = useAppSelector((state) => state.app.talentMenuOpen);
  const { launchRole, roleLimitReachedModal } = useOpenModal();
  const { pathname } = useLocation();
  const isRolePath = matchPath({ path: '/roles/:roleId' }, pathname);
  const isProfilePath = matchPath({ path: '/profile' }, pathname);
  const isMetricsPath = matchPath({ path: '/metrics' }, pathname);
  const { data: role } = useGetRoleByIdQuery(isRolePath?.params?.roleId!, {
    skip: !isRolePath,
  });
  const { data: company } = useGetCompanyQuery();
  const { classes } = useStyles();

  useShowModals();

  const links = navbarRoutes.map((link) => <NavbarLink {...link} key={link.label} />);

  const leftAction = () => {
    if (talentMenuOpen) {
      dispatch(closeTalentMenu());
      return;
    }
    if (isRolePath) {
      navigate(-1);
      return;
    }
    dispatch(toggleNavigation());
  };

  const menuName = () => {
    if (isRolePath) {
      return role?.name;
    }
    if (isProfilePath) {
      return 'My Profile';
    }
    return currentPath?.slice(1);
  };

  const rightAction = () => {
    if (isRolePath) {
      roleMenuOpen ? dispatch(toggleRoleMenu(false)) : dispatch(toggleRoleMenu(true));
      return;
    }
    if (isMetricsPath) {
      return;
    }

    if (company?.active_role_limit && company?.active_role_count >= company?.active_role_limit) {
      roleLimitReachedModal({ company: company });
    } else {
      launchRole({
        onClose: () => { }
      });
    }
  };

  const rightIcon = () => {
    if (talentMenuOpen || isProfilePath || isMetricsPath) return null;

    if (isRolePath) {
      return (
        <RoleMenu
          roleId={isRolePath?.params?.roleId!}
          roleName={role?.name || ''}
          role={role}
        />
      );
    }

    return <Plus />;
  };

  const leftIcon = () => {
    if (isRolePath || talentMenuOpen) return <ChevronLeft color={'white'} />;
    return <Menu2 color={'white'} />;
  };

  return (
    <AppShell
      fixed
      styles={(theme) => ({
        root: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        body: {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          marginTop: 'var(--mantine-header-height)',
          [theme.fn.largerThan('md')]: {
            marginTop: 0,
            flexDirection: 'row',
          },
        },
        main: {
          flex: 1,
          paddingTop: 0,
          paddingLeft: 0,
          minHeight: 'unset',
          [theme.fn.largerThan('md')]: {
            marginTop: 0,
            marginLeft: 'var(--mantine-navbar-width)',
          },
        },
      })}
      padding={0}
      navbar={
        <Navbar width={{ base: 68 }} classNames={{ root: classes.desktopNavbar }} hiddenBreakpoint="md" hidden={true}>
          <Center className={classes.iconWrapper} mt={40}>
            <NavLink to={'/roles'}>
              <Image
                src={Logo}
                fit="contain"
                width={30}
                height={'auto'}
                sx={{
                  filter:
                    'drop-shadow(0px 0px 20px rgba(112, 57, 237, 0.9)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.5))',
                }}
              />
            </NavLink>
          </Center>
          <Navbar.Section grow mt={40}>
            <Group direction="column" align="center" spacing={0}>
              {links}
            </Group>
          </Navbar.Section>
          <Navbar.Section>
            <NavbarUserMenu />
          </Navbar.Section>
        </Navbar>
      }
      header={
        showHeader ? (
          <Header height={56} className={classes.mobileNav}>
            <Group
              spacing={0}
              position={'apart'}
              noWrap
              align={'center'}
              sx={{ height: 'var(--mantine-header-height)' }}
            >
              <Group ml={10} onClick={leftAction} noWrap align={'center'} sx={{ width: 40 }}>
                {leftIcon()}
              </Group>
              <Text className={classes.title} lineClamp={1}>
                {menuName()}
              </Text>
              <Group
                mr={10}
                onClick={rightAction}
                id={'sidebar-right-content'}
                spacing={0}
                sx={{ width: 40, justifyContent: 'right' }}
              >
                {rightIcon()}
              </Group>
            </Group>
            <Transition mounted={mobileNavOpen} transition="slide-right" duration={200} timingFunction="ease">
              {(styles) => (
                <div style={styles} className={classes.navbar}>
                  <MobileNavigation />
                </div>
              )}
            </Transition>
          </Header>
        ) : (
          <></>
        )
      }
    >
      {children}
    </AppShell>
  );
};

export default SiteWrapper;
