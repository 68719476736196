import { Text } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { RolebotClient } from "../../../../api/client";
import { useDebouncedValue } from '@mantine/hooks';
import RolebotMultiselect from 'components/Inputs/RolebotMultiSelect';

interface SearchCriteriaLanguagesProps {
  onLanguagesChange: (payload: object) => void;
  languages: any;
}

// transforms the data to a Mantine MultiSelect readable format
const formatDropdownItems = (items: { id: any; name: any; }[]) => {

  if (items?.length) {
    return items?.map((x: { id: any; value?: any; name: any; label?: any; }) => {
      return {
        value: x.id.toString(),
        label: x.name,
        id: x.id,
        name: x.name
      };
    });
  } else {
    return []
  }
}

const getUniqueListById = (arr: any) => {
  return [...new Map(arr.map((item:any) => [item['id'], item])).values()]
}

const SearchCriteriaLanguages = ({ onLanguagesChange, languages }: SearchCriteriaLanguagesProps) => {
  const [languageSearchTerm, setLanguageSearchTerm] = useState("");
  const [availableLanguages, setAvailableLanguages] = useState(languages.length ? formatDropdownItems(languages) : []);
  const [selectedLanguages, setSelectedLanguages] = useState(languages.map( (lang:any) => lang.id.toString()));
  const [debouncedSearch] = useDebouncedValue(languageSearchTerm.trim(), 500);
  const [noMatches, setNoMatches] = useState(false)

  useEffect(() => {
    onLanguagesChange(formatPayloadItems(selectedLanguages));
  }, [selectedLanguages]);

  useEffect(() => {
    if ( languageSearchTerm ) searchForLanguageTag(languageSearchTerm)
  }, [debouncedSearch])

  // transforms the data to a back to the expected from API format  e.g: id: 1, name: "Laravel"
  // applicable for all search criteria variables
  const formatPayloadItems = (items: string[]) => {

    if (items?.length) {
      return items?.map((id:string) => {
        let object = availableLanguages.find((l:any) => l.id.toString() === id)
        return {
          id: object?.id ?? id,
          name: object?.name ?? id,
        };
      });
    } else {
      return []
    }
  }

  const searchForLanguageTag = useCallback((searchTerm) => {
    (async () => {
      setNoMatches(false)
      try {
        const { data } = await RolebotClient.get('/languages', { params: { 'search-term': searchTerm } });

        if (data.data && data.data.length > 0) {
          // @ts-ignore
          setAvailableLanguages((prev) => getUniqueListById([...prev, ...formatDropdownItems(data.data)]));
        } else {
          setNoMatches(true)
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [debouncedSearch]);

  const copyToAvailable = (values: string[]) => {

    let selected = availableLanguages.filter( (x:any) => values.includes(x.value))

    setAvailableLanguages(selected)
    setSelectedLanguages(values)
  }

  return (
    <>
      
      <RolebotMultiselect
        data={availableLanguages}
        searchable
        value={selectedLanguages}
        getCreateLabel={(query) => `+ Create ${query}`}
        onChange={copyToAvailable}
        nothingFound={noMatches ? (<span>No matches found for <strong style={{color:'#242424'}}>{languageSearchTerm}</strong></span>) : 'Type to search'}
        onSearchChange={setLanguageSearchTerm}
        selectOnBlur={true}
        clearSearchOnBlur={true}
        clearSearchOnChange={true}
        style={{width: '100%'}}
        maxSelectedValues={100}
        onBlur={() => {
          setNoMatches(false);
          setAvailableLanguages(availableLanguages.filter( (x:any) => selectedLanguages.includes(x.value)));
        }}
      />
      
    </>
  );
};

export default SearchCriteriaLanguages;
