import {Group, Box, Loader, LoadingOverlay, Stack, Text, TextInput, Textarea} from '@mantine/core';
import { useForm } from 'react-hook-form';
import FormNavigation from 'components/Modals/RoleInfo/FormNavigation';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useEffect, useState } from 'react';
import RolebotTextInput from 'components/Inputs/RolebotTextInput';

interface StepOneProps {
  next: (payload: object) => void;
  save: (payload: object) => void;
  values: { first_profile_link: string; second_profile_link: string, ideal_talent_expectations: string };
  isFetching: boolean;
  isSubmitting: boolean;
  showFinishLater: boolean;
  needsUpdate: boolean;
  isLoading: boolean;
}

const StepOneSchema = z
  .object({
    first_profile_link: z
      .string()
      .max(255, 'Must be under 255 chars')
      .regex(/^(https?):\/\/[^\s$.?#].[^\s,*!@^{}\[\];'"]*$/gm, 'Must be a valid URL')
      .regex(
        /^(?!https:\/\/www\.linkedin\.com\/talent\/profile\/).+$/,
        'Must be a public URL e.g. "https://www.linkedin.com/johndoe".'
      )
      .regex(
        /^(?!https:\/\/(drive\.google\.com|docs\.google\.com\/document\/u\/0\/\?tgif=d|docs\.google\.com\/document\/d\/.*)).+/,
        'Must be a public URL e.g. "https://www.linkedin.com/johndoe". Google Drive is not supported at this time'
      )
      .optional()
      .or(z.literal('')),
    second_profile_link: z
      .string()
      .max(255, 'Must be under 255 chars')
      .regex(/^(https?):\/\/[^\s$.?#].[^\s,*!@^{}\[\];'"]*$/gm, 'Must be a valid URL')
      .regex(
        /^(?!https:\/\/www\.linkedin\.com\/talent\/profile\/).+$/,
        'Must be a public URL e.g. "https://www.linkedin.com/johndoe".'
      )
      .regex(
        /^(?!https:\/\/(drive\.google\.com|docs\.google\.com\/document\/u\/0\/\?tgif=d|docs\.google\.com\/document\/d\/.*)).+/,
        'Must be a public URL e.g. "https://www.linkedin.com/johndoe". Google Drive is not supported at this time'
      )
      .optional()
      .or(z.literal('')),
    ideal_talent_expectations: z
        .string()
        .max(500, 'Must be under 500 chars')
        .optional()
        .or(z.literal('')),
  })
  .refine((data) => data.first_profile_link !== data.second_profile_link, {
    message: "Links can't be the same",
    path: ['second_profile_link'],
  });

const InitialDataStep = ({ next, save, values, isFetching, isSubmitting, showFinishLater, needsUpdate, isLoading }: StepOneProps) => {
  const { first_profile_link, second_profile_link, ideal_talent_expectations } = values;
  const [firstRun, setFirstRun] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      first_profile_link,
      second_profile_link,
      ideal_talent_expectations
    },
    resolver: zodResolver(StepOneSchema),
  });

  const [firstProfileLinkWatcher, secondProfileLinkWatcher, idealTalentExpectations] =
      watch(['first_profile_link', 'second_profile_link', 'ideal_talent_expectations']);

  //In some instances the role info data could be loading and we would need to reset the form to get new values
  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }

    reset({
      first_profile_link,
      second_profile_link,
      ideal_talent_expectations
    });
    // eslint-disable-next-line
  }, [first_profile_link, second_profile_link]);

  useEffect(() => {
    if (errors?.second_profile_link?.message === "Link's can't be the same") {
      clearErrors('second_profile_link');
    }

    if(firstProfileLinkWatcher !== "" && secondProfileLinkWatcher !== "" && firstProfileLinkWatcher === secondProfileLinkWatcher) {
      errors.second_profile_link = {
        type: 'manual',
        message: "Link's can't be the same",
      };
    }
  }, [firstProfileLinkWatcher, secondProfileLinkWatcher]);

  const firstProfileLinkLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>Link to first ideal candidate</Text>
    </Group>
  );

  const secondProfileLinkLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>Link to second ideal candidate</Text>
    </Group>
  );

  return (
    <>
      <Box mb={20}>
        <LoadingOverlay visible={isFetching} loader={<Loader size={'xl'} variant={'bars'} color={'#6852DB'} />} />
        <Stack spacing={0}>
          <Text size={'md'} mb={20} color={'#242424'}>
            Let's get the search started!
          </Text>
          <Text mb={20} color={'#242424'}>
            Provide 2 URLs of your ideal candidates, e.g. someone that already works at your company.
          </Text>
          <Stack>
            <RolebotTextInput
              placeholder={'https://www.linkedin.com/johndoe '}
              label={firstProfileLinkLabel}
              disabled={!showFinishLater || needsUpdate}
              {...register('first_profile_link', {
                setValueAs: (value) => value.replace(/\/+$/, ''),
              })}
              error={errors?.first_profile_link?.message}
            />
            <RolebotTextInput
              placeholder={'https://www.janedoe.com '}
              label={secondProfileLinkLabel}
              disabled={!showFinishLater || needsUpdate}
              {...register('second_profile_link', {
                setValueAs: (value) => value.replace(/\/+$/, ''),
              })}
              error={errors?.second_profile_link?.message}
            />
          </Stack>
        </Stack>
      </Box>
      <div style={{marginBottom: '2px'}}>
      <FormNavigation
        step={1}
        next={handleSubmit(next)}
        save={handleSubmit(save)}
        isSubmitting={isSubmitting}
        isSaveEnabled={!isValid}
        showFinishLater={showFinishLater}
        isNextDisabled={!isValid || firstProfileLinkWatcher === '' || secondProfileLinkWatcher === '' || isLoading}
      />
      </div>
    </>
  );
};

export default InitialDataStep;
