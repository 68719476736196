import { Text } from '@mantine/core';
import {useCallback, useEffect, useState} from 'react';
import {RolebotClient} from "../../../../api/client";
import { useDebouncedValue } from '@mantine/hooks';
import RolebotMultiselect from 'components/Inputs/RolebotMultiSelect';


interface SearchCriteriaKeywordsProps {
  onKeywordsChange: (payload: { name: string }[]) => void;
  keywords: any;
}

// transforms the data to a Mantine MultiSelect readable format
const formatDropdownItems = (items: { id: any; name: any; }[]) => {

  if (items?.length) {
    return items?.map((x: { id: any; value?: any; name: any; label?: any; }) => {
      return {
        value: x.id.toString(),
        label: x.name,
        id: x.id,
        name: x.name
      };
    });
  } else {
    return []
  }
}

const SearchCriteriaKeywords = ({ onKeywordsChange, keywords }: SearchCriteriaKeywordsProps) => {
  const [keywordSearchTerm, setKeywordSearchTerm] = useState("");
  const [availableKeywords, setAvailableKeywords] = useState(keywords.length ? formatDropdownItems(keywords) : []);
  const [selectedKeywords, setSelectedKeywords] = useState(keywords.map( (keyw:any) => keyw.id.toString()));
  const [debouncedSearch] = useDebouncedValue(keywordSearchTerm.trim(), 500);


  useEffect(() => {
    onKeywordsChange(formatPayloadItems(selectedKeywords));
  }, [selectedKeywords]);

  useEffect(() => {
    if ( keywordSearchTerm ) searchForKeywordTag(keywordSearchTerm)
  }, [debouncedSearch])

  // transforms the data to a back to the expected from API format  e.g: id: 1, name: "Laravel"
  // applicable for all search criteria variables
  const formatPayloadItems = (items: string[]) => {

    if (items?.length) {
      return items?.map((id: string) => {
        let object = availableKeywords.find((s:any) => s.id.toString() === id)
        return {
          id: object?.id ?? id,
          name: object?.name ?? id,
        };
      });
    } else {
      return []
    }
  }

  const searchForKeywordTag = useCallback((searchTerm) => {
    // ( async () => {
    //   try {
    //     const { data } = await RolebotClient.get('/job-titles', { params: { 'search-term': searchTerm } });
    //
    //     if (data.data && data.data.length) {
    //       // @ts-ignore
    //       setAvailableJobTitles((prevJobTitles) => formatDropdownItems([...prevJobTitles, ...data.data]));
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // })();
  }, [debouncedSearch]);


  return (
      <>
        <RolebotMultiselect
          data={availableKeywords}
          searchable
          creatable
          value={selectedKeywords}
          getCreateLabel={(query) => `+ Create ${query}`}
          onChange={setSelectedKeywords}
          nothingFound={'Type to search'}
          onSearchChange={setKeywordSearchTerm}
          selectOnBlur={true}
          clearSearchOnBlur={true}
          clearSearchOnChange={true}
          style={{width: '100%'}}
          maxSelectedValues={100}
          onBlur={() => {
            setAvailableKeywords(availableKeywords.filter( (x:any) => selectedKeywords.includes(x.value)));
          }}
        />
      </>
  );
};

export default SearchCriteriaKeywords;
