import { ContextModalProps, useModals } from "@mantine/modals";
import { Stack, Text, Image, createStyles, BackgroundImage, Box, Group } from "@mantine/core"
import TitleWithClose from "../components/TitleWithClose";
import { AnnouncementsProps } from "types/modals";
import { BorderRadius, X } from "tabler-icons-react";
import RolebotButton from "components/public/Buttons/RolebotButton";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
    media: {
        maxWidth: '550px',
        height: 'auto',
        BorderRadius: '5px 5px 5px 5px',
        '@media (max-width: 800px)':{
            maxWidth: '100%'
        }
    },

    mediaBox: {
        position: "relative",
        display: 'flex',
        justifyContent: 'center'
    },

    closeBtn: {
        zIndex: 10,
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'rgb(255, 255, 255, .3)',
        borderRadius: '50px',
        padding: '2px',
        display: 'flex',
        alignItems: 'center'
    },
    mainContent: {
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '550px',
        paddingBottom: '20px',
        '@media (max-width: 800px)':{
            width: '100%'
        }

    }
}));

const DEFAULT_MODAL_TRANSITION_TIME = 200

const isURL = (url : string) : boolean =>{
    try{
        new URL(url)
        return true
    } catch (err) {
        return false
    }
}

const Announcements = ({ id, context, innerProps }: ContextModalProps<AnnouncementsProps>) => {
    const modals = useModals()
    const navigate = useNavigate();
    const { classes } = useStyles();
    const {header, content, primary_button_text, primary_button_url, neutral_button_text, media_url, timeout, onClose} = innerProps

    const handlePrimaryButtonClick = () => {
        if(isURL(primary_button_url)) {
            window.open(primary_button_url, "_blank")
        } else {
            navigate(primary_button_url)
        }
        context.closeModal(id);
        let timer = setTimeout(() => {
            onClose()
        }, timeout ? (timeout*1000) : DEFAULT_MODAL_TRANSITION_TIME)
    }

    const handleNeutralButtonClick = () => {
        context.closeModal(id);
        setTimeout(() => onClose(), DEFAULT_MODAL_TRANSITION_TIME)
    }

    return (
        <Stack>
            {media_url && (
            <div className={classes.mediaBox}>
                <img className={classes.media} src={media_url}/>
                <div className={classes.closeBtn}>
                    <X onClick={handleNeutralButtonClick} cursor={'pointer'} color={'#838485'} size={20} />
                </div>
                
                
            </div>)}
            <div className={classes.mainContent}>
                <div style={{paddingBottom: '40px'}}>
                    <Text size="lg" style={{paddingBottom: '20px'}}>{header}</Text>
                    <Text>{content}</Text>
                </div>
                <div>
                    <Group position="right">
                        {neutral_button_text && (<RolebotButton onClick={handleNeutralButtonClick} type='neutral' >{neutral_button_text}</RolebotButton>)}
                        {primary_button_text && (<RolebotButton onClick={handlePrimaryButtonClick}>{primary_button_text}</RolebotButton>)}
                    </Group>
                </div>
            </div>      
        </Stack>
    )
}

export default Announcements