import React from 'react';
import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps } from '@mantine/modals';
import { Button, Text, Group } from '@mantine/core';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { useNavigate } from 'react-router-dom';
import { CronofyAuthExpiredProps } from 'types/modals';

const CronofyAuthExpired = ({ id, context, innerProps }: ContextModalProps<CronofyAuthExpiredProps>) => {
  const baseURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const { onClose } = innerProps

  const handlePrimaryButtonClick = () => {
    window.open(`${baseURL}/oauth/cronofy/redirect`, "_blank")
    context.closeModal(id);
    if(onClose){
      setTimeout(() => onClose(), 60000)
    }
  }

  return (
    <>
      <TitleWithClose id={id} title={'Calendar Authentication Expired'} onClose={onClose} />
      <Text mb={20} color={'#242424'}>
        Your calendar authentication token has expired. Please re-authenticate to continue using the integration.
      </Text>
      <Group position={'right'} mt={40}>
      <RolebotButton onClick={handlePrimaryButtonClick}>Authenticate</RolebotButton>
      </Group>
    </>
  );
};

export default CronofyAuthExpired;