import React from 'react';
import { Group, Text, Tooltip } from '@mantine/core';

interface RoleInfoIconProps {
  hasData: boolean;
  amount?: number;
  name: string;
  type?: 'talent-count' | 'interviews';
  className?: string;
  children: React.ReactNode;
}

const RoleInfoIcon = ({ hasData, amount, children, name, type, className }: RoleInfoIconProps) => {
  return (
    <Tooltip
      label={<span>{name}</span>}
      styles={{
        body: {
          fontSize: 12,
          fontFamily: 'Helvetica',
        },
      }}
      mr={20}
      placement={'center'}
    >
      <Group
        className={className}
        spacing={4}
        sx={{
          minWidth: 80,
          color: hasData ? '#838485' : '#DFE1E1',
          flexWrap: 'nowrap',
        }}
        direction={'row'}
        noWrap
        align={'center'}
        position={'left'}
      >
        <Group>{children}</Group>
        <Text size={'md'} color={hasData ? '#838485' : '#DFE1E1'} sx={{ wordBreak: 'keep-all' }}>
          {isNaN(amount as number) ? ' ' : type === 'talent-count' && Number(amount) > 0 ? '+ ' + amount : ' ' + amount}
        </Text>
      </Group>
    </Tooltip>
  );
};

export default RoleInfoIcon;
