import React, { useReducer } from 'react';
import { Checkbox, createStyles, Group, Popover, Text, Stack } from '@mantine/core';
import { sendGoogleEvent } from '../../utils/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme, state) => ({
  button: {
    color: state.filterByActiveRoles || state.filterByCompletedRoles ? '#351B77' : '#838485',
    backgroundColor: state.filterByActiveRoles || state.filterByCompletedRoles ? '#EEEEEE' : 'transparent',
    padding: 10,
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  filterIcon: {
    height: '16px',
    marginBottom: '4px',
  },
}));

function reducer(state, action) {
  switch (action.type) {
    case 'showFiltersIsChanged':
      return {
        ...state,
        showFilters: action.payload,
      };
    case 'filterByActiveRolesChanged':
      return {
        ...state,
        filterByActiveRoles: action.payload,
        filterByCompletedRoles: false,
      };
    case 'filterByCompletedRolesChanged':
      return {
        ...state,
        filterByCompletedRoles: action.payload,
        filterByActiveRoles: false,
      };
      case 'filterByTurnkeyRolesChanged':
        return {
          ...state,
          filterByTurnkeyRoles: action.payload,
          filterBySourcingOnlyRoles: false,
        };
      case 'filterBySourcingOnlyRolesChanged':
        return {
          ...state,
          filterBySourcingOnlyRoles: action.payload,
          filterByTurnkeyRoles: false,
        };
    default:
      throw new Error();
  }
}

const RoleMetricsFilters = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    showFilters: false,
    filterByActiveRoles: false,
    filterByCompletedRoles: false,
    filterByTurnkeyRoles: false,
    filterBySourcingOnlyRoles: false,
  });
  const { classes } = useStyles(state);

  // Function to count active filters
  const countActiveFilters = () => {
    const filters = [
      state.filterByActiveRoles,
      state.filterByCompletedRoles,
      state.filterByTurnkeyRoles,
      state.filterBySourcingOnlyRoles
    ];
    return filters.filter(Boolean).length; // Count how many are true
  };

  const handleFilterByActiveRoles = (event) => {
    if (event.target.checked) {
      dispatch({ type: 'filterByActiveRolesChanged', payload: true });
      props.onRoleStatusFilterChange('active-roles');
      sendGoogleEvent('User', 'Active Roles metrics viewed');
    } else {
      dispatch({ type: 'filterByActiveRolesChanged', payload: false });
      props.onRoleStatusFilterChange('all');
      sendGoogleEvent('User', 'All metrics viewed');
    }
  };

  const handleFilterByCompletedRoles = (event) => {
    if (event.target.checked) {
      dispatch({ type: 'filterByCompletedRolesChanged', payload: true });
      props.onRoleStatusFilterChange('completed-roles');
      sendGoogleEvent('User', 'Completed Roles metrics viewed');
    } else {
      dispatch({ type: 'filterByCompletedRolesChanged', payload: false });
      props.onRoleStatusFilterChange('all');
      sendGoogleEvent('User', 'All metrics viewed');
    }
  };

  const handleFilterByTurnkeyRoles = (event) => {
    if (event.target.checked) {
      dispatch({ type: 'filterByTurnkeyRolesChanged', payload: true });
      props.onRoleTypeFilterChange('turnkey-roles');
    } else {
      dispatch({ type: 'filterByTurnkeyRolesChanged', payload: false });
      props.onRoleTypeFilterChange('all');
    }
  };

  const handleFilterBySourcingOnlyRoles = (event) => {
    if (event.target.checked) {
      dispatch({ type: 'filterBySourcingOnlyRolesChanged', payload: true });
      props.onRoleTypeFilterChange('sourcing-only-roles');
    } else {
      dispatch({ type: 'filterBySourcingOnlyRolesChanged', payload: false });
      props.onRoleTypeFilterChange('all');
    }
  };

  const activeFilterCount = countActiveFilters(); // Get the count of active filters

  return (
    <Popover
      opened={state.showFilters}
      onClose={() => dispatch({ type: 'showFiltersIsChanged', payload: false })}
      target={
        <Group
          onClick={() => dispatch({ type: 'showFiltersIsChanged', payload: true })}
          spacing={5}
          className={classes.button}
        >
          <FontAwesomeIcon
            icon={faFilter}
            color={state.filterByActiveRoles || state.filterByCompletedRoles || state.filterByTurnkeyRoles || state.filterBySourcingOnlyRoles ? '#351B77' : '#838485'}
            className={classes.filterIcon}
            style={{ marginBottom: '2px' }}
          />
          Filter {activeFilterCount > 0 ? `/ ${activeFilterCount}` : ''}
        </Group>
      }
      position="bottom"
      placement="center"
    >
    <Group spacing={25}>
        <Stack spacing={8}>
          <Text component={'h6'} my={0} mb={6} color={'#4F4F4F'} sx={{ fontFamily: 'Roboto', fontWeight: 500 }}>
            Status
          </Text>
          <Checkbox
            value={'active-roles'}
            onChange={handleFilterByActiveRoles}
            label={'Active Roles'}
            checked={state.filterByActiveRoles}
          />
          <Checkbox
            value={'completed-roles'}
            onChange={handleFilterByCompletedRoles}
            label={'Completed Roles'}
            checked={state.filterByCompletedRoles}
          />
        </Stack>

        {props.company && props.company.role_type && props.company.role_type.name === 'Hybrid (Sourcing & Turnkey)' ?
          <Stack spacing={8}>
            <Text component={'h6'} my={0} mb={6} color={'#4F4F4F'} sx={{ fontFamily: 'Roboto', fontWeight: 500 }}>
              Type of Role
            </Text>
            <Checkbox
              value={'turnkey-roles'}
              onChange={handleFilterByTurnkeyRoles}
              label={'Turnkey Roles'}
              checked={state.filterByTurnkeyRoles}
            />
            <Checkbox
              value={'sourcing-only-roles'}
              onChange={handleFilterBySourcingOnlyRoles}
              label={'Sourcing Only Roles'}
              checked={state.filterBySourcingOnlyRoles}
            />
          </Stack> 
        : null}
      </Group>
    </Popover>
  );
};

export default RoleMetricsFilters;
