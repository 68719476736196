import React from 'react';
import { Stack, Text, Title, Image, createStyles } from '@mantine/core';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const useStyles = createStyles((theme) => ({
    image: {
        height: 250,
        width: 'auto%',
    },

    noItemsTitle: {
        color: '#242424',
        fontSize: 28,
        [theme.fn.largerThan('md')]: {
            fontSize: 28,
        },
    },

    placeholderContainer: {
        paddingInline: 25,
        gap: 10,
        [theme.fn.largerThan('md')]: {
            paddingInline: 0,
            gap: 0,
        },
    },
}))

interface FeedbackRejectionScreenProps {
    onAdjustSearchClick: () => void;
}

const FeedbackRejectionScreen = ({onAdjustSearchClick} : FeedbackRejectionScreenProps) => {
    const { classes } = useStyles();

    return (
        <Stack sx={{ height: '100%' }} align={'center'} mb={'20%'} mt={50} justify={'center'} spacing={20}>
            <Image src={require('../../../assets/img/Thinking_RB.png')} fit={'contain'} width={140} mt={20}/>
            <Title className={classes.noItemsTitle} sx={{ color: '#242424' }}>
                It looks like these candidates weren't a perfect fit
            </Title>
            <Stack align={'center'} justify={'center'} className={classes.placeholderContainer}>
                <Text color={'#242424'} align={'center'}>
                    We recommend updating your search criteria to better match your expectations or
                </Text>
                <Text color={'#242424'} align={'center'}>
                    providing feedback on what was missing.
                </Text>
                <RolebotButton
                    onClick={onAdjustSearchClick}
                    sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '30px', maxWidth: 'fit-content' }}
                >
                    Adjust Search Criteria
                </RolebotButton>
            </Stack>
        </Stack>
    );

}

export default FeedbackRejectionScreen;