import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import useAppSelector from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showConfettiAnimation } from 'features/app/appSlice';

const ConfettiAnimation = () => {
  const [isRun, setIsRun] = useState(false);
  const dispatch = useAppDispatch();
  const confettiAnimation = useAppSelector((state) => state.app.showConfettiAnimation);
  useEffect(() => {
    setTimeout(() => {
      setIsRun(false);
      confettiAnimation && dispatch(showConfettiAnimation(false));
    }, 4000);
    // eslint-disable-next-line
  }, [confettiAnimation]);

  return confettiAnimation ? <Confetti recycle={isRun} /> : <></>;
};

export default ConfettiAnimation;
