import React from 'react';
import { createStyles, Group, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  wrapper: {},
}));

interface TalentLocationProps {
  location?: string;
  className?: string;
}

const TalentLocation = ({ location = '', className }: TalentLocationProps) => {
  const { classes, cx } = useStyles();

  // sorry for this...
  if (!location || location === 'Contact info') return <span />;

  return (
    <Group noWrap align="center" spacing={5} className={cx(className, classes.wrapper)}>
      <Group style={{ width: 20 }} noWrap>
        <FontAwesomeIcon icon={faLocationDot} style={{ height: '15px', width: '15px', color: '#838485', marginBottom: '2px' }} />
      </Group>
      <Text color="#838485" size="md" lineClamp={1} sx={{ wordBreak: 'break-all' }}>
        {location}
      </Text>
    </Group>
  );
};

export default TalentLocation;
