import React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({

    LeverRoleItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'unset',
        marginBottom: '8px',
        color: '#242424',
        padding: '2px 10px',
        '&:hover': {
            backgroundColor: '#EEEEEE',
            borderRadius: '5px'
        },
        '&:last-child': {
            marginBottom: '0'
        }
    },
    UnavailableLeverRoleItem: {
        cursor: 'not-allowed',
        fontSize: '16px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'unset',
        marginBottom: '8px',
        color: '#b3b3b3',
        padding: '4px 10px',
        '&:last-child': {
            marginBottom: '0'
        }
    }
}));

const LeverRoleItem = ({ leverRoleSelected, role }) => {
    const { classes } = useStyles();

    return (
        <>
            {role.is_available &&
                <div className={classes.LeverRoleItem} onClick={() => leverRoleSelected(role)}>
                    {role.lever_job_name} {role.requisition_ids?.length > 0 ? <span style={{ color: "#959595", display: 'contents', fontSize: '12px' }}>{"Req ID " + role.requisition_ids}</span> : <span>{" "}</span>}
                    {role?.location && <span style={{ color: "#959595", marginLeft: 10, fontSize: '16px' }}>{role?.location}</span>}
                </div>
            }

            {!role.is_available &&
                <div className={classes.UnavailableLeverRoleItem}>
                    {role.lever_job_name} {role.requisition_ids?.length > 0 && <span style={{ color: "#b3b3b3", marginLeft: 10, fontSize: '12px' }}>{"Req ID " + role.requisition_ids}</span>}<span style={{ color: "#b3b3b3", float: "right", fontSize: '16px' }}>{"On Rolebot"}</span>
                </div>}
        </>
    )
};

export default LeverRoleItem;

LeverRoleItem.propTypes = {
    role: PropTypes.object.isRequired,
    leverRoleSelected: PropTypes.func.isRequired
};

LeverRoleItem.defaultProps = {};
