import React from 'react';
import { Button, createStyles, Stack } from '@mantine/core';

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
  }
}));

const ConnectUserCalendarButton = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const { classes } = useStyles();
  return (
    <Stack className={classes.wrapper}>
      <Button mr={'auto'} component={'a'} href={baseURL + '/oauth/cronofy/redirect'} mt={20}>
        Connect your calendar
      </Button>
    </Stack>
  );
};

export default ConnectUserCalendarButton;
