import React from 'react';
import { Button, createStyles, Stack, Text, Grid } from '@mantine/core';

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
    // backgroundColor: 'lightgreen',
  },
  meetingTimes: {
    fontSize: '16px',
    color: '#242424',
  },
  messageText: {
    color: '#838485',
  },
  button: {
    width: '100% !important', //shoot me
  },
  messageOuter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

interface preferredMeetingsProps {
  openModelClicked: () => void;
}

const PreferredMeetingTimesArea = ({ openModelClicked }: preferredMeetingsProps) => {
  const { classes } = useStyles();

  return (
    <Stack spacing={8} className={classes.wrapper} mt={40}>
      <Grid>
        <Grid.Col py={0}>
          <Text className={classes.meetingTimes}>Preferred meeting times</Text>
        </Grid.Col>
        <Grid.Col sm={8} lg={9} className={classes.messageOuter}>
          <Text className={classes.messageText}>
            Configure your available or unavailable hours to improve your experience.{' '}
          </Text>
        </Grid.Col>
        <Grid.Col sm={4} lg={3} className={classes.messageOuter}>
          <Button onClick={openModelClicked} classNames={{ root: classes.button }}>
            Configure
          </Button>
        </Grid.Col>
      </Grid>
    </Stack>
  );
};

export default PreferredMeetingTimesArea;
