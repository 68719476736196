import React, {useEffect, useState} from 'react';
import {ContextModalProps} from '@mantine/modals';
import {Button, Checkbox, createStyles, Grid, Group, List, LoadingOverlay, Stack, Text} from '@mantine/core';
import {useAddUserToRolesMutation, useDeactivateUserMutation, useGetCompanyRolesQuery, usePostRoleUserMutation} from 'app/services/rolebot';
import {useNotifications} from '@mantine/notifications';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import {AddUserToRolesProps} from 'types/modals';
import RolebotTextInput from "../../Inputs/RolebotTextInput";
import RolebotSelect from "../../Inputs/RolebotSelect";
import {ChevronDown, X} from "tabler-icons-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faLocationDot, faMagnifyingGlass} from "@fortawesome/pro-light-svg-icons";
import RolebotButton from "../../public/Buttons/RolebotButton";
import RolesNotFound from "../../AlertMessages/RolesNotFound";

const useStyles = createStyles((theme) => ({
    rolesContainer: {
        alignContent: "baseline",
        height: '400px',
        width: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        borderBottom: '1px solid #DFE1E1',
        '::-webkit-scrollbar': {
            width: 6 /* Set the width of the scrollbar */
        },
        '::-webkit-scrollbar-thumb': {
            background: '#C4C4C4', /* Set the color of the scrollbar thumb */
            borderRadius: 6 /* Set the border radius for a smoother appearance */
        },
        '::-webkit-scrollbar-track': {
            background: '#f1f1f1' /* Set the width of the scrollbar */
        },
        '::-webkit-scrollbar-button': {
            display: 'none' /* Set the width of the scrollbar */
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: '#555' /* Set the width of the scrollbar */
        },
    },
    searchIcon: {
        height: '16px',
        paddingBottom: '2px'
    },
    roleFilters: {
        marginBottom: 10
    },
    roleName: {
        color: "#242424",
        fontFamily: "Helvetica, sans-serif",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginLeft: -6
    },
    roleLocation: {
        color: "#838485",
        fontFamily: "Helvetica, sans-serif",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginTop: -5,
    },
    roleStatus: {
        color: "#838485",
        fontFamily: "Helvetica, sans-serif",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textAlign: "end"
    },
    checkBox: {
        marginLeft: 15,
    },
    noMatchingRolesCaption: {
        margin: "auto",
        alignSelf: "center"
    },
    roleSelectLimitCaption: {
        marginTop: 5,
        textAlign: "initial",
        color: "red"
    },
    rolesTypeCaption: {
        color: "#838485",
        fontFamily: "Helvetica, sans-serif",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal"
    },
    rolesNotFoundContainer: {
        margin: "auto"
    }
}));

const AddUserToRoles = ({id, context, innerProps}: ContextModalProps<AddUserToRolesProps>) => {
    const {classes} = useStyles();
    const { data, isLoading: companyRolesLoading } = useGetCompanyRolesQuery({companyId: innerProps?.customer_id?.toString()});
    const [addRoles, {isLoading, isSuccess, isError}] = useAddUserToRolesMutation()
    const notifications = useNotifications();
    const [allRolesAreSelected, setAllRolesAreSelected] = useState(false);
    const [roleTypes, setRoleTypes] = useState([
        {value: "0", label: "Any role status"},
        {value: "1", label: "Active roles"},
        {value: "2", label: "Completed roles"}
    ]);

    const [selectedRolesType, setSelectedRolesType] = useState("1");
    const [searchText, setSearchText] = useState("");
    const [selectedRoleIds, setSelectedRoleIds] = useState<number[]>([]);
    const [filteredRoles, setFilteredRoles] = useState<any>([]);

    useEffect(() => {
        if (allRolesAreSelected) {
            let selectedRoleIds = filteredRoles.filter((x: { already_added: any; }) => !x.already_added).map((x: { id: any; }) => x.id);

            setSelectedRoleIds(selectedRoleIds);
        } else {
            setSelectedRoleIds([]);
        }
    }, [allRolesAreSelected]);

    const handleCheckboxChange = (roleId: React.Key | null | undefined) => {
        if (selectedRoleIds.includes(roleId as number)) {
            // Remove the roleId if already selected
            setSelectedRoleIds(selectedRoleIds.filter((id) => id !== roleId));
        } else {
            // Add the roleId if not selected
            //@ts-ignore
            setSelectedRoleIds([...selectedRoleIds, roleId]);
        }
    };

    const transformCompanyRoles = (roles: any[]) => {

        //@ts-ignore
        const rolesIds = innerProps.roles?.map((role) => role.id);

        return roles?.map((role) => ({
            ...role,
            already_added: rolesIds.includes(role.id),
        }));
    }

    useEffect(() => {
        if (data?.roles.length) {
            // Filter roles based on search text and roles type
            const filtered = data?.roles.filter((role: { name: string; completed: any; }) => {

                // @ts-ignore
                const nameMatches = role.name.toLowerCase().includes(searchText.toLowerCase());

                if (selectedRolesType === "0") {
                    // Display all roles when rolesType is empty
                    return nameMatches;
                } else { // @ts-ignore
                    if (selectedRolesType === "1" && !role.completed) {
                        // Display active roles when rolesType is "1"
                        return nameMatches;
                    } else {
                        // @ts-ignore
                        if (selectedRolesType === "2" && role.completed) {
                            // Display completed roles when rolesType is "2"
                            return nameMatches;
                        }
                    }
                }

                return false; // Exclude other cases
            });

            setFilteredRoles(transformCompanyRoles(filtered));
        }
    }, [searchText, selectedRolesType, data?.roles]);

    const searchValueChanged = (e: { target?: { value: string }; } | string) => {
        if (typeof e === "string") {
            setSearchText(e);
        } else if (e.target && typeof e.target.value === "string") {
            setSearchText(e.target.value);
        }
    };

    const handleSelectedRolesTypeChanged = (value: React.SetStateAction<string>) => {
        setSelectedRolesType(value);
    }

    const handleAddExistingUserToMultipleRoles = () => {

        addRoles({
            user_id: innerProps.userId,
            roles: selectedRoleIds
        })
        
    }

    useEffect(() => {
        if (!isSuccess) return;
        notifications.showNotification({
            title: 'Success',
            message: 'User added to role(s) successfully',
            color: 'green',
        });
        context.closeModal(id);
        // eslint-disable-next-line
    }, [isSuccess]);

    useEffect(() => {
        if (!isError) return;
        notifications.showNotification({
            title: 'Error',
            message: 'Oops, something went wrong. Please try again.',
            color: 'red',
        });
        // eslint-disable-next-line
    }, [isError]);

    let name = () => {
        // @ts-ignore
        if (innerProps.userName.length > 0) {
            return innerProps.userName;
        }
        return innerProps.userEmail;
    };

    return (
        <>
            <TitleWithClose id={id} title={'Add user to roles'}/>
            <Text color={'#242424'}>
                Select the roles you'd like to add <span
                style={{fontFamily: 'Roboto'}}><strong>{name()}</strong></span> to
            </Text>
            <Grid mb={20}>
                <Grid.Col span={8}>
                    <RolebotTextInput
                        label=""
                        placeholder={'Search for a role...'}
                        maxLength={255}
                        onChange={searchValueChanged}
                        value={searchText}
                        rightSection={
                            searchText ? (
                                <X size={20} onClick={() => searchValueChanged('')} color={'#4F4F4F'} style={{ cursor: 'pointer', marginRight: 10 }} />
                            ) : null
                        }
                        icon={<FontAwesomeIcon icon={faMagnifyingGlass} className={classes.searchIcon}/>}
                    />
                </Grid.Col>
                <Grid.Col span={4}>
                    <RolebotSelect
                        rightSection={<ChevronDown size={20}/>}
                        data={roleTypes}
                        value={selectedRolesType}
                        //@ts-ignore
                        onChange={handleSelectedRolesTypeChanged}
                    />
                </Grid.Col>
            </Grid>

            <Grid className={classes.rolesContainer}>
                {filteredRoles.length ?
                    <React.Fragment>
                        {selectedRolesType !== '0' ?
                            <Grid.Col span={12} style={{marginLeft: 15, marginBottom: 5}}>
                                <Text className={classes.rolesTypeCaption}>DISPLAYING {selectedRolesType === '1' ? 'ACTIVE' : 'COMPLETED'} ROLES</Text>
                            </Grid.Col>
                        : null}
                        <Grid.Col span={1} style={{paddingRight: 0}}>
                            <Checkbox
                                className={classes.checkBox}
                                onChange={() => setAllRolesAreSelected(!allRolesAreSelected)}
                                checked={allRolesAreSelected}
                            />
                        </Grid.Col>
                        <Grid.Col span={8} style={{paddingLeft: 0}}>
                            <Stack spacing={"xs"}>
                                <Text className={classes.roleName}>Select all</Text>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col span={3}></Grid.Col>
                    </React.Fragment> : null
                }

                {filteredRoles.length ? (
                    filteredRoles.map((x: {
                        info: any;
                        completed: boolean;
                        name: string;
                        already_added: boolean | undefined;
                        id: React.Key | null | undefined; }) => (
                        <React.Fragment key={x.id}>
                            <Grid.Col span={1} style={{paddingRight: 0}}>
                                <Checkbox
                                    className={classes.checkBox}
                                    onChange={() => handleCheckboxChange(x.id)}
                                    disabled={x.already_added}
                                    checked={selectedRoleIds.includes(x.id as number) || x.already_added}
                                />
                            </Grid.Col>
                            <Grid.Col span={8} style={{paddingLeft: 0}}>
                                <Stack spacing={"xs"}>
                                    <Text className={classes.roleName} style={{color: x.already_added ? "#838485" : "inherit"}}>{x.name}</Text>
                                    <div style={{display: 'flex', alignItems:'center', height: 25, marginLeft: -5}}>
                                        <FontAwesomeIcon icon={faLocationDot} color={'#838485'} />
                                        <p style={{marginLeft:10, marginTop:'auto', marginBottom:'auto'}} className={classes.roleLocation}>{x?.info?.offices[0]?.location_name ?? "N/A"}</p>
                                    </div>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col span={3}>
                                <Text className={classes.roleStatus}>{x.already_added ? 'Already on this role' : x.completed ? 'Completed' : ''}</Text>
                            </Grid.Col>
                        </React.Fragment>
                    ))
                ) : (
                    <div className={classes.rolesNotFoundContainer}>
                        {!companyRolesLoading ?
                            <RolesNotFound />
                                :
                            <LoadingOverlay visible={true} />
                        }
                    </div>
                )}
            </Grid>

            <Group position={'right'} mt={40}>
                <Text style={{
                    color: "#B3B3B3",
                    marginRight: "auto",
                    textAlign: "initial",
                    visibility: selectedRoleIds.length ? "inherit" : "hidden"}}>{selectedRoleIds.length + " role(s) selected"}</Text>
                <RolebotButton type={'neutral'} onClick={() => context.closeModal(id)}>
                    Cancel
                </RolebotButton>
                <RolebotButton
                    type={"primary"}
                    disabled={isLoading || !selectedRoleIds.length || companyRolesLoading}
                    onClick={() => handleAddExistingUserToMultipleRoles()}
                    loading={false}
                    >
                        Add to Roles
                </RolebotButton>
            </Group>
        </>
    );
};

export default AddUserToRoles;
