import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { createStyles, Text } from '@mantine/core';

const BAR_HEIGHT = 8;
const NEGATIVE_PADDING = 5;

const useStyles = createStyles((theme) => ({
  itemTitle: {
    fontSize: theme.fontSizes.lg,
    color: '#242424',
  },

  PercentBarDisplay: {
    width: '100%',
  },

  LabelBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    lineHeight: '1',
    fontSize: '24px',
  },

  ResponsiveContainerShifter: {
    position: 'relative',
  },
}));

const PercentBarDisplay = (props) => {
  const { classes } = useStyles();

  const data = [
    {
      name: 'facebook words',
      red: props.value,
      grey: 100 - props.value,
    },
  ];

  return (
    <div className={classes.PercentBarDisplay}>
      <div className={classes.LabelBar}>
        <Text className={classes.itemTitle}>{props.title}</Text>
        <Text className={classes.itemTitle}>{props.value}%</Text>
      </div>
      <div>
        <div className={classes.ResponsiveContainerShifter} style={{ top: '-' + NEGATIVE_PADDING + 'px' }}>
          <ResponsiveContainer width="100%" height={BAR_HEIGHT}>
            <BarChart data={data} layout="vertical" margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <YAxis type="category" dataKey="name" hide />
              <XAxis type="number" hide />
              <Bar stackId="a" dataKey="red" fill={props.fillColor} />
              <Bar stackId="a" dataKey="grey" fill={props.backgroundColor} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default PercentBarDisplay;

PercentBarDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  fillColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

PercentBarDisplay.defaultProps = {
  fillColor: 'blue',
  backgroundColor: 'lightgrey',
};
