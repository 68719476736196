import { configureStore } from '@reduxjs/toolkit';
import appReducer from 'features/app/appSlice';
import formReducer from 'features/form/formsSlice';
import authReducer from 'features/auth/authSlice';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { rolebotApi } from 'app/services/rolebot';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    forms: formReducer,
    router: routerReducer,
    [rolebotApi.reducerPath]: rolebotApi.reducer,
  },
  middleware: (defaultMiddleware) => defaultMiddleware().concat(routerMiddleware).concat(rolebotApi.middleware),
});

export default store;

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
