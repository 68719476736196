import { Stack, Text, Title, Container, createStyles} from '@mantine/core';
import RolebotAnimated from 'assets/img/happy.gif';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showConfettiAnimation } from 'features/app/appSlice';
import { useGetCandidateReviewedMessageQuery } from 'app/services/rolebot';
import { useEffect } from 'react';

const useStyles = createStyles((theme) => ({
  image: {
    height: 250,
    width: 'auto%',
  }
}))

const CandidateToReviewSuccess = () => {
  const { data } = useGetCandidateReviewedMessageQuery()
  const dispatch = useAppDispatch();
  const { classes } = useStyles()

  useEffect(() => {
    if (data) {
      dispatch(showConfettiAnimation(true));
    }
  }, [data])

  return (
    <div style={{ overflow: 'hidden', marginTop: '3rem' }}>
      <Stack align={'center'}>
        {data &&
        <>
          <img className={classes.image} src={RolebotAnimated} alt='Loading...'/>
          <Title order={2} mt={0}>
            {data.data.header}
          </Title>
          <Stack align={'center'} spacing={3}>
            <Container size={500}>
              <Text align='center'>{data.data.text}</Text>
            </Container>
          </Stack>
        </>}
      </Stack>
    </div>
  );
};

export default CandidateToReviewSuccess;
