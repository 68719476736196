import React from 'react';
import { Group, Tooltip } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/pro-light-svg-icons';

const ContactOut = () => {
  return (
    <Tooltip label={'Contact Info Found'}>
      <Group mt={1}>
        <FontAwesomeIcon icon={faAddressCard} style={{ height: '16px', color: '#4F4F4F', marginBottom: '1px'}} />
      </Group>
    </Tooltip>
  );
};

export default ContactOut;
