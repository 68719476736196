import { Table, Skeleton, createStyles } from '@mantine/core';
import RoleRow from 'components/RoleRow/RoleRow';
import { IRole } from 'types';
import { Virtuoso } from 'react-virtuoso';

const useStyles = createStyles((theme) => ({
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '40px',
    paddingTop: '20px'
  },
}));

interface RoleStackProps {
  data: IRole[] | [];
  onEndReached: () => void;
  handlePatchCompleted: (action: string, roleId: number | string) => void;
  handleSetupComplete: (isCompleted: boolean, roleId: number) => void;
  handleNavigateToRole: (roleId: number | string, roleName: string) => void;
  isFetching?: boolean;
  page?: number;
}

const RoleStack = ({
  data = [],
  onEndReached,
  handlePatchCompleted,
  handleSetupComplete,
  handleNavigateToRole,
  isFetching = false,
  page = 1
}: RoleStackProps) => {
  const { classes } = useStyles();
  
  const Footer = () => {
    return (
      <div>
        {isFetching && page !== 1 && (
          <div className={classes.container}>
            <Skeleton height={8} radius="xl" />
            <Skeleton height={8} mt={6} radius="xl" />
            <Skeleton height={8} mt={6} width="70%" radius="xl" />
          </div>
        )}
      </div>
    )
  }

  return (
    <Table verticalSpacing={10} sx={{ height: '100%' }}>
      <tbody>
        <tr style={{ height: '100%' }}>
          <td style={{ height: '100%', padding: 0 }}>
            <Virtuoso
              fixedItemHeight={100}
              style={{ height: '100%' }}
              data={data}
              itemContent={(index, role) =>
                <RoleRow
                  role={role}
                  key={role.id}
                  index={index}
                  handlePatchCompleted={handlePatchCompleted}
                  handleSetupComplete={handleSetupComplete}
                  handleNavigateToRole={handleNavigateToRole}
                />}
              endReached={onEndReached}
              overscan={400}
              increaseViewportBy={200}
              components={{ Footer }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default RoleStack;
