import React from 'react';
import { createStyles, Text, Select, Anchor, Stack } from '@mantine/core';
import TitleSection from '../TitleSection';
import { IConferencingProfiles } from '../../../../../types';
import CalendarItem from './component/CalendarItem';
import { usePostCronofyConferencingSettingsMutation } from 'app/services/rolebot';
import {  HiOutlineChevronDown } from 'react-icons/hi';

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
    marginTop: '40px',
    paddingBottom: '40px',
  },
  message: {
    marginTop: '20px',
    fontFamily: 'Helvetica',
    fontSize: '16px',
    color: '#838485',
  },
  meetingTimeTitle: {
    marginTop: '40px',
    color: '#242424',
    fontFamily: 'Helvetica',
    fontSize: '16px',
  },
  meetingTimeMessage: {
    marginTop: '20px',
    color: '#838485',
    fontFamily: 'Helvetica',
    fontSize: '16px',
  },
  conferenceSelector: {
    marginTop: '20px',
  },
  redirectLink: {
    display: 'inline-block',
    marginTop: '20px',
    color: '#40a5d0',
    fontFamily: 'Helvetica',
    fontSize: '16px',
  },
  root: {
    marginTop: '20px',
    maxWidth: '200px',
  },
  label: {
    color: '#242424',
    fontFamily: 'Helvetica',
    fontSize: '16px',
  },
}));

interface CalendarSettingsSectionProps {
  conferenceProfiles: IConferencingProfiles[];
  meetingDuration: number;
  loading: boolean;
  cronofyProviders: any;
}

const CalendarSettingsSection = ({
  conferenceProfiles,
  meetingDuration,
  loading,
  cronofyProviders,
}: CalendarSettingsSectionProps) => {
  const baseURL = process.env.REACT_APP_API_URL;
  const { classes } = useStyles();

  const [postCronofyConferenceSettings, {  isLoading: posting }] =
    usePostCronofyConferencingSettingsMutation();

  const handleCalendarSettingsChange = (profileId: string) => {
    postCronofyConferenceSettings({
      profile_id: profileId,
    });
  };

  const handleMeetingDurationChange = (duration: string) => {
    postCronofyConferenceSettings({
      meeting_duration: parseInt(duration),
    });
  };

  return (
    <div className={classes.wrapper}>
      <TitleSection message="Calendar Settings" />
      <Text className={classes.message}>Configure the duration of your calls and preferred meeting type.</Text>
      <Select
        classNames={{ root: classes.root, label: classes.label }}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        rightSection={<HiOutlineChevronDown />}
        disabled={posting || loading}
        label="Appointment Slots"
        placeholder="Pick one"
        value={meetingDuration.toString()}
        onChange={handleMeetingDurationChange}
        data={[
          { value: '15', label: '15 minutes' },
          { value: '30', label: '30 minutes' },
          { value: '45', label: '45 minutes' },
          { value: '60', label: '60 minutes' },
        ]}
      />

      <Text className={classes.meetingTimeTitle} weight="bold">
        Default meeting type
      </Text>

      <Text className={classes.meetingTimeMessage}>
        Select a default meeting type. Rolebot supports regular phone calls, or conference calls via Google Hangouts,
        Zoom, Microsoft Teams and GoToMeeting.
      </Text>

      <Stack className={classes.conferenceSelector} spacing={0}>
        {conferenceProfiles.map((item) => (
          <CalendarItem
            key={item.id}
            item={item}
            onSelectItem={handleCalendarSettingsChange}
            loading={posting || loading}
            cronofyProviders={cronofyProviders}
          />
        ))}
      </Stack>

      <Anchor className={classes.redirectLink} href={baseURL + '/cronofy/conferencing-setup'} target="_self">
        Select a different conference service
      </Anchor>
    </div>
  );
};

export default CalendarSettingsSection;
