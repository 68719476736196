import { Alert, Button, createStyles, Group, Stack, Text, Textarea, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useGetUserQuery, usePostContactSupportMutation } from 'app/services/rolebot';
import React, { useState } from 'react';
import { ContextModalProps, useModals } from '@mantine/modals';
import SuccessContactSupport from './SuccessContactSupport';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { useMediaQuery } from '@mantine/hooks';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const useStyles = createStyles((theme) => ({
  Counter: {
    fontSize: theme.fontSizes.md,
    color: '#B3B3B3',
    minWidth: 'fit-content',
  },

  buttons: {
    alignContent: 'flex-end',
    flexDirection: 'column',
    marginTop: 'auto',
    [theme.fn.largerThan('md')]: {
      marginTop: 50,
      flexDirection: 'row',
    },
  },
  button: {
    width: '100%',
    [theme.fn.largerThan('md')]: {
      width: 'auto',
    },
  },
}));

const ImproveResults = ({ id }: ContextModalProps) => {
  const {
    formState: { errors },
  } = useForm({
    defaultValues: {
      subject: '',
      details: '',
    },
  });
  const { data } = useGetUserQuery();
  const { classes } = useStyles();
  const [subjectInputValue, setSubjectValue] = useState('');
  const [detailsValue, setDetailsValue] = useState('');
  const [postContactSupport, { isLoading, isSuccess, isError }] = usePostContactSupportMutation();
  const modals = useModals();
  const matches = useMediaQuery('(min-width: 993px)');

  const handleContactSupport = async () => {
    let payload = {
      subject: subjectInputValue,
      email: data?.user.email,
      details: detailsValue,
      type: 'support',
    };

    try {
      await postContactSupport(payload).unwrap();
    } catch (e) {
      console.log('error', e);
    }
  };

  const submitButton = (
    <Button
      px={40}
      disabled={subjectInputValue === '' || detailsValue === ''}
      onClick={handleContactSupport}
      loading={isLoading}
      loaderPosition={'right'}
      className={classes.button}
    >
      Submit
    </Button>
  );

  const cancelButton = (
    <RolebotButton type={'neutral'} disabled={isLoading} onClick={() => modals.closeAll()}>
      Cancel
    </RolebotButton>
  );

  const buttons = matches ? (
    <>
      {cancelButton}
      {submitButton}
    </>
  ) : (
    <>
      {submitButton}
      {cancelButton}
    </>
  );

  const detailsLabel = (
    <Group noWrap position={'apart'} sx={{ flex: 1, width: '100%' }}>
      <Text>Details</Text>
      <Text className={classes.Counter} ml={'auto'}>
        {detailsValue.length}/500
      </Text>
    </Group>
  );

  return (
    <>
      <TitleWithClose id={id} title={'Contact Support'} />
      {isSuccess ? (
        <SuccessContactSupport onClose={() => modals.closeAll()} />
      ) : (
        <>
          <Text size={'md'} color={'#838485'} mb={20}>
            Let us know how we can help you
          </Text>
          <div>
            <TextInput
              label={'Subject'}
              size={'md'}
              placeholder="What is the topic of your question?"
              value={subjectInputValue}
              onChange={(e) => setSubjectValue(e.target.value)}
              mb={'16px'}
              error={errors?.subject?.message}
            />
            <Textarea
              labelProps={{ style: { width: '100%' } }}
              placeholder="What is your question and how can we help you?"
              value={detailsValue}
              maxLength={500}
              onChange={(e) => setDetailsValue(e.target.value)}
              label={detailsLabel}
              autosize
              minRows={2}
            />
          </div>
          {isError && (
            <Alert title={'Oops, something went wrong!'} color={'orange'} mt={10}>
              An error has occurred.
            </Alert>
          )}
          <Group
            position={'right'}
            className={classes.buttons}
            sx={{ flexDirection: matches ? 'column' : 'row-reverse' }}
          >
            {buttons}
          </Group>
        </>
      )}
    </>
  );
};

export default ImproveResults;
