import React, { useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { Alert, Button, createStyles, Grid, Group, Loader, Stack, Switch, Text, TextInput, Title } from '@mantine/core';
import AdminRow from 'components/Modals/RoleAdmins/AdminRow';
import { useModals } from '@mantine/modals';
import { useForm } from 'react-hook-form';
import { useListState, useMediaQuery } from '@mantine/hooks';
import {
  useCreateRoleFromICIMSMutation,
  useGetICIMSRolesQuery,
  useGetRolesQuery,
  useGetUserQuery,
  useLinkICIMSJobMutation,
  useUnlinkICIMSJobMutation,
  useGetCompanyQuery,
} from 'app/services/rolebot';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import * as z from 'zod';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { IRole } from 'types';
import _ from 'lodash';
import ICIMSRolesDropdown from './Components/ICIMSRolesDropdown';
import LinkableICIMSRolesDropdown from './Components/LinkableICIMSRolesDropdown';
import useOutsideAreaClick from '../../../pages/RoleStats/hooks/useOutsideAreaClick';
import AddUsersToRoleDropDown from './Components/AddUsersToRoleDropDown';
import BodyScrollWrapper from '../components/BodyScrollWrapper';
import { toastSuccess } from '../../../utils/toastify-messages';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faLinkSlash } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  button: {
    marginTop: 'auto',
    marginBottom: 10,
    width: '100%',
    [theme.fn.largerThan('md')]: {
      marginBottom: 0,
      width: 'fit-content',
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '19px',
    fontWeight: 400,
    margin: '20px 0',
    color: '#242424',
  },
  chevron: {
    marginRight: '4px',
  },
  unlinkIcon: {
    cursor: 'pointer',
  },
  roleNameText: {
    color: '#242424',
  },
}));

const LaunchFormSchema = z.object({
  // roleName: z.string().nonempty('Role name is required'),
  admin: z.union([z.string().email('Invalid email address'), z.literal('')]),
});

type LaunchFormPayload = z.infer<typeof LaunchFormSchema>;

//TODO: fix typescript in this file

const getAvailableJobs = (roles = [], connectedRoles = []) => {
  // @ts-ignore
  const unavailableIds = connectedRoles.map((r) => r.job_id);
  return roles.map((i: IRole) => {
    // @ts-ignore
    if (unavailableIds.includes(i.id)) {
      return {
        //@ts-ignore
        ...i,
        isAvailable: false,
        label: i.name,
        value: i.id,
      };
    }
    return {
      //@ts-ignore
      ...i,
      label: i.name,
      value: i.id,
      isAvailable: true,
    };
  });
};

type linkError = {
  data: {
    message: string;
  };
};

const ICIMSTab = ({ integrations = null }) => {
  const [currentPane, setCurrentPane] = useState(1);
  // This one needs a better name. I only use it to determine which should be the right dropdown that's showing when trying to link RB role to ATS role.
  const [localRolebotRoleId, setLocalRolebotRoleId] = useState(0);
  const [, setRepeatedFields] = useState([]);
  const [showICIMSRoles, setShowICIMSRoles] = useState(false);
  const icimsRolesDropdownRef = useRef(null);
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const [linkableICIMSRolesInputFields, setLinkableICIMSRolesInputFields] = useState({});
  const clickedOutsideOfICIMSRolesDropdown = useOutsideAreaClick(icimsRolesDropdownRef);
  const [selectedICIMSRole, setSelectedICIMSRole] = useState<any>({});
  const [selectedLinkableICIMSRoles, setSelectedLinkableICIMSRoles] = useState<any>([]);
  const { classes } = useStyles();
  const modals = useModals();
  const { data: user } = useGetUserQuery();
  const { data: icimsRoles } = useGetICIMSRolesQuery({ type: 'name', value: '' });
  const { combinedData: activeRoles, extraData } = useInfiniteScroll(useGetRolesQuery, {
    type: 'active',
  });
  const [links, setLinks] = useState({});
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [someFailed, setSomeFailed] = useState(false);
  const [rejectsError, setRejectsError] = useState<any>([]);
  const [linkRole, { isLoading: isLinking }] = useLinkICIMSJobMutation();
  const [showUsers, setShowUsers] = useState(false);
  const [emailErrors, setEmailErrors] = useState<any>([]);
  const { data: company } = useGetCompanyQuery();

  const roles = useMemo(
    //@ts-ignore
    () => getAvailableJobs(icimsRoles?.icims_jobs, integrations?.icims?.icims_jobs),
    [icimsRoles, integrations]
  );

  //@ts-ignore
  const canUnlink = useMemo(() => integrations && integrations?.icims?.icims_jobs?.length > 0, [integrations]);

  const _roles = useMemo(
    () =>
      //@ts-ignore
      activeRoles.map((role) => {
        const index = roles.findIndex((r) => r.id === role.icims_job?.job_id);
        if (index === -1) {
          return role;
        }
        return {
          ...role,
          icims_job: {
            ...role.icims_job,
            name: roles[index].name,
          },
        };
      }),
    [activeRoles, roles]
  );

  const [value, setValue] = useState(null);

  const {
    handleSubmit,
    getValues,
    setValue: setICIMSRoleValue,
    register,
    resetField,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      roleName: '',
      admin: '',
      isSourcingOnly: false,
    },
    resolver: zodResolver(LaunchFormSchema),
  });

  const [launchRole, { isLoading, error, reset: resetCreateRole }] = useCreateRoleFromICIMSMutation();
  const [admins, handlers] = useListState<string>([]);
  const [roleNameWatcher] = watch(['roleName']);

  const [adminWatcher] = watch(['admin']);

  useEffect(() => {
    if (roleNameWatcher === '') {
      setValue(null);
    }
    setICIMSRoleValue('roleName', roleNameWatcher);
    // eslint-disable-next-line
  }, [roleNameWatcher]);

  useEffect(() => {
    setICIMSRoleValue('admin', adminWatcher);
    // eslint-disable-next-line
  }, [adminWatcher]);

  const [isSourcingOnlyWatcher] = watch(['isSourcingOnly']);

  useEffect(() => {
    if (user?.user) {
      admins.includes(user.user.email) || handlers.append(user.user.email);
    }
    // eslint-disable-next-line
  }, [admins, user, handlers]);

  useEffect(() => {
    if (clickedOutsideOfICIMSRolesDropdown) {
      handleClickedOutsideOfICIMSRolesDropdown();
    }
    // eslint-disable-next-line
  }, [clickedOutsideOfICIMSRolesDropdown]);

  useEffect(() => {
    if (emailErrors.length === 0) {
      resetCreateRole();
    }
    // eslint-disable-next-line
  }, [emailErrors]);

  const handleClickedOutsideOfICIMSRolesDropdown = () => {
    setShowICIMSRoles(false);
    if (selectedICIMSRole?.icims_job_name !== getValues('roleName')) {
      setICIMSRoleValue('roleName', '');
      setValue(null);
      setSelectedICIMSRole({});
    }
  };

  const handleLinkableICIMSRolesInputFieldsChanged = (e: any, roleId: number) => {
    setLinkableICIMSRolesInputFields((previousValue) => {
      return { ...previousValue, [roleId]: e };
    });
    if (e === '') {
      const newLinks = { ...links };
      // @ts-ignore
      delete newLinks[roleId];
      setLinks(newLinks);
    }
  };

  const handleCloseUsersDropdown = () => {
    //setShowUsers(false);
  };

  const handleUsersDropdownFocus = () => {
    setShowUsers(true);
  };

  const handleUserSelected = (user: any) => {
    setICIMSRoleValue('admin', user.email);
    setShowUsers(false);
    if (!admins.includes(user.email)) {
      handlers.append(user.email);
    }
    resetField('admin');
  };

  const addAdmin = (data: LaunchFormPayload) => {
    if (!data.admin) return;
    if (admins.includes(data.admin!)) return;
    handlers.append(data.admin!);
    resetField('admin');
  };

  // const onReset = () => {
  //   reset();
  //   resetCreateRole();
  //   setValue(null);
  //   const userEmail = user?.user.email.trim() || null;
  //   if (userEmail) {
  //     handlers.setState([userEmail]);
  //   }
  // };

  const handleCreateRole = async () => {
    console.log('selectedICIMSRole', selectedICIMSRole);
    console.log('selectedICIMSRole value', value);
    setEmailErrors([]);
    resetCreateRole();
    if (!selectedICIMSRole || !value) {
      return;
    }
    try {
      await launchRole({
        icims_job_id: parseInt(selectedICIMSRole?.job_id),
        name: selectedICIMSRole?.icims_job_name,
        interviews: 100,
        price: 0,
        //@ts-ignore
        admins,
        ...(company?.role_type?.name === 'Hybrid (Sourcing & Turnkey)' &&
          (isSourcingOnlyWatcher === true ? { sourcing_only: 1 } : { sourcing_only: 0 })),
      }).unwrap();
      modals.closeAll();
      toastSuccess(
        <div className="toastContainer">
          <div>Role requested successfully</div>
          <div>We will email you with details shortly</div>
        </div>
      );
      reset();
    } catch (e: any) {
      if (e.data.type === 'email-error') {
        setEmailErrors(e.data.message);
      }
    }
  };

  const handleICIMSRoleSelected = (role: any) => {
    console.log('selectedROle', role);
    setICIMSRoleValue('roleName', role.icims_job_name);
    setValue(role.job_id);
    setSelectedICIMSRole(role);

    setShowICIMSRoles(false);
  };

  const handleLinkableICIMSRoleSelected = (role: any) => {
    setSelectedLinkableICIMSRoles((previousValue: any) => {
      return { ...previousValue, [localRolebotRoleId]: role };
    });
    setLinkableICIMSRolesInputFields((previousValue) => {
      return { ...previousValue, [localRolebotRoleId]: role.icims_job_name };
    });
    onAdd(localRolebotRoleId, role.job_id, role.icims_portal.id);

    setShowICIMSRoles(false);
  };

  const handleICIMSRolesFocus = (roleId = 0) => {
    setShowICIMSRoles(true);
    setLocalRolebotRoleId(roleId);
  };

  const handleCloseAllDropdowns = () => {
    setLinkableICIMSRolesInputFields((previousValue) => {
      // @ts-ignore
      if (previousValue[localRolebotRoleId] !== selectedLinkableICIMSRoles[localRolebotRoleId]?.icims_job_name) {
        const newLinks = { ...links };
        // @ts-ignore
        delete newLinks[localRolebotRoleId];
        setLinks(newLinks);
      }

      // @ts-ignore
      return {
        ...previousValue,
        [localRolebotRoleId]:
        //@ts-ignore
          previousValue[localRolebotRoleId] === selectedLinkableICIMSRoles[localRolebotRoleId]?.icims_job_name
            ? //@ts-ignore
              previousValue[localRolebotRoleId]
            : '',
      };
    });
    setShowICIMSRoles(false);
  };

  const handleDelete = (admin: string) => {
    //@ts-ignore
    let newEmailErrors = emailErrors.filter((x) => x !== admin);
    setEmailErrors(newEmailErrors);
    const newState = admins.filter((a) => a !== admin);
    handlers.setState(newState);
  };

  const onAdd = (roleId: number, atsId: number, portalId: number) => {
    const newLinks = { ...links };
    //@ts-ignore
    const item = newLinks[roleId];
    if (item) {
      const wasSameItemClicked = item.roleId === roleId;
      if (wasSameItemClicked) {
        //@ts-ignore
        delete newLinks[roleId];
      }
    } else {
      //@ts-ignore
      newLinks[roleId] = {
        roleId,
        atsId,
        portalId,
      };
    }
    setLinks(newLinks);
  };

  const handleMakeConnection = async () => {
    setSomeFailed(false);
    setRepeatedFields([]);
    setHasDuplicates(false);
    setRejectsError([]);
    // look if there is no repeated data first.
    const atsRolesIds: any[] = [];

    _.mapValues(links, (v) => {
      //@ts-ignore
      atsRolesIds.push(v.atsId);
    });
    // do nothing if we have no data.
    if (atsRolesIds.length === 0) return;

    // return early if there are duplicates.
    const uniqueIds = [...new Set(atsRolesIds)];
    let duplicates = [...atsRolesIds];
    uniqueIds.forEach((item) => {
      const i = duplicates.indexOf(item);
      duplicates = duplicates.slice(0, i).concat(duplicates.slice(i + 1, duplicates.length));
    });
    if (duplicates.length > 0) {
      setHasDuplicates(true);
      return;
    }

    // get params for each request we are going to make.
    const connectionParams: { roleId: number; atsId: number; portalId: number }[] = []; // has objects of type: {roleId, atsId}
    _.mapValues(links, (v) => {
      console.log('mapValues', v);
      console.log('mapValues', links);
      //@ts-ignore
      connectionParams.push({ roleId: v.roleId, atsId: v.atsId, portalId: v.portalId });
    });

    try {
      const results = await Promise.allSettled(
        connectionParams.map(({ roleId, atsId, portalId }) =>
          linkRole({
            roleId,
            payload: {
              icims_job_id: atsId,
              icims_portal_id: portalId,
              on_hold: 0,
            },
          }).unwrap()
        )
      );
      const fulfilled = results.filter((p) => p.status === 'fulfilled');
      const rejects = results.filter((p) => p.status === 'rejected');

      if (rejects.length > 0) {
        setRejectsError(rejects);
      }
      //@ts-ignore
      const rejectedIds = rejects.map((r) => r.reason.meta.roleId);
      //@ts-ignore
      setRepeatedFields(rejectedIds);
      if (fulfilled.length > 0 && rejects.length > 0) {
        setSomeFailed(true);
        return;
      }
      if (fulfilled.length === 0 && rejects.length > 0) {
        throw new Error();
      }
      toastSuccess(
        <div className="toastContainer">
          <div>Your role was successfully linked</div>
          <div></div>
        </div>
      );
    } catch (e) {
      setLinks({});
      console.log(e);
    }
  };

  const wrapperRef = useRef(null);
  const clickedOutside = useOutsideAreaClick(wrapperRef);

  useEffect(() => {
    if (clickedOutside) {
      if (showUsers) {
        setShowUsers(false);
        setICIMSRoleValue('admin', '');
      }
    }
    // eslint-disable-next-line
  }, [clickedOutside]);

  // @ts-ignore
  return (
    <>
      {currentPane === 1 && (
        <>
          <div
            style={{
              minHeight: '380px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingTop: '10px',
            }}
          >
            <Stack>
              <div ref={icimsRolesDropdownRef}>
                <TextInput
                  label={"Role's Name"}
                  placeholder={'Type to search for a ICIMS role...'}
                  size={'md'}
                  {...register('roleName')}
                  onFocus={() => handleICIMSRolesFocus()}
                />
                <ICIMSRolesDropdown
                  icimsRoleSelected={handleICIMSRoleSelected}
                  show={showICIMSRoles}
                  needle={getValues('roleName')}
                />
              </div>

              {company?.role_type?.name === 'Hybrid (Sourcing & Turnkey)' && (
                <div>
                  <Title
                    style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#242424',
                      fontFamily: 'helvetica',
                      marginBottom: '10px',
                      padding: 0,
                    }}
                  >
                    Set to 'Sourcing only'
                  </Title>
                  <Grid style={isDesktop ? {} : { display: 'grid', gridTemplateColumns: '75% 22vw' }}>
                    <Grid.Col xs={9}>
                      <Stack>
                        <Text
                          sx={
                            isSourcingOnlyWatcher
                              ? { color: '#242424', paddingTop: 0 }
                              : { color: '#838485', paddingTop: 0 }
                          }
                        >
                          Rolebot does not engage with candidates when a role is set to 'Sourcing only'.
                          <span style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
                            {' '}
                            This can only be set during role creation.
                          </span>
                        </Text>
                      </Stack>
                    </Grid.Col>
                    <Grid.Col xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Group grow>
                        <Switch onLabel="" {...register('isSourcingOnly')} offLabel="" size="lg" />
                      </Group>
                    </Grid.Col>
                  </Grid>
                </div>
              )}

              <form onSubmit={handleSubmit(addAdmin)}>
                <div ref={wrapperRef}>
                  <TextInput
                    label={'Role Members'}
                    placeholder={'ex. bob@acme.com'}
                    size={'md'}
                    {...register('admin')}
                    // onSubmit={handleSubmit(onSubmit)}
                    error={errors?.admin?.message}
                    onFocus={() => handleUsersDropdownFocus()}
                    onClick={() => {
                      if (!showUsers) {
                        setShowUsers(true);
                      }
                    }}
                  />
                  <AddUsersToRoleDropDown
                    closeDropdown={handleCloseUsersDropdown}
                    userSelected={handleUserSelected}
                    show={showUsers}
                    needle={getValues('admin')}
                  />
                </div>
              </form>
            </Stack>
            <BodyScrollWrapper>
              <Stack spacing={5}>
                {admins.map((admin) => (
                  <AdminRow
                    key={admin}
                    onDelete={() => handleDelete(admin)}
                    email={admin}
                    removable={admin !== user?.user.email}
                    isError={emailErrors?.includes(admin)}
                  />
                ))}
              </Stack>
            </BodyScrollWrapper>
            {error &&
              (emailErrors?.length > 0 ? (
                <Alert title={'Oops!'} color={'orange'} style={{ marginTop: 15 }}>
                  {`The following user${
                    emailErrors?.length === 1 ? '' : 's'
                  } cannot be added because they are part of another company`}
                </Alert>
              ) : null)}
            {(error as linkError)?.data && emailErrors.length === 0 && (
              <Alert color={'red'}>{(error as linkError)?.data.message}</Alert>
            )}
            <Group position={'right'} mt={20}>
              {/* <RolebotButton type={'secondary'} onClick={() => setCurrentPane(2)}>
                Link jobs to Rolebot roles
              </RolebotButton> */}

              <RolebotButton
                type={'primary'}
                loaderPosition={'right'}
                disabled={value === null}
                onClick={handleSubmit(handleCreateRole)}
                loading={isLoading}
              >
                Create Role
              </RolebotButton>
            </Group>
          </div>
        </>
      )}
      {currentPane === 2 && (
        <div>
          <Group sx={{ color: '#4BA7C3' }} align={'center'} position={'left'} spacing={5} px={0}>
            <RolebotButton type={'link'} onClick={() => setCurrentPane(1)}>
              <FontAwesomeIcon icon={faChevronLeft} style={{ paddingRight: '6px' }} /> Launch a new role
            </RolebotButton>
          </Group>
          <div style={{ maxHeight: 600, overflowY: 'auto', overflowX: 'hidden', padding: '0 15px 0 0' }}>
            {/*@ts-ignore*/}
            {extraData && extraData?.icims_roles?.length !== _roles.length && (
              <>
                <Title className={classes.title} order={3}>
                  Link ICIMS jobs to Rolebot roles
                </Title>
                <Group>
                  <Stack sx={{ width: '100%' }}>
                    <Grid>
                      {_roles.map((role: IRole) => {
                        if (Boolean(role.icims_job)) return null;
                        return (
                          <Fragment key={role.id}>
                            <Grid.Col xs={6}>
                              <Stack spacing={0}>
                                <Text className={classes.roleNameText}>{role.name}</Text>
                                <Text color={'#838485'}>{role?.info?.office_locations}</Text>
                              </Stack>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                              <Group grow>
                                <div>
                                  <TextInput
                                    placeholder={'Type to search for a ICIMS role...'}
                                    size={'md'}
                                    //@ts-ignore
                                    value={linkableICIMSRolesInputFields[role.id] || ''}
                                    onChange={(event) =>
                                      handleLinkableICIMSRolesInputFieldsChanged(event.currentTarget.value, role.id)
                                    }
                                    onFocus={() => handleICIMSRolesFocus(role.id)}
                                  />
                                  <LinkableICIMSRolesDropdown
                                    closeAllDropdowns={handleCloseAllDropdowns}
                                    linkableICIMSRoleSelected={handleLinkableICIMSRoleSelected}
                                    show={showICIMSRoles && role.id === localRolebotRoleId}
                                    //@ts-ignore
                                    needle={linkableICIMSRolesInputFields[role.id] || ''}
                                  />
                                </div>
                              </Group>
                            </Grid.Col>
                          </Fragment>
                        );
                      })}
                    </Grid>
                  </Stack>
                </Group>
                {hasDuplicates && <Alert color={'red'}>You can only connect one Rolebot role to a ICIMS Job</Alert>}
                {someFailed && <Alert color={'yellow'}>We were not able to make some connections</Alert>}
                {rejectsError.length > 0 && <Alert color={'yellow'}>{rejectsError[0]?.reason?.data?.message}</Alert>}
                <Button
                  ml={'auto'}
                  mt={20}
                  sx={{ display: 'block' }}
                  onClick={handleMakeConnection}
                  loading={isLinking}
                >
                  Save Changes
                </Button>
              </>
            )}
            {/*@ts-ignore*/}
            {canUnlink && (
              <>
                <Title className={classes.title} order={3}>
                  Linked ICIMS jobs
                </Title>
                <Grid>
                  {_roles.map((role: IRole) => (
                    <LinkedRole
                      role={role}
                      reqId={
                        //@ts-ignore
                        role?.icims_job?.job_id
                      }
                    />
                  ))}
                </Grid>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const LinkedRole = ({ role, reqId }: { role: IRole; reqId: string | number }) => {
  console.log('linkedrole', role);
  const [unlink, { isLoading }] = useUnlinkICIMSJobMutation();
  const { classes } = useStyles();

  const handleUnlink = async () => {
    try {
      await unlink(role.id).unwrap();
      toastSuccess(
        <div className="toastContainer">
          <div>Your role was successfully unlinked</div>
          <div></div>
        </div>
      );
    } catch (e) {
      console.log('Error unlinking');
    }
  };

  if (!Boolean(role.icims_job)) return null;
  return (
    <>
      <Grid.Col xs={6}>
        <Stack spacing={0}>
          <Text className={classes.roleNameText}>{role.name}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col xs={6}>
        <Group>
          {/*@ts-ignore*/}
          <TextInput
            placeholder="Select a role..."
            value={`${role.icims_job.name} ${reqId}`}
            sx={{ flex: 1 }}
            disabled
          />
          {isLoading ? (
            <Loader size={'sm'} color={'violet'} />
          ) : (
            <FontAwesomeIcon icon={faLinkSlash} onClick={handleUnlink} />
          )}
        </Group>
      </Grid.Col>
    </>
  );
};

export default ICIMSTab;
