import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createStyles, Group, Text } from '@mantine/core';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setCurrentRoleId } from 'features/app/appSlice';
import useOpenModal from 'hooks/useOpenModal';
import useAppSelector from 'hooks/useAppSelector';

interface RoleNameProps {
  roleId: number;
  name: string;
  waitingForSetup: boolean;
  cardRequired: boolean;
  isNew?: boolean;
  handleSetupComplete: (isCompleted: boolean, roleId: number) => void;
  handleNavigateToRole: (roleId: number | string, roleName: string) => void;
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    flexWrap: 'nowrap',
  },
  role: {
    width: '80%',
    wordBreak: 'break-all',
    cursor: 'pointer',
    flex: 1,
    color: '#242424',
    position: 'relative',
    [theme.fn.largerThan('md')]: {
      fontSize: 19,
    },
    ':hover': {
      textDecoration: 'underline',
    },
  },
  dot: {
    '&:before': {
      content: `""`,
      display: 'block',
      position: 'absolute',
      top: 10,
      left: -20,
      backgroundColor: '#5757D2',
      width: 10,
      height: 10,
      borderRadius: 10,
    },
  },
}));

const RoleName = ({ roleId, name, waitingForSetup, cardRequired, isNew = false, handleSetupComplete, handleNavigateToRole }: RoleNameProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customerPlanIsActive = useAppSelector((state) => state.app.customerPlanIsActive);

  const { roleInfo, updatePayment, inactivePlanModal } = useOpenModal();

  const showLaunchRoleModal = () => {
    roleInfo({
      roleName: name,
      roleId,
      intitialStep: 1,
      withCloseButton: true,
      withTitle: true,
      onSetupComplete: handleSetupComplete,
      onNavigateToRole: handleNavigateToRole
    });
  };

  const showInactivePlanModal = () => {
    inactivePlanModal({});
  };

  const handleNavigate = () => {
    if (cardRequired) {
      updatePayment();
      return;
    }
    if (!customerPlanIsActive && waitingForSetup) {
      showInactivePlanModal();
      return;
    }
    if (waitingForSetup) {
      showLaunchRoleModal();
      return;
    }
    dispatch(setCurrentRoleId(roleId));
    navigate(roleId.toString(), {
      state: {
        role: name,
      },
    });
  };

  return (
    <Group className={classes.wrapper}>
      <Text className={classes.role} onClick={handleNavigate} lineClamp={1}>
        {isNew ? <span className={classes.dot} /> : null}
        {name}
      </Text>
    </Group>
  );
};

export default RoleName;
