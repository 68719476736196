import React, { useEffect } from 'react';
import { ContextModalProps } from '@mantine/modals';
import {Alert, Button, Group, NativeSelect, Stack, TextInput} from '@mantine/core';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useInviteUserMutation } from 'app/services/rolebot';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import RolebotButton from "../../public/Buttons/RolebotButton";
import useOpenModal from "../../../hooks/useOpenModal";

const InviteUserSchema = z.object({
  email: z.string().email('Please provide a valid email').nonempty('Email is required'),
  type: z.string().nonempty('You need to select a user type'),
});

type InviteUserFormPayload = z.infer<typeof InviteUserSchema>;

const InviteUser = ({ id, context }: ContextModalProps<Record<string, void>>) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
  } = useForm({
    defaultValues: {
      email: '',
      type: 'Member',
    },
    resolver: zodResolver(InviteUserSchema),
  });
  const { addUserToRoles } = useOpenModal();
  const [inviteUser, { isLoading, isSuccess, isError, reset: resetMutation, error }] = useInviteUserMutation();

  const openAddUserToRolesModal = (data: { email: string; customer_id: any; }) => {
    addUserToRoles({
      userName: '',
      userEmail: data.email,
      roles: [],
      customer_id: data.customer_id
    });
  };

  const onInvite = async (data: InviteUserFormPayload) => {
    resetMutation();
    const isAdmin = data.type === 'Admin';
    await inviteUser({
      email: data.email,
      is_admin: isAdmin,
    });
  };

  const onInviteAndAddToRoles = async (data: InviteUserFormPayload) => {
    resetMutation();
    const isAdmin = data.type === 'Admin';

    try {
      const response = await inviteUser({
        email: data.email,
        is_admin: isAdmin,
      });

      if ('error' in response && response.error !== null) {
        console.error('Error:', response.error);
      } else {

        context.closeModal(id);

        if ('data' in response) {

          const responseData = response as unknown as { data: { customer_id: number } };
          context.closeModal(id);

          openAddUserToRolesModal({ email: data.email, customer_id: responseData.data.customer_id });
        }
      }


    } catch (e) {
      console.log(e, "error");
    }
  };
  useEffect(() => {
    if (!isSuccess) return;
    resetForm();
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <Stack spacing={0}>
      <TitleWithClose id={id} title={'Invite new members'} />
      <form onSubmit={handleSubmit(onInvite)} id={'invite-form'}>
        <Stack spacing={15}>
          <TextInput
            label={'Invite with email'}
            size={'md'}
            {...register('email')}
            error={errors?.email?.message}
            placeholder={'jacob@example.com'}
          />
          <NativeSelect
            data={['Member', 'Admin']}
            label={'Invite as'}
            size={'md'}
            {...register('type')}
            error={errors?.type?.message}
          />
        </Stack>
      </form>
      {isError && //@ts-ignore
        (error.status === 400 ? (
          <Alert title={'Oops!'} color={'orange'}>
            {`The following user cannot be added because they already exist.`}
          </Alert>
        ) : (
          <Alert title={'Oops, something went wrong!'} color={'orange'}>
            Please try sending the invitation again.
          </Alert>
        ))}
      {/*{isError && (*/}
      {/*  <Alert color={'red'} mt={20}>*/}
      {/*    Oops, something went wrong. Please try sending the invitation again*/}
      {/*  </Alert>*/}
      {/*)}*/}
      {isSuccess && (
        <Alert color={'green'} mt={20}>
          Your invitation was sent.
        </Alert>
      )}
      <Group position={'right'} mt={40}>
        {/*<RolebotButton type={'secondary'} onClick={handleSubmit(onInviteAndAddToRoles)}>Send Invite & Add to Roles</RolebotButton>*/}

        <Button px={40} type={'submit'} form={'invite-form'} loading={isLoading}>
          Send Invite
        </Button>
      </Group>
    </Stack>
  );
};

export default InviteUser;
