import React from 'react';
import { createStyles, Text } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  name: {
    fontSize: theme.fontSizes.lg,
    color: '#242424',
  },
}));

const TalentName = ({ name = '' }: { name: string }) => {
  const { classes } = useStyles();
  return (
    <Text className={classes.name} lineClamp={1}>
      {name}
    </Text>
  );
};

export default TalentName;
