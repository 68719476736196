import React from 'react';
import { Group } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';

interface ExtLinkProps {
  to: string;
  title: string;
}

const ExtLink = ({ to, title }: ExtLinkProps) => {
  return (
    <Group spacing={6} align={'center'} noWrap>
      <RolebotButton type={'link'} sx={{ justifyContent: 'center', display: 'flex' }}>
        <FontAwesomeIcon icon={faExternalLink} style={{ height: '16px', marginRight: '10px', marginBottom: '2px' }}/>
        <a href={to} target={'_blank'} rel={'noreferrer'} style={{ color: '#249FC8', textDecoration: 'none' }}>
          {title}
        </a>
      </RolebotButton>
    </Group>
  );
};

export default ExtLink;
