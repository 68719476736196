import React from 'react';
import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps, useModals } from '@mantine/modals';
import { Button, Group, Text, Stack, createStyles } from '@mantine/core';
import { CronofyMissingTokenProps, RequireReactivationProps } from '../../../types/modals';
import { useNavigate } from 'react-router-dom';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { useGetCompanyQuery } from 'app/services/rolebot';

const useStyles = createStyles((theme, _params, getRef) => ({
  itemWrapper: {
    position: 'relative',
  },
  leftLine: {
    borderLeft: 'solid #C4C4C4 1px',
    position: 'absolute',
    zIndex: 0,
    top: '25px',
    left: 9,
    bottom: '25px',
  },
  inlineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '20px 0',
    color: '#242424',
  },
  message: {
    color: '#242424',
  },
}));

const RequireReactivation = ({ id, innerProps, context }: ContextModalProps<RequireReactivationProps>) => {
  const { classes } = useStyles();
  const { isAdmin, onClose } = innerProps;
  const navigate = useNavigate();
  const modals = useModals()
  const { data } = useGetCompanyQuery();

  const handleReactivate = () => {
    modals.closeAll()

    if (window.location.pathname === '/settings/billing') {
      modals.openContextModal('reactivationFormModal', {
        title: '',
        innerProps: {data},
        closeOnClickOutside: false,
        styles: {
          modal: { width: '602px', padding: '20px !important' },
        },
      });
    } else {
      navigate('/settings/billing', { state: { reactivate: true } })
    }
  }

  return (
    <Stack>
      <TitleWithClose id={id} title={'Reactivation Initiated - Action Required'} onClose={onClose} />
      {!isAdmin ? (
        <>
          <Text className={classes.message}>
            A plan reactivation has been initiated. To regain access to all platform functionalities, ask an
            administrator to review your company's plan details and update your account's payment method.
          </Text>
          <Text className={classes.message}>Once done, you'll be all set to start using the platform again.</Text>

          <Group position="right">
            <RolebotButton onClick={onClose} type="neutral">
              Okay
            </RolebotButton>
          </Group>
        </>
      ) : (
        <>
          <Text className={classes.message}>
          A plan reactivation has been initiated. To regain access to all platform functionalities, please review your plan details and update your payment method. 
          </Text>
          <Text className={classes.message}>Once done, you'll be all set to start using the platform again.</Text>

          <Group position="right">
            <RolebotButton onClick={handleReactivate}>
              Review & update details
            </RolebotButton>
          </Group>
        </>
      )}
    </Stack>
  );
};

export default RequireReactivation;
