import React from 'react';
import { Text } from '@mantine/core';

export const TalentSurfacedCell = ({ amount }: { amount: number }) => {
  return <Text color={amount === 0 ? '#B3B3B3' : '#242424'}>{amount}</Text>;
};

export const AwaitingReviewCell = ({ amount }: { amount: number }) => {
  return <Text color={amount === 0 ? '#B3B3B3' : '#242424'}>{amount}</Text>;
};

export const ConfirmedInterviews = ({ amount }: { amount: number }) => {
  return <Text color={amount === 0 ? '#B3B3B3' : '#242424'}>{amount}</Text>;
};
