import { ContextModalProps, useModals } from '@mantine/modals';
import TitleWithClose from '../components/TitleWithClose';
import { Grid, Group, Stack, Tabs, createStyles, Text, ScrollArea } from '@mantine/core';
import RolebotTabs from 'components/RolebotTabs/RolebotTabs';
import { LinkToATSProps } from 'types/modals';
import ATSSidebar from './ATSSidebar';
import Searchbar from './Searchbar/Searchbar';

import { Connect as GreenhouseConnect } from './Greenhouse/Connect';
import { Connect as LeverConnect } from './Lever/Connect'
import { Connect as SAPConnect } from './SAP/Connect'
import { Connect as ICIMSConnect } from './Icims/Connect'

import { Linked as GreenhouseLinked} from './Greenhouse/Linked';
import { Linked as LeverLinked} from './Lever/Linked';
import { Linked as SAPLinked} from './SAP/Linked';
import { Linked as ICIMSLinked} from './Icims/Linked';


import { useMemo, useState } from 'react';
import React from 'react';
import { useElementSize } from '@mantine/hooks';
import useAppSelector from 'hooks/useAppSelector';

const useStyles = createStyles((theme) => ({

    sidebar:{
        padding: '20px 20px 0 0',
        border: 'solid #DFE1E1',
        borderWidth: '0 1px 0 0'
    },

    fullHeight: {
        height: '100%',
    },

    wrapper: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
    },

    content: {
        padding: '0px 20px 0px 20px',
        width: '100%',
        overflow: 'hidden',
        flex: 1,
    },

    body: {
        display: 'flex',
        flexDirection: 'row',
    },

    title: {
        marginLeft: 'auto',
        lineHeight: 1.3,
        textAlign: 'center',
        fontSize: theme.fontSizes.lg,
        color: '#242424',
        fontFamily: 'Roboto',
        fontWeight: 500,
        [theme.fn.largerThan('sm')]: {
            textAlign: 'left',
            marginLeft: '0',
            fontSize: 19,
        },
    },

    subtitle: {
        marginLeft: 'auto',
        lineHeight: 1.3,
        textAlign: 'center',
        fontSize: theme.fontSizes.lg,
        color: '#838485',
        fontFamily: 'Helvetica',
        fontWeight: 400,
        overflowWrap: 'break-word',
        [theme.fn.largerThan('sm')]: {
            textAlign: 'left',
            marginLeft: '0',
            fontSize: 16,
        },
    }

}));

const LinkToATS = ({ id, context, innerProps }: ContextModalProps<LinkToATSProps>) => {
    const { classes, cx } = useStyles();
    const { greenhouse, lever, sap, icims } = innerProps
    const [activeOption, setactiveOption] = useState('connect')

    const selectedRole = useAppSelector((state) => state.app.ATSRoleSelected);
    const selectedRoleName = useAppSelector((state) => state.app.ATSRoleNameSelected);

    const { ref, height } = useElementSize();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden'}}>
            <TitleWithClose id={id} title={'ATS Manager'} mb={20} />
            <Stack classNames={{root:classes.wrapper}}>
                <RolebotTabs >
                    { greenhouse && <Tabs.Tab label="Greenhouse">
                        <div className={cx(classes.fullHeight, classes.body)}>
                            <div className={cx(classes.sidebar, classes.fullHeight)} ref={ref}>
                                <ATSSidebar activeOption={activeOption} onChange={setactiveOption}/>
                            </div>
                            <div className={cx(classes.content)} style={{height: height + 20, maxHeight: height + 20}}>
                                {activeOption === 'connect' && (
                                    <GreenhouseConnect roleId={selectedRole} roleName={selectedRoleName}/>
                                )}
                                {activeOption === 'link' && (
                                    <GreenhouseLinked roleId={selectedRole} roleName={selectedRoleName}/>
                                )}
                            </div>
                        </div>
                    </Tabs.Tab>}
                    { lever && <Tabs.Tab label="Lever">
                        <div className={cx(classes.fullHeight, classes.body)} >
                            <div className={cx(classes.sidebar, classes.fullHeight)} ref={ref}>
                                <ATSSidebar activeOption={activeOption} onChange={setactiveOption}/>
                            </div>

                            <div className={cx(classes.content)} style={{height: height + 20, maxHeight: height + 20}}>
                                {activeOption === 'connect' && (
                                    <LeverConnect roleId={selectedRole} roleName={selectedRoleName}/>
                                )}
                                {activeOption === 'link' && (
                                    <LeverLinked roleId={selectedRole} roleName={selectedRoleName}/>
                                )}
                            </div>
                        </div>
                    </Tabs.Tab>}
                    { sap && <Tabs.Tab label="SAP">
                        <div className={cx(classes.fullHeight, classes.body)} >
                            <div className={cx(classes.sidebar, classes.fullHeight)} ref={ref}>
                                <ATSSidebar activeOption={activeOption} onChange={setactiveOption}/>
                            </div>

                            <div className={cx(classes.content)} style={{height: height + 20, maxHeight: height + 20}}>
                                {activeOption === 'connect' && (
                                    <SAPConnect roleId={selectedRole} roleName={selectedRoleName}/>
                                )}
                                {activeOption === 'link' && (
                                    <SAPLinked roleId={selectedRole} roleName={selectedRoleName}/>
                                )}
                            </div>
                        </div>
                    </Tabs.Tab>}
                    { icims && <Tabs.Tab label="ICIMS">
                        <div className={cx(classes.fullHeight, classes.body)} >
                            <div className={cx(classes.sidebar, classes.fullHeight)} ref={ref}>
                                <ATSSidebar activeOption={activeOption} onChange={setactiveOption}/>
                            </div>

                            <div className={cx(classes.content)} style={{height: height + 20, maxHeight: height + 20}}>
                                {activeOption === 'connect' && (
                                    <ICIMSConnect roleId={selectedRole} roleName={selectedRoleName}/>
                                )}
                                {activeOption === 'link' && (
                                    <ICIMSLinked roleId={selectedRole} roleName={selectedRoleName}/>
                                )}
                            </div>
                        </div>
                    </Tabs.Tab>}
                    
                </RolebotTabs>
            </Stack>
        </div>
    )
}

export default LinkToATS