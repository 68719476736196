import React, { useRef, useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import PropTypes from 'prop-types';
import GreenhouseRoleItem from './GreenhouseRoleItem';
import useOutsideAreaClick from '../../../../pages/RoleStats/hooks/useOutsideAreaClick';
import { createStyles } from '@mantine/core';
import { useLazyGetGreenhouseRolesQuery, useGetCompanyQuery } from '../../../../app/services/rolebot';
import useDebounce from '../../../../pages/RoleStats/hooks/useDebounce';
import { RolebotClient } from 'api/client';
import Echo from 'utils/echo';

const useStyles = createStyles((theme) => ({
  GreenhouseRolesDropDownWrapper: {
    position: 'relative',
  },

  GreenhouseRolesDropDown: {
    border: 'solid 2px #DFE1E1',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 5px 5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: '1000',
    top: '0',
    left: '0',
    right: '0',
    padding: '5px 7px',
    maxHeight: '200px',
    overflowY: 'auto',
  },

  GreenhouseLoadingItem: {
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '0',
    color: '#838485',
    padding: '2px 10px',
  },
}));

const GreenhouseRolesDropDown = ({ show, greenhouseRoleSelected, needle, lostFocus }) => {
  const { classes } = useStyles();
  const searchDebounced = useDebounce(needle, 1000);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [getGreenhouseRoles] = useLazyGetGreenhouseRolesQuery();
  const [greenhouseRolesToShow, setGreenhouseRolesToShow] = useState([]);
  const { data } = useGetCompanyQuery();

  useEffect(() => {
    if (searchDebounced && searchDebounced.length > 0) {
      let stringCheck = /^(?=.*[a-zA-Z])(?=.*[0-9]).+|^[0-9]+/;
      if (stringCheck.test(searchDebounced)) {
        getGreenhouseRoles({ type: 'reqId', value: searchDebounced }).then((res) => {
          let rolesToShow = res?.data?.greenhouse_jobs?.filter(
            (x) =>
              x.greenhouse_job_name.toLowerCase().includes(needle.toLowerCase()) || x.requisition_id.includes(needle) || x.requisition_id.toUpperCase().includes(needle.toUpperCase())
          );
          setGreenhouseRolesToShow(rolesToShow);
          setIsLoading(false);
        });
      }     
      else {
        getGreenhouseRoles({ type: 'name', value: searchDebounced }).then((res) => {
          console.log(res, 'response');
          let rolesToShow = res?.data?.greenhouse_jobs?.filter(
            (x) =>
              x.greenhouse_job_name.toLowerCase().includes(needle.toLowerCase()) || x.requisition_id.includes(needle)
          );
          setGreenhouseRolesToShow(rolesToShow);
          setIsLoading(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [searchDebounced]);

  useEffect(() => {
    if (needle.length > 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [needle]);

  const greenhouseRoleRef = useRef(null);
  const clickedOutside = useOutsideAreaClick(greenhouseRoleRef);

  useEffect(() => {
    if (clickedOutside) {
      lostFocus();
    }
    // eslint-disable-next-line
  }, [clickedOutside]);

  const handleRefreshData = () => {
    setIsRefreshing(true);
    RolebotClient.get('/greenhouse/sync');
  };

  useEffect(() => {
    if (process.env.REACT_APP_WEB_SOCKETS_DISABLED === 'false' || !process.env.REACT_APP_WEB_SOCKETS_DISABLED) {
      if (isRefreshing) {
        Echo.channel(`greenhouse-company${data?.id}`).listen('GreenhouseCacheRefreshed', (e) => {
          setIsRefreshing(false);
        });
        return () => Echo.leaveChannel(`greenhouse-company${data?.id}`);
      }
    }
    // eslint-disable-next-line
  }, [data, isRefreshing]);

  return show && !isLoading && needle && greenhouseRolesToShow?.length > 0 ? (
    <div className={classes.GreenhouseRolesDropDownWrapper}>
      <div className={classes.GreenhouseRolesDropDown} ref={greenhouseRoleRef}>
        {greenhouseRolesToShow?.length > 0 &&
          greenhouseRolesToShow.map((x) => (
            <GreenhouseRoleItem role={x} key={x.id} greenhouseRoleSelected={greenhouseRoleSelected} />
          ))}
      </div>
    </div>
  ) : show && !isRefreshing && !isLoading && needle && greenhouseRolesToShow?.length === 0 ? (
    <div className={classes.GreenhouseRolesDropDownWrapper}>
      <div className={classes.GreenhouseRolesDropDown} ref={greenhouseRoleRef}>
        <div className={classes.GreenhouseLoadingItem}>
          No matches found for <span style={{ fontWeight: 500, color: '#242424', fontFamily: 'Roboto' }}>{searchDebounced}</span> on{' '}
          <span style={{ fontWeight: 500, color: '#242424', fontFamily: 'Roboto' }}>Greenhouse.</span>
          <a href="#" style={{ textDecoration: 'none', paddingLeft: '5px', color: '#40A5D0', fontFamily: 'Roboto', fontWeight: 100 }} onClick={() => handleRefreshData() }>
            Refresh data
          </a>
        </div>
      </div>
    </div>
  ) : show && isLoading ? (
    <div className={classes.GreenhouseRolesDropDownWrapper}>
      <div className={classes.GreenhouseRolesDropDown} ref={greenhouseRoleRef}>
        <div className={classes.GreenhouseLoadingItem}>
          <Loader style={{ verticalAlign: 'middle', marginRight: 5 }} color="gray" size="xs" /> Loading...
        </div>
      </div>
    </div>
  ) : show && isRefreshing ? (
    <div className={classes.GreenhouseRolesDropDownWrapper}>
      <div className={classes.GreenhouseRolesDropDown} ref={greenhouseRoleRef}>
        <div className={classes.GreenhouseLoadingItem}>
          <Loader style={{ verticalAlign: 'middle', marginRight: 5 }} color="gray" size="xs" />
          Refreshing data, this could take a few seconds...
        </div>
      </div>
    </div>
  ) : null;
};

export default GreenhouseRolesDropDown;

GreenhouseRolesDropDown.propTypes = {
  show: PropTypes.bool.isRequired,
  greenhouseRoleSelected: PropTypes.func.isRequired,
  lostFocus: PropTypes.func,
};

GreenhouseRolesDropDown.defaultProps = {
  lostFocus: () => {},
};
