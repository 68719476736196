import React, {forwardRef, Fragment, useEffect, useMemo, useRef, useState} from 'react';
import useAppSelector from 'hooks/useAppSelector';
import {
  Alert,
  Button,
  createStyles,
  Grid,
  Group,
  Loader,
  Stack,
  Text,
  TextInput,
  Title,
  Switch
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import {
  useCreateRoleFromLeverMutation, useGetCompanyQuery,
  useGetLeverRolesQuery,
  useGetRolesQuery,
  useGetUserQuery,
  useLinkLeverJobMutation,
  useUnlinkLeverJobMutation,
} from 'app/services/rolebot';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { useListState, useMediaQuery } from '@mantine/hooks';
import _ from 'lodash';
import SuccessLaunch from 'components/Modals/LaunchRole/SuccessLaunch';
import AdminRow from 'components/Modals/RoleAdmins/AdminRow';
import { IRole } from 'types';
import * as z from 'zod';
import useOutsideAreaClick from "../../../pages/RoleStats/hooks/useOutsideAreaClick";
import LeverRolesDropdown from "./Components/LeverRolesDropdown";
import LinkableLeverRolesDropdown from "./Components/LinkableLeverRolesDropdown";
import AddUsersToRoleDropDown from "./Components/AddUsersToRoleDropDown";
import BodyScrollWrapper from '../components/BodyScrollWrapper';
import { toastSuccess } from 'utils/toastify-messages';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faLinkSlash } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  button: {
    marginTop: 'auto',
    marginBottom: 10,
    width: '100%',
    [theme.fn.largerThan('md')]: {
      marginBottom: 0,
      width: 'fit-content',
    }
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '19px',
    fontWeight: 400,
    margin: '20px 0',
    color: '#242424'
  },
  chevron: {
    marginRight: '4px'
  },
  unlinkIcon: {
    cursor: 'pointer',
  },
  roleNameText: {
    color: '#242424'
  }

}));

const LaunchFormSchema = z.object({
  admin: z.union([z.string().email('Invalid email address'), z.literal('')]),
});

type LaunchFormPayload = z.infer<typeof LaunchFormSchema>;

//TODO: fix typescript in this file

const getAvailableJobs = (roles = [], connectedRoles = []) => {
  // @ts-ignore
  const unavailableIds = connectedRoles.map((r) => r.job_id);
  return roles.map((i: IRole) => {
    // @ts-ignore
    if (unavailableIds.includes(i.id)) {
      return {
        //@ts-ignore
        ...i,
        isAvailable: false,
        label: i.name,
        value: i.id,
      };
    }
    return {
      //@ts-ignore
      ...i,
      label: i.name,
      value: i.id,
      isAvailable: true,
    };
  });
};

const LeverTab = ({
  integrations = null
}) => {
  const { classes } = useStyles();
  const [currentPane, setCurrentPane] = useState(1);
  // This one needs a better name. I only use it to determine which should be the right dropdown that's showing when trying to link RB role to ATS role.
  const [localRolebotRoleId, setLocalRolebotRoleId] = useState(0);
  const [showLeverRoles, setShowLeverRoles] = useState(false);
  const leverRolesDropdownRef = useRef(null);
  const [linkableLeverRolesInputFields, setLinkableLeverRolesInputFields] = useState({});
  const clickedOutsideOfLeverRolesDropdown = useOutsideAreaClick(leverRolesDropdownRef);
  const [selectedLeverRole, setSelectedLeverRole] = useState<any>({});
  const [selectedLinkableLeverRoles, setSelectedLinkableLeverRoles] = useState<any>([]);
  const requiresOauth = useAppSelector((state) => state.app.requiresLeverOauth);
  const modals = useModals();
  const { data: user } = useGetUserQuery();
  const { data: leverRoles } = useGetLeverRolesQuery({type: "name", value: "", state: "published"});
  const { data } = useGetCompanyQuery();

  const isAdmin = useMemo(() => {
    return Boolean(data?.customer_user?.is_admin);
  }, [data]);

  const { combinedData: activeRoles, extraData } = useInfiniteScroll(useGetRolesQuery, {
    type: 'active',
  });
  const [links, setLinks] = useState({});
  const [, setRepeatedFields] = useState([]);
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [someFailed, setSomeFailed] = useState(false);
  const [rejectsError, setRejectsError] = useState<any>([]);
  const [linkRole, { isLoading: isLinking, reset: resetMutation }] = useLinkLeverJobMutation();
  const [showUsers, setShowUsers] = useState(false);
  const [emailErrors, setEmailErrors] = useState<any>([]);
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const roles = useMemo(
    //@ts-ignore
    () => getAvailableJobs(leverRoles?.lever_jobs, integrations?.lever?.lever_jobs),
    [leverRoles, integrations]
  );

    const wrapperRef = useRef(null);
    const clickedOutside = useOutsideAreaClick(wrapperRef);

    useEffect(() => {
        if (clickedOutside) {
            if(showUsers) {
                setShowUsers(false);
                setLeverRoleValue('admin', "");
            }
        }
        // eslint-disable-next-line
    }, [clickedOutside]);

  //@ts-ignore
  // const canUnlink = useMemo(() => extraData && extraData?.lever_roles?.length > 0, [extraData]);
  const canUnlink = useMemo(() => integrations && integrations?.lever?.lever_jobs?.length > 0, [integrations]);

  const _roles = useMemo(
    () =>
      //@ts-ignore
      activeRoles.map((role) => {
        const index = roles.findIndex((r) => r.id === role.lever_job?.job_id);
        if (index === -1) {
          return role;
        }
        return {
          ...role,
          lever_job: {
            ...role.lever_job,
            name: roles[index].name,
          },
        };
      }),
    [activeRoles, roles]
  );

  const [value, setValue] = useState(null);

  const {
    handleSubmit,
    getValues,
    setValue: setLeverRoleValue,
    register,
    resetField,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      roleName: '',
      admin: '',
      isSourcingOnly: false,
    },
    resolver: zodResolver(LaunchFormSchema),
  });

  const [launchRole, { isSuccess, isError: createRoleFromLeverError, error, isLoading, reset: resetCreateRole }] = useCreateRoleFromLeverMutation();
  const [admins, handlers] = useListState<string>([]);
  const [roleNameWatcher] = watch([
    "roleName"
  ]);

  const [adminWatcher] = watch([
    "admin"
  ]);

  const [isSourcingOnlyWatcher] = watch(['isSourcingOnly']);

  useEffect(() => {
    if (roleNameWatcher === "") {
      setValue(null);
    }
    setLeverRoleValue('roleName', roleNameWatcher);
    // eslint-disable-next-line
  }, [roleNameWatcher]);

  useEffect(() => {
    setLeverRoleValue('admin', adminWatcher);
    // eslint-disable-next-line
  }, [adminWatcher])

  useEffect(() => {
    if (user?.user) {
      admins.includes(user.user.email) || handlers.append(user.user.email);
    }
    // eslint-disable-next-line
  }, [admins, user, handlers]);

  useEffect(() => {
    if (clickedOutsideOfLeverRolesDropdown) {
      handleClickedOutsideOfLeverRolesDropdown();
    }
    // eslint-disable-next-line
  }, [clickedOutsideOfLeverRolesDropdown]);

  useEffect(() => {
    if (emailErrors.length === 0) {
      resetCreateRole();
    }
    // eslint-disable-next-line
  }, [emailErrors]);

  const handleClickedOutsideOfLeverRolesDropdown = () => {
    setShowLeverRoles(false);
    if (selectedLeverRole?.lever_job_name !== getValues('roleName')) {
      setLeverRoleValue('roleName', '');
      setValue(null);
      setSelectedLeverRole({});
    }
  };

  const handleLinkableLeverRolesInputFieldsChanged = (e: any, roleId: number) => {
    setLinkableLeverRolesInputFields((previousValue) => {
      return {...previousValue, [roleId]: e};
    });
    if (e === '') {
      const newLinks = { ...links };
      // @ts-ignore
      delete newLinks[roleId];
      setLinks(newLinks);
    }
  }

  const handleCloseUsersDropdown = () => {
    //setShowUsers(false);
    //setLeverRoleValue('admin', "");
  };

  const handleUsersDropdownFocus = () => {
    setShowUsers(true);
  };

  const handleUserSelected = (user: any) => {
    setLeverRoleValue("admin", user.email);
    setShowUsers(false);
    if (!admins.includes(user.email)) {
      handlers.append(user.email);
    }
    resetField('admin');
  };

  const addAdmin = (data: LaunchFormPayload) => {
    if (!data.admin) return;
    if (admins.includes(data.admin!)) return;
    handlers.append(data.admin!);
    resetField('admin');
  };

  const onReset = () => {
    reset();
    resetCreateRole();
    setValue(null);
    const userEmail = user?.user.email.trim() || null;
    if (userEmail) {
      handlers.setState([userEmail]);
    }
  };

  const handleCreateRole = async (payload: LaunchFormPayload) => {
    setEmailErrors([]);
    if (!selectedLeverRole || !value) return;
    try {
        /* Modified amount of interviews from 1 to 100 as requested on ticket T3-1483 */
      await launchRole({
        lever_job_id: selectedLeverRole?.lever_job_id,
        name: selectedLeverRole?.lever_job_name,
        interviews: 100,
        price: 0,
        admins,
        ...(data?.role_type?.name === 'Hybrid (Sourcing & Turnkey)' &&
          (isSourcingOnlyWatcher === true ? { sourcing_only: 1 } : { sourcing_only: 0 })),
      }).unwrap();
      toastSuccess(
        <div className="toastContainer">
          <div>Success!</div>
          <div>Your new role has been created</div>
        </div>
      );
      modals.closeAll()
    } catch (e: any) {
      console.log('Error launching role');
      if (e.data.type === 'email-error') {
        setEmailErrors(e.data.message);
      }
    }
  };

  const handleLeverRoleSelected = (role: any) => {
    setLeverRoleValue('roleName', role.lever_job_name);
    setValue(role.lever_job_id);
    setSelectedLeverRole(role);

    setShowLeverRoles(false);
  };

  const handleLinkableLeverRoleSelected = (role: any) => {
    setSelectedLinkableLeverRoles((previousValue: any) =>{
      return {...previousValue, [localRolebotRoleId]: role}
    });
    setLinkableLeverRolesInputFields((previousValue) => {
      return {...previousValue, [localRolebotRoleId]: role.lever_job_name};
    });
    onAdd(localRolebotRoleId, role.lever_job_id);

    setShowLeverRoles(false);
  };

  const handleLeverRolesFocus = (roleId = 0) => {
    setShowLeverRoles(true);
    setLocalRolebotRoleId(roleId);
  };

  const handleCloseAllDropdowns = () => {
    setLinkableLeverRolesInputFields((previousValue) => {
      // @ts-ignore
      if (previousValue[localRolebotRoleId] !== selectedLinkableLeverRoles[localRolebotRoleId]?.lever_job_name) {
        const newLinks = { ...links };
        // @ts-ignore
        delete newLinks[localRolebotRoleId];
        setLinks(newLinks);
      }
      // @ts-ignore
      return {...previousValue, [localRolebotRoleId]: previousValue[localRolebotRoleId] === selectedLinkableLeverRoles[localRolebotRoleId]?.lever_job_name ? previousValue[localRolebotRoleId] : ""};
    });
    setShowLeverRoles(false);
  };

  const handleDelete = (admin: string) => {
    //@ts-ignore
    let newEmailErrors = emailErrors.filter(x => x !== admin);
    setEmailErrors(newEmailErrors);
    const newState = admins.filter((a) => a !== admin);
    handlers.setState(newState);
  };

  const onAdd = (roleId: number, atsId: number) => {
    const newLinks = { ...links };
    //@ts-ignore
    const item = newLinks[roleId];
    if (item) {
      const wasSameItemClicked = item.roleId === roleId;
      if (wasSameItemClicked) {
        //@ts-ignore
        delete newLinks[roleId];
      }
    } else {
      //@ts-ignore
      newLinks[roleId] = {
        roleId,
        atsId,
      };
    }
    setLinks(newLinks);
  };

  const handleMakeConnection = async () => {
    setSomeFailed(false);
    setRepeatedFields([]);
    setHasDuplicates(false);
    setRejectsError([]);
    // look if there is no repeated data first.
    const atsRolesIds: any[] = [];

    _.mapValues(links, (v) => {
      //@ts-ignore
      atsRolesIds.push(v.atsId);
    });
    // do nothing if we have no data.
    if (atsRolesIds.length === 0) return;

    // return early if there are duplicates.
    const uniqueIds = [...new Set(atsRolesIds)];
    let duplicates = [...atsRolesIds];
    uniqueIds.forEach((item) => {
      const i = duplicates.indexOf(item);
      duplicates = duplicates.slice(0, i).concat(duplicates.slice(i + 1, duplicates.length));
    });
    if (duplicates.length > 0) {
      setHasDuplicates(true);
      return;
    }

    // get params for each request we are going to make.
    const connectionParams: { roleId: number; atsId: number }[] = []; // has objects of type: {roleId, atsId}
    _.mapValues(links, (v) => {
      //@ts-ignore
      connectionParams.push({ roleId: v.roleId, atsId: v.atsId });
    });
    try {
      const results = await Promise.allSettled(
        connectionParams.map(({ roleId, atsId }) =>
          linkRole({
            roleId,
            payload: {
              lever_job_id: atsId,
              on_hold: 0,
            },
          }).unwrap()
        )
      );
      const fulfilled = results.filter((p) => p.status === 'fulfilled');
      const rejects = results.filter((p) => p.status === 'rejected');
      if (rejects.length > 0) {
        setRejectsError(rejects);
      }
      //@ts-ignore
      const rejectedIds = rejects.map((r) => r.reason.meta.roleId);
      //@ts-ignore
      setRepeatedFields(rejectedIds);
      if (fulfilled.length > 0 && rejects.length > 0) {
        setSomeFailed(true);
        return;
      }
      if (fulfilled.length === 0 && rejects.length > 0) {
        throw new Error();
      }
      toastSuccess(
        <div className="toastContainer">
          <div>Your role was successfully linked</div>
          <div></div>
        </div>
      );
    } catch (e) {
      setLinks({});
      console.log(e);
    }
  };

  if (requiresOauth && isAdmin) {
    return (
      <Stack align={'center'} p={40} spacing={30}>
        <Text color={'#242424'}>To launch a role from Lever, please authenticate before.</Text>
        <Button
          component="a"
          href={`${process.env.REACT_APP_API_URL}/oauth/redirect?provider=lever`}
          sx={{ alignSelf: 'center' }}
          px={40}
        >
          Authenticate
        </Button>
      </Stack>
    );
  } else if(requiresOauth && !isAdmin) {
    return (<Stack align={'center'} p={40} spacing={30}>
      <Text color={'#242424'}>Please contact your company's admin to re-authenticate with Lever.</Text>
    </Stack>)
  }


  return (
    <>
      {currentPane === 1 && (
        <>
            <div style={{minHeight: '380px', display: 'flex', flexDirection:'column', justifyContent: 'space-between'}}>
                <Stack mt={10}>
                  <div ref={leverRolesDropdownRef}>
                    <TextInput
                      label={"Role's Name"}
                      placeholder={"Type to search for a Lever role..."}
                      size={'md'}
                      {...register('roleName')}
                      onFocus={() => handleLeverRolesFocus()}
                    />
                    <LeverRolesDropdown
                      leverRoleSelected={handleLeverRoleSelected}
                      show={showLeverRoles}
                      needle={getValues('roleName')}
                    />
                  </div>

                  {data?.role_type?.name === 'Hybrid (Sourcing & Turnkey)' && (
                    <div>
                      <Title style={{ fontSize: '16px', fontWeight: 400 , color: '#242424',fontFamily: 'helvetica', marginBottom: "10px", padding: 0 }}>Set to 'Sourcing only'</Title>
                      <Grid style={ isDesktop ? {} : {display: 'grid', gridTemplateColumns: "75% 22vw" }}>
                        <Grid.Col xs={9}>
                          <Stack>
                            <Text
                              sx={
                                isSourcingOnlyWatcher
                                  ? { color: '#242424', paddingTop: 0 }
                                  : { color: '#838485', paddingTop: 0 }
                              }
                            >
                              Rolebot does not engage with candidates when a role is set to 'Sourcing only'.
                              <span style={{fontFamily: 'Roboto', fontWeight: 500}}> This can only be set during role creation.</span>
                            </Text>
                          </Stack>
                        </Grid.Col>
                        <Grid.Col xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Group grow>
                            <Switch onLabel="" {...register('isSourcingOnly')} offLabel="" size="lg" />
                          </Group>
                        </Grid.Col>
                      </Grid>
                    </div>
                  )}

                  <form onSubmit={handleSubmit(addAdmin)}>
                      <div ref={wrapperRef}>
                        <TextInput
                          label={'Role Members'}
                          placeholder={'ex. bob@acme.com'}
                          size={'md'}
                          {...register('admin')}
                          // onSubmit={handleSubmit(onSubmit)}
                          error={errors?.admin?.message}
                          onFocus={() => handleUsersDropdownFocus()}
                          onClick={() => {
                            if (!showUsers) {
                              setShowUsers(true);
                            }
                          }}
                        />
                        <AddUsersToRoleDropDown
                          closeDropdown={handleCloseUsersDropdown}
                          userSelected={handleUserSelected}
                          show={showUsers}
                          needle={getValues('admin')}
                        />
                      </div>
                  </form>
                </Stack>
                <BodyScrollWrapper>
                  <Stack spacing={5}>
                    {admins.map((admin) => (
                      <AdminRow
                        key={admin}
                        onDelete={() => handleDelete(admin)}
                        email={admin}
                        removable={admin !== user?.user.email}
                        isError={emailErrors?.includes(admin)}
                      />
                    ))}
                  </Stack>
                </BodyScrollWrapper>
                {error &&
                (emailErrors?.length > 0 ? (
                        <Alert title={'Oops!'} color={'orange'} style={{marginTop: 15}}>
                          {`The following user${
                              emailErrors?.length === 1 ? '' : 's'
                          } cannot be added because they are part of another company`}
                        </Alert>
                    ) : null
                )}
                {/*@ts-ignore*/}
                {error && emailErrors?.length === 0 && <Alert color={'red'}>{error?.data.message}</Alert>}
              <Group position={'right'} mt={20}>
               
                {/* <RolebotButton type={'secondary'} onClick={() => setCurrentPane(2)}>Link jobs to Rolebot roles</RolebotButton> */}

                <RolebotButton type={'primary'} loaderPosition={'right'}
                    disabled={value === null}
                    onClick={handleSubmit(handleCreateRole)}
                    loading={isLoading}>Create Role</RolebotButton>

              </Group>
            </div>
        </>
      )}
      {currentPane === 2 && (
        <div >
          <Group sx={{ color: '#4BA7C3'}} align={'flex-start'} position={'left'} spacing={5}>
            

          <RolebotButton
                      type={'link'}
                      onClick={() => setCurrentPane(1)}
                      
                    >
                      <FontAwesomeIcon icon={faChevronLeft} style={{paddingRight : '6px'}} /> Launch a new role
          </RolebotButton>
          </Group>
          <div style={{ maxHeight: 600, overflowY: 'auto', overflowX: 'hidden', padding: '0 15px 0 0'}}>

            {/*@ts-ignore*/}
            {extraData && extraData?.lever_roles?.length !== _roles.length &&
            <>
              <Title order={3} className={classes.title}>
                Link Lever jobs to Rolebot roles
              </Title>
              <Group>
                <Stack sx={{ width: '100%' }} >
                  <Grid>
                    {_roles.map((role: IRole) => {
                      if (Boolean(role.lever_job)) return null;
                      return (
                        <Fragment key={role.id}>
                          <Grid.Col xs={6}>
                            <Stack spacing={0}>
                              <Text className={classes.roleNameText}>{role.name}</Text>
                              <Text color={'#838485'}>{role?.info?.office_locations}</Text>
                            </Stack>
                          </Grid.Col>
                          <Grid.Col xs={6}>
                            <Group grow>
                              <div>
                                <TextInput
                                  placeholder={"Type to search for a Lever role..."}
                                  size={'md'}
                                  //@ts-ignore
                                  value={linkableLeverRolesInputFields[role.id] || ""}
                                  onChange={(event) => handleLinkableLeverRolesInputFieldsChanged(event.currentTarget.value, role.id)}
                                  onFocus={() => handleLeverRolesFocus(role.id)}
                                />
                                <LinkableLeverRolesDropdown
                                  closeAllDropdowns={handleCloseAllDropdowns}
                                  linkableLeverRoleSelected={handleLinkableLeverRoleSelected}
                                  show={showLeverRoles && role.id === localRolebotRoleId}
                                  //@ts-ignore
                                  needle={linkableLeverRolesInputFields[role.id] || ""}
                                />
                              </div>
                            </Group>
                          </Grid.Col>
                        </Fragment>
                      );
                    })}
                  </Grid>
                </Stack>
              </Group>
              {hasDuplicates && <Alert color={'red'}>You can only connect one Rolebot role to a lever Job</Alert>}
              {someFailed && <Alert color={'yellow'}>We were not able to make some connections</Alert>}
              {rejectsError.length > 0 && <Alert color={'yellow'}>{rejectsError[0]?.reason?.data?.message}</Alert>}
              <Button ml={'auto'} mt={20} sx={{ display: 'block' }} onClick={handleMakeConnection} loading={isLinking}>
                Save Changes
              </Button>
            </>
            }
            {/*@ts-ignore*/}
            {canUnlink && (
              <>
                <Title order={3} className={classes.title}>
                  Linked Lever jobs
                </Title>
                <Grid>
                  {_roles.map((role: IRole) => (
                    <LinkedRole role={role} key={role.id} />
                  ))}
                </Grid>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  name: string;
  _id: number;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ name, _id, className, ...others }: ItemProps, ref) => (
  <Group
    ref={ref}
    key={_id + name}
    {...others}
    sx={() => ({
      gap: 6,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
    })}
    px={10}
    py={12}
  >
    <Text color={'#4F4F4F'} component={'span'} sx={{ lineHeight: 1.3 }}>
      {name}{' '}
    </Text>
  </Group>
));

const LinkedRole = ({ role }: { role: IRole }) => {
  const [unlink, { isLoading }] = useUnlinkLeverJobMutation();
  const { classes } = useStyles();

  const handleUnlink = async () => {
    try {
      await unlink(role.id).unwrap();
      toastSuccess(
        <div className="toastContainer">
          <div>Your role was successfully unlinked</div>
          <div></div>
        </div>
      );
    } catch (e) {
      console.log('Error unlinking');
    }
  };

  if (!Boolean(role.lever_job)) return null;
  return (
    <>
      <Grid.Col xs={6}>
        <Stack spacing={0}>
          <Text className={classes.roleNameText}>{role.name}</Text>
          <Text color={'#838485'}>{role?.info?.office_locations}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col xs={6}>
        <Group>
          {/*@ts-ignore*/}
          <TextInput placeholder="Select a role..." value={role.lever_job.name} sx={{ flex: 1 }} disabled />
          {isLoading ? <Loader size={'sm'} color={'violet'} /> : <FontAwesomeIcon icon={faLinkSlash} onClick={handleUnlink} />}

        </Group>
      </Grid.Col>
    </>
  );
};

interface UnlinkedRoleProps extends IRole {
  isAvailable: boolean;
  handleAdd: (roleId: number, leverId: number) => void;
  componentRef: any;
  mainRole: IRole;
}

//@ts-ignore
const UnlinkedRole = ({ name, id, isAvailable, handleAdd, componentRef, mainRole, ...others }: UnlinkedRoleProps) => {
  return (
    <div
      {...others}
      key={id + name}
      onMouseDown={(e) => {
        if (!isAvailable) return;
        //@ts-ignores
        others?.onMouseDown(e);
        handleAdd(mainRole.id, id);
      }}
      style={{ cursor: isAvailable ? 'pointer' : 'not-allowed' }}
    >
      <Group
        noWrap
        sx={() => ({
          '&:hover': {
            backgroundColor: '#F5F5F5',
          },
        })}
      >
        <Text color={'#4F4F4F'}>{name}</Text>
        <Text size={'sm'} color={'#959595'} lineClamp={1}>
          Req ID {id}
        </Text>
      </Group>
    </div>
  );
};

export default LeverTab;
