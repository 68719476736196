import React from "react";
// import classes from "./WidgetWrapper.css";
import {createStyles} from "@mantine/core";

const useStyles = createStyles(() => ({
    widgetWrapper: {
    position: 'relative',
    textAlign: 'center',
    borderRadius: '25px',
    boxShadow: '0 0 6px 1px lightgray',
    marginBottom: '15px',
    padding: '30px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
}
}));

const WidgetWrapper = (props) => {
    const { classes } = useStyles();

    return (
        <div className={classes.widgetWrapper} {...props}>
            {props.children}
        </div>
    );
};

export default WidgetWrapper;
