import React from 'react';
import { createStyles, Group, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  wrapper: {
    [theme.fn.largerThan('md')]: {
      maxWidth: '100%',
    },
  },
  locationIcon: {
    height: '15px',
    width: '15px',
    paddingBottom: '4px',
    color: '#838485'
  }
}));

interface RoleLocationProps {
  location?: string;
  className?: string;
}

const RoleLocation = ({ location = '', className }: RoleLocationProps) => {
  const { classes, cx } = useStyles();

  // sorry for this...
  if (!location || location === 'Contact info') return <span />;

  return (
    <Group noWrap align="center" spacing={5} className={cx(className, classes.wrapper)}>
      <Group sx={{ width: 20 }} spacing={0}>
        <FontAwesomeIcon icon={faLocationDot} className={classes.locationIcon} />
      </Group>
      <Text color="#838485" size="md" lineClamp={1} sx={{ wordBreak: 'break-all'}}>
        {location}
      </Text>
    </Group>
  );
};

export default RoleLocation;
