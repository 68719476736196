import React, { useEffect, useReducer } from 'react';
import { getIndexRange, isIndexRangeInHayStack } from './hours';
import { nanoid } from 'nanoid';
import { ContextModalProps } from '@mantine/modals';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { Alert, Button, createStyles, Group, Stack, Text } from '@mantine/core';
import DayButton from 'components/Modals/CronofyModal/DayButton';
import TimeSlot from './TimeSlot';
import {
  useCreateCronofyEventMutation,
  useGetCronofyAvailabilityRuleQuery,
  useGetCronofyUserCalendarQuery,
} from 'app/services/rolebot';

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {},
  dayCirclesArea: {
    justifyContent: 'left',
  },
  submitButton: {
    marginTop: '20px',
  },
  dayText: {
    fontFamily: 'helvetica',
    fontSize: '16px',
    minWidth: '100px',
    fontWeight: 'normal',
    textTransform: 'capitalize',
  },
  mainStackWindow: {
    // maxHeight: '300px',
    // overflow: 'auto'
  },
}));

type ACTION_TYPES =
  | { type: 'addSlot'; payload: { dayId: string; startTime: string | null; endTime: string | null } }
  | { type: 'removeSlot'; payload: { dayId: string; slotId: string } }
  | { type: 'removeAllSlots'; payload: { dayId: string } }
  | {
      type: 'setStartDate';
      payload: { dayId: string; slotId: string; startTime: string | null; endTime: string | null };
    }
  | { type: 'validate' }
  | { type: 'initiated' }
  | { type: 'setupCopySlotId' }
  | { type: 'copyToAllFields'; payload: string };

const initialState = {
  initiated: false,
  readyForSubmit: false,
  validateDates: false,
  setupCopyToAllId: false,
  copySlotId: null,
  times: [
    {
      dayId: nanoid(),
      day: 'sunday',
      slots: [],
    },
    {
      dayId: nanoid(),
      day: 'monday',
      slots: [],
    },
    {
      dayId: nanoid(),
      day: 'tuesday',
      slots: [],
    },
    {
      dayId: nanoid(),
      day: 'wednesday',
      slots: [],
    },
    {
      dayId: nanoid(),
      day: 'thursday',
      slots: [],
    },
    {
      dayId: nanoid(),
      day: 'friday',
      slots: [],
    },
    {
      dayId: nanoid(),
      day: 'saturday',
      slots: [],
    },
  ],
};

const reducer = (state: typeof initialState, action: ACTION_TYPES) => {
  switch (action.type) {
    case 'initiated':
      return {
        ...state,
        initiated: true,
      };
    case 'addSlot':
      return {
        ...state,
        validateDates: true,
        times: state.times.map((x: any) =>
          //loop the times in state
          // if the date in payload and this iteration id do not match skip
          // if id does match add this time stamp to its current array of time stamps
          x.dayId !== action.payload.dayId
            ? x
            : {
                ...x,
                slots: [
                  ...x.slots,
                  {
                    slotId: nanoid(),
                    error: false,
                    startTime: action.payload.startTime,
                    endTime: action.payload.endTime,
                  },
                ],
              }
        ),
      };
    case 'removeSlot':
      return {
        ...state,
        validateDates: true,
        times: state.times.map((d: any) =>
          d.dayId !== action.payload.dayId
            ? d
            : {
                ...d,
                slots: d.slots.filter((x: any) => x.slotId !== action.payload.slotId),
              }
        ),
      };
    case 'removeAllSlots':
      return {
        ...state,
        validateDates: true,
        times: state.times.map((d: any) =>
          d.dayId !== action.payload.dayId
            ? d
            : {
                ...d,
                slots: [],
              }
        ),
      };
    case 'setStartDate':
      return {
        ...state,
        validateDates: true,
        times: state.times.map((x: any) =>
          //loop the times in state
          // if the date in payload and this iteration id do not match skip
          // if id does match add this time stamp to its current array of time stamps
          x.dayId !== action.payload.dayId
            ? x
            : {
                ...x,
                slots: x.slots.map((s: any) => {
                  if (s.slotId !== action.payload.slotId) {
                    return s;
                  } else {
                    return {
                      ...s,
                      startTime: action.payload.startTime,
                      endTime: action.payload.endTime,
                    };
                  }
                }),
              }
        ),
      };
    case 'validate': {
      let readyForSubmit = true;

      const times = state.times.map((d: any) => {
        if (d.slots.length === 0) {
          // if this day has an empty slots array skip it.
          return d;
        }

        let trackingArray = [] as number[];

        return {
          ...d,
          slots: d.slots.map((s: any) => {
            let error = false;

            if (s.startTime === null || s.endTime == null) {
              readyForSubmit = false; // is any of these slots are null we mark as not ready for submit
            }

            const timeIndexRange = getIndexRange(s.startTime, s.endTime);

            if (isIndexRangeInHayStack(timeIndexRange, trackingArray)) {
              readyForSubmit = false; // mark form as not ready for submit
              error = true; // if there is an overlap we mark this slot as error and ignore it
            } else {
              trackingArray = [...trackingArray, ...timeIndexRange]; // if no overlap save these range index for next test
              error = false; // mark as no error just in case it was marked bad in another validation.
            }

            return {
              ...s,
              error,
            };
          }),
        };
      });

      return {
        ...state,
        setupCopyToAllId: true,
        copySlotId: null,
        validateDates: false,
        readyForSubmit,
        times,
      };
    }
    case 'setupCopySlotId': {
      // this gets the first day with slots enabled
      const slots: any = state?.times?.find((d: any) => d.slots.length > 0)?.slots;

      //we check the above slots to make sure there are no nulls or errors in any fields
      let slotsFilled = true;
      slots?.forEach((s: any) => {
        if (s.startTime === null || s.endTime === null || s.error) {
          slotsFilled = false;
        }
      });

      // This section makes sure you have more than days open so you have something to copy to.
      let slotCount = 0;
      state.times.forEach((c: any) => {
        if (c.slots.length > 0) {
          slotCount++;
        }
      });

      return {
        ...state,
        setupCopyToAllId: false,
        copySlotId: !!slots && slotCount > 1 && slotsFilled ? slots[0].slotId : null,
      };
    }
    case 'copyToAllFields': {
      // get the first day with slots
      const slotsMaster = state.times?.find((d: any) => d.slots.length > 0)?.slots;

      if (slotsMaster) {
        return {
          //if slotsMaster we copy these slots to all days with slots current open
          ...state,
          validateDates: true,
          times: state.times.map((d: any) => {
            if (d.slots.length === 0) {
              return d;
            }
            return {
              ...d,
              slots: slotsMaster.map((sm: any) => {
                return {
                  ...sm,
                  slotId: nanoid(), // make sure we udpate the ID!
                };
              }),
            };
          }),
        };
      }

      // If no slot masters just return original state.
      return {
        ...state,
      };
    }
    default:
      throw new Error();
  }
};

const CronofyModal = ({ id }: ContextModalProps) => {
  const { classes } = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: calendars } = useGetCronofyUserCalendarQuery();
  const [updateRules, { isLoading: updateIsLoading, isSuccess, isError }] = useCreateCronofyEventMutation();
  const { data: availability, isSuccess: availabilityIsSuccess } = useGetCronofyAvailabilityRuleQuery('default');

  useEffect(() => {
    if (availabilityIsSuccess && !state.initiated) {
      dispatch({ type: 'initiated' });
      const rules = availability?.availability_rules?.find((x: any) => x.availability_rule_id === 'default');

      if (rules) {
        rules?.weekly_periods.forEach((rules: any) => {
          const day = rules.day;
          const dayId = state.times.find((x: any) => x.day === day).dayId;
          const { start_time: startTime, end_time: endTime } = rules;
          dispatch({ type: 'addSlot', payload: { dayId, startTime, endTime } });
        });
      }
    }
    // eslint-disable-next-line
  }, [availability, availabilityIsSuccess, state.initiated, state.times]);

  useEffect(() => {
    if (state.validateDates) {
      dispatch({ type: 'validate' });
    }
    // eslint-disable-next-line
  }, [state.validateDates]);

  useEffect(() => {
    if (state.setupCopyToAllId) {
      dispatch({ type: 'setupCopySlotId' });
    }
    // eslint-disable-next-line
  }, [state.setupCopyToAllId]);

  const handleSetDate = (dayId: string, slotId: string, startTime: string | null, endTime: string | null) => {
    dispatch({ type: 'setStartDate', payload: { dayId, slotId, startTime, endTime } });
  };

  const handleAddSlot = (dayId: string) => {
    dispatch({
      type: 'addSlot',
      payload: {
        dayId,
        endTime: null,
        startTime: null,
      },
    });
  };

  const handleRemoveSlot = (dayId: string, slotId: string) => {
    dispatch({ type: 'removeSlot', payload: { dayId, slotId } });
  };

  const handleRemoveAllSlots = (dayId: string) => {
    dispatch({ type: 'removeAllSlots', payload: { dayId } });
  };

  const handleCopyToAllFields = (dayId: string) => {
    dispatch({ type: 'copyToAllFields', payload: dayId });
  };

  const handleSubmit = () => {
    let weekly_periods: any = [];

    state.times.forEach((d: any) => {
      if (d.slots.length === 0) return;

      d.slots.forEach((s: any) => {
        weekly_periods.push({
          day: d.day,
          start_time: s.startTime,
          end_time: s.endTime,
        });
      });
    });

    const payload = {
      availability_rule_id: 'default',
      calendar_ids: [calendars?.calendars[0].calendar_id],
      tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
      weekly_periods,
    };

    updateRules(payload);
  };

  return (
    <>
      <TitleWithClose id={id} title={'Configure your preferred meeting times'} />
      <Text color={'#838485'} mb={20}>
        Configuring your available or unavailable hours will help Rolebot schedule candidate interviews at your
        preferred times.
      </Text>
      <Group noWrap spacing={8} className={classes.dayCirclesArea}>
        {state.times.map((d: any) => (
          <DayButton
            key={d.dayId}
            dayId={d.dayId}
            onClick={d.slots.length > 0 ? () => handleRemoveAllSlots(d.dayId) : () => handleAddSlot(d.dayId)}
            isSelected={d.slots.length > 0}
          >
            {d.day[0]}
          </DayButton>
        ))}
      </Group>
      <Stack mt={20} className={classes.mainStackWindow}>
        {state.times
          .filter((n: any) => !!n.slots.length)
          .map((d: any) => (
            <Group align={'center'} key={d.dayId}>
              <Text mb={'auto'} mt={4} className={classes.dayText}>
                {d.day}
              </Text>
              <Stack>
                {d.slots.map((s: any, index: any) => {
                  console.log(d.slots.length, index > 0);

                  return (
                    <TimeSlot
                      key={s.slotId}
                      dayId={d.dayId}
                      slotId={s.slotId}
                      showCopyToAll={s.slotId === state.copySlotId}
                      showMinus={index > 0}
                      showPlus={d.slots.length === index + 1}
                      disablePlus={d.slots.length === index + 1 && s.startTime && s.endTime && !s.error}
                      disableMinus={d.slots.length > 1}
                      start={s.startTime}
                      end={s.endTime}
                      hasErrors={s.error}
                      onAddSlot={() => handleAddSlot(d.dayId)}
                      onRemoveSlot={() => handleRemoveSlot(d.dayId, s.slotId)}
                      setSlotDate={handleSetDate}
                      copyToAllFields={handleCopyToAllFields}
                    />
                  );
                })}
              </Stack>
            </Group>
          ))}
      </Stack>
      <Stack>
        <Button
          ml="auto"
          className={classes.submitButton}
          onClick={handleSubmit}
          disabled={!state.readyForSubmit || updateIsLoading}
        >
          Save Rules
        </Button>
      </Stack>

      {isSuccess && (
        <Alert color={'green'} mt={20}>
          Rules updated!
        </Alert>
      )}
      {isError && (
        <Alert color={'red'} mt={20}>
          An error occurred while updating your rules.
        </Alert>
      )}
    </>
  );
};

export default CronofyModal;
