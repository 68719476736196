import { Image, Stack, Text, Title } from '@mantine/core';
import DizzyRolebot from 'assets/img/rolebot-dizzy.svg';
import useOpenModal from 'hooks/useOpenModal';

const RolesNotFound = () => {
  const { contactSupport } = useOpenModal();

  return (
    <Stack align={'center'} spacing={0}>
      <Image src={DizzyRolebot} width={190} mb={20} />
      <Title order={3} sx={{ color: '#242424' }} mb={20}>
        No roles found
      </Title>
      <Text color={'#242424'}>No roles were found with those filters.</Text>
      <Text color={'#242424'}>
        Try checking your filters or{' '}
        <Text component="span" style={{ color: '#4BA7C3', cursor: 'pointer' }} onClick={contactSupport}>
          Contact Support
        </Text>{' '}
        for help.
      </Text>
    </Stack>
  );
};

export default RolesNotFound;
