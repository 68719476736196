import React from 'react'
import classes from './RolebotButton.module.css';
import { Button } from '@mantine/core';
interface IRolebotButton { 
  type : 'primary' | 'secondary' | 'neutral' | 'link' | 'destructive' | 'stand';
}

const RolebotButton = ( props:any ) => {

  const { type } : IRolebotButton = props

  let styles = '', styleDisable = '';
  if (props.disabled && type === 'primary') styleDisable = classes.primary_disable;
  if (!props.disabled && type === 'primary') styles = classes.primary;
  

  if(type === 'secondary') styles = classes.secondary
  if(type === 'neutral') styles = classes.neutral
  if(type === 'link') styles = classes.link
  if(type === 'destructive') styles = classes.destructive
  if(type === 'stand') styles = classes.stand

  return (
        <Button {...props} classNames={{root: ` ${classes.main} ${styles}`, default: styles}} onClick={props.onClick}>
          {props.children}
        </Button>
      )
}

export default RolebotButton
