import React, { useMemo } from 'react';
import {
  Avatar,
  Badge,
  Box,
  BoxProps,
  createStyles,
  Divider,
  Drawer,
  Group,
  Image,
  Stack,
  Tabs,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import useAppSelector from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { closeTalentMenu } from 'features/app/appSlice';
import {
  useGetCompanyQuery,
  useGetOutcomesQuery
} from 'app/services/rolebot';
import RoleLocation from 'components/RoleRow/RoleLocation';
import { getNameInitials, getRandomColor } from 'utils';
import TalentName from 'components/TalentRow/TalentName';
import TalentRole from 'components/TalentRow/TalentRole';
import TalentAbout from 'components/TalentDetail/TalentAbout';
import TalentJob from 'components/TalentDetail/TalentJob';
import TalentEducation from 'components/TalentDetail/TalentEducation';
import TalentExperience from 'components/TalentDetail/TalentExperience';
import TalentActivity from 'components/TalentDetail/TalentActivity';
import ThumbsUp from 'components/ActionButtons/ThumbsUp';
import ThumbsDown from 'components/ActionButtons/ThumbsDown';
import Skip from 'components/ActionButtons/Skip';
import { IInterview, ITalent } from 'types';
import useRandomColor from 'hooks/useRandomColor';
import { useMediaQuery } from '@mantine/hooks';
import RolebotTabs from 'components/RolebotTabs/RolebotTabs';
import TalentSocialLinks from 'components/TalentDetail/TalentSocialLinks';
import {
  DefaultMobileContent,
  MobileAcceptedContent,
  MobileDeclinedContent,
  MobileSkippedContent,
} from 'components/TalentDetail/MobileActionButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendar, faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';
import dayjs from 'dayjs';
import GreenhouseLogo from 'assets/img/greenhouse_icon.svg';
import LeverLogo from 'assets/img/lever_icon.svg';
import SapLogo from 'assets/img/logos/sap_logo.svg';
import IcimsLogo from 'assets/img/logos/ICIMS_logo.png';
import RolebotBadge from "../RolebotBadge";
import moment from "moment";
import TalentMenu from 'components/TalentMenu/TalentMenu';

const useStyles = createStyles((theme) => ({
  drawer: {
    height: 'calc(100% - var(--mantine-header-height))',
    marginTop: 'auto',
    overflowY: 'scroll',
    width: '100%',
    [theme.fn.largerThan('md')]: {
      width: '50%',
    },
  },
  closeButton: {
    marginRight: 25,
    marginTop: 15,
    svg: { width: 30, height: 30 },
  },
  header: {
    display: 'none',
    marginBottom: 0,
    [theme.fn.largerThan('md')]: {
      display: 'flex',
    },
  },

  tabsWrapper: {
    paddingInline: 18,
    [theme.fn.largerThan('md')]: {
      paddingInline: 25,
    },
  },

  hiddenOnMobile: {
    display: 'none',
    [theme.fn.largerThan('md')]: {
      display: 'revert',
    },
  },

  talentInfo: {
    flexDirection: 'column',
    [theme.fn.largerThan('md')]: {
      flexDirection: 'row',
    },
  },

  photoContainer: {
    alignSelf: 'center',
  },

  avatar: {
    width: 150,
    height: 150,
    borderRadius: 75,
    '* > img': {
      borderRadius: 75,
    },
    [theme.fn.largerThan('md')]: {
      width: 90,
      height: 90,
      '* > img': {
        borderRadius: 6,
      },
    },
  },

  placeholder: {
    width: 150,
    height: 150,
    borderRadius: 75,
    [theme.fn.largerThan('md')]: {
      width: 90,
      height: 90,
      borderRadius: 6,
    },
  },

  text: {
    h1: {
      fontSize: 60,
      [theme.fn.largerThan('md')]: {
        fontSize: 40,
      },
    },
  },

  avatarWrapper: {
    paddingInline: 0,
    marginTop: 20,
    [theme.fn.largerThan('md')]: {
      paddingInline: 25,
    },
  },

  talentData: {
    gap: 0,
    paddingInline: 18,
    alignSelf: 'flex-start',
    [theme.fn.largerThan('md')]: {
      paddingInline: 0,
      alignSelf: 'center',
    },
  },

  tabListWrapper: {
    marginLeft: 20,
    marginTop: 10,
    [theme.fn.largerThan('md')]: {
      // marginTop: 10,
      marginLeft: 20
    },
  },
}));

const TalentDrawer = ({
  role,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive = true,
  isFeatureLocked = true
}: {
  role: any;
  handleSetTalent?: any;
  handlePatchCompleted?: any;
  isButtonsActive?: boolean;
  isFeatureLocked?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { classes, cx } = useStyles();
  const isOpen = useAppSelector((state) => state.app.talentMenuOpen);
  const talent = useAppSelector((state) => state.app.currentTalent);
  const color = useRandomColor(talent?.id!);
  const isMediumBreakpoint = useMediaQuery('(min-width: 993px)');
  const { data: company } = useGetCompanyQuery();

  const { data: outcomes } = useGetOutcomesQuery();

  const validInterviews = (talent && talent.interviews.filter((i: { voided_at: null; }) => i.voided_at === null))?.reverse() || [];
  const interview = validInterviews[0] || null;

  const interviewer = role?.info && role.info.contacts.find((c: { role_contact_info: { type: string; }; }) => c.role_contact_info.type === 'interviewer');

  const outcomeId = talent?.pivot?.outcome_id ? talent.pivot.outcome_id : null;

  const outcomeName = outcomeId ? outcomes?.outcomes[outcomeId - 1].name : '';

  const isSourcingOnly = role?.sourcing_only ? role?.sourcing_only : false;

  const talentType = useMemo(() => {
    if (!talent) return null;
    const isDeclined = talent?.pivot.accepted === null ? false : !talent?.pivot.accepted;
    const isInterview = talent && talent.interviews.filter((i: { voided_at: null; }) => i.voided_at === null).length > 0;
    const isAccepted =
      talent?.pivot.skipped_at === null && talent?.pivot.skipped_reason === null && Boolean(talent?.pivot.accepted);
    const isSkipped = talent?.pivot.skipped_at !== null || talent?.pivot.skipped_reason !== null;
    const isUnavailable = talent?.pivot.outcome_id && !isNaN(talent.pivot.outcome_id);
    if (isInterview) return 'interview';
    if (isUnavailable) return 'unavailable';
    if (isSkipped) return 'skipped';
    if (isDeclined) return 'declined';
    if (isAccepted) return 'accepted';
    return 'review';
    // eslint-disable-next-line
  }, [talent]);

  const talentCurrentJob = useMemo(() => {
    if (!talent) return null;
    return talent.job_titles[0];
    // eslint-disable-next-line
  }, [talent]);

  const talentExperience = useMemo(() => {
    // return early if talent is still being fetched
    if (!talent) return null;
    // remove first element, it is the 'current job'
    const [, ...rest] = talent.job_titles;
    return rest.length > 0 ? rest : null;
    // eslint-disable-next-line
  }, [talent]);
  // This variable holds the contact_out emails and the internal rolebot email info we have.
  // only use for roles that are sourcing only and contact out enabled for the company.
  const mergedEmails = useMemo(() => {
    if (!talent) return [];
    const contactOut = talent.contact_out_email;
    const rocketReach = talent.rocket_reach_email;
    const rolebotEmail = talent.email ? talent.email.trim() : talent.email;
    let payload: string[] = [];
    if (rolebotEmail) payload.push(rolebotEmail);
    if (!rolebotEmail && company?.enable_contact_out && contactOut?.length) payload.push(...contactOut);
    if (!rolebotEmail && !contactOut && company?.enable_rocket_reach && rocketReach?.length) payload.push(...rocketReach);
    const uniqueValues = [...new Set(payload)];
    return uniqueValues;
    // eslint-disable-next-line
  }, [talent]);

  const mergedNumbers = useMemo(() => {
    if (!talent) return [];
    const mobileNumber = talent.mobile_number;
    const altNumber = talent.alt_number;
    let payload = [];
    if (Boolean(talent.mobile_number)) payload.push(mobileNumber);
    if (Boolean(talent.alt_number)) payload.push(altNumber);
    const uniqueValues = [...new Set(payload)];
    return uniqueValues;
  }, [talent]);

  const showContactInfo = Boolean(
      outcomeName === 'No Response' ||
      outcomeName === 'Happy in Role' ||
      outcomeName === 'Commute Distance' ||
      outcomeName === 'Comp Differential' ||
      outcomeName === 'Not Interested' ||
      outcomeName === 'Requires Visa Sponsorship' ||
      outcomeName === 'Seeking Remote work' ||
      outcomeName === 'Seeking Hybrid work' ||
      outcomeName === 'Not open to relocation'
  );

  const integrationName = useMemo(() => {
    if (role?.greenhouse_job) return 'Greenhouse';
    if (role?.lever_job) return 'Lever';
    if (role?.sap_job) return 'SAP';
    if (role?.icims_job) return 'ICIMS';
    return null;
  }, [role]);

  return (
    <Drawer
      onClose={() => dispatch(closeTalentMenu())}
      opened={isOpen && isButtonsActive}
      position={'right'}
      classNames={{
        drawer: classes.drawer,
        closeButton: classes.closeButton,
        header: classes.header,
      }}
      withOverlay={isMediumBreakpoint}
    >
      <Box sx={{ position: 'relative' }}>
        <Box pb={10} className={cx(classes.hiddenOnMobile, classes.tabsWrapper)}>
          <Title mt={0} order={4}>
            Candidate Details
          </Title>
        </Box>
        <Divider className={classes.hiddenOnMobile} />
        <Box className={classes.avatarWrapper}>
          <Group noWrap direction={'column'} align={'center'} className={classes.talentInfo}>
            <Stack className={classes.photoContainer}>
              {talent?.profile_image ? (
                <Image
                  className={classes.avatar}
                  src={process.env.REACT_APP_S3_BUCKET + encodeURIComponent(talent.profile_image)}
                />
              ) : (
                <Avatar
                  classNames={{ root: classes.placeholder, placeholder: classes.text }}
                  styles={{
                    placeholder: {
                      borderRadius: 6,
                      color,
                    },
                  }}
                >
                  <Title>{getNameInitials(talent?.name).toUpperCase()}</Title>
                </Avatar>
              )}
            </Stack>
            <Stack className={classes.talentData}>
              <Group noWrap spacing={15} align={'center'}>
                <TalentName name={talent?.name || ''} />
                {talent?.pivot.greenhouse_user_id ? <GreenhouseIcon /> : null}
                {talent?.pivot.lever_user_id ? <LeverIcon /> : null}
                {talent?.pivot.sap_user_id ? <SapIcon /> : null}
                {talent?.pivot.icims_user_id ? <IcimsIcon /> : null}
              </Group>
              {talentCurrentJob && <TalentRole withIcon role={talentCurrentJob.name} />}
              {talent?.talent_info?.location && <RoleLocation location={talent.talent_info.location} />}
            </Stack>
            <Stack
              sx={(theme) => ({
                alignSelf: 'flex-start',
                [theme.fn.largerThan('md')]: {
                  paddingInline: 0,
                  marginLeft: 'auto',
                  alignSelf: 'flex-end',
                },
              })}
            >
                {(integrationName === 'SAP') && talentType === 'interview' ?
                  (
                    <TalentMenu role={role} talent={talent} talentId={talent?.id} integration={integrationName} />
                  ) : null
                }
                {(integrationName === 'Greenhouse' || integrationName === 'Lever') &&
                  talentType === 'accepted'
                  ?
                  <TalentMenu role={role} talent={talent} talentId={talent?.id} integration={integrationName} />
                   : null
                }
                <Group
                  position={'left'}
                  sx={(theme) => ({
                    alignSelf: 'flex-start',
                    paddingInline: 18,
                    [theme.fn.largerThan('md')]: {
                      paddingInline: 0,
                      marginLeft: 'auto',
                      alignSelf: 'flex-end',
                    },
                  })}
                >
                  {isMediumBreakpoint && (
                    <>
                      {talentType === 'review' ? (
                        <DefaultContent
                          talent={talent}
                          roleId={role.id}
                          handleSetTalent={handleSetTalent}
                          handlePatchCompleted={handlePatchCompleted}
                          isButtonsActive={isButtonsActive}
                          isFeatureLocked={isFeatureLocked}
                        />
                      ) : null}
                      {talentType === 'accepted' ? (
                        <MobileAcceptedContent
                          talent={talent}
                          roleId={role.id}
                          handleSetTalent={handleSetTalent}
                          handlePatchCompleted={handlePatchCompleted}
                          isButtonsActive={isButtonsActive}
                          isFeatureLocked={isFeatureLocked}
                        />
                      ) : null}
                      {talentType === 'declined' ? (
                        <MobileDeclinedContent
                          talent={talent}
                          roleId={role.id}
                          handleSetTalent={handleSetTalent}
                          handlePatchCompleted={handlePatchCompleted}
                          isButtonsActive={isButtonsActive}
                          isFeatureLocked={isFeatureLocked}
                        />
                      ) : null}
                      {talentType === 'skipped' ? (
                        <MobileSkippedContent
                          talent={talent}
                          roleId={role.id}
                          handleSetTalent={handleSetTalent}
                          handlePatchCompleted={handlePatchCompleted}
                          isButtonsActive={isButtonsActive}
                          isFeatureLocked={isFeatureLocked}
                        />
                      ) : null}
                    </>
                  )}
                  {talentType === 'interview' ? (
                    <Badge>{interview?.cancelled_at ? 'Interview Cancelled' : 'Interview Confirmed'}</Badge>
                  ) : null}
                  {talentType === 'unavailable' && talent?.pivot?.outcome_id ? <Badge>{outcomeName}</Badge> : null}
                </Group>
            </Stack>
          </Group>
          {talentType === 'accepted' && !role?.sourcing_only ? (
            <div
              style={{
                marginTop: "12px",
                marginLeft: !isMediumBreakpoint ? "15px" : "0px",
                textAlign: !isMediumBreakpoint ? "start" : "end",
              }}
            >
              <RolebotBadge variant={'filled'} color={'#46209E'} backgroundColor={'#EEEEEE'}>
                {(role?.on_hold || role?.completed) ? "Outreach paused" : "Outreach in progress"}
              </RolebotBadge>
            </div>
            ) : null
          }
        </Box>
        <RolebotTabs
          variant={'unstyled'}
          styles={{ tabControl: { wordBreak: 'keep-all' } }}
          classNames={{ tabsListWrapper: classes.tabListWrapper }}
        >
          <Tabs.Tab label={'Overview'}>
            <Box mt={20} className={classes.tabsWrapper}>
              <TalentAbout about={talent?.about} />
              <TalentSocialLinks links={talent?.talent_social_link} linkedIn={talent?.link} />
              {talentCurrentJob && <TalentJob experience={talentCurrentJob} />}
              {talentExperience && <TalentExperience experience={talentExperience} />}
              <TalentEducation education={talent?.talent_education} />
            </Box>
          </Tabs.Tab>
          {talentType === 'interview' && (
            <Tabs.Tab label={'Interview Details'}>
              <Box mt={20} mx={18}>
                <Text sx={{ fontFamily: 'Roboto', fontSize: 19, color: '#242424' }} mb={16}>
                  Interview Date
                </Text>
                {validInterviews.map((i: IInterview) => (
                  //@ts-ignore
                  <Interview interview={i} key={i.created_at} />
                ))}
                <Stack spacing={16}>
                  <Text sx={{ fontFamily: 'Roboto', fontSize: 19, color: '#242424' }}>Interviewer</Text>
                  <Group spacing={14}>
                    <Avatar radius={100} size={45} color={getRandomColor(interviewer?.id)}>
                      {getNameInitials(interviewer?.name)}
                    </Avatar>
                    <Text color={'#242424'}>{interviewer?.name}</Text>
                  </Group>
                </Stack>
              </Box>
            </Tabs.Tab>
          )}
          {/*if type is interview we show whatever email and phone we have from rolebot.*/}
          {/*Add phone*/}
          {(
            (!isSourcingOnly && (showContactInfo || talentType === 'interview') && (talent?.email || talent?.mobile_number || talent?.alt_number)) ||// rules for turnkey roles (only show card if talent is no response or interview and has any internal contact info)
            (isSourcingOnly && //rules for source only roles (show info if talent is accepted and has any info enabled)
              talentType === 'accepted' &&
              ((company?.enable_contact_out && talent?.contact_out_email?.length) ||
              (company?.enable_rocket_reach && talent?.rocket_reach_email?.length) ||
              (talent?.email || talent?.mobile_number || talent?.alt_number)))
            ) &&
            (
            <Tabs.Tab label={'Contact Info'}>
              <Box mt={20} mx={18}>
                <Text sx={{ fontFamily: 'Roboto', fontSize: 19, color: '#242424' }} mb={16}>
                  Contact Information
                </Text>
                <Stack spacing={4}>
                  {mergedEmails.length > 0 &&
                    mergedEmails.map((email) => (
                      <Group spacing={10} key={email}>
                        <Group sx={{ width: 20 }}>
                          <FontAwesomeIcon icon={faEnvelope} style={{ height: '17px', color: '#4f4f4f' }} />
                        </Group>
                        <Text color={'#242424'}>{email}</Text>
                      </Group>
                    ))}
                  {(mergedNumbers.length > 0) &&
                    mergedNumbers.map((number) => (
                      <Group spacing={10}>
                        <Group sx={{ width: 20 }}>
                          <FontAwesomeIcon icon={faPhone} style={{ height: '16px', color: '#4f4f4f' }} />
                        </Group>
                        <Text color={'#242424'}>{number}</Text>
                      </Group>
                    ))}
                </Stack>
              </Box>
            </Tabs.Tab>
          )}
          {/* <Tabs.Tab label={'Candidate Activity'}>
            <Box mt={5} className={classes.tabsWrapper}>
              <TalentActivity role={role} talent={talent}/>
            </Box>
          </Tabs.Tab> */}
        </RolebotTabs>
        {talentType !== 'interview' && talentType !== 'unavailable' && (
          <MobileActionButtons>
            {talentType === 'review' ? (
              <DefaultMobileContent
                talent={talent}
                roleId={role.id}
                handleSetTalent={handleSetTalent}
                handlePatchCompleted={handlePatchCompleted}
                isButtonsActive={isButtonsActive}
                isFeatureLocked={isFeatureLocked}
              />
            ) : null}
            {talentType === 'accepted' ? (
              <MobileAcceptedContent
                talent={talent}
                roleId={role.id}
                handleSetTalent={handleSetTalent}
                handlePatchCompleted={handlePatchCompleted}
                isButtonsActive={isButtonsActive}
                isFeatureLocked={isFeatureLocked}
              />
            ) : null}
            {talentType === 'declined' ? (
              <MobileDeclinedContent
                talent={talent}
                roleId={role.id}
                handleSetTalent={handleSetTalent}
                handlePatchCompleted={handlePatchCompleted}
                isButtonsActive={isButtonsActive}
                isFeatureLocked={isFeatureLocked}
              />
            ) : null}
            {talentType === 'skipped' ? (
              <MobileSkippedContent
                talent={talent}
                roleId={role.id}
                handleSetTalent={handleSetTalent}
                handlePatchCompleted={handlePatchCompleted}
                isButtonsActive={isButtonsActive}
                isFeatureLocked={isFeatureLocked}
              />
            ) : null}
          </MobileActionButtons>
        )}
      </Box>
    </Drawer>
  );
};

interface MobileActionButtonsProps extends BoxProps<'div'> {
  children: React.ReactNode;
}

const MobileActionButtons = ({ children, ...rest }: MobileActionButtonsProps) => {
  return (
    <Box
      sx={(theme) => ({
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '15px 0',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.45)',
        [theme.fn.largerThan('md')]: {
          display: 'none',
        },
      })}
      {...rest}
    >
      {children}
    </Box>
  );
};

const DefaultContent = ({
  talent,
  roleId,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive,
  isFeatureLocked
}: {
  talent?: ITalent;
  roleId: string;
  handleSetTalent?: any;
  handlePatchCompleted?: any;
  isButtonsActive?: boolean;
  isFeatureLocked?: boolean;
}) => {
  if (!talent) {
    return null;
  }
  return (
    <Group
      noWrap
      spacing={5}
      sx={(theme) => ({
        display: 'none',
        [theme.fn.largerThan('md')]: {
          gap: 10,
          display: 'flex',
        },
      })}
    >
      <ThumbsUp
        talentId={talent?.id!}
        roleId={Number(roleId)}
        active={
          talent?.pivot.skipped_at === null && talent?.pivot.skipped_reason === null && Boolean(talent?.pivot.accepted)
        }
        handleSetTalent={handleSetTalent}
        handlePatchCompleted={handlePatchCompleted}
        isButtonsActive={isButtonsActive}
        isFeatureLocked={isFeatureLocked}
      />
      <ThumbsDown
        talentId={talent?.id!}
        roleId={Number(roleId)}
        active={talent?.pivot.accepted === null ? false : !talent?.pivot.accepted}
        acceptedOn={talent?.pivot.accepted_on}
        isDrawerMode={true}
        handleSetTalent={handleSetTalent}
        handlePatchCompleted={handlePatchCompleted}
        isFeatureLocked={isFeatureLocked}
      />
      <Box ml={15}>
        <Skip
          roleId={Number(roleId)}
          talentId={talent?.id!}
          active={talent?.pivot.skipped_at !== null || talent?.pivot.skipped_reason !== null}
          handleSetTalent={handleSetTalent}
          handlePatchCompleted={handlePatchCompleted}
          isFeatureLocked={isFeatureLocked}
        />
      </Box>
    </Group>
  );
};

const Interview = ({ interview }: { interview: IInterview }) => {
  return (
    <Stack mb={20} spacing={4}>
      <Group spacing={'xs'}>
        <Group sx={{ width: 20 }}>
          <FontAwesomeIcon icon={faCalendar} style={{ height: '18px', marginBottom: '2px', color: '#4F4F4F' }} />
        </Group>
        <Group position={'apart'} noWrap spacing={20}>
          <Text color={'#242424'}>
            {interview.interview_at ? dayjs(interview.interview_at).format('MMMM DD, YYYY') : null}
          </Text>
          {interview.cancelled_at && <Text component={'i'}>Canceled</Text>}
        </Group>
      </Group>
      <Group spacing={'xs'} align={'center'}>
        <Group sx={{ width: 20 }}>
          <FontAwesomeIcon icon={faClock} style={{ height: '16px', marginBottom: '2px', color: '#4F4F4F' }} />
        </Group>
        <Text color={'#242424'}>
          {/*@ts-ignore*/}
          {interview.interview_at ? dayjs.utc(interview.interview_at).local().format('LT') : null}
        </Text>
      </Group>
    </Stack>
  );
};

const GreenhouseIcon = () => {
  return (
    <Tooltip label={'Candidate exported'}>
      <Image src={GreenhouseLogo} width={8} />
    </Tooltip>
  );
};

const LeverIcon = () => {
  return (
    <Tooltip label={'Candidate exported'}>
      <Image src={LeverLogo} width={16} />
    </Tooltip>
  );
};

const SapIcon = () => {
  return (
    <Tooltip label={'Candidate exported'}>
      <Image src={SapLogo} width={16} />
    </Tooltip>
  );
};

const IcimsIcon = () => {
  return (
    <Tooltip label={'Candidate exported'}>
      <Image src={IcimsLogo} width={32} />
    </Tooltip>
  );
};

export default React.memo(TalentDrawer);
