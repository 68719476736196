import { Alert, Box, Button, Group, Space, Stack, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';
import AdminRow from 'components/Modals/RoleAdmins/AdminRow';
import { useGetRoleByIdQuery, usePostRoleUserMutation } from 'app/services/rolebot';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ContextModalProps, useModals } from '@mantine/modals';
import SuccessRoleUsers from 'components/Modals/RoleSettings/SuccessRoleUsers';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import React, { useEffect, useState } from 'react';
import AddUsersToRoleDropDown from '../LaunchRole/Components/AddUsersToRoleDropDown';

const LaunchFormSchema = z.object({
  email: z.string().email('Invalid email address').nonempty('Please provide the email address of the user'),
});

type LaunchFormPayload = z.infer<typeof LaunchFormSchema>;

interface RoleUserProps extends Record<string, unknown> {
  roleId: number;
}

const RoleUsers = ({ id, innerProps }: ContextModalProps<RoleUserProps>) => {
  const { roleId } = innerProps;
  const {
    handleSubmit,
    register,
    resetField,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(LaunchFormSchema),
  });

  const [emailWatcher] = watch(['email']);

  useEffect(() => {
    setValue('email', emailWatcher);
    // eslint-disable-next-line
  }, [emailWatcher]);

  const { data: users } = useGetRoleByIdQuery(roleId);
  const [addAdmin, { isLoading, isSuccess, isError, reset: resetMutation }] = usePostRoleUserMutation();
  const [showUsers, setShowUsers] = useState(false);
  const [error, setError] = useState<any>({});
  const modals = useModals();

  const onSubmit = async (data: LaunchFormPayload) => {
    setError({});
    let userIsAlreadyAdded = users?.users.find((x) => x.email === data.email);
    if (userIsAlreadyAdded) {
      resetField('email');
      return;
    }
    try {
      await addAdmin({
        roleId,
        payload: {
          email: data.email,
        },
      }).unwrap();
    } catch (e: any) {
      setError({ ...e, email: data.email });
    }
    resetField('email');
  };

  useEffect(() => {
    if (!isSuccess) return;
    resetField('email');
    // eslint-disable-next-line
  }, [isSuccess]);

  const handleUsersDropdownFocus = () => {
    setShowUsers(true);
  };

  const handleCloseDropdown = () => {
    setShowUsers(false);
    setValue('email', '');
  };

  const handleUserSelected = async (user: any) => {
    setError({});
    let userIsAlreadyAdded = users?.users.find((x) => x.name === user.name);
    if (userIsAlreadyAdded) {
      resetField('email');
      return;
    }
    setValue('email', user.email);
    setShowUsers(false);
    try {
      await addAdmin({
        roleId,
        payload: {
          email: user.email,
        },
      }).unwrap();
    } catch (e: any) {
      setError({ ...e, email: user.email });
    }
    resetField('email');
  };

  const handleClose = () => {
    modals.closeModal(id);
  };

  const onReset = () => {
    reset();
    resetMutation();
  };

  return (
    <>
      <TitleWithClose id={id} title={'Role Users'} mb={20} />
      <Space h="sm" />
      {isSuccess ? (
        <SuccessRoleUsers onClose={() => modals.closeAll()} onClick={onReset} />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} id={'admin-form'}>
            <TextInput
              label={'Role Members'}
              placeholder="ex. Bob Ross, or bob@acme.com"
              size={'md'}
              {...register('email')}
              onFocus={() => handleUsersDropdownFocus()}
              onClick={() => {
                if (!showUsers) {
                  setShowUsers(true);
                }
              }}
              error={errors?.email?.message}
              disabled={isLoading}
            />
            <AddUsersToRoleDropDown
              closeDropdown={handleCloseDropdown}
              userSelected={handleUserSelected}
              show={showUsers}
              needle={getValues('email')}
            />
          </form>
          <Box sx={{ height: 200, overflow: 'hidden', overflowY: 'auto' }} mt={20}>
            <Stack spacing={5}>
              {users?.users.map((user) => (
                <AdminRow key={user.id} id={user.id} name={user?.name} email={user.email} roleId={roleId} />
              ))}
            </Stack>
          </Box>
          {isError &&
            (error?.status === 400 ? (
              <Alert title={'Oops!'} color={'orange'}>
                {error?.email + ' cannot be added because they are part of a different company'}
              </Alert>
            ) : (
              <Alert title={'Oops, something went wrong!'} color={'orange'}>
                We were not able to add this user to the role.
              </Alert>
            ))}
          <Group position={'right'} mt={30}>
            <Button
              disabled={isLoading}
              onClick={handleClose}
              sx={{
                backgroundColor: 'transparent',
                color: '#838485',
                border: '1px solid #838485',
                paddingInline: 40,
              }}
              variant={'subtle'}
            >
              Cancel
            </Button>
            <Button px={40} type="submit" loading={isLoading} form={'admin-form'}>
              Add
            </Button>
          </Group>
        </>
      )}
    </>
  );
};

export default RoleUsers;
