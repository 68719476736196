import React, { useEffect, useReducer, useState } from 'react';
import { Container, createStyles, Divider, Group, SimpleGrid, Stack, Text, Title, TextInput } from '@mantine/core';
import RoleMetricsFilters from '../../components/Filters/RoleMetricsFilters';
import { useGetRoleMetricsQuery, useGetCompanyQuery } from '../../app/services/rolebot';
import { ArrowCircles, Avatar, Bolt, Bulb, HandShake } from '../../components/CustomIcons/CustomIcons';
import { buildMetricsData } from './metricsHelpers';
import PercentSmallWidget from './Widgets/PercentSmallWidget/PercentSmallWidget';
import PercentBarSmallWidget from './Widgets/PercentBarSmallWidget/PercentBarSmallWidget';
import SmallWidget from './Widgets/SmallWidget/SmallWidget';
import ResponseWidget from './Widgets/ResponseWidget/ResponseWidget';
import MetricDropDown from 'components/Modals/LaunchRole/Components/MetricDropDown';
import { X } from 'tabler-icons-react';
import MetricsDetail from 'components/Metrics/MetricsDetail';

const useStyles = createStyles((theme) => ({
  container: {
    paddingTop: '20px',
    [theme.fn.largerThan('md')]: {
      paddingTop: 0,
      marginTop: 40,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },

  responseRate: {
    display: 'none',
    [theme.fn.largerThan('md')]: {
      display: 'block',
    },
  },

  titleWrapper: {
    display: 'none',
    [theme.fn.largerThan('md')]: {
      flex: 0.5,
      display: 'flex',
    },
  },

  topMenu: {
    display: 'none',
    [theme.fn.largerThan('sm')]: {
      display: 'flex',
    },
  },

  mobileIndicator: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  col: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  flexWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  inputIcon: {
    height: 50,
    width: 50,
    marginLeft: 15,
  },

  customCard: {
    textAlign: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.35)',
    borderRadius: '10px',
  },
}));

function reducer(state, action) {
  switch (action.type) {
    case 'transformedMetricsDataChanged':
      return {
        ...state,
        transformedMetricsData: action.payload,
      };
    case 'roleIdChanged':
      return {
        ...state,
        roleId: action.payload,
      };
    case 'roleDetailsChanged':
      return {
        ...state,
        role: action.payload,
      };
    case 'roleStatusFilterChanged':
      return {
        ...state,
        roleStatusFilter: action.payload,
      };
    case 'roleTypeFilterChanged':
      return {
        ...state,
        roleTypeFilter: action.payload,
      };
    case 'setData': {
      return {
        ...state,
        data: action.payload,
      };
    }
    default:
      throw new Error();
  }
}

const Metrics = () => {
  const [state, dispatch] = useReducer(reducer, {
    transformedMetricsData: null,
    roleId: '0',
    roleStatusFilter: 'all',
    roleTypeFilter: 'all',
    data: [],
  });
  const { data: metricsData } = useGetRoleMetricsQuery(5000);
  const { data: company } = useGetCompanyQuery();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [showMetricsSearch, setshowMetricsSearch] = useState(false);
  const [query, setQuery] = useState('');

  const { classes } = useStyles();

  const avatarIcon = <Avatar color="#4EB448" style={{ height: '28px', width: '28px' }} />;
  const handshakeIcon = <HandShake color="#3F7BBE" style={{ height: '28px', width: '28px' }} />;
  const boltIcon = <Bolt color="#A75DA5" style={{ height: '28px', width: '28px' }} />;
  const arrowCircleIcon = <ArrowCircles color="#4D9EC6" style={{ height: '28px', width: '28px' }} />;
  const bulbIcon = <Bulb color="#FFD250" style={{ height: '28px', width: '28px' }} />;

  const responseRateHelp =
    "Only applicable for Turnkey roles. This percentage is based on the total number of people that have responded to Rolebot's engagement regarding opportunities at your company.";

  const sourcingAccuracyHelp =
    'This percentage represents the total number of candidates approved by you / your team, out of the total number of candidates reviewed by you / your team.';

  const diversityAndInclusionHelp = (
    <>
      <Text size="md" style={{ marginBottom: 5, fontWeight: 'bold' }}>
        Sourced:
      </Text>
      <Text size="sm" style={{ marginBottom: 10 }}>
        This percentage is based on the number of people that fall into the US government’s standard of ‘Diversity &
        Inclusion’, out of the total number of candidates sourced by Rolebot.
      </Text>

      <Text size="md" style={{ marginBottom: 5, fontWeight: 'bold' }}>
        Accepted:
      </Text>
      <Text size="sm">
        This percentage is based on the number of people that were accepted by your team, out of the total number of
        diverse candidates reviewed by your team.
      </Text>
    </>
  );

  const candidatesHelp =
    'This is the number of candidates Rolebot is currently engaging with, regarding opportunities at your company. Please note all of these candidates have been approved by you / your team.';

  const interestedHelp =
    "This is the number of people that are currently interested in opportunities at your company. Our team will contact you to coordinate a call once we ensure the candidates' salary expectations and location align with the opportunity at your company.";

  const transformedRolesData = (rolesType) => {
    if (rolesType) {
      return [
        {
          id: '0',
          name: `${state.roleStatusFilter === 'active-roles' ? "Showing 'Active Roles'" : "Showing 'Completed Roles'"}`,
        },
        ...rolesType,
      ];
    } else {
      return [{ id: '0', name: 'All roles' }, ...(metricsData?.roles?.data ?? [])];
    }
  };

  const syncMetricsDataWithRolesFilters = () => {
    // Start with all roles
    let filteredRoles = metricsData.roles.data;
  
    // Apply status filter if not 'all'
    if (state.roleStatusFilter === 'active-roles') {
      filteredRoles = filteredRoles.filter(x => !x.completed);
    } else if (state.roleStatusFilter === 'completed-roles') {
      filteredRoles = filteredRoles.filter(x => x.completed);
    }
  
    // Apply type filter if not 'all'
    if (state.roleTypeFilter === 'turnkey-roles') {
      filteredRoles = filteredRoles.filter(x => !x.sourcing_only);
    } else if (state.roleTypeFilter === 'sourcing-only-roles') {
      filteredRoles = filteredRoles.filter(x => x.sourcing_only);
    }
  
    // Build metrics data with the filtered roles
    let transformedData = buildMetricsData(filteredRoles);
    dispatch({ type: 'transformedMetricsDataChanged', payload: transformedData });
  };

  useEffect(() => {
    if (metricsData) {
      let transformedData = buildMetricsData(metricsData.roles.data);
      dispatch({ type: 'transformedMetricsDataChanged', payload: transformedData });
      dispatch({ type: 'setData', payload: metricsData.roles.data });
    }
    // eslint-disable-next-line
  }, [metricsData]);

  useEffect(() => {
    if (state.roleId && !isFirstRender) {
      let filteredRoles = metricsData.roles.data;

      if (state.roleStatusFilter === 'active-roles') {
        filteredRoles = filteredRoles.filter(x => !x.completed);
      } else if (state.roleStatusFilter === 'completed-roles') {
        filteredRoles = filteredRoles.filter(x => x.completed);
      }

      if (state.roleTypeFilter === 'turnkey-roles') {
        filteredRoles = filteredRoles.filter(x => !x.sourcing_only);
      } else if (state.roleTypeFilter === 'sourcing-only-roles') {
        filteredRoles = filteredRoles.filter(x => x.sourcing_only);
      }

      if (state.roleStatusFilter === 'all' && state.roleTypeFilter === 'all') {
        filteredRoles = null;
      }

      let role = transformedRolesData(
        filteredRoles
      ).find((x) => x.id === Number(state.roleId));
      if (role) {
        let transformedData = buildMetricsData([role]);
        dispatch({ type: 'transformedMetricsDataChanged', payload: transformedData });
        dispatch({ type: 'roleDetailsChanged', payload: role });
      } else {
        if (state.roleStatusFilter || state.roleTypeFilter) {
          syncMetricsDataWithRolesFilters();
        } else {
          let transformedData = buildMetricsData(metricsData.roles.data);
          dispatch({ type: 'transformedMetricsDataChanged', payload: transformedData });
        }
      }
    }
    // eslint-disable-next-line
  }, [state.roleId]);

  useEffect(() => {
    if ((state.roleStatusFilter || state.roleTypeFilter) && !isFirstRender) {
      syncMetricsDataWithRolesFilters();
    }
    // eslint-disable-next-line
  }, [state.roleStatusFilter, state.roleTypeFilter]);

  const handleChangeRoleStatusFilter = (value) => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    setQuery('');
    dispatch({ type: 'roleIdChanged', payload: '0' });
    dispatch({ type: 'roleDetailsChanged', payload: null });
    dispatch({ type: 'roleStatusFilterChanged', payload: value });
  };

  const handleChangeRoleTypeFilter = (value) => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    setQuery('');
    dispatch({ type: 'roleIdChanged', payload: '0' });
    dispatch({ type: 'roleDetailsChanged', payload: null });
    dispatch({ type: 'roleTypeFilterChanged', payload: value });
  };

  const handleMetricsSearchFocus = () => {
    setshowMetricsSearch(!showMetricsSearch);
  };

  const handleCloseDropdown = () => {
    setshowMetricsSearch(false);
  };

  const handleSearchSelected = (value) => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    setQuery(value.name);
    setshowMetricsSearch(false);
    dispatch({ type: 'roleIdChanged', payload: value.id });
  };

  const clearQuery = () => {
    setQuery('');
    dispatch({ type: 'roleIdChanged', payload: '0' });
    dispatch({ type: 'roleDetailsChanged', payload: null });
  };

  const shouldDisplayComponent = () => {
    // If the filter is set to show only sourcing-only roles, do not display the component
    if (state.roleTypeFilter === 'sourcing-only-roles') {
      return false;
    }
  
    // If a specific role is selected and it is a sourcing-only role, do not display the component
    if (state.role && state.role.sourcing_only) {
      return false;
    }
  
    // In all other cases, display the component
    return true;
  };

  return (
    <Container size={'xl'} className={classes.container}>
      {/*This content is hidden on mobile*/}
      <Stack className={classes.titleWrapper} spacing={0}>
        <Title style={{ fontSize: '34px' }}>Metrics</Title>
        <Group noWrap spacing={20} my={30}>
          <div style={{ width: '40vw' }}>
            <TextInput
              placeholder="Filter by Role"
              size={'md'}
              style={{ width: '40vw' }}
              radius={10}
              onFocus={() => handleMetricsSearchFocus()}
              value={query}
              onClick={() => {
                if (!showMetricsSearch) {
                  setshowMetricsSearch(true);
                }
              }}
              onChange={(e) => setQuery(e.target.value)}
              rightSection={!query ? '' : <X onClick={clearQuery} cursor={'pointer'} color={'#838485'} size={20} />}
            />
            <MetricDropDown
              closeDropdown={handleCloseDropdown}
              rowSelected={handleSearchSelected}
              show={showMetricsSearch}
              needle={query}
              dataToshow={state.data}
              filter={state.roleStatusFilter}
              filter2={state.roleTypeFilter}
            />
          </div>

          <RoleMetricsFilters 
            company={company}
            onRoleStatusFilterChange={(val) => handleChangeRoleStatusFilter(val)} 
            onRoleTypeFilterChange={(val) => handleChangeRoleTypeFilter(val)}
          />
        </Group>
        {state.roleId !== '0' && (
          <>
            <Divider sx={{ borderTopColor: '#EEEEEE' }} />
            <MetricsDetail data={state.data} roleId={state.roleId} />
          </>
        )}

        {
          query === '' && (state.roleStatusFilter !== 'all' || state.roleTypeFilter !== 'all') && (
            <div style={{ color: '#242424', fontFamily: 'Helvetica', fontSize: '16px', margin: '0 0 16px 4px' }}>
              Displaying {
                `${state.roleStatusFilter !== 'all' ? (state.roleStatusFilter === 'active-roles' ? 'Active Roles' : 'Completed Roles') : ''}` +
                `${state.roleStatusFilter !== 'all' && state.roleTypeFilter !== 'all' ? ' and ' : ''}` +
                `${state.roleTypeFilter !== 'all' ? (state.roleTypeFilter === 'turnkey-roles' ? 'Turnkey Roles' : 'Sourcing Only Roles') : ''}`
              }
            </div>
          )
        }

        {/* <Divider sx={{ borderTopColor: '#EEEEEE' }} /> */}
      </Stack>

      <SimpleGrid
        breakpoints={[
          { minWidth: 'md', cols: 3 },
          { minWidth: 'sm', cols: 1 },
        ]}
      >
        {shouldDisplayComponent() ? 
        <PercentSmallWidget
          color="#4EB448"
          section1="Response Rate"
          section2={`Total responses: ${state.transformedMetricsData?.totalResponses ?? 0}`}
          section3={`Total talent accepted: ${state.transformedMetricsData?.acceptedTalent ?? 0}`}
          percent={state.transformedMetricsData?.totalResponsesPercentage ?? 0}
          helpMessage={responseRateHelp}
          icon={avatarIcon}
        /> : null}
        <PercentSmallWidget
          color="#A75DA5"
          section1="Sourcing Accuracy"
          section2={`Total talent accepted: ${state.transformedMetricsData?.acceptedTalent ?? 0}`}
          section3={`Total talent reviewed: ${state.transformedMetricsData?.totalTalentReviewed ?? 0}`}
          percent={state.transformedMetricsData?.sourcingAccuracyPercentage ?? 0}
          helpMessage={sourcingAccuracyHelp}
          icon={boltIcon}
        />
        <PercentBarSmallWidget
          color="#3F7BBE"
          barOneText="Sourced"
          barOnePercent={state.transformedMetricsData?.sourcedDiversityPercentage ?? 0}
          barTwoText="Accepted"
          barTwoPercent={state.transformedMetricsData?.acceptedDiversityPercentage ?? 0}
          section1="Diversity & Inclusion"
          section2={`Total Diversity sourced: ${state.transformedMetricsData?.diverseTalent ?? 0}`}
          section3={`Total Diversity accepted: ${state.transformedMetricsData?.diverseTalentAccepted ?? 0}`}
          helpMessage={diversityAndInclusionHelp}
          icon={handshakeIcon}
        />
      </SimpleGrid>

      {shouldDisplayComponent() ? 
        <div className={classes.responseRate}>
          <ResponseWidget section="Response Rate" data={state.transformedMetricsData?.pieData} icon={avatarIcon} />
        </div> 
      : null}

      <SimpleGrid
        breakpoints={[
          { minWidth: 'md', cols: 2 },
          { minWidth: 'sm', cols: 1 },
        ]}
      >
        {shouldDisplayComponent() ? 
          <SmallWidget
            value={state.transformedMetricsData?.inProgressTalent ?? 0}
            icon={arrowCircleIcon}
            helpMessage={candidatesHelp}
            section="Candidates in progress"
          />
        : null}

    {shouldDisplayComponent() ? 
          <SmallWidget
            value={state.transformedMetricsData?.interestedTalent}
            icon={bulbIcon}
            helpMessage={interestedHelp}
            section="Interested Candidates"
          />
        : null}
      </SimpleGrid>
    </Container>
  );
};

export default Metrics;
