import React from 'react';
import PropTypes from 'prop-types';
import {createStyles} from "@mantine/core";

const useStyles = createStyles((theme) => ({

    GreenhouseRoleItem: {
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'unset',
        marginBottom: '8px',
        color: '#242424',
        padding: '2px 10px',
        '&:hover': {
            backgroundColor: '#EEEEEE',
            borderRadius: '5px'
        },
        '&:last-child': {
            marginBottom: '0'
        }
    },
    UnavailableGreenhouseRoleItem: {
        cursor: 'not-allowed',
        backgroundColor: '#EEEEEE',
        fontSize: '16px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'unset',
        marginBottom: '8px',
        color: '#838485',
        padding: '4px 10px',
        '&:last-child': {
            marginBottom: '0'
        }
    }
}));

const LinkableGreenhouseRoleItem = ({linkableGreenhouseRoleSelected, role}) => {
    const { classes } = useStyles();

    return (
        <>
          {role.is_available && <div className={classes.GreenhouseRoleItem}
            onClick={() => linkableGreenhouseRoleSelected(role)}>{role.greenhouse_job_name} <span style={{color: "#959595", marginLeft: 10, fontSize: '12px'}}>{"Req ID " + role.requisition_id}</span></div>}
          {!role.is_available && <div className={classes.UnavailableGreenhouseRoleItem}>{role.greenhouse_job_name} <span style={{color: "#838485", marginLeft: 10, fontSize: '12px'}}>{"Req ID " + role.requisition_id}</span></div>}
        </>
    )
};

export default LinkableGreenhouseRoleItem;

LinkableGreenhouseRoleItem.propTypes = {
    role: PropTypes.object.isRequired,
    linkableGreenhouseRoleSelected: PropTypes.func.isRequired
};

LinkableGreenhouseRoleItem.defaultProps = {};
