import { Alert, Button, createStyles, Group, Stack, Text, TextInput } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';

import { FormEvent, useEffect, useState } from 'react';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { useNotifications } from '@mantine/notifications';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useGetCompanyLocationsQuery, usePostCompanyLocationMutation, usePostMfaStatusMutation } from 'app/services/rolebot';
import { DisableMFAProps } from 'types/modals';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const DisableMFA = ({ id, innerProps }: ContextModalProps<DisableMFAProps>) => {
  const modals = useModals();
  const notifications = useNotifications()
  const [postMfaStatus, { isLoading, isSuccess, isError, error }] = usePostMfaStatusMutation();

  const handleDeactivateMFA = () => {
    postMfaStatus({
      mfa_enabled: false,
      customer_id: innerProps.customer_id
    })
  }

  useEffect(() => {
    if (isSuccess) {
      notifications.showNotification({
        title: 'Done',
        color: 'green',
        message: 'Two-factor authentication is now disbled for all company users',
        autoClose: 3000,
      });
      modals.closeAll()
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      notifications.showNotification({
        title: 'Oops!',
        color: 'yellow',
        message: 'Could not disable MFA',
        autoClose: 3000,
      });
    }
  }, [isError]);

  return (
    <>
      <TitleWithClose id={id} title={'Deactivate Two-factor authentication'} mb={20} />
      <Stack>
        <Text color='#242424'>
            If you deactivate Two-Factor Authentication for your company, users will no longer be required to enter a token to sign in to their accounts.
        </Text>
        <Text color='#242424'>Are you sure you want to proceed with deactivating Two-Factor Authentication?</Text>
        <Group position={'right'} mt={24}>
          <RolebotButton type={'neutral'} onClick={() => modals.closeAll()}>
            Cancel
          </RolebotButton>
          <RolebotButton type={'destructive'} onClick={handleDeactivateMFA} loading={isLoading}>
            Yes, deactivate it
          </RolebotButton>
        </Group>
      </Stack>
    </>
  );
};

export default DisableMFA;
