import React from 'react';
import { createStyles, Image, Loader, LoadingOverlay, Stack, Text, Title } from '@mantine/core';
import RolebotPet from 'assets/img/rolebot-pet.svg';
import RolebotSleeping from 'assets/img/rolebot-sleeping.svg';
import useAppSelector from 'hooks/useAppSelector';
import useOpenModal from 'hooks/useOpenModal';
import RolesNotFound from 'components/AlertMessages/RolesNotFound';

type roleType = 'active' | 'completed';

const useStyles = createStyles((theme) => ({
  title: {
    color: '#242424',
    fontSize: 28,
    [theme.fn.largerThan('md')]: {
      fontSize: 34,
    },
  },
  rolebotImg: {
    paddingTop: '25px',
  },
}));

interface RolesOverlayProps {
  isSearching: boolean;
  isInitialized: boolean;
  hasData: boolean;
  isFetching: boolean;
  type: roleType;
  children: React.ReactNode;
  page: number;
}

const RolesOverlay = ({ children, type, isSearching, isInitialized, hasData, isFetching, page }: RolesOverlayProps) => {
  const hasActiveFilters = useAppSelector((state) => state.app.hasActiveFilters);
  const hasActiveSort = useAppSelector((state) => state.app.hasActiveSort);
  const { classes } = useStyles();
  const { contactSupport } = useOpenModal();

  const openContactSupportModal = () => {
    contactSupport();
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/*No Roles when:
                 - no filters are active
                 - search is empty
                 - has fired the request to search roles at least once
                 - has no data
                 */}
      {!hasActiveFilters && !isSearching && !isInitialized && !hasData && type === 'active' && (
        <Stack align={'center'} spacing={0} mb={'20%'}>
          <Image src={RolebotPet} width={200} className={classes.rolebotImg} />
          <Title my={20} className={classes.title}>
            Ready when you are!
          </Title>
          <Stack spacing={10} align={'center'} justify={'center'}>
            <Text color={'#242424'} align={'center'}>
              You have no active roles.
            </Text>
            <Text color={'#242424'} align={'center'}>
              Feel free to request a new role at your convenience.
            </Text>
          </Stack>
          <Text color={'#53AFCD'} onClick={openContactSupportModal} mt={20} sx={{ cursor: 'pointer' }}>
            Contact Support
          </Text>
        </Stack>
      )}
      {/*Same but just for completed roles*/}
      {!hasActiveFilters && !isSearching && !isInitialized && !hasData && type === 'completed' && (
        <Stack align={'center'} spacing={0}>
          <Image src={RolebotSleeping} width={320} />
          <Title my={20} className={classes.title}>
            No roles in here yet...
          </Title>
          <Text color={'#242424'} align={'center'}>
            Once a role is completed, it will show up here.
          </Text>
          <Text color={'#53AFCD'} onClick={openContactSupportModal} mt={20} sx={{ cursor: 'pointer' }}>
            Contact Support
          </Text>
        </Stack>
      )}
      {/*No Roles found if:
                - at least one filter is active
                - search is not empty
                - is finished fetching
                - no data is found
                */}
      {(hasActiveFilters || isSearching) && !isFetching && !hasData && <RolesNotFound />}
      {/*Default loader for:
                - user checks any filter
                - user selects a sorting option
                - user searches for a role
                - is currently fetching
                */}
      {(isFetching && page === 1) && (
        <LoadingOverlay
          zIndex={90}
          visible={isFetching}
          loader={<Loader size={'xl'} variant={'bars'} color={'#6852DB'} />}
          color={'#fff'}
        />
      )}
      {children}
    </div>
  );
};

export default RolesOverlay;
