import { useParams } from 'react-router-dom';
import Greenhouse from 'pages/Integration/Greenhouse';
import Lever from 'pages/Integration/Lever';
import Okta from 'pages/Integration/Okta';
import SAP from 'pages/Integration/Sap';
import ICIMS from 'pages/Integration/ICIMS';

const Integration = () => {
  const params = useParams();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {params?.integrationId === 'greenhouse' ? <Greenhouse /> : null}
      {params?.integrationId === 'lever' ? <Lever /> : null}
      {params?.integrationId === 'okta' ? <Okta /> : null}
      {params?.integrationId === 'sap' ? <SAP /> : null}
      {params?.integrationId === 'icims' ? <ICIMS /> : null}
    </div>
  );
};

export default Integration;
