import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import WidgetWrapper from '../WidgetWrapper/WidgetWrapper';
import { createStyles, Text } from '@mantine/core';
// import {Popover} from "react-bootstrap";

const useStyles = createStyles((theme) => ({
  Title: {
    marginBottom: '10px',
    margin: 'auto'
  },

  DangerIcon: {
    position: 'absolute',
    top: '10px',
    left: '15px',
  },

  Help: {
    position: 'absolute',
    top: '10px',
    right: '15px',
  },
}));

const renderColorfulLegendText = (value) => {
  return <Text style={{ color: '#4f4f4f' }}>{value}</Text>;
};

const ResponseWidget = (props) => {
  const { classes } = useStyles();
  const hasData = !!props.data?.find((x) => x.value > 0);
  const data = hasData ? props.data : [{ name: 'sdsd', value: 66, color: 'lightgrey' }];

  const legend = hasData ? (
    <Legend
      iconSize={30}
      iconType="rect"
      layout="vertical"
      align="right"
      verticalAlign="middle"
      wrapperStyle={{ fontSize: '14px' }}
      color={'#4f4f4f'}
      style={{ 'svg > span': { color: '#4f4f4f' } }}
      formatter={renderColorfulLegendText}
    />
  ) : null;

  // const popover = (
  //     <Popover id="popover-basic">
  //         <Popover.Title as="h3">{props.section}</Popover.Title>
  //         <Popover.Content>
  //             Help will go here!
  //         </Popover.Content>
  //     </Popover>
  // );

  return (
    <WidgetWrapper style={{ height: '350px' }}>
      <div>
        <div style={{display: 'flex', marginBottom: '4rem', width: '95%'}}>
          <div className={classes.Icon}>{props.icon}</div>
          <div className={classes.Title}>{props.section}</div>
        </div>
        <div style={{height: 230}}>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                cy={99}
                dataKey="value"
                data={data}
                innerRadius={60}
                outerRadius={70}
                startAngle={90}
                endAngle={-270}
                paddingAngle={1}
                label={hasData}
              >
                {data.map((x, index) => (
                  <Cell key={index} fill={x.color} />
                ))}
              </Pie>
              {legend}
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default ResponseWidget;
