import { useCallback, useEffect, useState } from 'react';
import { RolebotClient } from "../../../../api/client";
import { useDebouncedValue } from '@mantine/hooks';
import RolebotMultiselect from 'components/Inputs/RolebotMultiSelect';

interface SearchCriteriaSkillsProps {
  onSkillsChange: (payload: { name: string }[]) => void;
  skills: any;
  isNiceToHave: boolean;
}

// transforms the data to a Mantine MultiSelect readable format
const formatDropdownItems = (items: { id: any; name: any; }[]) => {
  if (items?.length) {
    return items?.map((x: { id: any; value?: any; name: any; label?: any; pivot?: any }) => {
      return {
        value: x.id.toString(),
        label: x.name,
        id: x.id,
        name: x.name,
        is_from_feedback: x.pivot?.is_from_feedback,
        is_excluded: x.pivot?.is_excluded
      };
    });
  } else {
    return []
  }
}

const getUniqueListById = (arr: any) => {
  return [...new Map(arr.map((item: any) => [item['id'], item])).values()]
}

const SearchCriteriaSkillsExcluded = ({ onSkillsChange, skills, isNiceToHave }: SearchCriteriaSkillsProps) => {
  const [skillSearchTerm, setSkillSearchTerm] = useState("");
  const [availableSkills, setAvailableSkills] = useState(skills?.length ? formatDropdownItems(skills) : []);
  const [selectedSkills, setSelectedSkills] = useState(skills ? skills.map((skill: any) => skill.id.toString()) : []);
  const [debouncedSearch] = useDebouncedValue(skillSearchTerm.trim(), 500);

  useEffect(() => {
    onSkillsChange(formatPayloadItems(selectedSkills));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkills]);

  useEffect(() => {
    if (skillSearchTerm) searchForSkillsTag(skillSearchTerm)
  }, [debouncedSearch])

  // transforms the data to a back to the expected from API format  e.g: id: 1, name: "Laravel"
  // applicable for both skills and job titles
  const formatPayloadItems = (items: string[]) => {
    if (items?.length) {
      return items.map((id: string) => {
        let object = availableSkills.find((s: any) => s.id.toString() === id)
        return {
          id: object?.id ?? id,
          name: object?.name ?? id,
          nice_to_have: isNiceToHave,
          is_from_feedback: object?.is_from_feedback,
          is_excluded: object?.is_excluded
        };
      });
    } else {
      return []
    }
  }

  const searchForSkillsTag = useCallback((searchTerm) => {
    (async () => {
      try {
        const { data } = await RolebotClient.get('/skills', { params: { 'search-term': searchTerm } });

        if (data.data && data.data.length) {
          // @ts-ignore
          setAvailableSkills((prev) => getUniqueListById([...prev, ...formatDropdownItems(data.data)]));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [debouncedSearch]);

  const copyToAvailable = (values: string[]) => {

    let selected = availableSkills.filter((x: any) => values.includes(x.value))

    setAvailableSkills(selected)
    setSelectedSkills(values)
  }

  return (
    <>
      <RolebotMultiselect
        data={availableSkills}
        searchable
        creatable
        value={selectedSkills}
        getCreateLabel={(query) => `+ Create ${query}`}
        onChange={copyToAvailable}
        nothingFound={'Type to search'}
        onSearchChange={setSkillSearchTerm}
        selectOnBlur={true}
        clearSearchOnBlur={true}
        clearSearchOnChange={true}
        style={{ width: '100%' }}
        maxSelectedValues={isNiceToHave ? 100 : 7}
        withinPortal
        onBlur={() => {
          setAvailableSkills(availableSkills.filter((x: any) => selectedSkills.includes(x.value)));
        }}
      />
    </>
  );
};

export default SearchCriteriaSkillsExcluded;
