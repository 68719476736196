import React, { useEffect, useReducer } from 'react';
import {createStyles, Text, ScrollArea, Stack, Container, Loader} from '@mantine/core';
import useRandomColor from 'hooks/useRandomColor';
import { RolebotClient } from 'api/client';
import RoleActivityRow from './Components/RoleActivityRow';
import RolebotSleeping from "../../../../assets/img/rolebot-sleeping2.png";

const useStyles = createStyles((theme, { color }: any) => ({
    activityLabel: {
        fontSize: '16px',
        color: '#838485',
        fontFamily: 'Helvetica, Arial, sans-serif',
        paddingTop: '10px',
        paddingBottom: '10px',
    },

    image: {
        height: 70,
        width: 'auto%'
    },

    NoActivityTextContainer: {
      // marginLeft: 45
    },

    NoActivityHeading: {
        color: '#242424',
        fontFamily: 'Roboto, sans-serif',
        fontSize: 22,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginBottom: 10
    },

    NoActivitySubHeading: {
        width: 341,
        color: '#242424',
        fontFamily: 'Helvetica, sans-serif',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginBottom: 180
    }
}));

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'getRoleHistoryReturned':
            const roleHistory = action.payload.role_history.data;

            //@ts-ignore
            const groupedData = roleHistory.reduce((result, item) => {
                const createdAt = item.created_at;
                if (!result[createdAt]) {
                    result[createdAt] = [];
                }
                result[createdAt].push(item);
                return result;
            }, {});
        
            return {
                ...state,
                isLoading: false,
                roleHistory: groupedData,
                roleHistoryCount: action.payload.role_history.total
            };
        case 'onRequestMade':
            return {
                ...state,
                isLoading: true
            };
        default:
            throw new Error();
    }
}

interface RoleActivityTabProps {
    roleId: number;
}

const RoleActivityTab = ({ roleId }: RoleActivityTabProps) => {
    const color = useRandomColor(roleId);
    const { classes } = useStyles({ color });

    const [state, dispatch] = useReducer(reducer, {
        isLoading: false,
        perPage: 50,
        roleHistory: null,
        roleHistoryCount: 0
    });

    const getRoleHistory = (request: { page: number; 'per_page': number; types: Array<string>  }) => {
        ( async () => {
          try {
            const { data } = await RolebotClient.get('/roles/' + roleId + '/history', {	params: request	})
            dispatch({ type: 'getRoleHistoryReturned', payload: {...data}});
          } catch (error) {
            console.log(error);
          }
        })();
    };

    const getPageNumber = () => {
        return 1;
    };

    const handleGetRoleHistory = async () => {
        const pageNumber = getPageNumber();
        if (pageNumber !== -1) {
            const request = {
                page: pageNumber,
                'per_page': state.perPage,
                types: ['JobTitle', 'JobTitleAI', 'NiceToHaveSkill', 'MustHaveSkill', 'MustHaveSkillAI', 'Language', 'Industry', 'Certificate', 'Keyword', 'ExcludedSkill', 'ExcludedJobTitle']
            };
            dispatch({ type: 'onRequestMade' })
            getRoleHistory(request);
        }
    }

    useEffect(() => {
        handleGetRoleHistory();
    }, [roleId]);

    return (
        <div style={{paddingTop: 10}}>
            {state.roleHistory && (
                Object.keys(state.roleHistory).map((key, index) => {
                    return (
                        <RoleActivityRow
                            key={index}
                            date={key}
                            tags={state.roleHistory[key]}
                        />
                    )
                })
            )}

            {state.roleHistory && !Object.keys(state.roleHistory).length && (
                <div style={{ overflow: 'hidden', marginTop: '3rem' }}>
                    <Stack align={'center'}>
                        <>
                            <img className={classes.image} src={RolebotSleeping} alt='No activity found'/>
                            <Stack align={'center'}>
                                <Container size={500} className={classes.NoActivityTextContainer}>
                                    <Text align='center' className={classes.NoActivityHeading}>No activity in here yet...</Text>
                                    <Text align='center' className={classes.NoActivitySubHeading}>
                                        This section will populate once you or your team update the search criteria.
                                    </Text>
                                </Container>
                            </Stack>
                        </>
                    </Stack>
                </div>
            )}
        </div>
    );
};

export default RoleActivityTab;