import React from 'react';
import { Alert, Button, Container, createStyles, Group, Image, Stack, Text, TextInput, Title } from '@mantine/core';
import RolebotSmiling from 'assets/img/rolebot-smiling.svg';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import { Link, NavLink } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    image: {
        marginTop: 30,
        marginLeft: 16,
        marginBottom: 30,
        [theme.fn.largerThan('md')]: {
            marginLeft: 50,
            marginBottom: 60,
            marginTop: 40,
        },
    },

    container: {
        marginTop: 30,
        [theme.fn.largerThan('md')]: {
            marginLeft: 'auto',
        },
    },

    bottomText: {
        // [theme.fn.largerThan('md')]: {
        //     marginLeft: 44,
        // },
    },
}));

const CompanyAlreadyExists = () => {
    const { classes } = useStyles();

    return (
        <div style={{ paddingTop: '1px' }}>
            <NavLink to={'/'}>
                <Image src={LogoLetters} width={120} fit={'contain'} className={classes.image} />
            </NavLink>
            <Container size={600} className={classes.container}>
                <Stack align={'center'} spacing={0}>
                    <Image src={RolebotSmiling} width={95} fit={'contain'} mb={35} />
                    <Title
                        order={3}
                        mb={20}
                        style={{
                            color: '#242424'
                        }}
                    >
                        Company already exists
                    </Title>
                    <Text mb={30} color={'#838485'} align={'center'} size={'md'}>
                        The company you’re trying to create already exists. Ask your company's Admin for an invite or Contact Support for help.
                    </Text>
                    <Group spacing={4} position={'center'} className={classes.bottomText}>
                        <Text>Have an account?</Text>
                        <Text component={Link} to={'/'} color={'violet'}>
                            {' '}
                            Sign in
                        </Text>
                    </Group>
                </Stack>
            </Container>
        </div>
    );
};
export default CompanyAlreadyExists;
