import {
  ActionIcon,
  Box,
  Checkbox,
  createStyles, Input,
  Loader,
  LoadingOverlay,
  MultiSelect,
  ScrollArea,
  Select,
  Stack,
  Text
} from '@mantine/core';
import * as z from 'zod';
import React, {useCallback, useEffect, useState} from 'react';
import useDebounce from "../../../../pages/RoleStats/hooks/useDebounce";
import {RolebotClient} from "../../../../api/client";
import {sendGoogleEvent} from "../../../../utils/analytics";
import {faPlus, faTrash, faXmark, faMinus} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { get } from 'lodash';
import RolebotSelect from 'components/Inputs/RolebotSelect';
import RolebotTextInput from 'components/Inputs/RolebotTextInput';

const useStyles = createStyles(() => ({

  CandidateExperienceWrapper: {
    flexGrow: 1
  },

  GenderDiversityDiv: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 1,
    ':not(:first-child)':{
      marginTop: 10
    }
  },

  CandidateCertificateTypeDropdown: {
    width: 180,
    marginRight: 10
  },

  CandidateCertificateTypeDiv: {
    flexDirection: "column",
    marginBottom: 0
  },

  RoleSearchCriteriaVariableDiv: {
    // display: "flex",
    marginBottom: 15,
    // flexWrap: 'wrap'
  },

  SuggestedAiRoles: {
    marginBottom: 20
  },

  MultiSelectDiv: {
    // flexGrow: 1
  },

  svg: {
    width: 16
  },

  ButtonWrapper: {
    width: 36,
    height: 36,
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },

  CertificateInput: {
    marginRight: 10
  }
}));

interface SearchCriteriaCertificatesProps {
  onCertificatesChange: (payload: { title: string }[]) => void;
  preloadedCertificates: any;
  onCertificatesCleared: () => void;
}

const SearchCriteriaCandidateCertificates = ({ onCertificatesChange, preloadedCertificates, onCertificatesCleared }: SearchCriteriaCertificatesProps) => {
  const { classes } = useStyles();
  const [firstRun, setFirstRun] = useState(true);
  const [certificateTypes, setCertificateTypes] = useState([
    {value: "Certificate", label: "Certificate"},
    {value: "License", label: "License"},
    {value: "Specific degree", label: "Specific degree"},
  ]);
  const [selectedCertificateType, setSelectedCertificateType] = useState(0);
  const [certificates, setCertificates] = useState([
    {type: "Certificate", title: ""}
  ]);


  //In some instances the role info data could be loading and we would need to reset the form to get new values
  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }

  }, []);

  useEffect(() => {

    if (preloadedCertificates?.length) {
      let certificatesToDisplay = preloadedCertificates.map((x: { pivot: { type: any; }; id: number; title: any; type: any }) => {
        return {type: x.pivot ? x.pivot.type : x.type, id: x.id, title: x.title}
      });

      setCertificates(certificatesToDisplay);
      console.log(certificatesToDisplay, "certificates to display?");
    }


  }, [preloadedCertificates]);

  useEffect(() => {

    // logic that sends updated data back to role info form
    onCertificatesChange(certificates.filter(x => x.title.trim() !== ''));
    if (!firstRun && certificates.length === 0) {
      onCertificatesCleared();
    }
  }, [certificates, firstRun]);

  const handleCertificateChange = (index: number, field: string, value: string | null) => {
    const updatedCertificates = [...certificates];
    // @ts-ignore
    updatedCertificates[index][field] = value;

    setCertificates(updatedCertificates);
    // console.log(certificates, "certificates");
  };

  const handleAddCertificate = () => {
    setCertificates([...certificates, {type: "Certificate", title: ""}]);
  };

  const handleRemoveCertificate = (index: number) => {

    const updatedCertificates = [...certificates];
    updatedCertificates.splice(index, 1);
    setCertificates(updatedCertificates);

    // console.log(certificates, "certificates");
  };

  const getPlaceholder = (type: string) => {
    switch (type) {
      case "Certificate":
        return "e.g. ANCC";
      case "License":
        return "e.g. Building Inspector";
      case "Specific degree":
        return "e.g. Computer Science";
      default:
        return "e.g. ANCC";
    }
  };

  return (
      <div className={classes.CandidateExperienceWrapper}>
        {certificates.map((certificate, index) => (
          <div key={index} className={classes.GenderDiversityDiv}>
            <div className={classes.CandidateCertificateTypeDiv}>
              <RolebotSelect
                  className={classes.CandidateCertificateTypeDropdown}
                  label=""
                  placeholder="Pick one"
                  //@ts-ignore
                  value={certificate.type}
                  //@ts-ignore
                  data={certificateTypes}
                  onChange={(value) =>
                      handleCertificateChange(index, "type", value)
                  }
              />
            </div>
            <RolebotTextInput
                className={classes.CertificateInput}
                value={certificate.title}
                onChange={(event: { target: { value: any; }; }) =>
                    handleCertificateChange(index, "title", event.target.value)
                }
                placeholder={getPlaceholder(certificate.type)}
            />
            <div style={{display: 'flex', alignItems: 'center'}}>
              <ActionIcon
                  variant={'transparent'}
                  size={20}
                  className={classes.ButtonWrapper}
                  onClick={() => handleRemoveCertificate(index)}
              >
                <FontAwesomeIcon icon={faMinus} className={classes.svg}  style={{color: '#838485'}} />
              </ActionIcon>
              {index === (certificates.length - 1) &&
              <ActionIcon
                  variant={'transparent'}
                  size={20}
                  className={classes.ButtonWrapper}
                  onClick={handleAddCertificate}
              >
                <FontAwesomeIcon icon={faPlus} className={classes.svg} style={{color: '#40A5D0'}}  />
              </ActionIcon> }
            </div>
          </div>
        ))}
      </div>
  );
};

export default SearchCriteriaCandidateCertificates;
