import React, { useEffect } from 'react';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { Outlet, useNavigate } from 'react-router-dom';

//TODO: We should return is admin status from the /me endpoint and not company.
const AdminLayout = () => {
  const navigate = useNavigate();
  const { data } = useGetCompanyQuery();

  useEffect(() => {
    if (!data) return;

    if (!data.customer_user.is_admin) {
      navigate('/roles');
    }
    // eslint-disable-next-line
  }, [data]);

  return <Outlet />;
};

export default AdminLayout;
