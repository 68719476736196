import React, { forwardRef } from "react"
import { TextInput, TextInputProps } from "@mantine/core"

const RolebotTextInput = forwardRef<HTMLInputElement, TextInputProps>(({size = 'md', ...props}, ref) => {
    return (
        <TextInput
            ref={ref}
            size={size}
            {...props} />
    )
})

export default RolebotTextInput