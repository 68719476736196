import ColumnSortIcons from 'pages/RoleStats/RoleManagerSection/ColumnSortIcons';
import React from 'react';
import { createStyles, Group, Text } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  header: {
    color: '#838485',
    fontSize: theme.fontSizes.md,
  },
}));

const HeaderTitle = ({ title }: { title: string }) => {
  const { classes } = useStyles();
  return <Text className={classes.header}>{title}</Text>;
};

export const RoleNameHeader = ({ currentSortType, currentSortOrder, onCLick }: any) => {
  return (
    <Group spacing={4} align={'center'}>
      <HeaderTitle title={'Role Name'} />
      <ColumnSortIcons
        sortType="roleName"
        sortOrder={currentSortType === 'roleName' ? currentSortOrder : null}
        onClick={onCLick}
      />
    </Group>
  );
};

export const StatusHeader = () => <HeaderTitle title={'Status'} />;

export const TalentSurfacedHeader = ({ currentSortType, currentSortOrder, onCLick }: any) => {
  return <HeaderTitle title={'Talent Surfaced'} />;
};

export const AwaitingReviewHeader = ({ currentSortType, currentSortOrder, onCLick }: any) => {
  return <HeaderTitle title={'Awaiting Review'} />;
};

export const ConfirmedInterviewsHeader = ({ currentSortType, currentSortOrder, onCLick }: any) => {
  return <HeaderTitle title={'# of interviews'} />;
};

export const TypeOfRoleHeader = ({ currentSortType, currentSortOrder, onCLick }: any) => {
  return <HeaderTitle title={'Type of role'} />;
};

export const InterviewerHeader = ({ currentSortType, currentSortOrder, onCLick }: any) => {
  return <HeaderTitle title={'Interviewer'} />;
};

export const UsersInRoleHeader = ({ currentSortType, currentSortOrder, onCLick }: any) => {
  return <HeaderTitle title={'Users in Role'} />;
};

export const RoleCreatedOnHeader = ({ currentSortType, currentSortOrder, onCLick }: any) => {
  return (
    <Group noWrap position={'center'} spacing={4}>
      <HeaderTitle title={'Creation Date'} />
      <ColumnSortIcons
        sortType="createdOn"
        sortOrder={currentSortType === 'createdOn' ? currentSortOrder : null}
        onClick={onCLick}
      />
    </Group>
  );
};

export const LastReviewedHeader = ({ currentSortType, currentSortOrder, onCLick }: any) => {
  return (
    <Group noWrap position={'center'} spacing={4}>
      <HeaderTitle title={'Last Reviewed'} />
      <ColumnSortIcons
        sortType="lastReviewed"
        sortOrder={currentSortType === 'lastReviewed' ? currentSortOrder : null}
        onClick={onCLick}
      />
    </Group>
  );
};
