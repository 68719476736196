import { useGetUserQuery, useGetCompanyQuery, useGetModalsQuery } from 'app/services/rolebot';
import { useEffect, useState } from 'react';
import useOpenModal from './useOpenModal';
import { ContextModalProps, useModals } from '@mantine/modals';
import { AnnouncementsProps } from 'types/modals';
import { createStyles } from '@mantine/core';
import {isLeverOauthRequired} from "../features/app/appSlice";
import {useAppDispatch} from "./useAppDispatch";

const useStyles = createStyles((theme) => ({
    inner: {
      // this is the black out screen behind the modal
      padding: 0,
    },
  
    modal: {
      overflowY: 'scroll',
      height: '100%',
      [theme.fn.largerThan('md')]: {
        height: 'fit-content',
        overflow: 'unset',
      },
    },
  
    body: {
      maxHeight: 'unset',
      overflow: 'unset',
      [theme.fn.largerThan('md')]: {
        height: 'unset',
        maxHeight: 'unset',
      },
    },
  }));

const MODALS_COOKIE_NAME = '_modals_shown'

const useShowModals = () => {
    const dispatch = useAppDispatch();
    const { data: modalsData, isSuccess: modalsSuccess } = useGetModalsQuery()
    const modals = useModals();
    const { classes } = useStyles();
    const [modalsToShow, setModalsToShow] = useState<Array<AnnouncementsProps>>([])

    const getSettings = (type: string)  => {
        if(type === 'announcements') {
            return {
                closeOnClickOutside: false,
                size: 'auto',
                styles: {modal: {padding: '0px !important'}}
            }
        }
        
        if ( type === 'videoTutorial' ) {
            return {
                closeOnClickOutside: true,
                closeOnEscape: true,
                withCloseButton: false,
                classNames: {
                    inner: classes.inner,
                    modal: classes.modal,
                    body: classes.body,
                },
                styles: {
                    modal: { width: '602px', padding: '1px !important' },
                },
            }
        }

        return {
            closeOnClickOutside: false,
            classNames: {
                inner: classes.inner,
                modal: classes.modal,
                body: classes.body,
            },
        }
    }

    /**
     * returns the type of modal needed
     * @param modal 
     */
    const getModalType = (modal: AnnouncementsProps) => {

        if ('type' in modal){

            if (modal.type === 'leverAuthExpiredModal') {
                dispatch(isLeverOauthRequired(true));
            }

            return modal.type!
        } else {
            return 'announcements'
        }
        
    }

    /**
     * Recursive function that handles the creation of the
     * modals chain
     * 
     * @param number index 
     * @returns () => {}
     */
    const buildNextModal = (index: number) => {

        
        let previousType = getModalType(modalsToShow![index-1])

        //base case to end recursion
        if (index >= modalsToShow!.length) {
            return () => {
                if(previousType === 'announcements' || previousType === 'videoTutorial'){
                    saveModalToCookie(previousType === 'announcements' ? modalsToShow[index-1]!.id.toString() : previousType)
                }
                modals.closeAll()
            }
        }

        let mtype = getModalType(modalsToShow![index])
        let settings = getSettings(mtype)
        
        return () => {
            //we save the modal to the app's cookie
            if(previousType === 'announcements' || previousType === 'videoTutorial'){
                saveModalToCookie(previousType === 'announcements' ? modalsToShow[index-1]!.id.toString() : previousType)
            }
            modals.openContextModal( mtype , {
                ...settings,
                innerProps: {
                    ...modalsToShow![index],
                    onClose: buildNextModal(index+1)
                },
            })
        }
    }

    const saveModalToCookie = (id: string) => {
        let cookie = getCookie(MODALS_COOKIE_NAME)
        let updated = cookie === '' ? [] : cookie.split(',')
        updated.push(id)
        setCookie(MODALS_COOKIE_NAME, updated.join(','), 400)
    }
    /**
     * looks for a cookie with the name provided
     * @param cname 
     * @returns 
     */
    const getCookie = (cname : string) => {
        return document.cookie.match('(^|;)\\s*' + cname + '\\s*=\\s*([^;]+)')?.pop() || ''
    }

    /**
     * Creates a cookie with the provided data
     * @param cname 
     * @param cvalue 
     * @param expiretime 
     */
    const setCookie = (cname: string, cvalue: any, expiretime: number) => {
        var expires = ''
        if (expiretime) {
            var date = new Date();
            date.setTime(date.getTime() + (expiretime*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = cname + "=" + (cvalue || "")  + expires + "; path=/";
    }

    /**
     * Compares the modals response with the modals
     * stored in the cookies and return the modals yet to show
     */
    const getModalsToShow = () => {
        let cookie = getCookie(MODALS_COOKIE_NAME)
        let tempModals = []
        if(cookie !== ''){
            var ids = cookie.split(',')
            tempModals = modalsData!.filter( (modal: AnnouncementsProps) => {
                return !ids.includes(('type' in modal) ? modal.type! : modal.id.toString())
            })
            setModalsToShow(tempModals)
        } else {
            setModalsToShow(modalsData)
        }
    }

    
    useEffect(() => {
        if(modalsData && modalsData.length > 0) {
            getModalsToShow()
        }
    }, [modalsData, modalsSuccess])


    useEffect(() => {

        
        if(modalsToShow && modalsToShow.length > 0) {
            let mtype = getModalType(modalsToShow[0])
            let settings = getSettings(mtype)

            //to chain modals, we need to pass the next modal
            //in the onClose function, this way, the next modal will open
            //after the current one has beel closed
            modals.openContextModal( mtype , {
                ...settings,
                innerProps: {
                    ...modalsToShow[0],
                    onClose: buildNextModal(1)
                },
            })

        }

    }, [modalsToShow])

};

export default useShowModals;
