import { CloseButton, MultiSelectProps, MultiSelectValueProps, Box, createStyles } from "@mantine/core"
import { forwardRef } from "react";
import { MultiSelect } from "./MultiSelect";
import { X } from "tabler-icons-react";
import { reduce } from "lodash";

const useStyles = createStyles(() => ({
    rolebotStyles: {
        ':hover': {
            boxShadow: `0 0 0 2px #E2D7FB`,
            borderRadius: 5
          },
        ':focus-within': {
            border: '1 px solid #D9A8FF',
            boxShadow: `0 0 0 2px #E2D7FB`,
            borderRadius: 5
          }
          
    },

    dropdownStyles: {
        ':hover': {
            backgroundColor: "#EEEEEE",
        }
    },

    chipStyles: {
        borderRadius: '5px',
        border: '1px solid var(--silver, #C4C4C4)',
        background: 'var(--cultured, #F7F5F5)',
        display:'flex', alignItems: 'center',
        margin: '3px',

        ':hover': {
            border: '1px solid var(--silver, #838485)',
            cursor: 'pointer'
        }
    },

    xStyles: {
        color: '#B3B3B3',
        ':hover': {
            color: '#4F4F4F',
        }
    } 

}))

const Value = ({
    value,
    label,
    is_from_feedback,
    onRemove,
    classNames,
    ...others
  }: MultiSelectValueProps & { value: string, is_from_feedback: boolean }) => {
    
    const {classes, cx} = useStyles();
    return (
        <div onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }} className={classes.chipStyles}>
            {is_from_feedback ? <Box sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: '#D6328AD9',
                marginLeft: '8px'
            }} /> : null}
            <span style={{paddingLeft:10,color:'#242424'}}>{label}</span>
            {/* <X size={15} cursor='pointer' onClick={onRemove} className={classes.xStyles}></X> */}
            <CloseButton
                aria-hidden
                onMouseDown={onRemove}
                radius={2}
                variant="transparent"
                tabIndex={-1}
                className={classes.xStyles}
            />
        </div>
    )
}

const RolebotMultiselect = forwardRef<HTMLInputElement, MultiSelectProps>(({size = 'md', data = [], className, ...props}, ref) => {
    const {classes, cx} = useStyles();

    return (
        <MultiSelect classNames={{
            item: classes.dropdownStyles,
        }} ref={ref} className={cx(classes.rolebotStyles, className)}  size={size} data={data} valueComponent={Value} {...props}/>
    )
})

export default RolebotMultiselect