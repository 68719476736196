import React from 'react';
import TalentDrawerSection from 'components/TalentDetail/TalentDrawerSection';
import { Group, Stack, Text } from '@mantine/core';
import { ITalentExperience } from 'types';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcase } from '@fortawesome/pro-light-svg-icons';
interface TalentExperienceProps {
  experience: ITalentExperience;
}

const TalentJob = ({ experience }: TalentExperienceProps) => {
  return (
    <TalentDrawerSection title={'Current Position'} withDivider={false}>
      <Group spacing={'xs'} direction={'row'} position={'left'} align={'start'} mb={4}>
        <Stack ml={0} spacing={2}>
          <Group align={'flex-start'} spacing={8} noWrap>
            <Group align={'center'} sx={{ width: 17 }} spacing={0}>
              <FontAwesomeIcon icon={faSuitcase} color={'#4F4F4F'} style={{ height: '16px', marginTop: '4px'}} />
            </Group>
            <Text sx={{ fontFamily: 'Roboto' }} color={'#242424'}>
              {experience.name}
            </Text>
          </Group>
          <Text ml={25} color={'#242424'} sx={{ fontSize: 15 }}>
            {experience?.company_name}
          </Text>
          {experience?.pivot?.start_date && (
            <Text ml={25} color={'#838485'} sx={{ fontSize: 15, lineHeight: 2 }}>
              {dayjs(experience?.pivot?.start_date).format('MMMM YYYY')} -{' '}
              {experience?.pivot?.end_date ? dayjs(experience?.pivot?.end_date).format('MMMM YYYY') : 'Present'}
            </Text>
          )}
        </Stack>
      </Group>
    </TalentDrawerSection>
  );
};

export default TalentJob;
