import { Text, Group, Badge, CloseButton, createStyles } from '@mantine/core';
import {useCallback, useEffect, useState} from 'react';
import {RolebotClient} from "../../../../api/client";
import { useDebouncedValue } from '@mantine/hooks';
import RolebotMultiselect from 'components/Inputs/RolebotMultiSelect';
import { faWandSparkles } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePostRemoveSuggestionMutation } from 'app/services/rolebot';

const useStyles = createStyles(() => ({
  xStyles: {
    color: '#B3B3B3',
    ':hover': {
      color: '#4F4F4F',
    },
    marginLeft: '-5px'
  }
}))

interface SearchCriteriaIndustryExperienceProps {
  onIndustriesChange: (payload: object) => void;
  industries: any;
  removeIndustryFromSuggestions: (suggestion: any) => void;
  suggestedIndustries: any;
  handleAddIndustrySuggestion: (suggestion: any) => void;
  industriesSet: any;
  addIndustryToBlacklist: (industry: any) => void;
}

// transforms the data to a Mantine MultiSelect readable format
const formatDropdownItems = (items: { id: any; name: any; }[]) => {

  if (items?.length) {
    return items?.map((x: { id: any; value?: any; name: any; label?: any; pivot?: any; }) => {
      return {
        value: x.id.toString(),
        label: x.name,
        id: x.id,
        name: x.name,
        is_from_feedback: x.pivot?.is_from_feedback
      };
    });
  } else {
    return []
  }
}


const getUniqueListById = (arr: any) => {
  return [...new Map(arr.map((item:any) => [item['id'], item])).values()]
}

const SearchCriteriaIndustryExperience = ({ onIndustriesChange, industries, removeIndustryFromSuggestions, suggestedIndustries, industriesSet }: SearchCriteriaIndustryExperienceProps) => {
  
  const combinedIndustries = industries.length === 0 ? industriesSet : industries;

  const [industrySearchTerm, setIndustrySearchTerm] = useState("");
  const [availableIndustries, setAvailableIndustries] = useState(combinedIndustries.length ? formatDropdownItems(combinedIndustries) : []);
  const [selectedIndustries, setSelectedIndustries] = useState(combinedIndustries.map( (i:any) => i.id.toString()));
  const [industrySuggestions, setIndustrySuggestions] = useState(suggestedIndustries.length ? suggestedIndustries : []);
  const [debouncedSearch] = useDebouncedValue(industrySearchTerm.trim(), 500);
  const [noMatches, setNoMatches] = useState(false)
  const { classes, cx } = useStyles();
  const [postRemoveSuggestion] = usePostRemoveSuggestionMutation();

  useEffect(() => {
    onIndustriesChange(formatPayloadItems(selectedIndustries));
  }, [selectedIndustries]);

  useEffect(() => {
    if ( industrySearchTerm ) searchForIndustryTag(industrySearchTerm)
  }, [debouncedSearch])
 
  // transforms the data to a back to the expected from API format  e.g: id: 1, name: "Laravel"
  // applicable for all search criteria variables
  const formatPayloadItems = (items: string[]) => {

    if (items?.length) {
      return items?.map((id:string) => {
        let object = availableIndustries.find((i:any) => i.id.toString() === id)
        return {
          id: object?.id ?? id,
          name: object?.name ?? id,
        };
      });
    } else {
      return []
    }
  }


  const searchForIndustryTag = useCallback((searchTerm) => {
    ( async () => {
      setNoMatches(false)
      try {
        const { data } = await RolebotClient.get('/industries', { params: { 'search-term': searchTerm } });

        if (data.data && data.data.length) {
          // @ts-ignore
          setAvailableIndustries((prev) => getUniqueListById([...prev, ...formatDropdownItems(data.data)]));
        } else {
          setNoMatches(true)
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [debouncedSearch]);

  const copyToAvailable = (values: string[]) => {
    let selected = availableIndustries.filter( (x:any) => values.includes(x.value))

    setAvailableIndustries(selected)
    setSelectedIndustries(values)
  }

  const handleAddSuggestion = (suggestion: any) => {
    const payload = {
      value: suggestion.industry_id.toString(),
      label: suggestion.industry_name,
      id: suggestion.industry_id,
      name: suggestion.industry_name,
      is_from_feedback: false
    }

    setAvailableIndustries(prevIndustries => {
      const newIndustries = [...prevIndustries];
      const existingIndex = newIndustries.findIndex(suggestion => suggestion.id === payload.id);
      if (existingIndex === -1) {
        newIndustries.push(payload);
      }
  
      return newIndustries;
    });
  
    setSelectedIndustries((prevSelectedIndustries: string[]) => {
      const newSelected = [...prevSelectedIndustries];
      if (!newSelected.includes(payload.id.toString())) {
        newSelected.push(payload.id.toString());
      }
      return newSelected;  

    });

    removeIndustryFromSuggestions(suggestion);
    setIndustrySuggestions(industrySuggestions.filter((item: any) => item.id !== suggestion.id));
  };

  const addIndustrySuggestionToBlacklist = (suggestion: any) => {
    const payload = {
      type: 'industries',
      industry_id: suggestion.industry_id
    };

    (async () => {
      try {
        await postRemoveSuggestion({
          roleId: suggestion.role_id,
          payload
        }).unwrap()
          // @ts-ignore
          .then((response) => {
            console.log('response', response)
          });
      } catch (e) {
        console.log('error', e)
      }
    })();

    setIndustrySuggestions(industrySuggestions.filter((item: any) => item.id !== suggestion.id));
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <RolebotMultiselect
        data={availableIndustries}
        searchable
        value={selectedIndustries}
        getCreateLabel={(query) => `+ Create ${query}`}
        onChange={copyToAvailable}
        nothingFound={noMatches ? (<span>No matches found for <strong style={{color:'#242424'}}>{industrySearchTerm}</strong></span>) : 'Type to search'}
        onSearchChange={setIndustrySearchTerm}
        selectOnBlur={true}
        clearSearchOnBlur={true}
        clearSearchOnChange={true}
        style={{ width: '100%' }}
        maxSelectedValues={100}
        onBlur={() => {
          setNoMatches(false);
          setAvailableIndustries(availableIndustries.filter((x: any) => selectedIndustries.includes(x.value)));
        }}
      />
    {(industrySuggestions?.length > 0 && combinedIndustries?.length > 0) && (
    <Group my={10} spacing={10}>
      <Group
        spacing={10}
        style={{
          backgroundColor: '#40A5D01A',
          fontStyle: 'italic',
          color: '#147CA1',
          padding: '2px',
          borderRadius: '2px',
          paddingLeft: '5px',
          paddingRight: '5px'
        }}
      >
        <Text>Suggested by AI</Text>
        <FontAwesomeIcon icon={faWandSparkles} />
      </Group>
      {industrySuggestions.map((suggestion: any) => (
          <Badge
            styles={{
              root: {
                backgroundColor: 'transparent'
              },
              inner: {
                backgroundColor: 'transparent',
                color: '#838485',
                textDecoration: 'underline',
                cursor: 'pointer'
              }
            }}
            style={{
              marginRight: '-10px'
            }}
            key={suggestion.id}
            rightSection={<CloseButton
              aria-hidden
              radius={2}
              variant="transparent"
              tabIndex={-1}
              className={classes.xStyles}
              onClick={(event) => event.stopPropagation()}
              onMouseDown={() => addIndustrySuggestionToBlacklist(suggestion)}
            />}
            onClick={() => handleAddSuggestion(suggestion)}
          >
            {suggestion.industry_name}
          </Badge>       

        ))}
    </Group>
        )}
    </div>
   
  );
};

export default SearchCriteriaIndustryExperience;
