import React from 'react';
import { Badge, BadgeVariant } from '@mantine/core';

interface BadgeProps {
  children: React.ReactNode;
  color: string;
  backgroundColor: string;
  variant: BadgeVariant;
}

const RolebotBadge = ({ children, color, backgroundColor, ...rest }: BadgeProps) => {
  return (
    <Badge
      styles={() => ({
        filled: {
          backgroundColor,
          color,
        },
        flat: {
          backgroundColor,
          color,
        },
        outline: {
          backgroundColor,
          color,
        },
      })}
      {...rest}
    >
      {children}
    </Badge>
  );
};

export default RolebotBadge;
