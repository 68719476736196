import React, { useState } from 'react';
import { ContextModalProps } from '@mantine/modals';
import { Group, Stack } from '@mantine/core';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { DisconnectModalProps } from 'types/modals';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const DisconnectIntegration = ({ id, innerProps, context }: ContextModalProps<DisconnectModalProps>) => {
  const [isLoading, setIsLoading] = useState(false);
  const { integration, content, onAction } = innerProps;

  const _onAction = async () => {
    setIsLoading(true);
    await onAction();
    setIsLoading(false);
    context.closeModal(id);
  };

  return (
    <>
      <TitleWithClose id={id} title={`Disconnect from ${integration}`} />
      <Stack sx={{ color: '#242424' }}>
        {content}
        <Group noWrap position={'right'} mt={24}>
          <RolebotButton type={'neutral'} onClick={() => context.closeModal(id)}>
            Cancel
          </RolebotButton>

          <RolebotButton type={'destructive'} loading={isLoading} onClick={_onAction}>
            Disconnect App
          </RolebotButton>
        </Group>
      </Stack>
    </>
  );
};

export default DisconnectIntegration;
