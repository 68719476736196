import React from 'react';
import { ContextModalProps } from '@mantine/modals';
import {createStyles, Divider, Tabs} from '@mantine/core';
import RoleInfo from 'components/Modals/RoleInfo/RoleInfo';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { RoleSetupProps } from 'types/modals';

const useStyles = createStyles((theme) => ({
    hiddenOnMobile: {
        display: 'none',
        [theme.fn.largerThan('md')]: {
            display: 'revert'
        },
    }
}));

const RoleSetup = ({ id, context, innerProps }: ContextModalProps<RoleSetupProps>) => {
  const { classes } = useStyles();
  const { roleId, roleName, initialStep = 1, showInfo, onSetupComplete, onAdjustSearchCriteriaComplete, shouldTriggerFeedbackFlow } = innerProps;

  return (
    <>
      <TitleWithClose id={id} title={roleName} mb={20} />
      <Divider className={classes.hiddenOnMobile} style={{border: '1px solid #F1F1F1'}} />
      <RoleInfo context={context} id={''} innerProps={{ roleId, roleName, initialStep, origin: 'roleSetup', onSetupComplete, onAdjustSearchCriteriaComplete, shouldTriggerFeedbackFlow }} />
    </>
  );
};

export default RoleSetup;
