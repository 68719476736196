import { faFolderMagnifyingGlass, faLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Stack, Group, UnstyledButton, createStyles, Text } from "@mantine/core"

type ATSSidebarProps = {
    activeOption: string, 
    onChange: (arg: string) => void;
}

const useStyles = createStyles((theme) => ({
    button: {
      minWidth: 'fit-content',
      backgroundColor: 'transparent',
      borderRadius: 10,
      paddingInline: 10,
      paddingBlock: 10,
      ':hover': {
        backgroundColor: '#EEEEEE',
        transition: 'background-color 0.2s ease-in-out',
      },
      [theme.fn.largerThan('md')]: {
        minWidth: 150,
      },
    },
    activeButton: {
      backgroundColor: '#8241FD2E',
      ':hover': {
        backgroundColor: '#8241FD2E',
      },
    },
}));
  
const ATSSidebar = ({activeOption, onChange}: ATSSidebarProps) => {
    const { classes, cx } = useStyles();

    const options = {
        connect: {
            label: 'Connect roles to ATS',
            icon: faFolderMagnifyingGlass
        },
        link: {
            label: 'View linked roles',
            icon: faLink
        }
    }

    return (
        <>
            <Stack spacing={5}>
                {Object.keys(options).map((option, i) => {
                    return (
                        <UnstyledButton
                            className={cx(classes.button, { [classes.activeButton]: activeOption === option })}
                            key={i}
                            onClick={() => onChange(option)}
                        >
                            <Group noWrap spacing={10} >
                            <FontAwesomeIcon
                                icon={options[option as keyof typeof options].icon}
                                width={18}
                                height={18}
                                style={{ paddingBottom: '2px', color: activeOption === option ? "#24154F" : "#242424" }}
                            />
                            <Text style={{color: activeOption === option ? "#24154F" : "#242424", whiteSpace: 'nowrap'}}>{options[option as keyof typeof options].label}</Text>
                            </Group>
                        </UnstyledButton>
                    )
                })}
            </Stack>
        </>
    )
}

export default ATSSidebar