import React from 'react';
import { Group } from '@mantine/core';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const ContactSupportLink = () => {
  const { contactSupport } = useOpenModal();
  const openSupport = () => contactSupport();
  return (
    <Group noWrap spacing={6} onClick={openSupport} sx={{ cursor: 'pointer' }} align={'center'}>
      <RolebotButton type={'link'} onClick={openSupport}>
        <FontAwesomeIcon icon={faEnvelope} style={{ height: '17px', marginRight: '10px', marginBottom: '2px' }} /> {' Contact Rolebot'}
      </RolebotButton>
    </Group>
  )
};

export default ContactSupportLink;
