import ThumbsDown from 'components/ActionButtons/ThumbsDown';
import ThumbsUp from 'components/ActionButtons/ThumbsUp';
import Skip from 'components/ActionButtons/Skip';
import { Group } from '@mantine/core';
import React from 'react';
import { ITalent } from 'types';
import { useMediaQuery } from '@mantine/hooks';

export const MobileAcceptedContent = ({
  talent,
  roleId,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive,
  isFeatureLocked
}: {
  talent?: ITalent;
  roleId: string;
  handleSetTalent?: any;
  handlePatchCompleted?: any;
  isButtonsActive: boolean;
  isFeatureLocked: boolean;
}) => {
  const isMediumBreakpoint = useMediaQuery('(min-width: 993px)');
  const thumbsUp = (
    <ThumbsUp
      talentId={talent?.id!}
      roleId={Number(roleId)}
      active={
        talent?.pivot.skipped_at === null && talent?.pivot.skipped_reason === null && Boolean(talent?.pivot.accepted)
      }
      handleSetTalent={handleSetTalent}
      handlePatchCompleted={handlePatchCompleted}
      isButtonsActive={isButtonsActive}
      isFeatureLocked={isFeatureLocked}
    />
  );

  const thumbsDown = (
    <ThumbsDown
      talentId={talent?.id!}
      roleId={Number(roleId)}
      active={talent?.pivot.accepted === null ? false : !talent?.pivot.accepted}
      acceptedOn={talent?.pivot.accepted_on}
      isDrawerMode={true}
      handleSetTalent={handleSetTalent}
      handlePatchCompleted={handlePatchCompleted}
      isButtonsActive={isButtonsActive}
      isFeatureLocked={isFeatureLocked}
    />
  );

  const content = isMediumBreakpoint ? (
    <>
      {thumbsUp}
      {thumbsDown}
    </>
  ) : (
    <>
      {thumbsDown}
      {thumbsUp}
    </>
  );

  return (
    <Group
      position={'center'}
      sx={{
        flexWrap: 'nowrap',
        flexDirection: 'row',
      }}
      spacing={20}
    >
      {content}
    </Group>
  );
};

export const MobileDeclinedContent = ({
  talent,
  roleId,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive,
  isFeatureLocked
}: {
  talent?: ITalent;
  roleId: string;
  handleSetTalent?: any;
  handlePatchCompleted?: any;
  isButtonsActive: boolean;
  isFeatureLocked: boolean;
}) => {
  const isMediumBreakpoint = useMediaQuery('(min-width: 993px)');
  const thumbsDown = (
    <ThumbsDown
      talentId={talent?.id!}
      roleId={Number(roleId)}
      active={true}
      acceptedOn={talent?.pivot.accepted_on}
      isDrawerMode={true}
      handleSetTalent={handleSetTalent}
      handlePatchCompleted={handlePatchCompleted}
      isButtonsActive={isButtonsActive}
      isFeatureLocked={isFeatureLocked}
    />
  );
  const thumbsUp = (
    <ThumbsUp
      talentId={talent?.id!}
      roleId={Number(roleId)}
      active={
        talent?.pivot.skipped_at === null && talent?.pivot.skipped_reason === null && Boolean(talent?.pivot.accepted)
      }
      handleSetTalent={handleSetTalent}
      handlePatchCompleted={handlePatchCompleted}
      isButtonsActive={isButtonsActive}
      isFeatureLocked={isFeatureLocked}
    />
  );

  const content = isMediumBreakpoint ? (
    <>
      {thumbsUp}
      {thumbsDown}
    </>
  ) : (
    <>
      {thumbsDown}
      {thumbsUp}
    </>
  );

  return (
    <Group position={'center'} noWrap direction={'row'} spacing={20}>
      {content}
    </Group>
  );
};

export const MobileSkippedContent = ({
  talent,
  roleId,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive,
  isFeatureLocked
}: {
  talent?: ITalent;
  roleId: string;
  handleSetTalent?: any;
  handlePatchCompleted?: any;
  isButtonsActive: boolean;
  isFeatureLocked: boolean;
}) => {
  const isMediumBreakpoint = useMediaQuery('(min-width: 993px)');
  const thumbsDown = (
    <ThumbsDown
      talentId={talent?.id!}
      roleId={Number(roleId)}
      active={false}
      acceptedOn={talent?.pivot.accepted_on}
      isDrawerMode={true}
      handleSetTalent={handleSetTalent}
      handlePatchCompleted={handlePatchCompleted}
      isButtonsActive={isButtonsActive}
      isFeatureLocked={isFeatureLocked}
    />
  );
  const thumbsUp = (
    <ThumbsUp
      talentId={talent?.id!}
      roleId={Number(roleId)}
      active={false}
      handleSetTalent={handleSetTalent}
      handlePatchCompleted={handlePatchCompleted}
      isButtonsActive={isButtonsActive}
      isFeatureLocked={isFeatureLocked}
    />
  );

  const content = isMediumBreakpoint ? (
    <>
      {thumbsUp}
      {thumbsDown}
    </>
  ) : (
    <>
      {thumbsDown}
      {thumbsUp}
    </>
  );
  return (
    <Group position={'center'} sx={{ justifyContent: 'center', alignItems: 'center' }} noWrap spacing={20}>
      {content}
    </Group>
  );
};

export const DefaultMobileContent = ({
  talent,
  roleId,
  handleSetTalent,
  handlePatchCompleted,
  isButtonsActive = true,
  isFeatureLocked
}: {
  talent?: ITalent;
  roleId: string;
  handleSetTalent: any;
  handlePatchCompleted?: any;
  isButtonsActive: boolean;
  isFeatureLocked: boolean;
}) => (
  <Group position={'center'} spacing={20} sx={{ justifyContent: 'center', alignItems: 'center' }}>
    <ThumbsDown
      talentId={talent?.id!}
      roleId={Number(roleId)}
      active={talent?.pivot.accepted === null ? false : !talent?.pivot.accepted}
      acceptedOn={talent?.pivot.accepted_on}
      isDrawerMode={true}
      handleSetTalent={handleSetTalent}
      handlePatchCompleted={handlePatchCompleted}
      isButtonsActive={isButtonsActive}
      isFeatureLocked={isFeatureLocked}
    />
    <ThumbsUp
      talentId={talent?.id!}
      roleId={Number(roleId)}
      active={
        talent?.pivot.skipped_at === null && talent?.pivot.skipped_reason === null && Boolean(talent?.pivot.accepted)
      }
      handleSetTalent={handleSetTalent}
      handlePatchCompleted={handlePatchCompleted}
      isButtonsActive={isButtonsActive}
      isFeatureLocked={isFeatureLocked}
    />
    <Skip
      roleId={Number(roleId)}
      talentId={talent?.id!}
      active={talent?.pivot.skipped_at !== null || talent?.pivot.skipped_reason !== null}
      handleSetTalent={handleSetTalent}
      handlePatchCompleted={handlePatchCompleted}
      isFeatureLocked={isFeatureLocked}
    />
  </Group>
);
