import { Box, Col, Stack, Text, Title, Image, Button } from "@mantine/core";
import RolebotSelect from "components/RolebotSelect/RolebotSelect";
import { useState, useEffect, useMemo } from "react";
import ProPlan from 'assets/img/pro-plan.png';
import { ChevronDown } from "tabler-icons-react";
import RolebotButton from 'components/public/Buttons/RolebotButton';

interface RolebotPlanProps {
    product: any;
    isPro: boolean;
    activeRoleLimit: number | undefined;
    activeRoleCount: number | undefined;
    billingCycle: string;
    handlePlanSelected: (plan: any) => void;
    handleContactSales: () => void;
}

const RolebotPlan = ({ product, isPro, activeRoleLimit, activeRoleCount, billingCycle, handlePlanSelected, handleContactSales }: RolebotPlanProps) => {
    const [roleNumber, setRoleNumber] = useState([
        { value: "1", label: "1 role", disabled: activeRoleLimit === 1 || (activeRoleCount !== undefined && 1 < activeRoleCount) },
        { value: "2", label: "2 roles", disabled: activeRoleLimit === 2 || (activeRoleCount !== undefined && 2 < activeRoleCount) },
        { value: "3", label: "3 roles", disabled: activeRoleLimit === 3 || (activeRoleCount !== undefined && 3 < activeRoleCount) },
        { value: "4", label: "4 roles", disabled: activeRoleLimit === 4 || (activeRoleCount !== undefined && 4 < activeRoleCount) },
        { value: "5", label: "5 roles", disabled: activeRoleLimit === 5 || (activeRoleCount !== undefined && 5 < activeRoleCount) }
    ]);

    const allOptionsDisabled = useMemo(() => roleNumber.every(option => option.disabled), [roleNumber]);

    const [selectedRoleNumber, setSelectedRoleNumber] = useState(() => {
        const nextRoleNumber = Math.min((activeRoleLimit || 0) + 1, 5);
        return nextRoleNumber;
    });
    const [productPrice, setProductPrice] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handleSelectedRoleNumber = (value: string) => {
        setSelectedRoleNumber(parseInt(value, 10));
    };

    const handleSelectAndContinue = () => {
        const payload = {
            productId: product.id,
            productPrice: productPrice,
            sellingPlanId: selectedPlan,
            activeRoleLimit: selectedRoleNumber
        }

        handlePlanSelected(payload);
    }

    useEffect(() => {
        if (isPro) {
            setProductPrice(0);
        } else {
            const sellingPlanGroups = product.selling_plan_groups;

            if (sellingPlanGroups && sellingPlanGroups.length > 0) {
                const monthlyPlanGroup = sellingPlanGroups.find((group: any) => group.name === 'Monthly Subscription');
                const yearlyPlanGroup = sellingPlanGroups.find((group: any) => group.name === 'Yearly Subscription');

                let selectedPlan;
                if (billingCycle === 'yearly' && yearlyPlanGroup) {
                    selectedPlan = yearlyPlanGroup.selling_plans[0];
                } else if (monthlyPlanGroup) {
                    selectedPlan = monthlyPlanGroup.selling_plans[0];
                }

                setSelectedPlan(selectedPlan?.id);

                if (selectedPlan) {
                    const adjustmentValue = selectedPlan.pricing_policies[0].adjustment_value;
                    const adjustmentType = selectedPlan.pricing_policies[0].adjustment_type;
                    
                    let price = parseFloat(product.price) / 100;
                    if (adjustmentType === 'PERCENTAGE') {
                        price = price * (1 - adjustmentValue / 100);
                    }
                    
                    setProductPrice(Math.round(price));
                } else {
                    setProductPrice(Math.round(parseFloat(product.price) / 100));
                }
            } else {
                setProductPrice(Math.round(parseFloat(product.price) / 100));
            }
        }
    }, [billingCycle, product.price, isPro, product.selling_plan_groups]);

    return (
        <>
            <Box
                sx={{
                    maxWidth: '330px',
                    minWidth: '330px',
                    backgroundColor: isPro ? '#FFFFFF' : '#FDF8FF',
                    border: isPro ? '2px solid #3A83F6' : '2px solid #7039ED',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    height: '100%',
                }}
            >
                <Stack align={'center'}>
                    <Title order={2}
                        style={{
                            fontSize: '22px',
                            fontWeight: 500,
                            marginBottom: '10px'
                        }}
                    >
                        {product.title}
                    </Title>
                    {!isPro ? (
                        <RolebotSelect
                            rightSection={<ChevronDown size={20} />}
                            data={roleNumber}
                            value={selectedRoleNumber.toString()}
                            onChange={handleSelectedRoleNumber}
                            size='sm'
                        />
                    ) : (
                        <Text
                            style={{
                                marginTop: '5px',
                                marginBottom: '5px',
                                fontSize: '16px',
                                color: '#242424',
                                fontWeight: 400
                            }}
                        >
                            6+ roles
                        </Text>
                    )}
                    {!isPro ?
                        (
                            <Text
                                style={{
                                    fontSize: '35px',
                                    color: '#242424'
                                }}
                            >
                                ${productPrice * selectedRoleNumber}<span style={{ fontSize: '18px' }}> / month</span>
                            </Text>
                        ) : (
                            <Image src={ProPlan} alt={'ProPlan'} fit={'contain'} height={40} pt={10} />
                        )
                    }
                    <Text
                        style={{
                            fontSize: '12px',
                            fontWeight: 600,
                            color: '#242424',
                            fontFamily: 'Roboto',
                            marginTop: '10px',
                        }}
                    >
                        {isPro ? 'Everything in Lite, plus' : product.title + ' plan includes'}
                    </Text>
                    {!isPro ? (
                        <div
                            style={{ height: '150px', overflow: 'auto' }}
                            dangerouslySetInnerHTML={{ __html: product.description || '' }}
                        />
                    ) : (
                        <div
                            style={{ height: '150px', overflow: 'auto' }}
                        >
                            <div style={{ textAlign: 'left', lineHeight: '1.7', fontSize: '12px' }}>
                                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424', marginTop: '10px' }}>
                                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>As many roles as needed
                                </p>
                                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424' }}>
                                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>Dedicated customer success manager
                                </p>
                                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424' }}>
                                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>Interchangeable roles
                                </p>
                                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424' }}>
                                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>Ability lo launch sourcing-only roles
                                </p>
                                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424' }}>
                                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>Live & email support
                                </p>
                            </div>
                        </div>
                    )}
                    {isPro ? (
                        <RolebotButton
                            onClick={handleContactSales}
                            type={'secondary'}
                            mt="xl"
                        >
                            Contact Sales
                        </RolebotButton>
                    ) : (
                        <Button
                            mt="xl"
                            px={20}
                            tabIndex={3}
                            onClick={handleSelectAndContinue}
                            disabled={allOptionsDisabled}
                        >
                            Select and continue
                        </Button>
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default RolebotPlan;
