import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useOutsideAreaClick from '../../../../pages/RoleStats/hooks/useOutsideAreaClick';
import { createStyles, Loader } from '@mantine/core';
import { useLazyGetICIMSRolesQuery } from '../../../../app/services/rolebot';
import useDebounce from '../../../../pages/RoleStats/hooks/useDebounce';
import LinkableICIMSRoleItem from './LinkableICIMSRoleItem';

const useStyles = createStyles((theme) => ({
  ICIMSRolesDropDownWrapper: {
    position: 'relative',
  },

  ICIMSRolesDropDown: {
    border: 'solid 2px #DFE1E1',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 5px 5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: '1000',
    top: '0',
    left: '0',
    right: '0',
    padding: '5px 7px',
    maxHeight: '200px',
    overflowY: 'auto',
  },

  ICIMSLoadingItem: {
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '0',
    color: '#838485',
    padding: '2px 10px',
  },
}));

const LinkableICIMSRolesDropDown = ({ show, linkableICIMSRoleSelected, needle, closeAllDropdowns }) => {
  const { classes } = useStyles();
  const searchDebounced = useDebounce(needle, 1000);
  const [isLoading, setIsLoading] = useState(false);
  const [getICIMSRoles] = useLazyGetICIMSRolesQuery();
  const [icimsRolesToShow, setICIMSRolesToShow] = useState([]);
  const wrapperRef = useRef(null);
  const clickedOutside = useOutsideAreaClick(wrapperRef);

  useEffect(() => {
    if (clickedOutside && show) {
      closeAllDropdowns();
    }
    // eslint-disable-next-line
  }, [clickedOutside, show]);

  useEffect(() => {
    if (searchDebounced && searchDebounced.length > 0) {
      let numberCheck = /^[0-9]*$/;
      if (numberCheck.test(searchDebounced)) {
        getICIMSRoles({ type: 'reqId', value: searchDebounced, state: 'published' }).then((res) => {
          let rolesToShow = res?.data?.icims_jobs?.filter(
            (x) => x.icims_job_name.toLowerCase().includes(needle.toLowerCase()) || x.job_id.includes(needle)
          );
          setICIMSRolesToShow(rolesToShow);
          setIsLoading(false);
        });
      } else {
        getICIMSRoles({ type: 'name', value: searchDebounced, state: 'published' }).then((res) => {
          let rolesToShow = res?.data?.icims_jobs?.filter(
            (x) => x.icims_job_name.toLowerCase().includes(needle.toLowerCase()) || x.job_id.includes(needle)
          );
          setICIMSRolesToShow(rolesToShow);
          setIsLoading(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [searchDebounced]);

  useEffect(() => {
    if (needle.length > 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [needle]);

  return show && !isLoading && needle && icimsRolesToShow?.length > 0 ? (
    <div className={classes.ICIMSRolesDropDownWrapper}>
      <div className={classes.ICIMSRolesDropDown} ref={wrapperRef}>
        {icimsRolesToShow?.length > 0 &&
          icimsRolesToShow.map((x) => (
            <LinkableICIMSRoleItem role={x} key={x.id} linkableICIMSRoleSelected={linkableICIMSRoleSelected} />
          ))}
      </div>
    </div>
  ) : show && !isLoading && needle && icimsRolesToShow?.length === 0 ? (
    <div className={classes.ICIMSRolesDropDownWrapper}>
      <div className={classes.ICIMSRolesDropDown} ref={wrapperRef}>
        <div className={classes.ICIMSLoadingItem}>
          No matches found for <span style={{ fontWeight: 500, color: '#242424', fontFamily: 'Roboto' }}>{searchDebounced}</span> on{' '}
          <span style={{ fontWeight: 500, color: '#242424', fontFamily: 'Roboto' }}>SAP SuccessFactors</span>
        </div>
      </div>
    </div>
  ) : show && isLoading ? (
    <div className={classes.ICIMSRolesDropDownWrapper}>
      <div className={classes.ICIMSRolesDropDown} ref={wrapperRef}>
        <div className={classes.ICIMSLoadingItem}>
          <Loader style={{ verticalAlign: 'middle', marginRight: 5 }} color="gray" size="xs" /> Loading...
        </div>
      </div>
    </div>
  ) : null;
};

export default LinkableICIMSRolesDropDown;

LinkableICIMSRolesDropDown.propTypes = {
  show: PropTypes.bool.isRequired,
  linkableICIMSRoleSelected: PropTypes.func.isRequired,
  lostFocus: PropTypes.func,
};
