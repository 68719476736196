import React from 'react';
import { createStyles, Group, Stack, Text } from '@mantine/core';
import IntegrationIcon from '../../../../components/IntegrationIcon/IntegrationIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  roleName: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSize: '16px',
    color: '#242424',
  },
  locationIcon: {
    height: '17px',
    paddingBottom: '4px',
    color: '#838485'
  }
}));

interface TableRoleNameCellProps {
  name: string;
  location: string;
  greenHouseJob: {
    requisition_id: number;
  } | null;
  leverJob: {
    requisition_ids: number;
  } | null;
    sapJob: {
        requisition_id: number;
    } | null;
}

const RoleNameCell = ({ name, location, greenHouseJob, leverJob, sapJob }: TableRoleNameCellProps) => {
  const { classes } = useStyles();

  const greenHouseIcon = greenHouseJob ? (
    <IntegrationIcon type="greenhouse" reqId={greenHouseJob.requisition_id} />
  ) : null;

  const leverIcon = leverJob ? (
    <IntegrationIcon type="lever" reqId={leverJob?.requisition_ids.toString().replace(/[[\]"\\]/g, '')} />
  ) : null;

    const sapIcon = sapJob ? (
        <IntegrationIcon type="sap" reqId={sapJob?.requisition_id.toString().replace(/[[\]"\\]/g, '')} />
    ) : null;

  return (
    <Stack spacing={0} px={5}>
      <Text className={classes.roleName} lineClamp={1}>
        {name} {greenHouseIcon} {leverIcon} {sapIcon}
      </Text>
      {location && (
        <Group noWrap spacing={4}>
          <Group sx={{ width: 20 }} spacing={0}>
            <FontAwesomeIcon icon={faLocationDot} className={classes.locationIcon} />
          </Group>
          <Text color="#838485" size="md" lineClamp={1} sx={{ wordBreak: 'break-all' }}>
            {location}
          </Text>
        </Group>
      )}
    </Stack>
  );
};

export default RoleNameCell;
