import React, { useMemo } from 'react';
import { createStyles, Group, Text, Transition } from '@mantine/core';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    container: {
        justifyContent: 'space-between',
    },
    text: {
        fontSize: 16,
    },
    link: {
        color: '#242424',
        textDecorationSkipInk: 'none'
    },
}));

const FreeTrialAlert = () => {
    const { classes } = useStyles();
    const { data } = useGetCompanyQuery();
    const navigate = useNavigate();
    
    const isFreeTrialActive = useMemo(() => {
        return Boolean(data?.customer_pricing_plan?.free_trial_ends_at && moment(data?.customer_pricing_plan?.free_trial_ends_at).isSameOrAfter(moment(), 'day'));
    }, [data]);

    const trialDaysLeft = useMemo(() => {
        return moment(data?.customer_pricing_plan?.free_trial_ends_at).diff(moment(), 'days');
    }, [data]);

    const handleUpgrade = () => {
        window.open(process.env.REACT_APP_SHOPIFY_SELECT_PLAN_URL + "/pages/select-rolebot-plan", '_blank');
    }

    return (
        <Transition transition={'slide-down'} mounted={true}>
            {(styles) => (
                <div
                    style={{
                        ...styles,
                        height: 35,
                        backgroundColor: '#F4DCFF',
                    }}
                >
                    <Group sx={{ justifyContent: 'center', height: '100%' }} align={'center'} px={10} spacing={0} noWrap>
                        {isFreeTrialActive ? (
                            <Text align={'center'} color={'#242424'} className={classes.text}>
                                You have {trialDaysLeft} days left in your trial. Keep discovering top talent or {' '}
                                <span onClick={handleUpgrade} style={{ cursor: 'pointer', color: "#7039ED" }}>
                                    upgrade now
                                </span> for full access.
                            </Text>
                        ) : (
                            <Text align={'center'} color={'#242424'} className={classes.text}>
                                Your free trial has ended! Select a plan to continue discovering to talent with Rolebot. {' '}
                                <span onClick={handleUpgrade} style={{ cursor: 'pointer', color: "#7039ED" }}>
                                    Choose your plan here
                                </span>
                            </Text>
                        )}
                    </Group>
                </div>
            )}
        </Transition>
    );
};

export default FreeTrialAlert;