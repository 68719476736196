import { Grid, Group, Loader, Stack, Text, createStyles, TextInput, ScrollArea } from '@mantine/core'
import Searchbar from '../Searchbar/Searchbar'
import { useMemo, useState } from 'react';
import { IRole, sortOption } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkSlash, faLocationDot } from '@fortawesome/pro-light-svg-icons';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { buildSortPayload } from 'utils';
import { useGetATSRolesQuery, useUnlinkSapJobMutation } from 'app/services/rolebot';
import { toastSuccess } from 'utils/toastify-messages';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { selectATSRole, selectATSRoleName } from 'features/app/appSlice';
import RolesNotFound from 'components/AlertMessages/RolesNotFound';
import RolebotBadge from 'components/RolebotBadge';

const useStyles = createStyles((theme) => ({
    
  title: {
      marginLeft: 'auto',
      lineHeight: 1.3,
      textAlign: 'center',
      fontSize: theme.fontSizes.lg,
      color: '#242424',
      fontFamily: 'Roboto',
      fontWeight: 500,
      [theme.fn.largerThan('sm')]: {
          textAlign: 'left',
          marginLeft: '0',
          fontSize: 19,
      },
  },

  subtitle: {
      marginLeft: 'auto',
      lineHeight: 1.3,
      textAlign: 'center',
      fontSize: theme.fontSizes.lg,
      color: '#838485',
      fontFamily: 'Helvetica',
      fontWeight: 400,
      overflowWrap: 'break-word',
      [theme.fn.largerThan('sm')]: {
          textAlign: 'left',
          marginLeft: '0',
          fontSize: 16,
      },
  },
  columnHeader: {
    fontWeight: 400,
    fontSize: 12,
    color: '#B3B3B3'
},
roleNameText: {
    color: '#242424',
},
locationIcon: {
    height: '15px',
    width: '13px',
    color: '#838485'
},

}));

const getFilterType = (filters: {active: boolean, completed: boolean}) => {
    if (filters.active === filters.completed) return 'all'
    if (filters.active) return 'active'
    if (filters.completed) return 'completed'
}

type LinkedProps = {
  roleId?: number | null,
  roleName? : string | null
}

export const Linked = (props: LinkedProps) => {
    const { classes } = useStyles();

    const [filters, setFilters] = useState({
        active: false,
        completed: false
    })
    const [sort, setSort] = useState<sortOption>('default')
    const [searchTerm, setSearchTerm] = useState(props.roleName ?? '')
    const [debounced] = useDebouncedValue(searchTerm, 1000);
    const sortPayload = useMemo(() => buildSortPayload(sort), [sort]);

    const getLocationText = (role: IRole) => {
        let locationTag = ( (role?.info?.offices) && (role?.info?.offices.length > 0)) ? role?.info?.offices[0].location_name : ''
        let locationCount = ( (role?.info?.offices) && (role?.info?.offices.length > 1)) ? ('+'+(role?.info?.offices.length-1)) : ''
        
        return locationTag + ' ' + locationCount
    }

    const { combinedData: allRoles, extraData, isLoading } = useInfiniteScroll(useGetATSRolesQuery, {
        type: getFilterType(filters),
        search: debounced,
        ats: 'sap',
        ...(props.roleId ? {'job-id': props.roleId} : {}),
        ...sortPayload
    });

    const dispatch = useAppDispatch();

    useDidUpdate(() => {
      dispatch(
        selectATSRole(null)
      );
  
      dispatch(
        selectATSRoleName(null)
      );
    }, [debounced])

    return (
      <>

        <div style={{display: 'flex', flexDirection: 'column', paddingTop: 20, maxHeight: '100%', height: '100%'}}>
            <Stack>
              <Text className={classes.title}>Linked SAP jobs</Text>
              <Text className={classes.subtitle}>Below is the list of Rolebot roles that have been linked to a SAP role.</Text>
              <Searchbar
                  onTextChange={setSearchTerm}
                  onFiltersChange={setFilters}
                  onSortChange={setSort} searchTerm={searchTerm} filters={filters} sort={sort}/>
            </Stack>

            <ScrollArea type='auto' offsetScrollbars style={{minHeight: 0, flexGrow: 1}}>
                    { (extraData.total === 0 && !isLoading) && (
                      <Stack mt={80}>
                        <RolesNotFound />
                      </Stack>
                    )}

                    {(extraData.total > 0 && allRoles.length > 0 && !isLoading) && (
                      <>
                        <Grid style={{paddingTop: 5, paddingBottom: 5}}>
                          <Grid.Col xs={6}>
                              <Text className={classes.columnHeader}>ROLEBOT ROLE</Text>
                          </Grid.Col>
                          <Grid.Col xs={6}>
                              <Text className={classes.columnHeader}>SAP ROLE</Text>
                          </Grid.Col>
                        </Grid>
                        {allRoles.map((role: IRole) => (
                          <>
                            <LinkedRole
                                role={role}
                                reqId={
                                    //@ts-ignore
                                    role?.sap_job?.requisition_id
                                }
                            />
                          </>
                        ))}
                      </>
                    )}

            </ScrollArea>

        </div>

        </>
    )
}

const LinkedRole = ({ role, reqId }: { role: IRole; reqId: string | number }) => {
  const [unlink, { isLoading }] = useUnlinkSapJobMutation();
  const { classes } = useStyles();

  const handleUnlink = async () => {
    try {
      await unlink(role.id).unwrap();
      toastSuccess(
        <div className="toastContainer">
          <div>Your role was successfully unlinked</div>
          <div></div>
        </div>
      );
    } catch (e) {
      console.log('Error unlinking');
    }
  };

  if (!Boolean(role.sap_job)) return null;
  return (
    <Grid key={role.id}>
      <Grid.Col xs={6}>
        <Stack spacing={0}>
        <Group>
            <Text className={classes.roleNameText}>{role.name}</Text>
            { role.completed && <RolebotBadge
              variant={'filled'}
              color={'#46209E'}
              backgroundColor={'#EEEEEE'}
            >
              Completed
            </RolebotBadge>}
          </Group>
          <Text color={'#838485'}>{role?.info?.office_locations}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col xs={6}>
        <Group>
          {/*@ts-ignore*/}
          <TextInput placeholder="Select a role..." value={`${role.sap_job.name} ${reqId}`} sx={{ flex: 1 }} disabled />
          {isLoading ? (
            <Loader size={'sm'} color={'violet'} />
          ) : (
            <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faLinkSlash} onClick={handleUnlink} />
          )}
        </Group>
      </Grid.Col>
    </Grid>
  );
};