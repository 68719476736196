import { useEffect, useReducer } from 'react';
import { Button } from '@mantine/core';
import Stepper from './components/Stepper';
import { ContextModalProps, useModals } from '@mantine/modals';
import VideoDetail from './components/VideoDetail';
import { useGetCompanyQuery, useGetUserQuery, usePatchCompanyUserMutation } from 'app/services/rolebot';
import { useMediaQuery } from '@mantine/hooks';
import { VideoTutorialProps } from 'types/modals';

const initialState: any = {
  step: 1,
  lastStep: 3,
};

const reducer = (state: typeof initialState, action: any) => {
  switch (action.type) {
    case 'nextStep':
      if (state.step === state.lastStep) return { step: 1 };
      return {
        ...state,
        step: state.step + 1,
      };

    case 'setStep':
      return {
        ...state,
        step: action.payload,
      };

    case 'backStep':
      if (state.step === 1) return { step: 1 };
      return {
        ...state,
        step: state.step - 1,
      };

    case 'setLastStep':
      return {
        ...state,
        lastStep: action.payload,
      };

    default:
      return state;
  }
};

const VideoTutorial = ({ id, context, innerProps }: ContextModalProps<VideoTutorialProps>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: userData } = useGetUserQuery();
  const [changeUserType] = usePatchCompanyUserMutation();
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const modals = useModals();
  const { data: company } = useGetCompanyQuery();
  const { onClose } = innerProps

  const handleSkip = async () => {
    context.closeModal(id);
    
    if(onClose){
      setTimeout(() => {onClose()}, 200)
    }
      
    await changeUserType({
      userId: userData?.user?.id || 0,
      payload: {
        show_welcome_modal: false,
      },
    });
  };

  const handleNextStep = () => {
    if (state.step < state.lastStep) {
      return dispatch({ type: 'nextStep' });
    }
    return handleSkip();
  };

  const handleChangeView = (step: number) => {
    // dispatch({ type: 'setStep', payload: step });
  };

  const handleBackStep = () => {
    dispatch({ type: 'backStep' });
  };

  useEffect(() => {
    if (company?.role_type?.name === 'Sourcing Only') {
      dispatch({ type: 'setLastStep', payload: 2 });
    }
  }, [company]);

  return (
    <>
      {state.step === 1 && (
        <VideoDetail
          imageUrl={'https://s3.us-west-2.amazonaws.com/rolebot.io/static/Filling+in+Role+Info.gif'}
          title={'Getting started'}
          paragraph="Launch the roles where you wish to look for candidates."
          footer="Then, point Rolebot to your ideal candidates, e.g. 2 people that already work at your companny, and provide
        us with some information abbout the hiring team and the role."
          handleClose={handleSkip}
        />
      )}

      {state.step === 2 && (
        <VideoDetail
          imageUrl={'https://s3.us-west-2.amazonaws.com/rolebot.io/static/Reviewing%20Candidates%20fv.gif'}
          title={'Start reviewing candidates'}
          paragraph="Let Rolebot do its magic and surface a lookalike talent pool. Review the candidates presented and 'thumbs
            up' the ones you’d be interested in."
          footer=" Once you are done reviewing a batch of candidates, Rolebot will surface a new one on the next business day.
            "
          handleClose={handleSkip}
        />
      )}

      {company?.role_type?.name !== 'Sourcing Only' && state.step === 3 && (
        <VideoDetail
          imageUrl={'https://s3.us-west-2.amazonaws.com/rolebot.io/static/Confirmed+Interviews+fv.gif'}
          title={'Interview your candidates'}
          paragraph="Sit back and relax while Rolebot engages with the talent you're interested in."
          footer="Rolebot will coordinate calls for you to speak with candidates who want to hear more about the opportunities at your company! Happy Interviewing!"
          handleClose={handleSkip}
        />
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '40px 18px 20px 24px',
        }}
      >
        {state.step !== 1 ? (
          <Button
            variant={'subtle'}
            disabled={false}
            loading={false}
            onClick={handleBackStep}
            type={'submit'}
            style={isDesktop ? { padding: '0 4vw' } : {}}
          >
            {state.step === 1 ? '' : 'Back'}
          </Button>
        ) : (
          <Button
            disabled={true}
            variant={'subtle'}
            loading={false}
            type={'submit'}
            style={isDesktop ? { padding: '0 4vw' } : {}}
          >
            {}
          </Button>
        )}

        <Stepper step={state.step} handleChangeView={handleChangeView} />
        <Button
          type={'submit'}
          disabled={false}
          loading={false}
          loaderPosition={'right'}
          onClick={handleNextStep}
          style={isDesktop ? { padding: '0 4vw' } : {}}
        >
          {state.step === state.lastStep ? 'Get started!' : 'Next'}
        </Button>
      </div>
    </>
  );
};

export default VideoTutorial;
