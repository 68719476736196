import React from 'react';
import {createStyles, Group, Paper, Stack, Text} from '@mantine/core';
import {useMediaQuery} from "@mantine/hooks";

const useStyles = createStyles((theme, { isMobile }: { isMobile: boolean; }) => ({
    footer: {
        width: '100%',
        backgroundColor: '#F7F5F5',
        borderBottom: '1px solid #242424',
        padding: isMobile ? '21px 0 41px 0' : '41px 0 41px 0',
    },
    footerGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const FooterLinks = (props: { isMobile: boolean; }) => {
    return (
        <>
            <Text<'a'>
                component={'a'}
                color={'#838485'}
                lineClamp={1}
                href={'https://www.rolebot.io/contact'}
                target={'_blank'}
            >Questions? Contact us</Text>
            <Text<'a'>
                component={'a'}
                color={'#838485'}
                lineClamp={1}
                href={'https://www.rolebot.io/faq'}
                target={'_blank'}
                mx={!props.isMobile ? 50 : 'inherit'}
            >FAQ</Text>
            <Text<'a'>
                component={'a'}
                color={'#838485'}
                lineClamp={1}
                href={'https://www.rolebot.io/employers'}
                target={'_blank'}
            >Learn more about Rolebot</Text>
        </>
    );
}

const RolebotFooter = () => {
    const isMobile = useMediaQuery('(max-width: 532px)');
    const { classes } = useStyles({isMobile});

    return (
        <footer className={classes.footer}>
            {isMobile ?
                <Stack className={classes.footerGroup}>
                    <FooterLinks isMobile={isMobile} />
                </Stack> :
                <Group className={classes.footerGroup} position={'center'} spacing={'xl'}>
                    <FooterLinks isMobile={isMobile} />
                </Group>
            }
        </footer>
    );
}

export default RolebotFooter;
