import { Alert, Button, Grid, Group, ScrollArea, Stack, Text, TextInput, createStyles } from '@mantine/core'
import Searchbar from '../Searchbar/Searchbar'
import { useMemo, useState } from 'react';
import { useGetATSRolesQuery, useLinkICIMSJobMutation } from 'app/services/rolebot';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { buildSortPayload } from 'utils';
import { IRole, sortOption } from 'types';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import LinkableICIMSRolesDropdown from 'components/Modals/LaunchRole/Components/LinkableICIMSRolesDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import _ from 'lodash';
import { toastSuccess } from 'utils/toastify-messages';
import React from 'react';
import RolesNotFound from 'components/AlertMessages/RolesNotFound';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { selectATSRole, selectATSRoleName } from 'features/app/appSlice';
import NoRolesToLink from 'components/AlertMessages/NoRolesToLink';
import RolebotBadge from 'components/RolebotBadge';


const useStyles = createStyles((theme) => ({
    
    title: {
        marginLeft: 'auto',
        lineHeight: 1.3,
        textAlign: 'center',
        fontSize: theme.fontSizes.lg,
        color: '#242424',
        fontFamily: 'Roboto',
        fontWeight: 500,
        [theme.fn.largerThan('sm')]: {
            textAlign: 'left',
            marginLeft: '0',
            fontSize: 19,
        },
    },

    subtitle: {
        marginLeft: 'auto',
        lineHeight: 1.3,
        textAlign: 'center',
        fontSize: theme.fontSizes.lg,
        color: '#838485',
        fontFamily: 'Helvetica',
        fontWeight: 400,
        overflowWrap: 'break-word',
        [theme.fn.largerThan('sm')]: {
            textAlign: 'left',
            marginLeft: '0',
            fontSize: 16,
        },
    },

    content: {
        display: 'flex',
        flexGrow: 1
    },

    roleNameText: {
        color: '#242424',
    },
    locationIcon: {
        height: '15px',
        width: '13px',
        color: '#838485'
    },
    columnHeader: {
        fontWeight: 400,
        fontSize: 12,
        color: '#B3B3B3'
    }

}));

const getFilterType = (filters: {active: boolean, completed: boolean}) => {
    if (filters.active === filters.completed) return 'all'
    if (filters.active) return 'active'
    if (filters.completed) return 'completed'
}

const hasRolesToLink = (roles : IRole[], total : number) => {

  if ( total === 0 ) return false
  let filtered = roles.filter((role:IRole) => !Boolean(role.icims_job))
  return filtered.length > 0
}

type ConnectProps = {
  roleId?: number | null,
  roleName? : string | null
}

export const Connect = (props: ConnectProps) => {
    const { classes } = useStyles();
    const [filters, setFilters] = useState({
        active: false,
        completed: false
    })
    const [sort, setSort] = useState<sortOption>('default')
    const [searchTerm, setSearchTerm] = useState(props.roleName ?? '')
    const [debounced] = useDebouncedValue(searchTerm, 1000, {leading: true});
    const sortPayload = useMemo(() => buildSortPayload(sort), [sort]);
    
    const { combinedData: allRoles, isLoading, isFetching, extraData } = useInfiniteScroll(useGetATSRolesQuery, {
        type: getFilterType(filters),
        search: debounced,
        ...(props.roleId ? {'job-id': props.roleId} : {}),
        ...sortPayload
    });

    const hasRolesForLinking = useMemo( ()=> hasRolesToLink(allRoles, extraData.total), [allRoles, extraData] )

    const dispatch = useAppDispatch();

    useDidUpdate(() => {
      dispatch(
        selectATSRole(null)
      );
  
      dispatch(
        selectATSRoleName(null)
      );
    }, [debounced])

    const getLocationText = (role: IRole) => {
        let locationTag = ( (role?.info?.offices) && (role?.info?.offices.length > 0)) ? role?.info?.offices[0].location_name : ''
        let locationCount = ( (role?.info?.offices) && (role?.info?.offices.length > 1)) ? ('+'+(role?.info?.offices.length-1)) : ''
        
        return locationTag + ' ' + locationCount
    }

    const hasFilters = Boolean(debounced || sort !== 'default' || filters.active || filters.completed)

    const [linkableICIMSRolesInputFields, setLinkableICIMSRolesInputFields] = useState({});
    const [links, setLinks] = useState({});
    const [showICIMSRoles, setShowICIMSRoles] = useState(false);
    const [localRolebotRoleId, setLocalRolebotRoleId] = useState(0);
    const [selectedLinkableICIMSRoles, setSelectedLinkableICIMSRoles] = useState<any>([]);
    const [hasDuplicates, setHasDuplicates] = useState(false);
    const [someFailed, setSomeFailed] = useState(false);
    const [rejectsError, setRejectsError] = useState<any>([]);
    const [linkRole, { isLoading: isLinking }] = useLinkICIMSJobMutation();
    const [, setRepeatedFields] = useState([]);

    const handleLinkableICIMSRolesInputFieldsChanged = (e: any, roleId: number) => {
        setLinkableICIMSRolesInputFields((previousValue) => {
          return { ...previousValue, [roleId]: e };
        });
        if (e === '') {
          const newLinks = { ...links };
          // @ts-ignore
          delete newLinks[roleId];
          setLinks(newLinks);
        }
      };

      const handleICIMSRolesFocus = (roleId = 0) => {
        setShowICIMSRoles(true);
        setLocalRolebotRoleId(roleId);
      };

      const handleCloseAllDropdowns = () => {
        setLinkableICIMSRolesInputFields((previousValue) => {
          // @ts-ignore
          if (previousValue[localRolebotRoleId] !== selectedLinkableICIMSRoles[localRolebotRoleId]?.icims_job_name) {
            const newLinks = { ...links };
            // @ts-ignore
            delete newLinks[localRolebotRoleId];
            setLinks(newLinks);
          }
    
          // @ts-ignore
          return {
            ...previousValue,
            [localRolebotRoleId]:
            //@ts-ignore
              previousValue[localRolebotRoleId] === selectedLinkableICIMSRoles[localRolebotRoleId]?.icims_job_name
                ? //@ts-ignore
                  previousValue[localRolebotRoleId]
                : '',
          };
        });
        setShowICIMSRoles(false);
      };

      const onAdd = (roleId: number, atsId: number, portalId: number) => {
        const newLinks = { ...links };
        //@ts-ignore
        const item = newLinks[roleId];
        if (item) {
          const wasSameItemClicked = item.roleId === roleId;
          if (wasSameItemClicked) {
            //@ts-ignore
            delete newLinks[roleId];
          }
        } else {
          //@ts-ignore
          newLinks[roleId] = {
            roleId,
            atsId,
            portalId,
          };
        }
        setLinks(newLinks);
      };

      const handleLinkableICIMSRoleSelected = (role: any) => {
        setSelectedLinkableICIMSRoles((previousValue: any) => {
          return { ...previousValue, [localRolebotRoleId]: role };
        });
        setLinkableICIMSRolesInputFields((previousValue) => {
          return { ...previousValue, [localRolebotRoleId]: role.icims_job_name };
        });
        onAdd(localRolebotRoleId, role.job_id, role.icims_portal.id);
    
        setShowICIMSRoles(false);
      };

      const handleMakeConnection = async () => {
        setSomeFailed(false);
        setRepeatedFields([]);
        setHasDuplicates(false);
        setRejectsError([]);
        // look if there is no repeated data first.
        const atsRolesIds: any[] = [];
    
        _.mapValues(links, (v) => {
          //@ts-ignore
          atsRolesIds.push(v.atsId);
        });
        // do nothing if we have no data.
        if (atsRolesIds.length === 0) return;
    
        // return early if there are duplicates.
        const uniqueIds = [...new Set(atsRolesIds)];
        let duplicates = [...atsRolesIds];
        uniqueIds.forEach((item) => {
          const i = duplicates.indexOf(item);
          duplicates = duplicates.slice(0, i).concat(duplicates.slice(i + 1, duplicates.length));
        });
        if (duplicates.length > 0) {
          setHasDuplicates(true);
          return;
        }
    
        // get params for each request we are going to make.
        const connectionParams: { roleId: number; atsId: number; portalId: number }[] = []; // has objects of type: {roleId, atsId}
        _.mapValues(links, (v) => {
          console.log('mapValues', v);
          console.log('mapValues', links);
          //@ts-ignore
          connectionParams.push({ roleId: v.roleId, atsId: v.atsId, portalId: v.portalId });
        });
    
        try {
          const results = await Promise.allSettled(
            connectionParams.map(({ roleId, atsId, portalId }) =>
              linkRole({
                roleId,
                payload: {
                  icims_job_id: atsId,
                  icims_portal_id: portalId,
                  on_hold: 0,
                },
              }).unwrap()
            )
          );
          const fulfilled = results.filter((p) => p.status === 'fulfilled');
          const rejects = results.filter((p) => p.status === 'rejected');
    
          if (rejects.length > 0) {
            setRejectsError(rejects);
          }
          //@ts-ignore
          const rejectedIds = rejects.map((r) => r.reason.meta.roleId);
          //@ts-ignore
          setRepeatedFields(rejectedIds);
          if (fulfilled.length > 0 && rejects.length > 0) {
            setSomeFailed(true);
            return;
          }
          if (fulfilled.length === 0 && rejects.length > 0) {
            throw new Error();
          }
          toastSuccess(
            <div className="toastContainer">
              <div>Mission accomplished!</div>
              <div>
              Your roles were linked to ICIMS.
              </div>
            </div>
          );
        } catch (e) {
          setLinks({});
          console.log(e);
        }
        setSearchTerm('')
        dispatch(
          selectATSRole(null)
        );
        dispatch(
          selectATSRoleName(null)
        );
      };
    

    return (
        <>

        <div style={{display: 'flex', flexDirection: 'column', paddingTop: 20, maxHeight: '100%', height: '100%'}}>
            <Stack>
              <Text className={classes.title}>Connect ICIMS jobs to Rolebot roles</Text>
              <Text className={classes.subtitle}>Connecting roles will allow any user with a ICIMS account to export Rolebot candidates to those jobs on ICIMS.</Text>
              <Searchbar
                  onTextChange={setSearchTerm}
                  onFiltersChange={setFilters}
                  onSortChange={setSort} searchTerm={searchTerm} filters={filters} sort={sort}/>
            </Stack>
            
            <ScrollArea type='auto' offsetScrollbars style={{minHeight: 0, flexGrow: 1}}>

              {(extraData.total > 0 && allRoles.length > 0 && !isLoading) && (
                    <>
                    <Grid style={{paddingTop: 5, paddingBottom: 5}}>
                    <Grid.Col xs={6}>
                        <Text className={classes.columnHeader}>ROLEBOT ROLE</Text>
                    </Grid.Col>
                    <Grid.Col xs={6}>
                        <Text className={classes.columnHeader}>ICIMS ROLE</Text>
                    </Grid.Col>
                    </Grid>
                    {allRoles.map((role: IRole) => {
                      if (Boolean(role.icims_job)) return null;
                      return (
                        <Grid key={role.id} style={{paddingTop: 5, paddingBottom: 5}}>
                            <Grid.Col xs={6}>
                                <Stack spacing={0}>
                                    <Group>
                                        <Text className={classes.roleNameText}>{role.name}</Text>
                                        { role.completed && <RolebotBadge
                                          variant={'filled'}
                                          color={'#46209E'}
                                          backgroundColor={'#EEEEEE'}
                                        >
                                          Completed
                                        </RolebotBadge>}
                                      </Group>
                                    {((role?.info?.offices) && (role?.info?.offices.length > 0)) && <Group spacing={10} position='left'>
                                        <FontAwesomeIcon icon={faLocationDot} className={classes.locationIcon} />
                                        <Text color="#838485" size="md" lineClamp={1} sx={{ wordBreak: 'break-all'}}>{getLocationText(role)}</Text>
                                    </Group> }
                                </Stack>
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Group grow>
                                <div>
                                  <TextInput
                                    placeholder={'Type to search for a ICIMS role...'}
                                    size={'md'}
                                    //@ts-ignore
                                    value={linkableICIMSRolesInputFields[role.id] || ''}
                                    onChange={(event) =>
                                      handleLinkableICIMSRolesInputFieldsChanged(event.currentTarget.value, role.id)
                                    }
                                    onFocus={() => handleICIMSRolesFocus(role.id)}
                                  />
                                  <LinkableICIMSRolesDropdown
                                    closeAllDropdowns={handleCloseAllDropdowns}
                                    linkableICIMSRoleSelected={handleLinkableICIMSRoleSelected}
                                    show={showICIMSRoles && role.id === localRolebotRoleId}
                                    //@ts-ignore
                                    needle={linkableICIMSRolesInputFields[role.id] || ''}
                                  />
                                </div>
                              </Group>
                            </Grid.Col>
                        </Grid>
                      );
                    })}
                    </>
              )}

              {(!hasRolesForLinking && hasFilters) && !(isLoading || isFetching) && (
                <Stack mt={80} mb={20}>
                  <RolesNotFound />
                </Stack>
              )}

              {(!hasRolesForLinking && !hasFilters) && !(isLoading || isFetching) && (
                <Stack mt={80} mb={20}>
                  <NoRolesToLink />
                </Stack>
              )}
            </ScrollArea>

            <div>
              {(extraData.total > 0 && hasRolesForLinking  && allRoles.length > 0 && !isLoading) &&<Group position="right">
              {hasDuplicates && <Alert color={'red'}>You can only connect one Rolebot role to an ICIMS Job</Alert>}
                {someFailed && <Alert color={'yellow'}>We were not able to make some connections</Alert>}
                {rejectsError.length > 0 && <Alert color={'yellow'}>{rejectsError[0]?.reason?.data?.message}</Alert>}
                  <Button ml={'auto'} mt={20} mb={20} sx={{ display: 'block' }} onClick={handleMakeConnection} loading={isLinking}>
                      Save Changes
                  </Button>
              </Group>}
            </div>

        </div>

        </>
    )
}

