import React from 'react';
import { Button, createStyles, Stack, Text, Grid } from '@mantine/core';

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
    // backgroundColor: 'lightcoral',
  },
  yourCalendarText: {
    color: '#242424',
    fontFamily: 'Helvetica',
    fontWeight: 400,
    fontSize: '16px',
  },
  calendarName: {
    fontFamily: 'Helvetica',
    fontSize: '16px',
    fontWeight: 400,
    color: '#b3b3b3',
  },
  greenDot: {
    display: 'inline-block',
    height: '11px',
    width: '11px',
    borderRadius: '50%',
    backgroundColor: '#38aa2c',
    marginRight: '10px',
  },
  button: {
    width: '100% !important', //shoot me
  },
  outer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

interface CalendarSelectedProps {
  calendar: any;
  handleDisconnectCalendar: () => void;
}
const CalendarSelectedSection = ({ calendar, handleDisconnectCalendar }: CalendarSelectedProps) => {
  const { classes } = useStyles();
  return (
    <Stack spacing={10} mt={30} className={classes.wrapper}>
      <Grid>
        <Grid.Col py={0}>
          <Text className={classes.yourCalendarText}>Your calendar</Text>
        </Grid.Col>
        <Grid.Col sm={8} lg={9}>
          <Text className={classes.calendarName}>
            {calendar?.provider_name} ({calendar?.calendar_name})
          </Text>
          <Text color={'#4F4F4F'}>
            <span className={classes.greenDot} />
            Calendar Synced
          </Text>
        </Grid.Col>
        <Grid.Col sm={4} lg={3} className={classes.outer}>
          <Button classNames={{ root: classes.button }} variant={'outline'} onClick={handleDisconnectCalendar}>
            Disconnect
          </Button>
        </Grid.Col>
      </Grid>
    </Stack>
  );
};

export default CalendarSelectedSection;
