import { Image, Stack, Text, Title } from '@mantine/core';
import DizzyRolebot from 'assets/img/robotina.png';

const NoRolesToLink = () => {

  return (
    <Stack align={'center'} spacing={0}>
      <Image src={DizzyRolebot} width={190} mb={20} />
      <Title order={3} sx={{ color: '#242424' }} mb={20}>
        Your roles are up to date
      </Title>
      <Text color={'#242424'}>All of your roles are up to date. You can review them in the 'View linked roles' section.</Text>
        
    </Stack>
  );
};

export default NoRolesToLink;
