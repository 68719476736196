import React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  SapRoleItem: {
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '8px',
    color: '#242424',
    padding: '2px 10px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      borderRadius: '5px',
    },
    '&:last-child': {
      marginBottom: '0',
    },
  },
  UnavailableSapRoleItem: {
    cursor: 'not-allowed',
    backgroundColor: '#EEEEEE',
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '8px',
    color: '#838485',
    padding: '4px 10px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
}));

const LinkableSapRoleItem = ({ linkableSapRoleSelected, role }) => {
  const { classes } = useStyles();

  return (
    <>
      {role.is_available && (
        <div className={classes.SapRoleItem} onClick={() => linkableSapRoleSelected(role)}>
          {role.sap_job_name}{' '}
          {role.requisition_id && (
            <span style={{ color: '#959595', marginLeft: 10, fontSize: '12px' }}>
              {'Req ID ' + role.requisition_id}
            </span>
          )}
        </div>
      )}
      {!role.is_available && (
        <div className={classes.UnavailableSapRoleItem}>
          {role.sap_job_name}{' '}
          {role.requisition_id && (
            <span style={{ color: '#838485', marginLeft: 10, fontSize: '12px' }}>
              {'Req ID ' + role.requisition_id}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default LinkableSapRoleItem;

LinkableSapRoleItem.propTypes = {
  role: PropTypes.object.isRequired,
  linkableSapRoleSelected: PropTypes.func.isRequired,
};

LinkableSapRoleItem.defaultProps = {};
