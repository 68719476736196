import React from 'react';
import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps, useModals } from '@mantine/modals';
import { Button, Group, Text, Stack, createStyles } from '@mantine/core';
import { PricingPlanUpdatedProps } from '../../../types/modals';
import { Link, useNavigate } from 'react-router-dom';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { useGetCompanyQuery } from 'app/services/rolebot';

const useStyles = createStyles((theme, _params, getRef) => ({
  itemWrapper: {
    position: 'relative',
  },
  leftLine: {
    borderLeft: 'solid #C4C4C4 1px',
    position: 'absolute',
    zIndex: 0,
    top: '25px',
    left: 9,
    bottom: '25px',
  },
  inlineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '20px 0',
    color: '#242424',
  },
  message: {
    color: '#242424',
  },
}));

const PricingPlanUpdated = ({ id, innerProps, context }: ContextModalProps<PricingPlanUpdatedProps>) => {
  const { classes } = useStyles();
  const { roles, onClose } = innerProps;
  const navigate = useNavigate();
  const modals = useModals()
  const { data } = useGetCompanyQuery();

  const handleContactSupport = () => {
    modals.closeAll()
    modals.openContextModal('contactSupport', {
      title: '',
      innerProps: {},
      closeOnClickOutside: false,
      styles: {
        modal: { width: '602px', padding: '20px !important' },
      },
    });
  }

  const handleBillingDetails = () => {
    modals.closeAll()
    navigate('/settings/billing')
  }
  return (
    <Stack>
      <TitleWithClose id={id} title={'Plan updated successfully!'} onClose={onClose} />
      <>
          <Text className={classes.message}>
          We’re happy to inform you that your company’s upgrade request has been processed. Your new Rolebot plan is now active, with a role limit of {roles} active roles.
          </Text>
          <Text className={classes.message}>For more information on your updated plan and billing details, <span style={{color: '#0077C3', cursor: 'pointer'}} onClick={handleBillingDetails}>click here</span>.</Text>

          <Group position="right">
            <RolebotButton onClick={handleContactSupport} type="neutral">
              Contact Support
            </RolebotButton>
            <RolebotButton onClick={onClose} type="primary">Got it</RolebotButton>
          </Group>
        </>
    </Stack>
  );
};

export default PricingPlanUpdated;
