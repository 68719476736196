import React from 'react';
import PropTypes from 'prop-types';
import {Stack, Text, createStyles} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    LeverRoleItem: {
        cursor: 'pointer',
        fontSize: '16px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'unset',
        marginBottom: '8px',
        color: '#242424',
        padding: '2px 10px',
        '&:hover': {
            backgroundColor: '#EEEEEE',
            borderRadius: '5px'
        },
        '&:last-child': {
            marginBottom: '0'
        }
    },
    UnavailableLeverRoleItem: {
        cursor: 'not-allowed',
        // backgroundColor: '#EEEEEE',
        fontSize: '16px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'unset',
        marginBottom: '8px',
        color: '#838485',
        padding: '4px 10px',
        '&:last-child': {
            marginBottom: '0'
        }
    }
}));

const LinkableLeverRoleItem = ({linkableLeverRoleSelected, role}) => {
    const { classes } = useStyles();

    return (
        <>
            {role.is_available &&
                <div
                    className={classes.LeverRoleItem}
                    onClick={() => linkableLeverRoleSelected(role)}
                >
                    <Stack spacing={0}>
                        <Text>
                            {role.lever_job_name} {role.requisition_ids?.length > 0 && <span style={{color: "#959595", marginLeft: 10, fontSize: '12px'}}>{"Req IDs " + role.requisition_ids}</span>}
                        </Text>
                        <Text color={'#838485'}>{role.location}</Text>
                    </Stack>
                </div>
            }
            {!role.is_available &&
                <div
                    className={classes.UnavailableLeverRoleItem}
                >
                    {role.lever_job_name} {role.requisition_ids?.length > 0 && <span style={{color: "#838485", marginLeft: 10, fontSize: '12px'}}>{"Req IDs " + role.requisition_ids}</span>}
                </div>
            }
        </>
    )
};

export default LinkableLeverRoleItem;

LinkableLeverRoleItem.propTypes = {
    role: PropTypes.object.isRequired,
    linkableLeverRoleSelected: PropTypes.func.isRequired
};

LinkableLeverRoleItem.defaultProps = {};
