import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Global } from '@mantine/core';
import './styles/palette.module.css';

ReactDOM.render(
  <React.StrictMode>
    <Global
      styles={{
        'input[type="text"]': {
          '::placeholder': {
            color: '#B3B3B3',
          },
        },
        'input[type="password"]': {
          '::placeholder': {
            color: '#B3B3B3',
          },
        },
      }}
    />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
