import React from 'react';
import {createStyles, Group, Text} from '@mantine/core';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

const useStyles = createStyles((theme, { isSelectedMenuItem, iconWidth }: any) => ({
    RegularStepMenuItem: {
        ':hover': {
            cursor: "pointer",
            backgroundColor: isSelectedMenuItem ? "inherit " : "#EEEEEE !important"
        },
    },

    RegularStepMenuItemIconDiv: {
        display: "flex",
        justifyContent: "center",
        paddingRight: "10px",
        paddingLeft: "10px",
        paddingBottom: "2px",
        width: iconWidth,
        color: isSelectedMenuItem ? "#24154F" : "#242424"
    }
}))

type RegularStepMenuItemProps = {
  onClick: (step: number) => void;
  step: number;
  menuItem: { text: string; step: number; icon: IconProp; iconWidth: string };
  isDesktop: boolean;
};

const RegularStepMenuItem: React.FC<RegularStepMenuItemProps> = ({ onClick, step, menuItem, isDesktop }) => {

  const { classes } = useStyles({isSelectedMenuItem: step === menuItem.step, iconWidth: menuItem.iconWidth});


  return (
      <Group noWrap
        className={classes.RegularStepMenuItem}
        style={{
        width: '100%',
        padding: 6,
        backgroundColor: step === menuItem.step ? '#8241FD2E' : 'inherit',
        borderRadius: 5,
        gap: 10,
      }} onClick={() => onClick ? onClick(menuItem.step) : null}>
        <div className={classes.RegularStepMenuItemIconDiv}>
            <FontAwesomeIcon icon={menuItem.icon} />
        </div>
        <Text color={step === menuItem.step ? '#24154F' : '#242424'}
              style={{ flex: '1 1 auto', whiteSpace: 'nowrap' }}>{menuItem.text}</Text>
      </Group>
  );
};

export default RegularStepMenuItem;
