import React, { useState, useEffect } from 'react';
import {
    Button,
    createStyles,
    Group,
    Image,
    Text,
    Title,
    TextInput,
    Stack
} from '@mantine/core';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import TextLink from 'components/TextLink/TextLink';
import { ChevronLeft } from 'tabler-icons-react';
import {  toastSuccess, toastError } from 'utils/toastify-messages';
import { usePostConctactSalesMutation } from 'app/services/rolebot';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const useStyles = createStyles((theme) => ({
    button: {
        marginBottom: '20px',
        [theme.fn.largerThan('sm')]: {
            marginLeft: 'auto',
        },
    },
    member: {
        marginBottom: 24,
        [theme.fn.largerThan('md')]: {
            marginInline: 44,
        },
    },
    backButton: {
        display: 'none',
        [theme.fn.largerThan('md')]: {
          display: 'flex',
        },
    },
}));

const ContactSalesSchema = z.object({
    full_name: z.string()
                .min(2, 'Must be at least 2 characters long')
                .max(50, 'Must be less than 50 characters long')
                .nonempty('Name is required'),
    job_title: z.string()
                .min(1, 'Must be at least 1 characters long')
                .max(50, 'Must be less than 50 characters long')
                .nonempty('Job title is required'),
    work_email: z.string()
                .email('Please enter a valid email address')
                .nonempty('Email is required'),
    company_name: z.string()
                    .min(1, 'Must be at least 1 character long')
                    .max(50, 'Must be less than 50 characters long')
                    .nonempty('Company is required'),
    phone_number: z.string().optional(),
});

interface ContactSalesProps {
    handleBack: () => void;
}

const ContactSales = ({ handleBack } : ContactSalesProps) => {
    const { classes } = useStyles();
    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        reset,
        watch
    } = useForm({
        defaultValues: (() => {
            const savedFormData = sessionStorage.getItem('contactSalesFormData');
            return savedFormData ? JSON.parse(savedFormData) : {
                full_name: '',
                job_title: '',
                work_email: '',
                company_name: '',
                phone_number: '',
            };
        })(),
        resolver: zodResolver(ContactSalesSchema),
        mode: 'all',
    });

    useEffect(() => {
        const subscription = watch((data, { name, type }) => {
            sessionStorage.setItem('contactSalesFormData', JSON.stringify(data));
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const [postContactSales, { isLoading: isLoading, isSuccess, isError }] = usePostConctactSalesMutation();

    const handleCreateAccount = async (payload: any) => {
        await postContactSales({ payload })
            .unwrap()
            .then((data) => {
                toastSuccess(
                    <div className="toastContainer">
                        <div>Success!</div>
                        <div>A member of our team will contact you for further steps.</div>
                    </div>
                );
                reset();
                sessionStorage.removeItem('contactSalesFormData'); // Clear saved form data
                handleBack();
            })
            .catch((error) => {
                console.log(error);
                toastError(
                    <div className="toastContainer">
                        <div>Oops!</div>
                        <div>Something went wrong. Please try again later.</div>
                    </div>
                );
            });
    }

    return (
        <>
            <NavLink to={'/'}>
                <Image src={LogoLetters} width={180} fit={'contain'} mb={30} />
            </NavLink>
            <TextLink
                mr={'auto'}
                onClick={handleBack}
                spacing={0}
                icon={<ChevronLeft size={18} />}
                className={classes.backButton}
            >
                Back
            </TextLink>
            <Title order={3} mb={20}>
                Chat with our team
            </Title>
            <Stack spacing={'xs'}>
                <form onSubmit={handleSubmit(handleCreateAccount)} id="create-account-form">
                    <TextInput
                        size={'md'}
                        label="Full name*"
                        placeholder="Jane Doe"
                        autoComplete="full_name"
                        {...register('full_name')}
                        error={errors?.full_name?.message}
                        tabIndex={1}
                        autoCapitalize={'false'}
                        mb={16}
                    />
                    <TextInput
                        size={'md'}
                        label="Job title*"
                        placeholder="Product Manager"
                        autoComplete="job_title"
                        {...register('job_title')}
                        error={errors?.job_title?.message}
                        tabIndex={1}
                        autoCapitalize={'false'}
                        mb={16}
                    />
                    <TextInput
                        size={'md'}
                        label="Work Email*"
                        placeholder="jane@acme.com"
                        autoComplete="work_email"
                        {...register('work_email')}
                        error={errors?.work_email?.message}
                        tabIndex={1}
                        autoCapitalize={'false'}
                        mb={16}
                    />
                    <TextInput
                        size={'md'}
                        label="Company name*"
                        placeholder="ACME"
                        autoComplete="company_name"
                        {...register('company_name')}
                        error={errors?.company_name?.message}
                        tabIndex={1}
                        autoCapitalize={'false'}
                        mb={16}
                    />
                    <TextInput
                        size={'md'}
                        label="Phone number"
                        placeholder="(310) 987 6543"
                        autoComplete="phone_number"
                        {...register('phone_number')}
                        error={errors?.phone_number?.message}
                        tabIndex={1}
                        autoCapitalize={'false'}
                        mb={16}
                    />
                </form>
                <RolebotButton
                    type={'primary'}
                    loading={isLoading}
                    loaderPosition={'right'}
                    form="create-account-form"
                    disabled={!isValid}
                    onClick={handleSubmit(handleCreateAccount)}
                    className={classes.button}
                >
                    Get in touch
                </RolebotButton>
                <Group align={'center'} position={'center'} spacing={4} className={classes.member}>
                    <Text>Have an account?</Text>
                    <Text component={Link} to={'/'} color={'#7039ED'}>
                        Sign in
                    </Text>
                </Group>
            </Stack>
        </>
    );
};

export default ContactSales;
