import React from 'react';
import { Alert, Button, Container, createStyles, Group, Image, Stack, Text, TextInput, Title } from '@mantine/core';
import RolebotCrying from 'assets/img/password_reset/trouble-loggin-in.png';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import { Link, NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useResetPasswordMutation } from 'app/services/rolebot';
import { mapErrors } from 'utils';

const useStyles = createStyles((theme) => ({
  image: {
    marginTop: 30,
    marginLeft: 16,
    marginBottom: 30,
    [theme.fn.largerThan('md')]: {
      marginLeft: 50,
      marginBottom: 60,
      marginTop: 40,
    },
  },

  container: {
    marginTop: 30,
    [theme.fn.largerThan('md')]: {
      marginLeft: 'auto',
    },
  },

  bottomText: {
    marginTop: 34,
  },
}));

const ForgotPasswordFormSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
});

type ForgotPasswordPayload = z.infer<typeof ForgotPasswordFormSchema>;

const ForgotPassword = () => {
  const { classes } = useStyles();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(ForgotPasswordFormSchema),
  });

  let [resetPassword, { isSuccess, isError, error, isLoading, reset }] = useResetPasswordMutation();

  const onSubmit = async (data: ForgotPasswordPayload) => {
    reset();
    await resetPassword(data);
  };

  return (
    <div style={{ paddingTop: '1px' }}>
      <NavLink to={'/'}>
        <Image src={LogoLetters} width={120} fit={'contain'} className={classes.image} />
      </NavLink>
      <Container size={600} className={classes.container}>
        <Stack align={'center'} spacing={0}>
          <Image src={RolebotCrying} width={95} fit={'contain'} mb={35} />
          <Title order={3} mb={20} color='#242424'>
            Trouble Logging In?
          </Title>
          <Text mb={20} color={'#838485'} align={'center'} size={'md'}>
            Enter the email address you used when you joined and we’ll send you a link to get back into your account.
          </Text>
        </Stack>
        <Stack>
          <form onSubmit={handleSubmit(onSubmit)} id="sign-in-form">
            <TextInput
              size={'md'}
              label="Email"
              placeholder="example@email.com"
              autoComplete="email"
              {...register('email')}
              error={
                errors?.email?.message ||
                (isError &&
                  (mapErrors(error)[0] || 'Something went wrong. Please contact support if this keeps happening.'))
              }
            />
            <Button
              mt={40}
              //ml={'auto'}
              style={{width: '100%'}}
              type="submit"
              fullWidth
              size={'md'}
              loading={isLoading}
              loaderPosition={'right'}
              disabled={!isValid}
            >
              Send Login Link
            </Button>
          </form>
          {isSuccess && <Alert color={'green'}>We have e-mailed your password reset link!</Alert>}
          <Group spacing={4} position={'center'} className={classes.bottomText}>
            <Text color='#242424'>Remembered your password?</Text>
            <Text component={Link} to={'/'} color={'violet'}>
              {' '}
              Back to Login
            </Text>
          </Group>
        </Stack>
      </Container>
    </div>
  );
};
export default ForgotPassword;
