import React from 'react';
import { Avatar, AvatarsGroup, createStyles, Group, Stack, Tooltip } from '@mantine/core';
import { getNameInitials } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
interface styleProps {
  id: number;
}

const useStyles = createStyles((theme, { id }: styleProps) => ({
  avatarRoot: {
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.45)',
  },
  avatarPlaceholder: {
    border: '2px solid transparent',
    color: theme.colors.avatars[id % theme.colors.avatars.length],
    svg: {
      fill: theme.colors.avatars[id % theme.colors.avatars.length],
    },
  },
  avatarPlaceHolderIcon: {
    color: theme.colors.avatars[id % theme.colors.avatars.length],
  },
  numberAvatarRoot: {
    width: 40,
    height: 40,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.45)',
  },
  numberAvatarPlaceholder: {
    border: '2px solid transparent',
    backgroundColor: '#4F4F4F',
    color: '#fff',
  },
}));

const UsersCell = ({ users, id }: { users: { name: string; id: number; email: string }[]; id: number }) => {
  const { classes } = useStyles({ id: users[0]?.id });

  const usersList = (
    <Stack spacing={4}>
      {users.map((user) => (
        <span key={user.id.toString()}>{user.email}</span>
      ))}
    </Stack>
  );

  const avatarStyles = {
    root: classes.avatarRoot,
    placeholder: classes.avatarPlaceholder,
    placeholderIcon: classes.avatarPlaceHolderIcon,
  };

  const numberAvatarStyles = {
    root: classes.numberAvatarRoot,
    placeholder: classes.numberAvatarPlaceholder,
  };

  const buildFirstCircleContent = () => {
    return users[0] ? (
      <Avatar classNames={avatarStyles}>
        {users[0]?.name ? getNameInitials(users[0].name) : <FontAwesomeIcon icon={faUser} width={16} height={16} style={{ paddingBottom: '2px' }}/>}
      </Avatar>
    ) : null;
  };

  const buildSecondCircleContent = () => {
    let secondAvatarContent = null;
    if (users.length > 1) {
      secondAvatarContent =
        users.length > 2 ? (
          <Avatar classNames={numberAvatarStyles}>+{users.length - 1}</Avatar>
        ) : (
          <Avatar classNames={avatarStyles}>
            {users[1]?.name ? getNameInitials(users[1].name) : <FontAwesomeIcon icon={faUser} width={16} height={16} style={{ paddingBottom: '2px' }} />}
          </Avatar>
        );
    }
    return secondAvatarContent;
  };

  return (
    <Group noWrap position={'center'}>
      <Tooltip label={usersList} key={'users-list-tooltip' + id}>
        <AvatarsGroup limit={1} styles={{ child: { border: 'none' } }}>
          {buildFirstCircleContent()}
          {buildSecondCircleContent()}
        </AvatarsGroup>
      </Tooltip>
    </Group>
  );
};

export default UsersCell;
