import { useState, useEffect, useRef, useCallback, FormEvent } from 'react';
import { Text, Textarea, Group, Button, Alert, ScrollArea } from '@mantine/core';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { useNotifications } from '@mantine/notifications';
import ImproveResultRow from 'components/ImproveResults/ImproveResultRow';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { useGetFeedbackByRoleQuery, usePostRoleFeedbackMutation } from 'app/services/rolebot';

interface FeedbackTabProps {
    roleId: number | string;
}

const FeedbackTab = ({ roleId }: FeedbackTabProps) => {
    let notifications = useNotifications();
    const [feedback, setFeedback] = useState('');
    const [isSeeMore, setIsSeeMore] = useState(false);
    const observer: any = useRef();

    const {
        combinedData,
        readMore,
        isLoading: loading,
        isFetching,
        refetch,
        refresh,
    } = useInfiniteScroll(useGetFeedbackByRoleQuery, {
        perPage: 3,
        roleId,
    });

    const comments = isSeeMore ? combinedData : combinedData.slice(0, 3);

    const lastFeedbackElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    readMore();
                }
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line
        [loading, isFetching]
    );

    //const [patchRoleInfo, { isLoading, isSuccess, isError }] = usePatchRoleInfoMutation();
    const [postRoleFeedback, { isLoading, isSuccess, isError }] = usePostRoleFeedbackMutation();

    const handlePatchRoleInfo = async () => {
        let payload: Record<string, string | string[]> = { feedback: feedback };
        refresh();

        await postRoleFeedback({ roleId: roleId, payload });
        setFeedback('');
    };

    const handleSeeMore = () => setIsSeeMore(true);

    useEffect(() => {
        if (isSuccess) {
            notifications.showNotification({
                title: 'Done',
                color: 'green',
                message: 'Feedback updated successfully!',
                autoClose: 3000,
            });

            refetch();
        }
    }, [isSuccess]);

    return (
        <>
            <div style={{ paddingLeft: '2px' }}>
                <Text
                    style={{
                        paddingTop: '10px',
                        color: '#838485',
                        fontSize: '14px',
                        lineHeight: '18px'
                    }}
                >
                    Your feedback will be analyzed by AI to update the search criteria. Please note that candidate locations will not be considered and should be added in the location section.
                </Text>
                <Textarea
                    placeholder="e.g. Needs to have a background in..."
                    value={feedback}
                    maxLength={250}
                    onChange={(e) => setFeedback(e.target.value)}
                    styles={{
                        root: {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                        label: { flex: 1, fontSize: '16px', paddingTop: '10px' },
                    }}
                    label="Provide feedback"
                    autosize
                    minRows={1}
                />
                {isError && (
                    <Alert title={'Oops, something went wrong!'} color={'orange'}>
                        We were not able to add information for this role.
                    </Alert>
                )}
                <Group position={'right'} mt={10} mb={30}>
                    <Button
                        px={25}
                        onClick={(e: FormEvent) => {
                            e.preventDefault()
                            handlePatchRoleInfo()
                        }}
                        loading={feedback ? isLoading : false}
                        loaderPosition={'right'}
                        disabled={!feedback}
                    >
                        Submit
                    </Button>
                </Group>
                <ScrollArea offsetScrollbars scrollbarSize={5} style={{ height: '380px' }}>
                    {comments.length > 0 && (
                        <div style={!isSeeMore ? { height: '33%', overflow: 'hidden' } : { marginBottom: '1rem' }}>
                            {comments?.map((e: any, id: number) => {
                                if (comments.length === id + 1) {
                                    return (
                                        <div key={id} ref={lastFeedbackElementRef}>
                                            <ImproveResultRow roleData={e} />
                                        </div>
                                    );
                                } else {
                                    return <ImproveResultRow key={id} roleData={e} />;
                                }
                            })}
                        </div>
                    )}
                    {!isSeeMore && comments.length > 2 && (
                        <div>
                            <RolebotButton type="neutral" onClick={handleSeeMore} style={{ width: '100%' }}>
                                {'Load more'}{' '}
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    style={{
                                        marginLeft: '10px',
                                    }}
                                />
                            </RolebotButton>
                        </div>
                    )}
                </ScrollArea>


            </div>
        </>
    );
};

export default FeedbackTab;