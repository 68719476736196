import {
  ActionIcon,
  Box,
  Container,
  createStyles,
  Loader,
  LoadingOverlay,
  Popover,
  Stack,
  Text,
  Group,
  Badge,
  CloseButton
} from '@mantine/core';
import { useForm } from 'react-hook-form';
import FormNavigation from 'components/Modals/RoleInfo/FormNavigation';
import React, { useEffect, useRef, useState } from 'react';
import { useLazyGetUserCreatedRoleSkillsQuery } from "../../../app/services/rolebot";
import SearchCriteriaSkills from "./SearchCriteriaInputFields/SearchCriteriaSkills";
import SearchCriteriaJobTitles from "./SearchCriteriaInputFields/SearchCriteriaJobTitles";
import { faCircleExclamation, faTriangleExclamation, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchCriteriaLanguages from "./SearchCriteriaInputFields/SearchCriteriaLanguages";
import SearchCriteriaGenderDiversity from "./SearchCriteriaInputFields/SearchCriteriaGenderDiversity";
import SearchCriteriaCandidateExperience from "./SearchCriteriaInputFields/SearchCriteriaCandidateExperience";
import SearchCriteriaCertificates from "./SearchCriteriaInputFields/SearchCriteriaCertificates";
import SearchCriteriaIndustryExperience from "./SearchCriteriaInputFields/SearchCriteriaIndustryExperience";
import SearchCriteriaKeywords from "./SearchCriteriaInputFields/SearchCriteriaKeywords";
import Echo from 'utils/echo';
import RoverThinking from "../../../assets/img/rover-thinking.gif";
import SearchCriteriaCompanies from "./SearchCriteriaInputFields/SearchCriteriaCompanies";
import SearchCriteriaSkillsExcluded from './SearchCriteriaInputFields/SearchCriteriaSkillsExcluded';
import SearchCriteriaJobTitlesExcluded from './SearchCriteriaInputFields/SearchCriteriaJobTitlesExcluded';
import { faWandSparkles } from '@fortawesome/pro-light-svg-icons';
import { usePostRemoveSuggestionMutation } from 'app/services/rolebot';

const useStyles = createStyles((theme, { selectedMenuItems }: { selectedMenuItems: number[]; }) => ({
  MustHaveItem: {
    flexWrap: 'nowrap',
    padding: '3px 10px',
    backgroundColor: '#CF4CFF1F',
    borderRadius: '2px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSize: '20px',
    color: '#242424',
  },

  Icon: {
    color: '#b3b3b3',
    position: 'relative',
    top: '-1px',
    cursor: 'pointer',
  },

  'Icon:hover': {
    color: '#838485',
  },

  RoleVariableDiv: {
    marginBottom: 15,
  },

  RoleAdditionalVariableDiv: {
    display: "flex",
    marginBottom: 15,
  },

  SuggestedAiRoles: {
    marginTop: 20,
    marginBottom: 20
  },

  AddVariablesWrapper: {
    position: 'relative'
  },

  AddVariableButton: {
    '&:hover': {
      cursor: selectedMenuItems.length < 7 ? 'pointer' : 'not-allowed',
    }
  },

  AddExclusionButton: {
    '&:hover': {
      cursor: 'pointer',
    }
  },

  VariableBox: {
    width: 260,
    borderRadius: 6,
    background: "var(--white, #FFF)",
    /* Not Selected */
    boxShadow: "0px 0px 6px 0px rgba(79, 79, 79, 0.45)",
    position: 'absolute',
    zIndex: 1000,
    top: 30,
    left: 5,
    right: 0,
    padding: '10px',
  },

  VariableBoxItem: {
    padding: '9px 10px',
    borderRadius: '5px',
    color: '#242424',
    '&:hover': {
      backgroundColor: '#F7F5F5',
      cursor: 'pointer'
    }
  },

  svg: {
    width: 16,
    zIndex: 9999,
    color: '#838485'
  },

  disabledTrashIcon: {
    width: 16,
    zIndex: 9999,
    color: 'rgba(131, 132, 133, 0.5)',
    cursor: 'auto'
  },

  image: {
    height: 200,
    width: 'auto%',
    marginLeft: '75px'
  },

  LoadingScreenTextDiv: {
    //marginRight: '6rem'
  },

  LoaderIcon: {
    margin: "1rem 0 1rem 4rem"
  },

  ButtonWrapper: {
    width: 36,
    height: 36,
    marginLeft: 10,
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#EEEEEE'
    },
  },

  disabledButtonWrapper: {
    width: 36,
    height: 36,
    marginLeft: 10,
    borderRadius: 20,
    cursor: 'auto'
  },

  fieldWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },

  variableWrapper: {
    width: '100%',
    paddingBottom: '10px'
  },

  scrollBox: {
    overflowY: 'scroll',
    maxHeight: '100%',
    [theme.fn.largerThan('md')]: {
      maxHeight: '84%',
      overflow: 'hidden',
    },
    [theme.fn.largerThan('lg')]: {
      maxHeight: '92%',
      overflow: 'hidden',
    },
  },
  xStyles: {
    color: '#B3B3B3',
    ':hover': {
      color: '#4F4F4F',
    },
    marginLeft: '-5px'
  }

}));

const SEARCH_CRITERIA_VARIABLES = [
  { id: 1, text: 'Nice to have skills' },
  { id: 2, text: 'Candidate Experience' },
  { id: 3, text: 'Languages' },
  { id: 4, text: 'Industry experience' },
  { id: 5, text: 'Certificates or specific degrees' },
  { id: 6, text: 'Keywords' },
  { id: 7, text: 'Target companies' },
  { id: 8, text: 'Exclude companies' },
  { id: 9, text: 'Prioritize gender diversity' }
];

interface SearchCriteriaStepProps {
  prev: (payload: object) => void;
  next: (payload: object) => void;
  save: (payload: object) => void;
  values: {
    skills: any;
    nice_to_have_skills: any;
    excluded_skills: any;
    job_titles: any;
    excluded_job_titles: any;
    languages: any;
    industries: any;
    keywords: any;
    certificates: any;
    companies: any;
    excluded_companies: any;
    preferred_experience_min: number;
    preferred_experience_max: number;
    prioritize_gender_diversity: boolean;
    suggestedSkills: any;
    suggestedJobTitles: any;
    suggestedIndustries: any;
    suggestedYearsOfExperience: any;

  };
  originalData: any;
  submit: (payload: object) => void;
  isFetching: boolean;
  isSubmitting: boolean;
  sourcing_only: boolean;
  showFinishLater: boolean;//this is showInfo
  roleId: number;
  roleName: string;
  hasSearchData: boolean;
  roleInfo: any;
  onUnmount: (payload: object) => void;
  skillsSuggestions: any;
  titlesSuggestions: any;
  industriesSuggestions: any;
  yearsOfExperienceSuggestions: any;
  addSkillToBlacklist: (payload: object) => void;
  addTitleToBlacklist: (payload: object) => void;
  removeSkillFromSuggestions: (payload: object) => void;
  removeTitleFromSuggestions: (payload: object) => void;
  removeIndustryFromSuggestions: (payload: object) => void;
  removeYearsOfExperienceFromSuggestions: (payload: object) => void;
  addIndustryToBlacklist: (payload: object) => void;
  addYearsOfExperienceToBlacklist: (payload: object) => void;
  isCandidateLocationEmpty: boolean;
}

const SearchCriteriaStep = ({ next, prev, save, values, originalData, isFetching, submit, isSubmitting, sourcing_only, showFinishLater, roleId, roleName, hasSearchData, roleInfo, onUnmount, skillsSuggestions, titlesSuggestions, addSkillToBlacklist, addTitleToBlacklist, removeSkillFromSuggestions, removeTitleFromSuggestions, industriesSuggestions, removeIndustryFromSuggestions, yearsOfExperienceSuggestions, removeYearsOfExperienceFromSuggestions, addIndustryToBlacklist, addYearsOfExperienceToBlacklist, isCandidateLocationEmpty }: SearchCriteriaStepProps) => {
  //const { data: {skills: aiSkills, titles: aiTitles}, refetch: refetchAiData, isFetching: aiDataFetching, isError: aiDataError } = useGetUserCreatedRoleSkillsQuery(roleId, { refetchOnMountOrArgChange: true });
  const [getAiData, { data: aiData, isSuccess: aiDataSuccess, isUninitialized: aiDataUninitialized }] = useLazyGetUserCreatedRoleSkillsQuery();
  const updatedValuesRef = useRef({ ...values });
  const [showLongSkillTagWarning, setShowLongSkillTagWarning] = useState(false);
  const [tagLengthWarning, setTagLengthWarning] = useState({
    skills: false,
    nice_to_have_skills: false,
    job_titles: false,
    keywords: false,
    certificates: false,
    companies: false,
    excluded_companies: false
  });
  const [showLongNthSkillTagWarning, setShowLongNthSkillTagWarning] = useState(false);
  const [showLongJobTitleTagWarning, setShowLongJobTitleTagWarning] = useState(false);
  const [formValuesHaveChanged, setFormValuesHaveChanged] = useState(false);
  const [sameSkillsIncludedAndExcluded, setSameSkillsIncludedAndExcluded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVariableBoxOpen, setVariableBoxOpen] = useState<boolean>(false);
  const [trashIconPopoverIsOpened, setTrashIconPopoverIsOpened] = useState<number>(0);
  const [excludedTrashIconPopoverIsOpened, setExcludedTrashIconPopoverIsOpened] = useState<string | null>(null);
  const [selectedMenuItems, setSelectedMenuItems] = useState<number[]>([]);
  const [showJobTitlesExclusions, setShowJobTitlesExclusions] = useState(false);
  const [showSkillsExclusions, setShowSkillsExclusions] = useState(false);
  const variableBoxRef = useRef(null);
  const addVariableButtonRef = useRef(null);
  const { classes } = useStyles({ selectedMenuItems });
  const [postRemoveSuggestion] = usePostRemoveSuggestionMutation();

  const {
    skills,
    nice_to_have_skills,
    excluded_skills,
    job_titles,
    excluded_job_titles,
    languages,
    industries,
    keywords,
    certificates,
    preferred_experience_min,
    preferred_experience_max,
    companies,
    excluded_companies,
    prioritize_gender_diversity,
    suggestedYearsOfExperience
  } = values;

  const [availableIndustries, setAvailableIndustries] = React.useState(industries?.length ? industries : []);
  const [selectedIndustries, setSelectedIndustries] = useState(industries.map((i: any) => i.id.toString()));

  const [industrySuggestions, setIndustrySuggestions] = React.useState(industriesSuggestions?.length ? industriesSuggestions : []);
  const [suggestionsYearsOfExperience, setSuggestionsYearsOfExperience] = React.useState(yearsOfExperienceSuggestions ? yearsOfExperienceSuggestions : null);

  const {
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      skills,
      nice_to_have_skills,
      excluded_skills,
      job_titles,
      excluded_job_titles,
      languages,
      industries,
      keywords,
      certificates,
      preferred_experience_min,
      preferred_experience_max,
      companies,
      excluded_companies,
      prioritize_gender_diversity
    },
  });

  const [skillsWatcher, nthSkillsWatcher, excludedSkillsWatcher, jobTitlesWatcher, excludedJobTitlesWatcher, languagesWatcher, industriesWatcher, keywordsWatcher,
    certificatesWatcher, minExpWatcher, maxExpWatcher, genderWatcher, companiesWatcher, excludedCompaniesWatcher] = watch([
      'skills',
      'nice_to_have_skills',
      'excluded_skills',
      'job_titles',
      'excluded_job_titles',
      'languages',
      'industries',
      'keywords',
      'certificates',
      'preferred_experience_min',
      'preferred_experience_max',
      'prioritize_gender_diversity',
      'companies',
      'excluded_companies'
    ]);

  const variableLimitReached = ((skillsWatcher.length) +
    (nthSkillsWatcher.length) +
    (jobTitlesWatcher.length) +
    (languagesWatcher.length) +
    (industriesWatcher.length) +
    (keywordsWatcher.length) +
    (certificatesWatcher.length) +
    (companiesWatcher.length) +
    (excludedCompaniesWatcher.length) +
    ((minExpWatcher || maxExpWatcher) ? 1 : 0) +
    (genderWatcher ? 1 : 0)) > 25;

  const mustHaveSkillsLimitReached = ((skillsWatcher.length)) > 6;

  const handleAddVariableClick = () => {
    setVariableBoxOpen(!isVariableBoxOpen);
  };

  useEffect(() => {
    updatedValuesRef.current = {
      skills: skillsWatcher,
      nice_to_have_skills: nthSkillsWatcher,
      excluded_skills: excludedSkillsWatcher,
      job_titles: jobTitlesWatcher,
      excluded_job_titles: excludedJobTitlesWatcher,
      languages: languagesWatcher,
      industries: industriesWatcher,
      keywords: keywordsWatcher,
      certificates: certificatesWatcher,
      preferred_experience_min: minExpWatcher,
      preferred_experience_max: maxExpWatcher,
      prioritize_gender_diversity: genderWatcher,
      companies: companiesWatcher,
      excluded_companies: excludedCompaniesWatcher,
      suggestedSkills: null,
      suggestedJobTitles: null,
      suggestedIndustries: null,
      suggestedYearsOfExperience: null
    };
  });

  // useEffect hook to handle component unmount-like behavior
  useEffect(() => {
    return () => {
      onUnmount(updatedValuesRef.current);
    };
  }, []);

  useEffect(() => {
    setFormValuesHaveChanged(
      (JSON.stringify(originalData?.skills?.filter((x: { pivot: { nice_to_have: any; is_excluded: any; }; }) => !x.pivot.nice_to_have && !x.pivot.is_excluded).map((x: { id: any; }) => x.id) || []) !== JSON.stringify(skillsWatcher.map((x: { id: any; }) => x.id))) ||
      (JSON.stringify(originalData?.skills?.filter((x: { pivot: { nice_to_have: any; }; }) => x.pivot.nice_to_have).map((x: { id: any; }) => x.id) || []) !== JSON.stringify(nthSkillsWatcher.map((x: { id: any; }) => x.id))) ||
      (JSON.stringify(originalData?.skills?.filter((x: { pivot: { is_excluded: any; }; }) => x.pivot.is_excluded).map((x: { id: any; }) => x.id) || []) !== JSON.stringify(excludedSkillsWatcher.map((x: { id: any; }) => x.id))) ||
      (JSON.stringify(originalData?.job_titles?.filter((x: { pivot: { is_excluded: any; }; }) => !x.pivot.is_excluded).map((x: { id: any; }) => x.id) || [])) !== JSON.stringify(jobTitlesWatcher.map((x: { id: any; }) => x.id)) ||
      (JSON.stringify(originalData?.job_titles?.filter((x: { pivot: { is_excluded: any; }; }) => x.pivot.is_excluded).map((x: { id: any; }) => x.id) || [])) !== JSON.stringify(excludedJobTitlesWatcher.map((x: { id: any; }) => x.id)) ||
      (JSON.stringify(originalData?.languages?.map((x: { id: any; }) => x.id) || [])) !== JSON.stringify(languagesWatcher.map((x: { id: any; }) => x.id)) ||
      (JSON.stringify(originalData?.industries?.map((x: { id: any; }) => x.id) || [])) !== JSON.stringify(industriesWatcher.map((x: { id: any; }) => x.id)) ||
      (JSON.stringify(originalData?.keywords?.map((x: { id: any; }) => x.id) || [])) !== JSON.stringify(keywordsWatcher.map((x: { id: any; }) => x.id)) ||
      (JSON.stringify(originalData?.certificates?.map((x: { id: any; }) => x.id) || [])) !== JSON.stringify(certificatesWatcher.map((x: { id: any; }) => x.id)) ||
      (JSON.stringify(originalData?.companies?.filter((x: { pivot: { is_excluded: any; }; }) => !x.pivot.is_excluded).map((x: { id: any; }) => x.id) || []) !== JSON.stringify(companiesWatcher.map((x: { id: any; }) => x.id))) ||
      (JSON.stringify(originalData?.companies?.filter((x: { pivot: { is_excluded: any; }; }) => x.pivot.is_excluded).map((x: { id: any; }) => x.id) || []) !== JSON.stringify(excludedCompaniesWatcher.map((x: { id: any; }) => x.id))) ||
      ((originalData?.info?.preferred_experience_min || 0) !== (minExpWatcher || 0)) ||
      ((originalData?.info?.preferred_experience_max || 0) !== (maxExpWatcher || 0)) ||
      ((originalData?.prioritize_gender_diversity || false) !== (genderWatcher || false)))
  }, [
    originalData?.skills,
    skillsWatcher,
    originalData?.nice_to_have_skills,
    nthSkillsWatcher,
    originalData?.job_titles,
    jobTitlesWatcher,
    originalData?.languages,
    languagesWatcher,
    originalData?.industries,
    industriesWatcher,
    originalData?.keywords,
    keywordsWatcher,
    originalData?.companies,
    companiesWatcher,
    originalData?.excluded_companies,
    excludedCompaniesWatcher,
    originalData?.certificates,
    certificatesWatcher,
    originalData?.info?.preferred_experience_min,
    minExpWatcher,
    originalData?.info?.preferred_experience_max,
    maxExpWatcher,
    originalData?.prioritize_gender_diversity,
    genderWatcher,
    excludedJobTitlesWatcher,
    excludedSkillsWatcher
  ]);

  useEffect(() => {
    const skillsSet = new Set(skillsWatcher.map((skill: { id: number }) => skill.id));
    const nthSkillsSet = new Set(nthSkillsWatcher.map((skill: { id: number }) => skill.id));
    const jobTitlesSet = new Set(jobTitlesWatcher.map((title: { id: number }) => title.id));

    const excludedSkillsSet = new Set(excludedSkillsWatcher.map((skill: { id: number }) => skill.id));
    const excludedJobTitlesSet = new Set(excludedJobTitlesWatcher.map((title: { id: number }) => title.id));

    const skillsIntersection = new Set([...excludedSkillsSet].filter(id => skillsSet.has(id) || nthSkillsSet.has(id)));
    const jobTitlesIntersection = new Set([...excludedJobTitlesSet].filter(id => jobTitlesSet.has(id)));

    setSameSkillsIncludedAndExcluded(skillsIntersection.size > 0 || jobTitlesIntersection.size > 0);
  }, [
    excludedSkillsWatcher,
    excludedJobTitlesWatcher,
    skillsWatcher,
    nthSkillsWatcher,
    jobTitlesWatcher
  ])

  const handleMenuItemClick = (itemId: number) => {
    setSelectedMenuItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {

        // Remove the item from the selectedItems array
        return prevSelectedItems.filter((item) => item !== itemId);
      } else {

        // Add the item to the selectedItems array
        return [...prevSelectedItems, itemId];
      }
    });

    setVariableBoxOpen(false)
  };

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (!hasSearchData && showFinishLater) {
      getAiData(roleId)
      values.industries = originalData?.default_industry
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!showFinishLater) return

    if (aiDataUninitialized) return

    if (!hasSearchData && !aiDataSuccess) {
      timer.current = setTimeout(() => {
        setIsLoading(false)
        Echo.leaveChannel(`ai-skills${roleId}`)
      }, 30000);

      if (process.env.REACT_APP_WEB_SOCKETS_DISABLED === 'false' || !process.env.REACT_APP_WEB_SOCKETS_DISABLED) {
        Echo.channel(`ai-skills${roleId}`).listen('AiSkillsReceivedEvent', (e: any) => {
          clearTimeout(timer.current!)
          getAiData(roleId)
        });
      }

      return () => {
        clearTimeout(timer.current!);
        Echo.leaveChannel(`ai-skills${roleId}`);
      }
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiData, aiDataSuccess, aiDataUninitialized])

  useEffect(() => {

    if ((nice_to_have_skills.length || aiData?.skills?.filter((x: any) => x.pivot.nice_to_have)?.length) &&
      !selectedMenuItems.includes(1)) {
      handleMenuItemClick(1);
    }

    if ((preferred_experience_min ||
      ((preferred_experience_min && preferred_experience_max) ||
        (!preferred_experience_min && preferred_experience_max))) &&
      !selectedMenuItems.includes(2)) {

      handleMenuItemClick(2);
    }

    if (languages.length && !selectedMenuItems.includes(3)) {
      handleMenuItemClick(3);
    }

    if (industries.length && !selectedMenuItems.includes(4)) {
      handleMenuItemClick(4);
    }

    if (certificates.length && !selectedMenuItems.includes(5)) {
      handleMenuItemClick(5);
    }

    if (keywords.length && !selectedMenuItems.includes(6)) {
      handleMenuItemClick(6);
    }

    if (companies.length && !selectedMenuItems.includes(7)) {
      handleMenuItemClick(7);
    }

    if (excluded_companies.length && !selectedMenuItems.includes(8)) {
      handleMenuItemClick(8);
    }

    if (prioritize_gender_diversity && !selectedMenuItems.includes(9)) {
      handleMenuItemClick(9);
    }

    if (excluded_skills.length) {
      setShowSkillsExclusions(true);
    }

    if (excluded_job_titles.length) {
      setShowJobTitlesExclusions(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, roleInfo, aiData])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        variableBoxRef.current &&
        // @ts-ignore
        !variableBoxRef.current.contains(event.target) &&
        addVariableButtonRef.current !== event.target
      ) {
        setVariableBoxOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const trashIconWithPopover = (menuItem: number, hasMoreThanOneValue: boolean) => (
    <div
      style={{ zIndex: 9999 }}
      onMouseEnter={() => hasMoreThanOneValue ? setTrashIconPopoverIsOpened(menuItem) : null}
      onMouseLeave={() => setTrashIconPopoverIsOpened(0)}
      onBlur={() => setTrashIconPopoverIsOpened(0)}
    >
      <Popover
        opened={trashIconPopoverIsOpened === menuItem}
        onClose={() => setTrashIconPopoverIsOpened(0)}
        onClick={() => hasMoreThanOneValue ? null : handleRemoveExtraSearchCriteriaItem(menuItem)}
        position="top"
        placement="center"
        withArrow={false}
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        width={300}
        spacing={5}
        styles={{
          body: {
            backgroundColor: 'black',
            color: 'white',
            borderColor: 'black'
          }
        }}
        target={
          <ActionIcon
            variant={'transparent'}
            size={20}
            className={hasMoreThanOneValue ? classes.disabledButtonWrapper : classes.ButtonWrapper}
          >
            <FontAwesomeIcon
              icon={faTrash} className={hasMoreThanOneValue ? classes.disabledTrashIcon : classes.svg}
            />
          </ActionIcon>
        }
      >
        <div>
          <Text size="md" style={{ marginBottom: 0, paddingLeft: '4px' }}>
            Cannot remove. Multiple items in field.
          </Text>
        </div>
      </Popover>
    </div>
  );

  const handleRemoveExcludedInputFromSearchCriteria = (item: string) => {
    if (item === "skills") {
      setShowSkillsExclusions(false);
      handleExcludedSkillsChange([]);
    }

    if (item === "job_titles") {
      setShowJobTitlesExclusions(false);
      handleExcludedJobTitlesChange([]);
    }
  };

  const excludedTrashIconWithPopover = (item: string, hasMoreThanOneValue: boolean) => (
    <div
      style={{ zIndex: 9999 }}
      onMouseEnter={() => hasMoreThanOneValue ? setExcludedTrashIconPopoverIsOpened(item) : null}
      onMouseLeave={() => setExcludedTrashIconPopoverIsOpened(null)}
      onBlur={() => setExcludedTrashIconPopoverIsOpened(null)}
    >
      <Popover
        opened={excludedTrashIconPopoverIsOpened === item}
        onClose={() => setExcludedTrashIconPopoverIsOpened(null)}
        onClick={() => hasMoreThanOneValue ? null : handleRemoveExcludedInputFromSearchCriteria(item)}
        position="top"
        placement="center"
        withArrow={false}
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        width={300}
        spacing={5}
        styles={{
          body: {
            backgroundColor: 'black',
            color: 'white',
            borderColor: 'black'
          }
        }}
        target={
          <ActionIcon
            variant={'transparent'}
            size={20}
            className={hasMoreThanOneValue ? classes.disabledButtonWrapper : classes.ButtonWrapper}
          >
            <FontAwesomeIcon
              icon={faTrash} className={hasMoreThanOneValue ? classes.disabledTrashIcon : classes.svg}
            />
          </ActionIcon>
        }
      >
        <div>
          <Text size="md" style={{ marginBottom: 0, paddingLeft: '4px' }}>
            Cannot remove. Multiple items in field.
          </Text>
        </div>
      </Popover>
    </div>
  );

  // helper function that determines if any of the added tags has more than 3 words in it
  const tagHasMoreThanFiveWords = (items: { split: (arg0: string) => { (): unknown; new(): any; length: number; }; }[]) => {
    return items.some((x: { split: (arg0: string) => { (): unknown; new(): any; length: number; }; }) => x.split(" ").length > 5)
  };

  const tagLengthIsTooLong = (items: { length: number; }[]) => {
    return items.some((x: { length: number; }) => x.length > 255)
  }

  const handleSkillsChange = (selectedValues: { name: string; }[]) => {

    if (selectedValues) {

      const isTooLong = tagLengthIsTooLong(selectedValues.map((x) => x.name));
      setTagLengthWarning((prevState) => ({
        ...prevState,
        skills: isTooLong,
      }));

      setShowLongSkillTagWarning(tagHasMoreThanFiveWords(selectedValues.map((x: { name: any; }) => x.name)));

      setValue('skills', selectedValues);
      trigger('skills');
    }
  };

  const handleNiceToHaveSkillsChange = (selectedValues: { name: string; }[]) => {

    if (selectedValues) {

      const isTooLong = tagLengthIsTooLong(selectedValues.map((x) => x.name));
      setTagLengthWarning((prevState) => ({
        ...prevState,
        nice_to_have_skills: isTooLong,
      }));

      setShowLongNthSkillTagWarning(tagHasMoreThanFiveWords(selectedValues.map((x: { name: any; }) => x.name)));

      setValue('nice_to_have_skills', selectedValues);
      trigger('nice_to_have_skills');
    }
  };

  const handleExcludedSkillsChange = (selectedValues: { name: string; }[]) => {
    if (selectedValues) {
      setValue('excluded_skills', selectedValues);
      trigger('excluded_skills');
    }
  };

  const handleJobTitlesChange = (selectedValues: { name: string; }[]) => {
    if (selectedValues) {

      const isTooLong = tagLengthIsTooLong(selectedValues.map((x) => x.name));
      setTagLengthWarning((prevState) => ({
        ...prevState,
        job_titles: isTooLong,
      }));

      setShowLongJobTitleTagWarning(tagHasMoreThanFiveWords(selectedValues.map((x: { name: any; }) => x.name)));

      setValue('job_titles', selectedValues);
      trigger('job_titles');
    }
  };

  const handleExcludedJobTitlesChange = (selectedValues: { name: string; }[]) => {
    if (selectedValues) {
      setValue('excluded_job_titles', selectedValues);
      trigger('excluded_job_titles');
    }
  };

  const handleLanguagesChange = (selectedValues: object) => {

    if (selectedValues) {
      setValue('languages', selectedValues);
      trigger('languages');
    }
  };

  const handleIndustriesChange = (selectedValues: object) => {

    if (selectedValues) {
      setValue('industries', selectedValues);
      trigger('industries');
    }
  };

  const handleKeywordsChange = (selectedValues: { name: string; }[]) => {

    if (selectedValues) {

      const isTooLong = tagLengthIsTooLong(selectedValues.map((x) => x.name));
      setTagLengthWarning((prevState) => ({
        ...prevState,
        keywords: isTooLong,
      }));

      setValue('keywords', selectedValues);
      trigger('keywords');
    }
  };

  const handleCertificatesChange = (selectedValues: { title: string; }[]) => {

    if (selectedValues) {

      const isTooLong = tagLengthIsTooLong(selectedValues.map((x) => x.title));
      setTagLengthWarning((prevState) => ({
        ...prevState,
        certificates: isTooLong,
      }));

      setValue('certificates', selectedValues);
      trigger('certificates');
    }
  };

  const handleCompaniesChange = (selectedValues: { name: string; }[]) => {
    if (selectedValues) {

      const isTooLong = tagLengthIsTooLong(selectedValues.map((x) => x.name));
      setTagLengthWarning((prevState) => ({
        ...prevState,
        companies: isTooLong,
      }));

      setValue('companies', selectedValues);
      trigger('companies');
    }
  };

  const handleExcludedCompaniesChange = (selectedValues: { name: string; }[]) => {
    if (selectedValues) {

      const isTooLong = tagLengthIsTooLong(selectedValues.map((x) => x.name));
      setTagLengthWarning((prevState) => ({
        ...prevState,
        excluded_companies: isTooLong,
      }));

      setValue('excluded_companies', selectedValues);
      trigger('excluded_companies');
    }
  };

  const handleExperienceChange = (selectedValues: object) => {
    if (selectedValues) {
      // @ts-ignore
      setValue('preferred_experience_min', selectedValues.preferred_experience_min);
      trigger('preferred_experience_min');
      // @ts-ignore
      setValue('preferred_experience_max', selectedValues.preferred_experience_max);
      trigger('preferred_experience_max');
    }
  };

  const handleDiversityChange = (selectedValue: boolean) => {

    setValue('prioritize_gender_diversity', selectedValue);
    trigger('prioritize_gender_diversity');
  };

  const longTagWarningLabel = (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      marginTop: '20px',
      borderRadius: 5,
      border: '1px solid #F6C333',
      background: 'rgba(246, 195, 51, 0.10)',
      padding: "7px 0 7px 9px"
    }}>
      <FontAwesomeIcon size='xl' icon={faTriangleExclamation} style={{ color: '#F6C333', marginRight: '5px' }} />
      <Text size={"md"} color={"#242424"} mb={3} pl={5} inline align='left'
        style={{ marginBottom: 'unset', fontWeight: 400, fontSize: 16, lineHeight: '20px' }}>
        Oops! Tags work best when brief and specific, like 'Java' or 'Problem Solving.'
        Full sentences may not yield accurate results.</Text>
    </div>
  );

  const handleAddIndustrySuggestion = (suggestion: any) => {

    const payload = {
      value: suggestion.industry_id.toString(),
      label: suggestion.industry_name,
      id: suggestion.industry_id,
      name: suggestion.industry_name,
      is_from_feedback: false

    }

    const newIndustrySuggestions = industrySuggestions.filter((item: any) => item.id !== suggestion.id);
    setIndustrySuggestions(newIndustrySuggestions);


    setAvailableIndustries((prevIndustries: any) => {
      const newIndustries = [...prevIndustries];
      const existingIndex = newIndustries.findIndex(suggestion => suggestion.id === payload.id);
      if (existingIndex === -1) {
        newIndustries.push(payload);
      }

      handleIndustriesChange(newIndustries);

      return newIndustries;
    });

    setSelectedIndustries((prevSelectedIndustries: string[]) => {
      const newSelected = [...prevSelectedIndustries];
      if (!newSelected.includes(payload.id.toString())) {
        newSelected.push(payload.id.toString());
      }

      return newSelected;

    });

    if (!industries.length && !selectedMenuItems.includes(4)) {
      handleMenuItemClick(4);
    }
  };

  const handleAddYearsOfExperienceSuggestion = (suggestion: any) => {

    handleExperienceChange(suggestion)
    handleMenuItemClick(2);
    setSuggestionsYearsOfExperience(null);

  }

  const handleRemoveIndustrySuggestion = (suggestion: any) => {

    const newIndustrySuggestions = industrySuggestions.filter((item: any) => item.id !== suggestion.id);
    setIndustrySuggestions(newIndustrySuggestions);

    const payload = {
      type: 'industries',
      industry_id: suggestion.industry_id
    };

    (async () => {
      try {
        await postRemoveSuggestion({
          roleId: suggestion.role_id,
          payload
        }).unwrap()
          // @ts-ignore
          .then((response) => {
          });
      } catch (e) {
        console.log('error', e)
      }
    })();
  }

  const handleRemoveYearsOfExperienceSuggestion = (suggestion: any) => {

    setSuggestionsYearsOfExperience(null);

    const payload = {
      type: 'years_of_experience',
      years_of_experience_id: null
    };

    (async () => {
      try {
        await postRemoveSuggestion({
          roleId: suggestion.role_id,
          payload
        }).unwrap()
          // @ts-ignore
          .then((response) => {
            console.log('response', response)
          });
      } catch (e) {
        console.log('error', e)
      }
    })();

  }

  // This is the components array. They will be shown based on which ones of them are selected by the user or
  // have data on SearchCriteriaStep component load
  const components = [
    {
      menuItem: 1,
      component:
        <div className={classes.variableWrapper}>
          <Text size={"md"} color={"#242424"} mb={3}>Their nice to have skills include</Text>
          <div className={classes.fieldWrapper}>
            <SearchCriteriaSkills
              onSkillsChange={handleNiceToHaveSkillsChange}
              skills={!hasSearchData ? (aiData?.skills.length ? aiData?.skills.filter((x: any) => x.pivot.nice_to_have) : nice_to_have_skills) : nice_to_have_skills}
              isNiceToHave={true}
              suggestedSkills={[]}
              addSkillToBlacklist={addSkillToBlacklist}
              removeSkillFromSuggestions={removeSkillFromSuggestions}
            />
            {trashIconWithPopover(1, nthSkillsWatcher.length > 1)}
          </div>
          {(showLongNthSkillTagWarning || tagLengthWarning.nice_to_have_skills) ? longTagWarningLabel : null}
        </div>
    },
    {
      menuItem: 2,
      component:
        <div className={classes.variableWrapper}>
          <Text size={"md"} color={"#242424"} mb={3}>Candidate experience</Text>
          <div className={classes.fieldWrapper}>
            <SearchCriteriaCandidateExperience
              onCandidateExperienceChange={handleExperienceChange}
              preferred_experience_min={values?.preferred_experience_min || roleInfo?.preferred_experience_min || yearsOfExperienceSuggestions?.preferred_experience_min}
              preferred_experience_max={values?.preferred_experience_max || roleInfo?.preferred_experience_max}
              suggestedYearsOfExperience={yearsOfExperienceSuggestions}
            />
            {trashIconWithPopover(2, false)}
          </div>
        </div>
    },
    {
      menuItem: 3,
      component:
        <div className={classes.variableWrapper}>
          <Text size={"md"} color={"#242424"} mb={3}>Languages</Text>
          <div className={classes.fieldWrapper}>
            <SearchCriteriaLanguages
              onLanguagesChange={handleLanguagesChange} languages={languages}
            />
            {trashIconWithPopover(3, languagesWatcher.length > 1)}
          </div>
        </div>
    },
    {
      menuItem: 4,
      component:
        <div className={classes.variableWrapper}>
          <Text size={"md"} color={"#242424"} mb={3}>Industry experience</Text>

          <div className={classes.fieldWrapper}>
            <SearchCriteriaIndustryExperience
              onIndustriesChange={handleIndustriesChange}
              industries={industries}
              removeIndustryFromSuggestions={removeIndustryFromSuggestions}
              suggestedIndustries={industrySuggestions.length > 0 ? industrySuggestions : []}
              handleAddIndustrySuggestion={handleAddIndustrySuggestion}
              industriesSet={availableIndustries}
              addIndustryToBlacklist={addIndustryToBlacklist}
            />
            {trashIconWithPopover(4, industriesWatcher.length > 1)}
          </div>
        </div>
    },
    {
      menuItem: 5,
      component:
        <div className={classes.variableWrapper}>
          <Text size={"md"} color={"#242424"} mb={3}>Certificates and specific degrees</Text>

          <div className={classes.fieldWrapper}>
            <SearchCriteriaCertificates
              onCertificatesChange={handleCertificatesChange} preloadedCertificates={certificates}
              onCertificatesCleared={() => handleRemoveExtraSearchCriteriaItem(5)}
            />
            {trashIconWithPopover(5, certificatesWatcher.length > 1)}
          </div>
          {tagLengthWarning.certificates ? longTagWarningLabel : null}
        </div>
    },
    {
      menuItem: 6,
      component:
        <div className={classes.variableWrapper}>
          <Text size={"md"} color={"#242424"} mb={3}>Keywords</Text>
          <div className={classes.fieldWrapper}>
            <SearchCriteriaKeywords
              onKeywordsChange={handleKeywordsChange} keywords={keywords} />
            {trashIconWithPopover(6, keywordsWatcher.length > 1)}
          </div>
          {tagLengthWarning.keywords ? longTagWarningLabel : null}
        </div>
    },
    {
      menuItem: 7,
      component:
        <div className={classes.variableWrapper}>
          <Text size={"md"} color={"#242424"} mb={3}>Target Companies</Text>
          <div className={classes.fieldWrapper}>
            <SearchCriteriaCompanies
              onCompaniesChange={handleCompaniesChange}
              companies={companies}
              isExcluded={false}
            />
            {trashIconWithPopover(7, keywordsWatcher.length > 1)}
          </div>
          {tagLengthWarning.companies ? longTagWarningLabel : null}
        </div>
    },
    {
      menuItem: 8,
      component:
        <div className={classes.variableWrapper}>
          <Text size={"md"} color={"#242424"} mb={3}>Exclude Companies</Text>
          <div className={classes.fieldWrapper}>
            <SearchCriteriaCompanies
              onCompaniesChange={handleExcludedCompaniesChange}
              companies={excluded_companies}
              isExcluded={true}
            />
            {trashIconWithPopover(8, keywordsWatcher.length > 1)}
          </div>
          {tagLengthWarning.excluded_companies ? longTagWarningLabel : null}
        </div>
    },
    {
      menuItem: 9,
      component:
        <div className={classes.variableWrapper}>
          <Text size={"md"} color={"#242424"} mb={3}>Gender Diversity</Text>

          <div className={classes.fieldWrapper}>
            <SearchCriteriaGenderDiversity
              onDiversityChange={handleDiversityChange} prioritize_gender_diversity={prioritize_gender_diversity}
            />
            {trashIconWithPopover(9, false)}
          </div>
        </div>
    }
  ];

  // When removing menu items, we should account for removing said values from the payload as well
  const handleRemoveValuesFromPayload = (itemId: number) => {
    switch (itemId) {
      case 1:
        handleNiceToHaveSkillsChange([]);
        break;
      case 2:
        handleExperienceChange({ preferred_experience_min: null, preferred_experience_max: null });
        break;
      case 3:
        handleLanguagesChange([]);
        break;
      case 4:
        handleIndustriesChange([]);
        break;
      case 5:
        handleCertificatesChange([]);
        break;
      case 6:
        handleKeywordsChange([]);
        break;
      case 7:
        handleCompaniesChange([]);
        break;
      case 8:
        handleExcludedCompaniesChange([]);
        break;
      case 9:
        handleDiversityChange(false);
        break;
    }
  };

  // sort out the menu items so depending on the selectedMenuItems, components are rendered in the right order
  const sortedComponents = components.filter(item => selectedMenuItems.includes(item.menuItem))
    .sort((a, b) => selectedMenuItems.indexOf(a.menuItem) - selectedMenuItems.indexOf(b.menuItem));

  const handleRemoveExtraSearchCriteriaItem = (itemId: number) => {

    const updatedSelectedMenuItems = selectedMenuItems.filter(
      (item) => item !== itemId
    );

    setSelectedMenuItems(updatedSelectedMenuItems);

    handleRemoveValuesFromPayload(itemId);
  };

  const displaySuggestionsBadge = () => {

    return (industrySuggestions?.length > 0 && originalData?.industries.length === 0 && !selectedMenuItems.includes(4)) || (suggestionsYearsOfExperience?.id && (!originalData?.info?.preferred_experience_min || !originalData?.info?.preferred_experience_max))
  }

  return (
    <Box>
      {isLoading &&
        <div>
          {showFinishLater &&
            <Text size={'md'} color={'#838485'} >
              Search Criteria
            </Text>
          }
          <div style={{ overflow: 'hidden', marginTop: '3rem' }}>
            <Stack align={'center'}>
              <>
                <img className={classes.image} src={RoverThinking} alt='Loading...' />
                <Stack align={'center'} className={classes.LoadingScreenTextDiv}>
                  <Container size={500}>
                    <Text align='center'>Letting AI work its magic...</Text>
                    <Loader color="violet" size="lg" className={classes.LoaderIcon} />
                  </Container>
                </Stack>
              </>
            </Stack>
          </div>
        </div>
      }
      {!isLoading &&
        <div style={{ paddingLeft: '2px' }}>
          {showFinishLater &&
            <Text size={'md'} color={'#838485'} mb={20}>
              Search Criteria
            </Text>
          }
          <Box mb={20}>
            <LoadingOverlay visible={isFetching} loader={<Loader size={'xl'} variant={'bars'} color={'#6852DB'} />} />
            <Stack spacing={0}>
              {(hasSearchData || aiData)
                && showFinishLater
                && (
                  <Text color={'#242424'}>
                    Thank you for your input! I've put together a list of key <span style={{ fontFamily: 'Roboto', fontWeight: 500 }}>{roleName}</span> skills and variables for you to review. Add or remove any you see fit.
                  </Text>
                )}
              {showFinishLater && !hasSearchData && !aiData && (
                <>
                  <Text color={'#242424'}>
                    Oh no! I wasn't able to retrieve any information.
                  </Text>
                  <Text color={'#242424'}>
                    Please provide any skills and variables that are important for this role.
                  </Text>
                </>
              )}
              <div className={classes.SuggestedAiRoles}>
                <div className={classes.RoleVariableDiv}>
                  <Group position="apart">
                    <Text size={"md"} color={"#242424"} mb={3}>Job titles to include</Text>
                    {!showJobTitlesExclusions ?
                      <Text
                        className={classes.AddExclusionButton} color={'#40A5D0'}
                        onClick={() => setShowJobTitlesExclusions(!showJobTitlesExclusions)}>
                        + Add exclusions
                      </Text>
                      : null}
                  </Group>
                  <SearchCriteriaJobTitles
                    onJobTitlesChange={handleJobTitlesChange}
                    jobTitles={!hasSearchData ? (aiData?.titles.length ? aiData?.titles : job_titles) : job_titles}
                    suggestedJobTitles={titlesSuggestions.length ? titlesSuggestions : []}
                    addTitleToBlacklist={addTitleToBlacklist}
                    removeTitleFromSuggestions={removeTitleFromSuggestions}
                  />
                  {(showLongJobTitleTagWarning || tagLengthWarning.job_titles) ? longTagWarningLabel : null}
                </div>
                {showJobTitlesExclusions ? (
                  <div className={classes.RoleVariableDiv}>
                    <Text size={"md"} color={"#242424"} mb={3}>Job titles to <strong>exclude</strong></Text>
                    <div className={classes.fieldWrapper}>
                      <SearchCriteriaJobTitlesExcluded
                        onJobTitlesChange={handleExcludedJobTitlesChange}
                        jobTitles={!hasSearchData ? (aiData?.job_titles?.length ? aiData?.job_titles.filter((x: any) => x.pivot.is_excluded) : excluded_job_titles) : excluded_job_titles}
                      />
                      {excludedTrashIconWithPopover('job_titles', excludedJobTitlesWatcher.length > 1)}
                    </div>
                  </div>
                ) : null}
                <div className={classes.RoleVariableDiv}>
                  <Group position="apart">
                    <Text size={"md"} color={"#242424"} mb={3}>Must have skills</Text>
                    {!showSkillsExclusions ? (
                      <Text
                        style={{ paddingRight: '5px', paddingBottom: '5px' }}
                        className={classes.AddExclusionButton} color={'#40A5D0'}
                        onClick={() => setShowSkillsExclusions(!showSkillsExclusions)}>
                        + Add exclusions
                      </Text>
                    ) : null}
                  </Group>
                  <SearchCriteriaSkills
                    onSkillsChange={handleSkillsChange}
                    skills={!hasSearchData ? (aiData?.skills.length ? aiData?.skills.filter((x: any) => !x.pivot.nice_to_have && !x.pivot.is_excluded) : skills) : skills}
                    isNiceToHave={false}
                    suggestedSkills={skillsSuggestions.length ? skillsSuggestions : []}
                    addSkillToBlacklist={addSkillToBlacklist}
                    removeSkillFromSuggestions={removeSkillFromSuggestions}
                  />
                  {(showLongSkillTagWarning || tagLengthWarning.skills) ? longTagWarningLabel : null}
                  {mustHaveSkillsLimitReached && (
                    <div style={{ display: 'flex' }}>
                      <div style={{
                        display: 'flex',
                        width: 'auto',
                        alignItems: 'center',
                        marginTop: '6px',
                        backgroundColor: 'rgba(255, 196, 0, 0.10)',
                        padding: "4px 10px 4px 5px",
                        borderRadius: 4
                      }}>
                        <FontAwesomeIcon size='lg' icon={faCircleExclamation} style={{ color: '#ffc500', marginRight: '5px' }} />
                        <Text color={"#4F4F4F"} mb={3} inline align='center' style={{
                          marginBottom: 'unset',
                          fontSize: "15px",
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 0
                        }}>Limit reached. Adding too many 'must-haves' may limit available results.</Text>
                      </div>
                    </div>
                  )}
                </div>
                {showSkillsExclusions ? (
                  <div className={classes.RoleVariableDiv}>
                    <Text size={"md"} color={"#242424"} mb={3}>Skills to <strong>exclude</strong></Text>
                    <div className={classes.fieldWrapper}>
                      <SearchCriteriaSkillsExcluded
                        onSkillsChange={handleExcludedSkillsChange}
                        skills={!hasSearchData ? (aiData?.skills?.length ? aiData?.skills.filter((x: any) => x.pivot.is_excluded) : excluded_skills) : excluded_skills}
                        isNiceToHave={false}
                      />
                      {excludedTrashIconWithPopover('skills', excludedSkillsWatcher.length > 1)}
                    </div>
                  </div>
                ) : null}
                {sortedComponents.map((item) => (
                  <React.Fragment key={item.menuItem}>
                    {item.component}
                  </React.Fragment>
                ))}
                <>
                  {displaySuggestionsBadge() && (
                    <Group my={10} spacing={10}>
                      <Group
                        spacing={10}
                        style={{
                          backgroundColor: '#40A5D01A',
                          fontStyle: 'italic',
                          color: '#147CA1',
                          padding: '2px',
                          borderRadius: '2px',
                          paddingLeft: '5px',
                          paddingRight: '5px'
                        }}
                      >
                        <Text>Additional AI suggestions</Text>
                        <FontAwesomeIcon icon={faWandSparkles} />
                      </Group>
                      {(originalData?.industries?.length === 0 && industrySuggestions?.length > 0) && industrySuggestions.map((industry: any) => (

                        <Badge
                          styles={{
                            root: {
                              backgroundColor: 'transparent'
                            },
                            inner: {
                              backgroundColor: 'transparent',
                              color: '#838485',
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }
                          }}
                          key={industry.id}
                          rightSection={<CloseButton
                            aria-hidden
                            radius={2}
                            variant="transparent"
                            tabIndex={-1}
                            className={classes.xStyles}
                            onClick={(event) => event.stopPropagation()}
                            onMouseDown={() => handleRemoveIndustrySuggestion(industry)}
                          />}
                          onClick={() => handleAddIndustrySuggestion(industry)}
                        >
                          {"Add " + industry.industry_name + " Industry"}
                        </Badge>

                      ))}
                      {((suggestionsYearsOfExperience && Object.keys(suggestionsYearsOfExperience).length > 0) && (!originalData?.info?.preferred_experience_min || !!originalData?.info?.preferred_experience_max)) && (
                        <Badge
                          styles={{
                            root: {
                              backgroundColor: 'transparent'
                            },
                            inner: {
                              backgroundColor: 'transparent',
                              color: '#838485',
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }
                          }}
                          key={suggestionsYearsOfExperience.id}
                          rightSection={<CloseButton
                            aria-hidden
                            radius={2}
                            variant="transparent"
                            tabIndex={-1}
                            className={classes.xStyles}
                            onClick={(event) => event.stopPropagation()}
                            onMouseDown={() => handleRemoveYearsOfExperienceSuggestion(suggestedYearsOfExperience)}
                          />}
                          onClick={() => handleAddYearsOfExperienceSuggestion(suggestedYearsOfExperience)}
                        >
                          {"More than " + suggestionsYearsOfExperience.preferred_experience_min + " years of experience"}
                        </Badge>
                      )}
                    </Group>
                  )}
                </>
                <div className={classes.AddVariablesWrapper}>
                  <Popover
                    position="bottom"
                    placement='start'
                    opened={isVariableBoxOpen}
                    onClose={() => setVariableBoxOpen(false)}
                    target={<Text
                      className={classes.AddVariableButton} color={'#40A5D0'}
                      onClick={() => setVariableBoxOpen((o) => !o)}>
                      + Add variable
                    </Text>}
                  >
                    <div>
                      {SEARCH_CRITERIA_VARIABLES.map((variable) => (
                        !selectedMenuItems.includes(variable.id) &&
                        <div
                          key={variable.id}
                          className={classes.VariableBoxItem}
                          onClick={() => handleMenuItemClick(variable.id)}
                        >
                          {variable.text}
                        </div>
                      ))}
                    </div>
                  </Popover>

                </div>
                {variableLimitReached && (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '20px'
                  }}>
                    <FontAwesomeIcon size='xl' icon={faCircleExclamation} style={{ color: '#E82429', marginRight: '5px' }} />
                    <Text size={"md"} color={"#E82429"} mb={3} inline align='center' style={{ marginBottom: 'unset' }}>You have reached the maximum number of variables allowed</Text>
                  </div>
                )}
              </div>
            </Stack>
          </Box>
        </div>
      }

      {!isLoading &&
        <div style={{ marginBottom: '2px' }}>
          <FormNavigation
            step={sourcing_only ? 3 : 5}
            next={handleSubmit(submit)}
            prev={handleSubmit(prev)}
            save={handleSubmit(save)}
            isSubmitting={isSubmitting}
            isSaveEnabled={!isValid || variableLimitReached}
            showFinishLater={showFinishLater}
            sourcing_only={sourcing_only}
            isNextDisabled={!isValid || variableLimitReached}
            isSubmitDisabled={skillsWatcher.length === 0 || jobTitlesWatcher.length === 0 || sameSkillsIncludedAndExcluded || isCandidateLocationEmpty}
            isSaveChangesDisabled={!formValuesHaveChanged || Object.values(tagLengthWarning).some(value => value) || sameSkillsIncludedAndExcluded}
          />
        </div>
      }
    </Box>
  );
};

export default SearchCriteriaStep;