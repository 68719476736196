import { Group } from "@mantine/core"
import SearchInput from "components/SearchInput/SearchInput"
import Filters from "./Filters";
import Sorts from "./Sorts";
import { sortOption } from "types";

type SearchbarProps = {
    searchTerm: string,
    onTextChange: (arg: string) => void,
    filters: {active: boolean, completed: boolean},
    onFiltersChange: (arg: {active: boolean, completed: boolean}) => void
    sort: sortOption,
    onSortChange: (arg: sortOption) => void,
}

const Searchbar = ({searchTerm, onTextChange, filters, onFiltersChange, sort, onSortChange}:SearchbarProps) => {

    return (
        
        <Group>
            <SearchInput value={searchTerm} onChange={onTextChange} sx={{ minWidth: 200, maxWidth: 500, flex: 1 }}/>
            <Filters activeFilters={filters} onFiltersChange={onFiltersChange} />
            <Sorts activeSort={sort} onSortChange={onSortChange}/>
        </Group>

    )
}

export default Searchbar