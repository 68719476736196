import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Group,
  Image,
  Stack,
  Text,
  TextInput,
  Title,
  Select,
  MultiSelect,
  createStyles
} from '@mantine/core';
import ICIMSLogo from 'assets/img/icims_wordmark.png';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Link } from 'react-router-dom';
import ExtLink from 'pages/Integration/ExtLink';
import {
  useConnectICIMSMutation,
  useDisconnectICIMSMutation,
  useGetICIMSQuery,
  useLazyGetICIMSPortalsQuery,
  useGetICIMSRegionsQuery,
  useUpdateICIMSPortalsMutation,
} from 'app/services/rolebot';
import { ConnectICIMSPayload, ICIMSPortal, ICIMSRegion } from 'types/requests';
import ContactSupportLink from 'pages/Integration/ContactSupportLink';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const ICIMSFormSchema = z.object({
  customerId: z.string().min(1, 'Customer Id is required'),
  region: z.string().min(1, 'Region is required'),
});

type ICIMSFormPayload = z.infer<typeof ICIMSFormSchema>;

const useStyles = createStyles(() => ({
  submitButton: {
    backgroundColor: '#7039ED',
    borderRadius: 6,
    color: '#FFFFFF',
    fontFamily: 'Helvetica',
    fontSize: 16,
  },
  disconnectButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 6,
    border: '1px solid #838485',
    color: '#838485',
    fontFamily: 'Helvetica',
    fontSize: 16,
    ':hover': {
      backgroundColor: '#EEEEEE',
      transition: 'background-color 0.2s ease-in-out',
    },
  },
  formLabel: {
    color: '#242424',
    fontFamily: 'Helvetica',
    fontSize: '14px'
  }
}));

const ICIMS = () => {
  const { disconnectIntegration } = useOpenModal();
  const { data } = useGetICIMSQuery();
  const { data: regionsData } = useGetICIMSRegionsQuery();
  const [getICIMSPortals, portalsData] = useLazyGetICIMSPortalsQuery();
  const { classes } = useStyles();

  const [selectedPortals, setSelectedPortals] = useState<string[]>([]);

  // Separate to it's value since we only care if this changes and not the whole data for isConnected.
  const hasICIMS = data?.region;

  const showStars = () => {
    return '*'.repeat(10);
  };

  const isConnected = useMemo(() => {
    if (!data) {
      return false;
    }
    return Boolean(hasICIMS);
    // eslint-disable-next-line
  }, [hasICIMS]);

  useEffect(() => {
    resetForm({ customerId: (!hasICIMS || hasICIMS === null) ? '' : showStars(), region: (!hasICIMS || hasICIMS === null) ? '' : showStars() })
  }, [hasICIMS]);

  const initialPortals = useMemo(() => {
    if (portalsData?.data && isConnected) {
      const formattedPortals = portalsData.data.map((portal: ICIMSPortal) => ({
        value: portal.id.toString(),
        label: portal.name,
      }));

      const portalList = portalsData.data
        .filter((portal: ICIMSPortal) => portal['should_process'] === true)
        .map((portal: ICIMSPortal) => portal.id.toString());

      setSelectedPortals(portalList);

      return formattedPortals;
    }

    return [];
  }, [portalsData, isConnected]);

  useEffect(() => {
    if (isConnected && portalsData.isUninitialized) {
      getICIMSPortals();
    }
  }, [isConnected, portalsData, getICIMSPortals]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    register,
    reset: resetForm,
  } = useForm({
    defaultValues: {
      customerId: hasICIMS ? showStars() : '',
      region: hasICIMS ? showStars() : '',
    },
    resolver: zodResolver(ICIMSFormSchema),
    mode: 'onBlur',
  });

  const [connectICIMS, { isSuccess: successConnected, error, reset: resetConnect, isLoading: connectLoading }] =
    useConnectICIMSMutation();

  const [disconnectICIMS, { isSuccess: successDisconnected, reset: resetDisconnect, isLoading: disconnectLoading }] =
    useDisconnectICIMSMutation();

  const [updateICIMSPortals] = useUpdateICIMSPortalsMutation();

  /**
   * When portal has been changed, update the portals and make API call
   * @param portals
   */
  const onUpdatePortals = (portals: string[]) => {
    setSelectedPortals(portals);
    updateICIMSPortals({ portals });
  };

  const regions = useMemo(() => {
    if (regionsData) {
      const formattedRegions = regionsData.map((region: ICIMSRegion) => ({
        value: region.name,
        label: region.name,
      }));

      return formattedRegions;
    }

    return [];
  }, [regionsData]);

  const onConnect = async (data: ICIMSFormPayload) => {
    resetDisconnect();
    const payload: ConnectICIMSPayload = {
      customerId: data.customerId,
      region: data.region,
    };
    await connectICIMS(payload).unwrap();
  };

  const handleDisconnect = async () => {
    resetConnect();
    await disconnectICIMS().unwrap();
    setSelectedPortals([]);
    resetForm({
      customerId: '',
      region: '',
    });
  };

  const onDisconnect = (e: FormEvent) => {
    e.preventDefault();
    disconnectIntegration({
      integration: 'ICIMS',
      content: (
        <>
          <Text>
            This action will delete all the connections between Rolebot and ICIMS and will affect all of your company's
            users.
          </Text>
          <Text>
            You will continue to have access to any roles that were previously created on Rolebot via ICIMS, but will
            not have access to any new information.
          </Text>
          <Text>Are you sure you want to disconnect this app?</Text>
        </>
      ),
      onAction: async () => {
        await handleDisconnect();
      },
    });
  };

  return (
    <Container size={'xl'} px={0}>
      <Group noWrap spacing={8} mb={16}>
        <RolebotButton type={'link'}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ paddingRight: '6px' }} />
          <Link to={'/settings/integrations'} style={{ color: '#249FC8', textDecoration: 'none' }}>
            Browse Apps
          </Link>
        </RolebotButton>
      </Group>
      <Group spacing={40} noWrap>
        <Box sx={{ maxWidth: 200, alignSelf: 'flex-start' }}>
          <Image src={ICIMSLogo} width={200} height={200} fit={'contain'} radius={15} />
          <Divider mt={40} mb={20} />
          <Stack spacing={4} mb={40}>
            <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }}>
              Requirements
            </Text>
            <Text color={'#B3B3B3'}>Paid ICIMS Plan</Text>
            <Text color={'#B3B3B3'}>Pay for the integration</Text>
          </Stack>
          <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }} mb={4}>
            Support
          </Text>
          <Stack spacing={4}>
            {/* <ExtLink to={'#'} title={'Setup Guide'} /> */}
            <ContactSupportLink />
            <ExtLink to={'https://www.icims.com/'} title={'Visit ICIMS'} />
          </Stack>
        </Box>
        <Box mb={'auto'}>
          <Stack spacing={20} mb={10}>
            <Title order={2} sx={{ color: '#242424' }}>
              ICIMS
            </Title>
            <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'}>
              Description
            </Text>
          </Stack>
          <Stack sx={{ color: '#838485' }} spacing={10} mb={10}>
            <Text>ICIMS is a cloud-based applicant tracking system and recruting software that helps your recruiting team overcome your most difficult hiring challenges.</Text>
            <Text>
              This integration allows ICIMS' users to:
              <ul>
                <li>Launch ICIMS Jobs on Rolebot</li>
                <li>Link ICIMS Jobs to Rolebot roles</li>
                <li>Export Rolebot candidates to your company's ICIMS database</li>
              </ul>
            </Text>
          </Stack>
          <Stack sx={{ color: '#B3B3B3' }} spacing={1} mb={30}>
            <Text>Please note iCIMS will not be available to users that do not have and iCIMS account.</Text>
          </Stack>
          <Stack mb={4}>
            <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'} mb={10}>
              App Setup
            </Text>
          </Stack>
          {/* <Stack sx={{ color: '#838485' }} spacing={1} mb={16}>
            <Text>
              Enter your iCIMS details to complete the integration. For detailed step by step instructions, click{' '}
              <Text component={'a'} href={'http://v4-dev.localhost.com/settings/integrations/icims#'} target={'_blank'} color={'#53AFCD'}>
                here.
              </Text>{' '}
            </Text>
          </Stack> */}

          <form onSubmit={isConnected ? onDisconnect : handleSubmit(onConnect)}>
            <Stack style={{ marginBottom: '30px' }}>
              <TextInput
                label={'Customer ID'}
                {...register('customerId')}
                error={errors?.customerId?.message}
                disabled={isConnected}
                classNames={{
                  label: classes.formLabel,
                }}
              />
              <Controller
                control={control}
                name="region"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    data={regions}
                    value={value}
                    label={'Region'}
                    {...register('region')}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors?.region?.message}
                    disabled={isConnected}
                    classNames={{
                      label: classes.formLabel,
                    }}
                  />
                )}
              />

              {isConnected ? (
                <MultiSelect
                  data={initialPortals}
                  value={selectedPortals}
                  label="Select portals you want to sync"
                  placeholder="Select portals you want to sync"
                  searchable
                  onChange={onUpdatePortals}
                  nothingFound="No portals found"
                  classNames={{
                    label: classes.formLabel,
                  }}
                />
              ) : null}
              <Button
                ml={'auto'}
                px={30}
                type={'submit'}
                disabled={isConnected ? false : !isValid}
                loading={isConnected ? disconnectLoading : connectLoading}
                className={isConnected ? classes.disconnectButton : classes.submitButton}
              >
                {isConnected ? 'Disconnect' : 'Add to Rolebot'}
              </Button>
            </Stack>
          </form>
          {error && (
            <Alert color={'red'} mt={20}>
              {/*@ts-ignore*/}
              {error?.data?.message}
            </Alert>
          )}
          {successConnected && (
            <Alert color={'green'} mt={20}>
              Successfully connected to ICIMS
            </Alert>
          )}
          {successDisconnected && (
            <Alert color={'green'} mt={20}>
              Successfully disconnected from ICIMS
            </Alert>
          )}
        </Box>
      </Group>
    </Container>
  );
};
export default ICIMS;
