import React, { useEffect, useState, memo, useRef } from 'react';
import { Checkbox, createStyles, Group, Popover, Stack, Text, Title } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { filtersChanged } from 'features/app/appSlice';
import useAppSelector from 'hooks/useAppSelector';
import { sendGoogleEvent } from 'utils/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSeal } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme, { hasActiveFilters, showFilters }: any) => ({
  title: {
    color: '#242424',
  },
  button: {
    backgroundColor: hasActiveFilters || showFilters ? '#EEEEEE' : 'transparent',
    color: hasActiveFilters ? '#351B77' : '#838485',
    padding: 10,
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    }
  },
  filterIcon: {
    height: '16px',
    paddingBottom: '2px'
  }
}));

interface FilterProps {
  roleType?: string
}

const Filters = ({roleType}:FilterProps) => {
  const hasActiveFilters = useAppSelector((state) => state.app.hasActiveFilters);
  const activeFiltersCount = useAppSelector((state) => state.app.activeFiltersCount);
  const filtersState = useAppSelector((state) => state.app.filters);
  const dispatch = useAppDispatch();
  const [showFilters, setShowFilters] = useState(false);
  const { classes } = useStyles({ hasActiveFilters, showFilters });
  const { register, reset, watch } = useForm({
    defaultValues: filtersState,
    mode: 'onChange',
  });
  const isFirstRenderRef = useRef(true);
  const talentToReview = watch('talentToReview');
  const waitingForSetup = watch('waitingForSetup');
  const onHold = watch('onHold');
  const turnkey = watch('turnkey');
  const sourcing_only = watch('sourcing_only');

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    dispatch(
      filtersChanged({
        activeFiltersCount: [talentToReview, waitingForSetup, onHold, turnkey, sourcing_only].filter(Boolean).length,
        hasActiveFilters: talentToReview || waitingForSetup || onHold || turnkey || sourcing_only,
        talentToReview,
        waitingForSetup,
        onHold,
        turnkey,
        sourcing_only
      })
    );
  }, [talentToReview, waitingForSetup, onHold, turnkey, sourcing_only, dispatch]);

  const onClear = () => {
    dispatch(
      filtersChanged({
        activeFiltersCount: 0,
        hasActiveFilters: false,
        talentToReview: false,
        waitingForSetup: false,
        onHold: false,
        turnkey: false,
        sourcing_only: false
      })
    );
    reset({
      talentToReview: false,
      waitingForSetup: false,
      onHold: false,
      turnkey: false,
      sourcing_only: false
    });
  };

  useEffect(() => {
    if (showFilters) {
      sendGoogleEvent('User', 'Role filter opened');
    }
  }, [showFilters]);

  return (
    <Popover
      opened={showFilters}
      onClose={() => setShowFilters(false)}
      target={
        <Group spacing={5} onClick={() => setShowFilters(true)} className={classes.button}>
          <FontAwesomeIcon icon={faFilter} fill={hasActiveFilters ? '#351B77' : '#838485'} className={classes.filterIcon} />
          {hasActiveFilters ? `Filter / ${activeFiltersCount}` : 'Filter'}
        </Group>
      }
      width={320}
      position="bottom"
      placement="start"
      styles={{
        body: {
          borderRadius: 6,
          border: '1px solid #DFE1E1',
          boxShadow: '0px 5px 15px rgba(131, 132, 133, 0.45)',
        },
        inner: {
          padding: '20px 30px'
        },
      }}
    >
      <Stack spacing={0}>
        <Group position={'apart'} mb={20}>
          <Title order={5} color={'#242424'} className={classes.title}>
            Filters
          </Title>
          <Text size={'md'} color={'#B3B3B3'} onClick={onClear} sx={{ cursor: 'pointer' }}>
            Clear All
          </Text>
        </Group>
        <Text component={'h6'} my={0} mb={6} color={'#4F4F4F'} sx={{ fontFamily: 'Roboto', fontWeight: 500 }}>
          Status
        </Text>
        <Stack spacing={8}>
          <Checkbox size={'sm'} color={''} {...register('talentToReview')} label={'Talent to Review'} />
          <Checkbox size={'sm'} {...register('waitingForSetup')} label={'Waiting for Setup'} />
          <Checkbox size={'sm'} {...register('onHold')} label={'On Hold'} />
        </Stack>

        {roleType === 'Hybrid (Sourcing & Turnkey)' && (<><Text component={'h6'} mt={20} mb={6} color={'#4F4F4F'} sx={{ fontFamily: 'Roboto', fontWeight: 500 }}>
          Type of role
        </Text>
        <Stack spacing={8}>
          <Checkbox size={'sm'} {...register('turnkey')} label={'Turnkey'} />
          <Checkbox size={'sm'} {...register('sourcing_only')} label={'Sourcing only'} />
        </Stack></>)}

      </Stack>
    </Popover>
  );
};

export default memo(Filters);
