import { Alert, Box, Button, createStyles, Group, List, Stack, Text } from '@mantine/core';
import { useResumeRoleMutation } from 'app/services/rolebot';
import { ContextModalProps, useModals } from '@mantine/modals';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { useEffect } from 'react';
import { ResumeRoleProps } from 'types/modals';

const useStyles = createStyles((theme) => ({
  bottomButtons: {
    marginTop: 'auto',
    button: {
      ':first-child': {
        order: 2,
      },
      ':last-child': {
        order: 1,
      },
    },
    [theme.fn.largerThan('md')]: {
      marginTop: 30,
      flexGrow: 0,
      flexDirection: 'row',
      button: {
        ':first-child': {
          order: 1,
        },
        ':last-child': {
          order: 2,
        },
      },
    },
  },
}));

const ResumeRole = ({ id, innerProps }: ContextModalProps<ResumeRoleProps>) => {
  const { roleId, roleName } = innerProps;
  const [resumeRole, { isLoading, isSuccess, isError }] = useResumeRoleMutation();
  const modals = useModals();
  const { classes } = useStyles();

  const handleClose = () => {
    modals.closeAll();
  };

  const handleResumeRole = async () => {
    await resumeRole({
      roleId: roleId,
      payload: {
        on_hold: 0,
      },
    }).unwrap()
    .then((response) => {
      const role = response.role;
      if (role && innerProps.onPatchCompleted) {
        innerProps.onPatchCompleted('resumeRole', role.id);
      }
    });
  };

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <TitleWithClose id={id} title={`Resume Role`} mb={20} />
      <>
        <Group align={'flex-start'} noWrap direction={'column'}>
          <Stack>
            <Text color={'#242424'}>
              Do you want to resume the <strong style={{ fontFamily: 'Roboto', fontWeight: 500 }}>{roleName}</strong>{' '}
              role?
            </Text>
            <Text color={'#242424'}>This action will:</Text>
          </Stack>

          <Box sx={{ height: 'auto', overflow: 'hidden', overflowY: 'auto' }}>
            <List withPadding styles={{ item: { color: '#242424' } }}>
              <List.Item>Reactivate sourcing for this role</List.Item>
              <List.Item>Resume Rolebot’s engagement with all active candidates</List.Item>
            </List>
          </Box>
          <Text color={'#838485'} mt={4}>
            If there are no candidates left to review, you will receive a new batch of candidates on the next business
            day after reactivation.
          </Text>
        </Group>
        {isError && (
          <Alert title={'Oops, something went wrong!'} color={'orange'}>
            We were not able to resume this Role.
          </Alert>
        )}
        <Group position={'right'} className={classes.bottomButtons}>
          <Button onClick={handleClose} disabled={isLoading} variant={'outline'}>
            Cancel
          </Button>
          <Button px={40} onClick={handleResumeRole} loading={isLoading}>
            Confirm
          </Button>
        </Group>
      </>
    </>
  );
};

export default ResumeRole;
