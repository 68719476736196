import React from 'react';
import { createStyles, Group, Text, Transition } from '@mantine/core';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  container: {
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 16,
  },
  link: {
    color: '#242424',
    textDecorationSkipInk: 'none'
  },
}));

const InactivePlanAlert = () => {
  const { classes } = useStyles();

  return (
    <Transition transition={'slide-down'} mounted={true}>
      {(styles) => (
        <div
          style={{
            ...styles,
            height: 35,
            backgroundColor: '#F4DCFF',
          }}
        >
          <Group sx={{ justifyContent: 'center', height: '100%' }} align={'center'} px={10} spacing={0} noWrap>
            <Text align={'center'} color={'#242424'} className={classes.text}>
              Your plan is currently inactive. To gain access to all platform functionalities, 
              <Link to="/settings/billing" className={classes.link}> reactivate your plan here</Link>.
            </Text>
          </Group>
        </div>
      )}
    </Transition>
  );
};

export default InactivePlanAlert;