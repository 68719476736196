import React, { useMemo } from 'react';
import { createStyles, Group, Text, Transition } from '@mantine/core';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    container: {
        justifyContent: 'space-between',
    },
    text: {
        fontSize: 16,
    },
    link: {
        color: '#242424',
        textDecorationSkipInk: 'none'
    },
}));

const DowngradePending = () => {
    const { classes } = useStyles();
    const { data } = useGetCompanyQuery();
    const navigate = useNavigate();

    const newRoleLimit = data?.latest_downgrade?.quantity;
    const downgradeDate = data?.latest_downgrade?.scheduled_at;

    return (
        <Transition transition={'slide-down'} mounted={true}>
            {(styles) => (
                <div
                    style={{
                        ...styles,
                        height: 35,
                        backgroundColor: '#F4DCFF',
                    }}
                >
                    <Group sx={{ justifyContent: 'center', height: '100%' }} align={'center'} px={10} spacing={0} noWrap>
                        <Text align={'center'} color={'#242424'} className={classes.text}>
                            Your downgrade to {newRoleLimit} active roles will take effect on {moment(downgradeDate).format('MMM D, YYYY')}. Any extra active roles will be automatically closed after that date.
                        </Text>
                    </Group>
                </div>
            )}
        </Transition>
    );
};

export default DowngradePending;