import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { Grid, Text } from '@mantine/core';

const useStyles = createStyles(() => ({
  MetricRow: {
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '8px',
    color: '#242424',
    padding: '2px 10px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      borderRadius: '5px',
    },
    '&:last-child': {
      marginBottom: '0',
    },
  },

  AddRow: {
    color: '#838485',
    marginLeft: '5px',
    fontSize: '13px',
  },

  LocationRow: {
    fontDamily: 'Helvetica',
    fontWeight: 400,
    fontDize: '15px',
    lineHeight: '17px',
    margin: '4px 0',
    color: '#838485',
  },
}));

const MetricRow = ({ rowSelected, row }) => {
  const { classes } = useStyles();

  return (
    <div
      className={classes.MetricRow}
      onClick={() => {
        rowSelected(row);
      }}
    >
      <Grid grow style={{ width: '100%' }} justify="space-between" align="center">
        <Grid.Col style={{ width: '50%' }} md={8}>
          <Text color={'#242424'}>
            {row.name}
            <span className={classes.AddRow}>
              {row?.greenhouse_job?.requisition_id ||
              row?.lever_job?.requisition_ids.replace(/[[\]"\\]/g, '').length > 0 ||
              row?.sap_job?.requisition_id
                ? 'Req Id '
                : ''}
              {row?.greenhouse_job && row.greenhouse_job?.requisition_id?.concat(' ')}
              {row?.lever_job && row.lever_job?.requisition_ids.replace(/[[\]"\\]/g, '').concat(' ')}
              {row?.sap_job && row.sap_job?.requisition_id?.concat(' ')}
            </span>
            <p className={classes.LocationRow}>{row?.info?.office_locations}</p>
          </Text>
        </Grid.Col>
        {row?.completed && (
          <Grid.Col style={{ width: '50%' }} md={0}>
            <Text color={'#838485'}>Completed</Text>
          </Grid.Col>
        )}
      </Grid>
    </div>
  );
};

export default MetricRow;

MetricRow.propTypes = {
  row: PropTypes.object.isRequired,
  rowSelected: PropTypes.func.isRequired,
};
