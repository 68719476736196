import React from 'react';
import { getStartRange, getEndRange } from './hours';
import { Group, Select, UnstyledButton, Button, createStyles } from '@mantine/core';


const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {

  },
  copyToAllButton: {
    display: 'none',
    padding: 0,
    [theme.fn.largerThan('md')]: {
      display: 'block'
    },
  },
  copyToAllButtonOutline: {
    borderColor: '#ffffff',
    color: '#40a5d0',
    '&:hover': {
      color: '#40a5d0',
      textDecoration: 'underline',
      backgroundColor: '#ffffff'
    }
  },
  toText: {
    display: 'inline-block',
    whiteSpace: 'nowrap'
  },
  circleButtons: {
    width: '25px',
    height: '25px',
    overflow: 'hidden',
    border: '1px solid #838485',
    borderRadius: 12.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#838485',
    '&:hover': {
      backgroundColor: '#eeeeee'
    },
    '&:active': {
      borderColor: '#351b77',
      color: '#351b77'
    }
  },

  circleButtonsDisabled: {
    cursor: 'not-allowed',
    color: '#c4c4c4',
    borderColor: '#c4c4c4',
    '&:hover': {
      backgroundColor: 'unset',

    },
  }
}));

interface TimeSlotProps {
  dayId: string,
  slotId: string,
  showMinus: boolean;
  showPlus: boolean;
  disablePlus: boolean,
  disableMinus: boolean,
  showCopyToAll: boolean,
  start: string|null,
  end: string|null,
  hasErrors: boolean,
  onAddSlot: () => void;
  onRemoveSlot?: () => void;
  copyToAllFields: (dayId: string) => void;
  setSlotDate: (dayId: string, slotId: string, startTime: string|null, endValue: string|null) => void;
}

const TimeSlot = ({
  dayId,
  slotId,
  showMinus,
  disableMinus,
  showPlus,
  disablePlus,
  start,
  end,
  onAddSlot,
  onRemoveSlot,
  setSlotDate,
  hasErrors,
  copyToAllFields,
  showCopyToAll
  }: TimeSlotProps) => {

  const { classes } = useStyles();

  const updateStartSlotValue = (e:any) => {
    setSlotDate(dayId, slotId, e, end);
  }

  const updateEndSlotValue = (e:any) => {
    setSlotDate(dayId, slotId, start, e);
  }

  return (
    <Group noWrap>
      <Group noWrap>
        <Select placeholder="Start" error={hasErrors} sx={{ maxWidth: 150 }} data={getStartRange(end)} value={start} onChange={updateStartSlotValue} />
        <span className={classes.toText}>to</span>
        <Select placeholder="End" error={hasErrors} sx={{ maxWidth: 150 }} data={getEndRange(start)} value={end} onChange={updateEndSlotValue} />
      </Group>
      {showPlus &&
        <UnstyledButton disabled={!disablePlus} onClick={onAddSlot}
                        className={disablePlus ? classes.circleButtons : [classes.circleButtons, classes.circleButtonsDisabled].join(' ')}>
          +
        </UnstyledButton>
      }
      {showMinus &&
        <UnstyledButton disabled={!disableMinus} onClick={onRemoveSlot} className={disableMinus ? classes.circleButtons : [classes.circleButtons, classes.circleButtonsDisabled].join(' ')}>
          -
        </UnstyledButton>
      }

      {showCopyToAll &&
        <Button className={classes.copyToAllButton } classNames={{outline: classes.copyToAllButtonOutline }} variant='outline' onClick={() => copyToAllFields(dayId)}>Copy time to all</Button>
      }

    </Group>
  );
};

export default TimeSlot;