import { Group, Stack, Tabs, Text, Title, Pagination } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { usePostMfaStatusMutation, useGetCompanyQuery } from 'app/services/rolebot';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import OfficeRow from 'components/OfficeRow/OfficeRow';
import { useEffect, useMemo, useState } from 'react';
import Accounts from './Accounts';

const Security = () => {

  const [activePage, setPage] = useState(1);
  
  return (
    <div>
      <Stack mb={30}>
        <Title order={2} style={{color:'#242424'}}>
          Security
        </Title>
      </Stack>

      <Tabs variant={'unstyled'}>
        <Tabs.Tab label="Accounts">
            <Accounts />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

export default Security;
