import React from 'react';
import { ActionIcon, createStyles, Tooltip } from '@mantine/core';

const useStyles = createStyles((theme, { active, size, color }: { active: boolean; size: number; color: string }) => ({
  icon: {
    borderRadius: size / 2,
    fill: active ? `#${color}` : '#838485',
    ':hover': {
      color: `#${color}CC`,
    },
  },
}));

interface TalentActionIconProps {
  hoverColor: string;
  active: boolean;
  isLoading: boolean;
  size?: number;
  onClick?: () => void;
  children?: React.ReactNode;
  content?: React.ReactNode;
  isButtonsActive?: boolean;
}

const TalentActionIcon = ({
  children,
  active,
  isLoading,
  hoverColor,
  content,
  size = 50,
  onClick,
  isButtonsActive,
}: TalentActionIconProps) => {
  const { classes } = useStyles({ active, size, color: hoverColor });
  const handleClickIcon = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <Tooltip placement={'center'} label={content}>
      <ActionIcon
        loading={isLoading}
        size={size}
        variant={isLoading ? 'transparent' : 'outline'}
        radius={size / 2}
        onClick={handleClickIcon}
        onTouchStart={handleClickIcon}
        classNames={{ root: classes.icon }}
        disabled={!isButtonsActive}
        sx={{
          cursor: 'pointer',
          backgroundColor: '#fff',
          boxShadow: '0px 0px 4px #4f4f4f40',
          color: active ? `${hoverColor}` : '#4F4F4F',
          border:
            active && !isLoading ? `2px solid ${hoverColor}` : !active && !isLoading ? '1px solid #DFE1E1' : 'none',
          '&:hover': { border: !isLoading ? `2px solid ${hoverColor}CC` : 'none', backgroundColor: '#fff' },
        }}
        style={!isButtonsActive ? { cursor: 'progress' } : {}}
      >
        {children}
      </ActionIcon>
    </Tooltip>
  );
};

export default TalentActionIcon;
