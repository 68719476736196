import { Text, Group, Badge, CloseButton, createStyles } from "@mantine/core";
import { useCallback, useEffect, useState } from 'react';
import { RolebotClient } from "../../../../api/client";
import RolebotMultiselect from "components/Inputs/RolebotMultiSelect";
import { useDebouncedValue } from "@mantine/hooks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandSparkles } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles(() => ({
  xStyles: {
    color: '#B3B3B3',
    ':hover': {
      color: '#4F4F4F',
    },
    marginLeft: '-5px'
  },
  AddVariableButton: {
    paddingRight: '5px',
    paddingBottom: '5px',
    '&:hover': {
      cursor: 'pointer',
    }
  },
}))

interface SearchCriteriaJobTitlesProps {
  onJobTitlesChange: (payload: { name: string }[]) => void;
  jobTitles: any;
  suggestedJobTitles: any;
  addTitleToBlacklist: (payload: object) => void;
  removeTitleFromSuggestions: (payload: object) => void;
}

// transforms the data to a Mantine MultiSelect readable format
const formatDropdownItems = (items: { id: any; name: any; }[]) => {
  if (items?.length) {
    return items?.map((x: { id: any; value?: any; name: any; label?: any; pivot?: any }) => {
      return {
        value: x.id.toString(),
        label: x.name,
        id: x.id,
        name: x.name,
        is_from_feedback: x.pivot?.is_from_feedback
      };
    });
  } else {
    return []
  }
}

const getUniqueListById = (arr: any) => {
  return [...new Map(arr.map((item: any) => [item['id'], item])).values()]
}

const SearchCriteriaJobTitles = ({ onJobTitlesChange, jobTitles, suggestedJobTitles = [], addTitleToBlacklist, removeTitleFromSuggestions }: SearchCriteriaJobTitlesProps) => {
  const [jobTitleSearchTerm, setJobTitleSearchTerm] = useState("");
  const [availableJobTitles, setAvailableJobTitles] = useState(jobTitles?.length ? formatDropdownItems(jobTitles) : []);
  const [selectedTitles, setSelectedTitles] = useState(jobTitles ? jobTitles.map((title: any) => title.id.toString()) : []);
  const [debouncedSearch] = useDebouncedValue(jobTitleSearchTerm.trim(), 500);
  const { classes, cx } = useStyles();

  useEffect(() => {
    onJobTitlesChange(formatPayloadItems(selectedTitles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTitles]);

  useEffect(() => {
    if (jobTitleSearchTerm) searchForJobTitlesTag(jobTitleSearchTerm)
  }, [debouncedSearch])

  // transforms the data to a back to the expected from API format  e.g: id: 1, name: "Laravel"
  // applicable for both skills and job titles
  const formatPayloadItems = (items: string[]) => {

    if (items?.length) {
      return items?.map((id: string) => {
        let object = availableJobTitles.find((t: any) => t.id.toString() === id)
        return {
          id: object?.id ?? id,
          name: object?.name ?? id,
          is_from_feedback: object?.is_from_feedback
        };
      });
    } else {
      return []
    }
  }

  const searchForJobTitlesTag = useCallback((searchTerm) => {
    (async () => {
      try {
        const { data } = await RolebotClient.get('/job-titles', { params: { 'search-term': searchTerm } });

        if (data.data && data.data.length) {
          // @ts-ignore
          setAvailableJobTitles((prev) => getUniqueListById([...prev, ...formatDropdownItems(data.data)]));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [debouncedSearch]);

  const copyToAvailable = (values: string[]) => {
    let selected = availableJobTitles.filter((x: any) => values.includes(x.value))
    setAvailableJobTitles(selected)
    setSelectedTitles(values)
  }

  const handleAddSuggestion = (suggestion: any) => {
    const payload = {
      value: suggestion.job_title_id.toString(),
      label: suggestion.job_title_name,
      id: suggestion.job_title_id,
      name: suggestion.job_title_name,
      is_from_feedback: false
    }

    setAvailableJobTitles(prevTitles => {
      const newTitles = [...prevTitles];
      const existingIndex = newTitles.findIndex(title => title.id === payload.id);
      if (existingIndex === -1) {
        newTitles.push(payload);
      }
      return newTitles;
    });

    // Add to selectedTitles
    setSelectedTitles((prevSelectedTitles: string[]) => {
      const newSelected = [...prevSelectedTitles];
      if (!newSelected.includes(payload.id.toString())) {
        newSelected.push(payload.id.toString());
      }
      return newSelected;
    });

    removeTitleFromSuggestions(suggestion);
  }

  return (
    <>
      <RolebotMultiselect
        data={availableJobTitles}
        searchable
        creatable
        value={selectedTitles}
        getCreateLabel={(query) => `+ Create ${query}`}
        onChange={copyToAvailable}
        nothingFound={'Type to search'}
        onSearchChange={setJobTitleSearchTerm}
        clearSearchOnBlur={true}
        clearSearchOnChange={true}
        maxSelectedValues={100}
        onBlur={() => {
          setAvailableJobTitles(availableJobTitles.filter((x: any) => selectedTitles.includes(x.value)));
        }}
      />

      {suggestedJobTitles.length > 0 && (
        <Group my={10} spacing={10}>
          <Group
            spacing={10}
            style={{
              backgroundColor: '#40A5D01A',
              fontStyle: 'italic',
              color: '#147CA1',
              padding: '2px',
              borderRadius: '2px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            <Text>Suggested by AI</Text>
            <FontAwesomeIcon icon={faWandSparkles} />
          </Group>
          {suggestedJobTitles.map((suggestion: any) => (
            <Badge
              styles={{
                root: {
                  backgroundColor: 'transparent'
                },
                inner: {
                  backgroundColor: 'transparent',
                  color: '#838485',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}
              style={{
                marginRight: '-10px'
              }}
              key={suggestion.id}
              rightSection={<CloseButton
                aria-hidden
                onMouseDown={() => addTitleToBlacklist(suggestion)}
                radius={2}
                variant="transparent"
                tabIndex={-1}
                className={classes.xStyles}
                onClick={(event) => event.stopPropagation()}
              />}
              onClick={() => handleAddSuggestion(suggestion)}
            >
              {suggestion.job_title_name}
            </Badge>
          ))}
        </Group>
      )}
    </>
  );
};

export default SearchCriteriaJobTitles;
