import React from 'react';
import { Group, GroupProps, Text } from '@mantine/core';

interface TextLinkProps extends GroupProps {
  children: React.ReactNode;
  icon: React.ReactNode;
}

const TextLink = ({ children, icon, ...rest }: TextLinkProps) => {
  return (
    <Group
      role={'button'}
      spacing={10}
      noWrap
      sx={{ alignSelf: 'flex-start', color: '#1075BDCC', height: 40, cursor: 'pointer' }}
      {...rest}
    >
      {icon ? icon : null}
      <Text>{children}</Text>
    </Group>
  );
};

export default TextLink;
