import React, {useState} from "react";
import WidgetWrapper from '../WidgetWrapper/WidgetWrapper';
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createStyles, Popover, Text} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    SmallWidget: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },

    Value: {
        fontSize: '37px'
    },

    Help: {
        display: 'inline-block',
        position: 'relative',
        marginLeft: '3px',
        top: '2px',
    }
}));

const SmallWidget = (props) => {

    const { classes } = useStyles();
    const [opened, setOpened] = useState(false);

    const popover = (
        <Popover
            opened={opened}
            onClose={() => setOpened(false)}
            position="top"
            placement="start"
            withArrow
            trapFocus={false}
            closeOnEscape={false}
            transition="pop-top-left"
            width={260}
            styles={{ body: { pointerEvents: 'none' } }}
            target={
                <FontAwesomeIcon onMouseEnter={() => setOpened(true)} onMouseLeave={() => setOpened(false)} icon={faQuestionCircle} style={{cursor: 'pointer', marginRight: "4px", display: "inlineBlock", color: 'cornflowerblue'}}/>
            }
        >
            <div>
                <Text size="md" style={{marginBottom: 10, fontWeight: "bold"}}>{ props.section }</Text>
                <Text size="sm">{ props.helpMessage }</Text>
            </div>
        </Popover>
    );

    return (
        <WidgetWrapper>
            <div className={classes.SmallWidget}>
                <div>{props.icon} <span className={classes.Value}>{props.value}</span></div>
                <div>
                    {props.section}
                    <div className={classes.Help}>
                        { popover }
                    </div>
                </div>
            </div>
        </WidgetWrapper>
    );
};

export default SmallWidget;
