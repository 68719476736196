import React, { useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { createStyles, Table, Skeleton } from '@mantine/core';
import TalentRow from 'components/TalentRow/TalentRow';
import { RoleStackData, RoleType } from 'types';
import { useGetCompanyQuery } from 'app/services/rolebot';

const useStyles = createStyles((theme) => ({
  tr: {
    width: '100%',
    height: 115,
    position: 'relative',
    '&:after': {
      content: `""`,
      display: 'block',
      position: 'absolute',
      bottom: -1,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: '#EEEEEE',
    },
  },
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '40px',
    paddingTop: '20px'
  },
}));

interface TalentStackProps {
  data: RoleStackData[];
  roleId: string;
  type: RoleType;
  onEndReached?: () => void;
  handleSetTalent?: any;
  isButtonsActive?: boolean;
  isFetching?: boolean;
  onPatchCompleted?: (action: string, talentId: number | string, success: boolean | null) => void;
  page?: number;
  isSourceOnly?: boolean;
  extraRoleData: any;
}

const TalentStack = ({
  data = [],
  roleId,
  type,
  onEndReached,
  handleSetTalent,
  isButtonsActive = true,
  isFetching = false,
  onPatchCompleted,
  page = 1,
  isSourceOnly = false,
  extraRoleData
}: TalentStackProps) => {
  const { classes } = useStyles();
  const { data: company } = useGetCompanyQuery();
  const hasContactOut = useMemo(() => {
    if (!company) return false;
    return company?.enable_contact_out;
    // eslint-disable-next-line
  }, [company]);
  const hasRocketReach = useMemo(() => {
    if (!company) return false;
    return company?.enable_rocket_reach;
    // eslint-disable-next-line
  }, [company]);

  const Footer = () => {
    return (
      <div>
        {isFetching && page !== 1 && (
          <div className={classes.container}>
            <Skeleton height={80} circle mb="xl" />
            <Skeleton height={8} radius="xl" />
            <Skeleton height={8} mt={6} radius="xl" />
            <Skeleton height={8} mt={6} width="70%" radius="xl" />
          </div>
        )}
      </div>
    )
  }

  return (
    <Table verticalSpacing={10} sx={{ height: '100%' }}>
      <tbody id={'talent-content-table'}>
        <tr>
          <td style={{ height: '100%', padding: 0 }}>
            <Virtuoso
              fixedItemHeight={100}
              style={{ height: '100%' }}
              data={data}
              itemContent={(index, talent) =>
                <TalentRow
                  key={talent.id}
                  talent={talent}
                  roleId={roleId!}
                  hasContactOut={hasContactOut}
                  hasRocketReach={hasRocketReach}
                  type={type}
                  handleSetTalent={handleSetTalent}
                  handlePatchCompleted={onPatchCompleted}
                  isButtonsActive={isButtonsActive}
                  isSourceOnly={isSourceOnly}
                  extraRoleData={extraRoleData}
                />}
              endReached={onEndReached}
              overscan={400}
              components={{ Footer }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default React.memo(TalentStack);
