import React, { useMemo } from 'react';
import { createStyles, Group, Text, Transition } from '@mantine/core';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    container: {
        justifyContent: 'space-between',
    },
    text: {
        fontSize: 16,
    },
    link: {
        color: '#242424',
        textDecorationSkipInk: 'none'
    },
}));

const ActionPendingAlert = () => {
    const { classes } = useStyles();
    const { data } = useGetCompanyQuery();
    const navigate = useNavigate();

    const isAdmin = useMemo(() => {
        return Boolean(data?.customer_user?.is_admin);
        // eslint-disable-next-line
    }, [data]);

    const handleReactivate = () => {
        navigate('/settings/billing', { state: { reactivate: true } })
    }

    return (
        <Transition transition={'slide-down'} mounted={true}>
            {(styles) => (
                <div
                    style={{
                        ...styles,
                        height: 35,
                        backgroundColor: '#F4DCFF',
                    }}
                >
                    <Group sx={{ justifyContent: 'center', height: '100%' }} align={'center'} px={10} spacing={0} noWrap>
                        {isAdmin ? (
                            <Text align={'center'} color={'#242424'} className={classes.text}>
                                Your plan reactivation is almost ready! To regain access to all platform functionalities, {' '}
                                <span onClick={handleReactivate} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                    click here
                                </span> to complete the set up process.
                            </Text>
                        ) : (
                            <Text align={'center'} color={'#242424'} className={classes.text}>
                                Your plan reactivation is almost ready! Ask an administrator to complete the set up process to regain access to all platform functionalities.
                            </Text>
                        )}
                    </Group>
                </div>
            )}
        </Transition>
    );
};

export default ActionPendingAlert;