import React from 'react';
import TalentDrawerSection from 'components/TalentDetail/TalentDrawerSection';
import { Group, Stack, Text } from '@mantine/core';
import { ITalentEducation } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons';
import dayjs from "dayjs";

interface TalentEducationProps {
  education?: ITalentEducation[];
}

const TalentEducation = ({ education = [] }: TalentEducationProps) => {
  if (education.length < 1) {
    return null;
  }

  return (
    <TalentDrawerSection title={'Education'} withDivider={false}>
      <Stack>
        {education.map((education) => (
          <Stack spacing={2} key={education.id}>
            <Group noWrap spacing={8} align={'flex-start'}>
              <Group align={'center'} sx={{ width: 17 }} spacing={0}>
                <FontAwesomeIcon icon={faGraduationCap} color={'#4F4F4F'} style={{ height: '16px', marginTop: '4px'}}/>
              </Group>
              <Text sx={{ fontFamily: 'Roboto' }} color={'#242424'}>
                {education.institution_name}
              </Text>
            </Group>
            <Group ml={25} spacing={'xs'}>
              <Stack spacing={2}>
                <Text color={'#242424'} sx={{ fontSize: 15 }}>
                  {education.description}
                </Text>
              {education.start_date && (
                <Text color={'#838485'} sx={{ fontSize: 15, lineHeight: 1.75 }}>
                  {dayjs(education.start_date).format('MMMM YYYY')}
                  {education?.end_date ? " - " + dayjs(education.end_date).format('MMMM YYYY') : ''}
                </Text>
              )}
              </Stack>
            </Group>
          </Stack>
        ))}
      </Stack>
    </TalentDrawerSection>
  );
};

export default TalentEducation;
