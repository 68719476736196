import { Button, createStyles, Group } from '@mantine/core';
import { ChevronLeft } from 'tabler-icons-react';
import TextLink from 'components/TextLink/TextLink';
import ButtonsWrapper from 'components/ButtonsWrapper/ButtonsWrapper';
import RolebotButton from "../../public/Buttons/RolebotButton";
import React from "react";
import {useModals} from "@mantine/modals";

const useStyles = createStyles((theme) => ({
  backButton: {
    display: 'none',
    [theme.fn.largerThan('md')]: {
      display: 'flex',
    },
  },
}));

interface FormNavigationProps {
  step: number;
  isSaveEnabled?: boolean;
  isNextDisabled?: boolean;
  isSaveChangesDisabled?: boolean;
  isSubmitDisabled?: boolean;
  isSubmitting: boolean;
  showFinishLater?: boolean;
  sourcing_only?: boolean;
  prev?: () => void;
  next: () => void | Promise<void> | Promise<Boolean>;
  save: () => void | Promise<void>;
}

const FormNavigation = ({
  step,
  prev,
  next,
  save,
  isSaveEnabled,
  isNextDisabled,
  isSaveChangesDisabled,
  isSubmitDisabled = false,
  isSubmitting,
  showFinishLater,
  sourcing_only
}: FormNavigationProps) => {
  const { classes } = useStyles();
  const modals = useModals();
  return (
    <Group position={'right'} spacing={'xs'} align={'end'} mt={'auto'}>
      {step !== 1 && showFinishLater && (
        <TextLink
          mr={'auto'}
          onClick={prev}
          spacing={0}
          icon={<ChevronLeft size={18} />}
          className={classes.backButton}
        >
          Back
        </TextLink>
      )}
      <ButtonsWrapper>
        {showFinishLater && (
          <Button variant={'subtle'} onClick={save} disabled={isSaveEnabled || isSubmitDisabled} loading={isSubmitting}>
            Save and finish later
          </Button>
        )}
        {!showFinishLater && step !== 1 &&
          <RolebotButton type={'neutral'} onClick={() => modals.closeAll()}>
            Cancel
          </RolebotButton>
        }
        {showFinishLater ? (
            <Button
                type="submit"
                onClick={next}
                disabled={isNextDisabled || isSubmitDisabled}
                loading={isSubmitting}
                loaderPosition="right"
            >
              {step === (sourcing_only ? 3 : 5) ? 'Approve and get started!' : 'Next'}
            </Button>
        ) : (
            step > 1 && (
                <Button
                    type="submit"
                    onClick={save}
                    disabled={isNextDisabled || isSaveChangesDisabled || isSubmitDisabled}
                    loading={isSubmitting}
                    loaderPosition="right"
                >
                  Save Changes
                </Button>
            )
        )}
      </ButtonsWrapper>
    </Group>
  );
};

export default FormNavigation;
