import React, { FormEvent, useState } from 'react';
import { Alert, Box, Button, Container, Divider, Group, Image, List, Stack, Text, Title } from '@mantine/core';
import LeverLogo from 'assets/img/logos/lever_logo.png';
import { Link } from 'react-router-dom';
import ExtLink from 'pages/Integration/ExtLink';
import { useConnectLeverMutation, useDisconnectLeverMutation, useGetCompanyQuery } from 'app/services/rolebot';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { isLeverOauthRequired } from 'features/app/appSlice';
import ContactSupportLink from 'pages/Integration/ContactSupportLink';
import useOpenModal from 'hooks/useOpenModal';
import useAppSelector from '../../hooks/useAppSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

const Lever = () => {
  const dispatch = useAppDispatch();
  const { disconnectIntegration } = useOpenModal();
  const { data } = useGetCompanyQuery();
  const baseURL = process.env.REACT_APP_API_URL;
  const [, { error, isSuccess: successConnect, reset: resetConnect }] = useConnectLeverMutation();
  const [disconnectLever, { isLoading: disconnectLoading, isSuccess: successDisconnect, reset: resetDisconnect }] =
    useDisconnectLeverMutation();
  const isConnected = Boolean(data?.lever);
  const requiresOauth = useAppSelector((state) => state.app.requiresLeverOauth);

  let [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  const onConnect = async () => {
    resetDisconnect();
    setButtonIsDisabled(true);
    /*await connectLever();*/
  };

  const onDisconnect = async (e: FormEvent) => {
    e.preventDefault();
    disconnectIntegration({
      integration: 'Lever',
      content: (
        <>
          <Text>
            This action will delete all the connections between Rolebot and Lever and will affect all of your company’s
            users.
          </Text>
          <Text>
            You will continue to have access to any roles that were previously created in Rolebot via Lever, but will
            not have access to any new information.
          </Text>
          <Text>Are you sure you want to disconnect this app?</Text>
        </>
      ),
      onAction: handleDisconnect,
    });
  };

  const handleDisconnect = async () => {
    resetConnect();
    await disconnectLever();
    dispatch(isLeverOauthRequired(true));
  };

  return (
    <Container size={'xl'} px={0}>
      <Group noWrap spacing={8} mb={16}>
        <RolebotButton type={'link'}>
          <FontAwesomeIcon icon={faChevronLeft} style={{paddingRight : '6px'}} />
          <Link to={'/settings/integrations'} style={{ color: '#249FC8', textDecoration: 'none' }}>
            Browse Apps
          </Link>
        </RolebotButton>
      </Group>
      <Group spacing={40} noWrap>
        <Box sx={{ maxWidth: 200, alignSelf: 'flex-start' }}>
          <Image src={LeverLogo} width={200} height={200} fit={'contain'} radius={15} />
          <Divider mt={40} mb={20} />
          <Stack spacing={4} mb={40}>
            <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }}>
              Requirements
            </Text>
            <Text color={'#B3B3B3'}>Paid Lever Plan</Text>
          </Stack>
          <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }} mb={4}>
            Support
          </Text>
          <Stack spacing={4}>
            <ExtLink
              to={
                'https://help.lever.co/hc/en-us/articles/5548132581389-How-do-I-enable-and-use-the-Rolebot-integration-'
              }
              title={'Setup Guide'}
            />
            <ContactSupportLink />
            <ExtLink to={'https://www.lever.co/'} title={'Visit Lever'} />
          </Stack>
        </Box>
        <Box mb={'auto'}>
          <Stack spacing={20} mb={10}>
            <Title order={2} sx={{ color: '#242424' }}>
              Lever
            </Title>
            <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'}>
              Description
            </Text>
          </Stack>
          <Stack sx={{ color: '#838485' }} spacing={10} mb={50}>
            <Text>
              Lever is a leading Talent Acquisition Suite that makes it easy for talent teams to reach their hiring
              goals and to connect companies with top talent
            </Text>
            <Text>Lever Recruiting's integration with Rolebot allows you to:</Text>
            <List sx={{ color: '#838485' }} ml={20}>
              <List.Item>Launch Lever Jobs in Rolebot</List.Item>
              <List.Item>Link Lever jobs to Rolebot roles</List.Item>
              <List.Item>Search for Rolebot candidates in your company's Candidates + Prospects database</List.Item>
              <List.Item>Export Rolebot candidates to your company’s Lever database</List.Item>
            </List>
          </Stack>
          <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'} mb={10}>
            App Setup
          </Text>

          {!isConnected ?
              <RolebotButton
                  type={'primary'}
                  component="a"
                  href={baseURL + '/lever'}
                  size={'md'}
                  onClick={onConnect}
                  loading={buttonIsDisabled}
              >
                {'Add to Rolebot'}
              </RolebotButton> : null
          }

          {(isConnected && requiresOauth) ?
              <div>
                <RolebotButton
                    type={'primary'}
                    component="a"
                    href={`${process.env.REACT_APP_API_URL}/oauth/redirect?provider=lever`}
                    size={'md'}
                    loading={buttonIsDisabled}
                >
                  {'Re-authenticate'}
                </RolebotButton>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '20px',
                  borderRadius: 5,
                  border: '1px solid #db706b',
                  background: 'rgba(251,241,240,255)',
                  padding: '15px 0 15px 15px'
                }}>
                  <FontAwesomeIcon size='xl' icon={faTriangleExclamation}
                                   style={{color: '#db706b', marginRight: '5px', width: 28, height: 24}}/>
                  <Group ml={10}>
                    <Text size={"md"} color={"#242424"} mb={9} pl={5} inline align='left'
                          style={{fontWeight: 400, fontSize: 15}}>
                      Your company's Lever authentication token has expired and all Lever features are currently
                      unavailable.</Text>
                    <Text size={"md"} color={"#242424"} pl={5} inline align='left'
                          style={{marginBottom: 'unset', fontWeight: 400, fontSize: 15}}>
                      Please re-authenticate to continue using the integration.</Text>
                  </Group>
                </div>
              </div> : null
          }

          {isConnected && !requiresOauth ?
              <RolebotButton
                  type={'neutral'}
                  component="a"
                  href={baseURL + '/lever'}
                  size={'md'}
                  onClick={onDisconnect}
                  loading={disconnectLoading}
              >
                {'Disconnect'}
              </RolebotButton> : null
          }



          {error && (
            <Alert color={'red'} mt={20}>
              {/*@ts-ignore*/}
              {error?.data?.message}
            </Alert>
          )}
          {successConnect && (
            <Alert color={'green'} mt={20}>
              Successfully connected to Lever
            </Alert>
          )}
          {successDisconnect && (
            <Alert color={'green'} mt={20}>
              Successfully disconnected from Lever
            </Alert>
          )}
        </Box>
      </Group>
    </Container>
  );
};

export default Lever;
