import { useState, useEffect } from 'react';
import {
    Button,
    createStyles,
    Group,
    Image,
    Text,
    Title,
    Grid,
    Switch
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Link, NavLink, useLocation } from 'react-router-dom';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import PricingPlan from './PricingPlan';
import { IPricingPlan } from 'types/pricing';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const useStyles = createStyles((theme) => ({
    member: {
        marginTop: 24,
        paddingBottom: 24,
        [theme.fn.largerThan('md')]: {
            marginInline: 44,
        },
    }
}));

interface PlansProps {
    pricingPlans: IPricingPlan[];
    handlePlanSelected: (data: any) => void;
    handleContactSalesSelected: () => void;
}

const Plans = ({ handlePlanSelected, handleContactSalesSelected, pricingPlans }: PlansProps) => {
    const { classes } = useStyles();
    const isDesktop = useMediaQuery('(min-width: 992px)');
    const location = useLocation(); // Use the useLocation hook

    // Initialize isYearly from the selectedPlan in sessionStorage
    const [isYearly, setIsYearly] = useState(() => {
        const savedPlan = sessionStorage.getItem('selectedPlan');
        const planData = savedPlan ? JSON.parse(savedPlan) : {};

        if ('subscription_type' in planData) {
            return planData.subscription_type === 'Annual';
        } else {
            return false;
        }
    });

    // Update isYearly in the selectedPlan object in sessionStorage when it changes
    useEffect(() => {
        // Retrieve the current selectedPlan from sessionStorage
        const currentSelectedPlan = JSON.parse(sessionStorage.getItem('selectedPlan') || '{}');

        // Update the selectedPlan with the new role number
        const updatedSelectedPlan = {
            ...currentSelectedPlan,
            subscription_type: isYearly ? 'Annual' : 'Month to Month',
        };

        // Save the updated selectedPlan back to sessionStorage
        sessionStorage.setItem('selectedPlan', JSON.stringify(updatedSelectedPlan));
    }, [isYearly]);

    // React to changes in the URL
    useEffect(() => {
        const savedPlan = sessionStorage.getItem('selectedPlan');
        const planData = savedPlan ? JSON.parse(savedPlan) : {};
        if ('subscription_type' in planData) {
            setIsYearly(planData.subscription_type === 'Annual');
        }
    }, [location.pathname]);

    const handleScheduleADemoSelected = () => {
        window.open("https://www.rolebot.io/demo", "_blank");
    }

    return (
        <>
            <NavLink to={'/'}>
                <Image src={LogoLetters} width={180} fit={'contain'} mb={30} />
            </NavLink>
            <Text
                style={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    color: '#838485'
                }}
                mb={10}
            >
                Step 1
            </Text>
            <Group
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Title
                    order={3}
                    mb={30}
                    style={{
                        color: '#4F4F4F'
                    }}
                >
                    Choose the plan that's right for you
                </Title>
                <Group
                    style={{ marginBottom: '30px' }}
                >
                    <Text
                        style={{
                            color: isYearly ? '#838485' : '#7039ED',
                        }}
                    >
                        Monthly
                    </Text>
                    <Switch
                        checked={isYearly}
                        onChange={() => {
                            setIsYearly(!isYearly);
                        }}
                        onLabel=""
                        offLabel=""
                        size="lg"
                        sx={{
                            '&::before': {
                                color: 'blue !important',
                            },
                            '&:hover::before': {
                                color: 'blue !important',
                            }
                        }}
                    />
                    <Text
                        style={{
                            color: isYearly ? '#7039ED' : '#838485',
                        }}
                    >
                        Yearly (SAVE 10%)
                    </Text>
                </Group>
            </Group>
            <Grid gutter={40} justify="center" columns={isDesktop ? 12 : 6}>
                {pricingPlans.map((plan) => (
                    <PricingPlan
                        key={plan.id}
                        pricingPlan={plan}
                        isYearly={isYearly}
                        handlePlanSelected={handlePlanSelected}
                        handleContactSales={handleContactSalesSelected}
                    />
                ))}
            </Grid>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '24px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <RolebotButton
                    type={'neutral'}
                    onClick={handleScheduleADemoSelected}
                >
                    Not sure yet? Schedule a demo
                </RolebotButton>
            </div>
            <Group align={'center'} position={'center'} spacing={4} className={classes.member}>
                <Text>Have an account?</Text>
                <Text component={Link} to={'/'} color={'#7039ED'}>
                    Sign in
                </Text>
            </Group>
        </>
    );
};

export default Plans;
