import { ContextModalProps } from "@mantine/modals"
import { Text, LoadingOverlay } from "@mantine/core"
import { UpgradePlanProps } from "types/modals"
import { createStyles } from "@mantine/core";
import useOpenModal from 'hooks/useOpenModal';
import { useModals } from '@mantine/modals';
import TitleWithClose from "../components/TitleWithClose";
import { useState, useMemo } from "react";
import { useGetShopifyProductsQuery, useGetCompanyQuery } from "app/services/rolebot";
import RolebotPlan from "./RolebotPlan";
import PlanComparisonTable from "./PlanComparisonTable";
import BillingCycleToggle from "./BillingCycleToggle";

const useStyles = createStyles((theme) => ({
    container: {
        padding: '20px',
        background: 'linear-gradient(180deg, #48309D 26%, #24154F 100%)',
        paddingBottom: '60px',
    }
}));

const UpgradePlan = ({ id, context, innerProps }: ContextModalProps<UpgradePlanProps>) => {
    const { classes, cx } = useStyles();

    const modals = useModals();
    const { confirmNewPlanModal, contactSalesModal } = useOpenModal();
    const { data: shopifyProducts, isFetching } = useGetShopifyProductsQuery({});
    const { data: company } = useGetCompanyQuery();
    
    const activeRoleLimit = useMemo(() => {
        return company?.active_role_limit;
    }, [company]);

    const activeRoleCount = useMemo(() => {
        return company?.active_role_count;
    }, [company]);
    
    const subscriptionType = useMemo(() => {
        return company?.customer_pricing_plan?.subscription_type;
    }, [company]);

    const [billingCycle, setBillingCycle] = useState(() => 
        subscriptionType === 'Annual' ? 'yearly' : 'monthly'
    );

    const handleBillingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingCycle(event.target.value);
    };

    const calculateProratedSurcharge = (plan: any) => {
        const today = new Date();
        const nextBillingDate = new Date(company?.customer_pricing_plan?.next_billing_date);
        const differenceInTime = nextBillingDate.getTime() - today.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        
        const currentPlanPrice = company?.customer_pricing_plan?.amount || 0;
        const newPlanPrice = plan.productPrice * plan.activeRoleLimit;
        const priceDifference = newPlanPrice - currentPlanPrice;

        if (priceDifference <= 0) {
            return 0;
        }

        // If the change is made on the same day as subscription creation (or within 1 day)
        if (differenceInDays >= 29) {
            return Number(priceDifference.toFixed(2));
        }

        // Otherwise, calculate prorated amount
        const proratedSurcharge = (priceDifference * differenceInDays) / 30; // Assuming 30 days in a month
        return Number(proratedSurcharge.toFixed(2));
    };

    const handlePlanSelected = (plan: any) => {
        modals.closeModal(id);
        confirmNewPlanModal({
            activeRoleLimit: plan.activeRoleLimit,
            productPrice: plan.productPrice,
            customerPlanName: company?.customer_pricing_plan?.pricing_plan?.name,
            proratedSurcharge: calculateProratedSurcharge(plan),
            nextBillingDate: company?.customer_pricing_plan?.next_billing_date,
            shopifyContractId: company?.customer_pricing_plan?.shopify_contract_id,
            sellingPlanId: plan.sellingPlanId,
            productId: plan.productId
        });
    };

    const handleContactSales = () => {
        modals.closeModal(id);
        contactSalesModal({ fromProPlan: true });
    };

    return (
        <>
            <div className={!isFetching ? classes.container : ''}>
                <LoadingOverlay visible={isFetching} />
                {!isFetching && (
                    <>
                        <TitleWithClose id={id} title={''} mb={4} />
                        <Text align="center" mb={20} style={{ fontFamily: 'Helvetica, sans-serif', fontSize: '40px', fontWeight: 400, color: '#FFFFFF' }}>
                            Make changes to your plan
                        </Text>
                        <Text align="center" style={{ fontFamily: 'Helvetica, sans-serif', fontSize: '19px', fontWeight: 400, color: '#FFFFFF' }}>
                            Select the number of roles you'd like to have (up to 5).
                        </Text>
                        <Text align="center" mb={40} style={{ fontFamily: 'Helvetica, sans-serif', fontSize: '19px', fontWeight: 400, color: '#FFFFFF' }}>
                            For more than 5 roles, reach out to our sales team for a tailored solution.
                        </Text>
                        <BillingCycleToggle
                            billingCycle={billingCycle}
                            handleBillingChange={handleBillingChange}
                            subscriptionType={subscriptionType}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '40px',
                            }}
                        >
                            {shopifyProducts?.data && shopifyProducts.data.map((product: any) => (
                                <RolebotPlan
                                    key={product.id}
                                    product={product}
                                    isPro={false}
                                    billingCycle={billingCycle}
                                    handlePlanSelected={handlePlanSelected}
                                    handleContactSales={handleContactSales}
                                    activeRoleLimit={activeRoleLimit}
                                    activeRoleCount={activeRoleCount}
                                />
                            ))}
                            <RolebotPlan
                                key="pro-plan"
                                product={{ title: 'Pro' }}
                                isPro={true}
                                billingCycle={billingCycle}
                                handlePlanSelected={handlePlanSelected}
                                handleContactSales={handleContactSales}
                                activeRoleLimit={activeRoleLimit}
                                activeRoleCount={activeRoleCount}
                            />
                        </div>
                    </>
                )}
            </div>
            {!isFetching && <PlanComparisonTable />}
        </>
    )
}

export default UpgradePlan
