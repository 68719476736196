import React, { useEffect, useState } from 'react';
import { Stack, LoadingOverlay, createStyles} from '@mantine/core';
import useOpenModal from 'hooks/useOpenModal';
import './CalendarSettings.css';
import {
  useGetCronofyUserQuery,
  useDisconnectCronofyMutation,
  useLazyGetCronofyUserCalendarQuery,
  useLazyGetCronofyAvailabilityRuleQuery,
} from 'app/services/rolebot';
import TitleSection from './components/TitleSection';
import CalendarSelector from './components/CalendarSelector';
import CalendarSelectedSection from './components/CalendarSelectedSection';
import PreferredMeetingTimesArea from './components/PreferredMeetingTimesArea';
import OauthCalendar from './components/OauthCalendar';
import CalendarSettingsSection from './components/CalendarSettingsSection/CalendarSettingsSection';

import { ICronofyCalendar } from '../../../types';

const useStyles = createStyles((theme) => ({
  wrapper: {
    [theme.fn.largerThan('md')]: {
      paddingInline: 30,
      maxWidth: '60%',
    },
  },
  selectCalendarMessage: {
    marginTop: '10px',
    color: '#242424',
    fontSize: '16px',
    fontFamily: 'Helvetica',
    fontWeight: 400,
  },
  message: {
    color: '#838485',
  },
}));

const CalendarSettings = () => {
  const { cronofyModal } = useOpenModal();
  const { classes } = useStyles();
  const {
    data: cronofyUserdata,
    isLoading: cronofyUserIsLoading,
    isFetching: cronofyUserIsFetching,
    isError: cronofyUserIsError,
  } = useGetCronofyUserQuery();
  const [disconnectCalendar, { isLoading: disconnectCalendarIsLoading }] = useDisconnectCronofyMutation();
  const [getCalendars, { data: calendarsData, isLoading: calendarsIsLoading }] =
    useLazyGetCronofyUserCalendarQuery();
  const [fetchRules] = useLazyGetCronofyAvailabilityRuleQuery();

  const [userCronofyAuthed, setUserCronofyAuthed] = useState(false);
  const [userSelectedCalendar, setUserSelectedCalendar] = useState<boolean>(false);
  const [calendars, setCalendars] = useState<ICronofyCalendar[]>([]);

  useEffect(() => {
    if (cronofyUserdata?.cronofy) {
      getCalendars();
      //TODO: This should not here. Move to modal when you get a chance.
      //Leaving here due to deadline and not time for rebuild
      fetchRules('default');
    }
    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cronofyUserdata]);

  useEffect(() => {
    setCalendars(
      calendarsData
        ? calendarsData.calendars.filter((x) => !x.calendar_readonly).filter((x) => !x.calendar_deleted)
        : []
    );
    // eslint-disable-next-line
  }, [calendarsData]);

  useEffect(() => {
    if(!cronofyUserIsError){
      if (cronofyUserdata?.cronofy) {
        setUserCronofyAuthed(true);
      }
  
      if (cronofyUserdata?.cronofy?.calendar_id) {
        setUserSelectedCalendar(true);
      }
    }
  }, [cronofyUserdata, cronofyUserIsError]);

  const handleDisconnectCalendar = () => {
    disconnectCalendar().then(() => {
      setUserCronofyAuthed(false);
      setUserSelectedCalendar(false);
    });
  };

  // Designer wants the cancel button on CalendarSelector to disconnect on cancel.
  const disconnectOnCancel = userCronofyAuthed && !userSelectedCalendar;

  return (
    <Stack className={classes.wrapper} px={18} spacing={0}>
      <LoadingOverlay visible={cronofyUserIsLoading || calendarsIsLoading || disconnectCalendarIsLoading} />
      <TitleSection message="Connect a calendar to Rolebot" />
      {/*Step One: Oauth Calendar*/}
      {!userCronofyAuthed && <OauthCalendar />}

      {/*Step Two: Select Default Calendar*/}
      {userCronofyAuthed && !userSelectedCalendar && (
        <CalendarSelector
          disconnectOnCancel={disconnectOnCancel}
          onDisconnectCalendar={handleDisconnectCalendar}
          selectedCalendar={cronofyUserdata?.cronofy?.calendar_id}
          calendars={calendars}
        />
      )}
      {/* Your Calendar select summary area */}
      {userCronofyAuthed && userSelectedCalendar && (
        <CalendarSelectedSection
          handleDisconnectCalendar={handleDisconnectCalendar}
          calendar={calendars.find((x: any) => x.calendar_id === cronofyUserdata?.cronofy?.calendar_id)}
        />
      )}
      {/* Preferred meeting times area */}
      {userSelectedCalendar && <PreferredMeetingTimesArea openModelClicked={cronofyModal} />}

      {/*Calendar settings section*/}
      {userSelectedCalendar && calendarsData && (
        <CalendarSettingsSection
          loading={cronofyUserIsFetching}
          meetingDuration={cronofyUserdata.cronofy.meeting_duration}
          conferenceProfiles={calendarsData.conferencing_profiles}
          cronofyProviders={cronofyUserdata?.userInfo}
        />
      )}
    </Stack>
  );
};

export default CalendarSettings;
