import {useState, useEffect} from 'react';

function useDebounce(value, delay) {

    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(

        () => {

            // Update debounced value after delay

            const handler = setTimeout(() => {

                setDebouncedValue(value);

            }, delay);



            // Cancel the timeout if value changes (also on delay change or unmount)

            // This is how we prevent debounced value from updating if value is changed ...

            // .. within the delay period. Timeout gets cleared and restarted.

            return () => {

                clearTimeout(handler);

            };

        },

        [value, delay] // Only re-call effect if value or delay changes

    );



    return debouncedValue;

}

export default useDebounce;

/**
 * Example use case.
 */

// State and setter for search term
// const [searchTerm, setSearchTerm] = useState('');

// Now we call our hook, passing in the current searchTerm value.
// The hook will only return the latest value (what we passed in) ...
// ... if it's been more than 500ms since it was last called.
// Otherwise, it will return the previous value of searchTerm.
// The goal is to only have the API call fire when user stops typing ...
// ... so that we aren'TextLink hitting our API rapidly.
// const debouncedSearchTerm = useDebounce(searchTerm, 500);
//
// useEffect(() => {
//     use debouncedSearchTerm here
// }, [debouncedSearchTerm])
