import React from 'react';
import { Button, Group, Text, createStyles } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';
import TitleWithClose from '../components/TitleWithClose';
import { useNavigate } from 'react-router-dom';
import { getReactivatePlanToastContent, toastSuccess } from 'utils/toastify-messages';
import { usePostReactivatePlanMutation } from 'app/services/rolebot';

const useStyles = createStyles((theme) => ({
  cancelButton: {
    backgroundColor: '#FFF',
    color: '#838485',
    borderRadius: '6px',
    border: '1px solid #838485',
    paddingRight: '20px',
    paddingLeft: '20px',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      color: '#4F4F4F',
      border: '1px solid #4F4F4F',
    },
  },
}));

const PlanReactivateModal = ({ id, context }: ContextModalProps) => {
  const { classes } = useStyles();
  const modals = useModals();
  const navigate = useNavigate();
  const [postReactivatePlan] = usePostReactivatePlanMutation();

  const handleCloseModal = () => {
    context.closeModal(id);
  };

  const handleReactivatePlan = async () => {
    
    try {
      await postReactivatePlan({}).unwrap();

      toastSuccess(
        getReactivatePlanToastContent()
      );
      
      modals.closeAll();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <TitleWithClose id={id} title={'Reactivate your plan'}/>
      <>
        <Text mb={20} color={'#242424'}>
          Don't miss out on new prospects! Reactivate your plan to regain access to all of Rolebot's functionalities.
        </Text>
        <Text mb={20} color={'#242424'}>
          Our team is ready to assist you in reactivating your account and optimizing your Rolebot experience.
        </Text>
        <Text mb={20} color={'#242424'}>
          Ready to unlock Rolebot's full potential?
        </Text>
      </>
      <Group position={'right'} mt={40}>
        <Button className={classes.cancelButton} onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button  onClick={handleReactivatePlan}>
          Reactivate my plan
        </Button>
      </Group>
    </>
  );
};

export default PlanReactivateModal;