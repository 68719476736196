import React from 'react';
import { createStyles, Group, Text, Transition } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 15,
  },
}));

const MoreCandidatesComingUp = () => {
  const { classes } = useStyles();

  return (
    <Transition transition={'slide-down'} mounted={true}>
      {(styles) => (
        <div
          style={{
            ...styles,
            height: 35,
            backgroundColor: '#FEF9EB',
          }}
        >
          <Group sx={{ justifyContent: 'space-between', height: '100%' }} align={'center'} px={10} spacing={0} noWrap>
            <Text align={'center'} color={'#4F4F4F'} className={classes.text} mx={'auto'}>
              More candidates coming up. Hang tight! We'll notify you once we find more matches for this role.
            </Text>
          </Group>
        </div>
      )}
    </Transition>
  );
};

export default MoreCandidatesComingUp;
