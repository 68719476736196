import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useOutsideAreaClick from '../../../../pages/RoleStats/hooks/useOutsideAreaClick';
import { createStyles, Loader } from '@mantine/core';
import { useLazyGetLeverRolesQuery } from '../../../../app/services/rolebot';
import useDebounce from '../../../../pages/RoleStats/hooks/useDebounce';
import LinkableLeverRoleItem from './LinkableLeverRoleItem';

const useStyles = createStyles((theme) => ({
  LeverRolesDropDownWrapper: {
    position: 'relative',
  },

  LeverRolesDropDown: {
    border: 'solid 2px #DFE1E1',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 5px 5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: '1000',
    top: '0',
    left: '0',
    right: '0',
    padding: '5px 7px',
    maxHeight: '200px',
    overflowY: 'auto',
  },

  LeverLoadingItem: {
    fontSize: '16px',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'unset',
    marginBottom: '0',
    color: '#838485',
    padding: '2px 10px',
  },
}));

const LinkableLeverRolesDropDown = ({ show, linkableLeverRoleSelected, needle, closeAllDropdowns }) => {
  const { classes } = useStyles();
  const searchDebounced = useDebounce(needle, 1000);
  const [isLoading, setIsLoading] = useState(false);
  const [getLeverRoles] = useLazyGetLeverRolesQuery();
  const [leverRolesToShow, setLeverRolesToShow] = useState([]);
  const wrapperRef = useRef(null);
  const clickedOutside = useOutsideAreaClick(wrapperRef);

  useEffect(() => {
    if (clickedOutside && show) {
      closeAllDropdowns();
    }
    // eslint-disable-next-line
  }, [clickedOutside, show]);

  useEffect(() => {
    if (searchDebounced && searchDebounced.length > 0) {
      let numberCheck = /^[0-9]*$/;
      if (numberCheck.test(searchDebounced)) {
        getLeverRoles({ type: 'reqId', value: searchDebounced, state: 'published' }).then((res) => {
          let rolesToShow = res?.data?.lever_jobs?.filter(
            (x) => x.lever_job_name.toLowerCase().includes(needle.toLowerCase()) || x.requisition_ids.includes(needle)
          );
          setLeverRolesToShow(rolesToShow);
          setIsLoading(false);
        });
      } else {
        getLeverRoles({ type: 'name', value: searchDebounced, state: 'published' }).then((res) => {
          let rolesToShow = res?.data?.lever_jobs?.filter(
            (x) => x.lever_job_name.toLowerCase().includes(needle.toLowerCase()) || x.requisition_ids.includes(needle)
          );
          setLeverRolesToShow(rolesToShow);
          setIsLoading(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [searchDebounced]);

  useEffect(() => {
    if (needle.length > 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [needle]);

  return show && !isLoading && needle && leverRolesToShow?.length > 0 ? (
    <div className={classes.LeverRolesDropDownWrapper}>
      <div className={classes.LeverRolesDropDown} ref={wrapperRef}>
        {leverRolesToShow?.length > 0 &&
          leverRolesToShow.map((x) => (
            <LinkableLeverRoleItem role={x} key={x.id} linkableLeverRoleSelected={linkableLeverRoleSelected} />
          ))}
      </div>
    </div>
  ) : show && !isLoading && needle && leverRolesToShow?.length === 0 ? (
    <div className={classes.LeverRolesDropDownWrapper}>
      <div className={classes.LeverRolesDropDown} ref={wrapperRef}>
        <div className={classes.LeverLoadingItem}>
          No matches found for <span style={{ fontWeight: 500, color: '#242424', fontFamily: 'Roboto' }}>{searchDebounced}</span> on{' '}
          <span style={{ fontWeight: 500, color: '#242424', fontFamily: 'Roboto' }}>Lever</span>
        </div>
      </div>
    </div>
  ) : show && isLoading ? (
    <div className={classes.LeverRolesDropDownWrapper}>
      <div className={classes.LeverRolesDropDown} ref={wrapperRef}>
        <div className={classes.LeverLoadingItem}>
          <Loader style={{ verticalAlign: 'middle', marginRight: 5 }} color="gray" size="xs" /> Loading...
        </div>
      </div>
    </div>
  ) : null;
};

export default LinkableLeverRolesDropDown;

LinkableLeverRolesDropDown.propTypes = {
  show: PropTypes.bool.isRequired,
  linkableLeverRoleSelected: PropTypes.func.isRequired,
  lostFocus: PropTypes.func,
};
