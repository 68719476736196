import { ActionIcon, Avatar, Group, Text, Title } from '@mantine/core';
import { getNameInitials } from 'utils';
import { Mail, X } from 'tabler-icons-react';
import React from 'react';
import { useDeleteRoleUserMutation } from 'app/services/rolebot';
import useRandomColor from 'hooks/useRandomColor';

interface AdminRowProps {
  id?: number;
  roleId?: number | string;
  name?: string;
  email: string;
  removable?: boolean;
  onDelete?: () => void;
  isError?: boolean;
}

const AdminRow = ({ id, roleId, name, email, onDelete, removable = true, isError = false }: AdminRowProps) => {
  const [deleteUser] = useDeleteRoleUserMutation();
  const color = useRandomColor(id || 1);

  const _onDelete = async (e: any) => {
    if (onDelete) {
      onDelete();
      return;
    }
    try {
      await deleteUser({
        roleId: roleId!,
        payload: {
          id: id!,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  return (
    <Group align={'center'} spacing={14} style={{ backgroundColor: isError ? 'rgba(255, 244, 230, 1)' : 'none' }}>
      <Avatar
        size={50}
        radius={25}
        styles={{
          placeholder: {
            color,
          },
        }}
      >
        {name ? <Title order={6}>{getNameInitials(name)}</Title> : <Mail />}
      </Avatar>
      <Text color={'#242424'}>{name ? name : email}</Text>
      {removable && (
        <ActionIcon ml="auto" onClick={_onDelete} style={{ zIndex: 99 }}>
          <X color={'#838485'} size={15} />
        </ActionIcon>
      )}
    </Group>
  );
};

export default React.memo(AdminRow);
