import React from 'react';
import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps } from '@mantine/modals';
import { Button, Group, Text, Stack, createStyles } from '@mantine/core';
import CircleIcon from './Components/CircleIcon';
import { CronofyMissingTokenProps } from '../../../types/modals';
import { useNavigate } from 'react-router-dom';


const useStyles = createStyles((theme, _params, getRef) => ({
  itemWrapper: {
    position: 'relative',
  },
  leftLine: {
    borderLeft: 'solid #C4C4C4 1px',
    position: 'absolute',
    zIndex: 0,
    top: '25px',
    left: 9,
    bottom: '25px',
  },
  inlineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '20px 0',
    color: '#242424',
  },
  message: {
    color : '#242424',
  }
}));

const CronofyMissingSetupModal = ({ id, innerProps, context }: ContextModalProps<CronofyMissingTokenProps>)  => {
  const baseURL = process.env.REACT_APP_API_URL;
  const { classes } = useStyles();
  const {status, onClose} = innerProps;
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate('/profile');
    context.closeModal(id);
    if(onClose) {
      setTimeout(() => onClose(), 60000)
    }
  }

  const authCronofy = () => {
    window.location.href = baseURL + '/oauth/cronofy/redirect';
  }

  return (
    <>
      <TitleWithClose id={id} title={'You are now an Interviewer on Rolebot'} onClose={onClose} />
      <Text className={classes.message}>
        You were recently added as the interviewer on a role.
      </Text>
      <Text className={classes.message} mt={20}>
        Finish setting up your profile to streamline the interview setup process.
      </Text>
      <Stack mt={10}>
        <div className={classes.itemWrapper}>
          <div className={classes.leftLine}/>
          <div>
            <div className={classes.inlineItem}>
              <CircleIcon completed={true}/>Create an Account
            </div>
            <div className={classes.inlineItem}>
              <CircleIcon completed={status >= 1}/>Add your personal data (ex, Name, Title...)
            </div>
            <div className={classes.inlineItem}>
              <CircleIcon completed={status > 1}/>Connect your Calendar to Rolebot
            </div>
          </div>
        </div>
      </Stack>
      <Group position={'right'} mt={10}>
        <Button onClick={status === 1 ? authCronofy : goToProfile}>
          {status === 1 ? 'Authenticate Cronofy' : 'Set up my profile'}
        </Button>
      </Group>
    </>
  );
};

export default CronofyMissingSetupModal;