import {
  Alert,
  Box,
  Button,
  createStyles,
  Group,
  MediaQuery,
  Stack,
  Tabs,
  TextInput,
  Switch,
  Grid,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useListState, useMediaQuery } from '@mantine/hooks';
import AdminRow from 'components/Modals/RoleAdmins/AdminRow';
import { useCreateRoleMutation, useGetCompanyQuery, useGetUserQuery } from 'app/services/rolebot';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { ContextModalProps, useModals } from '@mantine/modals';
import SuccessLaunch from 'components/Modals/LaunchRole/SuccessLaunch';
import GreenhouseTab from 'components/Modals/LaunchRole/GreenhouseTab';
import LeverTab from 'components/Modals/LaunchRole/LeverTab';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { sendGoogleEvent } from 'utils/analytics';
import AddUsersToRoleDropDown from './Components/AddUsersToRoleDropDown';
import BodyScrollWrapper from '../components/BodyScrollWrapper';
import { toastError, toastSuccess } from 'utils/toastify-messages';
import { TrimValue } from 'utils';
import SapTab from './SapTab';
import useOutsideAreaClick from '../../../pages/RoleStats/hooks/useOutsideAreaClick';
import { RolebotClient } from 'api/client';
import ICIMSTab from './ICIMSTab';
import { LaunchRoleProps } from 'types/modals';

const useStyles = createStyles((theme, { hasAllIntegrations }: any) => ({
  button: {
    flexGrow: 1,
    [theme.fn.largerThan('md')]: {
      flexGrow: 0,
    },
  },

  tabLabel: {
    fontSize: hasAllIntegrations ? theme.fontSizes.md : theme.fontSizes.lg,
  },
}));

const nameSchema = z.preprocess(
  TrimValue,
  z
    .string()
    .min(4, { message: 'Role name must be at least 4 characters long' })
    .regex(/\b([a-zA-ZÀ-ÿ][-,A-Za-z. ']+ *)+/gm, 'Please provide a valid name')
);

const LaunchFormSchema = z.object({
  roleName: nameSchema,
  admin: z.string(),
});

type LaunchFormPayload = z.infer<typeof LaunchFormSchema>;

const LaunchRole = ({ id, innerProps }: ContextModalProps<LaunchRoleProps>) => {
  const { data: company } = useGetCompanyQuery();
  const hasAllIntegrations = company?.lever && company?.greenhouse;
  const { classes } = useStyles({ hasAllIntegrations });
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const {
    handleSubmit,
    register,
    resetField,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      roleName: '',
      admin: '',
      isSourcingOnly: false,
    },
    resolver: zodResolver(LaunchFormSchema),
  });

  const { data } = useGetUserQuery();
  const [admins, handlers] = useListState<string>([]);
  const [adminWatcher] = watch(['admin']);
  const [isSourcingOnlyWatcher] = watch(['isSourcingOnly']);

  const [createRole, { isLoading, isSuccess, isError, reset: resetMutation }] = useCreateRoleMutation();
  const modals = useModals();
  const [emailErrors, setEmailErrors] = useState<any>([]);
  const [showUsers, setShowUsers] = useState(false);
  const [integrations, setIntegrations] = useState(null);

  useEffect(() => {
    setValue('admin', adminWatcher);
    // eslint-disable-next-line
  }, [adminWatcher]);

  useEffect(() => {
    if (company) {
      RolebotClient.get('/customers/get-integrations?customer_id=' + company.id)
        .then(({ data }) => {
          setIntegrations(data);
        })
        .catch((e) => console.log(e));
    }
  }, [company]);

  useEffect(() => {
    if (data?.user) {
      admins.includes(data.user.email) || handlers.append(data.user.email);
    }
    // eslint-disable-next-line
  }, [admins, data, handlers]);

  const onSubmit = (data: LaunchFormPayload) => {
    if (!data.admin) return;

    if (admins.includes(data.admin!)) return;
    handlers.append(data.admin!);
    resetField('admin');
  };

  const handleCloseDropdown = () => {
    //setShowUsers(false);
    //setValue('admin', '');
  };

  const handleUsersDropdownFocus = () => {
    setShowUsers(true);
  };

  const handleUserSelected = (user: any) => {
    setValue('admin', user.email);
    setShowUsers(false);
    if (!admins.includes(user.email)) {
      handlers.append(user.email);
    }
    resetField('admin');
  };

  const handleDelete = (admin: string) => {
    //@ts-ignore
    let newEmailErrors = emailErrors.filter((x) => x !== admin);
    setEmailErrors(newEmailErrors);
    if (emailErrors.length === 0) {
      setEmailErrors([]);
    }
    const newState = admins.filter((a) => a !== admin);
    handlers.setState(newState);
  };

  const handleCreateRole = async (payload: LaunchFormPayload) => {
    setEmailErrors([]);
    try {
      await createRole({
        roleName: payload.roleName,
        admins,
        ...(company?.role_type?.name === 'Hybrid (Sourcing & Turnkey)' &&
          (isSourcingOnlyWatcher === true ? { sourcing_only: 1 } : { sourcing_only: 0 })),
      }).unwrap()
      .then((response) => {
        const role = response.role;
        if (role && innerProps.onPatchCompleted) {
          innerProps.onPatchCompleted('launchRole', role.id);
        }
      });

      sendGoogleEvent('User', 'New Rolebot role request submitted');
      modals.closeAll();
      toastSuccess(
        <div className="toastContainer">
          <div>Role created successfully</div>
          <div>Your new role is ready for setup</div>
        </div>
      );
      reset();
    } catch (e: any) {
      console.log('error', e);

      let errorMessage = e.data.message;

      if (!e.data.success) {
        toastError(
          <div className="toastContainer">
          <div>Oops!</div>
          <div>{errorMessage}</div>
        </div>
       );
      }

      if (e.data.type === 'email-error') {
        setEmailErrors(e.data.message);
      }
    }
  };

  const onReset = () => {
    reset();
    resetMutation();
    const userEmail = data?.user.email.trim() || null;
    if (userEmail) {
      handlers.setState([userEmail]);
    }
  };

  const handleResetFields = () => {
    setValue('roleName', '');
  };

  const wrapperRef = useRef(null);
  const clickedOutside = useOutsideAreaClick(wrapperRef);

  useEffect(() => {
    if (clickedOutside) {
      if (showUsers) {
        setShowUsers(false);
        setValue('admin', '');
      }
    }
    // eslint-disable-next-line
  }, [clickedOutside]);

  return (
    <>
      <TitleWithClose id={id} title={'New Role'} />
      <Tabs
        variant={'unstyled'}
        classNames={{
          tabLabel: classes.tabLabel,
        }}
      >
        <Tabs.Tab label={'Rolebot'}>
            <div
              style={{ minHeight: '380px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <Group noWrap grow mt={10} spacing={0} direction={'column'}>
                <Stack>
                  <TextInput
                    sx={{ fontWeight: 400 }}
                    label={"Role's Name"}
                    size={'md'}
                    placeholder={'React Developer'}
                    {...register('roleName')}
                    error={errors?.roleName?.message}
                  />
                  {company?.role_type?.name === 'Hybrid (Sourcing & Turnkey)' && (
                    <div>
                      <Title
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color: '#242424',
                          fontFamily: 'helvetica',
                          marginBottom: '10px',
                          padding: 0,
                        }}
                      >
                        Set to 'Sourcing only'
                      </Title>
                      <Grid style={isDesktop ? {} : { display: 'grid', gridTemplateColumns: '75% 22vw' }}>
                        <Grid.Col xs={9}>
                          <Stack>
                            <Text
                              sx={
                                isSourcingOnlyWatcher
                                  ? { color: '#242424', paddingTop: 0 }
                                  : { color: '#838485', paddingTop: 0 }
                              }
                            >
                              Rolebot does not engage with candidates when a role is set to 'Sourcing only'.
                              <span style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
                                {' '}
                                This can only be set during role creation.
                              </span>
                            </Text>
                          </Stack>
                        </Grid.Col>
                        <Grid.Col xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Group grow>
                            <Switch onLabel="" {...register('isSourcingOnly')} offLabel="" size="lg" />
                          </Group>
                        </Grid.Col>
                      </Grid>
                    </div>
                  )}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div ref={wrapperRef}>
                      <TextInput
                        sx={{ fontWeight: 400 }}
                        label={'Role Members'}
                        size={'md'}
                        placeholder={'ex. bob@acme.com'}
                        {...register('admin')}
                        onSubmit={handleSubmit(onSubmit)}
                        error={errors?.admin?.message}
                        onFocus={() => handleUsersDropdownFocus()}
                        onClick={() => {
                          if (!showUsers) {
                            setShowUsers(true);
                          }
                        }}
                      />
                      <AddUsersToRoleDropDown
                        closeDropdown={handleCloseDropdown}
                        userSelected={handleUserSelected}
                        show={showUsers}
                        needle={getValues('admin')}
                      />
                    </div>
                  </form>
                </Stack>
                <BodyScrollWrapper>
                  <Box sx={{ overflow: 'hidden', overflowY: 'auto' }}>
                    <Stack spacing={5}>
                      {admins.map((admin) => (
                        <AdminRow
                          key={admin}
                          onDelete={() => handleDelete(admin)}
                          email={admin}
                          removable={admin !== data?.user.email}
                          isError={emailErrors?.includes(admin)}
                        />
                      ))}
                    </Stack>
                  </Box>
                </BodyScrollWrapper>
                {isError &&
                  (emailErrors?.length > 0 ? (
                    <Alert title={'Oops!'} color={'orange'} style={{ marginTop: 15 }}>
                      {`The following user${
                        emailErrors?.length === 1 ? '' : 's'
                      } cannot be added because they are part of another company`}
                    </Alert>
                  ) : null)}
              </Group>
              <Group position={'right'} mt={20} align={'end'}>
                <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                  <Button disabled={isLoading} onClick={() => modals.closeAll()} variant={'outline'}>
                    Cancel
                  </Button>
                </MediaQuery>
                <Button
                  onClick={handleSubmit(handleCreateRole)}
                  disabled={watch('roleName').length < 4}
                  loading={isLoading}
                  loaderPosition={'right'}
                  className={classes.button}
                >
                  Create Role
                </Button>
              </Group>
            </div>        
        </Tabs.Tab>
        {company?.greenhouse && (
          <Tabs.Tab label={'Greenhouse'} onFocus={handleResetFields}>
            <GreenhouseTab integrations={integrations} />
          </Tabs.Tab>
        )}
        {company?.lever && (
          <Tabs.Tab label={'Lever'}>
            <LeverTab integrations={integrations} />
          </Tabs.Tab>
        )}
        {company?.sap && (
          <Tabs.Tab label={'SAP SuccessFactors'}>
            <SapTab integrations={integrations} />
          </Tabs.Tab>
        )}
        {company?.icims && (
          <Tabs.Tab label={'ICIMS'}>
            <ICIMSTab integrations={integrations} />
          </Tabs.Tab>
        )}
      </Tabs>
    </>
  );
};

export default LaunchRole;
