import React, { FormEvent, useMemo, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Group,
  Image,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  createStyles,
} from '@mantine/core';
import SapLogo from 'assets/img/sap_wordmark2.jpg';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Link } from 'react-router-dom';
import ExtLink from 'pages/Integration/ExtLink';
import { useConnectSapMutation, useDisconnectSapMutation, useGetCompanyQuery } from 'app/services/rolebot';
import { ConnectSapPayload } from 'types/requests';
import ContactSupportLink from 'pages/Integration/ContactSupportLink';
import useOpenModal from 'hooks/useOpenModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';

const SapFormSchema = z.object({
  sapDomainUrl: z.string().url('Must be a valid URL, e.g. https://example.com').nonempty('SAP Domain URL is required'),
  sapServerUrl: z
    .string()
    .url('Must be a valid URL, e.g. https://example.com')
    .nonempty('SAP SuccessFactors Server URL is required'),
  userId: z.string().nonempty('User ID is required'),
  companyId: z.string().nonempty('Company ID is required'),
  clientId: z.string().nonempty('Client ID is required'),
  clientSecret: z.string().nonempty('Client Secret is required'),
});

type SapFormPayload = z.infer<typeof SapFormSchema>;

const useStyles = createStyles((theme) => ({
  textarea_label: {
    color: '#242424',
    fontSize: 'initial',
    fontWeight: 400
  },
  submitButton: {
    backgroundColor: '#7039ED',
    borderRadius: 6,
    color: '#FFFFFF',
    fontFamily: 'Helvetica',
    fontSize: 16,
  },
  disconnectButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: 6,
    border: '1px solid #838485',
    color: '#838485',
    fontFamily: 'Helvetica',
    fontSize: 16,
    ':hover': {
      backgroundColor: '#EEEEEE',
      transition: 'background-color 0.2s ease-in-out',
    },
  },
}));

const Sap = () => {
  const { disconnectIntegration } = useOpenModal();
  const { data } = useGetCompanyQuery();

  const { classes } = useStyles();

  const isConnected = useMemo(() => {
    if (!data) {
      return false;
    }
    return Boolean(data?.sap);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    resetForm({
      sapDomainUrl: data?.sap ? '*'.repeat(10) : '',
      sapServerUrl: data?.sap ? '*'.repeat(10) : '',
      userId: data?.sap ? '*'.repeat(10) : '',
      companyId: data?.sap ? '*'.repeat(10) : '',
      clientId: data?.sap ? '*'.repeat(10) : '',
      clientSecret: data?.sap ? '*'.repeat(50) : '',
    })
  }, [data]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    reset: resetForm,
  } = useForm({
    defaultValues: useMemo(() => {
      if (!data) return {
        sapDomainUrl: '',
        sapServerUrl: '',
        userId: '',
        companyId: '',
        clientId: '',
        clientSecret: '',
      };
      return {
        sapDomainUrl: data.sap ? '*'.repeat(10) : '',
        sapServerUrl: data.sap ? '*'.repeat(10) : '',
        userId: data.sap ? '*'.repeat(10) : '',
        companyId: data.sap ? '*'.repeat(10) : '',
        clientId: data.sap ? '*'.repeat(10) : '',
        clientSecret: data.sap ? '*'.repeat(50) : '',
      };
    }, [data]),
    resolver: zodResolver(SapFormSchema),
    mode: 'onBlur',
  });
  const [connectSap, { isSuccess: successConnected, error, reset: resetConnect, isLoading: connectLoading }] =
    useConnectSapMutation();
  const [disconnectSap, { isSuccess: successDisconnected, reset: resetDisconnect, isLoading: disconnectLoading }] =
    useDisconnectSapMutation();

  const onConnect = async (data: SapFormPayload) => {
    resetDisconnect();
    const payload: ConnectSapPayload = {
      sapDomainUrl: data.sapDomainUrl,
      sapServerUrl: data.sapServerUrl,
      userId: data.userId,
      companyId: data.companyId,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
    };
    await connectSap(payload).unwrap();
  };

  const handleDisconnect = async () => {
    resetConnect();
    await disconnectSap().unwrap();
    resetForm({
      sapDomainUrl: '',
      sapServerUrl: '',
      userId: '',
      companyId: '',
      clientId: '',
      clientSecret: '',
    });
  };

  const onDisconnect = (e: FormEvent) => {
    e.preventDefault();
    disconnectIntegration({
      integration: 'SAP',
      content: (
        <>
          <Text>
            This action will delete all the connections between Rolebot and SAP SuccessFactors and will affect all of
            your company's users.
          </Text>
          <Text>
            You will continue to have access to any roles that were previously created on Rolebot via SAP
            SuccessFactors, but will not have access to any new information.
          </Text>
          <Text>Are you sure you want to disconnect this app?</Text>
        </>
      ),
      onAction: async () => {
        await handleDisconnect();
      },
    });
  };

  return (
    <Container size={'xl'} px={0}>
      <Group noWrap spacing={8} mb={16}>
        <RolebotButton type={'link'}>
          <FontAwesomeIcon icon={faChevronLeft} style={{paddingRight : '6px'}} />
          <Link to={'/settings/integrations'} style={{ color: '#249FC8', textDecoration: 'none' }}>
            Browse Apps
          </Link>
        </RolebotButton>
      </Group>
      <Group spacing={40} noWrap>
        <Box sx={{ maxWidth: 200, alignSelf: 'flex-start' }}>
          <Image src={SapLogo} width={200} height={200} fit={'contain'} radius={15} />
          <Divider mt={40} mb={20} />
          <Stack spacing={4} mb={40}>
            <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }}>
              Requirements
            </Text>
            <Text color={'#B3B3B3'}>Paid SAP SuccessFactors Plan</Text>
          </Stack>
          <Text color={'#4f4f4f'} sx={{ fontFamily: 'Roboto' }} mb={4}>
            Support
          </Text>
          <Stack spacing={4}>
            <ExtLink to={'https://docs.google.com/presentation/d/1VhF174V9jnMU74AwZx37TYf_X2MOPIbGpOzfhFJIY2c/edit?usp=sharing'} title={'Setup Guide'} />
            <ContactSupportLink />
            <ExtLink to={'https://www.sap.com/products/hcm.html'} title={'Visit SAP'} />
          </Stack>
        </Box>
        <Box mb={'auto'}>
          <Stack spacing={20} mb={10}>
            <Title order={2} sx={{ color: '#242424' }}>
              SAP SuccessFactors
            </Title>
            <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'}>
              Description
            </Text>
          </Stack>
          <Stack sx={{ color: '#838485' }} spacing={10} mb={10}>
            <Text>
              SAP SuccessFactors HXM Suite provides cloud-based solutions, such as human resource management system
              (HRMS), that connect core HR and payroll, talent management, and people analytics to help you deliver
              exceptional employee experiences.
            </Text>
            <Text>
              This integration allows SAP SuccessFactors' users to:
              <ul>
                <li>Launch SAP SuccessFactors Jobs on Rolebot</li>
                <li>Link SAP SuccessFactors Jobs to Rolebot roles</li>
                <li>Export Rolebot candidates to your company's SAP SuccessFactors database*</li>
              </ul>
            </Text>
          </Stack>
          <Stack sx={{ color: '#B3B3B3' }} spacing={1} mb={30}>
            <Text>
              Please note SAP SuccessFactors features will not be available to users that do not have a SAP account.
            </Text>
            <Text>*Only available for Turnkey plans.</Text>
          </Stack>
          <Stack mb={16}>
            <Text sx={{ fontFamily: 'Roboto', fontSize: 19 }} color={'#4f4f4f'} mb={10}>
              App Setup
            </Text>
          </Stack>
          {/* <Text color={'#838485'} mb={20}>
                Enter your SAP SuccessFactors details to complete the integration. For detailed step by step
                instructions, click{' '}
                <Text component={'a'} href={'https://www.google.com'} target={'_blank'} color={'#53AFCD'}>
                  here.
                </Text>{' '}
              </Text> */}

          <form onSubmit={isConnected ? onDisconnect : handleSubmit(onConnect)}>
            <Stack style={{marginBottom : '110px'}}>
              <TextInput
                label={'SAP Domain URL'}
                {...register('sapDomainUrl')}
                error={errors?.sapDomainUrl?.message}
                disabled={isConnected}
              />
              <TextInput
                label={'SAP SuccessFactors API Server URL'}
                {...register('sapServerUrl')}
                error={errors?.sapServerUrl?.message}
                disabled={isConnected}
              />
              <TextInput
                label={'User ID'}
                {...register('userId')}
                error={errors?.userId?.message}
                disabled={isConnected}
              />
              <TextInput
                label={'Company ID'}
                {...register('companyId')}
                error={errors?.companyId?.message}
                disabled={isConnected}
              />
              <TextInput
                label={'Client ID'}
                {...register('clientId')}
                error={errors?.clientId?.message}
                disabled={isConnected}
              />
              <Textarea
                label={'Client Secret'}
                {...register('clientSecret')}
                error={errors?.clientSecret?.message}
                disabled={isConnected}
                classNames={{
                  label: classes.textarea_label,
                }}
              />
              {error && (
                <Alert color={'red'} mt={20}>
                  {/*@ts-ignore*/}
                  {error?.data?.message}
                </Alert>
              )}
              {successConnected && (
                <Alert color={'green'} mt={20}>
                  Successfully connected to SAP SuccessFactors
                </Alert>
              )}
              {successDisconnected && (
                <Alert color={'green'} mt={20}>
                  Successfully disconnected from SAP SuccessFactors
                </Alert>
              )}
              <Button
                ml={'auto'}
                px={30}
                type={'submit'}
                disabled={isConnected ? false : !isValid}
                loading={isConnected ? disconnectLoading : connectLoading}
                className={isConnected ? classes.disconnectButton : classes.submitButton}
              >
                {isConnected ? 'Disconnect' : 'Add to Rolebot'}
              </Button>
            </Stack>
          </form>
        </Box>
      </Group>
    </Container>
  );
};
export default Sap;
