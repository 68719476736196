import { IoMdClose } from 'react-icons/io';
interface IVideoDetail {
  imageUrl: string;
  title: string;
  paragraph: string;
  footer: string;
  handleClose: any;
}

const VideoDetail = ({ imageUrl, title, paragraph, footer, handleClose }: IVideoDetail) => {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: '18px', top: '18px' }} onClick={handleClose}>
        <IoMdClose
          color="#4f4f4f"
          style={{
            borderRadius: '5px',
            backgroundColor: '#bcbcbd',
            padding: '2px',
            height: '30px',
            width: '30px',
            cursor: 'pointer',
          }}
        />
      </div>
      <div style={{ borderRadius: '5px', height: '' }}>
        <img src={imageUrl} alt="loading..." width={600} />
      </div>

      <div style={{ margin: '20px' }}>
        <div
          style={{
            marginBottom: '1vh',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            color: '#242424',
            lineHeight: '27px',
          }}
        >
          <p
            style={{
              fontSize: '23px',
            }}
          >
            {title}
          </p>
          <div
            style={{
              fontFamily: 'Helvetica',
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '21px',
            }}
          >
            <p>{paragraph}</p>
            <p>{footer}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoDetail;
