import TalentActionIcon from 'components/TalentActionIcon/TalentActionIcon';
import { usePatchTalentMutation } from 'app/services/rolebot';
import moment from 'moment';
import { closeTalentMenu, enqueueTalent } from 'features/app/appSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown } from '@fortawesome/pro-light-svg-icons';
import { Box, createStyles } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useEffect } from 'react';
import useOpenModal from 'hooks/useOpenModal';
import useAppSelector from 'hooks/useAppSelector';

const useStyles = createStyles((theme, { active, hovered }: { active: boolean; hovered: boolean }) => ({
  icon: {
    borderRadius: 25,
    fill: active ? '#E43F3F' : '#838485',
    ':hover': {
      fill: '#E43F3FCC',
    },
  },
  svg: {
    width: 30,
    height: 30,
    [theme.fn.largerThan('md')]: {
      width: 25,
      height: 25,
    },
    fill: hovered || active ? '#E43F3F' : '#838485',
    ':hover': {
      fill: '#E43F3FCC',
    },
    marginTop: '2px',
    marginLeft: '1px',
    color: hovered || active ? '#E43F3F' : '#838485',
  },
}));

interface ThumbsDownProps {
  roleId: number;
  talentId: number;
  active: boolean;
  size?: number;
  acceptedOn: any;
  isDrawerMode?: boolean;
  handleSetTalent?: any;
  handlePatchCompleted?: any;
  isButtonsActive?: boolean;
  isFeatureLocked?: boolean;
}

const ThumbsDown = ({
  roleId,
  talentId,
  size = 50,
  active,
  acceptedOn,
  isDrawerMode = false,
  handleSetTalent = () => {},
  handlePatchCompleted = (action: string, talentId: number | string) => {},
  isButtonsActive = true,
  isFeatureLocked
}: ThumbsDownProps) => {
  const { gracePeriodExpired } = useOpenModal();
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();
  const { classes } = useStyles({ active, hovered });
  const [patchTalent, { isSuccess, isLoading }] = usePatchTalentMutation();
  const talentMenuOpen = useAppSelector((state) => state.app.talentMenuOpen);

  useEffect(() => {
    if (!isSuccess) return;
    if (talentMenuOpen) {
      dispatch(closeTalentMenu());
      dispatch(enqueueTalent({ count: 1 }));
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const inChangeMindRange = () => {
    if (acceptedOn) {
      const now = moment();
      const expiresTime = moment.utc(acceptedOn).local().add(1, 'd');

      return now.isBefore(expiresTime);
    }

    return true;
  };

  const openGracePeriodExpiredModal = () => {
    gracePeriodExpired();
  };

  const handleRejectTalent = async () => {
    if (active) return;
    if (!inChangeMindRange()) {
      if (isDrawerMode) {
        dispatch(closeTalentMenu());
      }
      openGracePeriodExpiredModal();
      return;
    }
    try {
      handleSetTalent(talentId);

      await patchTalent({
        roleId: roleId,
        talentId: talentId,
        payload: {
          accepted: 0,
        },
      }).unwrap()
      .then((response: any) => {
        console.log('patch response', response);
        handlePatchCompleted('declined', talentId, response.should_show_feedback_modal);
      })
      
    } catch (e) {
      console.log('Error rejecting talent');
    }
  };

  return (
    <Box className={classes.icon} ref={ref}>
      <TalentActionIcon
        hoverColor={'#E43F3F'}
        onClick={() => isFeatureLocked ? null : handleRejectTalent()}
        content={isFeatureLocked ? 'Reactivate your plan to unlock this feature' : 'Decline'}
        isLoading={isLoading}
        size={size}
        active={active}
        isButtonsActive={isButtonsActive}
      >
        <FontAwesomeIcon icon={faThumbsDown} className={classes.svg} width={25} height={25} />
      </TalentActionIcon>
    </Box>
  );
};

export default ThumbsDown;
