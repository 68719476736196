import { useState, useEffect } from "react";
import {
    Stack,
    Text,
    Group,
    TextInput,
    createStyles,
} from '@mantine/core';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { RolebotClient } from "api/client";
import { toastError } from "utils/toastify-messages";

interface CheckoutFormProps {
    updateLoadingStatus: (status: boolean) => void;
    paymentIntent: any;
    updatePaymentLoadingStatus: (status: boolean) => void;
    goToSuccessPage: () => void;
}

const useStyles = createStyles((theme) => ({
    customStack: {
        marginBottom: '10px',
        padding: '12px',
        marginTop: '10px',
        borderRadius: '5px',
        backgroundColor: '#F7F5F5'
    },
    primary: {
        [theme.fn.largerThan('sm')]: {
            marginLeft: 'auto',
        },
    },
    buttonLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const CheckoutForm = ({ updateLoadingStatus, paymentIntent, updatePaymentLoadingStatus, goToSuccessPage }: CheckoutFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [name, setName] = useState('');
    const { classes } = useStyles();

    const toastErrorComponent = (error: any) => {
        //check if error variable has decline_code key
        var isDeclined = true;
        return (
            <div className="toastContainer">
                <div>{isDeclined ? 'Payment declined' : 'Payment failed'}</div>
                <div>{
                    isDeclined ? 'Your payment method was declined. Please review and update your payment details.' :
                        'Your payment couldn’t be processed. If the problem persists, contact your card issuer.'
                    
                }</div>
            </div>
        )
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        setLoadingPayment(true);
        updatePaymentLoadingStatus(true);

        if (!stripe || !elements) {
            setIsLoading(false);
            setLoadingPayment(false);
            updatePaymentLoadingStatus(false);
            return;
        }

        const response = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/payment/success`,
            },
            redirect: 'if_required'
        });

        if (response.error) {
            setIsLoading(false);
            setLoadingPayment(false);
            updatePaymentLoadingStatus(false);
            toastError(toastErrorComponent(response.error));
        } else if (response.paymentIntent) {
            RolebotClient.post(`/customer-pricing-plan/${paymentIntent.uuid}/payment-intent/complete`, {
                    payment_method_id: response.paymentIntent.payment_method,
                    name: name,
                })
                .then((response) => {
                    setIsLoading(false);
                    setLoadingPayment(false);
                    updatePaymentLoadingStatus(false);
                    goToSuccessPage();
                })
                .catch((error) => {
                    console.log('error', error);
                    setIsLoading(false);
                    setLoadingPayment(false);
                    updatePaymentLoadingStatus(false);
                });
        }
    }

    useEffect(() => {
        if (stripe && elements) {
            setTimeout(() => {
                updateLoadingStatus(false);
            }, 2000);
        }
    }, [stripe, elements]);


    return (
        <form id="payment-form" onSubmit={handleSubmit} style={{ position: 'relative' }}>
            <TextInput
                label={`Cardholder's Name`} 
                placeholder={'Jane Doe'}
                size={'md'}
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ paddingBottom: '10px' }}
            />
            <PaymentElement
                id="payment-element"
                options={{
                    wallets: {
                        applePay: 'never',
                        googlePay: 'never',
                    }
                }}
            />
            <Stack
                spacing={2}
                className={classes.customStack}
            >
                <Text style={{ fontSize: '16px', color: '#4F4F4F', fontFamily: 'Helvetica' }}>
                    USD ${Math.round(paymentIntent.amount)} / month
                </Text>
                <Text style={{ fontSize: '15px', color: '#838485', fontFamily: 'Helvetica' }}>
                    {paymentIntent.subscription_type === 'Month to Month' ? 'Month-to-Month' : 'Annual Agreement'} - {paymentIntent.number_of_roles} roles
                </Text>
            </Stack>

            {paymentIntent.subscription_type === 'Month to Month' &&
                <Text
                  style={{color: '#838485', fontSize: '12px', padding: '2px', lineHeight: 'normal', marginBottom: '8px'}}>
                  By clicking "Start Membership" you agree that Rolebot will automatically continue your membership plan
                  on a month-to-month basis until you cancel, and confirm your understanding and acceptance of Rolebot's
                  <>
                      {' '}
                    <a style={{color: '#40A5D0', textDecoration: 'none'}} href={"https://www.rolebot.io/terms-of-service"}
                       target="_blank">
                      termination policy
                    </a>
                  </>
                  . Your membership will start as soon as you set up payment.
                </Text>
            }

            {paymentIntent.subscription_type === 'Annual' &&
            <Text
              style={{color: '#838485', fontSize: '12px', padding: '2px', lineHeight: 'normal', marginBottom: '8px'}}>
              By clicking "Start Membership" you agree that Rolebot will automatically continue your membership plan for
              one (1) year and confirm your understanding and acceptance of Rolebot's
                <>
                  {' '}
                <a style={{color: '#40A5D0', textDecoration: 'none'}} href={"https://www.rolebot.io/terms-of-service"}
                   target="_blank">
                  termination policy
                </a>
              </>
              . If you choose to cancel your membership during this period, your subscription will remain active and
              will be billed until its conclusion. Your membership will start as soon as you set up payment.
            </Text>
            }

            <Group position={'center'} mb={20}>
                <RolebotButton
                    type={'primary'}
                    loading={isLoading}
                    loaderPosition={'right'}
                    form="create-account-form"
                    disabled={isLoading || !stripe || !elements || !name}
                    onClick={handleSubmit}
                    className={classes.primary}
                >
                    Start Membership
                </RolebotButton>
            </Group>
        </form>
    );
};

export default CheckoutForm;
