import React, { useEffect } from 'react';
import { ContextModalProps } from '@mantine/modals';
import { Button, Group, List, Text } from '@mantine/core';
import { usePostCancelPlanRenewalMutation } from 'app/services/rolebot';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { CancelPlanRenewalProps } from 'types/modals';
import { toastSuccess } from 'utils/toastify-messages';

const CancelPlanRenewal = ({ id, context, innerProps }: ContextModalProps<CancelPlanRenewalProps>) => {
    const [postCancelPlanRenewal, { isLoading, isSuccess, isError, reset }] = usePostCancelPlanRenewalMutation();

    const handleCancelPlanRenewal = async () => {
        try {
            await postCancelPlanRenewal({}).unwrap();

            context.closeModal(id);

            toastSuccess(
                <div className="toastContainer">
                    <div>Our team will contact you soon!</div>
                    <div>Our team will reach out to you to help reactive your plan.</div>
                </div>
            );

        } catch (e: any) {
            console.log('cancelPlan error', e);
        }
    }

    return (
        <>
            <TitleWithClose id={id} title={'Cancel your plan renewal'} />
            <Text mb={20} color={'#242424'}>
                By cancelling your plan, you'll retain access to your account and to continue receiving candidates until <b>{ innerProps.nextBillingDate }</b>. Beyond this date, you will continue having access to your account and data, but will not have access to new prospects.
            </Text>
            <Text mb={20} color={'#242424'}>
                If you have questions or would like to explore other options, feel free to reach out to our team.
            </Text>
            <Group position={'right'} mt={40}>
                <Button variant={'default'} onClick={() => context.closeModal(id)}>
                    Cancel
                </Button>
                <Button onClick={handleCancelPlanRenewal} loading={isLoading} color={'red'}>
                    Cancel plan renewal
                </Button>
            </Group>
        </>
    );
};

export default CancelPlanRenewal;
